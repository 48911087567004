import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
  Typography,
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Fab from "@material-ui/core/Fab";
import Loading from "../components/Loading";
import * as AdminService from "../services/AdminServices";
import EditArbitration from "../components/EditArbitration";
import * as VehiclePurchaseService from "../services/VehiclePurchaseServices";
import { store_arbitrations } from "../actions/uiActions";

const styles = (theme) => ({
  root: {
    width: "95%",
    height: "100%",
  },
  topWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  bottomWrapper: {
    marginTop: "1rem",
  },
  fieldWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "5rem",
    margin: ".5rem",
    marginTop: 0,
  },
  vinWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "2rem",
    margin: ".5rem",
    marginTop: 0,
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "8rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    margin: 0,
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    height: "2rem",
    borderRadius: 0,
  },
  createArbitration: {
    // display: 'flex',
    // flexDirection: 'column'
  },
  arbitrationSearch: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10%",
  },
  datePicker: {
    width: "8rem",
    height: "100%",
  },
  button: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.gray,
    fontSize: theme.font.small,
    fontWeight: "bold",
    width: "9rem",
    color: theme.palette.primary.white,
  },
  buttonWrapper: {
    display: "flex",
    height: "2rem",
    width: "20rem",
    justifyContent: "space-around",
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  datePickerWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "6rem",
  },
  vinSearchWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: ".5rem",
    height: "2rem",
  },
  formControl: {
    height: "3rem",
    margin: "0 0 .5rem .5rem",
    width: "20rem",
  },
  checkboxRoot: {
    "&$checked": {
      color: theme.palette.primary.gray,
    },
  },
  checked: {},
  errorSnackBar: {
    marginTop: "7rem",
  },
  title: {
    display: "flex",
  },
  fabText: {
    fontSize: theme.font.small,
  },
  "@global": {
    "label + .MuiInput-formControl": {
      width: "7rem",
      transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      backgroundColor: "rgba(0, 0, 0, 0.09)",
      marginTop: 0,
      height: "2rem",
    },
  },
});

export class ManageArbitration extends Component {
  state = {
    arbitrationData: [],
    openArbitrationData: [],
    closeArbitrationData: [],
    displayEdit: false,
    arbReqType: null,
    vin: "",
    vinSearch: "",
    arbitrationFromDate: null,
    arbitrationToDate: null,
    purchaseFromDate: null,
    purchaseToDate: null,
    isOpenArbitration: true,
    selectedArbitration: null,
    arbitrationTypes: {},
    editArbitraiton: false,
    createArbitration: false,
    carDetails: null,
    message: null,
  };

  componentDidMount = () => {
    const { data } = this.props;
    this.setState({
      arbitrationData: data,
    });
    this.setArbitrationTypes();
  };

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    if (data !== prevProps.data) {
      this.setState({
        arbitrationData: data,
      });
    }
  };

  handleCancelEdit = () => {
    this.setState({ editArbitration: false });
  };

  handleConditionReport = (inventoryId, dataType) => {
    const { dispatch, toggleConditionReport } = this.props;
    dispatch(store_arbitrations(this.state[dataType], dataType));
    toggleConditionReport(inventoryId);
  };

  toggleArbitration = () => {
    const {
      vin,
      arbitrationFromDate,
      arbitrationToDate,
      purchaseFromDate,
      purchaseToDate,
    } = this.state;
    this.props.getArbitrationData(
      vin,
      !this.state.isOpenArbitration ? "OPEN" : "CLOSED",
      arbitrationFromDate,
      arbitrationToDate,
      purchaseFromDate,
      purchaseToDate
    );
    this.setState({ isOpenArbitration: !this.state.isOpenArbitration });
  };

  handlePurchaseReceiptPdf = (inventoryId) => {
    try {
      VehiclePurchaseService.getPurchaseReceiptPdf(inventoryId);
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleVinChange = (event) => {
    this.setState({
      vin: event.target.value.toUpperCase(),
    });
  };

  handleDateChange = (name) => (time) => {
    this.setState({
      [name]: time,
    });
  };

  clearCreateArbitration = () => {
    this.setState({
      vin: "",
      arbReqType: null,
    });
  };

  clearSearchArbitration = () => {
    this.setState({
      vinSearch: "",
      arbitrationFromDate: null,
      arbitrationToDate: null,
      purchaseFromDate: null,
      purchaseToDate: null,
    });
  };

  removeCancelledArbitration = (arbitrationId) => {
    const { arbitrationData, openArbitrationData } = this.state;
    const filteredData = arbitrationData.filter(
      (arbitration) => arbitration.arbitrationId !== arbitrationId
    );
    const filteredOpenData = openArbitrationData.filter(
      (arbitration) => arbitration.arbitrationId !== arbitrationId
    );
    this.setState({
      arbitrationData: filteredData,
      openArbitrationData: filteredOpenData,
    });
  };

  handleArbitrationSearch = () => {
    const {
      vinSearch,
      isOpenArbitration,
      arbitrationFromDate,
      arbitrationToDate,
      purchaseFromDate,
      purchaseToDate,
    } = this.state;
    if (vinSearch && vinSearch.length < 7) {
      this.setState({
        message: `Invalid VIN number entered, please confirm that you have successfully purchased the Vehicle.
                            Please enter a minimum of last 6 characters of the Vehicle Identification Number.`,
        createLoading: false,
      });
    } else {
      this.props.getArbitrationData(
        vinSearch,
        isOpenArbitration ? "OPEN" : "CLOSED",
        arbitrationFromDate,
        arbitrationToDate,
        purchaseFromDate,
        purchaseToDate
      );
    }
  };

  handleUpdateArbitration = async (arbitrationId) => {
    const { editArbitration } = this.state;
    if (!editArbitration) {
      const response = await AdminService.getArbitrationDetail(arbitrationId);
      this.setState({ editArbitration: true, selectedArbitration: response });
    } else {
      this.setState({
        editArbitration: false,
        selectedArbitration: null,
        createLoading: false,
      });
    }
  };

  handleCreateArbitration = async () => {
    const { createArbitration, vin, arbReqType } = this.state;
    this.setState({
      createLoading: true,
    });
    if (vin && arbReqType) {
      if (!createArbitration) {
        const selectedArbitration = await AdminService.getArbitrationDetail(
          null,
          vin
        );
        if (selectedArbitration) {
          if (!selectedArbitration.vin) {
            this.setState({
              message: `Invalid VIN number entered. Please confirm that you have successfully purchased the Vehicle. 
                                        VIN was not purchased by the dealer and not available for arbitration.`,
              createArbitration: false,
              editArbitraiton: false,
              createLoading: false,
            });
          } else if (selectedArbitration.arbitrationId === 0) {
            this.setState({
              selectedArbitration,
              createArbitration: true,
              createLoading: false,
            });
          } else {
            this.setState({
              selectedArbitration,
              editArbitration: true,
              createLoading: false,
            });
          }
        }
      } else {
        this.setArbitrationTypes();
        this.setState({
          createArbitration: false,
          createLoading: false,
          vin: "",
          arbReqType: null,
        });
      }
    } else {
      this.setState({
        createLoading: false,
        message: "Please enter a vin and select a reason.",
      });
    }
  };

  setArbitrationTypes = async () => {
    const arbitrationTypes = await AdminService.getArbitrationTypes();
    if (arbitrationTypes) {
      Object.keys(arbitrationTypes).forEach(function (key) {
        if (arbitrationTypes[key] === "Unwind with Funds Sent")
          delete arbitrationTypes[key];
      });
      this.setState({
        arbitrationTypes,
      });
    } else {
      this.setState({
        errorMessage: "Could not retrieve arbtitration types.",
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      vin,
      arbReqType,
      vinSearch,
      arbitrationFromDate,
      arbitrationToDate,
      purchaseFromDate,
      purchaseToDate,
      isOpenArbitration,
      arbitrationData,
      editArbitration,
      selectedArbitration,
      arbitrationTypes,
      createArbitration,
      message,
      createLoading,
    } = this.state;
    const cellStyle = {
      maxWidth: "5rem",
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
      height: "2.3rem",
    };
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    const InputLabelProps = {
      style: {
        top: "-.8rem",
        left: ".25rem",
      },
    };
    return (
      <React.Fragment>
        {editArbitration && (
          <EditArbitration
            selectedArbitration={selectedArbitration}
            arbitrationTypes={arbitrationTypes}
            handleUpdateArbitration={this.handleUpdateArbitration}
            createArbitrationToggled={false}
            removeCancelledArbitration={this.removeCancelledArbitration}
          />
        )}
        {createArbitration ? (
          <EditArbitration
            selectedArbitration={selectedArbitration}
            arbitrationTypes={arbitrationTypes}
            handleUpdateArbitration={this.handleUpdateArbitration}
            handleCreateArbitration={this.handleCreateArbitration}
            createArbitrationToggled={true}
            arbReqType={arbReqType}
          />
        ) : null}
        {!editArbitration && !createArbitration ? (
          <div className={classes.root}>
            <div className={classes.topWrapper}>
              <div className={classes.createArbitration}>
                <Typography variant="h6" gutterBottom>
                  Create Arbitration
                </Typography>
                <div className={classes.vinWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>VIN</strong>
                  </Typography>
                  <TextField
                    id="vin"
                    placeholder="Required Field"
                    className={classes.textField}
                    value={vin}
                    onChange={this.handleVinChange}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="vin"
                    InputProps={{
                      ...InputProps,
                      inputProps: {
                        ...InputProps.inputProps,
                        maxLength: 17,
                      },
                    }}
                    required
                  />
                </div>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">
                    Arbitration Request (Select One):
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      onChange={this.handleChange("arbReqType")}
                      control={
                        <Checkbox
                          checked={arbReqType === "Concession"}
                          onChange={this.handleChange("arbReqType")}
                          value="Concession"
                          classes={{
                            root: classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Concession"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={arbReqType === "Unwind Purchase"}
                          onChange={this.handleChange("arbReqType")}
                          value="Unwind Purchase"
                          classes={{
                            root: classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Unwind Purchase"
                    />
                  </FormGroup>
                </FormControl>
                <div className={classes.buttonWrapper}>
                  <Button
                    id="clear_arbitration"
                    variant="contained"
                    onClick={this.clearCreateArbitration}
                    className={classes.button}
                    disabled={createLoading}
                  >
                    Clear
                  </Button>
                  <Button
                    id="create_arbitration"
                    variant="contained"
                    onClick={this.handleCreateArbitration}
                    className={classes.button}
                  >
                    {!createLoading ? (
                      "Create Arbitration"
                    ) : (
                      <Loading color={"#FFFFFF"} />
                    )}
                  </Button>
                </div>
              </div>
              <div className={classes.arbitrationSearch}>
                <Typography variant="h6" gutterBottom>
                  Arbitration Search
                </Typography>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Arbitration Date</strong>
                  </Typography>
                  <div className={classes.datePickerWrapper}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="arbitrationFromDate"
                        autoOk
                        variant="inline"
                        label="From"
                        value={arbitrationFromDate}
                        format="MM/dd/yyyy"
                        onChange={this.handleDateChange("arbitrationFromDate")}
                        className={classes.datePicker}
                        InputProps={InputProps}
                        InputLabelProps={InputLabelProps}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        variant="inline"
                        label="To"
                        value={arbitrationToDate}
                        format="MM/dd/yyyy"
                        onChange={this.handleDateChange("arbitrationToDate")}
                        className={classes.datePicker}
                        InputProps={InputProps}
                        InputLabelProps={InputLabelProps}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <Typography className={classes.fieldLabel}>
                    <strong>Purchase Date</strong>
                  </Typography>
                  <div className={classes.datePickerWrapper}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        variant="inline"
                        label="From"
                        value={purchaseFromDate}
                        format="MM/dd/yyyy"
                        onChange={this.handleDateChange("purchaseFromDate")}
                        className={classes.datePicker}
                        InputProps={InputProps}
                        InputLabelProps={InputLabelProps}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        variant="inline"
                        label="To"
                        value={purchaseToDate}
                        format="MM/dd/yyyy"
                        onChange={this.handleDateChange("purchaseToDate")}
                        className={classes.datePicker}
                        InputProps={InputProps}
                        InputLabelProps={InputLabelProps}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className={classes.vinSearchWrapper}>
                  <div className={classes.fieldWrapper}>
                    <Typography className={classes.fieldLabel}>
                      <strong>VIN Search</strong>
                    </Typography>
                    <TextField
                      id="vinSearch"
                      className={classes.textField}
                      value={vinSearch}
                      onChange={this.handleChange("vinSearch")}
                      margin="normal"
                      variant="filled"
                      autoComplete="off"
                      name="vinSearch"
                      InputProps={InputProps}
                    />
                  </div>
                  <div className={classes.buttonWrapper}>
                    <Button
                      id="clear_search_arbitration"
                      variant="contained"
                      onClick={this.clearSearchArbitration}
                      className={classes.button}
                    >
                      Clear
                    </Button>
                    <Button
                      id="search_arbitration"
                      variant="contained"
                      onClick={(evt) => this.handleArbitrationSearch()}
                      className={classes.button}
                      disabled={this.props.searchingArbitrations}
                    >
                      {this.props.searchingArbitrations ? (
                        <Loading />
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.bottomWrapper}>
              {arbitrationData && !this.props.searchingArbitrations ? (
                <MaterialTable
                  id="arbitration-table"
                  title={
                    <div className={classes.title}>
                      {isOpenArbitration ? (
                        <Typography>Open Arbitration</Typography>
                      ) : (
                        <Typography>Close Arbitration</Typography>
                      )}
                      <div className={classes.buttonWrapper}>
                        <Fab
                          id="toggle-btn"
                          variant="extended"
                          aria-label="overview"
                          className={classes.fab}
                          size="small"
                          onClick={(evt) => this.toggleArbitration(evt)}
                        >
                          <Typography className={classes.fabText}>
                            {isOpenArbitration
                              ? "Show Closed Arbitration"
                              : "Show Open Arbitration"}
                          </Typography>
                        </Fab>
                      </div>
                    </div>
                  }
                  columns={[
                    {
                      title: "VIN/CR",
                      field: "vin",
                      cellStyle: {
                        minWidth: "10rem",
                        textAlign: "center",
                        padding: 0,
                        fontSize: ".7rem",
                      },
                      render: (rowData) => (
                        <span
                          id={"arbitration-vin-" + rowData.vin}
                          onClick={
                            rowData.isCRAvailable
                              ? () =>
                                  this.handleConditionReport(
                                    rowData.inventoryId,
                                    "arbitrationData"
                                  )
                              : null
                          }
                          className={
                            rowData.isCRAvailable ? classes.clickableLink : null
                          }
                        >
                          {rowData.vin}
                        </span>
                      ),
                    },
                    {
                      title: "Year",
                      field: "year",
                      cellStyle,
                    },
                    {
                      title: "Model / Trim",
                      field: "model",
                      cellStyle,
                    },
                    {
                      title: "Arbitration Status",
                      field: "dealerFacingStatus",
                      cellStyle,
                      render: (rowData) => (
                        <span
                          id={
                            "arbitration-status-" +
                            rowData.vin +
                            "-" +
                            rowData.dealerFacingStatus
                          }
                          onClick={
                            !(
                              rowData.isUnwindPurchase ||
                              rowData.purchaseType === "Online-DB"
                            )
                              ? () =>
                                  this.handleUpdateArbitration(
                                    rowData.arbitrationId
                                  )
                              : null
                          }
                          className={
                            !(
                              rowData.isUnwindPurchase ||
                              rowData.purchaseType === "Online-DB"
                            )
                              ? classes.clickableLink
                              : null
                          }
                        >
                          {rowData.dealerFacingStatus}
                        </span>
                      ),
                    },
                    {
                      title: "Purchase Type",
                      field: "purchaseType",
                      cellStyle,
                    },
                    {
                      title: "Purchase Date",
                      field: "purchaseDate",
                      cellStyle,
                    },
                    {
                      title: "Purchase Price",
                      field: "purchasePrice",
                      cellStyle,
                      render: (rowData) =>
                        Number(rowData.purchasePrice).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        }),
                    },
                    {
                      title: "Arb File Date",
                      field: "dealerCalledDate",
                      cellStyle,
                    },
                    {
                      title: "Odometer",
                      field: "odometerReading",
                      cellStyle,
                      render: (rowData) =>
                        Number(rowData.odometerReading).toLocaleString(),
                    },
                  ]}
                  data={arbitrationData}
                  actions={[
                    (rowData) => ({
                      iconProps: { id: "actions" },
                      icon: "save",
                      tooltip: "Download Purchase Receipt",
                      onClick: (event, rowData) => {
                        this.handlePurchaseReceiptPdf(rowData.inventoryId);
                      },
                      hidden:
                        rowData.isUnwindPurchase ||
                        rowData.purchaseType === "Online-DB",
                    }),
                  ]}
                  options={{
                    headerStyle: {
                      backgroundColor: "#EEE",
                      textAlign: "center",
                      fontSize: ".7rem",
                      padding: 0,
                    },
                    showTitle: true,
                    sorting: true,
                    search: true,
                    paging: true,
                    maxBodyHeight: 500,
                    actionsColumnIndex: -1,
                  }}
                />
              ) : (
                <Loading />
              )}
            </div>
          </div>
        ) : null}
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          className={classes.errorSnackBar}
          open={message !== null}
          autoHideDuration={5000}
          onClose={() => this.setState({ message: null })}
        >
          <SnackbarContent className={classes.message} message={message} />
        </Snackbar>
      </React.Fragment>
    );
  }
}

ManageArbitration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
    storedArbitrations: state.ui.arbitrations,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(ManageArbitration));
