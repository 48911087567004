import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  Card,
  Select,
  Input,
  MenuItem,
  Typography,
  InputLabel,
} from "@material-ui/core";

import { transportquote } from "../services/UtilityServices";
import { formatPhoneNumber } from "../services/HelperServices";

import Loading from "../components/Loading";

const styles = (theme) => ({
  card: {
    margin: ".5rem",
    display: "flex",
    flexDirection: "column",
    height: "7rem",
    width: "98%",
    marginTop: "-.25rem",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
      height: "auto",
    },
  },
  cardTitle: {
    color: theme.palette.primary.white,
    fontWeight: "bold",
    backgroundColor: theme.palette.DDO.main,
    textAlign: "center",
    borderRadius: "0 0 .75rem .75rem",
  },
  selectWrapper: {
    marginLeft: "1rem",
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("md")]: {
      maxWidth: "8rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  selectRoot: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "8rem",
    },
  },
  lowerCardWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100%",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  textColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "1%",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: ".5rem",
      marginBottom: ".5rem",
    },
  },
  cardText: {
    fontSize: "76%",
    color: theme.palette.primary.gray,
  },
  boldedText: {
    fontWeight: "bold",
  },
});

export class DDOTransportQuoteTile extends PureComponent {
  state = {
    quote: null,
    location: {
      locationDTO: {
        addressDTO: {},
        communicationDTO: {},
      },
    },
    loading: true,
  };

  componentWillMount = () => {
    const { accountSetting } = this.props;
    this.getTransportQuote(accountSetting.custLocations[0]);
  };

  getTransportQuote = async (location) => {
    const { car, user } = this.props;
    try {
      const response = await transportquote(
        car.inventoryId,
        user.userId,
        car.saleEventItemId,
        location.locationDTO.addressDTO.zip,
        location.locationId
      );
      this.setState({
        quote: response.status !== "false" ? response[0] : response,
        location,
        loading: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  verifyQuotePrice = (quote) => {
    const { car } = this.props;
    if (car.programCode === "TAP") {
      return `$${quote.quoteWithPromo}`;
    } else {
      return quote.quote.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  };

  render() {
    const { classes, accountSetting } = this.props;
    const { location, quote, loading } = this.state;
    const { addressLine1, city, state, zip } =
      this.state.location.locationDTO.addressDTO;
    const { businessPhone, contactName } =
      this.state.location.locationDTO.communicationDTO;
    return (
      <Card className={classes.card}>
        <Typography className={classes.cardTitle}>
          Transportation Quote
        </Typography>
        {!loading ? (
          <div className={classes.lowerCardWrapper}>
            <div className={classes.selectWrapper}>
              <InputLabel htmlFor="location-helper">Destination</InputLabel>
              <Select
                id="locationSelect"
                value={location}
                classes={{ root: classes.selectRoot }}
                onChange={(evt) => this.getTransportQuote(evt.target.value)}
                input={<Input name="location" id="location-helper" />}
              >
                {accountSetting.custLocations.map((location) => {
                  return (
                    <MenuItem
                      id={location.locationId}
                      key={location.locationId}
                      value={location}
                    >
                      {location.locationDTO.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className={classes.textColumn}>
              <Typography className={classes.cardText}>
                <span className={classes.boldedText}>Address: </span>{" "}
                {` ${addressLine1}`}
              </Typography>
              <Typography className={classes.cardText}>
                <span className={classes.boldedText}>City: </span> {` ${city} `}
                <span className={classes.boldedText}>State: </span>{" "}
                {` ${state} `}
                <span className={classes.boldedText}>Zip: </span> {` ${zip}`}
              </Typography>
              <Typography className={classes.cardText}>
                <span className={classes.boldedText}>Contact:</span>
                {` ${contactName || "N/A"}`}
              </Typography>
              <Typography className={classes.cardText}>
                <span className={classes.boldedText}>Phone:</span>{" "}
                {` ${formatPhoneNumber(businessPhone)}`}
              </Typography>
            </div>
            {quote && quote.status !== "false" ? (
              <div className={classes.textColumn}>
                <Typography className={classes.cardText}>
                  <span className={classes.boldedText}>Transport Company:</span>{" "}
                  {quote.trasnportCompanyName}
                </Typography>
                <Typography className={classes.cardText}>
                  <span className={classes.boldedText}>Quote</span>{" "}
                  {quote.quote && this.verifyQuotePrice(quote)}
                </Typography>
                <Typography className={classes.cardText}>
                  <span className={classes.boldedText}>Delivery Estimate</span>{" "}
                  {`${quote.daysRequired} days`}
                </Typography>
              </div>
            ) : (
              <div>
                <Typography className={classes.cardText}>
                  <span className={classes.boldedText}>
                    {quote && quote.message}
                  </span>
                </Typography>
              </div>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(DDOTransportQuoteTile);
