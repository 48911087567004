import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Card, Button, Typography } from "@material-ui/core";

import { guarantee_payments } from "../actions/carActions";
import { guaranteePayments } from "../services/GroundingServices";
import {
  CANCEL,
  COLON,
  DESCRIPTION,
  DOLLAR_SIGN,
  DONE,
  GUARANTEE,
  GUARANTEE_REMAINING_PAYMENTS_TEXT_6,
  GUARANTEE_REMAINING_PAYMENTS_TEXT_5,
  GUARANTEE_REMAINING_PAYMENTS_TEXT_7,
  GUARANTEE_REMAINING_PAYMENTS_TEXT_8,
  GUARANTEE_REMANING_PAYMENTS_TEXT,
  GUARANTEE_REMANING_PAYMENTS_TEXT_2,
  GUARANTEE_REMANING_PAYMENTS_TEXT_3,
  GUARANTEE_REMANING_PAYMENTS_TEXT_4,
  SYSTEM_MESSAGE,
  VEHICLE_INFO,
  VIN,
} from "../constants/UILabel";

const styles = (theme) => ({
  bold: {
    fontWeight: "bold",
    margin: ".5rem",
  },
  value: {
    color: theme.palette.primary.redHighlight,
  },
  p: {
    margin: ".5rem",
    fontSize: ".8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.font.small,
      width: "16rem",
    },
  },
  card: {
    margin: ".5rem",
  },
  conditionReportCard: {
    margin: ".5rem",
    width: "40.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    margin: ".5rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      justifyContent: "initial",
    },
  },
  button: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "7rem",
    fontWeight: "bold",
  },
  redBoldedText: {
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
  },
});

export class GuaranteePayments extends Component {
  state = {
    errorMessage: "",
    showConfirmation: false,
  };

  handleGuarantee = async () => {
    const { user, car, dispatch, isBuyFlow, updateGuaranteePayment, ground } =
      this.props;
    const response = await guaranteePayments(
      user.userId,
      car.id ? car.id : car.inventoryId
    );
    if (response === "Success") {
      if (isBuyFlow) {
        if (!ground) {
          updateGuaranteePayment(
            car.vin ? car.vin : car.vehicleIdentificationNumber
          );
        }
        dispatch(guarantee_payments(car));
      }
      this.setState({
        showConfirmation: !this.state.showConfirmation,
      });
    } else {
      this.setState({
        errorMessage: response,
      });
    }
  };

  render() {
    const {
      car,
      classes,
      toggleGuaranteePayment,
      toggleGuaranteePaymentDone,
      isBuyFlow,
      fromConditionReport,
    } = this.props;
    const { showConfirmation } = this.state;
    let remainingPaymentAmount = car.outstandingPayments
      ? car.outstandingPayments
      : car.remainingPaymentAmount;
    if (!remainingPaymentAmount && remainingPaymentAmount !== 0) {
      if (car.remainingPaymentsAmt !== undefined) {
        remainingPaymentAmount = car.remainingPaymentsAmt;
      } else if (
        car.inventoryDTO !== undefined &&
        car.inventoryDTO.leaseInformationDTO !== undefined
      ) {
        remainingPaymentAmount =
          car.inventoryDTO.leaseInformationDTO.remainingPaymentAmount;
      } else {
        remainingPaymentAmount = null;
      }
    }
    return (
      <Card
        className={
          !fromConditionReport ? classes.card : classes.conditionReportCard
        }
      >
        <Typography className={classes.p}>
          <strong>{VEHICLE_INFO}</strong>
        </Typography>
        <Typography className={classes.p}>
          <strong>
            {VIN}
            {COLON}
          </strong>{" "}
          {car.vehicleIdentificationNumber}
        </Typography>
        <Typography className={classes.p}>
          <strong>{DESCRIPTION}</strong> {car.year} {car.make} {car.model}
        </Typography>
        {!showConfirmation ? (
          <div>
            {isBuyFlow !== undefined && isBuyFlow === true ? (
              <>
                <Typography className={classes.p}>
                  {GUARANTEE_REMANING_PAYMENTS_TEXT}{" "}
                  <span className={classes.redBoldedText}>
                    {DOLLAR_SIGN}
                    {remainingPaymentAmount &&
                      remainingPaymentAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </span>{" "}
                  {GUARANTEE_REMANING_PAYMENTS_TEXT_2}
                </Typography>
                <Typography className={classes.p}>
                  {GUARANTEE_REMANING_PAYMENTS_TEXT_3}
                </Typography>
                <div className={classes.buttonWrapper}>
                  <Button
                    className={classes.button}
                    id="cancelButton"
                    onClick={toggleGuaranteePayment}
                  >
                    {CANCEL}
                  </Button>
                  <Button
                    className={classes.button}
                    id="guaranteeButton"
                    onClick={() => this.handleGuarantee()}
                  >
                    {GUARANTEE}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Typography className={classes.p}>
                  <strong>{SYSTEM_MESSAGE}</strong>{" "}
                  {GUARANTEE_REMANING_PAYMENTS_TEXT_4}{" "}
                  <span className={classes.redBoldedText}>
                    {DOLLAR_SIGN}
                    {remainingPaymentAmount &&
                      remainingPaymentAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </span>
                  .
                </Typography>
                <Typography className={classes.p}>
                  {GUARANTEE_REMAINING_PAYMENTS_TEXT_5}
                </Typography>
                <Typography className={classes.p}>
                  {GUARANTEE_REMAINING_PAYMENTS_TEXT_6}{" "}
                  <span className={classes.redBoldedText}>
                    {DOLLAR_SIGN}
                    {remainingPaymentAmount &&
                      remainingPaymentAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </span>{" "}
                  {GUARANTEE_REMAINING_PAYMENTS_TEXT_7}
                </Typography>
                <div className={classes.buttonWrapper}>
                  <Button
                    className={classes.button}
                    id="cancelButton"
                    onClick={() => toggleGuaranteePayment(false)}
                  >
                    {CANCEL}
                  </Button>
                  <Button
                    className={classes.button}
                    id="guaranteeButton"
                    onClick={() => this.handleGuarantee()}
                  >
                    {GUARANTEE}
                  </Button>
                </div>
              </>
            )}
          </div>
        ) : (
          <div>
            <Typography className={classes.p}>
              <strong>{SYSTEM_MESSAGE}</strong> The total Remaining Payments of{" "}
              <span className={classes.redBoldedText}>
                {DOLLAR_SIGN}
                {remainingPaymentAmount.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>{" "}
              {GUARANTEE_REMAINING_PAYMENTS_TEXT_8}
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                id="doneButton"
                onClick={() =>
                  toggleGuaranteePaymentDone === undefined
                    ? toggleGuaranteePayment(true)
                    : toggleGuaranteePaymentDone()
                }
              >
                {DONE}
              </Button>
            </div>
          </div>
        )}
      </Card>
    );
  }
}

GuaranteePayments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(GuaranteePayments));
