const API_URL = process.env.REACT_APP_API_URL;
const { ungzip } = require("node-gzip");

export const getGAMDVehicles = async (dealerId, userId) => {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      dealerId,
      userId,
    }),
  };
  try {
    const response = await fetch(
      `${API_URL}/gamd/allvehiclesCompressed`,
      options
    );
    const jsonResponse = await response.json();
    if (jsonResponse.status && jsonResponse.status === "false") {
      return jsonResponse;
    }
    var buffer = new Buffer.from(jsonResponse.gzipResponse, "base64");
    const decompressedResponse = await ungzip(buffer);
    return JSON.parse(decompressedResponse.toString());
  } catch (err) {
    console.log(err);
  }
};

export const passVehicle = async (car, user, passReasons) => {
  const token = sessionStorage.getItem("token");
  const { dealerId, userId } = user;
  const {
    vehicleIdentificationNumber,
    residualAmount,
    grossPayoff,
    marketBasedPrice,
    remainingPayments,
    groundingDealerId,
    id,
    vin,
    inventoryId,
    remainingPaymentAmount,
    residualPayAmount,
  } = car;
  const {
    tireReasonValueChecked,
    selectedLFTire,
    selectedLRTire,
    selectedRFTire,
    selectedRRTire,
    alloyWheelDamaged,
    previouslyRepaired,
    windshieldReasonReported,
    odorReasonReported,
    missingEquipmentReasonReported,
    condOtherReasonReported,
    vehicleHistoryReasonReported,
    notRequiredForInventoryReasonReported,
    marketPriceHighReasonReported,
    damageNotAccountedReasonReported,
    remainingPaymentReasonReported,
    otherReasonReported,
    grossPayAmount,
  } = passReasons;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      dealerId: parseInt(dealerId, 10),
      inventoryId: id ? id : inventoryId,
      vin: vehicleIdentificationNumber ? vehicleIdentificationNumber : vin,
      userId: parseInt(userId, 10),
      groundingId: groundingDealerId,
      grossPayAmount: grossPayoff ? grossPayoff : grossPayAmount,
      residualPayAmount: residualAmount ? residualAmount : residualPayAmount,
      remainingPayments: remainingPayments
        ? remainingPayments
        : remainingPaymentAmount,
      marketBasedPrice: marketBasedPrice ? marketBasedPrice : null,
      tireReasonValueChecked,
      selectedLFTire,
      selectedLRTire,
      selectedRFTire,
      selectedRRTire,
      alloyWheelDamaged,
      previouslyRepaired,
      windshieldReasonReported,
      odorReasonReported,
      missingEquipmentReasonReported,
      condOtherReasonReported,
      vehicleHistoryReasonReported,
      notRequiredForInventoryReasonReported,
      marketPriceHighReasonReported,
      damageNotAccountedReasonReported,
      remainingPaymentReasonReported,
      otherReasonReported,
    }),
  };

  try {
    const response = await fetch(`${API_URL}/passvehicle/pass`, options);
    return response.json();
  } catch (err) {
    return { status: false, message: err.message };
  }
};
