import {
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_PASSWORD_SUCCESS,
  UPDATE_DEALER_EXCLUSIVITY,
} from "../actions/userActions";

export const userState = {
  userDetails: {
    firstName: "",
    lastName: "",
  },
  userRoles: [],
  timeZone: "",
  latitude: 0,
  longitude: 0,
  dealership: "",
  userId: "",
  datePattern: "",
  userStateId: 0,
  isForgotPassword: false,
  isAccountLocked: false,
  isDisabledUser: false,
  isDeleted: false,
  resetPassword: false,
  isPwdExpEmailSent: false,
  lastLoginTime: "",
  dealerAdmin: false,
  dealerAdminLogin: false,
  auctionAccessId: "",
  userTitle: "",
  registrationDate: "",
  region: "",
  state: "",
  emailId: "",
  loginId: "",
  dealerNumber: "",
  isOneTimeNotificationAccepted: false,
  dssNotificationMessage: "",
  allowedStoreDetails: {},
  pilotingEnabled: false,
  dlrExculsivityExtensionValue: 0,
};

export default (state = userState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      let newLoginState = { ...state };
      Object.keys(action.user).map((detail) => {
        return (newLoginState[detail] = action.user[detail]);
      });
      return newLoginState;
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPassword: false };
    case UPDATE_DEALER_EXCLUSIVITY:
      return {
        ...state,
        dlrExculsivityExtensionValue: action.dlrExculsivityExtensionValue,
      };
    case LOGOUT:
      sessionStorage.removeItem("token");
      window.localStorage.removeItem("url_carfax_microservices");
      window.localStorage.removeItem("theme");
      return userState;
    default:
      return state;
  }
};
