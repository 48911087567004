import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Moment from "react-moment";
import Viewer from "react-viewer";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  Snackbar,
  Typography,
} from "@material-ui/core";

import { EARLY_TERM_LABEL } from "../constants/UILabel";

import * as VehiclePurchaseServices from "../services/VehiclePurchaseServices";
import { getNotes } from "../services/NotesServices";
import { getDate, returnAccountType } from "../services/HelperServices";
import * as WatchListServices from "../services/WatchListServices";
import { getGAMDVehicles } from "../services/GAMDServices";
import * as userAccess from "../services/UserRoles";

import GAMDLPWEntryForm from "../components/GAMDLPWEntryForm";
import LCPEntryForm from "../components/LCPEntryForm";
import GuaranteePayments from "../components/GuaranteePayments";
import Notes from "../components/Notes";
import CarListTileExclusivity from "../components/CarListTileExclusivity";
import Loading from "../components/Loading";

import notesIcon from "../assets/notesIcon.svg";

import circledCheck from "../assets/circledCheck.svg";
import bidIcon from "../assets/bidIcon.svg";
import shoppingCartLarger from "../assets/shoppingCartLarger.svg";
import shoppingCartSmaller from "../assets/shoppingCartSmaller.svg";
import rotateRight from "../assets/rotateRight.svg";
import ReactSVG from "react-svg";
import watchlistIcon from "../assets/watchlistIcon.svg";
import activeWatchlistIcon from "../assets/activeWatchlistIcon.svg";
import DDOGroundedIcon from "../assets/DDOGroundedIcon.svg";

import {
  add_gamd_cars,
  select_car_for_purchase,
  select_pass_car,
  watchlist_count,
} from "../actions/carActions";

const styles = (theme) => ({
  card: {
    display: "flex",
    margin: ".5rem",
    borderRadius: 0,
    maxHeight: "11rem",
    width: "98%",
    position: "relative",
  },
  selectedCard: {
    display: "flex",
    margin: ".5rem",
    borderRadius: 0,
    maxHeight: "11rem",
    width: "98%",
    position: "relative",
    boxShadow: `0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.5)`,
    backgroundColor: theme.palette.light.gray,
  },
  cardWrapper: {
    display: "flex",
    position: "relative",
    alignItems: "flex-start",
    justifyContent: "left",
    flexDirection: "column",
    width: "11%",
    padding: 0,
  },
  cardTitle: {
    fontSize: "1rem",
    position: "absolute",
    width: "37rem",
    left: "2rem",
    display: "flex",
    flexDirection: "row",
  },
  selectedCardTitle: {
    fontSize: "1rem",
    position: "absolute",
    width: "37rem",
    fontWeight: "bold",
    left: "2rem",
    display: "flex",
    flexDirection: "row",
  },
  media: {
    width: "130%",
    height: "7rem",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      minWidth: 0,
      height: "5rem",
      width: "5rem",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  clickableMedia: {
    width: "130%",
    height: "7rem",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {
      margin: 0,
      minWidth: 0,
      height: "5rem",
      width: "5rem",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: ".5rem",
  },
  contentWrapper: {
    display: "flex",
    margin: ".5rem",
    position: "relative",
    cursor: "pointer",
    width: "76%",
    [theme.breakpoints.down("md")]: {
      width: "69%",
      marginRight: 0,
    },
  },
  textWrapper: {
    display: "flex",
    height: "85%",
    justifyContent: "space-around",
    position: "relative",
    top: "1.75rem",
  },
  textColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
  },
  textContainer: {
    width: "100%",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignContent: "space-between",
    height: "9rem",
  },
  smallButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
  smallButton: {
    height: "2.5rem",
    minWidth: 0,
  },
  smallButtonLabel: {
    marginLeft: "2%",
    marginRight: "2%",
    justifyContent: "initial",
    fontSize: ".6rem",
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    width: "7rem",
    position: "absolute",
    right: 0,
    height: "100%",
    [theme.breakpoints.down("md")]: {
      minWidth: "20%",
    },
  },
  buyButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100%",
    margin: 0,
  },
  buyNowButton: {
    height: "40%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100%",
    margin: 0,
    fill: "currentColor",
    [theme.breakpoints.down("md")]: {
      height: "50%",
    },
  },
  bidButton: {
    height: "40%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.DDO.main,
    width: "100%",
    margin: 0,
    fill: "currentColor",
    [theme.breakpoints.down("md")]: {
      height: "50%",
    },
  },
  passButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.pass,
    width: "100%",
    margin: 0,
  },
  guaranteedPaymentButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.guaranteedPayment,
    width: "100%",
    margin: 0,
  },
  cardText: {
    fontSize: "76%",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  cardTextRed: {
    fontSize: "76%",
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  boldedText: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
  vinText: {
    fontWeight: "bold",
    color: theme.palette.grounding.main,
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
  redBoldedText: {
    fontWeight: "bold",
    color: theme.palette.primary.redHighlight,
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  statusCardText: {
    position: "relative",
    bottom: "2%",
    fontSize: "76%",
    color: theme.palette.primary.gray,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  LPWLink: {},
  hasNotes: {
    color: theme.palette.primary.redHighlight,
    fill: "currentColor",
  },
  noteIcon: {
    fill: theme.palette.grounding.main,
  },
  carInfoIconWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "17%",
    minWidth: "4rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carInfoIcon: {
    width: "4rem",
    "&:hover": {
      opacity: ".5",
    },
  },
  errorSnackBar: {
    marginTop: "7rem",
  },
  linkColor: {
    color: theme.palette.button.main,
  },
  titleTextWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  titleText: {
    width: "100%",
    position: "absolute",
  },
  titleTextSmall: {
    width: "100%",
    position: "absolute",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  },
  titleTextLarge: {
    width: "100%",
    position: "absolute",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  coCarText: {
    width: "40%",
    fontSize: ".4rem",
    color: theme.palette.primary.redHighlight,
    zIndex: 2,
    position: "absolute",
    right: "0",
  },
  earlyTermLabel: {
    fontWeight: "bold",
    fontSize: "0.8rem",
    color: theme.palette.primary.black,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

const stockwaveIntegrationDiv = (car, user, conditionReport) => {
  const conditionReportInformation =
    conditionReport && conditionReport.vehicleInformation
      ? conditionReport.vehicleInformation
      : null;
  const data = `{
		VIN: ${car.vin ? car.vin : car.vehicleIdentificationNumber},
		Year: ${car.year},
		Make: ${car.make},
		Model: ${car.model},
		Trim: ${car.series},
		Engine: ${conditionReportInformation ? conditionReportInformation.Engine : ""},
		Drivetrain: ${
      conditionReportInformation ? conditionReportInformation.DriveTrain : ""
    },
		Transmission: ${
      conditionReportInformation ? conditionReportInformation.Transmission : ""
    },
		Odometer: ${
      conditionReportInformation ? conditionReportInformation.Odometer : ""
    },
		Odometer Unit of Measure: ${
      conditionReportInformation ? conditionReportInformation.ODOMETER_UOM : ""
    },
		Color: ${conditionReportInformation ? conditionReportInformation.ExtColor : ""},
		Interior Color: ${
      conditionReportInformation ? conditionReportInformation.IntColor : ""
    },
		Interior Type:,
		CR Score: ${conditionReport ? conditionReport.gradeScale : ""},
		CR URL:,
		VDP URL:,
		Lane:,
		Run:,
		inventoryId: ${car.id ? car.id : car.inventoryId},
		userId: ${user.userId},
		dealershipId: ${user.dealerId}
	}`;
  return (
    <div hidden="hidden" className="stockwaveVehicleInfo">
      {data}
    </div>
  );
};

const returnStatus = (status) => {
  const statuses = {
    DLR_MBP_PENDING: "Market Price Pending",
    DLR_INELIGIBLE_PUR: "Ineligible for Purchase",
    DLR_INSP_PENDING: "Inspection Pending",
    DLR_ACTION_NEEDED: "Action Needed",
    DLR_BUY_NOW: "Buy Now",
    DLR_ARBITRATION: "Arbitration",
    DLR_OFRD_DB_AUCTION: "Offered in DB Auction",
    DLR_BID_NOW: "Bid Now",
    DLR_BAL_INSP_PENDING: "Inspection Pending",
    DLR_SEC_AT_AUCT: "Secured at Auction",
    AWT_INSP: "Awaiting Inspection",
  };
  return statuses[status] || null;
};

const noPurchaseStatuses = [
  "DLR_INELIGIBLE_PUR",
  "DLR_ARBITRATION",
  "DLR_OFRD_DB_AUCTION",
  27252,
  27111,
];

let lpwCount = 1;

export class CarListTile extends Component {
  state = {
    LPWActiveCar: false,
    LCPActiveCar: false,
    guaranteePayment: false,
    openNote: false,
    notes: [],
    showCarfaxReport: false,
    viewerVisible: false,
    buyLoading: false,
    bidLoading: false,
    buyError: false,
    errorMessage: "An error has occured, please try again in a few minutes.",
    selectedProgram: {},
    isEarlyTermLease: false,
  };

  componentWillMount = () => {
    const { setTileRef, car } = this.props;
    this.myRef = createRef();
    this.getNotes();
    setTileRef(car, this.myRef);
  };

  componentDidMount = () => {
    this.updateEarlyTermLeaseFlag();
  };

  updateEarlyTermLeaseFlag = () => {
    const { inventoryDTO } = this.props.car;
    if (inventoryDTO) {
      this.setState({
        isEarlyTermLease: inventoryDTO.earlyTermNotification,
      });
    }
  };

  getNotes = async () => {
    const { car, user } = this.props;
    let notes = [];
    if (car.noteAvailable) {
      notes = await getNotes(user.dealerId, car.id);
    }
    this.setState({
      notes,
      programCertNum: car.programDTO ? car.programDTO[0] : null,
      programCertComments: "",
    });
  };

  toggleLPWCar = (program) => {
    this.setState({
      LPWActiveCar: !this.state.LPWActiveCar,
      selectedProgram: program,
    });
  };

  toggleLPWCarDone = async () => {
    const { user, dispatch } = this.props;
    dispatch(add_gamd_cars(await getGAMDVehicles(user.dealerId, user.userId)));
    this.setState({
      LPWActiveCar: !this.state.LPWActiveCar,
    });
  };

  toggleLCPCar = () => {
    this.setState({
      LCPActiveCar: !this.state.LCPActiveCar,
    });
  };

  toggleGuaranteePayment = (paymentGuaranteed) => {
    this.setState({
      guaranteePayment: !this.state.guaranteePayment,
    });
  };

  handleBuy = async () => {
    const { toggleBuy, dispatch, car } = this.props;
    const { inventoryDTO, saleEventItemId, accountType, inventoryId } =
      this.props.car;
    const { userId, dealerId, loginId } = this.props.user;
    if (accountType === "BALLOON") {
      dispatch(select_car_for_purchase(car));
      toggleBuy(car, false);
    } else {
      let buyDetails = await VehiclePurchaseServices.buy(
        inventoryDTO ? inventoryDTO.id : inventoryId,
        userId,
        dealerId,
        saleEventItemId,
        loginId
      );

      let leaseEndDate = inventoryDTO
        ? inventoryDTO.leaseInformationDTO.leaseEndDate
        : "";
      let adjustedLeaseEndDate = inventoryDTO
        ? inventoryDTO.leaseInformationDTO.adjustMaturityDate
        : "";

      buyDetails.leaseEndDate = adjustedLeaseEndDate
        ? adjustedLeaseEndDate
        : leaseEndDate;

      if (buyDetails.status !== "false") {
        // this is for transferring the inventory info on buydetail (i.e purchase information page)
        buyDetails.inventoryDTO = inventoryDTO;

        dispatch(
          select_car_for_purchase({
            ...buyDetails,
            discountedBuyNowPrice: car.discountedBuyNowPrice,
          })
        );

        toggleBuy(car, false);
      } else {
        this.setState({
          buyLoading: false,
          buyError: true,
        });
        console.log("Error occured while retrieving buy details", buyDetails);
      }
    }
  };

  handleBuyNow = async () => {
    const { toggleBuy, dispatch, car } = this.props;
    const { saleEventItemId } = this.props.car;
    const { userId, dealerId } = this.props.user;
    const buyDetails = await VehiclePurchaseServices.buyNow(
      userId,
      dealerId,
      saleEventItemId
    );
    if (buyDetails.status !== "false") {
      dispatch(
        select_car_for_purchase({
          ...buyDetails,
          discountedBuyNowPrice: car.discountedBuyNowPrice,
        })
      );
      toggleBuy(car, false);
    } else {
      this.setState({
        buyLoading: false,
        buyError: true,
        errorMessage: buyDetails.message,
      });
      console.log("Error occured while retrieving buy details", buyDetails);
    }
  };

  handlePass = () => {
    const { dispatch, car, togglePass } = this.props;
    dispatch(select_pass_car(car));
    togglePass(false, null);
  };

  toggleNote = () => {
    this.setState({
      openNote: !this.state.openNote,
    });
  };

  addNote = (newNote) => {
    const { accountSetting, car, user } = this.props;
    const fullNote = {
      inventoryId: car.id,
      dealerId: user.dealerId,
      note: newNote,
      needFollowUp: false,
      userId: user.userId,
      firstName: accountSetting.firstName,
      lastName: accountSetting.lastName,
      createdDate: getDate(),
    };
    this.setState({
      notes: [...this.state.notes, fullNote],
    });
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: !this.state.viewerVisible,
    });
  };

  returnExclusivePeriod = () => {
    const { car } = this.props;
    if (
      car.dealerDecisionCode === "DLR_EXCL_EXP" ||
      car.dealerDecisionCode === "NOTINT"
    ) {
      return "Expired";
    } else if (car.exclusivePeriod > 0 && car.isMBPApproved) {
      return car.exclusivePeriod;
    } else {
      return null;
    }
  };

  toggleWatchList = async () => {
    const { user, car, updateWatchlist, from } = this.props;
    let response;
    if (car.isInWatchList || (from && from === "watchlist")) {
      response = await WatchListServices.removeFromWatchList(user, car);
    } else {
      response = await WatchListServices.addToWatchList(user, car);
    }
    this.loadWatchlistData();
    if (response) {
      updateWatchlist(car.vin);
    }
  };

  loadWatchlistData = async () => {
    const { user, dispatch } = this.props;
    const watchListCars = await WatchListServices.getSavedWatchlist(user);
    if (watchListCars && watchListCars.length !== undefined) {
      dispatch(watchlist_count(watchListCars.length));
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  getMaximumMileage = (car) => {
    if (car.groundingMileage && car.inspectionMileage) {
      return car.groundingMileage >= car.inspectionMileage
        ? car.groundingMileage.toLocaleString()
        : car.inspectionMileage.toLocaleString();
    } else if (car.groundingMileage) {
      return car.groundingMileage.toLocaleString();
    } else {
      return car.odometer ? car.odometer.toLocaleString() : null;
    }
  };

  truncateTitle = () => {
    const { car, classes, from } = this.props;
    let titleText =
      car.year +
      " " +
      car.make +
      " " +
      car.model +
      " " +
      (car.series ? car.series : "");
    let finalTitleText =
      titleText.length <= 31 ? titleText : titleText.slice(0, 31) + "...";
    if (
      returnAccountType(car.accountType) === "Company Car" &&
      from &&
      from === "grounded"
    ) {
      return (
        <div>
          <div className={classes.titleTextLarge} title={titleText}>
            {titleText}
            {car.color ? car.color : null}
          </div>
          <div className={classes.titleTextSmall} title={titleText}>
            {finalTitleText}
            {car.color ? car.color : null}
            <div className="stockWaveLightBulb"></div>
          </div>
          <div className="stockWaveLightBulb"></div>
          {returnAccountType(car.accountType) === "Company Car" &&
          from &&
          from === "grounded" ? (
            <div className={classes.coCarText}>
              *Attention: This Company Car vehicle is only available to purchase
              at Residual or Payoff price. Once the inspection is completed, the
              vehicle will be available to purchase on a Buy Now/Bid Now venue.
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          <div className={classes.titleText} title={titleText}>
            {titleText}
            {car.color ? car.color : null}
            <div className="stockWaveLightBulb"></div>
          </div>
          <div className="stockWaveLightBulb"></div>
        </div>
      );
    }
  };

  render() {
    const {
      classes,
      car,
      selectCar,
      user,
      programCertComments,
      programCertNum,
      selectedCar,
      conditionReport,
      history,
      pageFrom,
      from,
      openNote,
      handleOpenNote,
      updateGuaranteePayment,
      selectConditionReport,
      handleBidOpen,
    } = this.props;
    const {
      LPWActiveCar,
      LCPActiveCar,
      notes,
      guaranteePayment,
      viewerVisible,
      buyLoading,
      buyError,
      errorMessage,
      selectedProgram,
      bidLoading,
    } = this.state;

    let remainingPaymentAmount = car.remainingPaymentAmount
      ? car.remainingPaymentAmount
      : car.remainingPaymentsAmt;
    if (!remainingPaymentAmount && remainingPaymentAmount !== 0) {
      remainingPaymentAmount = car.inventoryDTO
        ? car.inventoryDTO.leaseInformationDTO.remainingPaymentAmount
        : null;
    }

    if (!car.vehicleIdentificationNumber && car.vin) {
      car.vehicleIdentificationNumber = car.vin;
    }

    const {
      displayGuaranteePaymentsOnly,
      isInWatchList,
      conditionReportAvailable,
      isCRAvailable,
    } = this.props.car;

    return (
      <React.Fragment>
        {conditionReport && conditionReport.inspectionImageDTO ? (
          <Viewer
            visible={viewerVisible}
            zoomSpeed={0.3}
            noClose={true}
            onMaskClick={(evt) => this.setState({ viewerVisible: false })}
            onClose={() => this.setState({ viewerVisible: false })}
            images={conditionReport.inspectionImageDTO.map((image) => {
              return { src: image.imageUrl, alt: image.damageImageCaption };
            })}
          />
        ) : null}
        <Card
          ref={this.myRef}
          className={`${
            (car.vehicleIdentificationNumber &&
              car.vehicleIdentificationNumber ===
                selectedCar.vehicleIdentificationNumber) ||
            (car.vin && car.vin === selectedCar.vin)
              ? classes.selectedCard
              : classes.card
          } stockwaveVehicleRow`}
          id="carListTile"
          onClick={() => selectCar(car, user, "GAMD")}
        >
          {stockwaveIntegrationDiv(car, user, conditionReport)}
          <CardActions className={classes.cardWrapper}>
            <div className={classes.imageWrapper}>
              <img
                id="primaryImage"
                src={car.imageUrl ? car.imageUrl : car.imagePathFc}
                className={
                  conditionReport ? classes.clickableMedia : classes.media
                }
                alt={`${car.make} ${car.model}`}
                onClick={() => this.toggleImageViewer()}
              />
            </div>
            {userAccess.canPurchaseVehicles(user.userRoles) ? (
              <div className={classes.smallButtonWrapper}>
                {(from && from === "watchlist") ||
                (from && from === "advanceSearch") ||
                isInWatchList ? (
                  <Button
                    id="watchListBtn"
                    className={classes.smallButton}
                    onClick={() => this.toggleWatchList()}
                    title={
                      isInWatchList
                        ? "Remove from Watchlist"
                        : "Add to Watchlist"
                    }
                  >
                    <ReactSVG
                      src={
                        isInWatchList || from === "watchlist"
                          ? activeWatchlistIcon
                          : watchlistIcon
                      }
                    />
                  </Button>
                ) : null}

                {from === "advanceSearch" ? (
                  <ReactSVG
                    id="dealerLot"
                    src={DDOGroundedIcon}
                    svgStyle={{ height: "1.2rem" }}
                  />
                ) : null}
              </div>
            ) : null}
            <div className={classes.smallButtonWrapper}>
              <Button
                id="noteButton"
                className={classes.smallButton}
                onClick={() =>
                  openNote !== car.vehicleIdentificationNumber
                    ? handleOpenNote(car)
                    : handleOpenNote()
                }
                title="Notes"
              >
                <ReactSVG
                  src={notesIcon}
                  className={
                    notes && notes.length > 0
                      ? classes.hasNotes
                      : classes.noteIcon
                  }
                />
              </Button>
            </div>
          </CardActions>
          <div className={classes.contentWrapper}>
            <div className={classes.textContainer}>
              <Typography
                className={
                  (
                    car.vin
                      ? car.vin === selectedCar.vin
                      : car.vehicleIdentificationNumber ===
                        selectedCar.vehicleIdentificationNumber
                  )
                    ? classes.selectedCardTitle
                    : classes.cardTitle
                }
                style={{ width: "100%" }}
              >
                <span
                  className={classes.titleTextWrapper}
                  style={{ width: "67%" }}
                >
                  {this.truncateTitle()}
                </span>
                {this.state.isEarlyTermLease ? (
                  <span
                    className={classes.earlyTermLabel}
                    style={{ width: "33%" }}
                  >
                    {EARLY_TERM_LABEL}
                  </span>
                ) : null}
              </Typography>
              <div className={classes.textWrapper}>
                <CardContent
                  className={classes.textColumn}
                  style={{ width: "12rem" }}
                >
                  <CarListTileExclusivity
                    exclusivity={this.returnExclusivePeriod()}
                  />
                  <Typography className={classes.statusCardText}>
                    Status:{" "}
                    <span className={classes.boldedText}>
                      {!car.dlrFacingStatusName
                        ? returnStatus(car.dealerFacingStatus)
                        : car.dlrFacingStatusName}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent
                  className={classes.textColumn}
                  style={{ width: "11rem" }}
                >
                  <Typography className={classes.cardText}>
                    VIN:
                    <span
                      className={
                        conditionReportAvailable || isCRAvailable
                          ? classes.vinText
                          : classes.boldedText
                      }
                      onClick={
                        conditionReportAvailable || isCRAvailable
                          ? (evt) => {
                              evt.stopPropagation();
                              selectConditionReport(car, true);
                            }
                          : null
                      }
                    >
                      {car.vehicleIdentificationNumber
                        ? car.vehicleIdentificationNumber
                        : car.vin}
                    </span>
                  </Typography>
                  {
                    <Typography className={classes.cardText}>
                      Odometer:{" "}
                      <span className={classes.boldedText}>
                        {this.getMaximumMileage(car)}
                      </span>
                    </Typography>
                  }
                  <Typography className={classes.cardText}>
                    Account Type:{" "}
                    <span
                      className={
                        returnAccountType(car.accountType) === "Company Car"
                          ? classes.redBoldedText
                          : classes.boldedText
                      }
                    >
                      {returnAccountType(car.accountType)}
                    </span>
                  </Typography>
                  <Typography className={classes.cardTextRed}>
                    {`Grounding Date: `}
                    <Moment interval={0} format={"MM/DD/YYYY"}>
                      {car.groundingDate}
                    </Moment>
                  </Typography>
                </CardContent>
                {car.accountType !== "BALLOON" ? (
                  <CardContent
                    className={classes.textColumn}
                    style={{ width: "11rem", paddingBottom: 0 }}
                  >
                    {!car.discountedBuyNowPrice ||
                    !(
                      car.programDTO &&
                      car.programDTO[0] &&
                      car.programDTO[0].reducePricingProgram
                    ) ? (
                      <React.Fragment>
                        {!car.waivedPayments ? (
                          from &&
                          (from === "watchlist" || from === "advanceSearch") ? (
                            car.enableResidualPrice ? (
                              <Typography className={classes.cardText}>
                                Residual:{" "}
                                <span className={classes.boldedText}>
                                  {car.residualAmount &&
                                  car.residualAmount !== null
                                    ? car.residualAmount.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "USD",
                                        }
                                      )
                                    : null}
                                </span>
                              </Typography>
                            ) : null
                          ) : car.renderResidualAmount ? (
                            <Typography className={classes.cardText}>
                              Residual:{" "}
                              <span className={classes.boldedText}>
                                {car.residualAmount &&
                                car.residualAmount !== null
                                  ? car.residualAmount.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                  : car.residualPayAmount.toLocaleString(
                                      "en-US",
                                      { style: "currency", currency: "USD" }
                                    )}
                              </span>
                            </Typography>
                          ) : null
                        ) : null}
                        <Typography className={classes.cardText}>
                          Payoff:{" "}
                          <span className={classes.boldedText}>
                            {car.grossPayoff && car.grossPayoff !== null
                              ? car.grossPayoff.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : car.grossPayAmount
                              ? car.grossPayAmount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : null}
                          </span>
                        </Typography>
                        <Typography className={classes.cardText}>
                          Market:{" "}
                          <span className={classes.boldedText}>
                            {car.isMBPApproved
                              ? car.marketBasedPrice
                                ? car.marketBasedPrice.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                : null
                              : "Pending"}
                          </span>
                        </Typography>
                        {car.inventoryDTO &&
                        car.inventoryDTO.leaseInformationDTO.waivedPayments ? (
                          <>
                            {car.inventoryDTO.leaseInformationDTO
                              .waivedPayments > 0 ? (
                              <Typography className={classes.cardTextRed}>
                                Waived Pymt.:{" "}
                                {car.inventoryDTO.leaseInformationDTO.waivedPayments.toLocaleString(
                                  "en-US",
                                  { style: "currency", currency: "USD" }
                                )}
                              </Typography>
                            ) : null}
                            {car.guaranteedPayments > 0 ? (
                              <Typography className={classes.cardTextRed}>
                                Gua. Pymt.:{" "}
                                {car.guaranteedPayments.toLocaleString(
                                  "en-US",
                                  { style: "currency", currency: "USD" }
                                )}
                              </Typography>
                            ) : null}
                            <Typography className={classes.cardTextRed}>
                              Out. Pymt.:{" "}
                              {car.inventoryDTO.leaseInformationDTO.outstandingPayments.toLocaleString(
                                "en-US",
                                { style: "currency", currency: "USD" }
                              )}
                            </Typography>
                          </>
                        ) : null}
                        <Typography className={classes.cardTextRed}>
                          Rem. Pymt.:{" "}
                          {remainingPaymentAmount &&
                          remainingPaymentAmount !== null &&
                          ((displayGuaranteePaymentsOnly !== undefined &&
                            displayGuaranteePaymentsOnly) ||
                            (car.remainingPayGuaranteed !== undefined &&
                              !car.remainingPayGuaranteed))
                            ? remainingPaymentAmount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : null}
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <Typography className={classes.cardText}>
                        Discounted Price:{" "}
                        <span className={classes.boldedText}>
                          {car.discountedBuyNowPrice.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </span>
                      </Typography>
                    )}
                    {car.programDTO &&
                    car.programDTO[0] &&
                    car.programDTO[0].programCode === "LPW" ? (
                      <Typography className={classes.cardText}>
                        Promo:{" "}
                        {car.programDTO.map((program) => {
                          return (
                            <Link
                              id={`LPWLink` + lpwCount++}
                              key={`Key` + lpwCount++}
                              onClick={(evt) => this.toggleLPWCar(program)}
                              className={classes.linkColor}
                              title={
                                program.programName +
                                " Caption. You are eligible to participate in the " +
                                program.programName
                              }
                            >
                              {program.programCode}{" "}
                            </Link>
                          );
                        })}
                      </Typography>
                    ) : car.programDTO && car.programDTO[0] ? (
                      <Typography className={classes.cardText}>
                        Promo:{" "}
                        <Link
                          id="LCPLink"
                          onClick={(evt) => this.toggleLCPCar()}
                          className={classes.linkColor}
                          title={`${car.programDTO[0].programName}. You are eligible to participate in the ${car.programDTO[0].programCode} promotion.`}
                        >
                          {car.programDTO[0].programCode}
                        </Link>
                      </Typography>
                    ) : null}
                  </CardContent>
                ) : null}
              </div>
            </div>
          </div>
          <CardActions className={classes.cardActions}>
            {noPurchaseStatuses.indexOf(car.dealerFacingStatus) === -1 &&
            noPurchaseStatuses.indexOf(car.dlrFacingStatus) === -1 &&
            car.dealerFacingStatus !== "DLR_BUY_NOW" &&
            car.dealerFacingStatus !== "DLR_BID_NOW" &&
            car.availabilityStatus !== "NOT_PURCHASABLE" ? (
              <React.Fragment>
                {userAccess.canPurchaseVehicles(user.userRoles) ? (
                  <Button
                    id="buyButton"
                    className={classes.buyButton}
                    classes={{ label: classes.smallButtonLabel }}
                    size="small"
                    onClick={
                      !buyLoading
                        ? (evt) => {
                            evt.stopPropagation();
                            history.push(
                              pageFrom === "watchList"
                                ? "/watchlist"
                                : pageFrom === "advanceSearch"
                                ? "/advancesearch"
                                : "/GAMD"
                            );
                            this.setState({
                              buyLoading: true,
                            });
                            this.handleBuy(car);
                          }
                        : null
                    }
                  >
                    {!buyLoading ? (
                      <React.Fragment>
                        <ReactSVG
                          svgStyle={{ marginRight: ".5rem", height: "1.5rem" }}
                          src={shoppingCartLarger}
                        />
                        BUY
                      </React.Fragment>
                    ) : (
                      <Loading color={"#FFFFFF"} />
                    )}
                  </Button>
                ) : null}
                {!car.passedVehicle &&
                !car.vehiclePassed &&
                car.dealerDecisionCode !== "DLR_EXCL_EXP" ? (
                  <Button
                    id="passButton"
                    className={classes.passButton}
                    classes={{ label: classes.smallButtonLabel }}
                    size="small"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      history.push(
                        pageFrom === "watchList"
                          ? "/watchlist"
                          : pageFrom === "advanceSearch"
                          ? "/advancesearch"
                          : "/GAMD"
                      );
                      this.handlePass(null, car);
                    }}
                    disabled={buyLoading}
                  >
                    <ReactSVG
                      svgStyle={{ marginRight: ".5rem", height: "2rem" }}
                      src={rotateRight}
                    />
                    PASS
                  </Button>
                ) : null}
                {displayGuaranteePaymentsOnly ||
                (!car.remainingPayGuaranteed &&
                  car.remainingPaymentAmount > 0) ? (
                  <Button
                    id="guaranteeButton"
                    className={classes.guaranteedPaymentButton}
                    classes={{ label: classes.smallButtonLabel }}
                    size="small"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      this.toggleGuaranteePayment();
                    }}
                  >
                    <ReactSVG
                      svgStyle={{
                        position: "relative",
                        top: ".25rem",
                        marginRight: ".25rem",
                      }}
                      src={circledCheck}
                    />
                    GUARANTEE
                  </Button>
                ) : null}
              </React.Fragment>
            ) : null}
            {(car.dealerFacingStatus === "DLR_BUY_NOW" ||
              car.dealerFacingStatus === "DLR_BID_NOW") &&
            car.availabilityStatus !== "NOT_PURCHASABLE" &&
            userAccess.canPurchaseVehicles(user.userRoles) ? (
              <React.Fragment>
                <Button
                  id="buyNowButton"
                  className={classes.buyNowButton}
                  size="small"
                  disabled={car.isBiddingStarted}
                  onClick={
                    !buyLoading
                      ? (evt) => {
                          evt.stopPropagation();
                          history.push(
                            pageFrom === "watchList"
                              ? "/watchlist"
                              : pageFrom === "advanceSearch"
                              ? "/advancesearch"
                              : "/GAMD"
                          );
                          this.setState({
                            buyLoading: true,
                          });
                          this.handleBuyNow();
                        }
                      : null
                  }
                >
                  {!buyLoading ? (
                    <span className={classes.buttonIcon}>
                      <ReactSVG src={shoppingCartSmaller} />
                      Buy Now
                    </span>
                  ) : (
                    <Loading color={"#FFFFFF"} />
                  )}
                </Button>
                {from !== "lastChance" && (
                  <Button
                    id="bidButton"
                    className={classes.bidButton}
                    size="small"
                    disabled={!car.bidEnabled || !car.isBiddingStarted}
                    onClick={() => {
                      history.push(
                        pageFrom === "watchList"
                          ? "/watchlist"
                          : pageFrom === "advanceSearch"
                          ? "/advancesearch"
                          : "/GAMD"
                      );
                      this.setState({
                        bidLoading: true,
                      });
                      handleBidOpen(car);
                    }}
                  >
                    {!bidLoading ? (
                      <span className={classes.buttonIcon}>
                        <ReactSVG src={bidIcon} />
                        Bid
                      </span>
                    ) : (
                      <Loading color={"#FFFFFF"} />
                    )}
                  </Button>
                )}
              </React.Fragment>
            ) : null}
          </CardActions>
        </Card>
        {car.programDTO && car.programDTO[0] && LPWActiveCar ? (
          <GAMDLPWEntryForm
            car={car}
            onHandleChange={this.handleChange}
            programDTOInfo={selectedProgram}
            programCertNum={programCertNum}
            programCertComments={programCertComments}
            certVerifyMessage="This Payment Waiver Program will require you to guarantee remaining payments."
            toggleLPWCar={this.toggleLPWCar}
            toggleLPWCarDone={this.toggleLPWCarDone}
          />
        ) : null}
        {car.programDTO && car.programDTO[0] && LCPActiveCar ? (
          <LCPEntryForm
            car={car}
            programDTOInfo={car.programDTO[0]}
            toggleLCPCar={this.toggleLCPCar}
          />
        ) : null}
        {guaranteePayment ? (
          <GuaranteePayments
            car={car}
            toggleGuaranteePayment={this.toggleGuaranteePayment}
            user={user}
            isBuyFlow
            updateGuaranteePayment={updateGuaranteePayment}
          />
        ) : null}
        {openNote === car.vehicleIdentificationNumber ? (
          <Notes
            toggleNote={handleOpenNote}
            notes={notes}
            user={user}
            addNote={this.addNote}
            car={car}
            selected={
              car.vehicleIdentificationNumber ===
              selectedCar.vehicleIdentificationNumber
            }
          />
        ) : null}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={buyError}
          autoHideDuration={3000}
          onClose={() => this.setState({ buyError: false })}
          className={classes.errorSnackBar}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{errorMessage}</span>}
        />
      </React.Fragment>
    );
  }
}

CarListTile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CarListTile);
