const API_URL = process.env.REACT_APP_API_URL;

export async function login(username, password, recaptchaToken) {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  formData.append("recaptchaToken", recaptchaToken);
  const OPTIONS = {
    method: "POST",
    body: formData,
  };
  try {
    const response = await fetch(`${API_URL}/login/authenticate`, OPTIONS);
    if (response.status === 200) {
      const data = await response.json();

      // Don't set cookie if 200 response but not login success
      if (data.message === "Login success.") {
        sessionStorage.setItem("token", data.token);
      }
      return data;
    } else {
      return {
        status: response.status,
        message: "The server is having issues, please try again later.",
      };
    }
  } catch (err) {
    return {
      status: "false",
      message: err.message,
    };
  }
}

export async function loginSSO(username) {
  const OPTIONS = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${username}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/login/ssoLogin?ssoId=${username}`,
      OPTIONS
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return {
        status: response.status,
        message: "The server is having issues, please try again later.",
      };
    }
  } catch (err) {
    return {
      status: "false",
      message: err.message,
    };
  }
}

export async function loginBuyerView(username) {
  const OPTIONS = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${username}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/login/buyerview?ssoId=${username}`,
      OPTIONS
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return {
        status: response.status,
        message: "The server is having issues, please try again later.",
      };
    }
  } catch (err) {
    return {
      status: "false",
      message: err.message,
    };
  }
}

export async function loadAccountSetting(userId) {
  const token = sessionStorage.getItem("token");

  const OPTIONS = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const accountSettingsResp = await fetch(
      `${API_URL}/login/loadAccountSettings_1_2?userId=${userId}`,
      OPTIONS
    );
    return accountSettingsResp.json();
  } catch (err) {
    return null;
  }
}

export async function forgotPassword(username, emailId) {
  const token = sessionStorage.getItem("token");

  const OPTIONS = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/login/forgotpassword?username=${username}&emailId=${emailId}`,
      OPTIONS
    );
    return response.json();
  } catch (err) {
    return null;
  }
}

export async function updateOneTimeNotification(userId) {
  const token = sessionStorage.getItem("token");

  const OPTIONS = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/login/updateOneTimeNotification?userId=${userId}`,
      OPTIONS
    );
    return response.json();
  } catch (err) {
    return null;
  }
}

export async function passwordReset(userId, loginId, oldPassword, newPassword) {
  const token = sessionStorage.getItem("token");

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId: userId,
      userName: loginId,
      oldPassword: oldPassword,
      newPassword: newPassword,
      forgetPassword: false,
    }),
  };

  try {
    const response = await fetch(`${API_URL}/login/resetPassword`, options);
    return response.json();
  } catch (err) {
    return null;
  }
}

export async function updateAccountsettings(payload) {
  const token = sessionStorage.getItem("token");

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };

  try {
    const response = await fetch(
      `${API_URL}/login/updateAccountSettings_1_2`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
