import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactSVG from "react-svg";

import toyotaDealerDirect from "../assets/toyotaDealerDirect.svg";
import lexusDealerDirect from "../assets/lexusDealerDirect.png";

import { withStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  IconButton,
  InputBase,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Help from "@material-ui/icons/Help";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Menu from "@material-ui/icons/Menu";
import Home from "@material-ui/icons/Home";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import AppDownload from "@material-ui/icons/CloudDownload";
import Tooltip from "@material-ui/core/Tooltip";
import AccountIcon from "../assets/accountIcon.svg";
import LogoutCarfaxIcon from "../assets/logoutCarfaxIcon.png";
import LogoutTddIcon from "../assets/logoutTddIcon.png";
import PrivacyIcon from "../assets/privacyIcon.png";
import LegalIcon from "../assets/legalIcon.png";
import clock from "../assets/login/clock.svg";
import { isIOS, isSafari, isMobileSafari } from "react-device-detect";
import { redeemMobileCode } from "../services/MobileCodeRedemptionService";
import LoginForm from "../components/LoginForm";
import * as CarfaxService from "../services/CarfaxService";

import {
  set_nav_location,
  hide_mobile_nav_menu,
  mobile_app_download_instructions_modal,
} from "../actions/uiActions";
import {
  quick_search_key,
  remove_selected_mobile_car,
} from "../actions/carActions";
import * as LegalPrivacyContentServices from "../services/LegalPrivacyContentServices";
import * as userAccess from "../services/UserRoles";
import { TAB_LISTS } from "../constants/Constant";
const tddReactAppUrl = process.env.REACT_APP_TDD;
const lddReactAppUrl = process.env.REACT_APP_LDD;

const styles = (theme) => ({
  root: {
    zIndex: 3,
    color: theme.palette.primary.white,
    height: "3.5rem",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 0 2rem",
    position: "fixed",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      width: "100vw",
    },
  },
  loginRoot: {
    zIndex: 2,
    color: theme.palette.primary.white,
    height: "5.5rem",
    paddingTop: "1rem",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 0 2rem",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  toolbar: {
    maxHeight: "10%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      padding: 0,
      maxHeight: "100%",
      height: "20rem",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.gray,
    marginLeft: "1rem",
    marginRight: "20px",
    width: "27%",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      width: "80%",
      marginLeft: 0,
      marginRight: 0,
      top: "4rem",
      border: `1px solid ${theme.palette.primary.gray}`,
    },
  },
  searchOtherPage: {
    position: "relative",
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.gray,
    marginLeft: "1rem",
    marginRight: "20px",
    width: "27%",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchIcon: {
    width: "4.5rem",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userInfo: {
    fontSize: "small",
    lineHeight: ".3em",
    marginTop: "5px",
    marginBottom: "5px",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      lineHeight: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  userName: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  dealershipName: {
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    fontSize: theme.font.small,
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  inputInput: {
    paddingTop: ".5rem",
    paddingRight: ".5rem",
    paddingBottom: ".5rem",
    paddingLeft: "4rem",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "14rem",
    },
    "&::placeholder": {
      opacity: 1,
    },
  },
  iconButton: {
    padding: ".75rem !important",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  saveSearchLink: {
    color: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchIconButton: {
    zIndex: 2,
  },
  linkSeperator: {
    paddingRight: "0.1rem",
    paddingLeft: "0.4rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lexusDealerDirectLogoLoggedIn: {
    cursor: "pointer",
    width: "13%",
    [theme.breakpoints.down("md")]: {
      width: "18%",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: ".5rem",
      width: "50%",
      height: "2.5rem",
    },
  },
  lexusDealerDirectLogo: {
    width: "25%",
    marginTop: "1rem",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      height: "3rem",
    },
  },
  dealerDirectLogo: {
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      height: "3rem",
    },
  },
  mobileNavButton: {
    position: "absolute",
    left: "1rem",
    top: "1rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  ground: {
    color: theme.palette.grounding.main,
  },
  GAMD: {
    color: theme.palette.GAMD.main,
  },
  DDO: {
    color: theme.palette.DDO.main,
  },
  listItem: {
    minWidth: "auto",
    width: "5rem",
  },
  listItemMobility: {
    width: "10rem",
  },
  auctionListItem: {
    height: "2rem",
    marginTop: ".5rem",
  },
  userInfoHeader: {
    display: "flex",
    flexDirection: "column",
    paddingTop: ".4rem",
    marginBottom: "0rem",
    fontSize: "1rem",
  },
  mobilityUserText: {
    lineHeight: "1rem",
    fontSize: ".6rem",
  },
  saveSearchButton: {
    height: "1.6rem",
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    border: "1px solid white",
    fontWeight: "bold",
    color: theme.palette.primary.white,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#3c52b2",
    },
  },
  "@global": {
    ".MuiDrawer-root": {
      zIndex: "10000 !important",
    },
  },
});

export class Header extends Component {
  state = {
    right: false,
    searchKey: "",
    buyerView: false,
    ssoLoginView: false,
  };

  componentWillMount = () => {
    const { location } = this.props;
    if (location.pathname === "/buyerview") {
      this.setState({ buyerView: true });
    }
    if (location.pathname === "/ssoLogin") {
      this.setState({ ssoLoginView: true });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { user, location } = this.props;
    if (prevProps.user !== user) {
      if (user.loginId) {
        this.setState({ buyerView: false });
        this.setState({ ssoLoginView: false });
      }
    }
    if (prevProps.location.pathname !== location.pathname) {
      if (location.pathname === "/") {
        this.setState({ buyerView: false });
        this.setState({ ssoLoginView: false });
      }
    }
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  handleIOSSideListClick = async (index, user) => {
    const { history, dispatch } = this.props;

    switch (index) {
      case 0: // User Settings
        history.push("/accountsetting");
        break;
      case 1: // iOS download
        const mobileCode = await redeemMobileCode(
          user,
          user.franchise === "TBSG" ? "TDD" : "LDD"
        );

        const url =
          "https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=" +
          mobileCode;

        this.openDownloadAppInstructionModal(mobileCode, url);

        break;
      case 2: // Help
        history.push("/help");
        break;
      case 3: // Legal
        history.push("/legal");
        break;
      case 4: // Privacy
        history.push(
          LegalPrivacyContentServices.privacyRedirect(this.props.pageTheme)
        );
        break;
      default:
        // Logout when it is default
        dispatch({ type: "SIGNOUT_REQUEST" });
        sessionStorage.removeItem("token");
        window.localStorage.removeItem("url_carfax_microservices");
        history.push("/");
        break;
    }
  };

  handleCarfaxIOSSideListClick = async (index, user) => {
    const { history, dispatch } = this.props;
    switch (index) {
      case 0: // User Settings
        history.push("/accountsetting");
        break;
      case 1: // iOS download
        const mobileCode = await redeemMobileCode(
          user,
          user.franchise === "TBSG" ? "TDD" : "LDD"
        );
        const url =
          "https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=" +
          mobileCode;

        this.openDownloadAppInstructionModal(mobileCode, url);

        break;
      case 2: // Help
        history.push("/help");
        break;
      case 3: // Legal
        history.push("/legal");
        break;
      case 4: // Privacy
        history.push(
          LegalPrivacyContentServices.privacyRedirect(this.props.pageTheme)
        );
        break;
      case 5: // Log Out of CARFAX
        this.removeCarfaxCookies(this.props.pageTheme);
        break;
      default:
        // Logout when it is default
        dispatch({ type: "SIGNOUT_REQUEST" });
        sessionStorage.removeItem("token");
        window.localStorage.removeItem("url_carfax_microservices");
        history.push("/");
        break;
    }
  };

  openDownloadAppInstructionModal = (mobileCode, url) => {
    this.props.dispatch(
      mobile_app_download_instructions_modal({
        isHidden: false,
        mobileCode: mobileCode,
        url: url,
        isSeen: false,
      })
    );
  };

  handleSideListClick = (index) => {
    const { history, dispatch } = this.props;

    switch (index) {
      case 0: // User Settings
        history.push("/accountsetting");
        break;
      case 1: // Help
        history.push("/help");
        break;
      case 2: // Legal
        history.push("/legal");
        break;
      case 3: // Privacy
        history.push(
          LegalPrivacyContentServices.privacyRedirect(this.props.pageTheme)
        );
        break;
      default:
        // Logout when it is default
        dispatch({ type: "SIGNOUT_REQUEST" });
        sessionStorage.removeItem("token");
        window.localStorage.removeItem("url_carfax_microservices");
        history.push("/");
        break;
    }
  };

  handleCarfaxSideListClick = (index) => {
    const { history, dispatch } = this.props;

    switch (index) {
      case 0: // User Settings
        history.push("/accountsetting");
        break;
      case 1: // Help
        history.push("/help");
        break;
      case 2: // Legal
        history.push("/legal");
        break;
      case 3: // Privacy
        history.push(
          LegalPrivacyContentServices.privacyRedirect(this.props.pageTheme)
        );
        break;
      case 4: // Log Out of CARFAX
        this.removeCarfaxCookies(this.props.pageTheme);
        break;
      default:
        // Logout when it is default
        dispatch({ type: "SIGNOUT_REQUEST" });
        sessionStorage.removeItem("token");
        window.localStorage.removeItem("url_carfax_microservices");
        history.push("/");
        break;
    }
  };

  removeCarfaxCookies = (pageTheme) => {
    CarfaxService.carfaxReportLogout();
    var reactUrl;
    if (pageTheme === "Toyota") {
      reactUrl = tddReactAppUrl;
    } else {
      reactUrl = lddReactAppUrl;
    }
    var win = window.open(reactUrl + "/carfax/carfaxLogout.html", "_blank");
    setTimeout(function () {
      win.close();
    }, 5);
  };

  handleMobilitySideListClick = (index) => {
    const { history, dispatch } = this.props;

    switch (index) {
      case 0: // Home
        history.push("/mobilityHome");
        break;
      case 1: // Reports
        history.push("/mobilityReports");
        break;
      case 2: // Legal
        history.push("/legal");
        break;
      case 3: // Privacy
        history.push(
          LegalPrivacyContentServices.privacyRedirect(this.props.pageTheme)
        );
        break;
      default:
        // Logout when it is default
        dispatch({ type: "SIGNOUT_REQUEST" });
        sessionStorage.removeItem("token");
        window.localStorage.removeItem("url_carfax_microservices");
        history.push("/");
        break;
    }
  };

  handleCarfaxMobilitySideListClick = (index) => {
    const { history, dispatch } = this.props;

    switch (index) {
      case 0: // Home
        history.push("/mobilityHome");
        break;
      case 1: // Reports
        history.push("/mobilityReports");
        break;
      case 2: // Legal
        history.push("/legal");
        break;
      case 3: // Privacy
        history.push(
          LegalPrivacyContentServices.privacyRedirect(this.props.pageTheme)
        );
        break;
      case 4: // Log Out of CARFAX
        this.removeCarfaxCookies(this.props.pageTheme);
        break;
      default:
        // Logout when it is default
        dispatch({ type: "SIGNOUT_REQUEST" });
        sessionStorage.removeItem("token");
        window.localStorage.removeItem("url_carfax_microservices");
        history.push("/");
        break;
    }
  };

  redirectHome = () => {
    const { dispatch, history } = this.props;
    dispatch(set_nav_location("home"));
    history.push("/home");
  };

  quickSearch = () => {
    const { searchKey } = this.state;
    const { dispatch, history } = this.props;
    dispatch(quick_search_key(searchKey));
    this.handleChange("searchKey")({ target: { value: "" } });
    history.push("/advancesearch");
  };

  handleChange = (name) => (event) => {
    console.log(event);
    this.setState({
      [name]: event.target.value,
    });
  };

  advanceSearch = () => {
    const { history } = this.props;
    history.push("/advancesearch");
  };

  renderSearchHeader = () => {
    const { classes, user, navLocation } = this.props;
    return (
      <>
        <Button
          id="saveSearch"
          className={classes.saveSearchButton}
          variant="text"
          onClick={() => this.advanceSearch()}
        >
          Advanced Search
        </Button>
        <div className={classes.linkSeperator}> | </div>
        <div
          className={
            navLocation === "home" && !user.resetPassword
              ? classes.search
              : classes.searchOtherPage
          }
        >
          <div className={classes.searchIcon}>
            <IconButton
              id={"quickSearchIcon"}
              onClick={() => this.quickSearch()}
              className={classes.searchIconButton}
            >
              <SearchIcon />
            </IconButton>
          </div>
          <InputBase
            id={"quickSearchInput"}
            placeholder="Year, Make, Model, VIN, Series, or Color"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={this.state.searchKey}
            onChange={this.handleChange("searchKey")}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                this.quickSearch();
              }
            }}
          />
        </div>
      </>
    );
  };

  renderUserInfo = () => {
    const { classes, user } = this.props;
    return (
      <>
        <div className={classes.userInfoHeader}>
          <div>
            {user.userDetails.split(",")[0].split(":")[1].trim()}{" "}
            {user.userDetails.split(",")[1].split(":")[1].trim()}
          </div>
          <div className={classes.mobilityUserText}>Mobility User</div>
        </div>
      </>
    );
  };

  render() {
    const { buyerView, ssoLoginView } = this.state;
    const { externalConditionReportView } = this.props;
    if (externalConditionReportView) {
      return null;
    }
    const token = sessionStorage.getItem("token");
    const userId = window.localStorage.getItem("userID");
    const refresh_token = window.localStorage.getItem("refresh_token");

    const {
      classes,
      user,
      history,
      accountSetting,
      pageTheme,
      mobileCarInformation,
      dispatch,
    } = this.props;
    const { stringSale1, stringSale2 } = this.props.auctionTimes;

    const tabList = TAB_LISTS.tabList;
    const tabList_carfax = TAB_LISTS.tabList_carfax;
    const iOsTabList = TAB_LISTS.iOsTabList;
    const iOsTabList_carfax = TAB_LISTS.iOsTabList_carfax;
    const mobilityTabList = TAB_LISTS.mobilityTabList;
    const mobilityTabList_carfax = TAB_LISTS.mobilityTabList_carfax;

    const sideList = (
      <div className={classes.list}>
        <List>
          {userId && refresh_token
            ? tabList_carfax.map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => this.handleCarfaxSideListClick(index)}
                  id={`header${index}`}
                >
                  <ListItemIcon classes={{ root: classes.listItem }}>
                    <>
                      {index === 0 ? <AccountCircle /> : null}
                      {index === 1 ? <Help /> : null}
                      {index === 2 ? (
                        <img
                          src={LegalIcon}
                          alt="LegalIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 3 ? (
                        <img
                          src={PrivacyIcon}
                          alt="privacyIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 4 ? (
                        <img
                          src={LogoutCarfaxIcon}
                          alt="LogoutCarfaxIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 5 ? (
                        <img
                          src={LogoutTddIcon}
                          alt="LogoutTddIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                    </>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))
            : tabList.map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => this.handleSideListClick(index)}
                  id={`header${index}`}
                >
                  <ListItemIcon classes={{ root: classes.listItem }}>
                    <>
                      {index === 0 ? <AccountCircle /> : null}
                      {index === 1 ? <Help /> : null}
                      {index === 2 ? (
                        <img
                          src={LegalIcon}
                          alt="privacyIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 3 ? (
                        <img
                          src={PrivacyIcon}
                          alt="privacyIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 4 ? (
                        <img
                          src={LogoutTddIcon}
                          alt="LogoutTddIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                    </>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
        </List>
        <Divider />
        <ListItem classes={{ root: classes.auctionListItem }}>
          <ListItemIcon>
            <ReactSVG src={clock} svgStyle={{ width: "1.5rem" }} />
          </ListItemIcon>
          <ListItemText primary={`Online Auction: ${stringSale1}`} />
        </ListItem>
        <ListItem classes={{ root: classes.auctionListItem }}>
          <ListItemIcon>
            <ReactSVG src={clock} svgStyle={{ width: "1.5rem" }} />
          </ListItemIcon>
          <ListItemText primary={`Cross Line Auction: ${stringSale2}`} />
        </ListItem>
      </div>
    );

    const iOsSideList = (
      <div className={classes.list}>
        <List>
          {userId && refresh_token
            ? iOsTabList_carfax.map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => this.handleCarfaxIOSSideListClick(index, user)}
                  id={`header${index}`}
                >
                  <ListItemIcon classes={{ root: classes.listItem }}>
                    <>
                      {index === 0 ? <AccountCircle /> : null}
                      {index === 1 ? <AppDownload /> : null}
                      {index === 2 ? <Help /> : null}
                      {index === 3 ? (
                        <img
                          src={LegalIcon}
                          alt="LegalIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 4 ? (
                        <img
                          src={PrivacyIcon}
                          alt="privacyIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 5 ? (
                        <img
                          src={LogoutCarfaxIcon}
                          alt="LogoutCarfaxIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 6 ? (
                        <img
                          src={LogoutTddIcon}
                          alt="LogoutTddIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                    </>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))
            : iOsTabList.map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => this.handleIOSSideListClick(index, user)}
                  id={`header${index}`}
                >
                  <ListItemIcon classes={{ root: classes.listItem }}>
                    <>
                      {index === 0 ? <AccountCircle /> : null}
                      {index === 1 ? <AppDownload /> : null}
                      {index === 2 ? <Help /> : null}
                      {index === 3 ? (
                        <img
                          src={LegalIcon}
                          alt="privacyIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 4 ? (
                        <img
                          src={PrivacyIcon}
                          alt="privacyIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 5 ? (
                        <img
                          src={LogoutTddIcon}
                          alt="LogoutTddIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                    </>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
        </List>
        <Divider />
        <ListItem classes={{ root: classes.auctionListItem }}>
          <ListItemIcon>
            <ReactSVG src={clock} svgStyle={{ width: "1.5rem" }} />
          </ListItemIcon>
          <ListItemText primary={`Online Auction: ${stringSale1}`} />
        </ListItem>
        <ListItem classes={{ root: classes.auctionListItem }}>
          <ListItemIcon>
            <ReactSVG src={clock} svgStyle={{ width: "1.5rem" }} />
          </ListItemIcon>
          <ListItemText primary={`Cross Line Auction: ${stringSale2}`} />
        </ListItem>
      </div>
    );

    const mobilitySideList = (
      <div className={classes.list}>
        <List>
          {userId && refresh_token
            ? mobilityTabList_carfax.map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => this.handleCarfaxMobilitySideListClick(index)}
                  id={`header${index}`}
                >
                  <ListItemIcon classes={{ root: classes.listItem }}>
                    <>
                      {index === 0 ? <Home /> : null}
                      {index === 1 ? <LibraryBooks /> : null}
                      {index === 2 ? (
                        <img
                          src={LegalIcon}
                          alt="LegalIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 3 ? (
                        <img
                          src={PrivacyIcon}
                          alt="privacyIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 4 ? (
                        <img
                          src={LogoutCarfaxIcon}
                          alt="LogoutCarfaxIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 5 ? (
                        <img
                          src={LogoutTddIcon}
                          alt="LogoutTddIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                    </>
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    className={classes.listItemMobility}
                  />
                </ListItem>
              ))
            : mobilityTabList.map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => this.handleMobilitySideListClick(index)}
                  id={`header${index}`}
                >
                  <ListItemIcon classes={{ root: classes.listItem }}>
                    <>
                      {index === 0 ? <Home /> : null}
                      {index === 1 ? <LibraryBooks /> : null}
                      {index === 2 ? (
                        <img
                          src={LegalIcon}
                          alt="LegalIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 3 ? (
                        <img
                          src={PrivacyIcon}
                          alt="privacyIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                      {index === 4 ? (
                        <img
                          src={LogoutTddIcon}
                          alt="LogoutTddIcon"
                          style={{ width: "37px" }}
                        />
                      ) : null}
                    </>
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    className={classes.listItemMobility}
                  />
                </ListItem>
              ))}
        </List>
      </div>
    );

    return (
      <React.Fragment>
        {!buyerView && !ssoLoginView && (
          <div
            position="static"
            className={token ? classes.root : classes.loginRoot}
          >
            <Toolbar className={classes.toolbar}>
              {token ? (
                <React.Fragment>
                  {mobileCarInformation ? (
                    <ArrowBack
                      id="headerBackArrow"
                      className={classes.mobileNavButton}
                      onClick={() => {
                        dispatch(hide_mobile_nav_menu(false));
                        dispatch(remove_selected_mobile_car());
                      }}
                    />
                  ) : (
                    <Menu
                      id="headerHamburger"
                      className={classes.mobileNavButton}
                      onClick={this.toggleDrawer("right", true)}
                    />
                  )}
                </React.Fragment>
              ) : null}
              {pageTheme !== "Lexus" ? (
                <ReactSVG
                  className={classes.dealerDirectLogo}
                  src={toyotaDealerDirect}
                  alt={"dealer direct logo"}
                  onClick={(evt) => {
                    token ? this.redirectHome() : history.replace("/");
                  }}
                  svgStyle={
                    !token
                      ? { cursor: "pointer", width: "100%" }
                      : { cursor: "pointer", width: "100%", height: "3rem" }
                  }
                />
              ) : (
                <img
                  src={lexusDealerDirect}
                  alt="dealerDirectLogo"
                  className={
                    !token
                      ? classes.lexusDealerDirectLogo
                      : classes.lexusDealerDirectLogoLoggedIn
                  }
                  onClick={(evt) => {
                    token ? this.redirectHome() : history.replace("/");
                  }}
                />
              )}
              <div className={classes.grow} />
              {token ? (
                userAccess.isRideshareUser(user.userRoles) ? (
                  this.renderUserInfo()
                ) : (
                  this.renderSearchHeader()
                )
              ) : (
                <LoginForm history={history} />
              )}
              {token ? (
                <div id="userInfo" className={classes.userInfo}>
                  <p className={classes.userName}>
                    {accountSetting.firstName} {accountSetting.lastName}
                  </p>
                  {accountSetting.custLocations ? (
                    <p className={classes.dealershipName}>
                      {accountSetting.custLocations[0].locationDTO.name}
                    </p>
                  ) : null}
                </div>
              ) : null}
              {token ? (
                <>
                  <Tooltip title="Click here to see the Dealer Direct Navigation Menu">
                    <IconButton
                      className={classes.iconButton}
                      onClick={this.toggleDrawer("right", true)}
                      color="inherit"
                    >
                      <ReactSVG
                        src={AccountIcon}
                        svgStyle={{
                          height: "2.5rem",
                          position: "relative",
                          top: ".25rem",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <SwipeableDrawer
                    anchor="right"
                    open={this.state.right}
                    onClose={this.toggleDrawer("right", false)}
                    onOpen={this.toggleDrawer("right", true)}
                  >
                    <div
                      tabIndex={0}
                      role="button"
                      onClick={this.toggleDrawer("right", false)}
                      onKeyDown={this.toggleDrawer("right", false)}
                    >
                      {userAccess.isRideshareUser(user.userRoles)
                        ? mobilitySideList
                        : isIOS && (isSafari || isMobileSafari)
                        ? iOsSideList
                        : sideList}
                    </div>
                  </SwipeableDrawer>
                </>
              ) : null}
            </Toolbar>
          </div>
        )}
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
    pageTheme: state.ui.pageTheme,
    navLocation: state.ui.navLocation,
    mobileCarInformation: state.cars.mobileCarInformation,
    auctionTimes: state.ui.auctionTimes,
    externalConditionReportView: state.ui.externalConditionReportView,
  };
};
export default connect(mapStateToProps)(withRouter(withStyles(styles)(Header)));
