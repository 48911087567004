import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
} from "@material-ui/core";

import Loading from "../components/Loading";
import AutoSearchEntry from "../components/AutoSearchEntry";
import * as AdvanceSearchServices from "../services/AdvanceSearchServices";

const styles = (theme) => ({
  savedSearchButtonWrapper: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  button: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.primary.gray,
    width: "8rem",
    fontWeight: "bold",
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.primary.gray,
      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.gray,
        "&:active": {
          backgroundColor: theme.palette.primary.gray,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: ".5rem",
      width: "7rem",
      height: "2.5rem",
      "&:hover": {
        backgroundColor: theme.palette.primary.gray,
        "@media (hover: none)": {
          backgroundColor: theme.palette.primary.gray,
          "&:active": {
            backgroundColor: theme.palette.primary.gray,
          },
        },
      },
    },
  },
  saveSearchLink: {
    margin: "0.5rem",
  },
  tableRowFull: {
    width: "97%",
  },
  autoSearchWrapper: {
    maxWidth: "100%",
    padding: "0.4rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      paddingTop: "3.5rem",
    },
  },
  muitablecellRoot: {
    padding: "0.4rem",
    paddingRight: "0.6rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  tableRowHeader: {
    backgroundColor: theme.palette.headerColor.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  headerStyle: {
    margin: "0.5rem",
    fontSize: "1.0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  "@global": {
    ".MuiTable-root": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
});

export class AutoSearch extends Component {
  state = {
    savedSearch: [],
    pageLoading: true,
    showSavedSearchView: false,
    selectedEntry: [],
  };

  componentWillMount = () => {
    this.loadSearch();
  };

  loadSearch = async () => {
    const { user } = this.props;
    const savedSearchInfo = await AdvanceSearchServices.getSavedSearch(user);
    this.setState({
      savedSearch: savedSearchInfo !== null ? savedSearchInfo : [],
      pageLoading: false,
    });
  };

  deleteSearch = async () => {
    const { selectedEntry } = this.state;
    const { user } = this.props;
    let savedSearchInfo;
    if (selectedEntry.length > 0) {
      savedSearchInfo = await AdvanceSearchServices.deleteSavedSearch(
        selectedEntry,
        user
      );
      this.setState({
        savedSearch: savedSearchInfo,
        selectedEntry: [],
      });
    }
  };

  toggleEntryCheck = (searchId) => {
    if (this.state.selectedEntry.includes(searchId)) {
      this.setState({
        selectedEntry: this.state.selectedEntry.filter(
          (value) => value !== searchId
        ),
      });
    } else {
      this.setState({
        selectedEntry: [...this.state.selectedEntry, searchId],
      });
    }
  };

  render() {
    const { pageLoading, savedSearch, selectedEntry } = this.state;
    const { classes, toggleAutoSearch, toggleSearchCount, toggleSearchEdit } =
      this.props;
    return (
      <React.Fragment>
        {pageLoading ? (
          <Loading />
        ) : (
          <div className={classes.autoSearchWrapper}>
            <Typography variant="h6" className={classes.headerStyle}>
              AutoSearch looks for your vehicles 24/7. Results will be sent to
              you by email. See Admin to change.
            </Typography>
            <Table>
              <TableBody key={"body"}>
                <TableRow key={"head"} className={classes.tableRowHeader}>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>Criteria</strong>
                  </TableCell>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>Results</strong>
                  </TableCell>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>E-mail</strong>
                  </TableCell>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>Delete</strong>
                  </TableCell>
                </TableRow>
                {savedSearch.length > 0 ? (
                  savedSearch.map((info) => (
                    <>
                      <AutoSearchEntry
                        key={info.searchId}
                        info={info}
                        toggleEntryCheck={this.toggleEntryCheck}
                        toggleSearchCount={toggleSearchCount}
                        toggleSearchEdit={toggleSearchEdit}
                      />
                    </>
                  ))
                ) : (
                  <TableRow key={"noSearch"} className={classes.tableRowFull}>
                    <TableCell colSpan={5} className={classes.tableCell}>
                      No Saved Search.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <div className={classes.savedSearchButtonWrapper}>
              <Button
                id="close"
                className={classes.button}
                onClick={() => toggleAutoSearch()}
              >
                Close
              </Button>
              <Button
                id="delete"
                className={classes.button}
                onClick={() => this.deleteSearch()}
                disabled={selectedEntry.length > 0 ? false : true}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AutoSearch));
