const awsUrl = process.env.REACT_APP_WCM_CONTENT;

export async function helpPageContent() {
  try {
    const response = await fetch(`${awsUrl}/lexusHelpContent.txt`);
    return response.json();
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function resourcePageContent() {
  try {
    const response = await fetch(`${awsUrl}/lexusResourcesContent.txt`);
    return response.json();
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function homePageContent() {
  try {
    const response = await fetch(`${awsUrl}/lexusContent.txt`);
    return response.json();
  } catch (err) {
    console.log(err);
    return null;
  }
}
