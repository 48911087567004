import React, { Component } from "react";
import PropTypes from "prop-types";
import Viewer from "react-viewer";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

import Moment from "react-moment";

import CarReport from "../components/CarReport";
import AutoCheckReport from "../components/AutoCheckReport";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import { returnAccountType, returnAutoGrade } from "../services/HelperServices";

import ReactSVG from "react-svg";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import carfaxLogo from "../assets/carfaxLogo.svg";
import autoCheckLogo from "../assets/autoCheckLogo.png";

import {
  MODEL_NUMBER,
  ACCOUNT_TYPE,
  KEYS,
  REMAINING_PAYMENTS,
  PAYOFF_AMOUNT,
  BALLOON,
  MARKET_BASED_PRICE,
  GROUNDING_MILEAGE,
  GROUNDING_DATE,
  PAYMENT_GUARANTEED,
  GROUNDING_DEALER,
  GROUNDER_NAME,
  PAYMENT_WAIVER,
  ELIGIBLE,
  NOT_ELIGIBLE,
  LPW,
  TRANSPORTATION_ORDERED,
  TRANSPORTATION_STATUS,
  GRADE_CONDITION,
  EWT,
  INSPECTION_COMPLETION_DATE,
  INSPECTION_MILEAGE,
  NOT_INSPECTED_REASON,
  SOLD_DATE,
  PURCHASING_DEALER,
  PURCHASE_TYPE,
  GROSS_PURCHASE_AMOUNT,
  CARFAX,
  CLOSE,
  LEASE_TYPE,
  RESIDUAL,
  VIN,
} from "../constants/UILabel";

const styles = (theme) => ({
  conditionReport: {
    overflowY: "auto",
    height: "100%",
    border: `3px solid ${theme.palette.primary.gray}`,
    [theme.breakpoints.down("md")]: {
      margin: 0,
      height: "100%",
      marginTop: ".5rem",
    },
  },
  imageWrapper: {
    display: "flex",
    maxHeight: "13rem",
    backgroundColor: theme.palette.secondary.gray,
    borderBottom: `3px solid ${theme.palette.primary.gray}`,
    flexDirection: "column",
    overflow: "hidden",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
  topImageWrapper: {
    display: "flex",
    height: "9rem",
    margin: ".5rem",
    justifyContent: "center",
  },
  topRightImageWrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "4%",
    justifyContent: "space-evenly",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  imageCarousel: {
    height: "3rem",
    display: "flex",
    marginLeft: ".5rem",
    marginBottom: ".25rem",
    marginRight: ".75rem",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-around",
  },
  leftNav: {
    height: "100%",
    display: "flex",
    position: "absolute",
    zIndex: 2,
    left: "-.8rem",
    backgroundColor: "rgb(0,0,0,.05)",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,.1)",
    },
    cursor: "pointer",
  },
  rightNav: {
    height: "100%",
    position: "absolute",
    display: "flex",
    zIndex: 2,
    right: "-1rem",
    backgroundColor: "rgb(0,0,0,.05)",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,.1)",
    },
    cursor: "pointer",
  },
  image: {
    minWidth: "3rem",
    maxWidth: "47%",
    maxHeight: "4rem",
    cursor: "pointer",
  },
  largeImage: {
    width: "100%",
    cursor: "pointer",
    maxWidth: "12rem",
    "&:hover": {
      opacity: ".5",
    },
  },
  carouselImage: {
    height: "2.5rem",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "24%",
    },
  },
  table: {
    height: "100%",
  },
  row: {
    height: "2rem",
  },
  darkCell: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.light.gray,
    padding: 0,
    fontSize: theme.font.small,
    paddingLeft: ".5rem",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  lightCell: {
    color: theme.palette.primary.gray,
    backgroundColor: theme.palette.light.gray,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: ".5rem",
    "&:last-child": {
      paddingRight: 0,
    },
    fontSize: theme.font.small,
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  modelCell: {
    color: theme.palette.primary.gray,
    backgroundColor: theme.palette.light.gray,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: ".5rem",
    fontSize: theme.font.small,
    "&:last-child": {
      paddingRight: 0,
    },
    display: "flex",
    height: "2rem",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: ".5",
    },
    fontSize: theme.font.small,
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  unClickableLink: {
    fontWeight: "bold",
    fontSize: theme.font.small,
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  carInfoIconWrapper: {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: theme.palette.light.gray,
    height: "2rem",
    borderBottom: `2px solid ${theme.palette.primary.gray}`,
  },
  carInfoIcon: {
    width: "45%",
    height: "2rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
});

export class FullVehicleInformation extends Component {
  state = {
    keys: null,
    imageIndex: 0,
    carouselStartIndex: 0,
    showCarfaxReport: false,
    showAutoCheckReport: false,
    showCarfaxSnackBar: false,
  };

  getKeys = () => {
    const { conditionReport } = this.props;
    this.setState({
      keys: conditionReport.vehicleInformation.Keys,
    });
  };

  changeImage = (index) => {
    this.setState({
      imageIndex: index,
    });
  };
  componentDidMount = () => {
    const { conditionReport } = this.props;
    if (conditionReport) {
      this.getKeys();
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    const { showCarfaxReport, showAutoCheckReport } = this.state;
    const { conditionReport } = this.props;
    if (conditionReport && conditionReport !== prevProps.conditionReport) {
      this.getKeys();
    }
    if (
      showCarfaxReport !== prevState.showCarfaxReport ||
      showAutoCheckReport !== prevState.showAutoCheckReport
    ) {
      this.setState({
        showCarfaxReport: false,
        showAutoCheckReport: false,
      });
    }
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: !this.state.viewerVisible,
    });
  };

  navigateImages = (nav) => {
    const { conditionReport } = this.props;
    if (nav === "back") {
      if (this.state.carouselStartIndex === 0) {
        this.setState({
          carouselStartIndex: conditionReport.inspectionImageDTO.length - 4,
        });
      } else {
        this.setState({
          carouselStartIndex: this.state.carouselStartIndex - 1,
        });
      }
    } else {
      if (
        conditionReport.inspectionImageDTO.length < 4 ||
        this.state.carouselStartIndex ===
          conditionReport.inspectionImageDTO.length - 4
      ) {
        this.setState({
          carouselStartIndex: 0,
        });
      } else {
        this.setState({
          carouselStartIndex: this.state.carouselStartIndex + 1,
        });
      }
    }
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  showAutoCheckReport = () => {
    this.setState({
      showAutoCheckReport: !this.state.showAutoCheckReport,
    });
  };
  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  render() {
    const {
      classes,
      car,
      conditionReport,
      toggleFullConditionReport,
      selectedVehicleDetails,
      user,
    } = this.props;
    let remainingPaymentAmount = car.remainingPaymentAmount
      ? car.remainingPaymentAmount
      : car.remainingPaymentsAmt;
    if (!remainingPaymentAmount && remainingPaymentAmount !== 0) {
      remainingPaymentAmount = car.inventoryDTO
        ? car.inventoryDTO.leaseInformationDTO.remainingPaymentAmount
        : null;
    }
    const {
      keys,
      imageIndex,
      viewerVisible,
      carouselStartIndex,
      showCarfaxReport,
      showAutoCheckReport,
      showCarfaxSnackBar,
    } = this.state;
    return (
      <React.Fragment>
        <div className={classes.conditionReport}>
          {conditionReport && conditionReport.inspectionImageDTO ? (
            <Viewer
              activeIndex={imageIndex}
              visible={viewerVisible}
              zoomSpeed={0.3}
              noClose={true}
              onMaskClick={(evt) => this.setState({ viewerVisible: false })}
              onClose={() => this.setState({ viewerVisible: false })}
              images={conditionReport.inspectionImageDTO.map((image) => {
                return { src: image.imageUrl, alt: image.damageImageCaption };
              })}
            />
          ) : null}
          {conditionReport && conditionReport.inspectionImageDTO ? (
            <div className={classes.imageWrapper}>
              <div className={classes.topImageWrapper}>
                <img
                  id="largeImage"
                  className={classes.largeImage}
                  src={conditionReport.inspectionImageDTO[imageIndex].imageUrl}
                  alt=""
                  onClick={() => {
                    this.toggleImageViewer();
                  }}
                />
              </div>
              <div className={classes.imageCarousel}>
                <div
                  id="leftNav"
                  className={classes.leftNav}
                  onClick={(evt) => this.navigateImages("back")}
                >
                  <ArrowLeft className={classes.navIcon} />
                </div>
                {conditionReport.inspectionImageDTO
                  .slice(carouselStartIndex, carouselStartIndex + 4)
                  .map((image) => {
                    return (
                      <img
                        id={`image${image.sequenceNumber}`}
                        className={classes.carouselImage}
                        src={image.imageUrl}
                        alt={image.sequenceNumber}
                        key={image.sequenceNumber}
                        onClick={(evt) =>
                          this.changeImage(image.sequenceNumber - 1)
                        }
                      />
                    );
                  })}
                <div
                  id="rightNav"
                  className={classes.rightNav}
                  onClick={(evt) => this.navigateImages()}
                >
                  <ArrowRight className={classes.navIcon} />
                </div>
              </div>
            </div>
          ) : null}
          <div className={classes.carInfoIconWrapper}>
            <img
              className={classes.carInfoIcon}
              src={autoCheckLogo}
              onClick={() => this.showAutoCheckReport()}
              alt="autoCheckIcon"
            />
            <ReactSVG
              id="carfaxIcon"
              className={classes.carInfoIcon}
              svgStyle={{ width: "100%", position: "relative", top: ".60rem" }}
              src={carfaxLogo}
              onClick={() => this.showCarfaxReport()}
            />
          </div>
          <Table className={classes.table}>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>{VIN}</TableCell>
                <TableCell className={classes.lightCell}>
                  <Typography
                    id="toggleConditionReport"
                    className={
                      conditionReport
                        ? classes.clickableLink
                        : classes.unClickableLink
                    }
                    onClick={
                      conditionReport
                        ? () => toggleFullConditionReport(car)
                        : null
                    }
                  >
                    {car.vehicleIdentificationNumber
                      ? car.vehicleIdentificationNumber
                      : car.vin}
                  </Typography>
                </TableCell>
              </TableRow>
              {conditionReport ? (
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>
                    {MODEL_NUMBER}
                  </TableCell>
                  <TableCell className={classes.modelCell}>
                    {conditionReport.vehicleInformation["Model#"]}
                  </TableCell>
                </TableRow>
              ) : null}
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {ACCOUNT_TYPE}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {returnAccountType(car.accountType)}
                </TableCell>
              </TableRow>
              {conditionReport ? (
                <React.Fragment>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>{KEYS}</TableCell>
                    <TableCell className={classes.lightCell}>{keys}</TableCell>
                  </TableRow>
                </React.Fragment>
              ) : null}
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {REMAINING_PAYMENTS}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {remainingPaymentAmount &&
                  remainingPaymentAmount !== null &&
                  ((!car.remainingPayGuaranteed && car.isInWatchList) ||
                    car.displayGuaranteePaymentsOnly ||
                    (car.inventoryDTO &&
                      car.inventoryDTO.leaseInformationDTO.waivedPayments))
                    ? remainingPaymentAmount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : null}
                </TableCell>
              </TableRow>
              {car.accountType !== BALLOON ? (
                <React.Fragment>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>
                      {RESIDUAL}
                    </TableCell>
                    <TableCell className={classes.lightCell}>
                      {car.residualAmount && car.residualAmount !== null
                        ? car.residualAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : null}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>
                      {PAYOFF_AMOUNT}
                    </TableCell>
                    <TableCell className={classes.lightCell}>
                      {car.grossPayoff && car.grossPayoff !== null
                        ? car.grossPayoff.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : car.grossPayAmount
                        ? car.grossPayAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : null}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>
                      {MARKET_BASED_PRICE}
                    </TableCell>
                    <TableCell className={classes.lightCell}>
                      {car.marketBasedPrice && car.marketBasedPrice !== null
                        ? car.marketBasedPrice.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : null}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ) : null}
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {GROUNDING_MILEAGE}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.groundingMileage
                    ? car.groundingMileage.toLocaleString()
                    : car.odometer
                    ? car.odometer.toLocaleString()
                    : null}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {GROUNDING_DATE}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  <Moment interval={0} format={"MM/DD/YYYY"}>
                    {car.groundingDate}
                  </Moment>
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {PAYMENT_GUARANTEED}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.paymentGuaranteed ? "Yes" : "No"}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>{LEASE_TYPE}</TableCell>
                <TableCell className={classes.lightCell}>
                  {car.inventoryDTO &&
                  car.inventoryDTO.leaseInformationDTO.leaseType
                    ? car.inventoryDTO.leaseInformationDTO.leaseType
                    : CLOSE}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {GROUNDING_DEALER}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.groundingDealer && !car.isInWatchList
                    ? car.groundingDealer
                    : car.groundingDealer
                    ? car.locationName
                    : null}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {GROUNDER_NAME}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {selectedVehicleDetails.inventoryDTO
                    ? selectedVehicleDetails.inventoryDTO.grounder
                    : car.groundingDealerName}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {PAYMENT_WAIVER}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.programDTO &&
                  car.programDTO[0] &&
                  car.programDTO[0].programCode === LPW
                    ? ELIGIBLE
                    : NOT_ELIGIBLE}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {TRANSPORTATION_ORDERED}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.transportationMethods ? "Yes" : "No"}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {TRANSPORTATION_STATUS}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.transportationStatus || ""}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {GRADE_CONDITION}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {returnAutoGrade(car)}
                </TableCell>
              </TableRow>
              {conditionReport ? (
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>{EWT}</TableCell>
                  <TableCell className={classes.lightCell}>
                    {conditionReport.vehicleConditions[0]
                      .excessWearAndTearSubTotal
                      ? conditionReport.vehicleConditions[0].excessWearAndTearSubTotal.toLocaleString(
                          "en-US",
                          { style: "currency", currency: "USD" }
                        )
                      : null}
                  </TableCell>
                </TableRow>
              ) : null}
              {conditionReport ? (
                <React.Fragment>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>
                      {INSPECTION_COMPLETION_DATE}
                    </TableCell>
                    <TableCell className={classes.lightCell}>
                      {conditionReport.inspectionDate}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>
                      {INSPECTION_MILEAGE}
                    </TableCell>
                    <TableCell className={classes.lightCell}>
                      {conditionReport.vehicleInformation.Odometer}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ) : null}
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {NOT_INSPECTED_REASON}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.notInspReason || ""}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>{SOLD_DATE}</TableCell>
                <TableCell className={classes.lightCell}>
                  {car.purchaseDate}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {PURCHASING_DEALER}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.purchasingDealer}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {PURCHASE_TYPE}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.purchaseType}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  {GROSS_PURCHASE_AMOUNT}
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.purchaseAmountWithoutFeesAndRemainingPayment}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        {showCarfaxReport ? (
          <CarReport
            vin={
              car.vehicleIdentificationNumber
                ? car.vehicleIdentificationNumber
                : car.vin
            }
            reportType={CARFAX}
            showCarfaxReport={this.showCarfaxReport}
            showNoDataVin={this.showNoDataVin}
            user={user}
          />
        ) : null}

        {showCarfaxSnackBar ? (
          <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
        ) : null}
        {showAutoCheckReport ? (
          <AutoCheckReport
            vin={
              car.vehicleIdentificationNumber
                ? car.vehicleIdentificationNumber
                : car.vin
            }
            reportType={CARFAX}
            showAutoCheckReport={this.showAutoCheckReport}
            user={user}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

FullVehicleInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullVehicleInformation);
