import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
    fontSize: ".65rem",
  },
  progress: {
    margin: ".5rem",
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
});
export class MyInspectionReportTable extends Component {
  formatInspectionDate(inspectionDate) {
    return moment(inspectionDate).tz("America/New_York").format("MM/DD/YYYY");
  }

  render() {
    const { classes, data, toggleConditionReport } = this.props;
    const cellStyle = {
      maxWidth: "5rem",
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
    };

    return (
      <div id="materialTable" className={classes.root}>
        <MaterialTable
          columns={[
            {
              title: "Inspection Request Date",
              cellStyle,
              field: "inspRequestedDate",
              render: (rowData) =>
                this.formatInspectionDate(rowData.inspRequestedDate),
            },
            {
              title: "Year",
              field: "year",
              cellStyle,
            },
            {
              title: "VIN/CR/Model/Trim",
              field: "model",
              cellStyle,
              render: (rowData) => (
                <div>
                  {rowData.model} {rowData.trim} <br />
                  <span
                    onClick={
                      rowData.cravailable
                        ? () =>
                            toggleConditionReport(
                              rowData.inventoryId,
                              "inspectionData"
                            )
                        : null
                    }
                    className={
                      rowData.cravailable ? classes.clickableLink : null
                    }
                  >
                    {rowData.vin}
                  </span>
                </div>
              ),
            },
            {
              title: "Color",
              field: "color",
              cellStyle,
            },
            {
              title: "ODO",
              cellStyle,
              field: "odometerReading",
              render: (rowData) =>
                rowData.odometerReading
                  ? Number(rowData.odometerReading).toLocaleString()
                  : null,
            },
            {
              title: "Inspection Company",
              field: "inspectionCompany",
              cellStyle,
            },
            {
              title: "Inspection Scheduled Date",
              cellStyle,
              field: "inspScheduledDate",
              render: (rowData) =>
                rowData.inspScheduledDate
                  ? this.formatInspectionDate(rowData.inspScheduledDate)
                  : "",
            },
            {
              title: "Inspection Complete Date",
              cellStyle,
              field: "inspCompleteDate",
              render: (rowData) =>
                rowData.inspCompleteDate
                  ? this.formatInspectionDate(rowData.inspCompleteDate)
                  : "",
            },
            {
              title: "Not Inspected Reason",
              field: "notInspectionReason",
              cellStyle,
            },
          ]}
          data={data}
          options={{
            headerStyle: {
              backgroundColor: "#EEE",
              textAlign: "center",
              padding: ".5rem",
            },
            showTitle: false,
            sorting: true,
            search: true,
            paging: true,
            maxBodyHeight: 400,
          }}
        />
      </div>
    );
  }
}

MyInspectionReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyInspectionReportTable);
