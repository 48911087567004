import React, { Component } from "react";
import { connect } from "react-redux";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = (theme) => ({
  errorSnackBar: {
    marginTop: "7rem",
  },
});

export class CarfaxNoDataVin extends Component {
  render() {
    const { classes, hideCarFaxNoData } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={true}
          autoHideDuration={2000}
          className={classes.errorSnackBar}
          onClose={() => hideCarFaxNoData()}
        >
          <SnackbarContent message="CARFAX does not have any history on this VIN but the VIN is valid" />
        </Snackbar>
      </div>
    );
  }
}

CarfaxNoDataVin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(CarfaxNoDataVin));
