import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import ConditionReport from "../components/ConditionReport";
import FullVehicleInformation from "../components/FullVehicleInformation";
import DDOFullVehicleInformation from "../components/DDOFullVehicleInformation";
import Loading from "../components/Loading";
import EmptyListPlaceholder from "../components/EmptyListPlaceholder";
import PassVehicle from "../components/PassVehicle";
import BuyVehicle from "../components/BuyVehicle";
import BidVehicle from "../components/BidVehicle";
import CarListTile from "../components/CarListTile";
import DDOCarListTile from "../components/DDOCarListTile";
import MobileCarListTile from "../components/MobileCarListTile";
import MobileDDOCarListTile from "../components/MobileDDOCarListTile";
import MobileVehicleInformation from "../components/MobileVehicleInformation";
import MobileDDOVehicleInformation from "../components/MobileDDOVehicleInformation";
import MobilePurchaseButtons from "../components/MobilePurchaseButtons";

import * as ConditionReportService from "../services/ConditionReportService";
import { getVehicleBidInformation } from "../services/BidServices";
import * as VehiclePurchaseServices from "../services/VehiclePurchaseServices";

import {
  select_car_for_purchase,
  add_watchlist_condition_report,
  select_mobile_car,
} from "../actions/carActions";
import { hide_mobile_nav_menu } from "../actions/uiActions";

const styles = (theme) => ({
  watchListCardListWrapper: {
    display: "flex",
    height: "100%",
    maxWidth: "100vw",
    width: "100vw",
  },
  watchListCardListLeft: {
    backgroundColor: theme.palette.secondary.gray,
    margin: ".5rem",
    padding: ".2rem",
    width: "75%",
    display: "block",
    height: "100%",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
    },
  },
  watchListCardListLeftWhite: {
    margin: ".5rem",
    padding: ".2rem",
    width: "100%",
    display: "block",
  },
  watchListCardListCardWrapper: {
    paddingBottom: ".5rem",
  },
  watchListCardListRight: {
    padding: "0.5rem",
    position: "relative",
    overflow: "inherit",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      padding: 0,
    },
  },
  scrollbar236: {
    position: "relative",
    overflow: "inherit",
    width: "98%",
    height: "100%",
  },
  scrollContainer: {
    height: "100%",
    overflow: "auto",
    width: "100%",
  },
  desktopCardList: {
    display: "flex",
    height: "94%",
    [theme.breakpoints.down("md")]: {
      height: "87%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileCardList: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    paddingBottom: "6rem",
    backgroundColor: theme.palette.secondary.gray,
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  rightWrapper: {
    width: "25%",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      width: "27%",
    },
  },
});

export class WatchlistCardList extends Component {
  state = {
    selectedCar: null,
    cars: {},
    pageloading: true,
    conditionReport: null,
    carFrom: null,
    showConditionReport: false,
    carsWithCR: {},
    currentAuction: "",
    saleEventItemIds: [],
    allCar: [],
    clientId: "",
    openNote: null,
    openTransport: null,
    vehicleInformationLoading: false,
  };

  componentWillMount = () => {
    this.refs = {};
  };

  componentDidUpdate = async (prevProps) => {
    const {
      displayedCars,
      watchListConditionReports,
      dispatch,
      user,
      toggleLoading,
      openBid,
      passVehicle,
      buyVehicle,
      ddoBuyVehicle,
      getConnectionToAmeService,
    } = this.props;
    const { selectedCar } = this.state;
    const saleEventItemIds = [];
    if (prevProps.displayedCars !== displayedCars) {
      if (displayedCars.length > 0) {
        let conditionReport = watchListConditionReports[displayedCars[0].vin]
          ? watchListConditionReports[displayedCars[0].vin]
          : null;
        if (
          displayedCars[0].isCRAvailable &&
          !watchListConditionReports[
            displayedCars[0].vin
              ? displayedCars[0].vin
              : displayedCars[0].vehicleIdentificationNumber
          ]
        ) {
          conditionReport =
            await ConditionReportService.getSingleConditionReport(
              displayedCars[0].inventoryId,
              user.userId,
              user.dealerId
            );
          dispatch(
            add_watchlist_condition_report(
              conditionReport,
              `watchListConditionReports`
            )
          );
        }
        displayedCars.map((items) =>
          saleEventItemIds.push(items.saleEventItemId)
        );
        this.setState({
          saleEventItemIds,
          allCar: displayedCars,
          carsWithCR: displayedCars.filter((car) => car.inspectionId),
          conditionReport,
          showConditionReport: true,
          carFrom: displayedCars[0].isCRAvailable ? "DDO" : "GAMD",
          selectedCar: displayedCars[0],
        });
        getConnectionToAmeService();
      } else {
        this.setState({
          saleEventItemIds: [],
          allCar: [],
          carsWithCR: {},
          conditionReport: null,
          showConditionReport: false,
          carFrom: null,
          selectedCar: {},
        });
      }
      toggleLoading(false);
    }
    if (openBid !== prevProps.openBid && !openBid && selectedCar) {
      this.scrollToCar(selectedCar);
    }
    if (passVehicle !== prevProps.passVehicle && !passVehicle && selectedCar) {
      this.scrollToCar(selectedCar);
    }
    if (buyVehicle !== prevProps.buyVehicle && !buyVehicle && selectedCar) {
      this.scrollToCar(selectedCar);
    }
    if (
      ddoBuyVehicle !== prevProps.ddoBuyVehicle &&
      !ddoBuyVehicle &&
      selectedCar
    ) {
      this.scrollToCar(selectedCar);
    }
  };

  handleOpenNote = (car) => {
    const { openNote } = this.state;
    if (
      car &&
      ((car.vin && car.vin !== openNote) ||
        (car.vehicleIdentificationNumber &&
          car.vehicleIdentificationNumber !== openNote))
    ) {
      this.setState({
        openNote: car.vin ? car.vin : car.vehicleIdentificationNumber,
        openTransport: null,
      });
    } else {
      this.setState({
        openNote: null,
      });
    }
  };

  handleTransportToggle = (car) => {
    const { openTransport } = this.state;
    if (openTransport !== car.vin) {
      this.setState({
        openTransport: car.vin,
        openNote: null,
      });
    } else {
      this.setState({
        openTransport: null,
      });
    }
  };

  toggleConditionReport = (selectedCar, conditionReport, from) => {
    this.setState({
      selectedCar: selectedCar,
      conditionReport: conditionReport,
      carFrom: from,
      showConditionReport: !this.state.showConditionReport
        ? true
        : this.state.showConditionReport,
    });
  };

  updateWatchlist = async () => {
    const { updateData, displayedTab, list } = this.props;
    updateData(`${list}Data`, displayedTab);
  };

  selectCar = async (car) => {
    this.setState({ vehicleInformationLoading: true });
    const { user, dispatch, watchListConditionReports } = this.props;
    if (car.inspectionId) {
      if (!watchListConditionReports[car.vin]) {
        const conditionReport =
          await ConditionReportService.getSingleConditionReport(
            car.inventoryId,
            user.userId,
            user.dealerId
          );
        dispatch(
          add_watchlist_condition_report(
            conditionReport,
            `watchListConditionReports`
          )
        );
        this.setState({
          selectedCar: car,
          conditionReport: conditionReport,
          carFrom: car.isCRAvailable ? "DDO" : "GAMD",
          vehicleInformationLoading: false,
        });
      } else {
        this.setState({
          selectedCar: car,
          conditionReport:
            watchListConditionReports[
              car.vin ? car.vin : car.vehicleIdentificationNumber
            ],
          carFrom: car.isCRAvailable ? "DDO" : "GAMD",
          vehicleInformationLoading: false,
        });
      }
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: null,
        carFrom: car.isCRAvailable ? "DDO" : "GAMD",
        vehicleInformationLoading: false,
      });
    }
  };

  selectMobileCar = async (car, user, from) => {
    this.setState({ vehicleInformationLoading: true });
    const {
      watchListConditionReports,
      dispatch,
      toggleFullConditionReport,
      fullConditionReport,
    } = this.props;
    if (car.inspectionId || car.inspectionInfo) {
      if (
        !watchListConditionReports[
          car.vin ? car.vin : car.vehicleIdentificationNumber
        ]
      ) {
        const crInfo = await ConditionReportService.getSingleConditionReport(
          car.inventoryId ? car.inventoryId : car.id,
          user.userId,
          user.dealerId
        );
        dispatch(
          add_watchlist_condition_report(crInfo, "watchListConditionReports")
        );
        this.setState({
          selectedCar: car,
          conditionReport: crInfo,
          getCRFinished: true,
          vehicleInformationLoading: false,
        });
      } else {
        this.setState({
          selectedCar: car,
          conditionReport:
            watchListConditionReports[
              car.vin ? car.vin : car.vehicleIdentificationNumber
            ],
          getCRFinished: true,
          vehicleInformationLoading: false,
        });
      }
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: null,
        getCRFinished: true,
        vehicleInformationLoading: false,
      });
    }
    if (fullConditionReport) {
      toggleFullConditionReport();
    }
    dispatch(hide_mobile_nav_menu(true));
    dispatch(select_mobile_car(car));
  };

  handleBidOpen = async (car) => {
    const { user, openBid, toggleOpenBid, watchListConditionReports } =
      this.props;
    const bidInfo = await getVehicleBidInformation(car, user);
    if (!openBid) {
      this.setState({
        vehicleBidInformation: bidInfo,
        conditionReport: watchListConditionReports[car.vin],
        selectedCar: car,
      });
      toggleOpenBid();
    } else {
      toggleOpenBid();
    }
  };

  toggleBuy = (car, bought) => {
    const { toggleBuyVehicle, watchListConditionReports } = this.props;
    if (car.vin) {
      this.setState({
        conditionReport: watchListConditionReports[car.vin],
      });
    }
    toggleBuyVehicle(
      bought,
      car.vin ? car.vin : car.vehicleIdentificationNumber
    );
  };

  handleBuyNow = async (car, bought) => {
    const { dispatch, toggleDDOBuyVehicle, ddoBuyVehicle, displayedCars } =
      this.props;
    const { userId, dealerId } = this.props.user;
    if (!ddoBuyVehicle) {
      const buyNowDetails = await VehiclePurchaseServices.buyNow(
        userId,
        dealerId,
        car.saleEventItemId
      );
      if (buyNowDetails.status !== "false") {
        dispatch(select_car_for_purchase(buyNowDetails));
        toggleDDOBuyVehicle(car, false);
      } else {
        console.log(
          "Error occured while retriving buy now details",
          buyNowDetails
        );
      }
    } else {
      if (bought) {
        this.setState({
          selectedCar: displayedCars[0] ? displayedCars[0] : null,
        });
      }
      toggleDDOBuyVehicle(
        car.vin ? car.vin : car.vehicleIdentificationNumber,
        bought
      );
    }
  };

  togglePass = (passed, car) => {
    const { togglePassVehicle, displayedCars, updateWatchListDisplay } =
      this.props;
    if (passed) {
      const newDisplay = displayedCars.map((newCar) => {
        if (newCar.vin === car.vin) {
          return { ...newCar, vehiclePassed: true };
        } else {
          return newCar;
        }
      });
      updateWatchListDisplay(newDisplay);
    }
    togglePassVehicle();
  };

  setTileRef = (car, ref) => {
    this.refs[car.vin ? car.vin : car.vehicleIdentificationNumber] = ref;
  };

  scrollToCar = (car) => {
    if (car && this.refs[car.vin ? car.vin : car.vehicleIdentificationNumber]) {
      this.refs[car.vin ? car.vin : car.vehicleIdentificationNumber].current &&
        this.refs[
          car.vin ? car.vin : car.vehicleIdentificationNumber
        ].current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
    }
  };

  selectConditionReport = (car, open) => {
    const { toggleFullConditionReport, watchListConditionReports } = this.props;
    this.setState({
      selectedCar: car,
      conditionReport: watchListConditionReports[car.vin],
    });
    toggleFullConditionReport();
  };

  render() {
    const {
      selectedCar,
      showConditionReport,
      conditionReport,
      carFrom,
      carsWithCR,
      vehicleBidInformation,
      programCertNum,
      programCertComments,
      openNote,
      openTransport,
      vehicleInformationLoading,
    } = this.state;
    const {
      classes,
      history,
      user,
      buyVehicle,
      ddoBuyVehicle,
      passVehicle,
      openBid,
      toggleBuyVehicle,
      listLoading,
      fullConditionReport,
      toggleFullConditionReport,
      accountSetting,
      currentAuction,
      auctionTimes,
      watchListConditionReports,
      dispatch,
      mobileCarInformation,
      hideMobileNavMenu,
      updateAutoBid,
      displayedCars,
      updateGuaranteePayment,
    } = this.props;
    return (
      <React.Fragment>
        {!openBid &&
        !buyVehicle &&
        !ddoBuyVehicle &&
        !passVehicle &&
        !fullConditionReport ? (
          <React.Fragment>
            <div className={classes.desktopCardList}>
              {!listLoading ? (
                displayedCars && displayedCars.length > 0 ? (
                  <div className={classes.watchListCardListWrapper}>
                    <div
                      className={
                        buyVehicle || ddoBuyVehicle || passVehicle || openBid
                          ? classes.watchListCardListLeftWhite
                          : classes.watchListCardListLeft
                      }
                    >
                      <div className={classes.scrollContainer}>
                        {!passVehicle &&
                        !buyVehicle &&
                        !openBid &&
                        !ddoBuyVehicle
                          ? displayedCars.map((car) => (
                              <div
                                key={
                                  car.vehicleIdentificationNumber !== undefined
                                    ? car.vehicleIdentificationNumber
                                    : car.vin
                                }
                                className={classes.advSearchCardWrapper}
                              >
                                {(car.dealerFacingStatus === "DLR_BID_NOW" ||
                                  car.dealerFacingStatus === "DLR_BUY_NOW" ||
                                  car.statusName === "DLR_BID_NOW" ||
                                  car.statusName === "DLR_BUY_NOW" ||
                                  car.statusName === "DLR_SOLD") &&
                                car.vin !== undefined ? (
                                  <DDOCarListTile
                                    key={car.vin}
                                    car={car}
                                    selectCar={this.selectCar}
                                    user={user}
                                    onHandleProgramCertCommentsChange={
                                      this.onHandleProgramCertCommentsChange
                                    }
                                    onHandleProgramCertNumChange={
                                      this.onHandleProgramCertNumChange
                                    }
                                    programCertNum={programCertNum}
                                    programCertComments={programCertComments}
                                    selectedCar={selectedCar}
                                    conditionReport={
                                      watchListConditionReports[car.vin]
                                    }
                                    toggleBuy={this.toggleDDOBuy}
                                    history={history}
                                    handleBidOpen={this.handleBidOpen}
                                    handleBuyNow={this.handleBuyNow}
                                    updateAutoBid={updateAutoBid}
                                    currentAuction={currentAuction}
                                    pageFrom={"watchList"}
                                    updateWatchList={this.updateWatchList}
                                    openNote={openNote}
                                    handleOpenNote={this.handleOpenNote}
                                    openTransport={openTransport}
                                    handleTransportToggle={
                                      this.handleTransportToggle
                                    }
                                    accountSetting={accountSetting}
                                    toggleFullConditionReport={
                                      toggleFullConditionReport
                                    }
                                    setTileRef={this.setTileRef}
                                  />
                                ) : (
                                  <CarListTile
                                    key={car.vehicleIdentificationNumber}
                                    car={car}
                                    selectCar={this.selectCar}
                                    user={user}
                                    onHandleProgramCertCommentsChange={
                                      this.onHandleProgramCertCommentsChange
                                    }
                                    onHandleProgramCertNumChange={
                                      this.onHandleProgramCertNumChange
                                    }
                                    programCertNum={programCertNum}
                                    programCertComments={programCertComments}
                                    selectedCar={selectedCar}
                                    conditionReport={
                                      watchListConditionReports[
                                        car.vin
                                          ? car.vin
                                          : car.vehicleIdentificationNumber
                                      ]
                                    }
                                    history={history}
                                    dispatch={dispatch}
                                    accountSetting={accountSetting}
                                    togglePass={this.togglePass}
                                    toggleBuy={this.toggleBuy}
                                    pageFrom={"watchList"}
                                    updateWatchlist={this.updateWatchlist}
                                    from={"watchlist"}
                                    openNote={openNote}
                                    handleOpenNote={this.handleOpenNote}
                                    updateGuaranteePayment={
                                      updateGuaranteePayment
                                    }
                                    selectConditionReport={
                                      this.selectConditionReport
                                    }
                                    setTileRef={this.setTileRef}
                                  />
                                )}
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                    <div className={classes.rightWrapper}>
                      {buyVehicle ||
                      ddoBuyVehicle ||
                      passVehicle ||
                      openBid ? null : (
                        <React.Fragment>
                          {showConditionReport ? (
                            carFrom === "DDO" ? (
                              <DDOFullVehicleInformation
                                user={user}
                                car={selectedCar}
                                conditionReport={conditionReport}
                                toggleFullConditionReport={
                                  toggleFullConditionReport
                                }
                              />
                            ) : (
                              <div className={classes.watchListCardListRight}>
                                <FullVehicleInformation
                                  car={selectedCar}
                                  conditionReport={conditionReport}
                                  toggleFullConditionReport={
                                    toggleFullConditionReport
                                  }
                                  selectedVehicleDetails={selectedCar}
                                  user={user}
                                />
                              </div>
                            )
                          ) : null}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                ) : (
                  <EmptyListPlaceholder page={"Watchlist"} />
                )
              ) : (
                <Loading />
              )}
            </div>
            {!mobileCarInformation ? (
              <div className={classes.mobileCardList}>
                {listLoading ? (
                  <Loading />
                ) : displayedCars && displayedCars.length > 0 ? (
                  !passVehicle &&
                  !buyVehicle &&
                  !openBid &&
                  !ddoBuyVehicle &&
                  !fullConditionReport ? (
                    displayedCars.map((car) =>
                      (car.dealerFacingStatus === "DLR_BID_NOW" ||
                        car.dealerFacingStatus === "DLR_BUY_NOW" ||
                        car.statusName === "DLR_BID_NOW" ||
                        car.statusName === "DLR_BUY_NOW" ||
                        car.statusName === "DLR_SOLD") &&
                      car.vin !== undefined ? (
                        <MobileDDOCarListTile
                          key={car.vin}
                          car={car}
                          selectCar={this.selectMobileCar}
                          user={user}
                          onHandleProgramCertCommentsChange={
                            this.onHandleProgramCertCommentsChange
                          }
                          onHandleProgramCertNumChange={
                            this.onHandleProgramCertNumChange
                          }
                          programCertNum={programCertNum}
                          programCertComments={programCertComments}
                          selectedCar={selectedCar}
                          conditionReport={watchListConditionReports[car.vin]}
                          toggleBuy={this.toggleDDOBuy}
                          history={history}
                          handleBidOpen={this.handleBidOpen}
                          handleBuyNow={this.handleBuyNow}
                          updateAutoBid={updateAutoBid}
                          currentAuction={currentAuction}
                          pageFrom={"watchList"}
                          updateWatchlist={this.updateWatchlist}
                          openNote={openNote}
                          handleOpenNote={this.handleOpenNote}
                          openTransport={openTransport}
                          handleTransportToggle={this.handleTransportToggle}
                          accountSetting={accountSetting}
                        />
                      ) : (
                        <MobileCarListTile
                          key={car.vin}
                          car={car}
                          selectCar={this.selectMobileCar}
                          user={user}
                          onHandleProgramCertCommentsChange={
                            this.onHandleProgramCertCommentsChange
                          }
                          onHandleProgramCertNumChange={
                            this.onHandleProgramCertNumChange
                          }
                          programCertNum={programCertNum}
                          programCertComments={programCertComments}
                          selectedCar={selectedCar}
                          conditionReport={
                            watchListConditionReports[
                              car.vehicleIdentificationNumber
                            ]
                          }
                          history={history}
                          dispatch={dispatch}
                          accountSetting={accountSetting}
                          togglePass={this.togglePass}
                          toggleBuy={this.toggleBuy}
                          pageFrom={"watchList"}
                          updateWatchlist={this.updateWatchlist}
                          from={"watchlist"}
                          openNote={openNote}
                          handleOpenNote={this.handleOpenNote}
                        />
                      )
                    )
                  ) : null
                ) : (
                  <EmptyListPlaceholder page={"Watchlist"} />
                )}
              </div>
            ) : null}
            {mobileCarInformation &&
            conditionReport &&
            !passVehicle &&
            !buyVehicle &&
            !ddoBuyVehicle &&
            !openBid &&
            !fullConditionReport ? (
              <div className={classes.watchListCardListRight}>
                {mobileCarInformation.vin ? (
                  <MobileDDOVehicleInformation
                    user={user}
                    car={selectedCar}
                    conditionReport={conditionReport}
                    toggleFullConditionReport={toggleFullConditionReport}
                  />
                ) : (
                  <MobileVehicleInformation
                    car={selectedCar}
                    conditionReport={conditionReport}
                    toggleFullConditionReport={toggleFullConditionReport}
                    selectedVehicleDetails={selectedCar}
                  />
                )}
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
        {fullConditionReport &&
        conditionReport &&
        !passVehicle &&
        !buyVehicle &&
        !ddoBuyVehicle &&
        !openBid ? (
          <div className={classes.watchListCardListWrapper}>
            <div className={classes.scrollContainer}>
              <ConditionReport
                car={selectedCar}
                user={user}
                conditionReport={conditionReport}
                toggleFullConditionReport={toggleFullConditionReport}
                watchlistCar={carsWithCR}
                page="Watchlist"
                pageFrom="watchList"
                selectCar={this.selectCar}
                history={history}
                handleBidOpen={this.handleBidOpen}
                handleBuyNow={this.handleBuyNow}
                toggleBuyVehicle={toggleBuyVehicle}
                updateAutoBid={updateAutoBid}
                vehicleInformationLoading={vehicleInformationLoading}
                updateWatchlist={this.updateWatchlist}
              />
            </div>
          </div>
        ) : null}
        {passVehicle ? (
          <PassVehicle
            car={selectedCar}
            togglePass={this.togglePass}
            history={history}
          />
        ) : null}
        {buyVehicle ? (
          <BuyVehicle
            toggleBuy={this.toggleBuy}
            page="GAMD"
            conditionReport={conditionReport}
            updateGuaranteePayment={updateGuaranteePayment}
          />
        ) : null}
        {ddoBuyVehicle ? (
          <BuyVehicle
            toggleBuy={this.handleBuyNow}
            page="DDO"
            conditionReport={conditionReport}
            isBuyNow
          />
        ) : null}
        {openBid ? (
          <BidVehicle
            car={selectedCar}
            accountSetting={accountSetting}
            auctionTimes={auctionTimes}
            user={user}
            vehicleBidInformation={vehicleBidInformation}
            handleBidOpen={this.handleBidOpen}
            updateAutoBid={updateAutoBid}
            conditionReport={conditionReport}
            pageFrom="watchList"
          />
        ) : null}
        {mobileCarInformation &&
        hideMobileNavMenu &&
        !buyVehicle &&
        !ddoBuyVehicle &&
        !openBid &&
        !passVehicle &&
        !fullConditionReport ? (
          <MobilePurchaseButtons
            handleBuyNow={this.handleBuyNow}
            toggleBuy={this.toggleBuy}
            handleBidOpen={this.handleBidOpen}
            togglePass={this.togglePass}
            pageFrom="watchList"
            user={user}
            car={mobileCarInformation}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    watchListConditionReports: state.cars.watchListConditionReports,
    accountSetting: state.accountSetting,
    auctionTimes: state.ui.auctionTimes,
    currentAuction: state.ui.currentAuction,
    mobileCarInformation: state.cars.mobileCarInformation,
    hideMobileNavMenu: state.ui.hideMobileNavMenu,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(WatchlistCardList));
