import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as R from "ramda";

import { withStyles } from "@material-ui/core/styles";
import {
  TablePagination,
  Typography,
  Card,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import DDOFullVehicleInformation from "../components/DDOFullVehicleInformation";
import ConditionReport from "../components/ConditionReport";
import CarDisplay from "../components/CarDisplay";
import BidVehicle from "../components/BidVehicle";
import DDOAuctionTimer from "../components/DDOAuctionTimer";
import Loading from "../components/Loading";
import BuyVehicle from "../components/BuyVehicle";
import Filter from "../components/Filter";
import EmptyListPlaceholder from "../components/EmptyListPlaceholder";
import MobileCarDisplay from "../components/MobileCarDisplay";
import MobilePurchaseButtons from "../components/MobilePurchaseButtons";
import MobileFilterMenu from "../components/MobileFilterMenu";

import { getVehicleBidInformation } from "../services/BidServices";
import { upstreamSearch } from "../services/SearchServices";
import * as ConditionReportService from "../services/ConditionReportService";
import * as AmeService from "../services/AmeService";
import * as VehiclePurchaseServices from "../services/VehiclePurchaseServices";
import * as userAccess from "../services/UserRoles";

import {
  add_ddo_condition_report,
  select_car_for_purchase,
  select_mobile_car,
  remove_selected_mobile_car,
} from "../actions/carActions";
import { set_nav_location, hide_mobile_nav_menu } from "../actions/uiActions";

import ReactSVG from "react-svg";
import circleDDOIcon from "../assets/circleDDOIcon.svg";
import filterCars from "../assets/filterCars.svg";
import conditionReportIcon from "../assets/conditionReportIcon.svg";

const AME_TIMEOUT = process.env.REACT_APP_AME_TIMEOUT;

let IS_USED = {
  bodyStyleCarIsUsed: 0,
  modelCarIsUsed: 0,
  colorCarIsUsed: 0,
  seriesCarIsUsed: 0,
  yearCarIsUsed: 0,
  makeCarIsUsed: 0,
  stateCarIsUsed: 0,
  gradeCarIsUsed: 0,
  statusCarIsUsed: 0,
  priceCarIsUsed: 0,
  odometerCarIsUsed: 0,
};

const styles = (theme) => ({
  container: {
    display: "flex",
    height: "79%",
    [theme.breakpoints.down("sm")]: {
      height: "90%",
    },
  },
  navButton: {},
  leftWrapper: {
    width: "75%",
    backgroundColor: theme.palette.background.dark,
    margin: ".5rem",
    height: "95%",
    padding: ".2rem",
    overflow: "scroll",
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      height: "fit-content",
      marginBottom: "3rem",
    },
  },
  rightWrapper: {
    width: "25%",
    height: "95%",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      width: "27%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageHeader: {
    height: "3rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    width: "97%",
    marginTop: ".5rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
      height: "5.5rem",
      flexDirection: "column",
      paddingTop: ".5rem",
      marginTop: "3.5rem",
    },
  },
  pagination: {
    minHeight: 0,
  },
  DDOIcon: {
    width: "4rem",
    height: "2.4rem",
  },
  titleWrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    width: "10rem",
    [theme.breakpoints.down("md")]: {
      width: "11rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  titleTextWrapper: {
    borderBottom: `5px solid ${theme.palette.DDO.main}`,
    height: "100%",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
    width: "8.2rem",
  },
  titleText: {
    color: theme.palette.DDO.main,
    fontSize: ".8rem",
    width: "10rem",
    marginBottom: ".5rem",
    fontWeight: "bold",
  },
  rightSelectionButtonWrapper: {
    display: "flex",
    width: "6rem",
    justifyContent: "space-between",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  selectionButton: {
    backgroundColor: theme.palette.DDO.main,
    width: "2.5rem",
    height: "1.78rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  toolbar: {
    height: "1.5rem",
    minHeight: 0,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      width: "15rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  errorMessage: {},
  errorSnackBar: {
    marginTop: "7rem",
  },
  scrollContainer: {
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileScrollContainer: {
    height: "fit-content",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileButtonWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileButton: {
    height: "100%",
    width: "33.333%",
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
  },
  mobileButtonText: {
    fontSize: theme.font.small,
  },
  selectedMobileButton: {
    height: "100%",
    width: "33.333%",
    backgroundColor: theme.palette.secondary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.gray,
    },
  },
});

export class DDO extends Component {
  BID_TIMEOUT = null;
  state = {
    upstreamCars: [],
    displayedCars: [],
    selectedCar: {},
    conditionReport: null,
    fullConditionReport: false,
    programCertNum: "",
    programCertComments: "",
    filterActive: false,
    currentAuction: "",
    fetchCarsFinished: false,
    fetchConditionReportFinished: false,
    crossLine: false,
    vehicleBidInformation: {},
    openBid: false,
    page: 0,
    rowsPerPage: 5,
    total: 0,
    saleEventItemIds: [],
    start: 0,
    end: 0,
    buyVehicle: false,
    isFilterOn: false,
    filteredCarList: [],
    matchCount: 0,
    sortByColor: false,
    sortByDistance: false,
    sortByYear: false,
    sortByBody: false,
    sortByMake: false,
    sortByModel: false,
    sortBySeries: false,
    sortByGrade: false,
    sortByPrice: false,
    sortByOdometer: false,
    sortByStatus: false,
    pageLoading: true,
    vehicleInformationLoading: false,
    newSelection: false,
    fromCR: false,
  };

  componentWillMount = () => {
    const { user, dispatch, navLocation, history } = this.props;
    if (
      !userAccess.canViewOnly(user.userRoles) &&
      !userAccess.canPurchaseVehicles(user.userRoles)
    ) {
      history.replace("/home");
    } else {
      if (navLocation !== "DDO") {
        dispatch(set_nav_location("DDO"));
      }
      this.fetchUpstreamCars();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { history, mobileCarInformation, dispatch } = this.props;
    const {
      currentAuction,
      displayedCars,
      page,
      rowsPerPage,
      openBid,
      buyVehicle,
      fullConditionReport,
    } = this.state;

    if (currentAuction !== prevState.currentAuction) {
      this.setState({
        fetchCarsFinished: false,
        fetchConditionReportFinished: false,
      });
      clearTimeout(this.BID_TIMEOUT);
      this.fetchUpstreamCars();
    }

    if (page !== prevState.page || rowsPerPage !== prevState.rowsPerPage) {
      clearTimeout(this.BID_TIMEOUT);
      const saleEventItemIds = [];
      displayedCars.map((items) =>
        saleEventItemIds.push(items.saleEventItemId)
      );
      this.setState({ saleEventItemIds });
      this.BID_TIMEOUT = setTimeout(
        () => this.getConnectionToAmeService(),
        AME_TIMEOUT
      );
    }
    if (
      history.action === "POP" &&
      history.location.pathname === "/DDO" &&
      (openBid || buyVehicle)
    ) {
      this.setState({
        openBid: false,
        buyVehicle: false,
      });
    }
    if (
      mobileCarInformation !== prevProps.mobileCarInformation &&
      (openBid || buyVehicle || fullConditionReport)
    ) {
      if (buyVehicle) {
        this.toggleBuy();
      } else if (openBid) {
        this.handleBidOpen();
      } else if (fullConditionReport) {
        this.toggleFullConditionReport();
      }
      if (!mobileCarInformation) {
        dispatch(hide_mobile_nav_menu(false));
      }
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.BID_TIMEOUT);
  };

  fetchUpstreamCars = async () => {
    const { user, DDOConditionReports, dispatch, mobileCarInformation } =
      this.props;
    const { page, rowsPerPage, buyVehicle, bidVehicle, fullConditionReport } =
      this.state;
    const upstreamCars = await upstreamSearch(user);
    if (upstreamCars.length > 0) {
      const start = page * rowsPerPage;
      const end = page * rowsPerPage + rowsPerPage;
      const id = upstreamCars[0].id
        ? upstreamCars[0].id
        : upstreamCars[0].inventoryId;
      const saleEventItemIds = [];
      upstreamCars
        .slice(start, end)
        .map((items) => saleEventItemIds.push(items.saleEventItemId));

      if (!DDOConditionReports[upstreamCars[0].vin]) {
        const conditionReport =
          await ConditionReportService.getSingleConditionReport(
            id,
            user.userId,
            user.dealerId
          );
        dispatch(add_ddo_condition_report(conditionReport));
        this.setState({
          upstreamCars,
          conditionReport,
          start,
          end,
          selectedCar: upstreamCars[0],
          displayedCars: upstreamCars.slice(start, end),
          fetchCarsFinished: true,
          fetchConditionReportFinished: true,
          total: upstreamCars.length,
          matchCount: upstreamCars.length,
          saleEventItemIds,
          filteredCarList: upstreamCars,
          pageLoading: false,
        });
      } else {
        this.setState({
          upstreamCars,
          conditionReport:
            DDOConditionReports[upstreamCars.slice(start, end)[0].vin],
          start,
          end,
          selectedCar: upstreamCars[0],
          displayedCars: upstreamCars.slice(start, end),
          fetchCarsFinished: true,
          fetchConditionReportFinished: true,
          total: upstreamCars.length,
          matchCount: upstreamCars.length,
          saleEventItemIds,
          filteredCarList: upstreamCars,
          pageLoading: false,
        });
      }
      this.getConnectionToAmeService();
      if (
        mobileCarInformation &&
        !buyVehicle &&
        !bidVehicle &&
        !fullConditionReport
      ) {
        dispatch(remove_selected_mobile_car());
      }
    } else {
      this.setState({
        pageLoading: false,
      });
    }
  };

  selectCar = async (car) => {
    this.setState({ vehicleInformationLoading: true });
    const { user, dispatch, DDOConditionReports } = this.props;
    if (!DDOConditionReports[car.vin]) {
      const id = car.id ? car.id : car.inventoryId;
      const newConditionReport =
        await ConditionReportService.getSingleConditionReport(
          id,
          user.userId,
          user.dealerId
        );
      dispatch(add_ddo_condition_report(newConditionReport));
      this.setState({
        selectedCar: car,
        conditionReport: newConditionReport,
        fetchConditionReportFinished: true,
        filterActive: false,
        vehicleInformationLoading: false,
      });
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: DDOConditionReports[car.vin],
        fetchConditionReportFinished: true,
        filterActive: false,
        vehicleInformationLoading: false,
      });
    }
  };

  selectMobileCar = async (car) => {
    const { DDOConditionReports, user, dispatch } = this.props;
    if (!DDOConditionReports[car.vin]) {
      const id = car.id ? car.id : car.inventoryId;
      const newConditionReport =
        await ConditionReportService.getSingleConditionReport(
          id,
          user.userId,
          user.dealerId
        );
      if (newConditionReport.vin) {
        dispatch(add_ddo_condition_report(newConditionReport));
        this.setState({
          selectedCar: car,
          conditionReport: newConditionReport,
          filterActive: false,
        });
      } else {
        this.setState({
          conditionReport: null,
          filterActive: false,
          selectedCar: car,
        });
      }
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: DDOConditionReports[car.vin],
        filterActive: false,
      });
    }
    dispatch(hide_mobile_nav_menu(true));
    dispatch(select_mobile_car(car));
  };

  toggleRightView = (src) => {
    const { conditionReport } = this.state;
    if (src === "search") {
      this.setState({
        filterActive: true,
      });
    } else {
      if (conditionReport) {
        this.setState({
          filterActive: false,
        });
      }
    }
  };

  toggleFullConditionReport = () => {
    this.setState({
      fullConditionReport: !this.state.fullConditionReport,
      fromCR: true,
    });
  };

  handleBidOpen = async (car) => {
    const { user, dispatch } = this.props;
    const { openBid } = this.state;
    if (car && !openBid) {
      const vehicleBidInformation = await getVehicleBidInformation(car, user);
      if (vehicleBidInformation.status !== "false") {
        this.setState({
          vehicleBidInformation,
          openBid: true,
        });
        return;
      } else {
        this.setState({
          error: vehicleBidInformation.message,
        });
        return;
      }
    } else {
      this.getBidHistory();
      this.setState({
        openBid: false,
      });
      dispatch(remove_selected_mobile_car());
      dispatch(hide_mobile_nav_menu(false));
    }
  };

  handleBuyNow = async (car) => {
    const { dispatch } = this.props;
    const { userId, dealerId } = this.props.user;
    let buyNowDetails = await VehiclePurchaseServices.buyNow(
      userId,
      dealerId,
      car.saleEventItemId
    );
    if (buyNowDetails.status !== "false") {
      this.setState({
        selectedCar: car,
      });
      dispatch(select_car_for_purchase(buyNowDetails));
      clearTimeout(this.BID_TIMEOUT);
      this.toggleBuy(car, false);
    } else {
      this.setState({
        error: "Cannot purchase vehicle at this time.",
      });
    }
  };

  handleChangePage = (event, newPage) => {
    const { rowsPerPage, upstreamCars, isFilterOn, filteredCarList } =
      this.state;
    const start = newPage === 0 ? 0 : newPage * rowsPerPage;
    const end = newPage * rowsPerPage + rowsPerPage;
    clearTimeout(this.BID_TIMEOUT);
    this.setState({
      start,
      end,
      page: newPage,
      displayedCars: isFilterOn
        ? filteredCarList.slice(start, end)
        : upstreamCars.slice(start, end),
    });
  };

  handleChangeRowsPerPage = (event) => {
    const { upstreamCars, filteredCarList, isFilterOn } = this.state;
    clearTimeout(this.BID_TIMEOUT);
    this.setState({
      start: 0,
      end: +event.target.value,
      rowsPerPage: +event.target.value,
      page: 0,
      displayedCars: isFilterOn
        ? filteredCarList.slice(0, +event.target.value)
        : upstreamCars.slice(0, +event.target.value),
    });
  };

  getConnectionToAmeService = async () => {
    const { saleEventItemIds } = this.state;
    clearTimeout(this.BID_TIMEOUT);
    try {
      const clientId = await AmeService.startConnection(saleEventItemIds);
      this.setState({ clientId: clientId });
      this.getBidHistory();
    } catch (err) {
      console.log("Error occurred while connecting to AME:" + err.message);
      this.setState({
        errorMessage: "Error occurred while connecting to AME:" + err.message,
      });
      this.BID_TIMEOUT = setTimeout(
        () => this.getConnectionToAmeService(),
        AME_TIMEOUT
      );
    }
  };

  getBidHistory = async () => {
    const { clientId } = this.state;
    try {
      const resp = await AmeService.getBidHistory(clientId);
      if (resp[0].successful) {
        if (resp[1]) {
          this.updateCarsFromAmeResponse(resp.slice(1));
        }
        this.BID_TIMEOUT = setTimeout(() => this.getBidHistory(), AME_TIMEOUT);
      } else {
        this.BID_TIMEOUT = setTimeout(
          () => this.getConnectionToAmeService(),
          AME_TIMEOUT
        );
      }
    } catch (err) {
      this.setState({
        errorMessage: "Error occurred while connecting to AME:" + err.message,
      });
      this.BID_TIMEOUT = setTimeout(
        () => this.getConnectionToAmeService(),
        AME_TIMEOUT
      );
    }
  };

  updateCarsFromAmeResponse = (cars) => {
    const { upstreamCars, start, end, displayedCars, filteredCarList } =
      this.state;
    const newUpstreamCars = JSON.parse(JSON.stringify(upstreamCars));
    const newFilteredCars = JSON.parse(JSON.stringify(filteredCarList));
    const newDisplayedCars = JSON.parse(JSON.stringify(displayedCars));
    let updateCars = false;
    const updateCar = (carToUpdate, car) => {
      if (car.data.item.statusLst[0] === "sold") {
        carToUpdate.buyNowDisabled = true;
        carToUpdate.isSold = true;
        carToUpdate.bidNowAutoBidButtonEnabled = false;
        carToUpdate.winningUserID = car.data.item.winningUserID;
      } else {
        carToUpdate.bidPlaced = true;
        carToUpdate.bidNowAutoBidButtonEnabled = true;
        carToUpdate.autoBidButtonEnabled = true;
        carToUpdate.currentBid = Number(
          car.data.item.bidAmount.replace(",", "")
        );
        carToUpdate.buyNowDisabled = true;
        carToUpdate.inProgress = true;
        carToUpdate.highBidder = car.data.item.bidderName;
        carToUpdate.bidCount = +car.data.item.numberOfBids;
        carToUpdate.winningUserID = +car.data.item.winningUserID;
      }
    };
    cars.map((car) => {
      const carToUpdate = newUpstreamCars.find((upstreamCar) => {
        return (
          Number(upstreamCar.saleEventItemId) ===
          Number(car.data.item.saleEventItemId)
        );
      });
      const filteredCarToUpdate = newFilteredCars.find((filteredCar) => {
        return (
          Number(filteredCar.saleEventItemId) ===
          Number(car.data.item.saleEventItemId)
        );
      });
      const displayedCarToUpdate = newDisplayedCars.find((displayedCar) => {
        return (
          Number(displayedCar.saleEventItemId) ===
          Number(car.data.item.saleEventItemId)
        );
      });
      if (carToUpdate) {
        updateCar(carToUpdate, car);
        if (filteredCarToUpdate) {
          updateCar(filteredCarToUpdate, car);
        }
        if (displayedCarToUpdate) {
          updateCar(displayedCarToUpdate, car);
        }
        return (updateCars = true);
      } else {
        return null;
      }
    });
    if (updateCars) {
      return this.setState({
        upstreamCars: newUpstreamCars,
        displayedCars: newDisplayedCars.slice(start, end),
        filteredCarList: newFilteredCars,
      });
    }
  };

  toggleBuy = (car, bought) => {
    const { dispatch } = this.props;
    const { displayedCars, filteredCarList } = this.state;
    let newDisplay = JSON.parse(JSON.stringify(displayedCars));
    let newFilter = JSON.parse(JSON.stringify(filteredCarList));
    newDisplay = bought
      ? displayedCars.filter(
          (filterCar) =>
            (filterCar.vin
              ? filterCar.vin
              : filterCar.vehicleIdentificationNumber) !== car.vin
        )
      : displayedCars;
    newFilter = bought
      ? filteredCarList.filter(
          (filterCar) =>
            (filterCar.vin
              ? filterCar.vin
              : filterCar.vehicleIdentificationNumber) !== car.vin
        )
      : filteredCarList;
    if (bought) {
      dispatch(remove_selected_mobile_car());
    }
    this.setState({
      buyVehicle: !this.state.buyVehicle,
      fullConditionReport: bought ? false : this.state.fullConditionReport,
      vehicleInformationLoading: false,
      displayedCars: newDisplay,
      filteredCarList: newFilter,
    });
  };

  updateAutoBid = (vin, autoBid) => {
    const { dispatch } = this.props;
    const { upstreamCars, displayedCars } = this.state;
    const carMap = (car) =>
      car.vin === vin ? { ...car, myCurrentAutoBid: autoBid } : car;
    const updatedCars = R.map(carMap, upstreamCars);
    const updatedDisplay = R.map(carMap, displayedCars);
    dispatch(remove_selected_mobile_car());
    this.getBidHistory();
    this.setState({
      upstreamCars: updatedCars,
      displayedCars: updatedDisplay,
      selectedCar: updatedCars.filter((car) => car.vin === vin)[0],
      openBid: false,
    });
  };

  updateWatchlist = (vin) => {
    const { upstreamCars, displayedCars } = this.state;
    const newSelectedCar = displayedCars.filter((car) => car.vin === vin)[0];
    const modifiedUpstream = upstreamCars.map((car) => {
      if (car.vin === vin) {
        return { ...car, isInWatchList: !car.isInWatchList };
      } else {
        return car;
      }
    });
    const modifiedDisplayed = displayedCars.map((car) => {
      if (car.vin === vin) {
        return { ...car, isInWatchList: !car.isInWatchList };
      } else {
        return car;
      }
    });
    this.setState({
      upstreamCars: modifiedUpstream,
      displayedCars: modifiedDisplayed,
      selectedCar: {
        ...newSelectedCar,
        isInWatchList: !newSelectedCar.isInWatchList,
      },
    });
  };

  selectionChanged = async (query, removed) => {
    this.handleChangePage(null, 0);
    this.setState({
      newSelection: true,
    });
    const filterNotActive =
      query.selectedColor.length === 0 &&
      query.selectYear.length === 0 &&
      query.selectBodyStyle.length === 0 &&
      query.selectMake.length === 0 &&
      query.selectModel.length === 0 &&
      query.selectSeries.length === 0 &&
      query.selectState.length === 0 &&
      query.selectGrade.length === 0 &&
      query.selectStatus.length === 0 &&
      query.selectPrice.length === 0 &&
      query.selectOdometer.length === 0 &&
      query.selectedDistance.length === 0;
    const { upstreamCars, filteredCarList, start, end } = this.state;
    const { DDOConditionReports, user, dispatch } = this.props;
    let finalarray = JSON.parse(
      JSON.stringify(
        !filterNotActive && !removed ? filteredCarList : upstreamCars
      )
    );
    if (query.selectState.length > 0) {
      finalarray = upstreamCars.filter((car) =>
        R.includes(car.state, query.selectState)
      );
    }
    if (query.selectBodyStyle.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.bodyStyle, query.selectBodyStyle)
      );
    }
    if (query.selectModel.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.model, query.selectModel)
      );
    }
    if (query.selectedColor.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.color, query.selectedColor)
      );
    }
    if (query.selectSeries.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.series, query.selectSeries)
      );
    }
    if (query.selectYear.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(String(car.year), query.selectYear)
      );
    }
    if (query.selectMake.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.make, query.selectMake)
      );
    }
    if (query.selectGrade.length > 0) {
      query.selectGrade.map((grade) => {
        return (finalarray = finalarray.filter((car) => {
          if (grade === "1-2") {
            return Number(car.grade) >= 1 && Number(car.grade) < 2;
          } else if (grade === "2-3") {
            return Number(car.grade) >= 2 && Number(car.grade) < 3;
          } else if (grade === "3-4") {
            return Number(car.grade) >= 3 && Number(car.grade) < 4;
          } else if (grade === "4-5") {
            return Number(car.grade) >= 4 && Number(car.grade) < 5;
          } else if (grade === "5") {
            return Number(car.grade) === 5;
          } else {
            return false;
          }
        }));
      });
    }
    if (query.selectStatus.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.dlrFacingStatusName, query.selectStatus)
      );
    }
    if (query.selectPrice.length > 0) {
      query.selectPrice.map((priceRange) => {
        return (finalarray = finalarray.filter((car) => {
          const buyNowPrice = car.discountedBuyNowPrice
            ? car.discountedBuyNowPrice
            : car.buyNowPrice;
          if (priceRange === "$0-$10,000") {
            return buyNowPrice < 10000;
          } else if (priceRange === "$10,000-$15,000") {
            return buyNowPrice >= 10000 && buyNowPrice < 15000;
          } else if (priceRange === "$15,000-$20,000") {
            return buyNowPrice >= 15000 && buyNowPrice < 20000;
          } else if (priceRange === "$20,000-$25,000") {
            return buyNowPrice >= 20000 && buyNowPrice < 25000;
          } else if (priceRange === "$25,000-$30,000") {
            return buyNowPrice >= 25000 && buyNowPrice < 30000;
          } else if (priceRange === "$30,000-$35,000") {
            return buyNowPrice >= 30000 && buyNowPrice < 35000;
          } else if (priceRange === "$35,000-$40,000") {
            return buyNowPrice >= 35000 && buyNowPrice < 40000;
          } else if (priceRange === "$40,000-$45,000") {
            return buyNowPrice >= 40000 && buyNowPrice < 45000;
          } else if (priceRange === "$45,000-$50,000") {
            return buyNowPrice >= 45000 && buyNowPrice < 50000;
          } else if (priceRange === "$50,000-$55,000") {
            return buyNowPrice >= 50000 && buyNowPrice < 55000;
          } else if (priceRange === "$55,000-$60,000") {
            return buyNowPrice >= 55000 && buyNowPrice < 60000;
          } else if (priceRange === "$60,000-$65,000") {
            return buyNowPrice >= 60000 && buyNowPrice < 65000;
          } else if (priceRange === "$65,000-$75,000") {
            return buyNowPrice >= 65000 && buyNowPrice < 75000;
          } else if (priceRange === "$75,000-$100,000") {
            return buyNowPrice >= 75000 && buyNowPrice < 100000;
          } else {
            return false;
          }
        }));
      });
    }
    if (query.selectOdometer.length > 0) {
      query.selectOdometer.map((odometerSelection) => {
        return (finalarray = finalarray.filter((car) => {
          if (odometerSelection === "0-10,000") {
            return car.odometer < 10000;
          } else if (odometerSelection === "10,000-20,000") {
            return car.odometer >= 10000 && car.odometer < 20000;
          } else if (odometerSelection === "20,000-30,000") {
            return car.odometer >= 20000 && car.odometer < 30000;
          } else if (odometerSelection === "30,000-40,000") {
            return car.odometer >= 30000 && car.odometer < 40000;
          } else if (odometerSelection === "40,000-50,000") {
            return car.odometer >= 40000 && car.odometer < 50000;
          } else if (odometerSelection === "50,000-60,000") {
            return car.odometer >= 50000 && car.odometer < 60000;
          } else if (odometerSelection === "60,000-70,000") {
            return car.odometer >= 60000 && car.odometer < 70000;
          } else if (odometerSelection === "70,000-80,000") {
            return car.odometer >= 70000 && car.odometer < 80000;
          } else if (odometerSelection === "80,000-90,000") {
            return car.odometer >= 80000 && car.odometer < 90000;
          } else if (odometerSelection === "90,000-100,000") {
            return car.odometer >= 90000 && car.odometer < 100000;
          } else {
            return false;
          }
        }));
      });
    }
    if (query.selectedDistance.length > 0) {
      query.selectedDistance.map((distance) => {
        return (finalarray = finalarray.filter((car) => {
          if (distance === "<50 mi") {
            return car.distance < 50;
          } else if (distance === "<100 mi") {
            return car.distance < 100;
          } else if (distance === "<200 mi") {
            return car.distance < 200;
          } else if (distance === "<300 mi") {
            return car.distance < 300;
          } else if (distance === "<400 mi") {
            return car.distance < 400;
          } else if (distance === "<500 mi") {
            return car.distance < 500;
          } else if (distance === "<750 mi") {
            return car.distance < 750;
          } else if (distance === "<1000 mi") {
            return car.distance < 1000;
          } else if (distance === "<1500 mi") {
            return car.distance < 1500;
          } else if (distance === "<2000 mi") {
            return car.distance < 2000;
          } else {
            return false;
          }
        }));
      });
    }
    const id = filterNotActive
      ? upstreamCars[0].id
        ? upstreamCars[0].id
        : upstreamCars[0].inventoryId
      : finalarray[0]
      ? finalarray[0].id
        ? finalarray[0].id
        : finalarray[0].inventoryId
      : null;
    let conditionReport = filterNotActive
      ? DDOConditionReports[upstreamCars[0].vin]
      : finalarray[0]
      ? DDOConditionReports[finalarray[0].vin]
      : null;
    if (!conditionReport) {
      conditionReport = await ConditionReportService.getSingleConditionReport(
        id,
        user.userId,
        user.dealerId
      );
      dispatch(add_ddo_condition_report(conditionReport));
    }
    this.setState({
      filteredCarList: filterNotActive ? upstreamCars : finalarray,
      displayedCars: filterNotActive
        ? upstreamCars.slice(start, end)
        : finalarray.length > end - start
        ? finalarray.slice(start, end)
        : finalarray,
      isFilterOn: filterNotActive ? false : true,
      matchCount: filterNotActive ? upstreamCars.length : finalarray.length,
      selectedCar: filterNotActive
        ? upstreamCars[0]
        : finalarray[0] && finalarray[0],
      conditionReport: conditionReport,
      newSelection: false,
    });
  };

  resetIsUsedValue = () => {
    IS_USED.bodyStyleCarIsUsed = 0;
    IS_USED.modelCarIsUsed = 0;
    IS_USED.colorCarIsUsed = 0;
    IS_USED.seriesCarIsUsed = 0;
    IS_USED.yearCarIsUsed = 0;
    IS_USED.makeCarIsUsed = 0;
    IS_USED.stateCarIsUsed = 0;
    IS_USED.gradeCarIsUsed = 0;
    IS_USED.statusCarIsUsed = 0;
    IS_USED.priceCarIsUsed = 0;
    IS_USED.odometerCarIsUsed = 0;
    this.setState({
      isFilterOn: false,
    });
  };

  sortCars = (summary) => {
    const {
      start,
      end,
      sortByColor,
      sortByDistance,
      sortByModel,
      sortByBody,
      sortByMake,
      sortBySeries,
      sortByPrice,
      sortByOdometer,
      sortByGrade,
      sortByYear,
      sortByState,
      sortByStatus,
      filteredCarList,
      isFilterOn,
    } = this.state;
    let color;
    let distance;
    let model;
    let body;
    let make;
    let series;
    let price;
    let odometer;
    let grade;
    let year;
    let state;
    let status;
    const array = JSON.parse(JSON.stringify(filteredCarList));

    if (summary === "Color") {
      color = !sortByColor;
      array.sort((a, b) => {
        return !sortByColor
          ? a.color.toLowerCase() < b.color.toLowerCase()
            ? -1
            : 1
          : a.color.toLowerCase() > b.color.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Distance") {
      distance = !sortByDistance;
      array.sort((a, b) => {
        return !sortByDistance
          ? a.distance < b.distance
            ? -1
            : 1
          : a.distance > b.distance
          ? -1
          : 1;
      });
    }

    if (summary === "Model") {
      model = !sortByModel;
      array.sort((a, b) => {
        return !sortByModel
          ? a.model.toLowerCase() < b.model.toLowerCase()
            ? -1
            : 1
          : a.model.toLowerCase() > b.model.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Body/Style") {
      body = !sortByBody;
      array.sort((a, b) => {
        return a.bodyStyle && b.bodyStyle
          ? !sortByBody
            ? a.bodyStyle.toLowerCase() < b.bodyStyle.toLowerCase()
              ? -1
              : 1
            : a.bodyStyle.toLowerCase() > b.bodyStyle.toLowerCase()
            ? -1
            : 1
          : 0;
      });
    }

    if (summary === "Make") {
      make = !sortByMake;
      array.sort((a, b) => {
        return !sortByMake
          ? a.make.toLowerCase() < b.make.toLowerCase()
            ? -1
            : 1
          : a.make.toLowerCase() > b.make.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Series") {
      series = !sortBySeries;
      array.sort((a, b) => {
        return a.series && b.series
          ? !sortBySeries
            ? a.series.toLowerCase() < b.series.toLowerCase()
              ? -1
              : 1
            : a.series.toLowerCase() > b.series.toLowerCase()
            ? -1
            : 1
          : 0;
      });
    }

    if (summary === "Buy Now Price") {
      price = !sortByPrice;
      array.sort((a, b) => {
        return !sortByPrice
          ? a.buyNowPrice - b.buyNowPrice
          : b.buyNowPrice - a.buyNowPrice;
      });
    }

    if (summary === "Odometer") {
      odometer = !sortByOdometer;
      array.sort((a, b) => {
        return !sortByOdometer
          ? a.inspectionMileage - b.inspectionMileage
          : b.inspectionMileage - a.inspectionMileage;
      });
    }

    if (summary === "Grade") {
      grade = !sortByGrade;
      array.sort((a, b) => {
        return !sortByGrade
          ? parseFloat(a.grade) - parseFloat(b.grade)
          : parseFloat(b.grade) - parseFloat(a.grade);
      });
    }

    if (summary === "Year") {
      year = !sortByYear;
      array.sort((a, b) => {
        return !sortByYear ? a.year - b.year : b.year - a.year;
      });
    }

    if (summary === "State") {
      state = !sortByState;
      array.sort((a, b) => {
        return !sortByState
          ? a.state.toLowerCase() < b.state.toLowerCase()
            ? -1
            : 1
          : a.state.toLowerCase() > b.state.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Status") {
      status = !sortByStatus;
      array.sort((a, b) => {
        return !sortByStatus
          ? a.dlrFacingStatusName.toLowerCase() <
            b.dlrFacingStatusName.toLowerCase()
            ? -1
            : 1
          : a.dlrFacingStatusName.toLowerCase() >
            b.dlrFacingStatusName.toLowerCase()
          ? -1
          : 1;
      });
    }

    this.setState({
      sortByColor: color,
      sortByDistance: distance,
      sortByModel: model,
      sortByBody: body,
      sortByMake: make,
      sortBySeries: series,
      sortByPrice: price,
      sortByOdometer: odometer,
      sortByGrade: grade,
      sortByYear: year,
      sortByState: state,
      sortByStatus: status,
      displayedCars: array.slice(start, end),
      filteredCarList: array,
      isFilterOn: isFilterOn ? true : !this.state.isFilterOn,
    });
  };

  render() {
    const {
      classes,
      user,
      searchCriteria,
      accountSetting,
      auctionTimes,
      DDOConditionReports,
      currentAuction,
      history,
      mobileCarInformation,
      dispatch,
      hideMobileNavMenu,
    } = this.props;
    const {
      displayedCars,
      selectedCar,
      conditionReport,
      fullConditionReport,
      programCertComments,
      programCertNum,
      filterActive,
      fetchCarsFinished,
      fetchConditionReportFinished,
      openBid,
      vehicleBidInformation,
      rowsPerPage,
      page,
      total,
      buyVehicle,
      error,
      upstreamCars,
      isFilterOn,
      filteredCarList,
      matchCount,
      pageLoading,
      vehicleInformationLoading,
      newSelection,
      fromCR,
    } = this.state;
    return (
      <React.Fragment>
        {!fullConditionReport && !openBid && !buyVehicle ? (
          <React.Fragment>
            {!mobileCarInformation ? (
              <div className={classes.pageHeader}>
                <div className={classes.titleWrapper}>
                  <img
                    className={classes.DDOIcon}
                    src={circleDDOIcon}
                    alt="DDO Icon"
                  />
                  <div className={classes.titleTextWrapper}>
                    <Typography className={classes.titleText}>
                      Dealer Direct Online
                    </Typography>
                  </div>
                </div>
                <TablePagination
                  className={classes.pagination}
                  classes={{ toolbar: classes.toolbar }}
                  id="pagination"
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={"Rows"}
                  component="div"
                  count={isFilterOn ? filteredCarList.length : total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={(event, newPage) =>
                    this.handleChangePage(event, newPage)
                  }
                  onChangeRowsPerPage={(event) =>
                    this.handleChangeRowsPerPage(event)
                  }
                />
                <DDOAuctionTimer />
                <div className={classes.rightSelectionButtonWrapper}>
                  <Card
                    id="searchButton"
                    className={classes.selectionButton}
                    elevation={filterActive ? 6 : 0}
                    onClick={() => this.setState({ filterActive: true })}
                  >
                    <ReactSVG
                      svgStyle={{ fill: "white", marginTop: ".4rem" }}
                      src={filterCars}
                      alt="filterCarsIcon"
                    />
                  </Card>
                  {typeof selectedCar !== "undefined" ? (
                    <Card
                      id="conditionButton"
                      className={classes.selectionButton}
                      elevation={!filterActive ? 6 : 0}
                      onClick={() => this.setState({ filterActive: false })}
                    >
                      <ReactSVG
                        svgStyle={{ fill: "white", marginTop: ".4rem" }}
                        src={conditionReportIcon}
                        alt="conditionReportIcon"
                      />
                    </Card>
                  ) : null}
                </div>
              </div>
            ) : null}
            {!pageLoading ? (
              (isFilterOn && filteredCarList && filteredCarList.length > 0) ||
              (!isFilterOn && displayedCars && displayedCars.length > 0) ||
              upstreamCars.length > 0 ? (
                <div className={classes.container}>
                  <div className={classes.leftWrapper}>
                    <div className={classes.scrollContainer}>
                      {fetchCarsFinished && !newSelection ? (
                        filteredCarList.length > 0 ? (
                          <CarDisplay
                            carList={displayedCars}
                            totalCars={upstreamCars.length}
                            selectCar={this.selectCar}
                            user={user}
                            onHandleProgramCertCommentsChange={
                              this.onHandleProgramCertCommentsChange
                            }
                            onHandleProgramCertNumChange={
                              this.onHandleProgramCertNumChange
                            }
                            programCertComments={programCertComments}
                            programCertNum={programCertNum}
                            sourcePage={"DDO"}
                            DDOConditionReports={DDOConditionReports}
                            toggleImageViewer={this.toggleImageViewer}
                            handleBidOpen={this.handleBidOpen}
                            toggleBuy={this.toggleBuy}
                            handleBuyNow={this.handleBuyNow}
                            currentAuction={currentAuction}
                            updateAutoBid={this.updateAutoBid}
                            updateWatchlist={this.updateWatchlist}
                            history={history}
                            selectedCar={selectedCar}
                            toggleFullConditionReport={
                              this.toggleFullConditionReport
                            }
                          />
                        ) : (
                          <EmptyListPlaceholder />
                        )
                      ) : (
                        <Loading />
                      )}
                    </div>
                    <div className={classes.mobileScrollContainer}>
                      <MobileCarDisplay
                        carList={displayedCars}
                        selectCar={this.selectMobileCar}
                        user={user}
                        onHandleProgramCertCommentsChange={
                          this.onHandleProgramCertCommentsChange
                        }
                        onHandleProgramCertNumChange={
                          this.onHandleProgramCertNumChange
                        }
                        programCertComments={programCertComments}
                        programCertNum={programCertNum}
                        selectedCar={selectedCar}
                        sourcePage={"DDO"}
                        DDOConditionReports={DDOConditionReports}
                        history={history}
                        dispatch={dispatch}
                        accountSetting={accountSetting}
                        togglePass={this.togglePass}
                        toggleBuy={this.toggleBuy}
                        mobileCarInformation={mobileCarInformation}
                        updateWatchlist={this.updateWatchlist}
                        toggleFullConditionReport={
                          this.toggleFullConditionReport
                        }
                        hideMobileNavMenu={hideMobileNavMenu}
                        updateAutoBid={this.updateAutoBid}
                      />
                    </div>
                  </div>
                  <div className={classes.rightWrapper}>
                    {fetchCarsFinished &&
                    fetchConditionReportFinished &&
                    conditionReport ? (
                      <React.Fragment>
                        <div
                          className={classes.scrollContainer}
                          style={filterActive ? { display: "none" } : {}}
                        >
                          <React.Fragment>
                            {!vehicleInformationLoading ? (
                              selectedCar !== undefined ? (
                                <DDOFullVehicleInformation
                                  user={user}
                                  car={selectedCar}
                                  conditionReport={conditionReport}
                                  toggleFullConditionReport={
                                    this.toggleFullConditionReport
                                  }
                                />
                              ) : (
                                <Loading />
                              )
                            ) : null}
                          </React.Fragment>
                        </div>
                        <div
                          className={classes.scrollContainer}
                          style={filterActive ? {} : { display: "none" }}
                        >
                          <Filter
                            filterCars={filteredCarList}
                            from={"ddo"}
                            selectionChanged={this.selectionChanged}
                            matchCount={matchCount}
                            totalCount={upstreamCars.length}
                            resetIsUsedValue={this.resetIsUsedValue}
                            unfilteredCars={upstreamCars}
                            sortCars={this.sortCars}
                            fromCR={fromCR}
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              ) : (
                <EmptyListPlaceholder page={"DDO"} />
              )
            ) : (
              <Loading />
            )}
          </React.Fragment>
        ) : null}
        {fullConditionReport && conditionReport && !openBid && !buyVehicle ? (
          <ConditionReport
            searchCriteria={searchCriteria}
            car={selectedCar}
            user={user}
            conditionReport={conditionReport}
            toggleFullConditionReport={this.toggleFullConditionReport}
            upstreamCars={displayedCars}
            selectCar={this.selectCar}
            page="DDO"
            handleBidOpen={this.handleBidOpen}
            handleBuyNow={this.handleBuyNow}
            history={history}
            updateAutoBid={this.updateAutoBid}
            currentAuction={currentAuction}
            vehicleInformationLoading={vehicleInformationLoading}
            updateWatchlist={this.updateWatchlist}
            toggleBuyVehicle={this.handleBuyNow}
          />
        ) : null}
        {openBid ? (
          <BidVehicle
            car={selectedCar}
            accountSetting={accountSetting}
            auctionTimes={auctionTimes}
            user={user}
            vehicleBidInformation={vehicleBidInformation}
            handleBidOpen={this.handleBidOpen}
            updateAutoBid={this.updateAutoBid}
            conditionReport={conditionReport}
            pageFrom="DDO"
          />
        ) : null}
        {buyVehicle ? (
          fetchConditionReportFinished ? (
            <BuyVehicle
              toggleBuy={this.toggleBuy}
              page="DDO"
              conditionReport={conditionReport}
              isBuyNow
              carInfo={selectedCar}
            />
          ) : (
            <Loading />
          )
        ) : null}
        {mobileCarInformation &&
        hideMobileNavMenu &&
        !buyVehicle &&
        !openBid &&
        !fullConditionReport ? (
          <MobilePurchaseButtons
            handleBuyNow={this.handleBuyNow}
            handleBidOpen={this.handleBidOpen}
            pageFrom="DDO"
            user={user}
            car={mobileCarInformation}
          />
        ) : null}
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={error}
          autoHideDuration={2000}
          className={classes.errorSnackBar}
          onClose={() => this.setState({ error: null })}
        >
          <SnackbarContent className={classes.errorMessage} message={error} />
        </Snackbar>
        {!buyVehicle &&
        !openBid &&
        !fullConditionReport &&
        !mobileCarInformation ? (
          <MobileFilterMenu
            filterCars={filteredCarList}
            from={"ddo"}
            selectionChanged={this.selectionChanged}
            matchCount={matchCount}
            totalCount={upstreamCars.length}
            resetIsUsedValue={this.resetIsUsedValue}
            upstreamCars={upstreamCars}
            sortCars={this.sortCars}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

DDO.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    searchCriteria: state.ui.searchCriteria,
    accountSetting: state.accountSetting,
    DDOConditionReports: state.cars.DDOConditionReports,
    currentAuction: state.ui.currentAuction,
    auctionTimes: state.ui.auctionTimes,
    mobileCarInformation: state.cars.mobileCarInformation,
    hideMobileNavMenu: state.ui.hideMobileNavMenu,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(DDO));
