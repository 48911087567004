import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import MobileCarListTile from "../components/MobileCarListTile";
import MobileDDOCarListTile from "../components/MobileDDOCarListTile";
import MobileVehicleInformation from "../components/MobileVehicleInformation";
import MobileDDOVehicleInformation from "../components/MobileDDOVehicleInformation";
import GuaranteePayments from "../components/GuaranteePayments";

import { hide_mobile_nav_menu } from "../actions/uiActions";

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "6rem",
    height: "fit-content",
  },
  gridList: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    width: "100%",
    backgroundColor: theme.palette.background.dark,
  },
  GAMDGridList: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    width: "100%",
    backgroundColor: theme.palette.background.dark,
    position: "absolute",
    top: "3rem",
    marginBottom: "3rem",
  },
});

export class MobileCarDisplay extends Component {
  state = {
    openNote: null,
    openTransport: null,
  };

  componentDidUpdate = (prevProps) => {
    const { mobileCarInformation, dispatch } = this.props;
    if (
      mobileCarInformation !== prevProps.mobileCarInformation &&
      !mobileCarInformation
    ) {
      dispatch(hide_mobile_nav_menu(false));
    }
  };

  handleOpenNote = (car) => {
    const { openNote } = this.state;
    if (
      car &&
      ((car.vin && car.vin !== openNote) ||
        (car.vehicleIdentificationNumber &&
          car.vehicleIdentificationNumber !== openNote))
    ) {
      this.setState({
        openNote: car.vin ? car.vin : car.vehicleIdentificationNumber,
        openTransport: null,
      });
    } else {
      this.setState({
        openNote: null,
      });
    }
  };

  handleTransportToggle = (car) => {
    const { openTransport } = this.state;
    if (openTransport !== car.vin) {
      this.setState({
        openTransport: car.vin,
        openNote: null,
      });
    } else {
      this.setState({
        openTransport: null,
      });
    }
  };

  render() {
    const {
      classes,
      carList,
      selectCar,
      selectedCar,
      user,
      onHandleProgramCertCommentsChange,
      onHandleProgramCertNumChange,
      programCertNum,
      programCertComments,
      sourcePage,
      GAMDConditionReports,
      DDOConditionReports,
      toggleImageViewer,
      history,
      dispatch,
      accountSetting,
      handleBidOpen,
      handleBuyNow,
      currentAuction,
      togglePass,
      toggleBuy,
      updateAutoBid,
      updateWatchlist,
      mobileCarInformation,
      selectedVehicleDetails,
      toggleFullConditionReport,
      updateGuaranteePayment,
      mobileGuarantee,
      toggleMobileGuarantee,
    } = this.props;
    const { openNote, openTransport } = this.state;
    return (
      <div className={classes.root}>
        {!mobileCarInformation ? (
          <div
            className={
              sourcePage !== "GAMD" ? classes.gridList : classes.GAMDGridList
            }
            children
          >
            {sourcePage === "GAMD"
              ? carList && carList.length > 0
                ? carList.map((car) => (
                    <MobileCarListTile
                      key={car.vehicleIdentificationNumber}
                      car={car}
                      selectCar={selectCar}
                      user={user}
                      onHandleProgramCertCommentsChange={
                        onHandleProgramCertCommentsChange
                      }
                      onHandleProgramCertNumChange={
                        onHandleProgramCertNumChange
                      }
                      programCertNum={programCertNum}
                      programCertComments={programCertComments}
                      selectedCar={selectedCar}
                      conditionReport={
                        GAMDConditionReports[car.vehicleIdentificationNumber]
                      }
                      history={history}
                      dispatch={dispatch}
                      accountSetting={accountSetting}
                      togglePass={togglePass}
                      toggleBuy={toggleBuy}
                      handleOpenNote={this.handleOpenNote}
                      openNote={openNote}
                      updateGuaranteePayment={updateGuaranteePayment}
                    />
                  ))
                : null
              : carList && carList.length > 0
              ? carList.map((car) => (
                  <MobileDDOCarListTile
                    key={car.vin}
                    car={car}
                    selectCar={selectCar}
                    user={user}
                    onHandleProgramCertCommentsChange={
                      onHandleProgramCertCommentsChange
                    }
                    onHandleProgramCertNumChange={onHandleProgramCertNumChange}
                    programCertNum={programCertNum}
                    programCertComments={programCertComments}
                    selectedCar={selectedCar}
                    conditionReport={DDOConditionReports[car.vin]}
                    toggleImageViewer={toggleImageViewer}
                    history={history}
                    handleBidOpen={handleBidOpen}
                    handleBuyNow={handleBuyNow}
                    currentAuction={currentAuction}
                    updateAutoBid={updateAutoBid}
                    updateWatchlist={updateWatchlist}
                    handleOpenNote={this.handleOpenNote}
                    openNote={openNote}
                    handleTransportToggle={this.handleTransportToggle}
                    openTransport={openTransport}
                    accountSetting={accountSetting}
                    dispatch={dispatch}
                  />
                ))
              : null}
          </div>
        ) : null}
        {mobileCarInformation && !mobileGuarantee ? (
          sourcePage === "GAMD" ? (
            <MobileVehicleInformation
              car={mobileCarInformation}
              conditionReport={
                GAMDConditionReports[
                  mobileCarInformation.vehicleIdentificationNumber
                ]
              }
              selectedVehicleDetails={selectedVehicleDetails}
              toggleFullConditionReport={toggleFullConditionReport}
            />
          ) : (
            <MobileDDOVehicleInformation
              user={user}
              car={mobileCarInformation}
              conditionReport={DDOConditionReports[mobileCarInformation.vin]}
              toggleFullConditionReport={toggleFullConditionReport}
            />
          )
        ) : null}
        {mobileGuarantee && mobileCarInformation ? (
          <GuaranteePayments
            car={mobileCarInformation}
            toggleGuaranteePayment={toggleMobileGuarantee}
            user={user}
            isBuyFlow
            updateGuaranteePayment={updateGuaranteePayment}
          />
        ) : null}
      </div>
    );
  }
}

MobileCarDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MobileCarDisplay);
