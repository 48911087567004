import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, TextField } from "@material-ui/core";

import { sendPurchaseDetailsNotification } from "../services/VehiclePurchaseServices";

const styles = (theme) => ({
  vehicleInfoWrapper: {
    margin: "2rem",
    float: "left",
    width: "95%",
  },
  button: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
  },
  h1Padding: {
    padding: ".5rem",
  },
  fieldWrapper: {
    display: "flex",
    width: "20rem",
    height: "2rem",
    margin: ".5rem",
    marginBottom: "5%",
  },
  GAMDfieldLabel: {
    backgroundColor: theme.palette.GAMD.main,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "6rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  LPPfieldLabel: {
    backgroundColor: theme.palette.grounding.main,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "6rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  DDOfieldLabel: {
    backgroundColor: theme.palette.DDO.main,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "6rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    margin: 0,
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    height: "2rem",
    borderRadius: 0,
  },
  GAMDButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
  },
  LPPButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.grounding.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
  },
  DDOButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.DDO.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
  },
});

export class SendPurchaseDetails extends Component {
  state = {
    toContacts: "",
    ccContacts: "",
    errorSendingEmail: false,
    badToContacts: false,
    errorMessage: null,
  };

  handleSend = async () => {
    const { car, purchaseOptions, toggleShowEmailNotification } = this.props;
    const { toContacts, ccContacts } = this.state;
    if (toContacts === null || toContacts === "") {
      this.setState({
        errorMessage: "Please enter email email adress.",
        errorSendingEmail: true,
        badToContacts: true,
      });
    } else {
      const response = await sendPurchaseDetailsNotification(
        car,
        purchaseOptions,
        toContacts,
        ccContacts
      );
      if (response.status !== "false") {
        toggleShowEmailNotification();
      } else {
        this.setState({
          errorMessage:
            "Something went wrong in sending email notification. Sorry for the inconvenience.",
          errorSendingEmail: true,
        });
      }
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    if (name === "toContacts") {
      this.setState({
        errorMessage: null,
        errorSendingEmail: false,
        badToContacts: false,
      });
    }
  };

  render() {
    const { classes, toggleShowEmailNotification, page } = this.props;
    const { toContacts } = this.state;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    return (
      <div className={classes.vehicleInfoWrapper}>
        <div className={classes.fieldWrapper}>
          <Typography
            className={
              page === "GAMD"
                ? classes.GAMDfieldLabel
                : page === "LPP"
                ? classes.LPPfieldLabel
                : classes.DDOfieldLabel
            }
          >
            <strong>To:</strong>
          </Typography>
          <TextField
            id="toContacts"
            className={classes.textField}
            value={toContacts}
            onChange={this.handleChange("toContacts")}
            margin="normal"
            variant="filled"
            autoComplete="off"
            name="cell"
            InputProps={InputProps}
          />
        </div>
        <div className={classes.confirmWrapper}>
          <Button
            id="cancelEmailSend"
            onClick={() => toggleShowEmailNotification()}
            className={
              page === "GAMD"
                ? classes.GAMDButton
                : page === "LPP"
                ? classes.LPPButton
                : classes.DDOButton
            }
          >
            Cancel
          </Button>
          <Button
            id="sendEmail"
            onClick={() => this.handleSend()}
            className={
              page === "GAMD"
                ? classes.GAMDButton
                : page === "LPP"
                ? classes.LPPButton
                : classes.DDOButton
            }
          >
            Send
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(withStyles(styles)(SendPurchaseDetails)));
