import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import MyPreferences from "../components/MyPreferences";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Typography, Checkbox, Fab, FormControlLabel } from "@material-ui/core";

import * as LoginService from "../services/LoginServices";

const styles = (theme) => ({
  root: {
    width: "95%",
    height: "97%",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
  },
  controlWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    marginBottom: "1rem",
  },
  buttonWrapper: {
    marginLeft: "1rem",
  },
  fab: {
    height: "2rem",
  },
  fabLabel: {
    textTransform: "none",
  },
  checkboxRoot: {
    "&$checked": {
      color: theme.palette.primary.gray,
    },
  },
  checked: {},
  selectorCheck: {
    marginLeft: "1rem",
  },
});
export class ManageUsers extends Component {
  state = {
    data: [],
    onlyActiveData: [],
    showall: false,
    userSettings: false,
    displayEdit: false,
    displayAddUser: false,
    selectedUser: null,
    selectedAccountSetting: {},
  };

  handleUserSettings = () => {
    const { userSettings } = this.state;
    this.setState({ userSettings: !userSettings });
  };

  handleShowAllCheckBox = () => {
    const { showall } = this.state;
    this.setState({ showall: !showall });
  };

  handleAddUser = () => {
    this.setState({ displayAddUser: true });
  };

  handleEditUser = async (user) => {
    const accountSetting = await LoginService.loadAccountSetting(user.userId);

    this.setState({
      selectedUser: user,
      selectedAccountSetting: accountSetting,
      displayEdit: true,
    });
  };

  cancelEditUser = () => {
    this.setState({ displayEdit: false, displayAddUser: false });
  };

  componentDidMount = () => {
    const { data } = this.props;
    this.setState({
      displayData: data,
      onlyActiveData: data.filter((item) => item.status !== "Inactive"),
    });
  };

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    if (data.length > 0 && data !== prevProps.data) {
      this.setState({
        displayData: data,
        onlyActiveData: data.filter((item) => item.status !== "Inactive"),
      });
    }
  };

  render() {
    const { classes, user, accountSetting } = this.props;
    const {
      showall,
      userSettings,
      displayEdit,
      displayAddUser,
      selectedUser,
      selectedAccountSetting,
    } = this.state;
    const cellStyle = {
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
    };
    return (
      <div id="materialTable" className={classes.root}>
        {displayEdit && (
          <MyPreferences
            id="displayEdit"
            user={selectedUser}
            accountSetting={selectedAccountSetting}
            fromAdmin={true}
            addUser={false}
            getCurrentUserData={this.props.getCurrentUserData}
            cancelEditUser={this.cancelEditUser}
          />
        )}
        {displayAddUser && (
          <MyPreferences
            id="displayAddUser"
            user={user}
            accountSetting={accountSetting}
            fromAdmin={true}
            addUser={true}
            getCurrentUserData={this.props.getCurrentUserData}
            cancelEditUser={this.cancelEditUser}
          />
        )}
        {!displayEdit && !displayAddUser && (
          <div className={classes.controlWrapper}>
            <div className={classes.buttonWrapper}>
              <Fab
                id="addUser-btn"
                variant="extended"
                aria-label="Add"
                className={classes.fab}
                classes={{ label: classes.fabLabel }}
                onClick={(evt) => this.handleAddUser(evt)}
              >
                <AddIcon />
                <Typography>Add A User</Typography>
              </Fab>
            </div>
            <div className={classes.buttonWrapper}>
              <Fab
                id="toggle-btn"
                variant="extended"
                aria-label="overview"
                className={classes.fab}
                classes={{ label: classes.fabLabel }}
                onClick={(evt) => this.handleUserSettings(evt)}
              >
                <Typography>
                  {userSettings
                    ? "Show Manage Users"
                    : "Show User Settings Overview"}
                </Typography>
              </Fab>
            </div>
          </div>
        )}
        {!userSettings && !displayEdit && !displayAddUser && (
          <div>
            <FormControlLabel
              id="active-inactive-checkbox"
              className={classes.selectorCheck}
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  value={showall}
                  onClick={(evt) => this.handleShowAllCheckBox()}
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label="Show Inactive & Active Users"
            />
          </div>
        )}
        {!userSettings && !displayEdit && !displayAddUser && (
          <MaterialTable
            id="manager-user-table"
            title={<Typography>Current Users</Typography>}
            columns={[
              {
                title: "First Name",
                field: "firstName",
                cellStyle,
              },
              {
                title: "Last Name",
                field: "lastName",
                cellStyle,
              },
              {
                title: "User Name",
                field: "loginId",
                cellStyle,
              },
              {
                title: "Title",
                field: "userTitle",
                cellStyle,
              },
              {
                title: "Status",
                field: "status",
                cellStyle,
              },
            ]}
            data={showall ? this.state.displayData : this.state.onlyActiveData}
            actions={[
              (rowData) => ({
                iconProps: { id: "update" },
                icon: "edit",
                tooltip: "Update the user",
                onClick: (event, rowData) => {
                  this.handleEditUser(rowData);
                },
                disabled: rowData.userId === user.userId,
              }),
            ]}
            options={{
              headerStyle: {
                backgroundColor: "#EEE",
                textAlign: "center",
                padding: 0,
                fontSize: ".7rem",
              },
              showTitle: true,
              sorting: true,
              search: true,
              paging: true,
              maxBodyHeight: 500,
              actionsColumnIndex: -1,
            }}
          />
        )}
        {userSettings && !displayEdit && !displayAddUser && (
          <MaterialTable
            id="setting-overview-table"
            title={<Typography>User Settings Overview</Typography>}
            columns={[
              {
                title: "First Name",
                field: "firstName",
                cellStyle,
              },
              {
                title: "Last Name",
                field: "lastName",
                cellStyle,
              },
              {
                title: "User Name",
                field: "loginId",
                cellStyle,
                defaultSort: "desc",
              },
              {
                title: "Title",
                field: "userTitle",
                cellStyle,
              },
              {
                title: "Status",
                field: "status",
                cellStyle,
              },
              {
                title: "Location",
                field: "location",
                cellStyle,
              },
              {
                title: "Access Role",
                field: "roles",
                cellStyle,
                render: (rowData) =>
                  rowData.roles.find((item) => item === "Full Admin Access")
                    ? "Full Admin Access"
                    : rowData.roles.map((item) => <div key={item}>{item}</div>),
              },
            ]}
            data={this.state.displayData}
            options={{
              headerStyle: {
                backgroundColor: "#EEE",
                textAlign: "center",
                padding: 0,
                fontSize: ".7rem",
              },
              showTitle: true,
              sorting: true,
              search: true,
              paging: true,
              maxBodyHeight: 500,
            }}
          />
        )}
      </div>
    );
  }
}

ManageUsers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(ManageUsers));
