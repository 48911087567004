import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Button,
  Snackbar,
  SnackbarContent,
  TextField,
} from "@material-ui/core";

import { reset_password_success } from "../actions/userActions";

import * as LoginServices from "../services/LoginServices";
import {
  validatePassword,
  validateStrongerPassword,
} from "../services/ValidationServices";

const styles = (theme) => ({
  passwordResetWrapper: {
    minHeight: "78%",
  },
  container: {
    width: "auto",
    display: "block",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
    },
  },
  standAloneContainer: {
    width: "auto",
    display: "flex",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: "3.5rem",
    },
    color: theme.palette.primary.gray,
    marginTop: "2rem",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `.5rem .5rem`,
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      padding: 0,
    },
  },
  submit: {
    backgroundColor: theme.palette.primary.gray,
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "2.5rem",
      backgroundColor: theme.palette.primary.gray,
      fontSize: ".8rem",
      fontWeight: "bold",
      borderRadius: 0,
    },
  },
  underline: {
    "&:after": {
      borderBottom: `2px solid ${theme.palette.grounding.main}`,
    },
  },
  cardTitle: {
    fontSize: "1rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      marginTop: ".5rem",
      fontWeight: "bold",
    },
  },
  listText: {
    fontSize: theme.font.small,
  },
  errorMessage: {
    marginTop: "10rem",
  },
  successMessage: {
    backgroundColor: theme.palette.DDO.main,
    color: theme.palette.primary.white,
    marginTop: "10rem",
  },
  fieldWrapper: {
    display: "flex",
    width: "20rem",
    height: "2rem",
    margin: ".5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      marginTop: ".5rem",
      marginBottom: ".5rem",
    },
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "12rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      fontSize: ".6rem",
      height: "1.8rem",
    },
  },
  textField: {
    margin: 0,
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    height: "2rem",
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      width: "60%",
    },
  },
  "@global": {
    ".MuiInputBase-root": {
      [theme.breakpoints.down("sm")]: {
        fontSize: ".6rem",
      },
    },
  },
});

export class PasswordReset extends Component {
  state = {
    password: "",
    password2: "",
    oldPassword: "",
    errorMessage: null,
    success: false,
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = async () => {
    const { user, history, fromAccountSetting, dispatch } = this.props;
    const { password, password2, oldPassword } = this.state;

    if (password !== password2) {
      this.setState({
        errorMessage: "Your new passwords do not match.",
      });
      return;
    }
    if (user.showStrongerPassword && user.showStrongerPassword === true) {
      if (!validateStrongerPassword(password)) {
        this.setState({
          errorMessage:
            "Your new password does not meet the minimum validation requirements.",
        });
        return;
      }
    } else if (!validatePassword(password)) {
      this.setState({
        errorMessage:
          "Your new password does not meet the minimum validation requirements.",
      });
      return;
    }
    try {
      const resp = await LoginServices.passwordReset(
        user.userId,
        user.loginId,
        oldPassword,
        password
      );
      if (resp.status === "false") {
        if (resp.message === "Password History Verification Failed!") {
          this.setState({
            errorMessage:
              "Password was not changed, please make sure your new password is not one of your previous 5 passwords.",
          });
        } else {
          this.setState({
            errorMessage: resp.message,
          });
        }
      } else {
        this.setState({
          success: true,
          errorMessage: "Password was changed successfully!",
        });
        if (!fromAccountSetting) {
          dispatch(reset_password_success());
          setTimeout(() => {
            history.replace("/home");
          }, 3000);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { classes, fromAccountSetting, user } = this.props;
    const { errorMessage, success } = this.state;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    return (
      <div className={classes.passwordResetWrapper}>
        <div
          className={
            !fromAccountSetting
              ? classes.standAloneContainer
              : classes.container
          }
          elevation={1}
        >
          <Paper className={classes.paper}>
            <Typography className={classes.cardTitle}>
              Update/Change Your Password
            </Typography>
            <form>
              <div className={classes.fieldWrapper}>
                <Typography className={classes.fieldLabel}>
                  <strong>Old Password:</strong>
                </Typography>
                <TextField
                  id="oldPassword"
                  name="oldPassword"
                  placeholder="Old Password"
                  className={classes.textField}
                  value={this.state.oldPassword}
                  onChange={this.handleChange("oldPassword")}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  type="password"
                  InputProps={InputProps}
                  autoFocus
                  required
                />
              </div>
              <div className={classes.fieldWrapper}>
                <Typography className={classes.fieldLabel}>
                  <strong>New Password:</strong>
                </Typography>
                <TextField
                  id="newPassword"
                  name="newPassword"
                  placeholder="New Password"
                  className={classes.textField}
                  value={this.state.password}
                  onChange={this.handleChange("password")}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.handleSubmit();
                    }
                  }}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  type="password"
                  InputProps={InputProps}
                  autoFocus
                  required
                />
              </div>
              <div className={classes.fieldWrapper}>
                <Typography className={classes.fieldLabel}>
                  <strong>Confirm Password:</strong>
                </Typography>
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className={classes.textField}
                  value={this.state.password2}
                  onChange={this.handleChange("password2")}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.handleSubmit();
                    }
                  }}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  type="password"
                  InputProps={InputProps}
                  autoFocus
                  required
                />
              </div>
              {user.showStrongerPassword &&
              user.showStrongerPassword === true ? (
                <ul>
                  <li>
                    <Typography className={classes.listText}>
                      Minimum 10 characters and Maximum 128 characters
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.listText}>
                      Must contain 1 upper and 1 lower case character
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.listText}>
                      Must contain 1 number
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.listText}>
                      Must contain atleast 1 special character
                    </Typography>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <Typography className={classes.listText}>
                      Minimum 8 characters
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.listText}>
                      Must contain 1 upper and 1 lower case character
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.listText}>
                      Must contain 1 number
                    </Typography>
                  </li>
                </ul>
              )}
              <Button
                id="submitButton"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => this.handleSubmit()}
              >
                Enter
              </Button>
            </form>
          </Paper>
          <Snackbar
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
            open={errorMessage}
            autoHideDuration={3000}
            onClose={() =>
              this.setState({ errorMessage: null, success: false })
            }
          >
            <SnackbarContent
              className={
                !success ? classes.errorMessage : classes.successMessage
              }
              message={errorMessage}
            />
          </Snackbar>
        </div>
      </div>
    );
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(PasswordReset));
