const API_URL = process.env.REACT_APP_API_URL;

export const signUrls = async (urls) => {
  const token = sessionStorage.getItem("token");

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(urls),
  };
  try {
    const response = await fetch(
      `${API_URL}/resource/signCloudFrontUrls`,
      options
    );
    return response.json();
  } catch (err) {
    return { status: false, message: err.message };
  }
};
