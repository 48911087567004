import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Snackbar,
  Button,
} from "@material-ui/core";
import * as AdminService from "../services/AdminServices";
import * as userActions from "../actions/userActions";

const styles = (theme) => ({
  root: {
    width: "90%",
    height: "90%",
  },
  formControl: {
    display: "flex",
    flexDirection: "row",
  },
  fab: {
    marginTop: "1rem",
  },
  button: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.gray,
    fontSize: theme.font.small,
    fontWeight: "bold",
    width: "4rem",
    color: theme.palette.primary.white,
  },
});
export class ManageExclusivityTime extends Component {
  state = {
    selectedPeriod: "",
    message: "",
  };

  componentDidMount = () => {
    const { user } = this.props;
    this.setState({
      selectedPeriod: user.dlrExculsivityExtensionValue.toString(),
    });
  };

  handleChange = (event) => {
    this.setState({
      selectedPeriod: event.target.value,
    });
  };

  handleClose = () => {
    this.setState({ ...this.state, open: false, message: "" });
  };

  handleSubmit = async () => {
    const { user, dispatch } = this.props;
    const { selectedPeriod } = this.state;

    const response = await AdminService.updateDealerExclusivity(
      user.dealerId,
      selectedPeriod
    );

    if (response === "success") {
      this.setState({ open: true, message: "Dealer Exclusivity Updated" });
      dispatch(userActions.update_dealer_exclusivity(+selectedPeriod));
    } else {
      this.setState({
        open: true,
        message: "Service Unavailable. Please try again later.",
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { selectedPeriod } = this.state;
    return (
      <div id="radio-btn" className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <Typography>
            {" "}
            Number of hours to EXTEND the Dealers 'Exclusivity' period:
          </Typography>
          <RadioGroup
            id="exclusivity_time"
            aria-label="Number of hours to EXTEND the Dealers 'Exclusivity' period:"
            name="exclusivity_time"
            className={classes.group}
            value={selectedPeriod}
            onChange={(evt) => this.handleChange(evt)}
          >
            <FormControlLabel
              value="0"
              control={<Radio color="default" />}
              label="0 Hours"
            />
            <FormControlLabel
              value="24"
              control={<Radio color="default" />}
              label="24 Hours"
            />
          </RadioGroup>
        </FormControl>
        <Button
          id="submitButton"
          variant="contained"
          className={classes.button}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
        {this.state.message ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key={`top,center`}
            open={this.state.open}
            onClose={this.handleClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.message}</span>}
          />
        ) : null}
      </div>
    );
  }
}

ManageExclusivityTime.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles)(ManageExclusivityTime)
);
