export const ROUTE_URL = {
  ADVANCE_SEARCH: "/advancesearch",
  MOBILITY: "/mobility",
  HOME: "/home",
  CALLING_INFORMATION: "/callingInformation",
  FORGOT_PASSWORD: "/forgotpassword",
  CHANGE_PASSWORD: "/changePassword",
  GROUND: "/ground",
  GAMD: "/GAMD",
  PRICEQUOTE: "/priceQuote",
};

export const API_URL = {
  LEXUS_DEALER_DIRECT: "https://lexusdealerdirect.com",
  HOME_PAGE_ITUNES_URL:
    "https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=",
};

export const TAB_LISTS = {
  tabList: [
    "User Settings",
    "Help",
    "Legal",
    "Privacy",
    "Log Out of Dealer Direct",
  ],
  tabList_carfax: [
    "User Settings",
    "Help",
    "Legal",
    "Privacy",
    "Log Out of CARFAX",
    "Log Out of Dealer Direct",
  ],
  iOsTabList: [
    "User Settings",
    "Download Mobile App",
    "Help",
    "Legal",
    "Privacy",
    "Log Out of Dealer Direct",
  ],
  iOsTabList_carfax: [
    "User Settings",
    "Download Mobile App",
    "Help",
    "Legal",
    "Privacy",
    "Log Out of CARFAX",
    "Log Out of Dealer Direct",
  ],
  mobilityTabList: [
    "Home",
    "Reports",
    "Legal",
    "Privacy",
    "Log Out of Dealer Direct",
  ],
  mobilityTabList_carfax: [
    "Home",
    "Reports",
    "Legal",
    "Privacy",
    "Log Out of CARFAX",
    "Log Out of Dealer Direct",
  ],
};
