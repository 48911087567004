import React, { Component } from "react";
import { connect } from "react-redux";
import Viewer from "react-viewer";
import "react-viewer/dist/index.css";

import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  Button,
  Link,
  Typography,
  CardContent,
  CardActions,
} from "@material-ui/core";
import Notes from "../components/Notes";
import TransportationQuote from "../components/TransportationQuote";
import BidHistory from "../components/BidHistory";

import * as NotesServices from "../services/NotesServices";
import { returnAccountType } from "../services/HelperServices";

import ReactSVG from "react-svg";
import groundNavIcon from "../assets/groundNavIcon.svg";
import notesIcon from "../assets/notesIcon.svg";

const styles = (theme) => ({
  card: {
    display: "flex",
    margin: ".5rem",
    borderRadius: 0,
    maxHeight: "11rem",
    width: "97%",
    position: "relative",
  },
  selectedCard: {
    display: "flex",
    margin: ".5rem",
    borderRadius: 0,
    maxHeight: "11rem",
    width: "97%",
    position: "relative",
    boxShadow: `0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.5)`,
    backgroundColor: theme.palette.light.gray,
    marginBottom: 0,
  },
  infoCard: {
    display: "flex",
    margin: ".5rem",
    borderRadius: 0,
    width: "97%",
    position: "relative",
    marginTop: ".25rem",
  },
  cardWrapper: {
    display: "flex",
    position: "relative",
    alignItems: "flex-start",
    justifyContent: "left",
    flexDirection: "column",
    width: "20%",
    padding: 0,
  },
  winlossListCardImage: {
    margin: ".125rem",
    padding: ".125rem",
    backgroundColor: theme.palette.primary.white,
    maxWidth: "23%",
  },
  winlossListCardInfoParentWrapper: {
    margin: ".125rem",
    padding: ".125rem",
    backgroundColor: theme.palette.primary.white,
    minWidth: "60%",
    fontSize: ".75rem",
  },
  winlossListCardInfoChildWrapper: {
    display: "flex",
  },
  winlossListCardInfoChildLeft: {
    width: "50%",
  },
  winlossListCardInfoChildRight: {
    width: "50%",
  },
  winlossListCardLinks: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "15%",
    minWidth: "4rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
  },
  carReportLink: {
    width: "4rem",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  winlossListCardForm: {
    margin: ".125rem",
    padding: ".125rem",
    backgroundColor: theme.palette.primary.white,
    minWidth: "30%",
    marginLeft: ".125rem",
    marginRight: ".125rem",
    fontSize: ".75rem",
  },
  buttonWrapper: {
    alignSelf: "flex-end",
    textAlign: "bottom",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignContent: "space-between",
    height: "9rem",
  },
  smallButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
  media: {
    width: "100%",
    height: "8rem",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  hasNotes: {
    color: theme.palette.primary.redHighlight,
  },
  blankNotes: {},
  contentWrapper: {
    display: "flex",
    margin: ".5rem",
    position: "relative",
    cursor: "pointer",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "76%",
    },
  },
  textWrapper: {
    display: "flex",
    height: "85%",
    marginLeft: "1rem",
    justifyContent: "space-around",
    position: "relative",
    top: "1.75rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  textColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "1%",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "11rem",
    },
  },
  cardText: {
    fontSize: "76%",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("md")]: {
      fontSize: "59%",
    },
  },
  boldedText: {
    fontWeight: "bold",
  },
  optionsText: {
    fontWeight: "bold",
    fontSize: "1.4vh",
  },
  cardTitle: {
    marginLeft: "1rem",
    fontSize: "1.2rem",
    position: "absolute",
    width: "23rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: ".5rem",
    },
  },
  selectedCardTitle: {
    marginLeft: "1rem",
    fontSize: "1.2rem",
    position: "absolute",
    width: "23rem",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      marginLeft: ".5rem",
    },
  },
  distanceWrapper: {
    display: "flex",
    alignItems: "center",
  },
  DDOColorText: {
    color: theme.palette.DDO.main,
  },
  priceDisplayTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    width: "11rem",
    marginRight: ".7%",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
  },
  smallButton: {
    height: "2.5rem",
    minWidth: 0,
  },
  redText: {
    color: theme.palette.primary.redHighlight,
  },
  redBoldedText: {
    fontWeight: "bold",
    color: theme.palette.primary.redHighlight,
  },
});

const awsUrl = process.env.REACT_APP_WCM_CONTENT;

export class WinLossCard extends Component {
  state = {
    viewerVisible: false,
    openNote: false,
    notes: [],
    vehicleHasNotes: false,
    openTransportationQuote: false,
    showBidHistory: false,
  };

  componentWillMount = () => {
    const { isNoteAvailable } = this.props.car;
    this.setState({
      vehicleHasNotes: isNoteAvailable ? true : false,
    });
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: true,
    });
  };

  toggleNote = async () => {
    const { dealerId } = this.props.user;
    const { inventoryId } = this.props.car;
    const response = await NotesServices.getNotes(inventoryId, dealerId);
    this.setState({
      openNote: !this.state.openNote,
      notes: response,
      openTransportationQuote: false,
      showBidHistory: false,
    });
  };

  toggleTransportationQuote = () => {
    this.setState({
      openTransportationQuote: !this.state.openTransportationQuote,
      showBidHistory: false,
      openNote: false,
    });
  };

  addedNote = async () => {
    const { dealerId } = this.props.user;
    const { inventoryId } = this.props.car;
    const response = await NotesServices.getNotes(inventoryId, dealerId);
    this.setState({
      notes: response,
      vehicleHasNotes: true,
      openNote: false,
    });
  };

  toggleBidCount = () => {
    this.setState({
      showBidHistory: !this.state.showBidHistory,
      openTransportationQuote: false,
      openNote: false,
    });
  };

  render() {
    const {
      viewerVisible,
      openNote,
      notes,
      vehicleHasNotes,
      openTransportationQuote,
      showBidHistory,
    } = this.state;
    const {
      classes,
      car,
      selectCar,
      selectedCarConditionReport,
      isWinCard,
      user,
      selectedCar,
    } = this.props;
    const {
      year,
      make,
      model,
      series,
      vin,
      accountType,
      engine,
      color,
      distance,
      myCurrentAutoBid,
      bidCount,
      option,
      isImageAvailable,
      currentBid,
      purchaseAmt,
      saleEventItemId,
      highBidder,
      isUserWinning,
    } = car;
    return (
      <React.Fragment>
        {selectedCarConditionReport &&
        selectedCarConditionReport.inspectionImageDTO ? (
          <Viewer
            visible={viewerVisible}
            zoomSpeed={0.3}
            noClose={true}
            onMaskClick={(evt) => this.setState({ viewerVisible: false })}
            onClose={() => this.setState({ viewerVisible: false })}
            images={selectedCarConditionReport.inspectionImageDTO.map(
              (image) => {
                return { src: image.imageUrl, alt: image.damageImageCaption };
              }
            )}
          />
        ) : null}
        <Card
          className={
            car.vin === selectedCar.vin ? classes.selectedCard : classes.card
          }
          id="carListTile"
          onClick={() => selectCar(car)}
          elevation={car.vin === selectedCar.vin ? 24 : 0}
        >
          <CardActions className={classes.cardWrapper}>
            <div className={classes.imageWrapper}>
              <img
                id="primaryImage"
                src={
                  isImageAvailable
                    ? car.imagePathFc
                    : awsUrl + "images/toyota/help/logo/TFS_NoImage-logo.png"
                }
                className={classes.media}
                alt={`${car.make} ${car.model}`}
                onClick={() => this.toggleImageViewer()}
              />
            </div>
            <div className={classes.smallButtonWrapper}>
              <Button
                id="noteButton"
                className={classes.smallButton}
                onClick={() => this.toggleNote()}
              >
                <ReactSVG
                  className={
                    vehicleHasNotes ? classes.hasNotes : classes.blankNotes
                  }
                  src={notesIcon}
                  svgStyle={{ width: "1.3rem" }}
                />
              </Button>
              <Button
                id="transportButton"
                className={classes.smallButton}
                onClick={this.toggleTransportationQuote}
              >
                <ReactSVG
                  className={classes.transportIcon}
                  src={groundNavIcon}
                  svgStyle={{ width: "1.3rem" }}
                />
              </Button>
            </div>
          </CardActions>
          <div className={classes.contentWrapper}>
            <div style={{ width: "100%" }}>
              <Typography
                className={
                  car.vin === selectedCar.vin
                    ? classes.selectedCardTitle
                    : classes.cardTitle
                }
              >
                {`${year} ${make} ${model} `}
                {series ? series : null}
              </Typography>
              <div className={classes.textWrapper}>
                <div className={classes.textColumn}>
                  <Typography className={classes.cardText}>
                    VIN: <span className={classes.boldedText}>{vin}</span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Inspection Mileage:{" "}
                    <span className={classes.boldedText}>
                      {car.inspectionMileage.toLocaleString()}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Options:{" "}
                    <span className={classes.optionsText}>{option}</span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Account Type:{" "}
                    <span
                      className={
                        returnAccountType(accountType) === "Company Car"
                          ? classes.redBoldedText
                          : classes.boldedText
                      }
                    >
                      {returnAccountType(car.accountType)}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Promotion:{" "}
                    <span className={classes.boldedText}>
                      {car.programDtos !== undefined
                        ? car.programDtos[0].programName
                        : null}
                    </span>
                  </Typography>
                </div>
                <div className={classes.textColumn}>
                  <Typography className={classes.cardText}>
                    Color:{" "}
                    <span className={classes.boldedText}>
                      {color.slice(0, 13)}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Engine: <span className={classes.boldedText}>{engine}</span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Distance:{" "}
                    <span className={classes.boldedText}>
                      {distance.toFixed(2)} mi
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    {car.autoGradeAvailable ? "Auto-grade" : "Grade:"}{" "}
                    <span className={classes.boldedText}>{car.grade}</span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Announcement: <span className={classes.boldedText}></span>
                  </Typography>
                </div>
                <CardContent className={classes.priceDisplayTextColumn}>
                  <Typography className={classes.cardText}>
                    <span className={classes.boldedText}>
                      {car.saleEventName}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    <span className={classes.boldedText}>My AutoBid: </span>
                    <span className={classes.DDOColorText}>
                      {myCurrentAutoBid.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </Typography>
                  {isWinCard ? (
                    <Typography className={classes.cardText}>
                      <span className={classes.boldedText}>
                        Purchase Price:{" "}
                      </span>
                      <span className={classes.DDOColorText}>
                        {purchaseAmt &&
                          purchaseAmt.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                      </span>
                    </Typography>
                  ) : (
                    <Typography className={classes.cardText}>
                      <span className={classes.boldedText}>Winning Bid: </span>
                      <span className={classes.redText}>
                        {currentBid &&
                          currentBid.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                      </span>
                    </Typography>
                  )}
                  <Typography className={classes.cardText}>
                    <span className={classes.boldedText}>Bids: </span>
                    <span className={classes.DDOColorText}>
                      {bidCount === 0 ? (
                        0
                      ) : (
                        <Link
                          id="bidCountLink"
                          component="button"
                          onClick={() => this.toggleBidCount()}
                        >
                          {bidCount}
                        </Link>
                      )}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    <span className={classes.boldedText}>High Bidder: </span>
                    <span
                      className={
                        isUserWinning ? classes.DDOColorText : classes.redText
                      }
                    >
                      {highBidder}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </div>
          </div>
        </Card>
        <div className={classes.infoCard}>
          {openNote ? (
            <Notes
              toggleNote={this.toggleNote}
              notes={notes}
              user={user}
              addNote={this.addedNote}
              car={car}
            />
          ) : null}
          {openTransportationQuote ? (
            <TransportationQuote
              car={car}
              toggleTransportationQuote={this.toggleTransportationQuote}
            />
          ) : null}
          {showBidHistory ? (
            <BidHistory
              saleEventItemId={saleEventItemId}
              toggleBidCount={this.toggleBidCount}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(WinLossCard));
