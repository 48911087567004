import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  Button,
  CardActions,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import Loading from "../components/Loading";

import ReactSVG from "react-svg";
import circledCheck from "../assets/circledCheck.svg";
import bidIcon from "../assets/bidIcon.svg";
import shoppingCartLarger from "../assets/shoppingCartLarger.svg";
import rotateRight from "../assets/rotateRight.svg";

import * as VehiclePurchaseServices from "../services/VehiclePurchaseServices";
import * as userAccess from "../services/UserRoles";

import {
  select_car_for_purchase,
  select_pass_car,
} from "../actions/carActions";

const styles = (theme) => ({
  cardActions: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  DDOCardActions: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    zIndex: 9999,
    display: "flex",
    padding: 0,
  },
  buyButton: {
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "50%",
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.button.main,
      "@media (hover: none)": {
        backgroundColor: theme.palette.button.main,
        "&:active": {
          backgroundColor: theme.palette.button.main,
        },
      },
    },
  },
  passButton: {
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.pass,
    width: "50%",
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.button.pass,
      "@media (hover: none)": {
        backgroundColor: theme.palette.button.pass,
        "&:active": {
          backgroundColor: theme.palette.button.pass,
        },
      },
    },
  },
  guaranteedPaymentButton: {
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.guaranteedPayment,
    width: "100%",
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.button.guaranteedPayment,
      "@media (hover: none)": {
        backgroundColor: theme.palette.button.guaranteedPayment,
        "&:active": {
          backgroundColor: theme.palette.button.guaranteedPayment,
        },
      },
    },
  },
  bidButton: {
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.DDO.main,
    width: "50%",
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.button.guaranteedPayment,
      "@media (hover: none)": {
        backgroundColor: theme.palette.button.guaranteedPayment,
        "&:active": {
          backgroundColor: theme.palette.button.guaranteedPayment,
        },
      },
    },
  },
  upperButtonWrapper: {
    display: "flex",
    width: "100%",
  },
  smallButtonLabel: {
    marginLeft: "2%",
    marginRight: "2%",
    justifyContent: "center",
    fontSize: ".6rem",
  },
});

const noPurchaseStatuses = [
  "DLR_INELIGIBLE_PUR",
  "DLR_ARBITRATION",
  "DLR_OFRD_DB_AUCTION",
];

export class MobilePurchaseButtons extends PureComponent {
  state = {
    buyLoading: false,
  };

  handleBuy = async () => {
    const { toggleBuy, dispatch, car } = this.props;
    const {
      inventoryDTO,
      saleEventItemId,
      vehicleIdentificationNumber,
      accountType,
    } = this.props.car;
    const { userId, dealerId, loginId } = this.props.user;
    if (accountType === "BALLOON") {
      dispatch(select_car_for_purchase(car));
      toggleBuy(vehicleIdentificationNumber);
    } else {
      let buyDetails = await VehiclePurchaseServices.buy(
        inventoryDTO.id,
        userId,
        dealerId,
        saleEventItemId,
        loginId
      );
      if (buyDetails.status !== "false") {
        dispatch(select_car_for_purchase(buyDetails));
        toggleBuy(vehicleIdentificationNumber);
      } else {
        this.setState({
          buyLoading: false,
          error: "Cannot purchase at this time.",
        });
        console.log("Error occured while retrieving buy details", buyDetails);
      }
    }
  };

  handlePass = () => {
    const { dispatch, car, togglePass } = this.props;
    dispatch(select_pass_car(car));
    togglePass();
  };

  render() {
    const { buyLoading, error } = this.state;
    const {
      classes,
      history,
      user,
      car,
      pageFrom,
      toggleMobileGuarantee,
      handleBuyNow,
      handleBidOpen,
    } = this.props;
    const { displayGuaranteePaymentsOnly } = this.props.car;
    return (
      <React.Fragment>
        <CardActions
          className={
            pageFrom === "GAMD" ? classes.cardActions : classes.DDOCardActions
          }
        >
          {noPurchaseStatuses.indexOf(car.dealerFacingStatus) === -1 &&
          car.dealerFacingStatus !== "DLR_BUY_NOW" &&
          car.dlrFacingStatus !== 27107 &&
          car.dealerFacingStatus !== "DLR_BID_NOW" &&
          car.dlrFacingStatus !== 27106 ? (
            <React.Fragment>
              <div className={classes.upperButtonWrapper}>
                {userAccess.canPurchaseVehicles(user.userRoles) ? (
                  <Button
                    id="buyButton"
                    className={classes.buyButton}
                    classes={{ label: classes.smallButtonLabel }}
                    size="small"
                    onClick={
                      !buyLoading
                        ? (evt) => {
                            evt.stopPropagation();
                            history.push(
                              pageFrom === "watchList"
                                ? "/watchlist"
                                : pageFrom === "advanceSearch"
                                ? "/advancesearch"
                                : "/GAMD"
                            );
                            this.setState({
                              buyLoading: true,
                            });
                            this.handleBuy();
                          }
                        : null
                    }
                  >
                    {!buyLoading ? (
                      <React.Fragment>
                        <ReactSVG
                          svgStyle={{ marginRight: "1rem", height: "1.5rem" }}
                          src={shoppingCartLarger}
                        />
                        Buy
                      </React.Fragment>
                    ) : (
                      <Loading color={"#FFFFFF"} />
                    )}
                  </Button>
                ) : null}
                {!car.passedVehicle &&
                car.dealerDecisionCode !== "DLR_EXCL_EXP" ? (
                  <Button
                    id="passButton"
                    className={classes.passButton}
                    classes={{ label: classes.smallButtonLabel }}
                    size="small"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      history.push(
                        pageFrom === "watchList"
                          ? "/watchlist"
                          : pageFrom === "advanceSearch"
                          ? "/advancesearch"
                          : "/GAMD"
                      );
                      this.handlePass();
                    }}
                    disabled={buyLoading}
                  >
                    <ReactSVG
                      svgStyle={{ marginRight: "1rem", height: "1.5rem" }}
                      src={rotateRight}
                    />
                    Pass
                  </Button>
                ) : null}
              </div>
              {displayGuaranteePaymentsOnly ? (
                <Button
                  id="guaranteeButton"
                  className={classes.guaranteedPaymentButton}
                  classes={{ label: classes.smallButtonLabel }}
                  size="small"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    toggleMobileGuarantee();
                  }}
                >
                  <ReactSVG
                    svgStyle={{ height: "1.5rem" }}
                    src={circledCheck}
                  />
                  Guarantee
                </Button>
              ) : null}
            </React.Fragment>
          ) : null}
          {(car.dealerFacingStatus === "DLR_BUY_NOW" ||
            car.dlrFacingStatus === 27107 ||
            car.dealerFacingStatus === "DLR_BID_NOW" ||
            car.dlrFacingStatus === 27106) &&
          userAccess.canPurchaseVehicles(user.userRoles) ? (
            <React.Fragment>
              <Button
                id="buyNowButton"
                className={classes.buyButton}
                size="small"
                disabled={
                  car.dealerFacingStatus === "DLR_BID_NOW" ||
                  car.dlrFacingStatus === 27106 ||
                  car.buyNowDisabled
                }
                onClick={(evt) => {
                  history.push(
                    pageFrom === "watchList"
                      ? "/watchlist"
                      : pageFrom === "advanceSearch"
                      ? "/advancesearch"
                      : "/DDO"
                  );
                  evt.stopPropagation();
                  handleBuyNow(car.saleEventItemId);
                }}
              >
                <ReactSVG
                  svgStyle={{ marginRight: "1rem", height: "1.5rem" }}
                  src={shoppingCartLarger}
                />
                BUY NOW
              </Button>
              <Button
                id="bidButton"
                className={classes.bidButton}
                size="small"
                disabled={!car.bidNowAutoBidButtonEnabled}
                onClick={(evt) => {
                  history.push(
                    pageFrom === "watchList"
                      ? "/watchlist"
                      : pageFrom === "advanceSearch"
                      ? "/advancesearch"
                      : "/DDO"
                  );
                  evt.stopPropagation();
                  handleBidOpen(car);
                }}
              >
                <ReactSVG src={bidIcon} style={{ fill: "currentColor" }} /> BID
              </Button>
            </React.Fragment>
          ) : null}
        </CardActions>
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={error}
          autoHideDuration={2000}
          className={classes.errorSnackBar}
          onClose={() => this.setState({ error: null })}
        >
          <SnackbarContent className={classes.errorMessage} message={error} />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    hideMobileNavMenu: state.ui.hideMobileNavMenu,
    mobileCarInformation: state.cars.mobileCarInformation,
  };
};

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(MobilePurchaseButtons))
);
