export async function getSingleConditionReport(inventoryId, userId, dealerId) {
  const options = {
    method: "GET",
  };
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(
      `${API_URL}/conditionReport/conditionReportDetails?inventoryId=${inventoryId}&userId=${userId}&dealerId=${dealerId}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      console.log(
        "Fetching condition report failed for",
        inventoryId,
        userId,
        dealerId
      );
      return null;
    }
  } catch {
    console.log(
      "Fetching condition report failed for",
      inventoryId,
      userId,
      dealerId
    );
    return null;
  }
}
