import React, { Component } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TablePagination,
  Button,
  FormLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@material-ui/core";

import WatchlistCardList from "../components/WatchlistCardList";
import WinLossCardList from "../components/WinLossCardList";

import * as WatchListServices from "../services/WatchListServices";
import * as userAccess from "../services/UserRoles";
import * as AmeService from "../services/AmeService";

import { set_nav_location, hide_mobile_nav_menu } from "../actions/uiActions";
import {
  watchlist_count,
  remove_selected_mobile_car,
  select_mobile_car,
} from "../actions/carActions";

import ReactSVG from "react-svg";
import watchlistIcon from "../assets/watchlistIcon.svg";

const AME_TIMEOUT = process.env.REACT_APP_AME_TIMEOUT;

const styles = (theme) => ({
  root: {
    height: "79%",
    [theme.breakpoints.down("md")]: {
      height: "82%",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh",
    },
  },
  tabsRoot: {
    borderBottom: "1px solid #C2BFBF",
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  wrapper: {
    backgroundColor: theme.palette.secondary.gray,
    height: "100%",
    display: "flex",
  },
  pageHeader: {
    height: "3.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pagination: {
    minHeight: 0,
    marginRight: "1rem",
  },
  titleWrapper: {
    display: "flex",
    height: "2.5rem",
    alignItems: "center",
    marginLeft: "1rem",
    color: theme.palette.primary.main,
  },
  titleTextWrapper: {
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    height: "100%",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
    width: "5.3rem",
  },
  titleText: {
    color: theme.palette.primary.main,
    fontSize: ".8rem",
    width: "6rem",
    marginBottom: ".5rem",
    fontWeight: "bold",
  },
  buttonWrapper: {
    marginLeft: "1rem",
    width: "23rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      width: "16rem",
    },
  },
  buttonText: {
    marginRight: ".5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.font.small,
    },
  },
  noButtonUnderline: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    borderBottom: "5px solid transparent",
    width: "7rem",
    [theme.breakpoints.down("md")]: {
      width: "5rem",
    },
  },
  buttonUnderline: {
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    width: "7rem",
    minHeight: "100%",
    [theme.breakpoints.down("md")]: {
      width: "5rem",
    },
  },
  navButton: {
    height: "1.5rem",
    lineHeight: 0,
    padding: 0,
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    marginBottom: ".5rem",
    width: "7rem",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      width: "5rem",
    },
  },
  displayWinCount: {
    textAlign: "right",
    paddingRight: "1.25rem",
    paddingBottom: ".5rem",
    [theme.breakpoints.down("md")]: {
      paddingRight: ".25rem",
      paddingBottom: 0,
    },
  },
  displaySelect: {
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    paddingRight: ".5rem",
  },
  displayIcon: {
    position: "relative",
    top: 0,
  },
  label: {
    fontSize: theme.font.small,
    color: theme.palette.primary.black,
    width: "5rem",
    marginRight: ".5rem",
  },
  highlightedButtonText: {
    color: theme.palette.primary.main,
    marginRight: ".5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.font.small,
    },
  },
  watchlistIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    height: "2rem",
    width: "2rem",
    marginRight: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileButtonWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "2.5rem",
    marginTop: "3.5rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileButton: {
    height: "100%",
    width: "33.333%",
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
  },
  mobileButtonText: {
    fontSize: theme.font.small,
  },
  selectedMobileButton: {
    height: "100%",
    width: "33.333%",
    backgroundColor: theme.palette.secondary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.gray,
    },
  },
  mobilePagination: {
    minHeight: 0,
    marginTop: ".25rem",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileToolbar: {
    minHeight: 0,
    height: "2rem",
  },
  mobileDisplayWinCount: {
    textAlign: "right",
    paddingRight: "1.25rem",
    paddingBottom: ".5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ".25rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  "@global": {
    ".MuiIconButton-root": {
      padding: "0",
    },
  },
});

export class WatchList extends Component {
  state = {
    value: 0,
    watchListCount: 0,
    lossCount: 0,
    winCount: 0,
    buyVehicle: false,
    ddoBuyVehicle: false,
    passVehicle: false,
    openBid: false,
    displayedTab: 1,
    listLoading: true,
    page: 0,
    rowsPerPage: 5,
    total: 0,
    start: 0,
    end: 5,
    watchListData: {},
    winListData: {},
    lossListData: {},
    hideTab: false,
    fullConditionReport: false,
    displayedCars: [],
    saleEventItemIds: [],
  };

  componentWillMount = () => {
    const { navLocation, dispatch, user, history } = this.props;
    this.BID_TIMEOUT = null;
    if (
      !userAccess.canViewOnly(user.userRoles) &&
      !userAccess.canPurchaseVehicles(user.userRoles)
    ) {
      history.replace("/home");
    } else {
      if (navLocation !== "watchlist") {
        dispatch(set_nav_location("watchlist"));
      }
      this.populateData();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { buyVehicle, passVehicle, ddoBuyVehicle, openBid } = this.state;
    const { history, mobileCarInformation } = this.props;
    if (
      history.action === "POP" &&
      history.location.pathname === "/watchlist" &&
      (buyVehicle || passVehicle || ddoBuyVehicle || openBid)
    ) {
      this.setState({
        buyVehicle: false,
        passVehicle: false,
        ddoBuyVehicle: false,
        openBid: false,
        hideTab: false,
      });
    }
    if (
      mobileCarInformation !== prevProps.mobileCarInformation &&
      !mobileCarInformation
    ) {
      this.setState({
        fullConditionReport: false,
        buyVehicle: false,
        passVehicle: false,
        ddoBuyVehicle: false,
        openBid: false,
        hideTab: false,
      });
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.BID_TIMEOUT);
  };

  getConnectionToAmeService = async () => {
    const { saleEventItemIds } = this.state;
    clearTimeout(this.BID_TIMEOUT);
    try {
      const clientId = await AmeService.startConnection(saleEventItemIds);
      this.setState({ clientId: clientId });
      this.getBidHistory();
    } catch (err) {
      console.log("Error occurred while connecting to AME:" + err.message);
      this.setState({
        errorMessage: "Error occurred while connecting to AME:" + err.message,
      });
    }
  };

  getBidHistory = async () => {
    const { clientId } = this.state;
    try {
      const resp = await AmeService.getBidHistory(clientId);
      if (resp[0].successful) {
        if (resp[1]) {
          this.updateCarsFromAmeResponse(resp.slice(1));
        }
        this.BID_TIMEOUT = setTimeout(() => this.getBidHistory(), AME_TIMEOUT);
      } else {
        this.BID_TIMEOUT = setTimeout(
          () => this.getConnectionToAmeService(),
          AME_TIMEOUT
        );
      }
    } catch (err) {
      this.setState({
        errorMessage: "Error occurred while connecting to AME:" + err.message,
      });
      this.BID_TIMEOUT = setTimeout(
        () => this.getConnectionToAmeService(),
        AME_TIMEOUT
      );
    }
  };

  updateCarsFromAmeResponse = (cars) => {
    const { displayedCars } = this.state;
    const tempCar = JSON.parse(JSON.stringify(displayedCars));
    let updateCars = false;
    cars.map((car) => {
      let carToUpdate = tempCar.find((allCar) => {
        return (
          Number(allCar.saleEventItemId) ===
          Number(car.data.item.saleEventItemId)
        );
      });
      if (carToUpdate) {
        if (car.data.item.statusLst[0] === "sold") {
          carToUpdate.buyNowDisabled = true;
          carToUpdate.isSold = true;
          carToUpdate.bidNowAutoBidButtonEnabled = false;
          carToUpdate.winningUserID = car.data.item.winningUserID;
        } else {
          carToUpdate.bidPlaced = true;
          carToUpdate.bidNowAutoBidButtonEnabled = true;
          carToUpdate.autoBidButtonEnabled = true;
          carToUpdate.currentBid = Number(
            car.data.item.bidAmount.replace(",", "")
          );
          carToUpdate.buyNowDisabled = true;
          carToUpdate.inProgress = true;
          carToUpdate.highBidder = car.data.item.bidderName;
          carToUpdate.bidCount = +car.data.item.numberOfBids;
          carToUpdate.winningUserID = +car.data.item.winningUserID;
        }
        return (updateCars = true);
      } else {
        return null;
      }
    });
    if (updateCars) {
      return this.setState({ displayedCars: tempCar });
    }
  };

  populateData = async () => {
    const { dispatch } = this.props;
    const { start, end } = this.state;
    let watchListData = await this.loadWatchlistData();
    const winListData = await this.loadWinListData(1);
    const lossListData = await this.loadLossListData(1);
    let newWatchListData = watchListData.filter((car) => !car.isSold);
    const saleEventItemIds = newWatchListData.map((item) => {
      return item.saleEventItemId;
    });
    dispatch(watchlist_count(newWatchListData.length));
    this.setState({
      watchListCount: newWatchListData.length,
      winCount: winListData.length,
      lossCount: lossListData.length,
      total: newWatchListData.length,
      displayedCars: newWatchListData.slice(start, end),
      watchListData: newWatchListData,
      saleEventItemIds,
    });
  };

  updateWatchlistData = async () => {
    const { start, end } = this.state;
    let watchListData = await this.loadWatchlistData();
    const winListData = await this.loadWinListData(1);
    const lossListData = await this.loadLossListData(1);
    watchListData = watchListData.filter((car) => !car.isSold);
    const saleEventItemIds = watchListData.map((item) => {
      return item.saleEventItemId;
    });
    this.setState({
      watchListCount: watchListData.length,
      total: watchListData.length,
      displayedCars: watchListData.slice(start, end),
      winCount: winListData.length,
      lossCount: lossListData.length,
      watchListData,
      saleEventItemIds,
    });
  };

  toggleBuyVehicle = (bought, vin) => {
    const { displayedCars } = this.state;
    const { watchlistCount, dispatch } = this.props;
    let newDisplay = JSON.parse(JSON.stringify(displayedCars));
    if (bought) {
      newDisplay = newDisplay.filter((filterCar) => filterCar.vin !== vin);
      dispatch(watchlist_count(watchlistCount - 1));
    }
    this.setState({
      buyVehicle: !this.state.buyVehicle,
      hideTab: !this.state.buyVehicle,
      fullConditionReport: bought ? false : this.state.fullCondtionReport,
      displayedCars: newDisplay,
    });
  };

  toggleDDOBuyVehicle = (car, bought) => {
    const { dispatch } = this.props;
    const { displayedCars } = this.state;
    let newDisplay = JSON.parse(JSON.stringify(displayedCars));
    if (car && (car.vin || car.vehicleIdentificationNumber)) {
      dispatch(remove_selected_mobile_car());
      dispatch(hide_mobile_nav_menu(false));
    }
    newDisplay = bought
      ? displayedCars.filter(
          (filterCar) =>
            (filterCar.vin
              ? filterCar.vin
              : filterCar.vehicleIdentificationNumber) !==
            (car.vin ? car.vin : car)
        )
      : displayedCars;
    this.setState({
      ddoBuyVehicle: !this.state.ddoBuyVehicle,
      hideTab: !this.state.ddoBuyVehicle,
      displayedCars: newDisplay,
    });
  };

  togglePassVehicle = () => {
    this.setState({
      passVehicle: !this.state.passVehicle,
      hideTab: !this.state.passVehicle,
    });
  };

  updateWatchListDisplay = (cars) => {
    this.setState({
      displayedCars: cars,
    });
  };

  toggleOpenBid = (bid) => {
    const { dispatch } = this.props;
    const { openBid, fullConditionReport } = this.state;
    if (bid) {
      dispatch(remove_selected_mobile_car());
      dispatch(hide_mobile_nav_menu(false));
      this.updateWatchlistData();
    } else {
      dispatch(remove_selected_mobile_car());
      dispatch(hide_mobile_nav_menu(!openBid));
      if (fullConditionReport) {
        this.setState({
          openBid: openBid ? false : true,
        });
      } else {
        this.setState({
          openBid: openBid ? false : true,
          hideTab: openBid ? false : true,
        });
      }
    }
  };

  toggleFullConditionReport = () => {
    this.setState({
      fullConditionReport: !this.state.fullConditionReport,
      hideTab: !this.state.fullConditionReport,
    });
  };

  loadWatchlistData = async () => {
    const { user } = this.props;
    const watchListCars = await WatchListServices.getSavedWatchlist(user);
    if (watchListCars && watchListCars.length !== undefined) {
      return watchListCars.map((car) => {
        return {
          ...car,
          isInWatchList: true,
        };
      });
    } else {
      return [];
    }
  };

  loadWinListData = async (displayedTab) => {
    const { user } = this.props;
    const winListCars = await WatchListServices.getWinsAndLoss(
      user,
      displayedTab,
      "win"
    );
    if (winListCars && winListCars.length !== undefined) {
      return winListCars;
    } else {
      return [];
    }
  };

  loadLossListData = async (displayLossing) => {
    const { user } = this.props;
    const lossListCars = await WatchListServices.getWinsAndLoss(
      user,
      displayLossing,
      "loss"
    );
    if (lossListCars && lossListCars.length !== undefined) {
      return lossListCars;
    } else {
      return [];
    }
  };

  updateAutoBid = (vin, autoBid, isAutoBidRemoved) => {
    const { displayedCars } = this.state;
    const carMap = (car) =>
      car.vin === vin ? { ...car, myCurrentAutoBid: autoBid } : car;
    let updatedCars;
    if (isAutoBidRemoved) {
      updatedCars = displayedCars.filter((car) => car.vin !== vin);
    } else {
      updatedCars = R.map(carMap, displayedCars);
    }
    this.setState({
      watchListData: updatedCars,
      displayedCars: updatedCars,
      fullConditionReport: !isAutoBidRemoved
        ? this.state.fullConditionReport
        : false,
    });
    if (!isAutoBidRemoved) {
      this.toggleOpenBid();
    }
  };

  updateData = async (tabData, displayedTab) => {
    const { start, end } = this.state;
    const { dispatch } = this.props;
    let data;
    let countCategory;
    switch (tabData) {
      case "watchListData":
        data = await this.loadWatchlistData();
        data = data.filter((car) => !car.isSold);
        countCategory = "watchListCount";
        dispatch(watchlist_count(data.length));
        break;
      case "winListData":
        data = await this.loadWinListData(displayedTab);
        countCategory = "winCount";
        break;
      case "lossListData":
        data = await this.loadLossListData(displayedTab);
        countCategory = "lossCount";
        break;
      default:
        return null;
    }
    this.setState({
      [tabData]: data,
      total: data.length,
      displayedCars: data.slice(start, end),
      [countCategory]: data.length,
    });
  };

  toggleLoading = (flag) => {
    this.setState({
      listLoading: flag,
    });
  };

  handleTabChange = (value, tabData) => {
    const { displayedTab } = this.state;
    if (value !== this.state.value) {
      this.setState({
        value,
        listLoading: true,
        displayedCars: {},
      });
      this.updateData(tabData, displayedTab);
    }
  };

  toggleDisplayView = (evt) => {
    const { listLoading } = this.state;
    if (!listLoading) {
      this.setState({
        displayedTab: evt ? evt.target.value : 0,
        listLoading: true,
      });
    } else {
      this.setState({
        listLoading: false,
      });
    }
  };

  updateGuaranteePayment = (vin) => {
    const { displayedCars, selectedCar, buyVehicle } = this.state;
    const { mobileCarInformation, dispatch } = this.props;
    const carMap = (car) =>
      car.vehicleIdentificationNumber === vin || car.vin === vin
        ? { ...car, displayGuaranteePaymentsOnly: false }
        : car;
    const updatedDisplay = R.map(carMap, displayedCars);
    this.setState({
      displayedCars: updatedDisplay,
    });
    if (mobileCarInformation && !buyVehicle) {
      dispatch(
        select_mobile_car({
          ...selectedCar,
          displayGuaranteePaymentsOnly: false,
        })
      );
    }
  };

  renderTabContent = () => {
    const {
      value,
      watchlistCarData,
      buyVehicle,
      ddoBuyVehicle,
      passVehicle,
      openBid,
      displayedTab,
      listLoading,
      displayedCars,
      fullConditionReport,
    } = this.state;
    const {
      history,
      dispatch,
      watchlistConditionReports,
      winListConditionReports,
      lossListConditionReports,
    } = this.props;
    switch (value) {
      case 0:
        return (
          <WatchlistCardList
            history={history}
            sourcePage={"Watchlist"}
            loadWatchlistData={this.loadWatchlistData}
            watchListCars={watchlistCarData}
            buyVehicle={buyVehicle}
            ddoBuyVehicle={ddoBuyVehicle}
            passVehicle={passVehicle}
            openBid={openBid}
            toggleBuyVehicle={this.toggleBuyVehicle}
            togglePassVehicle={this.togglePassVehicle}
            toggleOpenBid={this.toggleOpenBid}
            toggleDDOBuyVehicle={this.toggleDDOBuyVehicle}
            listLoading={listLoading}
            toggleLoading={this.toggleLoading}
            updateData={this.updateData}
            toggleDisplayView={this.toggleDisplayView}
            displayedCars={displayedCars}
            updateWatchListDisplay={this.updateWatchListDisplay}
            dispatch={dispatch}
            watchlistConditionReports={watchlistConditionReports}
            list={"watchList"}
            displayedTab={displayedTab}
            toggleFullConditionReport={this.toggleFullConditionReport}
            fullConditionReport={fullConditionReport}
            closeBidFlow={this.closeBidFlow}
            updateAutoBid={this.updateAutoBid}
            getConnectionToAmeService={this.getConnectionToAmeService}
            updateGuaranteePayment={this.updateGuaranteePayment}
          />
        );
      case 1:
        return (
          <WinLossCardList
            displayedTab={displayedTab}
            listLoading={listLoading}
            toggleDisplayView={this.toggleDisplayView}
            updateData={this.updateData}
            displayedCars={displayedCars}
            dispatch={dispatch}
            winListConditionReports={winListConditionReports}
            list={"winList"}
            value={value}
            toggleFullConditionReport={this.toggleFullConditionReport}
            fullConditionReport={fullConditionReport}
            toggleLoading={this.toggleLoading}
          />
        );
      case 2:
        return (
          <WinLossCardList
            displayedTab={displayedTab}
            listLoading={listLoading}
            toggleDisplayView={this.toggleDisplayView}
            updateData={this.updateData}
            displayedCars={displayedCars}
            dispatch={dispatch}
            lossListConditionReports={lossListConditionReports}
            list={"lossList"}
            value={value}
            toggleFullConditionReport={this.toggleFullConditionReport}
            fullConditionReport={fullConditionReport}
            toggleLoading={this.toggleLoading}
          />
        );
      default:
        return null;
    }
  };

  handleChangePage = (event, newPage) => {
    const { rowsPerPage, value, watchListData, winListData, lossListData } =
      this.state;
    const start = newPage === 0 ? 0 : newPage * rowsPerPage;
    const end = newPage * rowsPerPage + rowsPerPage;
    let displayedCars;
    switch (value) {
      case 0:
        displayedCars = watchListData.slice(start, end);
        break;
      case 1:
        displayedCars = winListData.slice(start, end);
        break;
      case 2:
        displayedCars = lossListData.slice(start, end);
        break;
      default:
        return null;
    }

    this.setState({ start, end, page: newPage, displayedCars });
  };

  handleChangeRowsPerPage = (event) => {
    const { value, watchListData, winListData, lossListData } = this.state;
    let displayedCars;
    switch (value) {
      case 0:
        displayedCars = watchListData.slice(0, +event.target.value);
        break;
      case 1:
        displayedCars = winListData.slice(0, +event.target.value);
        break;
      case 2:
        displayedCars = lossListData.slice(0, +event.target.value);
        break;
      default:
        return null;
    }
    this.setState({
      start: 0,
      end: +event.target.value,
      rowsPerPage: +event.target.value,
      page: 0,
      displayedCars,
    });
  };

  render() {
    const {
      value,
      page,
      rowsPerPage,
      total,
      displayedTab,
      hideTab,
      winCount,
      lossCount,
    } = this.state;
    const { classes, watchlistCount, mobileCarInformation } = this.props;

    return (
      <React.Fragment>
        <>
          {!mobileCarInformation ? (
            <React.Fragment>
              <div className={classes.mobileButtonWrapper}>
                <Button
                  id={"mobileGrounded"}
                  className={
                    value === 0
                      ? classes.selectedMobileButton
                      : classes.mobileButton
                  }
                  variant="outlined"
                  onClick={(evt) => this.handleTabChange(0)}
                >
                  <Typography
                    className={classes.mobileButtonText}
                  >{`Watchlist ${watchlistCount}`}</Typography>
                </Button>
                <Button
                  id={"mobilePriced"}
                  className={
                    value === 1
                      ? classes.selectedMobileButton
                      : classes.mobileButton
                  }
                  variant="outlined"
                  onClick={(evt) => this.handleTabChange(1)}
                >
                  <Typography className={classes.mobileButtonText}>
                    {`Wins ${winCount}`}{" "}
                  </Typography>
                </Button>
                <Button
                  id={"mobilePassed"}
                  className={
                    value === 2
                      ? classes.selectedMobileButton
                      : classes.mobileButton
                  }
                  variant="outlined"
                  onClick={(evt) => this.handleTabChange(2)}
                >
                  <Typography className={classes.mobileButtonText}>
                    {`Losses ${lossCount}`}{" "}
                  </Typography>
                </Button>
              </div>
              <div className={classes.mobilePaginationWrapper}>
                <TablePagination
                  className={classes.mobilePagination}
                  classes={{ toolbar: classes.mobileToolbar }}
                  id="pagination"
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={"Rows"}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={(event, newPage) =>
                    this.handleChangePage(event, newPage)
                  }
                  onChangeRowsPerPage={(event) =>
                    this.handleChangeRowsPerPage(event)
                  }
                />
                {value !== 0 ? (
                  <div className={classes.mobileDisplayWinCount}>
                    <FormLabel className={classes.label}>
                      {value === 1 ? `Display Winning: ` : `Display Losing: `}
                    </FormLabel>
                    <Select
                      id="displayedTab"
                      value={displayedTab}
                      onChange={this.toggleDisplayView}
                      classes={{
                        root: classes.displaySelect,
                        icon: classes.displayIcon,
                      }}
                      input={
                        <OutlinedInput
                          labelWidth={this.state.labelWidth}
                          name="choice"
                          id="outlined-choice-simple"
                          style={{ fontSize: ".75rem" }}
                        />
                      }
                    >
                      <MenuItem id="1" value={1}>
                        Today
                      </MenuItem>
                      <MenuItem id="7" value={7}>
                        Previous 7 Days
                      </MenuItem>
                      <MenuItem id="30" value={30}>
                        Previous 30 Days
                      </MenuItem>
                    </Select>
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          ) : null}
          {hideTab ? null : (
            <div className={classes.pageHeader}>
              <div className={classes.titleWrapper}>
                <ReactSVG
                  svgStyle={{
                    fill: "#FFFFFF",
                    position: "relative",
                    top: ".2rem",
                  }}
                  className={classes.watchlistIcon}
                  src={watchlistIcon}
                  alt="Report Icon"
                />
                <div className={classes.titleTextWrapper}>
                  <Typography className={classes.titleText}>
                    My Watchlist
                  </Typography>
                </div>
              </div>
              <div className={classes.buttonWrapper}>
                <div
                  className={
                    value === 0
                      ? classes.buttonUnderline
                      : classes.noButtonUnderline
                  }
                >
                  <Button
                    id={"watchlist"}
                    className={classes.navButton}
                    variant="outlined"
                    onClick={(evt) => this.handleTabChange(0, "watchListData")}
                  >
                    <Typography
                      className={
                        value === 0
                          ? classes.highlightedButtonText
                          : classes.buttonText
                      }
                    >{`Watchlist `}</Typography>
                    {watchlistCount}
                  </Button>
                </div>
                <div
                  className={
                    value === 1
                      ? classes.buttonUnderline
                      : classes.noButtonUnderline
                  }
                >
                  <Button
                    id={"wins"}
                    className={classes.navButton}
                    variant="outlined"
                    onClick={(evt) => this.handleTabChange(1, "winListData")}
                  >
                    <Typography
                      className={
                        value === 1
                          ? classes.highlightedButtonText
                          : classes.buttonText
                      }
                    >{`Wins `}</Typography>
                    {winCount}
                  </Button>
                </div>
                <div
                  className={
                    value === 2
                      ? classes.buttonUnderline
                      : classes.noButtonUnderline
                  }
                >
                  <Button
                    id={"losses"}
                    className={classes.navButton}
                    variant="outlined"
                    onClick={(evt) => this.handleTabChange(2, "lossListData")}
                  >
                    <Typography
                      className={
                        value === 2
                          ? classes.highlightedButtonText
                          : classes.buttonText
                      }
                    >{`Losses `}</Typography>
                    {lossCount}
                  </Button>
                </div>
              </div>
              <TablePagination
                className={classes.pagination}
                classes={{ toolbar: classes.toolbar }}
                id="pagination"
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={"Rows"}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onChangePage={(event, newPage) =>
                  this.handleChangePage(event, newPage)
                }
                onChangeRowsPerPage={(event) =>
                  this.handleChangeRowsPerPage(event)
                }
              />
              {value !== 0 ? (
                <div className={classes.displayWinCount}>
                  <FormLabel className={classes.label}>
                    {value === 1 ? `Display Winning: ` : `Display Losing: `}
                  </FormLabel>
                  <Select
                    id="displayedTab"
                    value={displayedTab}
                    onChange={this.toggleDisplayView}
                    classes={{
                      root: classes.displaySelect,
                      icon: classes.displayIcon,
                    }}
                    input={
                      <OutlinedInput
                        labelWidth={this.state.labelWidth}
                        name="choice"
                        id="outlined-choice-simple"
                        style={{ fontSize: ".75rem" }}
                      />
                    }
                  >
                    <MenuItem id="1" value={1}>
                      Today
                    </MenuItem>
                    <MenuItem id="7" value={7}>
                      Previous 7 Days
                    </MenuItem>
                    <MenuItem id="30" value={30}>
                      Previous 30 Days
                    </MenuItem>
                  </Select>
                </div>
              ) : null}
            </div>
          )}
          <div className={classes.root}>{this.renderTabContent()}</div>
        </>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    navLocation: state.ui.navLocation,
    watchlistConditionReports: state.cars.watchlistConditionReports,
    winListConditionReports: state.cars.winListConditionReports,
    lossListConditionReports: state.cars.lossListConditionReports,
    watchlistCount: state.cars.watchlistCount,
    mobileCarInformation: state.cars.mobileCarInformation,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(WatchList));
