const API_URL = process.env.REACT_APP_API_URL;

export const autoBid = async (
  bidInformation,
  user,
  proxyBidAmount,
  paymentOptionId,
  transport,
  transQuoteAmount,
  selectedLocation
) => {
  const token = sessionStorage.getItem("token");
  const { userId, loginId, dealerId } = user;
  const {
    inventoryId,
    saleEventItemDTOId,
    specialInstruction,
  } = bidInformation;
  const { id } = bidInformation.saleEventDTO;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      inventoryId,
      dealerId,
      userId,
      saleEventItemId: saleEventItemDTOId,
      saleEventId: id,
      proxyBidAmount,
      selectedTransMethod: transport,
      paymentOptionId,
      transQuoteAmount,
      locationId: selectedLocation,
      specialInstructions: specialInstruction,
      login: loginId,
    }),
  };
  try {
    const response = await fetch(`${API_URL}/auction/autoBid`, options);
    if (response.status === 200) {
      return response.json();
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const hardBid = async (
  bidInformation,
  user,
  hardBidAmount,
  selectedPaymentId,
  transMode,
  transQuoteAmount,
  selectedLocation
) => {
  const token = sessionStorage.getItem("token");
  const { userId, dealerId, loginId } = user;
  const {
    inventoryId,
    saleEventItemDTOId,
    specialInstruction,
  } = bidInformation;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      selectedTransMethod: transMode,
      hardBidAmount,
      userId,
      inventoryId,
      saleEventItemId: saleEventItemDTOId,
      selectedPaymentId,
      customerId: dealerId,
      transQuoteAmount,
      locationId: selectedLocation,
      specialInstructions: specialInstruction,
      login: loginId,
    }),
  };
  try {
    const response = await fetch(`${API_URL}/auction/hardBid`, options);
    return response.json();
  } catch (err) {
    return { status: false, message: err.message };
  }
};

export const removeAutoBid = async (user, car) => {
  const token = sessionStorage.getItem("token");
  const { userId, dealerId } = user;
  const { inventoryId, saleEventItemId, saleEventId } = car;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      inventoryId,
      dealerId: parseInt(dealerId, 10),
      userId: parseInt(userId, 10),
      saleEventItemId,
      saleEventId,
    }),
  };
  try {
    const response = await fetch(`${API_URL}/auction/removeAutoBid`, options);
    return response.json();
  } catch (err) {
    return { status: false, message: err.message };
  }
};

export const getVehicleBidInformation = async (car, user) => {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      inventoryId: car.inventoryId,
      dealerId: user.dealerId,
      userId: user.userId,
      saleEventItemId: car.saleEventItemId,
    }),
  };
  try {
    const response = await fetch(
      `${API_URL}/auction/getBidVehicleDetails`,
      options
    );
    return response.json();
  } catch (err) {
    return { status: false, message: err.message };
  }
};
