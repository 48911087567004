import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";

import * as UtilityServices from "../services/UtilityServices";

export const styles = (theme) => ({
  transportationQuoteWrapper: {
    margin: ".5rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  transportationQuoteCard: {
    margin: ".5rem",
    fontSize: ".8rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  transportationQuoteP: {
    margin: ".5rem",
    fontSize: ".8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  button: {
    margin: ".5rem",
    marginLeft: 0,
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "5rem",
    fontWeight: "bold",
    textTransform: "initial",
    fontSize: theme.font.small,
  },
  buttonWrapper: {
    textAlign: "right",
  },
  selectWrapper: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "14rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  head: {},
  row: {
    height: "auto",
  },
  cell: {
    [theme.breakpoints.down("sm")]: {
      width: "33%",
      fontSize: ".6rem",
      padding: 0,
      textAlign: "center",
    },
  },
  cardTitle: {
    color: theme.palette.primary.white,
    fontWeight: "bold",
    backgroundColor: theme.palette.DDO.main,
    textAlign: "center",
    borderRadius: "0 0 .75rem .75rem",
  },
});

export class TransportationQuote extends Component {
  state = {
    custLocation: null,
    quote: [],
  };

  componentWillMount = () => {
    const { accountSetting } = this.props;
    if (accountSetting.loginID) {
      this.setState({
        ...this.state,
        custLocation: this.findUsersLocation(accountSetting.selectedLocation),
      });
    }
  };

  findUsersLocation = () => {
    const { accountSetting } = this.props;
    return accountSetting.custLocations.filter(
      (location) =>
        location.locationId === parseInt(accountSetting.selectedLocation, 10)
    )[0];
  };

  populateLocations = () => {
    const { accountSetting } = this.props;
    if (accountSetting.custLocations[0]) {
      return accountSetting.custLocations.map((custLocation) => {
        return (
          <MenuItem key={custLocation.locationId} value={custLocation}>
            <em>{custLocation.locationDTO.name}</em>
          </MenuItem>
        );
      });
    } else {
      return (
        <MenuItem value="">
          <em>'Address Not Available'</em>
        </MenuItem>
      );
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    this.getTransportationQuote();
  };

  getTransportationQuote = async () => {
    const { car, user, accountSetting } = this.props;
    const { custLocation } = this.state;
    const response = await UtilityServices.transportquote(
      car.inventoryId,
      user.userId,
      car.saleEventItemId,
      accountSetting.zip,
      custLocation.customerId
    );
    if (response.status !== false) {
      this.setState({
        quote: response,
      });
    }
  };

  render() {
    const { custLocation, quote } = this.state;
    const { classes, toggleTransportationQuote } = this.props;
    return (
      <React.Fragment>
        <Card className={classes.transportationQuoteCard}>
          <Typography className={classes.cardTitle}>
            Transportation Quote
          </Typography>
          <div className={classes.transportationQuoteWrapper}>
            <Typography className={classes.transportationQuoteP}>
              Below is your transportation quote. If you would like the vehicle
              to be dropped off at an alternate location, please choose the
              location from the dropdown and recalculate.
            </Typography>
            <div className={classes.selectWrapper}>
              Destination:
              <Select
                value={custLocation}
                onChange={this.handleChange("custLocation")}
                style={{ fontSize: ".7rem" }}
                inputProps={{
                  style: {
                    height: "auto",
                  },
                }}
                input={
                  <OutlinedInput
                    labelWidth={0}
                    name="location"
                    id="outlined-location"
                  />
                }
              >
                {this.populateLocations()}
              </Select>
            </div>
            <div>
              <Table className={classes.table}>
                <TableHead className={classes.head}>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.cell}>
                      Transport Company
                    </TableCell>
                    <TableCell className={classes.cell}>Quote</TableCell>
                    <TableCell className={classes.cell}>
                      Delivery Estimate
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quote.length > 0 ? (
                    quote.map((row) => (
                      <TableRow key={row.quote} className={classes.row}>
                        <TableCell className={classes.cell}>
                          {row.trasnportCompanyName}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          $ {row.quote}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.daysRequired} Days
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className={classes.row}>
                      <TableCell className={classes.cell}></TableCell>
                      <TableCell className={classes.cell}>$ N/A</TableCell>
                      <TableCell className={classes.cell}>0 Days</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                id="buyNowButton"
                className={classes.button}
                size="small"
                onClick={(evt) => this.getTransportationQuote()}
              >
                Recalculate
              </Button>
              <Button
                id="closeButton"
                className={classes.button}
                size="small"
                onClick={(evt) => toggleTransportationQuote()}
              >
                Close
              </Button>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(TransportationQuote)
);
