import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

export class MyPurchaseGoalReportTable extends Component {
  state = {
    data: [],
    title: "",
    goalCountFrntEnd: 0,
    goalAchieved: 0,
    monthYearFrndEnd: "",
  };

  componentDidMount = () => {
    const { goalCountFrntEnd, goalAchieved, monthYearFrndEnd } = this.props;
    this.setState({
      title:
        "Dealer Level - Goal # " +
        goalCountFrntEnd +
        " and Goal Achieved : " +
        goalAchieved +
        " (" +
        monthYearFrndEnd +
        ")",
    });
  };

  render() {
    const { title } = this.state;
    const { classes, data } = this.props;
    const cellStyle = {
      maxWidth: "4rem",
      textAlign: "center",
      padding: 0,
      fontSize: ".6rem",
    };
    return (
      <div id="materialTable" className={classes.root}>
        <MaterialTable
          title={title}
          columns={[
            {
              title: "Year",
              field: "year",
              cellStyle,
            },
            {
              title: "Make",
              field: "make",
              cellStyle,
            },
            {
              title: "VIN/CR/Model/Trim",
              cellStyle,
              field: "model",
              render: (rowData) => (
                <div>
                  {rowData.model} {rowData.trim} <br />
                  {rowData.vin}{" "}
                </div>
              ),
            },
            {
              title: "Account Type",
              field: "accountType",
              cellStyle,
            },
            {
              title: "Grounding Date",
              cellStyle,
              field: "groundingDate",
              render: (rowData) =>
                moment(rowData.groundingDate).format("MM/DD/YYYY"),
            },
            {
              title: "Grounding Dealer No.",
              field: "grndDlrNumb",
              cellStyle,
            },
            {
              title: "Grounding Dealer Name",
              field: "grndDlrName",
              cellStyle,
            },
            {
              title: "Purchase Date",
              cellStyle,
              field: "purchaseDate",
              render: (rowData) =>
                moment(rowData.purchaseDate).format("MM/DD/YYYY"),
            },
            {
              title: "Purchasing Dealer No.",
              field: "purchaseDlrNumb",
              cellStyle,
            },
            {
              title: "Purchasing Dealer Name",
              field: "purchDlrName",
              cellStyle,
            },
            {
              title: "Purchase Type",
              field: "purchaseType",
              cellStyle,
            },
            {
              title: "Purchase Price",
              field: "purchasePrice",
              cellStyle,
              render: (rowData) =>
                Number(rowData.purchaseAmount).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
            },
            {
              title: "Rewards Payout",
              cellStyle,
              field: "rewardPayout",
              render: (rowData) =>
                Number(rowData.rewardPayout).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
            },
          ]}
          data={data}
          options={{
            headerStyle: {
              backgroundColor: "#EEE",
              textAlign: "center",
              padding: ".4rem",
            },
            showTitle: true,
            sorting: true,
            search: true,
            paging: true,
            maxBodyHeight: 400,
          }}
        />
      </div>
    );
  }
}

MyPurchaseGoalReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyPurchaseGoalReportTable);
