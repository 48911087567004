const API_URL = process.env.REACT_APP_API_URL;

export async function addToWatchList(user, car) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId: parseInt(user.userId, 10),
      dealerId: parseInt(user.dealerId, 10),
      latitude: user.latitude,
      longitude: user.longitude,
      auctionDate: car.auctionDate ? car.auctionDate : null,
      estimatedEndTime: car.estimatedEndTime ? car.estimatedEndTime : null,
      startDate: car.startDate ? car.startDate : null,
      endDate: car.endDate ? car.endDate : null,
      saleDate: null,
      payOutDate: car.payOutDate ? car.payOutDate : null,
      saleEventItemId: car.saleEventItemId ? car.saleEventItemId : null,
      inventoryId: car.inventoryId ? car.inventoryId : car.id,
      expDateTime: car.expDateTime ? car.expDateTime : null,
      loginId: user.loginId,
      nowDate: car.nowDate ? car.nowDate : null,
      dealerShip: user.franchise,
      timeZone: user.timeZone,
      winOrLoss: null,
      loggedInUserLatitude: user.latitude,
      loggedInUserLongitude: user.longitude,
      winLossDisplayPeriod: null,
    }),
  };
  try {
    const response = await fetch(`${API_URL}/watchlist/add`, options);
    if (response.status === 200) {
      return response.json();
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function removeFromWatchList(user, car) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId: parseInt(user.userId, 10),
      dealerId: parseInt(user.dealerId, 10),
      latitude: user.latitude,
      longitude: user.longitude,
      auctionDate: car.auctionDate,
      estimatedEndTime: car.estimatedEndTime,
      startDate: car.startDate,
      endDate: car.endDate,
      saleDate: null,
      payOutDate: car.payOutDate,
      saleEventItemId: car.saleEventItemId,
      inventoryId: car.inventoryId,
      expDateTime: car.expDateTime,
      loginId: user.loginId,
      nowDate: car.nowDate,
      dealerShip: user.franchise,
      timeZone: user.timeZone,
      winOrLoss: null,
      loggedInUserLatitude: user.latitude,
      loggedInUserLongitude: user.longitude,
      winLossDisplayPeriod: null,
    }),
  };
  try {
    const response = await fetch(`${API_URL}/watchlist/remove`, options);
    return response.json();
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getSavedWatchlist(user) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId: parseInt(user.userId, 10),
      dealerId: parseInt(user.dealerId, 10),
      latitude: user.latitude,
      longitude: user.longitude,
      auctionDate: null,
      estimatedEndTime: null,
      startDate: null,
      endDate: null,
      saleDate: null,
      payOutDate: null,
      saleEventItemId: null,
      inventoryId: null,
      expDateTime: null,
      loginId: user.loginId,
      nowDate: null,
      dealerShip: user.franchise,
      timeZone: user.timeZone,
      winOrLoss: null,
      loggedInUserLatitude: user.latitude,
      loggedInUserLongitude: user.longitude,
      winLossDisplayPeriod: null,
    }),
  };
  try {
    const response = await fetch(`${API_URL}/watchlist/savedlists`, options);
    return response.json();
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getWinsAndLoss(user, displayCount, winOrLoss) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId: parseInt(user.userId, 10),
      dealerId: parseInt(user.dealerId, 10),
      latitude: user.latitude,
      longitude: user.longitude,
      auctionDate: null,
      estimatedEndTime: null,
      startDate: null,
      endDate: null,
      saleDate: null,
      payOutDate: null,
      saleEventItemId: null,
      inventoryId: null,
      expDateTime: null,
      loginId: user.loginId,
      nowDate: null,
      dealerShip: user.franchise,
      timeZone: user.timeZone,
      winOrLoss: winOrLoss,
      loggedInUserLatitude: user.latitude,
      loggedInUserLongitude: user.longitude,
      winLossDisplayPeriod: displayCount,
    }),
  };
  try {
    const response = await fetch(`${API_URL}/watchlist/winsandloss`, options);
    return response.json();
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getBidHistory(saleEventItemId) {
  const token = sessionStorage.getItem("token");
  try {
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(
      `${API_URL}/auction/getBidHistory?saleEventItemId=${saleEventItemId}`,
      options
    );
    return response.json();
  } catch (err) {
    console.log(err);
    return null;
  }
}
