import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
    fontSize: ".65rem",
  },
  progress: {
    margin: ".5rem",
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
});

export class MyUpstreamSaleReportTable extends Component {
  render() {
    const { classes, data, toggleConditionReport } = this.props;
    const cellStyle = {
      maxWidth: "5rem",
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
    };
    return (
      <div id="materialTable" className={classes.root}>
        <MaterialTable
          columns={[
            {
              title: "Sold Date",
              cellStyle,
              field: "vehicleSoldDate",
              render: (rowData) =>
                moment(rowData.vehicleSoldDate).format("MM/DD/YYYY"),
            },
            {
              title: "Year",
              field: "year",
              cellStyle,
            },
            {
              title: "VIN/CR/Model/Trim",
              cellStyle,
              field: "model",
              render: (rowData) => (
                <div>
                  {rowData.model} {rowData.trim} <br />
                  <span
                    onClick={
                      rowData.conditionReportAvailable
                        ? () =>
                            toggleConditionReport(
                              rowData.inventoryId,
                              "purchasedByOthersData"
                            )
                        : null
                    }
                    className={
                      rowData.conditionReportAvailable
                        ? classes.clickableLink
                        : null
                    }
                  >
                    {rowData.vin}
                  </span>
                </div>
              ),
            },
            {
              title: "Color",
              field: "color",
              cellStyle,
            },
            {
              title: "Purchased Dealer Name",
              field: "dlrName",
              cellStyle,
            },
            {
              title: "Purchased Dealer City",
              field: "dlrCity",
              cellStyle,
            },
            {
              title: "Purchased Dealer State",
              field: "dlrState",
              cellStyle,
            },
            {
              title: "Dealer Purchased Amount",
              cellStyle,
              field: "dlrPurchaseAmount",
              render: (rowData) =>
                Number(rowData.dlrPurchaseAmount).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
            },
          ]}
          data={data}
          options={{
            headerStyle: {
              backgroundColor: "#EEE",
              textAlign: "center",
              padding: ".5rem",
            },
            showTitle: false,
            sorting: true,
            search: true,
            paging: true,
            maxBodyHeight: 400,
          }}
        />
      </div>
    );
  }
}

MyUpstreamSaleReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyUpstreamSaleReportTable);
