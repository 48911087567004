import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ReactSVG from "react-svg";

import circleReportIcon from "../assets/reportsIcon.svg";
import * as userAccess from "../services/UserRoles";
import { set_nav_location } from "../actions/uiActions";
import { advancedSearchCriteriaWrapper } from "../services/AdvanceSearchServices";
import ConditionReport from "../components/ConditionReport";
import { getSingleConditionReport } from "../services/ConditionReportService";
import { Typography } from "@material-ui/core";
import ReportSearch from "../components/ReportSearch";

const styles = (theme) => ({
  homeWrapper: {
    display: "flex",
    position: "relative",
    height: "100%",
  },
  reportRoot: {
    height: "138%",
    maxWidth: "98%",
    padding: "1rem",
  },
});

const tabTitles = ["My Transportation Report"];

export class MobilityReports extends Component {
  state = {
    tabValue: 5,
    models: [],
  };

  componentWillMount = () => {
    const { user, dispatch, navLocation, history } = this.props;
    if (!userAccess.isRideshareUser(user.userRoles)) {
      history.replace("/home");
    } else {
      if (navLocation !== "mobilityReports") {
        dispatch(set_nav_location("mobilityReports"));
      }
    }
    this.fetchModels();
  };

  handleTabValueChange = (tabValue) => {
    this.setState({ tabValue });
  };

  renderTabContent = () => {
    const { tabValue, models } = this.state;
    const { classes, purchasedVehicles } = this.props;
    return (
      <div className={classes.reportWrapper}>
        <Typography className={classes.reportTitle}>
          {tabTitles[tabValue]}
        </Typography>
        <ReportSearch
          tabValue={tabValue}
          purchasedVehicles={purchasedVehicles}
          toggleConditionReport={this.toggleConditionReport}
          models={models}
        />
      </div>
    );
  };

  toggleConditionReport = async (inventoryId) => {
    const { userId, dealerId } = this.props.user;
    const { conditionReport } = this.state;
    if (!conditionReport) {
      const conditionReport = await getSingleConditionReport(
        inventoryId,
        userId,
        dealerId
      );
      this.setState({
        conditionReport,
      });
    } else {
      this.setState({
        conditionReport: null,
      });
    }
  };

  fetchModels = async () => {
    const searchCriteria = await advancedSearchCriteriaWrapper();
    const lexusModels = Object.keys(
      searchCriteria.typeMakeModelSeriesModelNumberMap.Lexus
    );
    const scionModels = Object.keys(
      searchCriteria.typeMakeModelSeriesModelNumberMap.Scion
    );
    const toyotaModels = Object.keys(
      searchCriteria.typeMakeModelSeriesModelNumberMap.Toyota
    );
    this.setState({
      models: {
        Lexus: lexusModels,
        Scion: scionModels,
        Toyota: toyotaModels,
      },
    });
  };

  render() {
    const { classes, user } = this.props;
    const { conditionReport } = this.state;
    return (
      <React.Fragment>
        {!conditionReport ? (
          <div className={classes.reportRoot}>
            <div className={classes.header}>
              <div className={classes.titleWrapper}>
                <ReactSVG
                  svgStyle={{
                    fill: "#FFFFFF",
                    position: "relative",
                    top: ".1rem",
                  }}
                  className={classes.ReportIcon}
                  src={circleReportIcon}
                  alt="Report Icon"
                />
              </div>
            </div>
            <div className={classes.reportTitle}>My Transportation Report</div>
            {this.renderTabContent()}
          </div>
        ) : (
          <ConditionReport
            user={user}
            toggleFullConditionReport={this.toggleConditionReport}
            conditionReport={conditionReport}
            fromReports
          />
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    navLocation: state.ui.navLocation,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(MobilityReports));
