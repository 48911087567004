import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";

import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";

import CarouselRadioButtons from "../components/CarouselRadioButtons";
import * as TDDStaticContentServices from "../services/TDDStaticContentServices";
import * as LDDStaticContentServices from "../services/LDDStaticContentServices";
import * as ResourceSigningServices from "../services/ResourceSigningServices";
import { BACK, LEXUS } from "../constants/UILabel";

const styles = (theme) => ({
  carousel: {
    display: "flex",
    height: "46%",
    margin: "1rem",
    position: "relative",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      marginTop: "1rem",
      marginRight: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      maxHeight: "10rem",
    },
  },
  carouselContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  leftNav: {
    height: "100%",
    display: "flex",
    position: "absolute",
    zIndex: 2,
    left: 0,
    backgroundColor: "rgb(0,0,0,.05)",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,.1)",
    },
    cursor: "pointer",
  },
  rightNav: {
    height: "100%",
    position: "absolute",
    display: "flex",
    zIndex: 2,
    right: 0,
    backgroundColor: "rgb(0,0,0,.05)",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,.1)",
    },
    cursor: "pointer",
  },
  navIcon: {
    zIndex: 3,
    color: theme.palette.primary.black,
    cursor: "pointer",
  },
});

const shuffleCarousel = 10000;
const awsUrl = process.env.REACT_APP_WCM_CONTENT;

export class MessageCenter extends Component {
  state = {
    count: 0,
    content: {
      images: [],
      pTags: [],
    },
  };

  radioClick = (value) => {
    this.setState({
      ...this.state,
      count: value,
    });
    clearInterval(this.timer);
    this.setTimer();
  };

  setTimer = () => {
    this.timer = setInterval(() => {
      if (this.state.count === this.state.content.length - 1) {
        this.setState({
          ...this.state,
          count: 0,
        });
      } else {
        this.setState({
          ...this.state,
          count: this.state.count + 1,
        });
      }
    }, shuffleCarousel);
  };

  navigateImages = (nav) => {
    clearInterval(this.timer);
    this.setTimer();
    if (nav === BACK) {
      if (this.state.count === 0) {
        this.setState({
          count: this.state.content.length - 1,
        });
      } else {
        this.setState({
          count: this.state.count - 1,
        });
      }
    } else {
      if (this.state.count === this.state.content.length - 1) {
        this.setState({
          count: 0,
        });
      } else {
        this.setState({
          count: this.state.count + 1,
        });
      }
    }
  };

  componentWillMount = () => {
    this.parseContent();
    this.setTimer();
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  parseContent = async () => {
    const { accountSetting, pageTheme } = this.props;
    const content =
      pageTheme !== LEXUS
        ? await TDDStaticContentServices.homePageContent()
        : await LDDStaticContentServices.homePageContent();
    let servedContent = [];
    if (content) {
      content.didYouKnow.map((message) => {
        return servedContent.push(message);
      });
      content.bannerAd.map((ad) => {
        if (!ad.state) {
          return servedContent.push(ad);
        } else if (ad.state === accountSetting.state) {
          return servedContent.push(ad);
        } else {
          return null;
        }
      });

      content.homeImages.map(async (image) => {
        image.imgUrl = await this.signContentUrl(image.imgUrl);
        image.url = await this.signContentUrl(image.url);
        image.tabletUrl = await this.signContentUrl(image.tabletUrl);

        if (!image.state) {
          return servedContent.push(image);
        } else if (image.state === accountSetting.state) {
          return servedContent.push(image);
        } else {
          return null;
        }
      });
      content.messageCenter.map((message) => {
        return servedContent.push(message);
      });
      this.setState({
        ...this.state,
        content: servedContent,
      });
    }
  };

  signContentUrl = async (url) => {
    if (url) {
      url = awsUrl + "/" + url;

      if (url.includes("docs")) {
        return await ResourceSigningServices.signUrls([url]);
      } else {
        return url;
      }
    }

    return url;
  };

  renderContent = (content) => {
    const { classes } = this.props;
    if (content.imgUrl) {
      return (
        <img
          style={content.url ? { cursor: "pointer" } : null}
          className={classes.carouselContent}
          src={`${content.imgUrl}`}
          alt={content.title}
          onClick={
            content.url
              ? () => window.open(`${content.url}`, "_blank", "")
              : null
          }
        />
      );
    } else {
      return (
        <Paper className={classes.carouselContent}>
          {content.title ? (
            <Typography variant="h6" style={{ margin: ".75rem" }}>
              {content.title}
            </Typography>
          ) : null}
          <Typography style={{ margin: ".75rem", overflow: "auto" }}>
            {content.text}
          </Typography>
        </Paper>
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { count, content } = this.state;
    return (
      <div className={classes.carousel} key="carousel">
        <div
          className={classes.leftNav}
          onClick={(evt) => this.navigateImages("back")}
        >
          <ArrowLeft className={classes.navIcon} />
        </div>
        {content.length ? this.renderContent(content[count]) : null}
        {content.length ? (
          <CarouselRadioButtons
            count={count}
            content={content}
            handleClick={this.radioClick}
            key="radiobuttons"
          />
        ) : null}
        <div
          className={classes.rightNav}
          onClick={(evt) => this.navigateImages()}
        >
          <ArrowRight className={classes.navIcon} />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    pageTheme: state.ui.pageTheme,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(MessageCenter));
