import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactSVG from "react-svg";
import phoneIcon from "../assets/phone.svg";
import { withStyles } from "@material-ui/core/styles";

import { set_nav_location } from "../actions/uiActions";
import * as LegalPrivacyContentServices from "../services/LegalPrivacyContentServices";

const styles = (theme) => ({
  footerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "small",
    borderTop: `.1rem solid ${theme.palette.secondary.gray}`,
    position: "relative",
    bottom: "-2rem",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      fontSize: ".5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".4rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
      width: "98%",
    },
  },
  footerWrapperAddBottom: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "small",
    borderTop: `.1rem solid ${theme.palette.secondary.gray}`,
    position: "relative",
    bottom: "-3rem",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      fontSize: ".5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".4rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
      width: "98%",
    },
  },
  "@supports (-ms-ime-align:auto)": {
    footerWrapper: {
      bottom: "-8rem",
    },
  },
  "@supports (-moz-appearance:none)": {
    footerWrapper: {
      bottom: "-2rem",
    },
  },
  poweredByWrapper: {
    height: "1.25rem",
    display: "flex",
    marginLeft: ".2rem",
    [theme.breakpoints.down("lg")]: {
      width: "inherit",
    },
    [theme.breakpoints.down("md")]: {
      width: "inherit",
    },
    [theme.breakpoints.down("sm")]: {
      width: "12rem",
    },
  },
  contactDetailsWrapper: {
    display: "flex",
    position: "relative",
    left: "-6rem",
    [theme.breakpoints.down("lg")]: {
      width: "120%",
    },
    [theme.breakpoints.down("md")]: {
      width: "inherit",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactDetails: {
    paddingLeft: ".5rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: ".2rem",
    },
  },
  phoneText: {
    color: theme.palette.primary.redHighlight,
  },
  linkButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    display: "inline",
    margin: 0,
    padding: 0,
  },
  divider: {
    color: theme.palette.primary.gray,
  },
  dealerDirectCopyright: {
    marginLeft: "4rem",
    position: "relative",
    right: "4rem",
    dealerDirectRightsReserved: {
      [theme.breakpoints.down("sm")]: {
        right: "4.5rem",
        width: "2rem",
      },
    },
  },
  linkWrapper: {
    width: "20%",
    height: "1.25rem",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "top",
    position: "relative",
    right: "1rem",
    [theme.breakpoints.up("lg")]: {
      width: "10%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "20%",
    },
  },
  dealerDirectRightsReserved: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

class Footer extends Component {
  render() {
    const { classes, dispatch, pageTheme, navLocation } = this.props;
    const token = sessionStorage.getItem("token");
    return (
      <React.Fragment>
        {token ? (
          <React.Fragment>
            <div
              className={
                navLocation === "reports" || navLocation === "admin"
                  ? classes.footerWrapperAddBottom
                  : classes.footerWrapper
              }
            >
              <div className={classes.poweredByWrapper}>
                <div className={classes.dealerDirectCopyright}>
                  © <Moment interval={86400000} format={"YYYY"} />{" "}
                  <span className={classes.dealerDirectRightsReserved}>
                    DealerDirect. All Rights Reserved.
                  </span>
                </div>
              </div>
              <div className={classes.contactDetailsWrapper}>
                <ReactSVG
                  src={phoneIcon}
                  svgStyle={{
                    fill: "currentColor",
                    height: ".6rem",
                    width: "15px",
                  }}
                />
                <span className={classes.phoneText}> 877-522-3372 </span>
                <span className={classes.contactDetails}>
                  {" "}
                  Contact Dealer Direct Support by Telephone{" "}
                  <span style={{ marginLeft: "4px" }}>Monday</span> through
                  Friday 9:00am - 8:00pm EST
                </span>
              </div>
              <div className={classes.linkWrapper}>
                <Link
                  to="/legal"
                  onClick={() => dispatch(set_nav_location(false))}
                >
                  Legal
                </Link>{" "}
                |
                <Link
                  to=""
                  onClick={() =>
                    LegalPrivacyContentServices.privacyRedirect(pageTheme)
                  }
                >
                  Privacy
                </Link>{" "}
                |
                <Link
                  to="/help"
                  onClick={() => dispatch(set_nav_location(false))}
                >
                  Help
                </Link>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    pageTheme: state.ui.pageTheme,
    navLocation: state.ui.navLocation,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Footer));
