import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  OutlinedInput,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: ".2rem",
    width: "100%",
  },
  checkboxRoot: {
    "&$checked": {
      color: theme.palette.grounding.main,
    },
  },
  radioRoot: {
    "&$checked": {
      color: theme.palette.grounding.main,
    },
  },
  checkboxRootGAMD: {
    "&$checked": {
      color: theme.palette.GAMD.main,
    },
  },
  radioRootGAMD: {
    "&$checked": {
      color: theme.palette.GAMD.main,
    },
  },
  checked: {},
  formControl: {
    margin: ".25rem",
    minWidth: "5rem",
  },
  formControlLarge: {
    margin: ".25rem",
    minWidth: "5rem",
    width: "100%",
  },
  subMenu: {
    marginLeft: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1.5rem",
    },
  },
  selectEmpty: {
    marginTop: "2rem",
  },
  choiceWrapper: {
    display: "flex",
    color: theme.palette.primary.gray,
    flexDirection: "column",
    width: "25rem",
    [theme.breakpoints.down("sm")]: {
      width: "16rem",
    },
  },
  rightChoiceWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "27rem",
    [theme.breakpoints.down("sm")]: {
      width: "16rem",
    },
  },
  conditionWrapper: {
    width: "60%",
    minWidth: "29.3rem",
    [theme.breakpoints.down("sm")]: {
      width: "16rem",
    },
  },
  "@global": {
    ".MuiFormControlLabel-root": {
      height: "2rem",
      width: "27rem",
      [theme.breakpoints.down("sm")]: {
        width: "16rem",
      },
    },
  },
  lowerChoiceWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  textField: {
    width: "24rem",
    marginTop: ".5rem",
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.font.small,
      width: "16rem",
    },
  },
  label: {
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.font.small,
    },
  },
  tireSelection: {
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.font.small,
      display: "flex",
      flexDirection: "column",
      width: "9rem",
    },
  },
});

const tireDepthSelections = [
  "0/32",
  "1/32",
  "2/32",
  "3/32",
  "4/32",
  "5/32",
  "6/32",
  "7/32",
  "8/32",
];

export class PassReasonOptions extends React.Component {
  state = {
    labelWidth: 25,
  };

  render() {
    const {
      classes,
      handleChange,
      handleRadio,
      handleSelect,
      ground,
    } = this.props;
    const {
      vehicleHistoryReasonReported,
      condition,
      notRequiredForInventoryReasonReported,
      outOfPriceRange,
      remainingPaymentReasonReported,
      tireReasonValueChecked,
      alloyWheelDamaged,
      previouslyRepaired,
      windshieldReasonReported,
      odorReasonReported,
      missingEquipmentReasonReported,
      condOther,
      condOtherReasonReported,
      other,
      otherReasonReported,
      outOfPriceRangeReason,
      selectedLFTire,
      selectedLRTire,
      selectedRFTire,
      selectedRRTire,
    } = this.props.passOptions;

    return (
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControlLarge}>
          <FormLabel component="legend">Why Did You Pass?</FormLabel>
          <FormHelperText>Select all applicable reasons below</FormHelperText>
          <div className={classes.lowerChoiceWrapper}>
            <div className={classes.choiceWrapper}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="vehicleHistoryCheck"
                    checked={vehicleHistoryReasonReported}
                    onChange={handleChange("vehicleHistoryReasonReported")}
                    value="vehicleHistoryReasonReported"
                    classes={{
                      root: !ground
                        ? classes.checkboxRootGAMD
                        : classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                  />
                }
                classes={{ label: classes.label }}
                label="Vehicle History"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="notRequiredCheck"
                    checked={notRequiredForInventoryReasonReported}
                    onChange={handleChange(
                      "notRequiredForInventoryReasonReported"
                    )}
                    value="notRequiredForInventoryReasonReported"
                    classes={{
                      root: !ground
                        ? classes.checkboxRootGAMD
                        : classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                  />
                }
                classes={{ label: classes.label }}
                label="Vehicle not required for inventory"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="outOfPriceCheck"
                    checked={outOfPriceRange}
                    onChange={handleChange("outOfPriceRange")}
                    value="outOfPriceRange"
                    classes={{
                      root: !ground
                        ? classes.checkboxRootGAMD
                        : classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                  />
                }
                classes={{ label: classes.label }}
                label="Out of my price range"
              />
              {outOfPriceRange ? (
                <div className={classes.subMenu}>
                  <RadioGroup
                    aria-label="OOPSelect"
                    name="OOPSelect"
                    className={classes.group}
                    value={outOfPriceRangeReason}
                    onChange={(evt) => handleRadio(evt)}
                  >
                    <FormControlLabel
                      id="marketPriceHigh"
                      value="marketPriceHighReasonReported"
                      control={
                        <Radio
                          classes={{
                            root: !ground
                              ? classes.radioRootGAMD
                              : classes.radioRoot,
                            checked: classes.checked,
                            label: classes.label,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Market Price Too High For My Region"
                    />
                    <FormControlLabel
                      id="damageNotAccounted"
                      value="damageNotAccountedReasonReported"
                      control={
                        <Radio
                          id="damageRadio"
                          classes={{
                            root: !ground
                              ? classes.radioRootGAMD
                              : classes.radioRoot,
                            checked: classes.checked,
                            label: classes.label,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Damage Not Taken into Account"
                    />
                  </RadioGroup>
                </div>
              ) : null}
              <FormControlLabel
                control={
                  <Checkbox
                    id="remainingPaymentCheck"
                    checked={remainingPaymentReasonReported}
                    onChange={handleChange("remainingPaymentReasonReported")}
                    value="remainingPaymentReasonReported"
                    classes={{
                      root: !ground
                        ? classes.checkboxRootGAMD
                        : classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                  />
                }
                classes={{ label: classes.label }}
                label="Price with remaining payments too high"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="otherCheck"
                    checked={other}
                    onChange={handleChange("other")}
                    value="other"
                    classes={{
                      root: !ground
                        ? classes.checkboxRootGAMD
                        : classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                  />
                }
                classes={{ label: classes.label }}
                label="Other"
              />
              {other ? (
                <TextField
                  id="otherReasonReported"
                  label="Other reason"
                  multiline
                  rowsMax="4"
                  value={otherReasonReported}
                  onChange={handleChange("otherReasonReported")}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  classes={{ label: classes.label }}
                />
              ) : null}
            </div>

            <div className={classes.rightChoiceWrapper}>
              <FormGroup className={classes.conditionWrapper}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="conditionCheck"
                      checked={condition}
                      onChange={handleChange("condition")}
                      value="condition"
                      classes={{
                        root: !ground
                          ? classes.checkboxRootGAMD
                          : classes.checkboxRoot,
                        checked: classes.checked,
                      }}
                    />
                  }
                  classes={{ label: classes.label }}
                  label="Condition"
                />
                {condition ? (
                  <FormGroup className={classes.subMenu}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="tireReasonCheck"
                          checked={tireReasonValueChecked}
                          onChange={handleChange("tireReasonValueChecked")}
                          value="tireReasonValueChecked"
                          classes={{
                            root: !ground
                              ? classes.checkboxRootGAMD
                              : classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Tires (Tread Depth)"
                    />
                    {tireReasonValueChecked ? (
                      <div className={classes.tireSelection}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="selectedLFTire">LF</InputLabel>
                          <Select
                            id="LFSelect"
                            value={selectedLFTire}
                            onChange={handleSelect}
                            input={
                              <OutlinedInput
                                labelWidth={this.state.labelWidth}
                                name="selectedLFTire"
                                id="selectedLFTire"
                              />
                            }
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {tireDepthSelections.map((selection) => {
                              return (
                                <MenuItem
                                  id={`LF${selection}`}
                                  key={selection}
                                  value={selection}
                                >
                                  {selection}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="selectedLRTire">LR</InputLabel>
                          <Select
                            id="LRSelect"
                            value={selectedLRTire}
                            onChange={handleSelect}
                            input={
                              <OutlinedInput
                                labelWidth={this.state.labelWidth}
                                name="selectedLRTire"
                                id="selectedLRTire"
                              />
                            }
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {tireDepthSelections.map((selection) => {
                              return (
                                <MenuItem
                                  id={`LR${selection}`}
                                  key={selection}
                                  value={selection}
                                >
                                  {selection}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="selectedRFTire">RF</InputLabel>
                          <Select
                            id="RFSelect"
                            value={selectedRFTire}
                            onChange={handleSelect}
                            input={
                              <OutlinedInput
                                labelWidth={this.state.labelWidth}
                                name="selectedRFTire"
                                id="selectedRFTire"
                              />
                            }
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {tireDepthSelections.map((selection) => {
                              return (
                                <MenuItem
                                  id={`RF${selection}`}
                                  key={selection}
                                  value={selection}
                                >
                                  {selection}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="selectedRRTire">RR</InputLabel>
                          <Select
                            id="RRSelect"
                            value={selectedRRTire}
                            onChange={handleSelect}
                            input={
                              <OutlinedInput
                                labelWidth={this.state.labelWidth}
                                name="selectedRRTire"
                                id="selectedRRTire"
                              />
                            }
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {tireDepthSelections.map((selection) => {
                              return (
                                <MenuItem
                                  id={`RR${selection}`}
                                  key={selection}
                                  value={selection}
                                >
                                  {selection}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    ) : null}
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="wheelDamaged"
                          checked={alloyWheelDamaged}
                          onChange={handleChange("alloyWheelDamaged")}
                          value="alloyWheelDamaged"
                          classes={{
                            root: !ground
                              ? classes.checkboxRootGAMD
                              : classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Alloy Wheel Damage"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="prevRepair"
                          checked={previouslyRepaired}
                          onChange={handleChange("previouslyRepaired")}
                          value="previouslyRepaired"
                          classes={{
                            root: !ground
                              ? classes.checkboxRootGAMD
                              : classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Previous Repair"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="windshieldReport"
                          checked={windshieldReasonReported}
                          onChange={handleChange("windshieldReasonReported")}
                          value="windshieldReasonReported"
                          classes={{
                            root: !ground
                              ? classes.checkboxRootGAMD
                              : classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Windshield"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="odorReported"
                          checked={odorReasonReported}
                          onChange={handleChange("odorReasonReported")}
                          value="odorReasonReported"
                          classes={{
                            root: !ground
                              ? classes.checkboxRootGAMD
                              : classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Odor"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="missingEquip"
                          checked={missingEquipmentReasonReported}
                          onChange={handleChange(
                            "missingEquipmentReasonReported"
                          )}
                          value="missingEquipmentReasonReported"
                          classes={{
                            root: !ground
                              ? classes.checkboxRootGAMD
                              : classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Missing/Inoperable Equipment"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="otherConditionCheck"
                          checked={condOther}
                          onChange={handleChange("condOther")}
                          value="condOther"
                          classes={{
                            root: !ground
                              ? classes.checkboxRootGAMD
                              : classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Other"
                    />
                    {condOther ? (
                      <TextField
                        id="condOtherReasonReported"
                        label="Other conditions"
                        multiline
                        rowsMax="4"
                        value={condOtherReasonReported}
                        onChange={handleChange("condOtherReasonReported")}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                      />
                    ) : null}
                  </FormGroup>
                ) : null}
              </FormGroup>
            </div>
          </div>
        </FormControl>
      </div>
    );
  }
}

PassReasonOptions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PassReasonOptions);
