import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { formatPhoneNumber } from "../services/HelperServices";

const styles = (theme) => ({
  infoWrapper: {
    width: "22rem",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: ".5rem",
    },
  },
  lineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".75rem",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    paddingBottom: ".2rem",
  },
  inlineCell: {
    display: "flex",
    justifyContent: "space-between",
  },
  bolded: {
    fontSize: theme.font.small,
    fontWeight: "bold",
  },
  normal: {
    marginLeft: "1.5rem",
    fontSize: theme.font.small,
  },
});
export class LocationDisplay extends Component {
  render() {
    const { classes } = this.props;
    const { addressLine1, addressLine2, city, state, zip } =
      this.props.custLocation.locationDTO.addressDTO;
    const { businessPhone, contactName } =
      this.props.custLocation.locationDTO.customerContactDTOs[0]
        .communicationDTO;
    return (
      <div className={classes.infoWrapper}>
        <div className={classes.lineWrapper}>
          <Typography className={classes.bolded}>Street 1:</Typography>
          <Typography className={classes.bolded}>{addressLine1}</Typography>
        </div>
        <div className={classes.lineWrapper}>
          <Typography className={classes.bolded}>Street 2:</Typography>
          <Typography className={classes.bolded}>{addressLine2}</Typography>
        </div>
        <div className={classes.lineWrapper}>
          <div className={classes.inlineCell}>
            <Typography className={classes.bolded}>City:</Typography>
            <Typography className={classes.normal}>{city}</Typography>
          </div>
          <div className={classes.inlineCell}>
            <Typography className={classes.bolded}>State:</Typography>
            <Typography className={classes.normal}>{state}</Typography>
          </div>
          <div className={classes.inlineCell}>
            <Typography className={classes.bolded}>Zip:</Typography>
            <Typography className={classes.normal}>{zip}</Typography>
          </div>
        </div>
        <div className={classes.lineWrapper}>
          <Typography className={classes.bolded}>Phone:</Typography>
          <Typography className={classes.bolded}>
            {formatPhoneNumber(businessPhone)}
          </Typography>
        </div>
        <div className={classes.lineWrapper}>
          <Typography className={classes.bolded}>Contact Name:</Typography>
          <Typography className={classes.bolded}>
            {contactName ? contactName : "None Specified"}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LocationDisplay);
