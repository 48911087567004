import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Viewer from "react-viewer";
import "react-viewer/dist/index.css";

import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Chip,
  Tooltip,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import { getNotes } from "../services/NotesServices";
import { getDate, returnAccountType } from "../services/HelperServices";
import { removeAutoBid } from "../services/BidServices";
import * as WatchListServices from "../services/WatchListServices";
import * as userAccess from "../services/UserRoles";

import Notes from "../components/Notes";
import CarReport from "../components/CarReport";
import Loading from "../components/Loading";
import DDOTransportQuoteTile from "../components/DDOTransportQuoteTile";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import ReactSVG from "react-svg";
import bidIcon from "../assets/bidIcon.svg";
import shoppingCart from "../assets/shoppingCartSmaller.svg";
import watchlistIcon from "../assets/watchlistIcon.svg";
import notesIcon from "../assets/notesIcon.svg";
import winningBidIcon from "../assets/winningBidIcon.svg";
import losingBidIcon from "../assets/losingBidIcon.svg";
import activeWatchlistIcon from "../assets/activeWatchlistIcon.svg";
import DDOGroundedIcon from "../assets/DDOGroundedIcon.svg";
import groundNavIcon from "../assets/groundNavIcon.svg";

import { watchlist_count } from "../actions/carActions";
import BidHistory from "../components/BidHistory";

const styles = (theme) => ({
  card: {
    display: "flex",
    margin: ".5rem",
    borderRadius: 0,
    maxHeight: "11rem",
    width: "98%",
    position: "relative",
  },
  selectedCard: {
    display: "flex",
    margin: ".5rem",
    borderRadius: 0,
    maxHeight: "11rem",
    width: "98%",
    position: "relative",
    boxShadow: `0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.5)`,
    backgroundColor: theme.palette.light.gray,
  },
  cardWrapper: {
    display: "flex",
    position: "relative",
    alignItems: "flex-start",
    justifyContent: "left",
    flexDirection: "column",
    width: "40%",
    padding: 0,
    maxWidth: "10rem",
  },
  media: {
    width: "100%",
    height: "8rem",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "6rem",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: ".5rem",
  },
  contentWrapper: {
    display: "flex",
    margin: ".5rem",
    position: "relative",
    cursor: "pointer",
    width: "75%",
  },
  textWrapper: {
    display: "flex",
    height: "85%",
    justifyContent: "space-around",
    position: "relative",
    top: "1.75rem",
  },
  textContainer: {
    width: "100%",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignContent: "space-between",
    height: "9rem",
  },
  smallButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
  smallButton: {
    height: "2.5rem",
    minWidth: 0,
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    minWidth: "12%",
    justifyContent: "space-around",
    marginRight: ".5rem",
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  buyButton: {
    height: "40%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100%",
    margin: 0,
    fill: "currentColor",
    [theme.breakpoints.down("md")]: {
      height: "50%",
    },
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "20%",
    justifySelf: "flex-end",
  },
  bidButton: {
    height: "40%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.DDO.main,
    width: "100%",
    margin: 0,
    fill: "currentColor",
    [theme.breakpoints.down("md")]: {
      height: "50%",
    },
  },
  LPWLink: {},
  hasNotes: {
    color: theme.palette.primary.redHighlight,
    fill: "currentColor",
  },
  blankNotes: {},
  isInWatchList: {
    color: theme.palette.primary.redHighlight,
  },
  isNotInWatchList: {},
  expandButton: {
    alignSelf: "center",
    padding: 0,
  },
  cardTitle: {
    fontSize: "1.2rem",
    position: "absolute",
    width: "37rem",
  },
  selectedCardTitle: {
    fontSize: "1.2rem",
    position: "absolute",
    width: "37rem",
    fontWeight: "bold",
  },
  middleWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
  },
  GAMDIcon: {
    color: theme.palette.GAMD.main,
  },
  DDOColorText: {
    color: theme.palette.DDO.main,
  },
  winningBid: {
    color: theme.palette.DDO.main,
    fontSize: "76%",
    [theme.breakpoints.down("md")]: {
      fontSize: "60%",
    },
  },
  losingBid: {
    color: theme.palette.primary.redHighlight,
    fontSize: "76%",
    [theme.breakpoints.down("md")]: {
      fontSize: "60%",
    },
  },
  startPrice: {
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
  },
  circleIcon: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "20px",
    verticalAlign: "middle",
    alignSelf: "center",
  },
  autoBid: {
    display: "flex",
    justifyContent: "space-between",
    width: "8rem",
    [theme.breakpoints.down("md")]: {
      width: "7rem",
    },
  },
  leftTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "1%",
    width: "11rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      width: "9rem",
    },
  },
  rightTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "1%",
    width: "10.1rem",
    paddingBottom: 0,
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      width: "8rem",
    },
  },
  priceDisplayTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    width: "21rem",
    marginRight: ".7%",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      width: "18rem",
    },
  },
  dialogPaper: {
    minHeight: "31vh",
    maxHeight: "31vh",
    maxWidth: "50%",
    width: "50%",
  },
  cardText: {
    fontSize: "76%",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("md")]: {
      fontSize: "60%",
    },
  },
  boldedText: {
    fontWeight: "bold",
  },
  vinText: {
    fontWeight: "bold",
    color: theme.palette.grounding.main,
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
  redText: {
    color: theme.palette.primary.redHighlight,
  },
  redBoldedText: {
    fontWeight: "bold",
    color: theme.palette.primary.redHighlight,
  },
  losingBar: {
    width: "10px",
    backgroundColor: theme.palette.primary.redHighlight,
  },
  winningBar: {
    width: "10px",
    backgroundColor: theme.palette.DDO.main,
  },
  chipRoot: {
    fontSize: theme.font.small,
    height: "1rem",
    width: "4.4rem",
  },
  deleteIcon: {
    height: "1rem",
    "&:hover": {
      fill: theme.palette.primary.redHighlight,
    },
  },
  distanceWrapper: {
    display: "flex",
    alignItems: "center",
  },
  transportIcon: {
    marginLeft: ".5rem",
    fill: theme.palette.primary.gray,
    zIndex: 2,
    "&:hover": {
      opacity: ".5",
    },
  },
  noteIcon: {
    fill: theme.palette.grounding.main,
  },
  orgText: {
    color: "#e68a00",
    fontWeight: "bold",
  },
  noOfBidText: {
    fontWeight: "bold",
    color: theme.palette.grounding.main,
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
});

const stockwaveIntegrationDiv = (car, user, conditionReport) => {
  const data = `{
		VIN: ${car.vin},
		Year: ${car.year},
		Make: ${car.make},
		Model: ${car.model},
		Trim: ${car.series !== undefined ? car.series : ""},
		Engine: ${car.engine},
		Drivetrain: ${car.driveTrain},
		Transmission: ${
      conditionReport ? conditionReport.vehicleInformation.Transmission : ""
    },
		Odometer: ${car.inspectionMileage},
		Odometer Unit of Measure: ${car.unitOfOdometer},
		Color: ${car.color},
		Interior Color:,
		Interior Type:,
		CR Score: ${car.grade},
		CR URL:,
		VDP URL:,
		Lane:,
		Run:,
		inventoryId: ${car.inventoryId},
		userId: ${user.userId},
		dealershipId: ${user.dealerId}
	}`;
  return (
    <div hidden="hidden" className="stockwaveVehicleInfo">
      {data}
    </div>
  );
};

export class DDOCarListTile extends Component {
  state = {
    LPWActiveCar: false,
    guaranteePayment: false,
    notes: [],
    showCarfaxReport: false,
    expand: false,
    viewerVisible: false,
    bidLoading: false,
    buyLoading: false,
    showBidHistory: false,
    showCarfaxSnackBar: false,
  };

  componentWillMount = () => {
    const { setTileRef, car } = this.props;
    this.myRef = createRef();
    setTileRef(car, this.myRef);
    this.fetchNotes();
  };

  fetchNotes = async () => {
    const { car, user } = this.props;
    if (car.noteAvailable || car.notesPresent || car.isNoteAvailable) {
      const notes = await getNotes(
        user.dealerId,
        car.id ? car.id : car.inventoryId
      );
      this.setState({
        notes,
      });
    }
  };

  addNote = (newNote) => {
    const { accountSetting, car, user } = this.props;
    const fullNote = {
      inventoryId: car.id,
      dealerId: user.dealerId,
      note: newNote,
      needFollowUp: false,
      userId: user.userId,
      firstName: accountSetting.firstName,
      lastName: accountSetting.lastName,
      createdDate: getDate(),
    };
    this.setState({
      notes: [...this.state.notes, fullNote],
    });
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: true,
    });
  };

  removeAutoBid = async () => {
    const { user, car, updateAutoBid, watchlistCount, dispatch } = this.props;
    const response = await removeAutoBid(user, car);
    if (response.status === "true") {
      updateAutoBid(car.vin, 0, true);
      dispatch(watchlist_count(watchlistCount - 1));
    }
  };

  toggleBidHistory = () => {
    this.setState({
      showBidHistory: !this.state.showBidHistory,
    });
  };

  renderPriceDisplay = () => {
    const { car, classes, currentAuction, pageFrom, sourcePage } = this.props;
    const { name } = this.props.accountSetting.custLocations[0].locationDTO;
    if (
      !car.bidPlaced &&
      car.myCurrentAutoBid > 0 &&
      currentAuction !== "sale1" &&
      currentAuction !== "sale2"
    ) {
      return (
        <div className={classes.autoBid}>
          <Typography className={classes.cardText}>
            <span className={classes.boldedText}>Autobid:</span>
          </Typography>
          <Chip
            label={`${car.myCurrentAutoBid.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}
            onDelete={this.removeAutoBid}
            className={classes.chip}
            classes={{ root: classes.chipRoot, deleteIcon: classes.deleteIcon }}
            deleteIcon={
              <Tooltip title="Remove Autobid">
                <CancelIcon />
              </Tooltip>
            }
          />
        </div>
      );
    } else if (
      (car.statusCode === "DLR_BID_NOW" || car.statusCode === "DLR_BUY_NOW") &&
      car.bidPlaced
    ) {
      return (
        <Typography
          className={
            name === car.highBidder ? classes.winningBid : classes.losingBid
          }
        >
          <span className={classes.boldedText}>Current Bid:</span>{" "}
          <span>
            {car.currentBid.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}

            <span
              className={
                name === car.highBidder ? classes.winningBid : classes.losingBid
              }
            >
              {" "}
              <span className={classes.boldedText}>
                {name === car.highBidder ? "WINNING" : "LOSING"}
              </span>
            </span>
          </span>
          <br />
          {(pageFrom === "watchList" || sourcePage === "DDO") &&
          car.bidCount > 0 ? (
            <div style={{ color: "black", fontWeight: "900" }}>
              # of Bids:{" "}
              <span
                className={classes.noOfBidText}
                onClick={() => this.toggleBidHistory()}
              >
                {car.bidCount}
              </span>{" "}
              Bids
            </div>
          ) : (
            <span style={{ color: "black", fontWeight: "900" }}>
              {" "}
              # of Bids: {car.bidCount} Bids
            </span>
          )}
        </Typography>
      );
    } else if (car.statusCode === "DLR_BID_NOW") {
      // After release revist the code and figure out why we have car.statusCode === "DLR_BUY_NOW" check in the above else if block.
      return (
        <Typography
          className={
            name === car.highBidder ? classes.winningBid : classes.losingBid
          }
        >
          {car.currentBid ? (
            <>
              <span className={classes.boldedText}>Current Bid:</span>{" "}
              <span>
                {car.currentBid.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}

                <span
                  className={
                    name === car.highBidder
                      ? classes.winningBid
                      : classes.losingBid
                  }
                >
                  {" "}
                  <span className={classes.boldedText}>
                    {name === car.highBidder ? "WINNING" : "LOSING"}
                  </span>
                </span>
              </span>
            </>
          ) : (
            <>
              <span className={classes.redBoldedText}>Start Price: </span>
              <span className={classes.redText}>
                {car.discountedStartPrice
                  ? car.discountedStartPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : car.startPrice
                  ? car.startPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : null}
              </span>
            </>
          )}
          <br />
          {(pageFrom === "watchList" || sourcePage === "DDO") &&
          car.bidCount > 0 ? (
            <div style={{ color: "black", fontWeight: "900" }}>
              {" "}
              # of Bids:{" "}
              <span
                className={classes.noOfBidText}
                onClick={() => this.toggleBidHistory()}
              >
                {car.bidCount}
              </span>{" "}
              Bids
            </div>
          ) : (
            <span style={{ color: "black", fontWeight: "900" }}>
              {" "}
              # of Bids: {car.bidCount} Bids
            </span>
          )}
        </Typography>
      );
    } else {
      return (
        <Typography className={classes.cardText}>
          <span className={classes.redBoldedText}>Start Price: </span>
          <span className={classes.redText}>
            {car.discountedStartPrice
              ? car.discountedStartPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : car.startPrice
              ? car.startPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : null}
          </span>
        </Typography>
      );
    }
  };

  renderDisplayIcon = () => {
    const { bidPlaced, isVehAtLoggedInDlrLot, highBidder } = this.props.car;
    const { name } = this.props.accountSetting.custLocations[0].locationDTO;

    if (bidPlaced) {
      if (name === highBidder) {
        return (
          <ReactSVG
            id="winningBid"
            src={winningBidIcon}
            svgStyle={{ height: "1.2rem" }}
          />
        );
      } else {
        return (
          <ReactSVG
            id="losingBid"
            src={losingBidIcon}
            svgStyle={{ height: "1.2rem" }}
          />
        );
      }
    } else if (isVehAtLoggedInDlrLot) {
      return (
        <ReactSVG
          id="dealerLot"
          src={DDOGroundedIcon}
          svgStyle={{ height: "1.2rem" }}
        />
      );
    } else {
      return null;
    }
  };

  toggleWatchList = async () => {
    const { user, car, updateWatchlist } = this.props;
    let response;
    if (car.isInWatchList) {
      response = await WatchListServices.removeFromWatchList(user, car);
    } else {
      response = await WatchListServices.addToWatchList(user, car);
    }
    this.loadWatchlistData();
    if (response) {
      updateWatchlist(car.vin);
    }
  };

  loadWatchlistData = async () => {
    const { user, dispatch } = this.props;
    const watchListCars = await WatchListServices.getSavedWatchlist(user);
    if (watchListCars && watchListCars.length !== undefined) {
      dispatch(watchlist_count(watchListCars.length));
    }
  };

  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  render() {
    const {
      classes,
      car,
      selectCar,
      user,
      conditionReport,
      handleBidOpen,
      handleBuyNow,
      accountSetting,
      history,
      pageFrom,
      selectedCar,
      openNote,
      handleOpenNote,
      openTransport,
      handleTransportToggle,
      toggleFullConditionReport,
      ref,
    } = this.props;
    const {
      notes,
      showCarfaxReport,
      viewerVisible,
      buyLoading,
      bidLoading,
      showBidHistory,
      showCarfaxSnackBar,
    } = this.state;
    const { name } = this.props.accountSetting.custLocations[0].locationDTO;
    const { isVehAtLoggedInDlrLot } = this.props.car;
    return (
      <React.Fragment>
        {conditionReport && conditionReport.inspectionImageDTO ? (
          <Viewer
            visible={viewerVisible}
            zoomSpeed={0.3}
            noClose={true}
            onMaskClick={(evt) => this.setState({ viewerVisible: false })}
            onClose={() => this.setState({ viewerVisible: false })}
            images={conditionReport.inspectionImageDTO.map((image) => {
              return {
                src: image.imageUrl,
                alt: image.damageImageCaption,
              };
            })}
          />
        ) : null}
        <Card
          className={`${
            car.vin === selectedCar.vin ? classes.selectedCard : classes.card
          } stockwaveVehicleRow`}
          id={`DDOCarListTile${car.vin}`}
          onClick={() => selectCar(car, user, "DDO")}
          ref={this.myRef}
        >
          {stockwaveIntegrationDiv(car, user, conditionReport)}
          {car.bidPlaced ? (
            <div
              className={
                name !== car.highBidder ? classes.losingBar : classes.winningBar
              }
            ></div>
          ) : null}
          <CardActions className={classes.cardWrapper}>
            <div className={classes.imageWrapper}>
              <img
                id="primaryImage"
                src={car.imagePathFc}
                className={classes.media}
                alt={`${car.make} ${car.model}`}
                onClick={() => this.toggleImageViewer()}
              />
            </div>
            <div className={classes.smallButtonWrapper}>
              {!isVehAtLoggedInDlrLot ? (
                <React.Fragment>
                  {car.myCurrentAutoBid === 0 && !car.bidPlaced ? (
                    <Button
                      id="watchListBtn"
                      className={classes.smallButton}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        this.toggleWatchList();
                      }}
                      title={
                        car.isInWatchList
                          ? "Remove from Watchlist"
                          : "Add to Watchlist"
                      }
                    >
                      <ReactSVG
                        src={
                          car.isInWatchList
                            ? activeWatchlistIcon
                            : watchlistIcon
                        }
                      />
                    </Button>
                  ) : null}
                </React.Fragment>
              ) : null}
              <Button
                id="noteButton"
                className={classes.smallButton}
                onClick={() => handleOpenNote(car)}
                title="Notes"
              >
                <ReactSVG
                  src={notesIcon}
                  className={
                    notes && notes.length > 0 ? classes.hasNotes : null
                  }
                />
              </Button>
              {this.renderDisplayIcon()}
            </div>
          </CardActions>
          <div className={classes.contentWrapper}>
            <div className={classes.textContainer}>
              <Typography
                className={
                  car.vin === selectedCar.vin
                    ? classes.selectedCardTitle
                    : classes.cardTitle
                }
              >
                {`${car.year} ${car.make} ${car.model} `}
                {car.series ? car.series : null}
                {` ${car.color ? car.color : null}`}
                <div className="stockWaveLightBulb"></div>
              </Typography>
              <div className={classes.textWrapper}>
                <CardContent className={classes.leftTextColumn}>
                  <Typography className={classes.cardText}>
                    VIN:{" "}
                    <span
                      className={classes.vinText}
                      onClick={() => toggleFullConditionReport(car, ref)}
                    >
                      {car.vin}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Odometer:{" "}
                    <span className={classes.boldedText}>
                      {car.odometer.toLocaleString()}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Promotions:{" "}
                    <span className={classes.orgText}>
                      {car.grdDlrProgramNames !== undefined
                        ? car.grdDlrProgramNames
                        : car.programName !== undefined
                        ? car.programName
                        : null}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Account Type:{" "}
                    <span
                      className={
                        returnAccountType(car.accountType) === "Company Car"
                          ? classes.redBoldedText
                          : classes.boldedText
                      }
                    >
                      {returnAccountType(car.accountType)}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className={classes.rightTextColumn}>
                  <Typography className={classes.cardText}>
                    Location:{" "}
                    <span
                      className={classes.boldedText}
                    >{`${car.locationName}, ${car.state}`}</span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Engine:{" "}
                    <span className={classes.boldedText}>{car.engine}</span>
                  </Typography>
                  <div className={classes.distanceWrapper}>
                    <Typography className={classes.cardText}>
                      Distance:{" "}
                      <span className={classes.boldedText}>
                        {car.distance.toFixed(2)} mi
                      </span>
                    </Typography>
                    <ReactSVG
                      title="Transportation Quote"
                      className={classes.transportIcon}
                      src={groundNavIcon}
                      svgStyle={{ width: "1rem" }}
                      onClick={() => handleTransportToggle(car)}
                    />
                  </div>
                  <Typography className={classes.cardText}>
                    {car.autoGradeAvailable ? "Auto-grade" : "Grade:"}{" "}
                    <span className={classes.boldedText}>{car.grade}</span>
                  </Typography>
                </CardContent>
              </div>
            </div>
          </div>
          <CardContent className={classes.priceDisplayTextColumn}>
            <Typography className={classes.cardText}>
              <span className={classes.boldedText}>{car.saleEventName}</span>
            </Typography>
            {!car.buyNowDisabled ? (
              <Typography className={classes.cardText}>
                <span className={classes.boldedText}>Buy Now Price: </span>
                <span className={classes.DDOColorText}>
                  {car.discountedBuyNowPrice
                    ? car.discountedBuyNowPrice.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : car.buyNowPrice.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                </span>
              </Typography>
            ) : (
              <Typography className={classes.cardText}>
                <span className={classes.boldedText}>My AutoBid: </span>
                <span className={classes.DDOColorText}>
                  {car.myCurrentAutoBid.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
              </Typography>
            )}
            {this.renderPriceDisplay()}
            <Typography className={classes.cardText}>
              <span className={classes.boldedText}>Administration Fee:</span>{" "}
              <span>
                {car.buyFee
                  ? car.buyFee.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : null}
              </span>
            </Typography>
          </CardContent>
          {userAccess.canPurchaseVehicles(user.userRoles) ? (
            <CardActions className={classes.cardActions}>
              <Button
                id="buyNowButton"
                className={classes.buyButton}
                size="small"
                disabled={car.buyNowDisabled || car.isSold}
                onClick={(evt) => {
                  history.push(
                    pageFrom === "watchList"
                      ? "/watchlist"
                      : pageFrom === "advanceSearch"
                      ? "/advancesearch"
                      : "/DDO"
                  );
                  this.setState({
                    buyLoading: true,
                  });
                  handleBuyNow(car);
                }}
              >
                {!buyLoading ? (
                  <span className={classes.buttonIcon}>
                    <ReactSVG src={shoppingCart} />
                    Buy Now
                  </span>
                ) : (
                  <Loading color={"#FFFFF"} />
                )}
              </Button>
              <Button
                id="bidButton"
                className={classes.bidButton}
                size="small"
                disabled={
                  pageFrom && pageFrom === "advanceSearch"
                    ? !car.bidButtonEnabled
                    : !car.bidNowAutoBidButtonEnabled
                }
                onClick={() => {
                  history.push(
                    pageFrom === "watchList"
                      ? "/watchlist"
                      : pageFrom === "advanceSearch"
                      ? "/advancesearch"
                      : "/DDO"
                  );
                  this.setState({
                    bidLoading: true,
                  });
                  handleBidOpen(car);
                }}
              >
                {!bidLoading ? (
                  <span className={classes.buttonIcon}>
                    <ReactSVG src={bidIcon} />
                    Bid
                  </span>
                ) : (
                  <Loading color={"#FFFFFF"} />
                )}
              </Button>
            </CardActions>
          ) : null}
        </Card>
        {openNote === car.vin ? (
          <Notes
            toggleNote={handleOpenNote}
            notes={notes}
            user={user}
            addNote={this.addNote}
            car={car}
            conditionReport={conditionReport}
            selected={car.vin === selectedCar.vin}
          />
        ) : null}
        {showCarfaxReport ? (
          <CarReport
            vin={conditionReport.vin}
            reportType="carfax"
            showCarfaxReport={this.showCarfaxReport}
            showNoDataVin={this.showNoDataVin}
          />
        ) : null}
        {showCarfaxSnackBar ? (
          <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
        ) : null}
        {openTransport === car.vin ? (
          <DDOTransportQuoteTile
            car={car}
            user={user}
            accountSetting={accountSetting}
          />
        ) : null}
        {showBidHistory ? (
          <Dialog
            open={showBidHistory}
            onClose={() => this.toggleBidHistory()}
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogContent>
              <BidHistory
                saleEventItemId={car.saleEventItemId}
                from="DDOCarListTile"
              />
            </DialogContent>
          </Dialog>
        ) : null}
      </React.Fragment>
    );
  }
}

DDOCarListTile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    accountSetting: state.accountSetting,
    watchlistCount: state.cars.watchlistCount,
    currentAuction: state.ui.currentAuction,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(DDOCarListTile));
