const awsUrl = process.env.REACT_APP_WCM_CONTENT;
const tfsPrivacyUrl = process.env.REACT_APP_TFS_PRIVACY_CONTENT;
const lexusPrivacyUrl = process.env.REACT_APP_LEXUS_PRIVACY_CONTENT;

export async function privacyRedirect(pageTheme) {
  try {
    if (pageTheme === "Toyota") window.open(tfsPrivacyUrl, "_tab");
    else window.open(lexusPrivacyUrl, "_tab");
  } catch (err) {
    console.log(err);
  }
}

export async function legalData() {
  try {
    const response = await fetch(`${awsUrl}/legal.html`);
    return response.text();
  } catch (err) {
    console.log(err);
    return null;
  }
}
