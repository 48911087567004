const returnUserSearches = async (user) => {
  const token = sessionStorage.getItem("token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const regexDealershipFilter = new RegExp(/\w+/g);
    const dealership = regexDealershipFilter.exec(user.dealership)[0];
    const getSavedSearches = await fetch(
      `${process.env.REACT_APP_API_URL}/savedSearch/loadSaveSearch?dealerId=${user.dealerId}&dealership=${dealership}&userId=${user.userId}`,
      options
    );
    return getSavedSearches.json();
  } catch (err) {
    console.log(err);
    return [];
  }
};

const returnSearchResults = async (user, search) => {
  const token = sessionStorage.getItem("token");
  const regexDealershipFilter = new RegExp(/\w+/g);
  const dealership = regexDealershipFilter.exec(user.dealership)[0];
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      dealerId: user.dealerId,
      dealership: dealership,
      userId: user.userId,
      searchName: search.searchName,
      startIndex: 0,
      maxResults: null,
      timeZone: user.timeZone,
      datePattern: user.datePattern,
    }),
  };
  try {
    const searchResults = await fetch(
      `${process.env.REACT_APP_API_URL}/savedSearch/showVehicleSearchNowWithLessOptionCodes`,
      options
    );
    if (searchResults.status === 200) {
      return await searchResults.json();
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
  }
};

export default async (user) => {
  const userSearches = await returnUserSearches(user);
  if (userSearches.length > 0) {
    try {
      const searchResults = [];
      for (let search of userSearches) {
        searchResults.push(returnSearchResults(user, search));
      }
      return await Promise.all(searchResults);
    } catch (err) {
      console.log(err);
      return [];
    }
  } else {
    return [];
  }
};
