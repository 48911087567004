const API_URL = process.env.REACT_APP_API_URL;

export async function transportquote(
  inventoryId,
  userId,
  saleEventItemId,
  zipCode,
  customerId
) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      inventoryId: inventoryId,
      userId: userId,
      saleEventItemId: saleEventItemId,
      storeId: 13,
      zipCode: zipCode,
      customerId: customerId,
    }),
  };
  try {
    const response = await fetch(`${API_URL}/utility/transportquote`, options);
    return response.json();
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getDealerLocations(dealerId) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(
      `${API_URL}/utility/dealerlocations?dealerid=${dealerId}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return {
        status: response.status,
        message: "The server is having issues, please try again later.",
      };
    }
  } catch (err) {
    return {
      status: false,
      message: err.message,
    };
  }
}

export const loadStateInformation = async (data, user) => {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(`${API_URL}/utility/stateAndTimeZone`, options);
  return response.json();
};

export const submitNewLocation = async (data, user) => {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      vin: data.vin,
      locationName: data.locationName,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      phone: data.phone,
      fax: data.fax,
      timeZoneId: data.timeZoneId,
      stateId: data.stateId,
      zip: data.zip,
      contactName1: data.contactName1,
      contactPhone1: data.contactPhone1,
      contactEmail1: data.contactEmail1,
      contactName2: data.contactName2,
      contactPhone2: data.contactPhone2,
      contactEmail2: data.contactEmail2,
      userId: user.userId,
      dealerId: user.dealerId,
      loginId: user.loginId,
    }),
  };

  const response = await fetch(`${API_URL}/utility/location`, options);
  return response.json();
};

export async function loadAuctionLocation() {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(`${API_URL}/utility/getAuctionCode`, options);
  return response.json();
}
