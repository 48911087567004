import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Typography, CardContent } from "@material-ui/core";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const styles = (theme) => ({
  card: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardHeader: {
    marginBottom: ".55rem",
    padding: 0,
    height: "9%",
    color: theme.palette.GAMD.main,
  },
  cardTitle: {
    color: theme.palette.GAMD.main,
    fontWeight: "bold",
    fontSize: ".8rem",
  },
  expiredCardTitle: {
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
    fontSize: ".8rem",
  },
  cardContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    height: "10rem",
    padding: 0,
    borderTop: `3px solid ${theme.palette.secondary.gray}`,
  },
  circularProgress: {
    margin: ".5rem",
    height: "8rem",
  },
  circularProgressBar: {
    width: "4rem",
  },
  timeDisplay: {
    fontWeight: "bold",
    fontSize: "1rem",
    color: theme.palette.primary.gray,
  },
  interiorText: {
    fontSize: ".5rem",
    marginBottom: "1rem",
    color: theme.palette.primary.gray,
  },
  container: {
    display: "flex",
  },
});

export class CarListTileExclusivity extends Component {
  state = {
    timeRemaining: null,
    hours: 0,
    minutes: 0,
  };

  timeRemaining = (time) => {
    const hours = time / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return { hours: rhours, minutes: rminutes };
  };

  componentDidMount = () => {
    const { exclusivity } = this.props;
    if (typeof exclusivity === "number" && exclusivity > 0) {
      const time = this.timeRemaining(exclusivity);
      this.setState({
        timeRemaining: exclusivity,
        hours: time.hours,
        minutes: time.minutes,
      });
      this.timer = setInterval((timeRemaining) => {
        const newTimeRemaining = this.state.timeRemaining - 1;
        const newTime = this.timeRemaining(newTimeRemaining);
        this.setState({
          timeRemaining: newTimeRemaining,
          hours: newTime.hours,
          minutes: newTime.minutes,
        });
      }, 60000);
    } else {
      this.setState({
        timeRemaining: exclusivity,
      });
    }
  };

  renderHoursDisplay = () => {
    const { exclusivity } = this.props;
    const { hours } = this.state;
    switch (exclusivity) {
      case "Expired":
        return "-";
      case null:
        return "-";
      default:
        return hours;
    }
  };

  renderMinutesDisplay = () => {
    const { exclusivity } = this.props;
    const { minutes } = this.state;
    switch (exclusivity) {
      case "Expired":
        return "-";
      case null:
        return "-";
      default:
        return minutes;
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };
  render() {
    const { classes, exclusivity } = this.props;
    const { hours, minutes } = this.state;
    let progressStyles = {
      path: {
        stroke: "#E4B042",
      },
      trail: {
        stroke: "#C2BFBF",
      },
    };
    return (
      <CardContent className={classes.card}>
        <Typography
          className={
            exclusivity !== "Expired"
              ? classes.cardTitle
              : classes.expiredCardTitle
          }
        >
          {exclusivity !== "Expired" ? "Exclusivity Period" : "Expired"}
        </Typography>
        <div className={classes.container}>
          <CircularProgressbarWithChildren
            styles={progressStyles}
            value={(hours / 48) * 100}
            className={classes.circularProgressBar}
          >
            <Typography className={classes.timeDisplay}>
              {this.renderHoursDisplay()}
            </Typography>
            <Typography className={classes.interiorText}>hours</Typography>
          </CircularProgressbarWithChildren>
          <CircularProgressbarWithChildren
            styles={progressStyles}
            value={(minutes / 60) * 100}
            className={classes.circularProgressBar}
          >
            <Typography className={classes.timeDisplay}>
              {this.renderMinutesDisplay()}
            </Typography>
            <Typography className={classes.interiorText}>minutes</Typography>
          </CircularProgressbarWithChildren>
        </div>
      </CardContent>
    );
  }
}

export default withStyles(styles)(CarListTileExclusivity);
