import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Snackbar,
  Table,
  TableRow,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import { GROUNDING_MESSAGES } from "../constants/UILabel";
import { set_nav_location } from "../actions/uiActions";
import {
  getMbpPriceRange,
  getPriceQuoteDetailByVin,
} from "../services/EarlyTermService";
import PreTermPriceQuote from "./PreTermPriceQuote";
import { ROUTE_URL } from "../constants/Constant";

const styles = (theme) => ({
  tableWrapper: {
    height: "20vh",
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.secondary.gray}`,
    borderBottom: `0.5px solid ${theme.palette.secondary.gray}`,
    paddingBottom: "10px",
  },
  priceQuoteText: {
    margin: ".5rem",
  },
  odometerTitle: {
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "4rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
    paddingLeft: "12px",
    paddingBottom: "4px",
  },
  ReEnter: {
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "4rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
    paddingLeft: "12px",
    paddingBottom: "4px",
    paddingRight: "20px ",
  },
  sectionHeader: {
    height: "35px",
    backgroundColor: theme.palette.DDO.main,
    color: theme.palette.primary.white,
    width: "100%",
    paddingLeft: "10px",
    display: "flex",
  },
  preTermPriceButton: {
    margin: "0.5rem",
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.DDO.main,
    width: "5rem",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
  },
});
const InputProps = {
  disableUnderline: true,
  inputProps: {
    style: {
      padding: 5,
      height: "100%",
    },
    maxLength: 11,
    min: 0,
  },
};

export class PriceQuote extends Component {
  state = {
    vin: "",
    vehicleDetails: {},
    priceQuoteOdometer: "",
    priceQuoteOdometerConfirmation: "",
    badOdoSubmit: "",
    preTermPriceQuote: false,
    date: "",
    finishedFetchingMbpPriceQuote: false,
    errorFetchingMbpPriceRange: false,
    errorMessage: "",
  };

  componentDidMount() {
    const { dispatch, history } = this.props;
    const { state } = this.props.location;
    dispatch(set_nav_location("/home"));
    if (state !== undefined) {
      this.setState({ vin: state });
    } else {
      history.replace("/home");
    }
    this.getPriceQuoteDetailByVin(state);
  }

  getPriceQuoteDetailByVin = async (vin) => {
    if (vin !== " ") {
      const response = await getPriceQuoteDetailByVin(vin);
      this.setState({ vehicleDetails: response });
    }
  };

  getMbpPriceRange = async () => {
    const { vehicleDetails, priceQuoteOdometer } = this.state;
    const { dealerId } = this.props.user;
    if (priceQuoteOdometer && vehicleDetails.vin !== " ") {
      const response = await getMbpPriceRange(
        vehicleDetails.vin,
        vehicleDetails.inventoryId,
        dealerId,
        priceQuoteOdometer
      );
      if (
        response.status === "false" ||
        response.status === false ||
        response.failedToFetch
      ) {
        this.setState({
          errorFetchingMbpPriceRange: true,
          errorMessage:
            "We are unable to provide an estimated market-based price for the VIN you have submitted. Please refer to your preferred used car valuation guide to estimate vehicle value.",
          finishedFetchingMbpPriceQuote: true,
        });
      } else {
        let mbpDate;
        if (response.leaseMaturityEndDate) {
          mbpDate = new Date(response.leaseMaturityEndDate);
        }
        this.setState({
          vehicleDetails: response,
          date: mbpDate,
          finishedFetchingMbpPriceQuote: true,
          errorFetchingMbpPriceRange: false,
        });
      }
    }
  };

  loadGroundScreen = () => {
    const { history } = this.props;
    const { vin, priceQuoteOdometer, priceQuoteOdometerConfirmation } =
      this.state;
    return vin !== ""
      ? history.push({
          pathname: ROUTE_URL.GROUND,
          state: {
            vin: vin,
            odometer: priceQuoteOdometer,
            odometerConfirmation: priceQuoteOdometerConfirmation,
          },
        })
      : "";
  };
  loadHomeScreen = () => {
    const { history } = this.props;
    //const { vin } = this.state;
    return history.push(ROUTE_URL.HOME);
  };

  getValidate = (event) => {
    const { priceQuoteOdometer, priceQuoteOdometerConfirmation } = this.state;

    if (
      priceQuoteOdometer === "" ||
      priceQuoteOdometer !== priceQuoteOdometerConfirmation
    ) {
      this.setState({
        badOdoSubmit: true,
      });
    } else {
      this.getMbpPriceRange();
      this.setState({
        preTermPriceQuote: true,
      });
    }
  };

  retryGetMbpPriceRange = () => {
    this.setState({
      finishedFetchingMbpPriceQuote: false,
    });
    this.getMbpPriceRange();
  };

  handleChange = (name) => (event) => {
    if (
      name === "priceQuoteOdometer" ||
      name === "priceQuoteOdometerConfirmation"
    ) {
      if (
        typeof Number(event.target.value) === "number" &&
        Number(event.target.value.replace(/,/g, '')) >= 0 &&
        (event.target.value !== "-" || event.target.value !== "e") &&
        !event.target.value.includes(".")
      ) {
        this.setState({
          [name]: event.target.value.replace(/,/g, ''),
          badOdoSubmit: false,
        });
      }
    }
  };

  render() {
    const { classes, history } = this.props;
    const {
      vin,
      priceQuoteOdometer,
      priceQuoteOdometerConfirmation,
      badOdoSubmit,
      vehicleDetails,
      preTermPriceQuote,
      date,
      finishedFetchingMbpPriceQuote,
      errorFetchingMbpPriceRange,
      errorMessage,
    } = this.state;
    return !preTermPriceQuote ? (
      <React.Fragment>
        <Typography className={classes.sectionHeader}>
          Pre-Term Price Quote
        </Typography>
        <Typography className={classes.priceQuoteText}>
          <strong>
            {" "}
            {vin} -{vehicleDetails.vehicleYear} {vehicleDetails.make}{" "}
            {vehicleDetails.model} {vehicleDetails.series}
          </strong>{" "}
        </Typography>
        <Typography className={classes.priceQuoteText}>
          Please enter vehicle mileage to ensure an accurate Market Based Price
          is received.
        </Typography>
        <Table className={classes.tableWrapper}>
          <TableRow>
            <span className={classes.odometerTitle}> Odometer : </span>
            <TextField
              id="filled-odometer"
              placeholder="Required Field"
              value={
                priceQuoteOdometer === ""
                  ? ""
                  : Number(priceQuoteOdometer).toLocaleString("en-US")
              }
              onChange={this.handleChange("priceQuoteOdometer")}
              variant="filled"
              autoComplete="off"
              name="priceQuoteOdometer"
              InputProps={InputProps}
              autoFocus
            />
          </TableRow>
          <TableRow>
            <span className={classes.ReEnter}>Re-enter : </span>
            <TextField
              id="filled-Re-enter"
              placeholder="Required Field"
              value={
                priceQuoteOdometerConfirmation === ""
                  ? ""
                  : Number(priceQuoteOdometerConfirmation).toLocaleString(
                      "en-US"
                    )
              }
              onChange={this.handleChange("priceQuoteOdometerConfirmation")}
              variant="filled"
              autoComplete="off"
              name="priceQuoteOdometerConfirmation"
              InputProps={InputProps}
            />
          </TableRow>
        </Table>
        <div style={{ alignSelf: "center", marginLeft: "20px" }}>
          <Button
            id="preTermPriceBtn"
            className={classes.preTermPriceButton}
            onClick={(evt) => this.getValidate()}
            //disabled={!userAccess.canGroundVehicles(user.userRoles)}
          >
            Quote
          </Button>
          <Button
            id="preTermPriceCancelBtn"
            className={classes.preTermPriceButton}
            onClick={(evt) => history.push("/home")}
            //      disabled={!userAccess.canGroundVehicles(user.userRoles)}
          >
            Cancel
          </Button>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={badOdoSubmit}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              {GROUNDING_MESSAGES.ERROR_ODOMETER_VALUE}
            </span>
          }
          autoHideDuration={3000}
          className={classes.snackBarStyle}
        />
      </React.Fragment>
    ) : (
      <PreTermPriceQuote
        vin={vin}
        vehicleDetails={vehicleDetails}
        priceQuoteOdometer={priceQuoteOdometer}
        loadGroundScreen={this.loadGroundScreen}
        loadHomeScreen={this.loadHomeScreen}
        date={date}
        finishedFetchingMbpPriceQuote={finishedFetchingMbpPriceQuote}
        errorFetchingMbpPriceRange={errorFetchingMbpPriceRange}
        errorMessage={errorMessage}
        retryGetMbpPriceRange={this.retryGetMbpPriceRange}
      ></PreTermPriceQuote>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    navLocation: state.ui.navLocation,
    pageTheme: state.ui.pageTheme,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(PriceQuote));
