export const ADD_GAMD_CARS = "ADD_GAMD_CARS";
export const ADD_SEARCH_CARS = "ADD_SEARCH_CARS";
export const SELECT_CAR_FOR_PURCHASE = "SELECT_CAR_FOR_PURCHASE";
export const REMOVE_CAR_FOR_PURCHASE = "REMOVE_CAR_FOR_PURCHASE";
export const SELECT_PASS_CAR = "SELECT_PASS_CAR";
export const QUICK_SEARCH_KEY = "QUICK_SEARCH_KEY";
export const PASS_CAR = "PASS_CAR";
export const ADD_UPSTREAM_CARS_SUCCESS = "ADD_UPSTREAM_CARS_SUCCESS";
export const UPDATE_UPSTREAM_CAR = "UPDATE_UPSTREAM_CAR";
export const ADD_GAMD_CONDITION_REPORT = "ADD_GAMD_CONDITION_REPORT";
export const GUARANTEE_PAYMENTS = "GUARANTEE_PAYMENTS";
export const ADD_DDO_CONDITION_REPORT = "ADD_DDO_CONDITION_REPORT";
export const ADD_CAR_TO_WATCHLIST = "ADD_CAR_TO_WATCHLIST";
export const REMOVE_CAR_FROM_WATCHLIST = "REMOVE_CAR_FROM_WATCHLIST";
export const ADD_WATCHLIST_CONDITION_REPORT = "ADD_WATCHLIST_CONDITION_REPORT";
export const ADVANCE_SEARCH_CARS = "ADVANCE_SEARCH_CARS";
export const ADVANCE_SEARCH_CRITERIA = "ADVANCE_SEARCH_CRITERIA";
export const ADVANCE_SEARCH_INFO = "ADVANCE_SEARCH_INFO";
export const WATCHLIST_COUNT = "WATCHLIST_COUNT";
export const SELECT_MOBILE_CAR = "SELECT_MOBILE_CAR";
export const REMOVE_SELECTED_MOBILE_CAR = "REMOVE_SELECTED_MOBILE_CAR";
export const MOBILE_SEARCH_CRITERIA = "MOBILE_SEARCH_CRITERIA";
export const MOBILE_SEARCH_EDIT_INFO = "MOBILE_SEARCH_EDIT_INFO";

export const quick_search_key = (quickSearchKey) => {
  return {
    type: QUICK_SEARCH_KEY,
    quickSearchKey,
  };
};

export const add_gamd_cars = (cars) => {
  return {
    type: ADD_GAMD_CARS,
    cars,
  };
};

export const add_search_cars = (cars) => {
  return {
    type: ADD_SEARCH_CARS,
    cars,
  };
};

export const add_upstream_cars_success = (cars) => {
  return {
    type: ADD_UPSTREAM_CARS_SUCCESS,
    cars,
  };
};

export const select_car_for_purchase = (car) => {
  return {
    type: SELECT_CAR_FOR_PURCHASE,
    car,
  };
};

export const remove_car_for_purchase = () => {
  return {
    type: REMOVE_CAR_FOR_PURCHASE,
  };
};

export const select_pass_car = (car) => {
  return {
    type: SELECT_PASS_CAR,
    car,
  };
};

export const pass_car = (car) => {
  return {
    type: PASS_CAR,
    car,
  };
};

export const update_upstream_car = (car) => {
  return {
    type: UPDATE_UPSTREAM_CAR,
    car,
  };
};

export const guarantee_payments = (car, page) => {
  return {
    type: GUARANTEE_PAYMENTS,
    car,
    page,
  };
};

export const add_gamd_condition_report = (conditionReport) => {
  return {
    type: ADD_GAMD_CONDITION_REPORT,
    conditionReport,
  };
};

export const add_ddo_condition_report = (conditionReport) => {
  return {
    type: ADD_DDO_CONDITION_REPORT,
    conditionReport,
  };
};

export const add_car_to_watchlist = (car) => {
  return {
    type: ADD_CAR_TO_WATCHLIST,
    car,
  };
};
export const remove_car_from_watchlist = (car) => {
  return {
    type: REMOVE_CAR_FROM_WATCHLIST,
    car,
  };
};

export const add_watchlist_condition_report = (conditionReport, list) => {
  return {
    type: ADD_WATCHLIST_CONDITION_REPORT,
    conditionReport,
    list,
  };
};

export const advance_search_cars = (cars) => {
  return {
    type: ADVANCE_SEARCH_CARS,
    cars,
  };
};

export const advance_search_criteria = (cars) => {
  return {
    type: ADVANCE_SEARCH_CRITERIA,
    cars,
  };
};
export const advance_search_info = (cars) => {
  return {
    type: ADVANCE_SEARCH_INFO,
    cars,
  };
};

export const watchlist_count = (count) => {
  return {
    type: WATCHLIST_COUNT,
    count,
  };
};

export const select_mobile_car = (car) => {
  return {
    type: SELECT_MOBILE_CAR,
    car,
  };
};

export const remove_selected_mobile_car = () => {
  return {
    type: REMOVE_SELECTED_MOBILE_CAR,
  };
};

export const mobile_search_criteria = (mobileSearchCriteria) => {
  return {
    type: MOBILE_SEARCH_CRITERIA,
    mobileSearchCriteria,
  };
};

export const mobile_search_edit_info = (mobileSearchEditInfo) => {
  return {
    type: MOBILE_SEARCH_EDIT_INFO,
    mobileSearchEditInfo,
  };
};
