import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import { Typography, TextField } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: "1rem",
  },
  textField: {
    marginLeft: ".5rem",
    marginRight: ".5rem",
  },
});

export class ReportVehicleDetails extends Component {
  state = {
    vehicleInfo: {},
  };

  componentWillMount = () => {
    this.setState({ vehicleInfo: this.props.vehicleInfo });
  };

  render() {
    const { classes } = this.props;
    const { vehicleInfo } = this.state;
    const InputProps = {
      readOnly: true,
      style: {
        width: "15rem",
      },
      inputProps: {
        style: {
          fontSize: ".7rem",
        },
      },
    };
    return (
      <div className={classes.root}>
        <Typography variant="body1" component="div" gutterBottom>
          <TextField
            id="modelCode"
            label="Model Number"
            defaultValue={vehicleInfo.modelCode}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="vehicleType"
            label="Vehicle Type"
            defaultValue={vehicleInfo.vehicleType}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="numberOFKeys"
            label="Master Keys Returned"
            defaultValue={vehicleInfo.numberOFKeys}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="numberOFValetKeys"
            label="Valet Keys Returned"
            defaultValue={vehicleInfo.numberOFValetKeys}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="remainingPayments"
            label="Remaining Payments"
            defaultValue={Number(vehicleInfo.remainingPayments).toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            )}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="residualAmount"
            label="Residual"
            defaultValue={Number(vehicleInfo.residualAmount).toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            )}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="payOffAmount"
            label="Payoff Amount"
            defaultValue={Number(vehicleInfo.payOffAmount).toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            )}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="marketBasedPrice"
            label="Market Based Price"
            defaultValue={
              vehicleInfo.marketBasedPrice
                ? Number(vehicleInfo.marketBasedPrice).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="groundingMileage"
            label="Grounding Mileage"
            defaultValue={vehicleInfo.groundingMileage}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="groundingDate"
            label="Grounding Date"
            defaultValue={moment(vehicleInfo.groundingDate).format(
              "MM/DD/YYYY"
            )}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="paymentGuaranteed"
            label="Payment Guaranteed"
            defaultValue={vehicleInfo.paymentGuaranteed ? "Yes" : "No"}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="leaseType"
            label="Lease Type"
            defaultValue={
              vehicleInfo.inventoryDTO
                ? vehicleInfo.inventoryDTO.leaseInformationDTO.leaseType
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="groundingDealer"
            label="Grounding Dealer"
            defaultValue={vehicleInfo.groundingDealer}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="grounder"
            label="Grounder Name"
            defaultValue={
              vehicleInfo.inventoryDTO
                ? vehicleInfo.inventoryDTO.grounder
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="paymentWaived"
            label="Payment Waiver"
            defaultValue={
              vehicleInfo.paymentWaived
                ? vehicleInfo.paymentWaived
                : "Not Eligible"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="transportOrdered"
            label="Transportation Ordered"
            defaultValue={vehicleInfo.transportFee ? "Yes" : "No"}
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="transportationStatus"
            label="Transportation Status"
            defaultValue={
              vehicleInfo.transportationStatus
                ? vehicleInfo.transportationStatus
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="inspectionGrade"
            label="Grade Condition"
            defaultValue={
              vehicleInfo.inspectionGrade ? vehicleInfo.inspectionGrade : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="excessWearAndTearGrandTotal"
            label="EWT"
            defaultValue={
              vehicleInfo.conditionReportAvailable
                ? vehicleInfo.conditionReportDTO.excessWearAndTearGrandTotal
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="inspScheduleDate"
            label="Inspection Schedule Date"
            defaultValue={
              vehicleInfo.inspScheduleDate
                ? moment(vehicleInfo.inspScheduleDate).format("MM/DD/YYYY")
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="inspectedDate"
            label="Inspection Completion Date"
            defaultValue={
              vehicleInfo.isInspectionQcCompleted
                ? moment(
                    vehicleInfo.inspectionInformationDTO.inspectedDate
                  ).format("MM/DD/YYYY")
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="inspectionMileage"
            label="Inspection Mileage"
            defaultValue={
              vehicleInfo.inspectionMileage
                ? vehicleInfo.inspectionMileage
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="reason"
            label="Not Inspected Reason"
            defaultValue={
              vehicleInfo.inspectionInformationDTO &&
              vehicleInfo.inspectionInformationDTO.reason
                ? vehicleInfo.inspectionInformationDTO.reason
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="purchaseDate"
            label="Sold Date"
            defaultValue={
              vehicleInfo.purchaseDate
                ? moment(vehicleInfo.purchaseDate).format("MM/DD/YYYY")
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="purchasingDealer"
            label="Purchasing Dealer"
            defaultValue={
              vehicleInfo.purchasingDealer
                ? vehicleInfo.purchasingDealer
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="purchaseType"
            label="Purchasing Type"
            defaultValue={
              vehicleInfo.purchaseType ? vehicleInfo.purchaseType : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
          <TextField
            id="netPurchaseAmount"
            label="Gross Purchase Amount"
            defaultValue={
              vehicleInfo.netPurchaseAmount
                ? Number(vehicleInfo.netPurchaseAmount).toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )
                : "N/A"
            }
            className={classes.textField}
            InputProps={InputProps}
          />
        </Typography>
      </div>
    );
  }
}

ReportVehicleDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportVehicleDetails);
