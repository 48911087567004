import {
  ADD_PURCHASED_VEHICLE,
  ADD_SEARCH_CRITERIA,
  ADVANCE_SEARCH_CRITERIA_DATA,
  ADVANCE_SEARCH_CRITERIA_WRAPPER_DATA,
  CLEAR_STORED_ARBITRATIONS,
  CLEAR_STORED_REPORTS,
  HIDE_MOBILE_NAV_MENU,
  NON_SAFARI_MOBILE_APP_DOWNLOAD_NOTIFICATION_MODAL,
  MOBILE_APP_DOWNLOAD_INSTRUCTIONS_MODAL,
  REMOVE_PURCHASED_VEHICLE,
  SET_AUCTION_TIMES,
  SET_BUYER_VIEW,
  SET_CURRENT_AUCTION,
  SET_EXTERNAL_CONDITION_REPORT_VIEW,
  SET_NAV_LOCATION,
  SET_PAGE_THEME,
  SET_VEHICLES_NEEDING_ATTENTION,
  SHOW_SAVED_SEARCH_PAGE,
  STORE_ARBITRATIONS,
  STORE_FILTER_OPTIONS,
  STORE_REPORTS,
  SYSTEM_MESSAGE_VIEWED,
  TICK_TIMER,
  TOGGLZ_CONFIG,
} from "../actions/uiActions";
import * as R from "ramda";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import { lexusTheme, toyotaTheme } from "themes";

export const uiState = {
  purchasedVehicles: {},
  searchCriteria: {},
  auctionTimes: {},
  navLocation: "home",
  currentAuction: "preSale1",
  reports: null,
  arbitrations: null,
  pageTheme: "",
  theme: {},
  hideMobileNavMenu: false,
  nonSafariMobileAppDownloadNotificationModal: {
    isHidden: true,
    isSeen: false,
  },
  mobileAppDownloadInstructionsModal: {
    isHidden: true,
    mobileCode: null,
    url: null,
    isSeen: false,
  },
  advancedSearchCriteriaWrapperData: null,
  advancedSearchCriteriaData: null,
  showsavedsearchpage: false,
  buyerView: false,
  systemMessageViewed: false,
  filterOptions: null,
  externalConditionReportView: false,
  togglzMap: {},
};

const persistConfig = {
  key: "ui",
  storage: storageSession,
  blacklist: ["theme", "pageTheme"],
};

const uiReducer = (state = uiState, action) => {
  switch (action.type) {
    case ADD_PURCHASED_VEHICLE:
      return R.assocPath(["purchasedVehicles", action.vin], true, state);
    case REMOVE_PURCHASED_VEHICLE:
      if (state.purchasedVehicles[action.vin]) {
        return R.dissocPath(["purchasedVehicles", action.vin], state);
      } else {
        return state;
      }
    case TICK_TIMER:
      const timerValue = R.lensPath(["cancelTimers", action.vin]);
      const timeRemaining = R.view(timerValue, state) - 1000;
      return R.set(timerValue, timeRemaining, state);
    case ADD_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: action.criteria,
      };
    case SET_AUCTION_TIMES:
      return {
        ...state,
        auctionTimes: action.times,
      };
    case SET_NAV_LOCATION:
      return {
        ...state,
        navLocation: action.navLocation,
      };
    case SET_CURRENT_AUCTION:
      return {
        ...state,
        currentAuction: action.auction,
      };
    case STORE_REPORTS:
      return {
        ...state,
        reports: {
          [action.dataType]: action.report,
          to: action.to,
          from: action.from,
        },
      };
    case CLEAR_STORED_REPORTS:
      return {
        ...state,
        reports: null,
      };
    case STORE_ARBITRATIONS:
      return {
        ...state,
        arbitrations: {
          ...state.arbitrations,
          [action.dataType]: action.arbitrations,
        },
      };
    case CLEAR_STORED_ARBITRATIONS:
      return {
        ...state,
        arbitrations: null,
      };
    case HIDE_MOBILE_NAV_MENU:
      const _hideMobileNavMenu = action.hideMobileNavMenu;
      return {
        ...state,
        hideMobileNavMenu: _hideMobileNavMenu,
      };
    case NON_SAFARI_MOBILE_APP_DOWNLOAD_NOTIFICATION_MODAL:
      const _nonSafariMobileAppDownloadNotificationModal =
        action.nonSafariMobileAppDownloadNotificationModal;
      return {
        ...state,
        nonSafariMobileAppDownloadNotificationModal:
          _nonSafariMobileAppDownloadNotificationModal,
      };
    case MOBILE_APP_DOWNLOAD_INSTRUCTIONS_MODAL:
      const _mobileAppDownloadInstructionsModal =
        action.mobileAppDownloadInstructionsModal;
      return {
        ...state,
        mobileAppDownloadInstructionsModal: _mobileAppDownloadInstructionsModal,
      };
    case SET_PAGE_THEME:
      let theme;
      let pageTheme;
      const url = window.location.href;
      const re = /(lexus|ldd)/gm;
      if (action.franchise === "LBSG" || re.test(url)) {
        theme = lexusTheme;
        pageTheme = "Lexus";
      } else {
        theme = toyotaTheme;
        pageTheme = "Toyota";
      }
      return {
        ...state,
        pageTheme,
        theme,
      };
    case ADVANCE_SEARCH_CRITERIA_WRAPPER_DATA:
      return {
        ...state,
        advancedSearchCriteriaWrapperData:
          action.advancedSearchCriteriaWrapperData,
      };
    case ADVANCE_SEARCH_CRITERIA_DATA:
      return {
        ...state,
        advancedSearchCriteriaData: action.advancedSearchCriteriaData,
      };
    case SHOW_SAVED_SEARCH_PAGE:
      return {
        ...state,
        showsavedsearchpage: action.showsavedsearchpage,
      };
    case SET_BUYER_VIEW:
      return {
        ...state,
        buyerView: true,
      };
    case SET_VEHICLES_NEEDING_ATTENTION:
      return {
        ...state,
        vehiclesNeedingAttention: action.toggle,
      };
    case SYSTEM_MESSAGE_VIEWED:
      return {
        ...state,
        systemMessageViewed: true,
      };
    case STORE_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.options,
      };
    case SET_EXTERNAL_CONDITION_REPORT_VIEW:
      return {
        ...state,
        externalConditionReportView: true,
      };
    case TOGGLZ_CONFIG:
      return {
        ...state,
        togglzMap: action.togglzMap,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, uiReducer);
