import React from "react";
import PropTypes from "prop-types";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    position: "relative",
    overflow: "auto",
    maxHeight: 200,
    backgroundColor: theme.palette.light.gray,
    width: "100%",
  },
  rootDisabled: {
    minWidth: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 200,
    backgroundColor: theme.palette.light.gray,
    pointerEvents: "none",
    marginRight: "1rem",
  },
  MuiListItemRoot: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  container: {
    color: theme.palette.primary.gray,
    width: "100%",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
  checkboxRoot: {
    "&$checked": {
      color: theme.palette.primary.gray,
      "&:hover": {
        backgroundColor: fade(
          theme.palette.primary.gray,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "&:hover": {
      backgroundColor: fade(
        theme.palette.primary.gray,
        theme.palette.action.hoverOpacity
      ),
    },
    padding: ".25rem",
    marginLeft: ".5rem",
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  optionLabel: {
    fontSize: ".8rem",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.font.small,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  "@global": {
    ".SearchCheckboxOptions-MuiListItemRoot-457": {
      padding: 0,
    },
  },
});

const renderAuctionOptions = (props) => {
  const {
    criteria,
    isChecked,
    handleCheck,
    classes,
    stateKey,
    userRoleHasMobilityRideshareView,
  } = props;
  return criteria.sort().map((option) => {
    return (
      <ListItem className={classes.MuiListItemRoot} key={`${option}CheckLi`}>
        <FormControlLabel
          key={option}
          classes={{ label: classes.optionLabel }}
          control={
            <Checkbox
              id={`${option}Check`}
              name={`${option}Check`}
              checked={isChecked(stateKey, option)}
              onChange={handleCheck(stateKey)}
              value={option}
              disabled={userRoleHasMobilityRideshareView ? true : false}
              option="primary"
              color="default"
              classes={{
                root: classes.checkboxRoot,
                checked: classes.checked,
              }}
            />
          }
          label={option}
        />
      </ListItem>
    );
  });
};

const returnOptions = (props) => {
  const {
    criteria,
    isChecked,
    handleCheck,
    classes,
    stateKey,
    userRoleHasMobilityRideshareView,
  } = props;
  switch (stateKey) {
    case "selectedDistance":
      return criteria.map((option) => {
        return (
          <ListItem
            className={classes.MuiListItemRoot}
            key={`${option}CheckLi`}
          >
            <FormControlLabel
              key={option}
              classes={{ label: classes.optionLabel }}
              control={
                <Checkbox
                  id={`${option}Check`}
                  name={`${option}Check`}
                  checked={isChecked(stateKey, option)}
                  onChange={handleCheck(stateKey)}
                  value={option}
                  option="primary"
                  color="default"
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={option}
            />
          </ListItem>
        );
      });
    case "selectedAuction":
      return (
        <React.Fragment>
          <ListItem className={classes.MuiListItemRoot} key={"AllAuction"}>
            <FormControlLabel
              key={"All Auction"}
              classes={{ label: classes.optionLabel }}
              control={
                <Checkbox
                  id={"AllAuction"}
                  name={"AllAuction"}
                  checked={isChecked(stateKey, "AllAuction")}
                  onChange={handleCheck(stateKey)}
                  value={"AllAuction"}
                  option="primary"
                  color="default"
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={"All Auction"}
            />
          </ListItem>
          {renderAuctionOptions(props)}
        </React.Fragment>
      );
    case "selectedVehicleGrade":
      return (
        <React.Fragment>
          <ListItem className={classes.MuiListItemRoot} key={`0CheckLi`}>
            <FormControlLabel
              key={"0"}
              classes={{ label: classes.optionLabel }}
              control={
                <Checkbox
                  id={`${"0"}Check`}
                  name={`${"0"}Check`}
                  checked={isChecked(stateKey, "0")}
                  onChange={handleCheck(stateKey)}
                  value={"0"}
                  option="primary"
                  color="default"
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={"0-.99"}
            />
          </ListItem>
          <ListItem className={classes.MuiListItemRoot} key={`1CheckLi`}>
            <FormControlLabel
              key={"1"}
              classes={{ label: classes.optionLabel }}
              control={
                <Checkbox
                  id={`${"1"}Check`}
                  name={`${"1"}Check`}
                  checked={isChecked(stateKey, "1")}
                  onChange={handleCheck(stateKey)}
                  value={"1"}
                  option="primary"
                  color="default"
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={"1-1.99"}
            />
          </ListItem>
          <ListItem className={classes.MuiListItemRoot} key={`2CheckLi`}>
            <FormControlLabel
              key={"2"}
              classes={{ label: classes.optionLabel }}
              control={
                <Checkbox
                  id={`${"2"}Check`}
                  name={`${"2"}Check`}
                  checked={isChecked(stateKey, "2")}
                  onChange={handleCheck(stateKey)}
                  value={"2"}
                  option="primary"
                  color="default"
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={"2-2.99"}
            />
          </ListItem>
          <ListItem className={classes.MuiListItemRoot} key={`3CheckLi`}>
            <FormControlLabel
              key={"3"}
              classes={{ label: classes.optionLabel }}
              control={
                <Checkbox
                  id={`${"3"}Check`}
                  name={`${"3"}Check`}
                  checked={isChecked(stateKey, "3")}
                  onChange={handleCheck(stateKey)}
                  value={"3"}
                  option="primary"
                  color="default"
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={"3-3.99"}
            />
          </ListItem>
          <ListItem className={classes.MuiListItemRoot} key={`4CheckLi`}>
            <FormControlLabel
              key={"4"}
              classes={{ label: classes.optionLabel }}
              control={
                <Checkbox
                  id={`${"4"}Check`}
                  name={`${"4"}Check`}
                  checked={isChecked(stateKey, "4")}
                  onChange={handleCheck(stateKey)}
                  value={"4"}
                  option="primary"
                  color="default"
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={"4-4.99"}
            />
          </ListItem>
          <ListItem className={classes.MuiListItemRoot} key={`5CheckLi`}>
            <FormControlLabel
              key={"5"}
              classes={{ label: classes.optionLabel }}
              control={
                <Checkbox
                  id={`${"5"}Check`}
                  name={`${"5"}Check`}
                  checked={isChecked(stateKey, "5")}
                  onChange={handleCheck(stateKey)}
                  value={"5"}
                  option="primary"
                  color="default"
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={"5"}
            />
          </ListItem>
        </React.Fragment>
      );
    default:
      return criteria.sort().map((option) => {
        return (
          <ListItem
            className={classes.MuiListItemRoot}
            key={`${option}CheckLi`}
          >
            <FormControlLabel
              key={option}
              classes={{ label: classes.optionLabel }}
              control={
                <Checkbox
                  id={`${option}Check`}
                  name={`${option}Check`}
                  checked={isChecked(stateKey, option)}
                  onChange={handleCheck(stateKey)}
                  value={option}
                  disabled={userRoleHasMobilityRideshareView ? true : false}
                  option="primary"
                  color="default"
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={option}
            />
          </ListItem>
        );
      });
  }
};

export const SearchCheckboxOptions = (props) => {
  const { classes, isDisabled } = props;
  return (
    <FormGroup row className={classes.container}>
      <List
        className={isDisabled === true ? classes.rootDisabled : classes.root}
      >
        {returnOptions(props)}
      </List>
    </FormGroup>
  );
};

SearchCheckboxOptions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchCheckboxOptions);
