import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import * as userAccess from "../services/UserRoles";
import { connect } from "react-redux";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
    fontSize: ".65rem",
  },
  progress: {
    margin: ".5rem",
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
});
export class MyTransportationReportTable extends Component {
  state = {
    isRideShareUser: false,
  };

  componentDidMount = () => {
    const { user } = this.props;
    if (userAccess.isRideshareUser(user.userRoles)) {
      this.setState({
        isRideShareUser: true,
      });
    }
  };

  render() {
    const { classes, data, toggleConditionReport } = this.props;
    const { isRideShareUser } = this.state;
    const cellStyle = {
      maxWidth: "5rem",
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
    };
    return (
      <div id="materialTable" className={classes.root}>
        <MaterialTable
          columns={[
            {
              title: "Transportation Ordered",
              cellStyle,
              field: "transOrderedDate",
              render: (rowData) =>
                moment(rowData.transOrderedDate).format("MM/DD/YYYY"),
            },
            {
              title: "Year",
              field: "year",
              cellStyle,
            },
            {
              title: "VIN/CR/Model/Trim",
              cellStyle,
              field: "model",
              render: (rowData) => (
                <div>
                  {rowData.model} {rowData.trim} <br />
                  <span
                    onClick={
                      rowData.conditionReportAvailable
                        ? () =>
                            toggleConditionReport(
                              rowData.inventoryId,
                              "transportationData"
                            )
                        : null
                    }
                    className={
                      rowData.conditionReportAvailable
                        ? classes.clickableLink
                        : null
                    }
                  >
                    {rowData.vin}
                  </span>
                </div>
              ),
            },
            {
              title: "Color",
              field: "color",
              cellStyle,
            },
            {
              title: "Odometer",
              cellStyle,
              field: "odometer",
              render: (rowData) =>
                rowData.odometerReading
                  ? Number(rowData.odometerReading).toLocaleString()
                  : null,
            },
            {
              title: "Transport Destination",
              field: "transportDestination",
              cellStyle,
            },
            {
              title: "Est. Pickup Date",
              cellStyle,
              field: "estPickUpDate",
              render: (rowData) =>
                rowData.estPickUpDate
                  ? moment(rowData.estPickUpDate).format("MM/DD/YYYY")
                  : "",
            },
            {
              title: "Pickup Date",
              cellStyle,
              field: "pickUpDate",
              render: (rowData) =>
                rowData.pickUpDate
                  ? moment(rowData.pickUpDate).format("MM/DD/YYYY")
                  : "",
            },
            {
              title: "Delivered Date",
              cellStyle,
              hidden: isRideShareUser,
              field: "deliveryDate",
              render: (rowData) =>
                rowData.deliveredDate
                  ? moment(rowData.deliveryDate).format("MM/DD/YYYY")
                  : "",
            },
            {
              title: "Rideshare Program",
              field: "rideshareProgram",
              cellStyle,
              hidden: !isRideShareUser,
            },
            {
              title: "Transportation Status",
              field: "transportStatus",
              cellStyle,
            },
          ]}
          data={data}
          options={{
            headerStyle: {
              backgroundColor: "#EEE",
              textAlign: "center",
              padding: ".5rem",
            },
            showTitle: false,
            sorting: true,
            search: true,
            paging: true,
            maxBodyHeight: 400,
          }}
        />
      </div>
    );
  }
}

MyTransportationReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(MyTransportationReportTable)
);
