import React, { Component } from "react";
import ReactSVG from "react-svg";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

import circleReportIcon from "../assets/reportsIcon.svg";
import ReportSearch from "../components/ReportSearch";
import ConditionReport from "../components/ConditionReport";

import { set_nav_location } from "../actions/uiActions";

import { getSingleConditionReport } from "../services/ConditionReportService";
import { advancedSearchCriteriaWrapper } from "../services/AdvanceSearchServices";

const styles = (theme) => ({
  reportRoot: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
  },
  container: {
    display: "flex",
    height: "100%",
  },
  header: {
    width: "97%",
    marginTop: ".5rem",
    height: "2.5rem",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    justifyContent: "space-between",
    display: "flex",
  },
  titleWrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    marginLeft: ".5rem",
    width: "7rem",
  },
  buttonWrapper: {
    marginLeft: "5vw",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  buttonText: {
    fontSize: ".8rem",
  },
  noButtonUnderline: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "5px solid transparent",
  },
  buttonUnderline: {
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  highlightedButtonText: {
    color: theme.palette.primary.main,
    fontSize: ".8rem",
  },
  titleTextWrapper: {
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  titleText: {
    color: theme.palette.primary.main,
    fontSize: ".8rem",
    fontWeight: "bold",
  },
  selectionButton: {
    backgroundColor: theme.palette.primary.main,
    width: "2.5rem",
    height: "1.78rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  navButton: {
    height: "1.5rem",
    lineHeight: 0,
    padding: 0,
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    width: "max-content",
  },
  reportWrapper: {
    margin: ".5rem",
    height: "84%",
  },
  reportTitle: {
    fontSize: "1.5rem",
    color: theme.palette.primary.gray,
  },
  leftWrapper: {
    width: "75%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.dark,
    margin: ".5rem",
  },
  rightWrapper: {
    width: "25%",
    height: "100%",
  },
  loader: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  ReportIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    height: "2rem",
    width: "2rem",
    marginRight: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const tabTitles = [
  "My Purchased Report",
  "My Grounding Report",
  "Dealer Purchase Performance Report",
  "My Bid History",
  "My Inspection Report",
  "My Transportation Report",
  "My Grounding Purchased by other dealers",
  "My Purchases Goal Report",
];
const buttonLabels = [
  "Purchased",
  "Grounded",
  "Purchase Performance",
  "Bid History",
  "Inspection",
  "Transportation",
  "Upstream Sale",
  "Goal Report",
];
export class Reports extends Component {
  state = {
    tabValue: 0,
    models: [],
  };

  componentWillMount = () => {
    const { dispatch, navLocation } = this.props;
    if (navLocation !== "reports") {
      dispatch(set_nav_location("reports"));
    }
    this.fetchModels();
  };

  handleTabValueChange = (tabValue) => {
    this.setState({ tabValue });
  };

  renderTabContent = () => {
    const { tabValue, models } = this.state;
    const { classes, purchasedVehicles } = this.props;

    return (
      <div className={classes.reportWrapper}>
        <Typography className={classes.reportTitle}>
          {tabTitles[tabValue]}
        </Typography>
        <ReportSearch
          tabValue={tabValue}
          purchasedVehicles={purchasedVehicles}
          toggleConditionReport={this.toggleConditionReport}
          models={models}
        />
      </div>
    );
  };

  toggleConditionReport = async (inventoryId) => {
    const { userId, dealerId } = this.props.user;
    const { conditionReport } = this.state;
    if (!conditionReport) {
      const conditionReport = await getSingleConditionReport(
        inventoryId,
        userId,
        dealerId
      );
      this.setState({
        conditionReport,
      });
    } else {
      this.setState({
        conditionReport: null,
      });
    }
  };

  fetchModels = async () => {
    const searchCriteria = await advancedSearchCriteriaWrapper();
    const lexusModels = Object.keys(
      searchCriteria.typeMakeModelSeriesModelNumberMap.Lexus
    );
    let scionModels = [];
    if (
      searchCriteria.typeMakeModelSeriesModelNumberMap.Scion !== null &&
      searchCriteria.typeMakeModelSeriesModelNumberMap.Scion !== undefined
    ) {
      scionModels = Object.keys(
        searchCriteria.typeMakeModelSeriesModelNumberMap.Scion
      );
    }
    const toyotaModels = Object.keys(
      searchCriteria.typeMakeModelSeriesModelNumberMap.Toyota
    );
    this.setState({
      models: {
        Lexus: lexusModels,
        Scion: scionModels,
        Toyota: toyotaModels,
      },
    });
  };

  render() {
    const { classes, user } = this.props;
    const { tabValue, conditionReport } = this.state;
    return (
      <React.Fragment>
        {!conditionReport ? (
          <div className={classes.reportRoot}>
            <div className={classes.header}>
              <div className={classes.titleWrapper}>
                <ReactSVG
                  svgStyle={{
                    fill: "#FFFFFF",
                    position: "relative",
                    top: ".1rem",
                  }}
                  className={classes.ReportIcon}
                  src={circleReportIcon}
                  alt="Report Icon"
                />
                <div className={classes.titleTextWrapper}>
                  <Typography className={classes.titleText}>Reports</Typography>
                </div>
              </div>
              <div className={classes.buttonWrapper}>
                {buttonLabels.map((label, index) => {
                  return (
                    <div
                      key={label}
                      className={
                        tabValue === index
                          ? classes.buttonUnderline
                          : classes.noButtonUnderline
                      }
                    >
                      <Button
                        id={`${label}Button`}
                        className={classes.navButton}
                        variant="outlined"
                        onClick={(evt) => this.handleTabValueChange(index)}
                      >
                        <Typography
                          className={
                            tabValue === index
                              ? classes.highlightedButtonText
                              : classes.buttonText
                          }
                        >
                          {label}
                        </Typography>
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
            {this.renderTabContent()}
          </div>
        ) : (
          <ConditionReport
            user={user}
            toggleFullConditionReport={this.toggleConditionReport}
            conditionReport={conditionReport}
            fromReports
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    navLocation: state.ui.navLocation,
    purchasedVehicles: state.ui.purchasedVehicles,
    user: state.user,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Reports));
