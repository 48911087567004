import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";

import Notes from "../components/Notes";

import * as ReportService from "../services/ReportServices";
import { getNotes } from "../services/NotesServices";
import * as userAccess from "../services/UserRoles";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
    fontSize: ".65rem",
  },
  progress: {
    margin: "1rem",
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
});
export class MyGroundedReportTable extends Component {
  state = {
    data: [],
    notes: [],
    user: "",
    accountSetting: "",
    selectedNotesInventoryId: "",
    selectedInfoInventoryId: "",
    loadingNotesComplete: false,
  };

  componentDidMount = () => {
    const { data, user, accountSetting } = this.props;
    if (data) {
      this.setState({
        user,
        accountSetting,
        data,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    if (data !== prevProps.data) {
      this.setState({ data });
    }
  };

  getOdometerStatemetPdf = (inventoryId) => {
    ReportService.getOdometerStatementPdf(inventoryId);
  };

  toggleNote = () => {
    //no-op
  };

  displayNotes = (inventoryId, vin) => {
    const { classes } = this.props;
    const { user, notes, selectedNotesInventoryId, loadingNotesComplete } =
      this.state;
    if (selectedNotesInventoryId !== inventoryId) {
      this.setState({
        selectedNotesInventoryId: inventoryId,
        loadingNotesComplete: false,
        notes: [],
      });
      getNotes(user.dealerId, inventoryId).then((notes) => {
        if (notes && notes.status !== "false") {
          this.setState({
            notes,
          });
        }
        this.setState({ loadingNotesComplete: true });
      });
    } else {
      if (loadingNotesComplete) {
        return (
          <div>
            <Notes
              addNote={this.addNote}
              toggleNote={this.toggleNote}
              notes={notes}
              user={user}
              car={{
                vehicleIdentificationNumber: vin,
                id: inventoryId,
                inventoryId: inventoryId,
              }}
              reports
            />
          </div>
        );
      } else {
        return (
          <div>
            <CircularProgress className={classes.progress} />
          </div>
        );
      }
    }
  };

  addNote = (newNote) => {
    const { accountSetting, selectedInventoryId, user } = this.state;
    const fullNote = {
      inventoryId: selectedInventoryId,
      dealerId: user.dealerId,
      note: newNote,
      needFollowUp: false,
      userId: user.userId,
      firstName: accountSetting.firstName,
      lastName: accountSetting.lastName,
      createdDate: moment().format("LLL"),
    };
    this.setState({
      notes: [...this.state.notes, fullNote],
    });
  };

  render() {
    const { classes, toggleConditionReport, user } = this.props;
    const cellStyle = {
      maxWidth: "5rem",
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
    };
    return (
      <div id="materialTable" className={classes.root}>
        <MaterialTable
          columns={[
            {
              title: "Grounded Date",
              field: "groundedDate",
              cellStyle,
              render: (rowData) =>
                moment(rowData.groundedDate).format("MM/DD/YYYY"),
              defaultSort: "desc",
            },
            {
              title: "Return Date",
              field: "customerReturnDate",
              cellStyle,
              render: (rowData) =>
                rowData.customerReturnDate === null
                  ? "-"
                  : moment(rowData.customerReturnDate).format("MM/DD/YYYY"),
            },
            {
              title: "Master Keys",
              field: "numberOfKeys",
              cellStyle,
            },
            {
              title: "Valet Keys",
              field: "numberOfValetKeys",
              cellStyle,
            },
            {
              title: "Year",
              field: "year",
              cellStyle,
            },
            {
              title: "VIN/CR/Model/Trim",
              field: "model",
              cellStyle,
              render: (rowData) => (
                <div>
                  {rowData.model} {rowData.trim}{" "}
                  <span
                    onClick={
                      rowData.conditionReportAvailable
                        ? () =>
                            toggleConditionReport(
                              rowData.inventoryId,
                              "groundedData"
                            )
                        : null
                    }
                    className={
                      rowData.conditionReportAvailable
                        ? classes.clickableLink
                        : null
                    }
                  >
                    {rowData.vin}
                  </span>
                </div>
              ),
            },
            {
              title: "Account Type",
              field: "accountType",
              cellStyle,
            },
            {
              title: "Color",
              field: "color",
              cellStyle,
            },
            {
              title: "Odometer",
              field: "odometerReading",
              cellStyle,
            },
            {
              title: "Payoff",
              field: "payoffAmount",
              cellStyle,
              render: (rowData) =>
                Number(rowData.payoffAmount).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
            },
            {
              title: "Residual",
              field: "residualAmount",
              cellStyle,
              render: (rowData) =>
                Number(rowData.residualAmount).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
            },
            {
              title: "Market",
              field: "mbpStatus",
              cellStyle,
              render: (rowData) =>
                rowData.mbpStatus === "MBP_REQ"
                  ? "Pending"
                  : rowData.marketPrice,
            },
            {
              title: "Remaining Payments",
              field: "remainingPaymentAmount",
              cellStyle,
              render: (rowData) =>
                Number(rowData.remainingPaymentAmount).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
            },
            {
              title: "Guaranteed",
              field: "isPaymentGuaranteed",
              cellStyle,
            },
            {
              title: "Promo",
              field: "programCode",
              cellStyle,
            },
            {
              title: "Status",
              field: "status",
              cellStyle,
            },
          ]}
          data={this.state.data}
          detailPanel={
            userAccess.canPurchaseVehicles(user.userRoles)
              ? [
                  {
                    icon: "noteadd",
                    tooltip: "Show Notes",
                    render: (rowData) => {
                      return this.displayNotes(
                        rowData.inventoryId,
                        rowData.vin
                      );
                    },
                  },
                ]
              : []
          }
          actions={[
            {
              iconProps: { id: "actions" },
              icon: "save",
              tooltip: "Download Odometer Statement",
              onClick: (event, rowData) => {
                this.getOdometerStatemetPdf(rowData.inventoryId);
              },
            },
          ]}
          options={{
            headerStyle: {
              backgroundColor: "#EEE",
              textAlign: "center",
              fontSize: ".7rem",
              padding: 0,
            },
            showTitle: false,
            sorting: true,
            search: true,
            actionsColumnIndex: -1,
            detailPanelType: "single",
            detailPanelColumnAlignment: "left",
            maxBodyHeight: 1000,
            toolbar: true,
          }}
        />
      </div>
    );
  }
}

MyGroundedReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyGroundedReportTable);
