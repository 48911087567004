import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  Input,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  button: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.gray,
    fontSize: theme.font.small,
    fontWeight: "bold",
    width: "9rem",
    color: theme.palette.primary.white,
    marginTop: "1rem",
    [theme.breakpoints.down("md")]: {
      marginTop: ".5rem",
      marginLeft: ".25rem",
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: ".5rem",
      marginLeft: ".25rem",
      width: "60%",
    },
  },
  formControl: {
    width: "9.55rem",
  },
  textField: {
    margin: 0,
    width: "13rem",
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    height: "2rem",
    borderRadius: 0,
    marginTop: ".5rem",
  },
  fieldWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "2rem",
    margin: ".25rem",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
    },
  },
  locationWrapper: {
    width: "40rem",
    marginLeft: "2rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: "2.5rem",
      marginBottom: ".5rem",
      width: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
      marginBottom: ".5rem",
      width: "95%",
    },
  },
  stateSelect: {
    width: "7rem",
    transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    marginTop: ".5rem",
    marginRight: ".25rem",
    height: "2rem",
    padding: 0,
  },
  timeZone: {
    width: "10rem",
    transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    marginTop: ".5rem",
    marginRight: ".25rem",
    height: "2rem",
    padding: 0,
  },
  systemMessage: {
    marginLeft: ".5rem",
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
    marginTop: ".5rem",
    [theme.breakpoints.down("md")]: {
      width: "92%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "92%",
    },
  },
  "@global": {
    ".MuiSelect-selectMenu": {
      height: "1.1rem",
      textAlign: "center",
    },
    ".MuiDialog-paperWidthSm": {
      maxWidth: "100rem",
    },
    "label + .MuiInput-formControl": {
      width: "7rem",
      transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      backgroundColor: "rgba(0, 0, 0, 0.09)",
      marginTop: 0,
      height: "2rem",
    },
  },
});

export class LocationForm extends Component {
  state = {
    labelWidth: 0,
  };

  stateSelectInit = () => {
    const { stateDetails } = this.props;
    if (stateDetails[0]) {
      return stateDetails.map((stateDD) => {
        return (
          <MenuItem value={stateDD.stateId} key={stateDD.stateId}>
            {stateDD.name}
          </MenuItem>
        );
      });
    }
  };

  render() {
    const { classes, onHandleChange, onAddLocation } = this.props;
    const {
      locationName,
      address1,
      address2,
      city,
      phone,
      fax,
      timeZoneId,
      stateId,
      zip,
      contactName1,
      contactPhone1,
      contactEmail1,
      contactName2,
      contactPhone2,
      contactEmail2,
    } = this.props.locationDetails;
    const InputLabelProps = {
      style: {
        fontSize: ".7rem",
        marginLeft: ".5rem",
      },
    };
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
        width: "13rem",
      },
      inputProps: {
        style: {
          paddingBottom: 0,
          height: "100%",
          fontSize: ".8rem",
        },
      },
    };
    return (
      <div className={classes.locationWrapper}>
        <div className={classes.fieldWrapper}>
          <TextField
            id="locationName"
            label="Location Name"
            className={classes.textField}
            margin="none"
            variant="standard"
            required
            onChange={onHandleChange("locationName", "isLocationNameValid")}
            value={locationName}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <TextField
            id="address1"
            label="Address 1"
            className={classes.textField}
            margin="none"
            variant="standard"
            required
            onChange={onHandleChange("address1", "isAddress1Valid")}
            value={address1}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
          <TextField
            id="address2"
            label="Address 2"
            className={classes.textField}
            margin="none"
            variant="standard"
            onChange={onHandleChange("address2")}
            value={address2}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <TextField
            id="city"
            label="City"
            className={classes.textField}
            margin="none"
            variant="standard"
            required
            onChange={onHandleChange("city", "isCityValid")}
            value={city}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />

          <Select
            id="stateId"
            value={stateId}
            onChange={onHandleChange("stateId", "isStateIdValid")}
            className={classes.stateSelect}
            autoComplete="true"
            disableUnderline
            displayEmpty
            required
            input={<Input name="state" id="state" />}
          >
            <MenuItem value={""}>{"State*"}</MenuItem>
            {this.stateSelectInit()}
          </Select>

          <TextField
            id="zip"
            label="Zip"
            className={classes.textField}
            margin="none"
            variant="standard"
            required
            onChange={onHandleChange("zip", "isZipValid")}
            value={zip}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Select
            id="timeZoneId"
            value={timeZoneId}
            onChange={onHandleChange("timeZoneId", "isTimeZoneIdValid")}
            className={classes.timeZone}
            displayEmpty
            disableUnderline
            required
            input={<Input name="timeZone" id="timeZone" />}
            autoComplete="true"
          >
            <MenuItem value="">TimeZone*</MenuItem>
            <MenuItem value="15697">Pacific Time</MenuItem>
            <MenuItem value="15694">Hawaiian Time</MenuItem>
            <MenuItem value="15695">Alaskan Time</MenuItem>
            <MenuItem value="15708">Eastern Time</MenuItem>
            <MenuItem value="15698">Mountain Time</MenuItem>
            <MenuItem value="15705">Central Time</MenuItem>
          </Select>
          <TextField
            id="phone"
            label="Phone"
            className={classes.textField}
            margin="none"
            variant="standard"
            required
            onChange={onHandleChange("phone", "isPhoneValid")}
            value={phone}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
          <TextField
            id="fax"
            label="Fax"
            className={classes.textField}
            margin="none"
            variant="standard"
            onChange={onHandleChange("fax")}
            value={fax}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
        </div>
        <Typography className={classes.systemMessage}>
          Please provide contact information for the individuals you wish to be
          contacted by the transportation company.{" "}
        </Typography>
        <div className={classes.fieldWrapper}>
          <TextField
            id="contactName1"
            label="Contact Name 1"
            className={classes.textField}
            margin="none"
            variant="standard"
            required
            onChange={onHandleChange("contactName1", "isContactName1Valid")}
            value={contactName1}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
          <TextField
            id="contactPhone1"
            label="Contact Phone 1"
            className={classes.textField}
            margin="none"
            variant="standard"
            required
            onChange={onHandleChange("contactPhone1", "isContactPhone1Valid")}
            value={contactPhone1}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />

          <TextField
            id="contactEmail1"
            label="Contact Email 1"
            className={classes.textField}
            margin="none"
            variant="standard"
            onChange={onHandleChange("contactEmail1")}
            value={contactEmail1}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <TextField
            id="contactName2"
            label="Contact Name 2"
            className={classes.textField}
            margin="none"
            variant="standard"
            onChange={onHandleChange("contactName2", "contactName2")}
            value={contactName2}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
          <TextField
            id="contactPhone2"
            label="Contact Phone 2"
            required={contactName2 !== ""}
            className={classes.textField}
            margin="none"
            variant="standard"
            onChange={onHandleChange("contactPhone2", "isContactPhone2Valid")}
            value={contactPhone2}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
          <TextField
            id="contactEmail2"
            label="Contact Email 2"
            className={classes.textField}
            margin="none"
            variant="standard"
            onChange={onHandleChange("contactEmail2")}
            value={contactEmail2}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
        </div>
        <Button
          id="addLocationBtn"
          className={classes.button}
          onClick={(evt) => onAddLocation()}
        >
          Add
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(LocationForm);
