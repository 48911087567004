import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import { Typography, TextField, Fab } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/SearchRounded";

import EditVehicleLocation from "../components/EditVehicleLocation";

const styles = (theme) => ({
  root: {
    width: "90%",
    height: "90%",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
  },
  controlWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1rem",
    alignItems: "center",
  },
  star: {
    width: "5%",
    height: "5%",
  },
  inputLabel: {
    fontSize: ".8rem",
    padding: ".4rem",
    textAlign: "center",
    alignItems: "center",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.gray,
    width: "7rem",
    display: "inline-block",
  },
  inputField: {
    fontSize: ".8rem",
    padding: ".6rem",
    display: "inline-block",
    alignItems: "center",
    width: "11rem",
  },
  fab: {
    height: "2rem",
    marginLeft: "1rem",
  },
  fabLabel: {
    textTransform: "none",
  },
  fieldWrapper: {
    display: "flex",
    width: "20rem",
    height: "2rem",
    margin: ".5rem",
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "7rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@global": {
    "label + .MuiInput-formControl": {
      marginTop: 0,
      width: "12rem",
    },
  },
});
export class ManageVehicleLocation extends Component {
  state = {
    data: [],
    vin: "",
    displayEdit: false,
    displayAddLocation: false,
    selectedLocation: null,
  };

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    if (data !== prevProps.data) {
      this.setState({ data });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleEditLocation = (location) => {
    this.setState({ displayEdit: true, selectedLocation: location });
  };

  handleAddLocation = () => {
    this.setState({ displayAddLocation: true });
  };

  cancelEditLocation = () => {
    this.setState({ displayEdit: false, displayAddLocation: false });
  };

  render() {
    const { classes } = this.props;
    const { data, displayEdit, vin, selectedLocation } = this.state;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    const cellStyle = {
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
      minWidth: "7rem",
    };
    return (
      <div id="materialTable" className={classes.root}>
        {displayEdit && (
          <EditVehicleLocation
            location={selectedLocation}
            accountSetting={this.props.accountSetting}
            cancelEditLocation={this.cancelEditLocation}
          />
        )}
        {!displayEdit && (
          <div className={classes.controlWrapper}>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                <strong>VIN</strong>
              </Typography>
              <TextField
                id="vin"
                className={classes.textField}
                value={vin}
                onChange={this.handleChange("vin")}
                variant="filled"
                autoComplete="off"
                name="vin"
                InputProps={InputProps}
              />
            </div>
            <Fab
              id="search-btn"
              variant="extended"
              aria-label="overview"
              className={classes.fab}
              classes={{ label: classes.fabLabel }}
              onClick={(evt) => this.props.getVehicleLocationData(vin)}
            >
              <SearchIcon />
              <Typography>Search By VIN</Typography>
            </Fab>
          </div>
        )}
        {!displayEdit && (
          <MaterialTable
            id="vehicle-table"
            title={<Typography>Vehicle Locations</Typography>}
            columns={[
              {
                title: "Grounding Date",
                cellStyle,
                field: "groundingDate",
                render: (rowData) =>
                  moment(rowData.groundingDate).format("MM/DD/YYYY"),
                defaultSort: "desc",
              },
              {
                title: "VIN",
                field: "vin",
                cellStyle,
              },
              {
                title: "Location",
                field: "locationName",
                cellStyle,
              },
              {
                title: "Special Grounding Instructions",
                field: "pickUpLocationSpecialInstructions",
                cellStyle,
              },
            ]}
            data={data}
            actions={[
              (rowData) => ({
                iconProps: { id: "edit_" + rowData.locationId },
                icon: "edit",
                tooltip: "Edit the Vehicle Location",
                onClick: (event, rowData) => {
                  this.handleEditLocation(rowData);
                },
              }),
            ]}
            options={{
              headerStyle: {
                backgroundColor: "#EEE",
                textAlign: "center",
                padding: ".5rem",
              },
              showTitle: true,
              search: true,
              sorting: true,
              paging: true,
              maxBodyHeight: 500,
              actionsColumnIndex: -1,
            }}
          />
        )}
      </div>
    );
  }
}

ManageVehicleLocation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles)(ManageVehicleLocation)
);
