import moment from "moment";
import { saveAs } from "file-saver";

const API_URL = process.env.REACT_APP_API_URL;

export async function getOdometerStatementPdf(inventoryId) {
  const token = sessionStorage.getItem("token");
  const OPTIONS = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream",
    },
  };
  await fetch(
    `${API_URL}/reports/getOdometerPdf?inventoryId=${inventoryId}`,
    OPTIONS
  )
    .then((resp) => {
      return resp.blob();
    })
    .then((myBlob) => {
      if (myBlob.size > 0) {
        saveAs(myBlob, "OdometerStatement.pdf");
      }
    })
    .catch((err) => {
      return null;
    });
}

export async function getDealerPurchasePerformance(user) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      fromDate: moment().set("date", 1).format("L"),
      toDate: moment().add(1, "days").format("L"),
      storeId: 13,
      dealerId: user.dealerId,
      dealerNumber: user.dealerNumber,
      dealerFactoryRegion: user.dealerFactoryRegion,
      cscRegion: user.cscRegion,
      franchise: user.franchise,
    }),
  };
  const response = await fetch(`${API_URL}/reports/performance`, options);
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
}

export function getDealerPurchasePerformanceReport(user, fromDate, toDate) {
  const token = sessionStorage.getItem("token");
  if (fromDate === null) {
    fromDate = moment().set("date", 1).format("L");
  } else {
    fromDate = moment(fromDate).format("L");
  }

  if (toDate === null) {
    toDate = moment().add(1, "days").format("L");
  } else {
    toDate = moment(toDate).format("L");
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      fromDate,
      toDate,
      dealerId: user.dealerId,
      dealerNumber: user.dealerNumber,
      dealerFactoryRegion: user.dealerFactoryRegion,
      cscRegion: user.cscRegion,
      franchise: user.franchise,
    }),
  };
  const response = fetch(`${API_URL}/reports/performanceReport`, options)
    .then((resp) => {
      return resp.text();
    })
    .then((resp) => {
      return convertHtmlToJson(resp);
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
  return response;
}

export async function getMyPurchaseReport(
  user,
  includeArbitrations,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }
  if (!vin) {
    if (!toDate && !fromDate) {
      toDate = moment().format("YYYY-MM-DD 04:00:00");
      fromDate = moment().subtract(8, "days").format("YYYY-MM-DD 04:00:00");
    } else if (!toDate) {
      toDate = moment(fromDate).add(1, "days").format("YYYY-MM-DD 04:00:00");
      fromDate = moment(fromDate).format("YYYY-MM-DD 04:00:00");
    } else {
      fromDate = moment(fromDate).format("YYYY-MM-DD 04:00:00");
      toDate = moment(toDate).add(1, "days").format("YYYY-MM-DD 04:00:00");
    }
  }
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      customerId: Number(user.dealerId),
      includeArbitrations: includeArbitrations,
      dlrNumber: user.dealerNumber,
      vin: vin,
      year: year,
      model: model,
      make: make,
      purchaseFromDate: !vin ? fromDate : null,
      purchaseToDate: !vin ? toDate : null,
    }),
  };
  try {
    const reportResponse = await fetch(`${API_URL}/reports/purchase`, options);
    if (reportResponse.status === 200) {
      return reportResponse.json();
    } else {
      return [];
    }
  } catch (err) {
    return { message: err, status: false };
  }
}

export async function getMyGroundedReport(
  user,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }

  if (!vin) {
    if (!toDate && !fromDate) {
      toDate = moment().format("MM/DD/YYYY");
      fromDate = moment().subtract(8, "days").format("MM/DD/YYYY");
    } else if (!toDate) {
      toDate = moment(fromDate).add(1, "days").format("MM/DD/YYYY");
      fromDate = moment(fromDate).format("MM/DD/YYYY");
    } else {
      fromDate = moment(fromDate).format("MM/DD/YYYY");
      toDate = moment(toDate).add(1, "days").format("MM/DD/YYYY");
    }
  } else {
    toDate = null;
    fromDate = null;
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      dealerId: Number(user.dealerId),
      dlrNum: user.dealerNumber,
      vin: vin,
      year: year,
      model: model,
      make: make,
      groundingFromDate: fromDate,
      groundingToDate: toDate,
    }),
  };
  try {
    const reportResponse = await fetch(`${API_URL}/reports/grounded`, options);
    if (reportResponse.status === 200) {
      return reportResponse.json();
    } else {
      return [];
    }
  } catch (err) {
    return { message: err, status: false };
  }
}

export async function getMyBidHistoryReport(
  user,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate,
  bidResult
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }
  if (!vin) {
    if (!toDate && !fromDate) {
      toDate = moment().format("YYYY-MM-DD");
      fromDate = moment().subtract(8, "days").format("YYYY-MM-DD");
    } else if (!toDate) {
      toDate = moment(fromDate).add(1, "days").format("YYYY-MM-DD");
      fromDate = moment(fromDate).format("YYYY-MM-DD");
    } else {
      fromDate = moment(fromDate).format("YYYY-MM-DD");
      toDate = moment(toDate).add(1, "days").format("YYYY-MM-DD");
    }
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      customerId: Number(user.dealerId),
      vin: vin,
      year: year === "ALL" ? null : year,
      model: model === "ALL" ? null : model,
      make: make === "ALL" ? null : make,
      searchByVin: vin ? true : false,
      groundingFromDate: fromDate,
      groundingToDate: toDate,
      bidResult: bidResult === "All" ? null : bidResult,
    }),
  };

  try {
    const reportResponse = await fetch(
      `${API_URL}/reports/mybidhistory`,
      options
    );
    if (reportResponse.status === 200) {
      return reportResponse.json();
    } else {
      return [];
    }
  } catch (err) {
    return { message: err, status: false };
  }
}

export async function getMyInspectionReport(
  user,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }

  if (!vin) {
    if (!toDate && !fromDate) {
      toDate = moment().format("MM/DD/YYYY");
      fromDate = moment().subtract(8, "days").format("MM/DD/YYYY");
    } else if (!toDate) {
      toDate = moment(fromDate).add(1, "days").format("MM/DD/YYYY");
      fromDate = moment(fromDate).format("MM/DD/YYYY");
    } else {
      fromDate = moment(fromDate).format("MM/DD/YYYY");
      toDate = moment(toDate).add(1, "days").format("MM/DD/YYYY");
    }
  } else {
    toDate = null;
    fromDate = null;
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      dealerId: Number(user.dealerId),
      vin: vin,
      year: year === "ALL" ? null : year,
      model: model === "ALL" ? null : model,
      make: make === "ALL" ? null : make,
      inspectionFromDate: fromDate,
      inspectionToDate: toDate,
    }),
  };

  try {
    const reportResponse = await fetch(
      `${API_URL}/reports/inspection`,
      options
    );
    if (reportResponse.status === 200) {
      return reportResponse.json();
    } else {
      return [];
    }
  } catch (err) {
    return { message: err, status: false };
  }
}

export async function getMyTransportationReport(
  user,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate,
  tranportationCheckbox,
  rideshareUser
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }

  if (!vin) {
    if (!toDate && !fromDate) {
      toDate = moment().format("MM/DD/YYYY");
      fromDate = moment().subtract(8, "days").format("MM/DD/YYYY");
    } else if (!toDate) {
      toDate = moment(fromDate).add(1, "days").format("MM/DD/YYYY");
      fromDate = moment(fromDate).format("MM/DD/YYYY");
    } else {
      fromDate = moment(fromDate).format("MM/DD/YYYY");
      toDate = moment(toDate).add(1, "days").format("MM/DD/YYYY");
    }
  } else {
    toDate = null;
    fromDate = null;
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      dealerId: Number(user.dealerId),
      vin: vin,
      year: year === "ALL" ? null : year,
      model: model === "ALL" ? null : model,
      make: make === "ALL" ? null : make,
      transportFromDate: fromDate,
      transportToDate: toDate,
      showVehicles: tranportationCheckbox,
      rideshareUser: rideshareUser,
    }),
  };

  try {
    const reportResponse = await fetch(`${API_URL}/reports/transport`, options);
    if (reportResponse.status === 200) {
      return reportResponse.json();
    } else {
      return [];
    }
  } catch (err) {
    return { message: err, status: false };
  }
}

export async function getMyGroundingsPurchasedByOthersReport(
  user,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }

  if (!vin) {
    if (!toDate && !fromDate) {
      toDate = moment().format("MM/DD/YYYY");
      fromDate = moment().subtract(8, "days").format("MM/DD/YYYY");
    } else if (!toDate) {
      toDate = moment(fromDate).add(1, "days").format("MM/DD/YYYY");
      fromDate = moment(fromDate).format("MM/DD/YYYY");
    } else {
      fromDate = moment(fromDate).format("MM/DD/YYYY");
      toDate = moment(toDate).add(1, "days").format("MM/DD/YYYY");
    }
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      dealerId: Number(user.dealerId),
      vin: vin,
      year: year === "ALL" ? null : year,
      model: model === "ALL" ? null : model,
      make: make === "ALL" ? null : make,
      soldFromDate: !vin ? fromDate : null,
      soldToDate: !vin ? toDate : null,
    }),
  };

  try {
    const reportResponse = await fetch(`${API_URL}/reports/upstream`, options);
    if (reportResponse.status === 200) {
      return reportResponse.json();
    } else {
      return [];
    }
  } catch (err) {
    return { message: err, status: false };
  }
}

export async function getMyPurchaseGoalReport(user, fromDate) {
  const token = sessionStorage.getItem("token");
  let monthYearCriteria, toDate;
  if (fromDate) {
    monthYearCriteria = moment(fromDate).format("MM/YY");
    toDate = moment(fromDate).clone().add(1, "month").format("MM/01/YYYY");
    fromDate = moment(fromDate).format("MM/01/YYYY");
  } else {
    monthYearCriteria = moment().format("MM/YY");
    fromDate = moment().format("MM/01/YYYY");
    toDate = moment().add(1, "month").format("MM/01/YYYY");
  }
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      dealerId: Number(user.dealerId),
      purchaseFromDate: fromDate,
      purchaseToDate: toDate,
      monthYearCriteria: monthYearCriteria,
    }),
  };
  try {
    const reportResponse = await fetch(
      `${API_URL}/reports/purchasegoal`,
      options
    );
    return reportResponse.json();
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getMyTransportationReportFile(
  user,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate,
  tranportationCheckbox,
  isPdf
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }

  if (!vin) {
    if (!toDate && !fromDate) {
      toDate = moment().format("MM/DD/YYYY");
      fromDate = moment().subtract(8, "days").format("MM/DD/YYYY");
    } else if (!toDate) {
      toDate = moment(fromDate).add(1, "days").format("MM/DD/YYYY");
      fromDate = moment(fromDate).format("MM/DD/YYYY");
    } else {
      fromDate = moment(fromDate).format("MM/DD/YYYY");
      toDate = moment(toDate).add(1, "days").format("MM/DD/YYYY");
    }
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream",
    },
    body: JSON.stringify({
      dealerId: Number(user.dealerId),
      vin: vin,
      year: year === "ALL" ? null : year,
      model: model === "ALL" ? null : model,
      make: make === "ALL" ? null : make,
      transportFromDate: fromDate,
      transportToDate: toDate,
      showVehicles: tranportationCheckbox,
    }),
  };

  const response = await fetch(
    isPdf
      ? `${API_URL}/reports/transportPdf`
      : `${API_URL}/reports/transportExcel`,
    options
  );
  const blob = await response.blob();
  if (blob.size > 0) {
    const fileName =
      "My_Transport_Report_" +
      moment().format("MMDDYYYY_hhmmss") +
      (isPdf ? ".pdf" : ".xls");
    saveAs(blob, fileName);
    return true;
  } else {
    return false;
  }
}

export async function getMyPurchaseReportFile(
  user,
  includeArbitrations,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate,
  isPdf
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }

  if (vin === null && (fromDate === null || fromDate === "Invalid date")) {
    fromDate = moment().subtract(8, "days").format("MM/DD/YYYY 04:00:00");
  }
  if (vin === null && (toDate === null || toDate === "Invalid date")) {
    toDate = moment().format("MM/DD/YYYY 04:00:00");
  }

  if (year === "ALL") {
    year = null;
  }

  if (make === "ALL") {
    make = null;
  }

  if (model === "ALL") {
    model = null;
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream",
    },
    body: JSON.stringify({
      customerId: Number(user.dealerId),
      includeArbitrations: includeArbitrations,
      dlrNumber: user.dealerNumber,
      vin: vin,
      year: year,
      model: model,
      make: make,
      order: "DESC",
      purchaseFromDate: fromDate,
      purchaseToDate: toDate,
    }),
  };

  const response = await fetch(
    isPdf
      ? `${API_URL}/reports/purchasePdf`
      : `${API_URL}/reports/purchaseExcel`,
    options
  );

  const blob = await response.blob();
  if (blob.size > 0) {
    const fileName =
      "My_Purchased_Report_" +
      moment().format("MMDDYYYY_hhmmss") +
      (isPdf ? ".pdf" : ".xls");
    saveAs(blob, fileName);
    return true;
  } else {
    return false;
  }
}

export async function getMyGroundedReportFile(
  user,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate,
  isPdf
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }

  if (vin === null && fromDate === null) {
    fromDate = moment().subtract(8, "days").format("MM/DD/YYYY");
  }
  if (vin === null && toDate === null) {
    toDate = moment().format("MM/DD/YYYY");
  }

  if (year === "ALL") {
    year = null;
  }

  if (make === "ALL") {
    make = null;
  }

  if (model === "ALL") {
    model = null;
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream",
    },
    body: JSON.stringify({
      dealerId: Number(user.dealerId),
      dlrNum: user.dealerNumber,
      vin: vin,
      year: year,
      model: model,
      make: make,
      groundingFromDate: fromDate,
      groundingToDate: toDate,
    }),
  };

  const response = await fetch(
    isPdf
      ? `${API_URL}/reports/groundedPdf`
      : `${API_URL}/reports/groundedExcel`,
    options
  );
  const blob = await response.blob();
  if (blob.size > 0) {
    const fileName =
      "My_Grounding_Report_" +
      moment().format("MMDDYYYY_hhmmss") +
      (isPdf ? ".pdf" : ".xls");
    saveAs(blob, fileName);
    return true;
  } else {
    return false;
  }
}

export async function getDealerPurchasePerformanceReportFile(
  user,
  fromDate,
  toDate,
  isPdf
) {
  const token = sessionStorage.getItem("token");
  if (fromDate === null) {
    fromDate = moment().set("date", 1).format("L");
  } else {
    fromDate = moment(fromDate).format("L");
  }

  if (toDate === null) {
    toDate = moment().add(1, "days").format("L");
  } else {
    toDate = moment(toDate).add(1, "days").format("L");
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream",
    },
    body: JSON.stringify({
      fromDate: fromDate,
      toDate: toDate,
      storeId: 13,
      dealerId: user.dealerId,
      dealerNumber: user.dealerNumber,
      dealerFactoryRegion: user.dealerFactoryRegion,
      cscRegion: user.cscRegion,
      franchise: user.franchise,
    }),
  };

  const response = await fetch(
    isPdf
      ? `${API_URL}/reports/performanceReportPdf`
      : `${API_URL}/reports/performanceReportExcel`,
    options
  );
  const blob = await response.blob();
  if (blob.size > 0) {
    const fileName =
      "Dealer_Purchase_Performance_Report_" +
      moment().format("MMDDYYYY_hhmmss") +
      (isPdf ? ".pdf" : ".xls");
    saveAs(blob, fileName);
    return true;
  } else {
    return false;
  }
}

export async function getMyBidHistoryReportFile(
  user,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate,
  bidResult,
  isPdf
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }

  if (fromDate !== null) {
    fromDate = moment(fromDate).format("YYYY-MM-DD");
  }

  if (toDate !== null) {
    toDate = moment(toDate).format("YYYY-MM-DD");
  }

  if (vin === null && fromDate === null) {
    fromDate = moment().subtract(9, "days").format("YYYY-MM-DD");
  }
  if (vin === null && toDate === null) {
    toDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream",
    },
    body: JSON.stringify({
      customerId: Number(user.dealerId),
      vin: vin,
      year: year === "ALL" ? null : year,
      model: model === "ALL" ? null : model,
      make: make === "ALL" ? null : make,
      searchByVin: vin ? true : false,
      groundingFromDate: fromDate,
      groundingToDate: toDate,
      bidResult: bidResult === "All" ? null : bidResult,
    }),
  };

  const response = await fetch(
    isPdf
      ? `${API_URL}/reports/mybidhistoryPdf`
      : `${API_URL}/reports/mybidhistoryExcel`,
    options
  );
  const blob = await response.blob();
  if (blob.size > 0) {
    const fileName =
      "My_Bid_History_Report_" +
      moment().format("MMDDYYYY_hhmmss") +
      (isPdf ? ".pdf" : ".xls");
    saveAs(blob, fileName);
    return true;
  } else {
    return false;
  }
}

export async function getMyInspectionReportFile(
  user,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate,
  isPdf
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }

  if (fromDate !== null) {
    fromDate = moment(fromDate).format("MM/DD/YYYY");
  }

  if (toDate !== null) {
    toDate = moment(toDate).format("MM/DD/YYYY");
  }

  if (vin === null && fromDate === null) {
    fromDate = moment().subtract(8, "days").format("MM/DD/YYYY");
  }
  if (vin === null && toDate === null) {
    toDate = moment().format("MM/DD/YYYY");
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream",
    },
    body: JSON.stringify({
      dealerId: Number(user.dealerId),
      VIN: vin,
      year: year === "ALL" ? null : year,
      model: model === "ALL" ? null : model,
      make: make === "ALL" ? null : make,
      inspectionFromDate: fromDate,
      inspectionToDate: toDate,
    }),
  };

  const response = await fetch(
    isPdf
      ? `${API_URL}/reports/inspectionPdf`
      : `${API_URL}/reports/inspectionExcel`,
    options
  );
  const blob = await response.blob();
  if (blob.size > 0) {
    const fileName =
      "My_Inspected_Report_" +
      moment().format("MMDDYYYY_hhmmss") +
      (isPdf ? ".pdf" : ".xls");
    saveAs(blob, fileName);
    return true;
  } else {
    return false;
  }
}

export async function getMyGroundingsPurchasedByOthersReportFile(
  user,
  vin,
  year,
  model,
  make,
  fromDate,
  toDate,
  isPdf
) {
  const token = sessionStorage.getItem("token");
  if (vin === "") {
    vin = null;
  }

  if (fromDate !== null) {
    fromDate = moment(fromDate).format("MM/DD/YYYY");
  }

  if (toDate !== null) {
    toDate = moment(toDate).format("MM/DD/YYYY");
  }

  if (vin === null && fromDate === null) {
    fromDate = moment().subtract(8, "days").format("MM/DD/YYYY");
  }
  if (vin === null && toDate === null) {
    toDate = moment().add(1, "days").format("MM/DD/YYYY");
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream",
    },
    body: JSON.stringify({
      dealerId: Number(user.dealerId),
      vin: vin,
      year: year === "ALL" ? null : year,
      model: model === "ALL" ? null : model,
      make: make === "ALL" ? null : make,
      soldFromDate: fromDate,
      soldToDate: toDate,
      dlrNum: user.dealerNumber,
    }),
  };

  const response = await fetch(
    isPdf
      ? `${API_URL}/reports/upstreamPdf`
      : `${API_URL}/reports/upstreamExcel`,
    options
  );
  const blob = await response.blob();
  if (blob.size > 0) {
    const fileName =
      "My_Purchased_by_Other_Dealers_Report_" +
      moment().format("MMDDYYYY") +
      (isPdf ? ".pdf" : ".xls");
    saveAs(blob, fileName);
    return true;
  } else {
    return false;
  }
}

export async function getMyPurchaseGoalReportFile(user, fromDate, isPdf) {
  const token = sessionStorage.getItem("token");
  let monthYearCriteria, toDate;
  if (fromDate) {
    monthYearCriteria = moment(fromDate).format("MM/YY");
    toDate = moment(fromDate).clone().add(1, "month").format("MM/01/YYYY");
    fromDate = moment(fromDate).format("MM/01/YYYY");
  } else {
    monthYearCriteria = moment().format("MM/YY");
    fromDate = moment().format("MM/01/YYYY");
    toDate = moment().add(1, "month").format("MM/01/YYYY");
  }
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream",
    },
    body: JSON.stringify({
      dealerId: Number(user.dealerId),
      purchaseFromDate: fromDate,
      purchaseToDate: toDate,
      monthYearCriteria: monthYearCriteria,
    }),
  };

  const response = await fetch(
    isPdf
      ? `${API_URL}/reports/purchasegoalPdf`
      : `${API_URL}/reports/purchasegoalExcel`,
    options
  );
  const blob = await response.blob();
  if (blob.size > 0) {
    const fileName =
      "My_Purchased_Goal_Report_" +
      moment().format("MMDDYYYY") +
      (isPdf ? ".pdf" : ".xls");
    saveAs(blob, fileName);
    return true;
  } else {
    return false;
  }
}

function convertHtmlToJson(html) {
  let data = [];
  let parser = new DOMParser();
  let doc = parser.parseFromString(
    "<table>" + html.toString().replace(/\n/g, "") + "</table>",
    "text/html"
  );

  let rows = doc.querySelectorAll("tr");

  rows.forEach((row) => {
    let rowObj = {
      makeModel: row.childNodes[0].textContent,
      grounded: row.childNodes[1].textContent,
      purchased: row.childNodes[2].textContent,
      myPercent: row.childNodes[3].textContent,
      regPercent: row.childNodes[4].textContent,
      natPercent: row.childNodes[5].textContent,
    };
    data.push(rowObj);
  });

  return data;
}
