const API_URL = process.env.REACT_APP_API_URL;

export async function advancedSearchCriteria() {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${API_URL}/mobilecache/advancedsearchcriteria`,
    options
  );
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
}

export async function advancedSearchCriteriaWrapper() {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${API_URL}/mobilecache/advancedsearchcriteriawrapper`,
    options
  );
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
}

export async function advanceSearch(data, user) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      criteriaPopulatedFrom: "Search Functions",
      quickSearchText: null,
      timeZone: user.timeZone,
      datePattern: user.datePattern,
      loggedInUserLongitude: user.longitude,
      loggedInUserLatitude: user.latitude,
      dealerId: user.dealerId,
      dealership: user.franchise ? user.franchise : "TBSG",
      notificationFrequency: "DaysOfTheWeek",
      selectedDaysOfTheWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Saturday",
        "Sunday",
      ],
      userId: user.userId,
      userStateId: user.userStateId,
      make: data.make,
      model: data.model,
      modelNumber: data.modelNumber,
      bodyStyle: data.bodyStyle,
      vehicleGrade: data.vehicleGrade,
      exteriorColor: data.exteriorColor,
      interiorColor: data.interiorColor,
      doors: data.doors,
      driveTrain: data.driveTrain,
      region: data.region,
      series: data.series,
      fromYear: data.fromYear,
      toYear: data.toYear,
      state: data.state,
      odometerRange: data.odometerRange,
      priceRange: data.priceRange,
      distanceFromUser: data.distanceFromUser,
      option: data.options,
      transmission: data.transmission,
      engine: data.engine,
    }),
  };
  const response = await fetch(`${API_URL}/search/advancedSearch`, options);
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
}

export async function getSavedSearch(user) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${API_URL}/savedSearch/loadSaveSearch?dealerId=${user.dealerId}&dealership=${user.franchise}&userId=${user.userId}`,
    options
  );
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
}

export async function deleteSavedSearch(selectedSearch, user) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      searchIdList: selectedSearch,
      dealerId: user.dealerId,
      dealership: user.franchise ? user.franchise : "TBSG",
      userId: user.userId,
    }),
  };
  const response = await fetch(`${API_URL}/savedSearch/deleteSearch`, options);
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
}

export async function saveUpdateSearch(
  data,
  user,
  frequency,
  frequencyDay,
  name
) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      criteriaPopulatedFrom: "Search Functions",
      quickSearchText: null,
      timeZone: user.timeZone,
      datePattern: user.datePattern,
      loggedInUserLongitude: user.longitude,
      loggedInUserLatitude: user.latitude,
      dealerId: user.dealerId,
      dealership: user.franchise ? user.franchise : "TBSG",
      notificationFrequency: frequency,
      selectedDaysOfTheWeek: frequencyDay,
      userId: user.userId,
      userStateId: user.userStateId,
      make: data.make,
      model: data.model,
      modelNumber: data.modelNumber,
      bodyStyle: data.bodyStyle,
      vehicleGrade: data.vehicleGrade,
      exteriorColor: data.exteriorColor,
      interiorColor: data.interiorColor,
      doors: data.doors,
      driveTrain: data.driveTrain,
      region: data.region,
      series: data.series,
      fromYear: data.fromYear,
      toYear: data.toYear,
      state: data.state,
      odometerRange: data.odometerRange,
      priceRange: data.priceRange,
      distanceFromUser: data.distanceFromUser,
      savedSearchName: name,
      isIncludeNoPriceVehicles: true,
      option: data.options,
      transmission: data.transmission,
      engine: data.engine,
    }),
  };
  const response = await fetch(
    `${API_URL}/savedSearch/saveOrUpdateSearch`,
    options
  );
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
}

export async function showVehicleSearch(user, name) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      dealerId: user.dealerId,
      dealership: user.franchise ? user.franchise : "TBSG",
      userId: user.userId,
      searchName: name,
      startIndex: null,
      maxResults: null,
      timeZone: user.timeZone,
      datePattern: user.datePattern,
    }),
  };
  const response = await fetch(
    `${API_URL}/savedSearch/showVehicleSearchNowWithLessOptionCodes`,
    options
  );
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
}
