import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Paper, TextField, Button, Typography } from "@material-ui/core";
import * as ProgramServices from "../services/ProgramServices";
import renderHTML from "react-render-html";

const styles = (theme) => ({
  label: {
    margin: "1rem",
    marginRight: "0",
    fontWeight: "bold",
  },
  labelText: {
    fontWeight: "bold",
  },
  vehicleInfoContainer: {
    width: "auto",
    display: "block",
    margin: ".5rem",
    textAlign: "center",
  },
  vehicleInfoVINEntryContainer: {
    margin: ".5rem",
    display: "inline-block",
    width: "auto",
    marginTop: ".5rem",
  },
  infoParentWrapper: {
    margin: "1rem",
  },
  infoWrapper: {
    margin: "1rem",
  },
  h1Padding: {
    margin: "1rem",
  },
  paper: {
    alignItems: "center",
    width: "98%",
    borderRadius: 0,
    fontSize: ".8rem",
    marginLeft: ".5rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  paperBuy: {
    alignItems: "center",
    width: "98%",
    borderRadius: 0,
    fontSize: ".8rem",
    marginLeft: ".5rem",
    marginTop: "4rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  formControl: {
    minWidth: 200,
  },
  tableCellBorder: {
    borderBottom: 0,
  },
  messageContainer: {
    alignItems: "center",
    width: "80%",
    margin: "auto",
  },
  paperWrapper: {
    alignItems: "center",
    margin: "auto",
  },
  programInfoHighlight: {
    display: "inline",
    color: "#CC0000",
  },
  textField: {
    marginLeft: ".5rem",
    marginRight: ".5rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      fontSize: ".5rem",
    },
  },
  textfieldWrapper: {
    margin: "auto",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  buttonWrapper: {
    textAlign: "right",
  },
  button: {
    margin: ".5rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100px",
  },
  gpWrapper: {
    margin: "2rem",
  },
  cardTitle: {
    color: theme.palette.primary.white,
    fontWeight: "bold",
    backgroundColor: theme.palette.grounding.main,
    textAlign: "center",
    borderRadius: "0 0 .75rem .75rem",
  },
  maxCharLimit: {
    display: "inline",
    color: "#CC0000",
  },
});

export class LPWEntryForm extends Component {
  state = {
    enteredCertNumberValid: false,
    showInvalidCertError: false,
    programCertNum: "",
    programCertComments: "",
    programDetails: {},
    outStandingPayments: 0,
    waivedPayments: 0,
    guaranteeAdditionalRemainingPayments: false,
    hideSaveButton: false,
    showYesNoDropdown: false,
    badGuaranteePayment: false,
  };

  componentWillMount = () => {
    this.myRef = createRef();
    this.getProgramDetails();
  };

  componentDidMount = () => {
    this.myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  getProgramDetails = async () => {
    const { car, programDTOInfo, from } = this.props;
    const programDetails = await ProgramServices.loadProgram(
      car.id,
      programDTOInfo.programId
    );
    if (from !== undefined && from === "Buy") {
      this.setState({
        programDetails,
        programCertNum:
          programDetails.userCertificateNumber &&
          programDetails.userCertificateNumber !== null
            ? programDetails.userCertificateNumber
            : "",
        programCertComments:
          programDetails.userCertificateNumber &&
          programDetails.userCertificateNumber !== null
            ? programDetails.comments
              ? programDetails.comments
              : ""
            : "",
        hideSaveButton:
          programDetails.userCertificateNumber &&
          programDetails.userCertificateNumber !== null
            ? true
            : false,
      });
    } else {
      this.setState({
        programDetails,
        programCertNum:
          programDetails.userCertificateNumber &&
          programDetails.userCertificateNumber !== null
            ? programDetails.userCertificateNumber
            : "",
        programCertComments:
          programDetails.userCertificateNumber &&
          programDetails.userCertificateNumber !== null
            ? programDetails.comments
              ? programDetails.comments
              : ""
            : "",
        hideSaveButton:
          programDetails.userCertificateNumber &&
          programDetails.userCertificateNumber !== null
            ? true
            : false,
      });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      noToGuarantee: false,
    });
  };

  removeDiv = (text) => {
    let newText = text.replace("<div>", "");
    return newText.replace("</div>", "");
  };

  removeSpan = (text) => {
    let newText = text.replace('<span style="font-size: 11.2px;">', "");
    return newText.replace("</span>", "");
  };

  onVerifyCertNumber = async () => {
    const { toggleLPWCarDone } = this.props;
    const { programCertNum, programDetails } = this.state;
    if (
      programCertNum !== "" &&
      programCertNum === programDetails.certificateNumber
    ) {
      if (programDetails.isDealerGuaranteed) {
        this.setState({
          showYesNoDropdown: true,
        });
      } else {
        await this.validateAndSaveProgram();
        toggleLPWCarDone();
      }
    } else {
      this.setState({
        showInvalidCertError: true,
      });
    }
  };

  validateAndSaveProgram = async () => {
    const { car, user, programDTOInfo } = this.props;
    const { programCertNum, programCertComments } = this.state;
    const validationDetails = await ProgramServices.validateAndSaveProgram(
      car.id,
      programDTOInfo.programId,
      "FROM_GAMD",
      user.userId,
      programCertNum,
      user.dealerId,
      programCertComments
    );
    if (validationDetails.message === "Invalid Certificate Number") {
      this.setState({
        showInvalidCertError: true,
      });
    } else {
      this.setState({
        outStandingPayments: validationDetails.outStandingPayments,
        waivedPayments: validationDetails.waivedPayments,
        guaranteeAdditionalRemainingPayments:
          validationDetails.guaranteeAdditionalRemainingPayments,
      });
    }

    return validationDetails;
  };

  onFocusClear = () => {
    this.setState({
      showInvalidCertError: false,
    });
  };

  getGuaranteePaymentsData = (carInfo) => {
    const car = {
      id: carInfo.inventoryDTO.id,
      year: carInfo.inventoryDTO.vehicleYear,
      make: carInfo.inventoryDTO.make,
      model: carInfo.inventoryDTO.model,
      vehicleIdentificationNumber:
        carInfo.inventoryDTO.vehicleIdentificationNumber,
      remainingPaymentsAmt: this.state.outStandingPayments,
    };
    return car;
  };

  getGuaranteePaymentsDataBuy = (carInfo) => {
    const car = {
      id: carInfo.id,
      year: carInfo.year,
      make: carInfo.make,
      model: carInfo.model,
      vehicleIdentificationNumber: carInfo.vehicleIdentificationNumber,
      remainingPaymentsAmt: this.state.outStandingPayments,
    };
    return car;
  };

  handleGuarantee = async (validationDetails) => {
    const { car, toggleLPWCarDone } = this.props;
    const guaranteePayment = await ProgramServices.guarnteePaymentDone(
      car.id,
      Number(validationDetails.waivedPayments).toFixed(2),
      Number(validationDetails.outStandingPayments).toFixed(2)
    );
    toggleLPWCarDone();
  };

  handleAgreedToGuaranteedPayment = async (agreed) => {
    const { toggleLPWCarDone } = this.props;
    if (agreed) {
      let validationDetails = await this.validateAndSaveProgram();
      if (
        validationDetails.waivedPayments !== undefined &&
        validationDetails.outStandingPayments !== undefined &&
        validationDetails.guaranteeAdditionalRemainingPayments
      ) {
        await this.handleGuarantee(validationDetails);
      } else {
        toggleLPWCarDone();
      }
    } else {
      this.setState({
        showYesNoDropdown: false,
        programCertNum: "",
        programCertComments: "",
        noToGuarantee: true,
      });
    }
  };

  handleAgreedToGuaranteedPaymentCancel = () => {
    this.setState({
      showYesNoDropdown: false,
      programCertNum: "",
      programCertComments: "",
    });
  };

  render() {
    const { classes, programDTOInfo, badCertValid, toggleLPWCar, from } =
      this.props;
    const {
      showInvalidCertError,
      programCertComments,
      programCertNum,
      hideSaveButton,
      showYesNoDropdown,
      noToGuarantee,
    } = this.state;
    return (
      <Paper
        ref={this.myRef}
        className={
          from !== undefined && from === "Buy"
            ? classes.paperBuy
            : classes.paper
        }
      >
        {showYesNoDropdown ? (
          <div className={classes.gpWrapper}>
            <h2> Guarantee Payments </h2>
            <div> PAYMENT WAIVER ACCEPTANCE </div>
            <div>
              {" "}
              This Payment Waiver Program may require you to guarantee remaining
              payments.{" "}
            </div>
            <div> Do you want to continue? </div>
            <div className={classes.buttonWrapper}>
              <Button
                id="yes"
                className={classes.button}
                onClick={(evt) => this.handleAgreedToGuaranteedPayment(true)}
              >
                Yes
              </Button>
              <Button
                id="no"
                className={classes.button}
                onClick={(evt) => this.handleAgreedToGuaranteedPayment(false)}
              >
                No
              </Button>
              <Button
                id="no"
                className={classes.button}
                onClick={(evt) => this.handleAgreedToGuaranteedPaymentCancel()}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Typography className={classes.cardTitle}>
              {programDTOInfo.programName}
            </Typography>
            <h2 className={classes.h1Padding}>Attention:</h2>
            <div className={classes.infoWrapper}>
              <h3>
                THIS VEHICLE IS AVAILABLE FOR THE{" "}
                <div className={classes.programInfoHighlight}>
                  {programDTOInfo.programName}
                </div>
              </h3>
              <div>
                {renderHTML(programDTOInfo.contentManagementDTO.content)}
              </div>
            </div>
            <div className={classes.textfieldWrapper}>
              <TextField
                inputProps={{ maxlength: 250 }}
                id="programCertComments"
                label="Comments"
                placeholder="Enter Text..."
                multiline
                rows="3"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange("programCertComments")}
                value={programCertComments}
              />
              <div className={classes.maxCharLimit}>
                &nbsp; *Maximum of 250 characters allowed
              </div>
            </div>
            <div className={classes.textfieldWrapper}>
              <TextField
                id="programCertNum"
                label={
                  showInvalidCertError
                    ? "Invalid Program Certificate #"
                    : "Program Certificate #"
                }
                placeholder="Enter Text..."
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange("programCertNum")}
                value={programCertNum}
                error={showInvalidCertError}
                onFocus={this.onFocusClear}
              />
            </div>
            <div className={classes.textfieldWrapper}>
              <div className={classes.programInfoHighlight}>
                {badCertValid
                  ? "Please verify the certificate number you entered."
                  : null}
                {noToGuarantee
                  ? "Please guarantee the the payments if you wish to use Payment Waiver Program."
                  : null}
              </div>
            </div>
            <div className={classes.textfieldWrapper}>
              <div className={classes.buttonWrapper}>
                {hideSaveButton ? null : (
                  <Button
                    id="addLocationBtn"
                    className={classes.button}
                    onClick={(evt) => this.onVerifyCertNumber()}
                  >
                    Save
                  </Button>
                )}
                <Button
                  id="addLocationBtn"
                  className={classes.button}
                  onClick={(evt) => toggleLPWCar()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </Paper>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(LPWEntryForm));
