import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Card, Typography, CardHeader, CardContent } from "@material-ui/core";

import AssessmentIcon from "@material-ui/icons/Assessment";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import {
  DEALER_PURCHASE_PERFORMANCE,
  DEALER_PURCHASE,
  NATIONAL_PURCHASE,
  REGIONAL_PURCHASE,
} from "../constants/UILabel";

const styles = (theme) => ({
  icon: {
    margin: ".5rem",
    fontSize: "2rem",
    fill: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginLeft: "1.5rem",
      fontSize: "1.3rem",
      marginTop: ".5rem",
    },
  },
  card: {
    maxWidth: "46rem",
    margin: "1.5%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    height: "13rem",
    boxShadow: "0 1px 3px rgba(0,0,0,0.03), 0 1px 2px rgba(0,0,0,0.09)",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      marginTop: "1rem",
      marginRight: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  cardHeader: {
    margin: ".5rem",
    marginBottom: ".55rem",
    padding: 0,
    height: "9%",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.light.gray,
      borderRadius: "2rem 2rem 0 0",
      fontSize: theme.font.small,
      margin: 0,
      height: "12%",
    },
  },
  cardHeaderTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.font.small,
    },
  },
  cardHeaderAvatar: {
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  cardContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    height: "10rem",
    padding: 0,
    borderTop: `3px solid ${theme.palette.secondary.gray}`,
  },
  circularProgress: {
    margin: ".5rem",
    height: "8rem",
  },
  circularProgressBar: {
    width: "90%",
    height: "90%",
  },
  label: {
    marginTop: 5,
    marginBottom: 10,
    fontSize: ".75rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
});

function Label(props) {
  const { classes } = props;
  return (
    <Typography className={classes.label} gutterBottom>
      {props.children}
    </Typography>
  );
}

export class DealerPurchasePerformance extends Component {
  render() {
    const { classes } = this.props;

    const dealerPenetrationStr =
      Number.parseFloat(
        this.props.dealerPurchasePerformance.dealerPenetrationStr
      ) || 0;
    const regionalPenetrationStr =
      Number.parseFloat(
        this.props.dealerPurchasePerformance.regionalPenetrationStr
      ) || 0;
    const nationalPenetrationStr =
      Number.parseFloat(
        this.props.dealerPurchasePerformance.nationalPenetrationStr
      ) || 0;

    return (
      <Card className={classes.card} square>
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.cardHeaderTitle,
            avatar: classes.cardHeaderAvatar,
          }}
          avatar={<AssessmentIcon className={classes.icon} />}
          title={DEALER_PURCHASE_PERFORMANCE}
        />
        <CardContent className={classes.cardContent}>
          <div className={classes.circularProgress}>
            <Label classes={classes}>{DEALER_PURCHASE}</Label>
            <CircularProgressbar
              value={dealerPenetrationStr}
              text={`${dealerPenetrationStr}%`}
              className={classes.circularProgressBar}
            />
          </div>
          <div className={classes.circularProgress}>
            <Label classes={classes}>{REGIONAL_PURCHASE}</Label>
            <CircularProgressbar
              value={regionalPenetrationStr}
              text={`${regionalPenetrationStr}%`}
              className={classes.circularProgressBar}
            />
          </div>
          <div className={classes.circularProgress}>
            <Label classes={classes}>{NATIONAL_PURCHASE}</Label>
            <CircularProgressbar
              value={nationalPenetrationStr}
              text={`${nationalPenetrationStr}%`}
              className={classes.circularProgressBar}
            />
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(DealerPurchasePerformance);
