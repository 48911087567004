import React, { Component } from "react";
import { connect } from "react-redux";
import * as MobilityServices from "../services/MobilityServices";
import MobilityCarListTile from "../components/MobilityCarListTile";
import PropTypes from "prop-types";
import ConditionReport from "../components/ConditionReport";

import * as ConditionReportService from "../services/ConditionReportService";
import * as userAccess from "../services/UserRoles";
import Loading from "../components/Loading";

import { add_ddo_condition_report } from "../actions/carActions";
import { set_nav_location } from "../actions/uiActions";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  color: theme.palette.GAMD.main,
  container: {
    display: "flex",
    height: "79%",
    [theme.breakpoints.down("sm")]: {
      height: "90%",
    },
    backgroundColor: theme.palette.background.dark,
  },
  header: {
    display: "flex",
    width: "97%",
    marginTop: ".5rem",
    height: "7%",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginTop: "3.5rem",
      width: "100%",
      height: "2.5rem",
    },
  },
  leftHeaderContent: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  titleWrapper: {
    paddingLeft: "3rem",
    display: "flex",
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  desktopButtonWrapper: {
    marginLeft: "3rem",
    width: "31rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileButtonWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "2.5rem",
    position: "fixed",
    zIndex: 3,
    top: "3.5rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileButton: {
    height: "100%",
    width: "33.333%",
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
  },
  mobileButtonText: {
    fontSize: theme.font.small,
  },
  selectedMobileButton: {
    height: "100%",
    width: "33.333%",
    backgroundColor: theme.palette.secondary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.gray,
    },
  },
  buttonText: {
    marginRight: ".5rem",
  },
  noButtonUnderline: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "5px solid transparent",
  },
  buttonUnderline: {
    borderBottom: `5px solid ${theme.palette.GAMD.main}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    minHeight: "100%",
  },
  highlightedButtonText: {
    color: theme.palette.GAMD.main,
    marginRight: ".5rem",
  },
  GAMDIcon: {
    width: "4rem",
  },
  titleTextWrapper: {
    borderBottom: `5px solid`,
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  titleText: {
    color: theme.palette.GAMD.main,
    fontSize: ".8rem",
  },
  boldedTitleText: {
    fontSize: ".8rem",
    fontWeight: "bold",
  },
  rightSelectionButtonWrapper: {
    display: "flex",
    width: "6rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  selectionButton: {
    backgroundColor: theme.palette.GAMD.main,
    width: "2.5rem",
    height: "1.78rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  navButton: {
    height: "1.5rem",
    lineHeight: 0,
    padding: 0,
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
  },
  leftWrapper: {
    width: "75%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.dark,
    margin: ".5rem",
    padding: ".2rem",
    height: "95%",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      marginTop: ".5rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      height: "100vh",
      marginTop: 0,
      paddingBottom: "3rem",
    },
  },
  rightWrapper: {
    width: "25%",
    height: "95%",
    margin: ".5rem",
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  loader: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  scrollContainer: {
    height: "100%",
    overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileScrollContainer: {
    height: "100%",
    overflow: "auto",
    position: "absolute",
    width: "100%",
    marginBottom: "3rem",
    top: "3rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  errorMessage: {},
  errorSnackBar: {
    marginTop: "7rem",
  },
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    width: "100%",
    backgroundColor: theme.palette.background.dark,
  },
  noSearchResultText: {
    marginTop: "1rem",
  },
});

export class MobilityVehicleCard extends Component {
  state = {
    savedSearchResult: [],
    selectedCar: {},
    viewerVisible: false,
    conditionReport: null,
    selectedVehicleDetails: {},
    filterActive: false,
    vehicleInformationLoading: false,
    fetchConditionReportFinished: false,
    fullConditionReport: false,
    pageLoading: false,
  };

  setTileRef = (car, ref) => {
    //this.refs[car.vin ? car.vin : car.vehicleIdentificationNumber] = ref;
  };

  selectCar = async (car) => {
    this.setState({ vehicleInformationLoading: true });
    const { user, dispatch, DDOConditionReports } = this.props;
    if (!DDOConditionReports[car.vin]) {
      const id = car.id ? car.id : car.inventoryId;
      const newConditionReport =
        await ConditionReportService.getSingleConditionReport(
          id,
          user.userId,
          user.dealerId
        );
      dispatch(add_ddo_condition_report(newConditionReport));
      this.setState({
        selectedCar: car,
        conditionReport: newConditionReport,
        fetchConditionReportFinished: true,
        filterActive: false,
        vehicleInformationLoading: false,
      });
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: DDOConditionReports[car.vin],
        fetchConditionReportFinished: true,
        filterActive: false,
        vehicleInformationLoading: false,
      });
    }
  };

  toggleFullConditionReport = () => {
    this.setState({
      fullConditionReport: !this.state.fullConditionReport,
    });
  };

  componentDidMount = () => {
    const { user, dispatch, navLocation, history } = this.props;
    if (!userAccess.isRideshareUser(user.userRoles)) {
      history.replace("/home");
    } else {
      if (navLocation !== "mobilityVehicleCard") {
        dispatch(set_nav_location("mobilityVehicleCard"));
      }
    }
    this.savedSearchResultBySearchName();
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: !this.state.viewerVisible,
    });
  };
  savedSearchResultBySearchName = async () => {
    this.setState({ pageLoading: true });
    const { history, DDOConditionReports } = this.props;
    this.setState({
      userRoleHasMobilityRideshareView:
        history.location.state.userRoleHasMobilityRideshareView,
    });
    let searchName = history.location.state.searchName
      ? history.location.state.searchName
      : null;
    let savedSearchResultBySearchName = [];
    if (searchName !== null) {
      savedSearchResultBySearchName =
        await MobilityServices.loadSavedSearchResultBySearchName(searchName);
    }
    if (
      savedSearchResultBySearchName &&
      savedSearchResultBySearchName.length > 0
    ) {
      this.setState({
        savedSearchResult: savedSearchResultBySearchName,
        ConditionReport:
          DDOConditionReports[savedSearchResultBySearchName[0].vin],
        pageLoading: false,
      });
    } else {
      this.setState({
        pageLoading: false,
      });
    }
  };
  render() {
    const {
      savedSearchResult,
      selectedCar,
      fullConditionReport,
      vehicleInformationLoading,
      conditionReport,
      pageLoading,
    } = this.state;
    const { classes, history, user, DDOConditionReports } = this.props;
    return (
      <React.Fragment>
        <div className={classes.header}>
          <div className={classes.titleWrapper}>
            <div className={classes.titleTextWrapper}>
              <Typography className={classes.boldedTitleText}>Kinto</Typography>
              <Typography className={classes.boldedTitleText}>
                Reservations
              </Typography>
            </div>
          </div>
        </div>

        {!fullConditionReport && (
          <div className={classes.scrollContainer}>
            <div className={classes.root}>
              <div className={classes.gridList} children>
                {savedSearchResult && savedSearchResult.length > 0
                  ? savedSearchResult.map((car) => (
                      <MobilityCarListTile
                        key={car.vin}
                        car={car}
                        selectCar={this.selectCar}
                        user={user}
                        userRoleHasMobilityRideshareView={
                          this.state.userRoleHasMobilityRideshareView
                        }
                        selectedCar={selectedCar}
                        conditionReport={DDOConditionReports[car.vin]}
                        toggleImageViewer={this.toggleImageViewer}
                        history={history}
                        toggleFullConditionReport={
                          this.toggleFullConditionReport
                        }
                        isCRAvailable={car.imagePathFc}
                        setTileRef={this.setTileRef}
                      />
                    ))
                  : null}

                {pageLoading ? (
                  <Loading />
                ) : (
                  <React.Fragment>
                    {savedSearchResult.length === 0 && (
                      <div className={classes.noSearchResultText}>
                        {" "}
                        Currently there are no vehicles for the Name selected.{" "}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        )}
        {fullConditionReport && conditionReport && (
          <ConditionReport
            car={selectedCar}
            user={user}
            conditionReport={conditionReport}
            toggleFullConditionReport={this.toggleFullConditionReport}
            upstreamCars={savedSearchResult}
            selectCar={this.selectCar}
            page="DDO"
            handleBidOpen={this.handleBidOpen}
            handleBuyNow={this.handleBuyNow}
            history={history}
            updateAutoBid={this.updateAutoBid}
            fromMobility={"mobilityVehicleCard"}
            vehicleInformationLoading={vehicleInformationLoading}
            updateWatchlist={this.updateWatchlist}
          />
        )}
      </React.Fragment>
    );
  }
}

MobilityVehicleCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    DDOConditionReports: state.cars.DDOConditionReports,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles)(MobilityVehicleCard)
);
