import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import DateFnsUtils from "@date-io/date-fns";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormControl,
  Link,
} from "@material-ui/core";

import Moment from "react-moment";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const saleEventItems = [
  {
    id: "SELECT",
    text: "Select",
  },
  {
    id: "DEALER_EXCLUSIVITY",
    text: "Dealer Exclusivity",
  },
  {
    id: "UPSTREAM_SALE_1",
    text: "Upstream Sale 1",
  },
  {
    id: "UPSTREAM_SALE_2",
    text: "Upstream Sale 2",
  },
  {
    id: "PSE",
    text: "PSE",
  },
];

const frequencyItems = [
  {
    id: "SELECT",
    text: "Select",
  },
  {
    id: "EVERY_DAY",
    text: "Every Day",
  },
  {
    id: "WEEK_DAYS",
    text: "Week Days",
  },
  {
    id: "DaysOfTheWeek",
    text: "Day(s) of the Week",
  },
];

const daysOfTheWeekLeft = [
  {
    id: "MONDAY",
    text: "Monday",
  },
  {
    id: "TUESDAY",
    text: "Tuesday",
  },
  {
    id: "WEDNESDAY",
    text: "Wednesday",
  },
  {
    id: "THURSDAY",
    text: "Thursday",
  },
];

const daysOfTheWeekRight = [
  {
    id: "FRIDAY",
    text: "Friday",
  },
  {
    id: "SATURDAY",
    text: "Saturday",
  },
  {
    id: "SUNDAY",
    text: "Sunday",
  },
];

const programTypeOptions = [
  {
    id: "SELECT",
    text: "Select",
  },
  {
    id: "ALM",
    text: "ALM",
  },
  {
    id: "RIDESHARE",
    text: "Rideshare",
  },
];

const styles = (theme) => ({
  saveSearchWrapper: {
    marginLeft: "0.6rem",
    color: theme.palette.primary.gray,
  },
  select: {
    minWidth: "12rem",
  },
  displayFrequency: {
    textAlign: "left",
    marginLeft: "0.6rem",
  },
  programType: {
    display: "flex",
    width: "30rem",
    margin: ".5rem",
    marginBottom: "0rem",
  },
  viewDaysSelection: {
    display: "flex",
    marginLeft: "10rem",
  },
  viewDaysSelectionLabel: {
    marginLeft: "10rem",
    marginTop: "0.5rem",
    fontSize: ".75rem",
  },
  viewDaysSelectionLabelError: {
    marginLeft: "10rem",
    marginTop: "0.5rem",
    fontSize: ".75rem",
    color: "#EB0A1E",
  },
  viewDaysSelectionDays: {
    display: "flex",
    margin: "0.4rem",
    flexDirection: "column",
  },
  textField: {
    margin: 0,
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    borderRadius: 0,
  },
  fieldWrapper: {
    display: "flex",
    width: "30rem",
    margin: ".5rem",
    marginBottom: "0rem",
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    width: "6rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fieldLabelProgramType: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    width: "10rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fieldLabelEmail: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "14rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectInput: {
    fontSize: ".8rem",
    minWidth: "10rem",
    textAlign: "center",
  },
  formControl: {
    display: "flex",
    height: "2rem",
    marginTop: ".5rem",
    flexDirection: "row",
  },
  heading: {
    margin: "1rem",
    fontSize: "1.2rem",
    color: theme.palette.primary.gray,
  },
  heading2: {
    margin: "0.6rem",
    color: theme.palette.primary.gray,
  },
  checkboxRoot: {
    "&$checked": {
      color: theme.palette.primary.gray,
      "&:hover": {
        backgroundColor: fade(
          theme.palette.primary.gray,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "&:hover": {
      backgroundColor: fade(
        theme.palette.primary.gray,
        theme.palette.action.hoverOpacity
      ),
    },
    padding: ".25rem",
    marginLeft: ".5rem",
  },
  errorSnackBar: {
    marginTop: "7rem",
  },
  autoSearchSaveWrapper: {},
  searchParam: {
    marginRight: ".5rem",
    minWidth: "5rem",
  },
  datePickerWidth: {
    width: "14rem",
  },
  todayLink: {
    textDecoration: "underline",
    textAlign: "end",
    fontSize: ".6rem",
    color: "#58595B",
  },
  limitWrapper: {
    textAlign: "end",
    fontSize: ".6rem",
    color: "#58595B",
    width: "20.5rem",
  },
  errorMessage: {
    fontSize: ".75rem",
    color: "#EB0A1E",
    width: "30rem",
    marginBottom: ".5rem",
    marginLeft: ".5rem",
  },
  "@global": {
    ".MuiFormControl-marginNormal": {
      marginTop: "0rem",
      marginBottom: "0rem",
    },
    ".DayPicker": {
      width: "17rem",
    },
    ".DayPicker-Month": {
      marginTop: 0,
    },
    ".DayPicker-NavButton": {
      top: 0,
    },
    ".Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)": {
      backgroundColor: `#f0f8ff !important`,
      color: "#4a90e2",
    },
    ".Range .DayPicker-Day": {
      borderRadius: "50% !important",
      padding: ".25rem",
      display: "flex",
      width: "1rem",
      alignItems: "center",
      justifyContent: "center",
    },
    ".DayPicker-Week": {
      display: "flex",
      height: "1.5rem",
      justifyContent: "space-between",
      fontSize: theme.font.small,
    },
    ".DayPicker-Weekdays": {
      display: "flex",
      marginTop: 0,
    },
    ".DayPicker-Weekday": {
      fontWeight: "bold",
      padding: ".25rem",
    },
    ".DayPicker-Footer": {
      padding: "0 43%",
    },
    ".DayPicker-TodayButton": {
      fontSize: theme.font.small,
    },
  },
});

class MobilitySearchForm extends Component {
  renderDatePicker = () => {
    const {
      classes,
      startDate,
      endDate,
      startDateError,
      endDateError,
      startDateErrorMessage,
      endDateErrorMessage,
      handleStartDateChange,
      setTodayDate,
      handleEndDateChange,
      isFromViewEdit,
      userRoleHasMobilityRideshareView,
    } = this.props;
    return (
      <>
        {isFromViewEdit && userRoleHasMobilityRideshareView ? (
          <FormControl className={classes.searchParam}>
            <Typography align="left" className={classes.custReturnDate}>
              Start Date:{" "}
              <Moment interval={0} format={"MM/DD/YYYY"}>
                {startDate}
              </Moment>
            </Typography>
          </FormControl>
        ) : (
          <FormControl className={classes.searchParam}>
            <Typography align="left" className={classes.custReturnDate}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="startDatePicker"
                  label="Start Date:"
                  value={startDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={startDateError}
                  helperText={startDateErrorMessage}
                  className={classes.datePickerWidth}
                  disablePast={true}
                  inputProps={{ readOnly: true }}
                />
              </MuiPickersUtilsProvider>
            </Typography>
            <Link
              className={classes.todayLink}
              variant="body2"
              onClick={() => setTodayDate("start")}
            >
              Today
            </Link>
          </FormControl>
        )}
        {isFromViewEdit && userRoleHasMobilityRideshareView ? (
          <FormControl className={classes.searchParam}>
            <Typography align="left" className={classes.custReturnDate}>
              End Date:{" "}
              <Moment interval={0} format={"MM/DD/YYYY"}>
                {endDate}
              </Moment>
            </Typography>
          </FormControl>
        ) : (
          <FormControl className={classes.searchParam}>
            <Typography align="left" className={classes.custReturnDate}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="endDatePicker"
                  label="End Date:"
                  value={endDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={endDateError}
                  helperText={endDateErrorMessage}
                  className={classes.datePickerWidth}
                  disablePast={true}
                  inputProps={{ readOnly: true }}
                />
              </MuiPickersUtilsProvider>
            </Typography>
            <Link
              className={classes.todayLink}
              variant="body2"
              onClick={() => setTodayDate("end")}
            >
              Today
            </Link>
          </FormControl>
        )}
      </>
    );
  };

  returnText = (programType) => {
    let text = "";
    for (let pType in programTypeOptions) {
      if (programTypeOptions[pType].id === programType) {
        text = programTypeOptions[pType].text;
      }
    }
    return text;
  };

  render() {
    const {
      classes,
      name,
      programType,
      frequency,
      saleEvent,
      handleChange,
      handleNameChange,
      handleNameBlur,
      isChecked,
      toggleFreqCheck,
      programTypeError,
      programTypeErrorMessage,
      nameError,
      nameErrorMessage,
      nameLengthError,
      nameLengthErrorMessage,
      frequencyError,
      frequencyErrorMessage,
      saleEventError,
      saleEventErrorMessage,
      frequencyDayError,
      serverError,
      serverErrorMessage,
      criteriaError,
      gradeError,
      isFromViewEdit,
      userRoleHasMobilityRideshareView,
    } = this.props;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        maxLength: 40,
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    return (
      <React.Fragment>
        <div className={classes.autoSearchSaveWrapper}>
          <Typography variant="h4" className={classes.heading}>
            Mobility Rideshare Vehicle Selection Criteria
          </Typography>
          <div className={classes.saveSearchWrapper}>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                <strong>Name:</strong>
              </Typography>
              <TextField
                id="name"
                placeholder="Required Field"
                className={classes.textField}
                value={name}
                onChange={handleNameChange("name")}
                onBlur={() => handleNameBlur("name")}
                margin="normal"
                variant="filled"
                autoComplete="off"
                name="name"
                InputProps={InputProps}
                disabled={isFromViewEdit}
                autoFocus
                required
              />
            </div>
            <div className={classes.limitWrapper}>
              Max 15 characters allowed.
            </div>
            <div className={classes.errorMessage}>
              {nameError ? nameErrorMessage : null}
            </div>
            <div className={classes.errorMessage}>
              {nameLengthError ? nameLengthErrorMessage : null}
            </div>
            <div className={classes.programType}>
              {isFromViewEdit ? (
                <>
                  <Typography className={classes.fieldLabelProgramType}>
                    <strong>Program Type:</strong>
                  </Typography>
                  <TextField
                    id="programType"
                    className={classes.textField}
                    value={this.returnText(programType)}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="programType"
                    InputProps={InputProps}
                    disabled={isFromViewEdit}
                    autoFocus
                    required
                  />
                </>
              ) : (
                <>
                  <FormControl className={classes.formControl}>
                    <div className={classes.fieldLabelEmail}>
                      <strong>Select Program Type:</strong>
                    </div>
                    <Select
                      id="programType"
                      className={classes.selectInput}
                      value={programType}
                      onChange={handleChange("programType")}
                      name="programType"
                      displayEmpty
                      disabled={isFromViewEdit}
                    >
                      {programTypeOptions.map((info) => {
                        return (
                          <MenuItem id={info.id} value={info.id} key={info.id}>
                            {info.text}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            </div>
            <div className={classes.errorMessage}>
              {programTypeError ? programTypeErrorMessage : null}
            </div>
            {this.renderDatePicker()}
            <div className={classes.displayFrequency}>
              <FormControl className={classes.formControl}>
                <div className={classes.fieldLabelEmail}>
                  <strong>Select Vehicle Sale Event:</strong>
                </div>
                <Select
                  id="saleEvent"
                  className={classes.selectInput}
                  value={saleEvent}
                  onChange={handleChange("saleEvent")}
                  name="saleEvent"
                  disabled={isFromViewEdit && userRoleHasMobilityRideshareView}
                >
                  {saleEventItems.map((info) => {
                    return (
                      <MenuItem id={info.id} value={info.id} key={info.id}>
                        {info.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className={classes.errorMessage}>
              {saleEventError ? saleEventErrorMessage : null}
            </div>
            <div className={classes.displayFrequency}>
              <FormControl className={classes.formControl}>
                <div className={classes.fieldLabelEmail}>
                  <strong>Select Vehicle Frequency:</strong>
                </div>
                <Select
                  id="frequency"
                  className={classes.selectInput}
                  value={frequency}
                  onChange={handleChange("frequency")}
                  name="frequency"
                  displayEmpty
                  disabled={isFromViewEdit && userRoleHasMobilityRideshareView}
                >
                  {frequencyItems.map((info) => {
                    return (
                      <MenuItem id={info.id} value={info.id} key={info.id}>
                        {info.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className={classes.errorMessage}>
              {frequencyError ? frequencyErrorMessage : null}
            </div>
            <div className={classes.errorMessage}>
              {serverError ? serverErrorMessage : null}
            </div>
            <div className={classes.errorMessage}>
              {criteriaError ? "Please select at least one criteria." : null}
            </div>
            <div className={classes.errorMessage}>
              {gradeError ? "Please enter valid vehicle grade." : null}
            </div>
            {frequency === "DaysOfTheWeek" ? (
              <>
                <FormControl component="fieldset">
                  <Typography
                    className={
                      frequencyDayError
                        ? classes.viewDaysSelectionLabelError
                        : classes.viewDaysSelectionLabel
                    }
                  >
                    Please Select Day(s) of the week
                  </Typography>
                  <div className={classes.viewDaysSelection}>
                    <div className={classes.viewDaysSelectionDays}>
                      {daysOfTheWeekLeft.map((info) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                id={info.id}
                                key={info.id}
                                checked={isChecked(`frequencyDay`, info.id)}
                                onChange={() => toggleFreqCheck(info.id)}
                                value={info.id}
                                color="default"
                                classes={{
                                  root: classes.checkboxRoot,
                                  checked: classes.checked,
                                }}
                                disabled={
                                  isFromViewEdit &&
                                  userRoleHasMobilityRideshareView
                                }
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  fontSize: ".75rem",
                                }}
                              >
                                {info.text}
                              </Typography>
                            }
                          />
                        );
                      })}
                    </div>
                    <div className={classes.viewDaysSelectionDays}>
                      {daysOfTheWeekRight.map((info) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                id={info.id}
                                key={info.id}
                                checked={isChecked(`frequencyDay`, info.id)}
                                onChange={() => toggleFreqCheck(info.id)}
                                value={info.id}
                                color="default"
                                classes={{
                                  root: classes.checkboxRoot,
                                  checked: classes.checked,
                                }}
                                disabled={
                                  isFromViewEdit &&
                                  userRoleHasMobilityRideshareView
                                }
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  fontSize: ".75rem",
                                }}
                              >
                                {info.text}
                              </Typography>
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                </FormControl>
              </>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MobilitySearchForm);
