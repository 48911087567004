export const ADD_PURCHASED_VEHICLE = "ADD_PURCHASED_VEHICLE";
export const REMOVE_PURCHASED_VEHICLE = "REMOVE_PURCHASED_VEHICLE";
export const TICK_TIMER = "TICK_TIMER";
export const ADD_SEARCH_CRITERIA = "ADD_SEARCH_CRITERIA";
export const SET_AUCTION_TIMES = "SET_AUCTION_TIMES";
export const SET_NAV_LOCATION = "SET_NAV_LOCATION";
export const SET_CURRENT_AUCTION = "SET_CURRENT_AUCTION";
export const STORE_REPORTS = "STORE_REPORTS";
export const CLEAR_STORED_REPORTS = "CLEAR_STORED_REPORTS";
export const STORE_ARBITRATIONS = "STORE_ARBITRATIONS";
export const CLEAR_STORED_ARBITRATIONS = "CLEAR_STORED_ARBITRATIONS";
export const SET_PAGE_THEME = "SET_PAGE_THEME";
export const HIDE_MOBILE_NAV_MENU = "HIDE_MOBILE_NAV_MENU";
export const NON_SAFARI_MOBILE_APP_DOWNLOAD_NOTIFICATION_MODAL =
  "NON_SAFARI_MOBILE_APP_DOWNLOAD_NOTIFICATION_MODAL";
export const MOBILE_APP_DOWNLOAD_INSTRUCTIONS_MODAL =
  "MOBILE_APP_DOWNLOAD_INSTRUCTIONS_MODAL";
export const ADVANCE_SEARCH_CRITERIA_WRAPPER_DATA =
  "ADVANCE_SEARCH_CRITERIA_WRAPPER_DATA";
export const ADVANCE_SEARCH_CRITERIA_DATA = "ADVANCE_SEARCH_CRITERIA_DATA";
export const SHOW_SAVED_SEARCH_PAGE = "SHOW_SAVED_SEARCH_PAGE";
export const SET_BUYER_VIEW = "SET_BUYER_VIEW";
export const SET_VEHICLES_NEEDING_ATTENTION = "SET_VEHICLES_NEEDING_ATTENTION";
export const SYSTEM_MESSAGE_VIEWED = "SYSTEM_MESSAGE_VIEWED";
export const STORE_FILTER_OPTIONS = "STORE_FILTER_OPTIONS";
export const SET_EXTERNAL_CONDITION_REPORT_VIEW =
  "SET_EXTERNAL_CONDITION_REPORT_VIEW";
export const TOGGLZ_CONFIG = "TOGGLZ_CONFIG";

export const add_purchased_vehicle = (vin) => {
  return {
    type: ADD_PURCHASED_VEHICLE,
    vin,
  };
};

export const remove_purchased_vehicle = (vin) => {
  return {
    type: REMOVE_PURCHASED_VEHICLE,
    vin,
  };
};

export const tick_timer = (vin) => {
  return {
    type: TICK_TIMER,
    vin,
  };
};

export const set_auction_times = (times) => {
  return {
    type: SET_AUCTION_TIMES,
    times,
  };
};

export const set_nav_location = (navLocation) => {
  return {
    type: SET_NAV_LOCATION,
    navLocation,
  };
};

export const set_current_auction = (auction) => {
  return {
    type: SET_CURRENT_AUCTION,
    auction,
  };
};

export const store_reports = (report, dataType, to, from) => {
  return {
    type: STORE_REPORTS,
    report,
    dataType,
    to,
    from,
  };
};

export const clear_stored_reports = () => {
  return {
    type: CLEAR_STORED_REPORTS,
  };
};

export const store_arbitrations = (arbitration, dataType) => {
  return {
    type: STORE_ARBITRATIONS,
    arbitration,
    dataType,
  };
};

export const clear_stored_arbitrations = () => {
  return {
    type: CLEAR_STORED_ARBITRATIONS,
  };
};

export const set_page_theme = (franchise) => {
  return {
    type: SET_PAGE_THEME,
    franchise,
  };
};

export const hide_mobile_nav_menu = (hideMobileNavMenu) => {
  return {
    type: HIDE_MOBILE_NAV_MENU,
    hideMobileNavMenu,
  };
};

export const non_safari_mobile_app_download_notification_modal = (
  nonSafariMobileAppDownloadNotificationModal
) => {
  return {
    type: NON_SAFARI_MOBILE_APP_DOWNLOAD_NOTIFICATION_MODAL,
    nonSafariMobileAppDownloadNotificationModal,
  };
};

export const mobile_app_download_instructions_modal = (
  mobileAppDownloadInstructionsModal
) => {
  return {
    type: MOBILE_APP_DOWNLOAD_INSTRUCTIONS_MODAL,
    mobileAppDownloadInstructionsModal,
  };
};

export const advanced_search_criteria_wrapper_data = (
  advancedSearchCriteriaWrapperData
) => {
  return {
    type: ADVANCE_SEARCH_CRITERIA_WRAPPER_DATA,
    advancedSearchCriteriaWrapperData,
  };
};

export const advanced_search_criteria_data = (advancedSearchCriteriaData) => {
  return {
    type: ADVANCE_SEARCH_CRITERIA_DATA,
    advancedSearchCriteriaData,
  };
};

export const show_saved_search_page = (showsavedsearchpage) => {
  return {
    type: SHOW_SAVED_SEARCH_PAGE,
    showsavedsearchpage,
  };
};

export const set_buyer_view = () => {
  return {
    type: SET_BUYER_VIEW,
  };
};

export const set_vehicles_needing_attention = (toggle) => {
  return {
    type: SET_VEHICLES_NEEDING_ATTENTION,
    toggle,
  };
};

export const system_message_viewed = () => {
  return {
    type: SYSTEM_MESSAGE_VIEWED,
  };
};

export const store_filter_options = (options) => {
  return {
    type: STORE_FILTER_OPTIONS,
    options,
  };
};

export const set_external_condition_report_view = () => {
  return {
    type: SET_EXTERNAL_CONDITION_REPORT_VIEW,
  };
};

export const togglz_config = (togglzMap) => {
  return {
    type: TOGGLZ_CONFIG,
    togglzMap,
  };
};
