import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";

import BidHistory from "../components/BidHistory";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
    fontSize: ".65rem",
  },
  progress: {
    margin: ".5rem",
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
});
export class MyBidHistoryReportTable extends Component {
  componentDidMount = () => {
    const { data, setStatus } = this.props;
    if (data && data.status === "false") {
      setStatus(
        "The report service is having some issues, please try again in a few minutes."
      );
    }
  };

  displayBidHistory = (rowData) => {
    return (
      <BidHistory
        saleEventItemId={rowData.saleEventItemId}
        from="BidHistoryReport"
      />
    );
  };

  render() {
    const { classes, data, toggleConditionReport } = this.props;
    const cellStyle = {
      maxWidth: "5rem",
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
    };
    return (
      <div id="materialTable" className={classes.root}>
        <MaterialTable
          columns={[
            {
              title: "Date/Event",
              field: "auctionDate",
              cellStyle,
              render: (rowData) => (
                <div>
                  {moment(rowData.auctionDate).format("MM/DD/YYYY")}
                  <br />
                  {rowData.event}{" "}
                </div>
              ),
            },
            {
              title: "Year",
              field: "year",
              cellStyle,
            },
            {
              title: "VIN/CR/Model/Trim",
              field: "model",
              cellStyle,
              render: (rowData) => (
                <div>
                  {rowData.model} {rowData.trim} <br />
                  <span
                    onClick={() =>
                      toggleConditionReport(
                        rowData.inventoryId,
                        "bidHistoryData"
                      )
                    }
                    className={classes.clickableLink}
                  >
                    {rowData.vin}
                  </span>
                </div>
              ),
            },
            {
              title: "Color",
              field: "color",
              cellStyle,
            },
            {
              title: "Odometer",
              field: "odometerReading",
              cellStyle,
              render: (rowData) =>
                Number(rowData.odometerReading).toLocaleString(),
            },
            {
              title: "Start Price",
              field: "startPrice",
              cellStyle,
              render: (rowData) =>
                Number(rowData.startPrice).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
            },
            {
              title: "My Bid Amount",
              field: "bidAmount",
              cellStyle,
              render: (rowData) =>
                Number(rowData.bidAmount).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
            },
            {
              title: "My Bid Type",
              field: "bidType",
              cellStyle,
            },
            {
              title: "Winning Bid",
              field: "winningBidAmount",
              cellStyle,
              render: (rowData) =>
                rowData.winningBidAmount
                  ? Number(rowData.winningBidAmount).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : null,
            },
            {
              title: "Winning Bidder",
              field: "winningBidder",
              cellStyle,
            },
            {
              title: "# of Bids",
              field: "totalBids",
              cellStyle,
            },
          ]}
          data={data && data.status !== "false" ? data : []}
          detailPanel={[
            {
              icon: "history",
              tooltip: "Show bid history",
              render: (rowData) => {
                return this.displayBidHistory(rowData);
              },
            },
          ]}
          options={{
            headerStyle: {
              backgroundColor: "#EEE",
              textAlign: "center",
              padding: ".5rem",
            },
            detailPanelColumnAlignment: "right",
            showTitle: false,
            sorting: true,
            search: true,
            paging: true,
            maxBodyHeight: 400,
          }}
        />
      </div>
    );
  }
}

MyBidHistoryReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyBidHistoryReportTable);
