const API_URL = process.env.REACT_APP_API_URL;

export const getNotes = async (dealerId, inventoryId) => {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/notes/getNotes?dealerId=${dealerId}&inventoryId=${inventoryId}`,
      options
    );
    return response.json();
  } catch (err) {
    console.log(err);
  }
};

export const saveNote = async (dealerId, userId, inventoryId, vin, note) => {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/notes/saveNote?dealerId=${dealerId}&userId=${userId}&inventoryId=${inventoryId}&vin=${vin}&note=${note}`,
      options
    );
    return await response.json();
  } catch (err) {
    return { status: false, message: err.message };
  }
};
