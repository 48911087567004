import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MaterialTable from "material-table";
import * as R from "ramda";

import { withStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

import Notes from "../components/Notes";
import ReportVehicleDetail from "../components/ReportVehicleDetails";

import { remove_purchased_vehicle } from "../actions/uiActions";

import * as VehiclePurchaseService from "../services/VehiclePurchaseServices";
import * as AdminService from "../services/AdminServices";
import { getNotes } from "../services/NotesServices";
import * as userAccess from "../services/UserRoles";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
    fontSize: ".65rem",
  },
  progress: {
    margin: "1rem",
  },
  cancelButton: {
    height: "40%",
    boxShadow: "none",
    borderRadius: 0,
    margin: 0,
    backgroundColor: theme.palette.secondary.gray,
    color: theme.palette.primary.white,
    padding: ".75rem !important",
  },
  confirmCancelButton: {
    height: "40%",
    boxShadow: "none",
    borderRadius: 0,
    margin: 0,
    backgroundColor: theme.palette.DDO.main,
    color: theme.palette.primary.white,
    padding: ".75rem !important",
  },
  detailIcon: {},
  boldedRed: {
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
  },
  dialogText: {
    color: theme.palette.primary.gray,
    fontWeight: "bold !important",
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  "@global": {
    ".acceptIcon": {
      color: theme.palette.DDO.main,
    },
    ".MuiButtonBase-root": {
      padding: 0,
    },
  },
});
export class MyPurchaseReportTable extends Component {
  state = {
    data: [],
    notes: [],
    user: "",
    accountSetting: "",
    selectedNotesInventoryId: "",
    selectedInfoInventoryId: "",
    loadingNotesComplete: false,
    loadingVehicleComplete: false,
    vehicleInfo: {},
    cancelPurchaseToggle: false,
    cancelTimers: {},
    isPendingCancel: false,
    isConfirmDisabled: false,
  };

  componentDidMount = () => {
    const { data, user, purchasedVehicles, accountSetting } = this.props;
    const cancelTimers = {};
    if (data) {
      data.map((car) => {
        if (
          Object.keys(purchasedVehicles).indexOf(car.vin) !== -1 &&
          car.purchaseDateInMillis
        ) {
          return (cancelTimers[car.vin] = car.purchaseDateInMillis);
        } else {
          return null;
        }
      });
      if (Object.keys(cancelTimers).length > 0) {
        this.tickDown = setInterval(() => {
          this.decrementPurchasedVehiclesTimer();
        }, 1000);
      }
    }
    this.setState({
      user,
      accountSetting,
      data,
      cancelTimers,
    });
  };

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    if (data !== prevProps.data) {
      this.setState({ data });
    }
  };

  decrementPurchasedVehiclesTimer = () => {
    const { cancelTimers } = this.state;
    const newTimers = {};
    if (Object.keys(cancelTimers).length > 0) {
      Object.keys(cancelTimers).map((car) => {
        if (this.state.cancelTimers[car] > 0) {
          return (newTimers[car] = this.state.cancelTimers[car] - 1000);
        } else {
          return null;
        }
      });
      this.setState({
        cancelTimers: newTimers,
      });
    } else {
      clearInterval(this.tickDown);
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.tickDown);
  };

  displayVehicleInfo = (inventoryId) => {
    const { classes } = this.props;
    const { selectedInfoInventoryId, loadingVehicleComplete, vehicleInfo } =
      this.state;
    if (selectedInfoInventoryId !== inventoryId) {
      this.setState({
        selectedInfoInventoryId: inventoryId,
        loadingVehicleComplete: false,
        vehicleInfo: {},
      });
      AdminService.getVehicleDetails(inventoryId).then((vehicleInfo) => {
        if (vehicleInfo && vehicleInfo.status !== "false") {
          this.setState({
            vehicleInfo,
          });
        }
        this.setState({ loadingVehicleComplete: true });
      });
    } else {
      if (loadingVehicleComplete) {
        return (
          <div>
            <ReportVehicleDetail vehicleInfo={vehicleInfo} />
          </div>
        );
      } else {
        return (
          <div>
            <CircularProgress className={classes.progress} />
          </div>
        );
      }
    }
  };

  displayNotes = (inventoryId, vin) => {
    const { classes } = this.props;
    const { user, notes, selectedNotesInventoryId, loadingNotesComplete } =
      this.state;
    if (selectedNotesInventoryId !== inventoryId) {
      this.setState({
        selectedNotesInventoryId: inventoryId,
        loadingNotesComplete: false,
        notes: [],
      });
      getNotes(user.dealerId, inventoryId).then((notes) => {
        if (notes && notes.status !== "false") {
          this.setState({
            notes,
          });
        }
        this.setState({ loadingNotesComplete: true });
      });
    } else {
      if (loadingNotesComplete) {
        return (
          <div>
            <Notes
              addNote={this.addNote}
              toggleNote={this.toggleNote}
              notes={notes}
              user={user}
              car={{
                vehicleIdentificationNumber: vin,
                id: inventoryId,
                inventoryId: inventoryId,
              }}
              reports
            />
          </div>
        );
      } else {
        return (
          <div>
            <CircularProgress className={classes.progress} />
          </div>
        );
      }
    }
  };

  addNote = (newNote) => {
    const { accountSetting, selectedInventoryId, user } = this.state;
    const fullNote = {
      inventoryId: selectedInventoryId,
      dealerId: user.dealerId,
      note: newNote,
      needFollowUp: false,
      userId: user.userId,
      firstName: accountSetting.firstName,
      lastName: accountSetting.lastName,
      createdDate: moment().format("LLL"),
    };
    this.setState({
      notes: [...this.state.notes, fullNote],
    });
  };

  handlePurchaseReceiptPdf = (inventoryId) => {
    try {
      VehiclePurchaseService.getPurchaseReceiptPdf(inventoryId);
    } catch (err) {
      console.log(err);
    }
  };

  toggleNote = () => {
    // no-op
  };

  cancelPurchase = async (rowData) => {
    const { user, dispatch } = this.props;
    const { cancelPurchaseToggle, cancelTimers, data } = this.state;
    if (!cancelPurchaseToggle) {
      this.setState({
        cancelPurchaseToggle: rowData,
      });
    } else {
      const car = {
        id: cancelPurchaseToggle.inventoryId,
        saleEventItemId: cancelPurchaseToggle.saleEventItemId,
      };
      const loggedInUser = {
        loggedInUserId: user.userId,
      };
      let newCancelTimers = cancelTimers;
      let newData = data;

      if (!rowData) {
        this.setState({
          isConfirmDisabled: true,
        });
      }
      const response = await VehiclePurchaseService.cancelPurchase(
        car,
        loggedInUser,
        true
      );

      if (null === response) {
        this.setState({
          isConfirmDisabled: false,
        });
      }

      if (response === "Pending") {
        this.setState({
          isPendingCancel: true,
          isConfirmDisabled: false,
        });
      } else {
        if (response) {
          newCancelTimers = R.dissocPath(
            ["cancelTimers", cancelPurchaseToggle.vin],
            this.state
          );
          newData = R.filter(
            (rowData) => rowData.vin !== cancelPurchaseToggle.vin,
            data
          );
          dispatch(remove_purchased_vehicle(cancelPurchaseToggle.vin));

          this.setState({
            cancelPurchaseToggle: false,
            cancelTimers: newCancelTimers.cancelTimers,
            data: newData,
            isPendingCancel: false,
            isConfirmDisabled: true,
          });
        }
      }
    }
  };

  handleClose = () => {
    this.setState({
      cancelPurchaseToggle: false,
      isPendingCancel: false,
    });
  };

  timeRemaining = (car) => {
    const { cancelTimers } = this.state;
    const minutes = car.vehicleIdentificationNumber
      ? cancelTimers[car.vehicleIdentificationNumber] / 60000
      : cancelTimers[car.vin] / 60000;
    const rminutes = Math.floor(minutes);
    const seconds = (minutes - rminutes) * 60;
    const rseconds = Math.round(seconds);
    return { minutes: rminutes, seconds: rseconds };
  };

  render() {
    const { classes, toggleConditionReport, user } = this.props;
    const {
      cancelPurchaseToggle,
      cancelTimers,
      isPendingCancel,
      isConfirmDisabled,
    } = this.state;
    const cellStyle = {
      maxWidth: "5rem",
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
    };
    return (
      <div id="materialTable" className={classes.root}>
        <MaterialTable
          columns={[
            {
              title: "Purchase Date",
              field: "purchaseDate",
              cellStyle,
              render: (rowData) =>
                moment(rowData.purchaseDate).format("MM/DD/YYYY"),
              defaultSort: "desc",
            },
            {
              title: "VIN/CR/Model/Trim",
              cellStyle,
              field: "model",
              render: (rowData) => (
                <div>
                  {rowData.model} {rowData.trim}{" "}
                  <span
                    onClick={
                      rowData.cravailable
                        ? () =>
                            toggleConditionReport(
                              rowData.inventoryId,
                              "purchaseData"
                            )
                        : null
                    }
                    className={
                      rowData.cravailable ? classes.clickableLink : null
                    }
                  >
                    {rowData.vin}
                  </span>
                </div>
              ),
            },
            {
              title: "Account Type",
              field: "accountType",
              cellStyle,
            },
            {
              title: "Color",
              field: "color",
              cellStyle,
            },
            {
              title: "Odometer",
              field: "odometer",
              cellStyle,
            },
            {
              title: "Sale Type",
              field: "saleType",
              cellStyle,
            },
            {
              title: "Purchase Price",
              field: "purchasePrice",
              cellStyle,
              render: (rowData) =>
                Number(rowData.purchasePrice).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
            },
            {
              title: "Guaranteed Payments",
              field: "guaranteedPayments",
              cellStyle,
              render: (rowData) =>
                rowData.isRemainingPaymentGuaranteed !== "1"
                  ? Number(0.0).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : rowData.guaranteedPayments &&
                    Number(rowData.guaranteedPayments).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    }),
            },
            {
              title: "Payment Type",
              field: "paymentType",
              cellStyle,
            },
            {
              title: "Promo",
              field: "promoCode",
              cellStyle,
            },
            {
              title: "Arbitration?",
              field: "arbitrated",
              cellStyle,
            },
          ]}
          data={this.state.data}
          detailPanel={
            userAccess.canPurchaseVehicles(user.userRoles)
              ? [
                  {
                    icon: "noteadd",
                    tooltip: "Show Notes",
                    className: classes.detailIcon,
                    render: (rowData) => {
                      return this.displayNotes(
                        rowData.inventoryId,
                        rowData.vin
                      );
                    },
                  },
                  {
                    icon: "info",
                    tooltip: "Show Vehicle Info",
                    render: (rowData) => {
                      return this.displayVehicleInfo(rowData.inventoryId);
                    },
                  },
                ]
              : [
                  {
                    icon: "info",
                    tooltip: "Show Vehicle Info",
                    render: (rowData) => {
                      return this.displayVehicleInfo(rowData.inventoryId);
                    },
                  },
                ]
          }
          actions={[
            {
              icon: "receipt",
              tooltip: "Download Purchase Receipt",
              onClick: (event, rowData) => {
                this.handlePurchaseReceiptPdf(rowData.inventoryId);
              },
            },
            (rowData) =>
              cancelTimers[rowData.vin]
                ? {
                    icon: "report_problem",
                    tooltip: `Cancel Purchase`,
                    onClick: (evt, rowData) => {
                      this.cancelPurchase(rowData);
                    },
                    iconProps: {
                      color: "error",
                    },
                  }
                : null,
          ]}
          options={{
            headerStyle: {
              backgroundColor: "#EEE",
              textAlign: "center",
              fontSize: ".7rem",
              padding: 0,
            },
            showTitle: false,
            sorting: true,
            search: true,
            actionsColumnIndex: -1,
            detailPanelType: "single",
            detailPanelColumnAlignment: "left",
            maxBodyHeight: 1000,
            toolbar: true,
          }}
        />
        <Dialog
          open={cancelPurchaseToggle || isPendingCancel}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            className={classes.dialogText}
            id="alert-dialog-slide-title"
          >
            Cancel Purchase
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              className={classes.dialogText}
              id="alert-dialog-slide-description"
            >
              {isPendingCancel
                ? "Your purchase cancellation cannot be completed at this time. Please wait 30 seconds before attempting to cancel your purchase of this vehicle."
                : "Please confirm that you would like to cancel the purchase of this vehicle."}
            </DialogContentText>

            {!isPendingCancel && (
              <DialogContentText
                className={classes.dialogText}
                id="alert-dialog-slide-description"
              >
                Time Remaining:
                {cancelPurchaseToggle ? (
                  <span className={classes.boldedRed}>{` ${
                    this.timeRemaining(cancelPurchaseToggle).minutes
                  }m : ${
                    this.timeRemaining(cancelPurchaseToggle).seconds
                  }s`}</span>
                ) : null}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              id="abortCancelPurchaseButton"
              onClick={() => this.handleClose()}
              className={classes.cancelButton}
            >
              {isPendingCancel ? "Return" : "Abort Cancellation"}
            </Button>
            {!isPendingCancel && (
              <Button
                id="cancelPurchaseButton"
                onClick={() => this.cancelPurchase()}
                className={classes.confirmCancelButton}
                disabled={isConfirmDisabled}
              >
                Cancel Purchase
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

MyPurchaseReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyPurchaseReportTable);
