const API_URL = process.env.REACT_APP_API_URL;

export async function loadProgram(inventoryId, programId) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/utility/program?inventoryId=${inventoryId}&programId=${programId}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export async function validateAndSaveProgram(
  inventoryId,
  programId,
  programFromValue,
  userId,
  userCertificateNumber,
  dealerId,
  comments
) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/customerProgram/validateAndSaveProgram?inventoryId=${inventoryId}&programId=${programId}&programFromValue=${programFromValue}&userId=${userId}&userCertificateNumber=${userCertificateNumber}&dealerId=${dealerId}&comments=${comments}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export async function guarnteePaymentDone(
  inventoryId,
  waivedPayments,
  outStandingPayments
) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/customerProgram/guarnteePaymentDone?inventoryId=${inventoryId}&waivedPayments=${waivedPayments}&outStandingPayments=${outStandingPayments}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export async function calculateWaivedAndOutstandingAmounts(
  inventoryId,
  noOfPaymentsWaived
) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/customerProgram/calculateWaivedAndOutstandingAmounts?inventoryId=${inventoryId}&numberOfPaymentsWaived=${noOfPaymentsWaived}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
