import React, { Component } from "react";
import PropTypes from "prop-types";
import renderHTML from "react-render-html";

import { withStyles } from "@material-ui/core/styles";

import * as LegalPrivacyContentServices from "../services/LegalPrivacyContentServices";

export const styles = (theme) => ({
  container: {
    marginLeft: "1rem",
    marginRight: "1rem",
    width: "auto",
    height: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginTop: "3.5rem",
    },
  },
  paper: {
    marginTop: "1rem",
    flexDirection: "column",
    alignItems: "left",
    padding: `.5rem .5rem`,
    display: "flex",
    width: "100%",
    overflowY: "auto",
    height: "90%",
    [theme.breakpoints.down("sm")]: {
      height: "79%",
      paddingBottom: "6rem",
    },
  },
});

export class Legal extends Component {
  state = {
    content: null,
  };

  componentWillMount = () => {
    this.getLegal();
  };

  getLegal = async () => {
    const response = await LegalPrivacyContentServices.legalData();
    this.setState({
      content: response,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.paper}>
          {this.state.content !== null ? renderHTML(this.state.content) : null}
        </div>
      </div>
    );
  }
}

Legal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Legal);
