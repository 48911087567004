import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

import DateFnsUtils from "@date-io/date-fns";

import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Button,
  Checkbox,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Snackbar,
  Link,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Loading from "../components/Loading";
import DealerPurchasePerformance from "../components/DealerPurchasePerformance";
import MyPurchaseReportTable from "../components/MyPurchaseReportTable";
import MyGroundedReportTable from "../components/MyGroundedReportTable";
import MyDealerPurchaseReportTable from "../components/MyDealerPurchaseReportTable";
import MyBidHistoryReportTable from "../components/MyBidHistoryReportTable";
import MyInspectionReportTable from "../components/MyInspectionReportTable";
import MyTransportationReportTable from "../components/MyTransportationReportTable";
import MyUpstreamSaleReportTable from "../components/MyUpstreamSaleReportTable";
import MyPurchaseGoalReportTable from "../components/MyPurchaseGoalReportTable";

import * as ReportService from "../services/ReportServices";
import * as userAccess from "../services/UserRoles";

import { store_reports, clear_stored_reports } from "../actions/uiActions";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const header = [
  [
    "Transportation Ordered",
    "Year",
    "VIN",
    "Model",
    "Trim",
    "Color",
    "Odometer",
    "Transport Destination",
    "Est. Pickup Date",
    "Pickup Date",
    "Rideshare Program",
    "Transportation Status",
  ],
];
const groundingReportHeader = [
  [
    "Grounded Date",
    "Return Date",
    "Master Keys",
    "Valet Keys",
    "Year",
    "VIN",
    "Model",
    "Color",
    "Odometer",
    "Payoff",
    "Residual",
    "Market",
    "Remaining Payments",
    "Guaranteed",
    "Promo",
    "Status",
  ],
];

const styles = (theme) => ({
  form: { dispay: "flex", margin: ".5rem" },
  vinSearch: {},
  year: {},
  make: {},
  model: {},
  button: {
    margin: ".5rem",
    fontWeight: "bold",
    width: "5rem",
    height: "2rem",
  },
  table: { display: "flex", margin: ".5rem" },
  dealerPurchaseWrapper: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    marginLeft: "7rem",
  },
  upperWrapper: {
    display: "flex",
    marginBottom: ".5rem",
  },
  leftUpperWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "max-content",
  },
  rightUpperWrapper: {
    width: "50%",
    alignItems: "center",
    position: "relative",
  },
  buttonWrapper: {},
  searchParam: {
    marginRight: ".5rem",
    minWidth: "5rem",
  },
  searchParamWrapper: {
    marginLeft: ".5rem",
    width: "100%",
  },
  arbitrationLabel: {
    marginLeft: ".1rem",
  },
  arbitrationCheck: {
    padding: ".75rem !important",
  },
  radioRoot: {
    color: theme.palette.primary.gray,
  },
  radioLabel: {
    fontSize: ".75rem",
  },
  checked: {},
  colorPrimary: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  group: {
    marginRight: "2rem",
    marginLeft: "6.5rem",
    display: "flex",
    flexDirection: "row",
  },
  datePickerWidth: {
    width: "14rem",
  },
  todayLink: {
    textDecoration: "underline",
    textAlign: "end",
    fontSize: ".6rem",
    color: "#58595B",
  },
  "@global": {
    ".MuiFormControl-marginNormal": {
      marginTop: "0rem",
      marginBottom: "0rem",
    },
    ".DayPicker": {
      width: "17rem",
    },
    ".DayPicker-Month": {
      marginTop: 0,
    },
    ".DayPicker-NavButton": {
      top: 0,
    },
    ".Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)":
      {
        backgroundColor: `#f0f8ff !important`,
        color: "#4a90e2",
      },
    ".Range .DayPicker-Day": {
      borderRadius: "50% !important",
      padding: ".25rem",
      display: "flex",
      width: "1rem",
      alignItems: "center",
      justifyContent: "center",
    },
    ".DayPicker-Week": {
      display: "flex",
      height: "1.5rem",
      justifyContent: "space-between",
      fontSize: theme.font.small,
    },
    ".DayPicker-Weekdays": {
      display: "flex",
      marginTop: 0,
    },
    ".DayPicker-Weekday": {
      fontWeight: "bold",
      padding: ".25rem",
    },
    ".DayPicker-Footer": {
      padding: "0 43%",
    },
    ".DayPicker-TodayButton": {
      fontSize: theme.font.small,
    },
  },
});

export class ReportSearch extends Component {
  state = {
    vin: "",
    years: ["ALL"],
    makes: ["Lexus", "Scion", "Toyota"],
    models: [],
    includeArbitration: false,
    selectedYear: "ALL",
    selectedMake: "ALL",
    selectedModel: "ALL",
    from: null,
    to: null,
    enteredTo: null,
    selectedBidResult: "All",
    transportCheckbox: false,
    purchaseData: null,
    groundedData: null,
    performanceData: null,
    performanceDataTotal: [],
    bidHistoryData: null,
    inspectionData: null,
    transportationData: null,
    purchasedByOthersData: null,
    goalReportData: null,
    goalCountFrntEnd: 0,
    goalAchieved: 0,
    monthYearFrndEnd: "",
    tabValue: null,
    status: null,
    fetchingData: false,
    printFetch: false,
    fromDateError: false,
    toDateError: false,
    fromDateErrorMessage: "",
    toDateErrorMessage: "",
    rideshareUser: false,
  };

  componentWillMount = () => {
    this.setRideShareProgramValue();
    this.setInitialDates();
  };

  setRideShareProgramValue = () => {
    const { user } = this.props;
    if (userAccess.isRideshareUser(user.userRoles)) {
      this.setState({
        rideshareUser: true,
      });
    } else {
      this.getDealerPurchasePerformance();
    }
  };

  componentDidMount = () => {
    const { tabValue, storedReports, dispatch } = this.props;
    const { years } = this.state;
    if (years.length === 1) {
      this.populateYears();
    }
    if (!storedReports) {
      this.setInitialDates();
      this.populateData(tabValue);
    } else {
      let newState = {};
      Object.keys(storedReports).map((key) => {
        return (newState[key] = storedReports[key]);
      });
      this.setState({
        ...this.state,
        ...newState,
        to: newState.to,
        from: newState.from,
        enteredTo: newState.to,
        fetchingData: false,
      });
      dispatch(clear_stored_reports());
    }
  };

  componentDidUpdate = (prevProps) => {
    const { tabValue } = this.props;
    if (tabValue !== prevProps.tabValue) {
      this.handleClear();
      this.setInitialDates();
      setTimeout(() => {
        this.populateData(tabValue);
      }, 1000);
    }
  };

  handleConditionReport = (inventoryId, dataType) => {
    const { dispatch, toggleConditionReport } = this.props;
    const { to, from } = this.state;
    dispatch(store_reports(this.state[dataType], dataType, to, from));
    toggleConditionReport(inventoryId);
  };

  setInitialDates = () => {
    const { tabValue } = this.props;
    this.now = moment();
    this.tomorrow = new Date(
      this.now.add(1, "days").format("YYYY-MM-DD 04:00:00")
    );
    this.lastWeek = new Date(
      this.now.subtract(8, "days").format("YYYY-MM-DD 04:00:00")
    );
    if (tabValue === 2) {
      this.setState({
        enteredTo: new Date(moment(this.now).endOf("month")),
        from: new Date(this.now.set("date", 1)),
        to: new Date(moment(this.now).endOf("month")),
        fetchingData: true,
      });
    } else {
      this.setState({
        enteredTo: this.tomorrow,
        from: this.lastWeek,
        to: this.tomorrow,
        fetchingData: true,
      });
    }
  };

  populateYears = () => {
    let currentYear = moment().year();
    const years = [];
    for (let i = 0; i < 8; i++) {
      years.push(currentYear - i);
    }
    this.setState({
      years: ["ALL", ...years],
    });
  };

  populateData = async (tabValue) => {
    const {
      vin,
      selectedYear,
      selectedMake,
      selectedModel,
      selectedBidResult,
      includeArbitration,
      from,
      to,
      transportCheckbox,
      performanceData,
      rideshareUser,
    } = this.state;
    const { user } = this.props;
    try {
      let data = [];
      this.setState({ fetchingData: true });
      switch (tabValue) {
        case 0:
          data = await ReportService.getMyPurchaseReport(
            user,
            includeArbitration,
            vin,
            selectedYear,
            selectedModel,
            selectedMake,
            from,
            to
          );
          this.setState({ purchaseData: data, fetchingData: false });
          break;
        case 1:
          data = await ReportService.getMyGroundedReport(
            user,
            vin,
            selectedYear,
            selectedModel,
            selectedMake,
            from,
            to
          );
          this.setState({ groundedData: data, fetchingData: false });
          break;
        case 2:
          data =
            performanceData === null
              ? await ReportService.getDealerPurchasePerformanceReport(
                  user,
                  new Date(moment().set("date", 1)),
                  new Date(moment().endOf("month"))
                )
              : await ReportService.getDealerPurchasePerformanceReport(
                  user,
                  from,
                  to
                );
          let total;
          if (data.length > 0) {
            total = data.pop();
          }

          this.setState({
            performanceDataTotal: total ? [total] : [],
            performanceData: data,
            fetchingData: false,
          });
          break;
        case 3:
          data = await ReportService.getMyBidHistoryReport(
            user,
            vin,
            selectedYear,
            selectedModel,
            selectedMake,
            from,
            to,
            selectedBidResult
          );
          this.setState({
            bidHistoryData: data,
            fetchingData: false,
          });
          break;
        case 4:
          data = await ReportService.getMyInspectionReport(
            user,
            vin,
            selectedYear,
            selectedModel,
            selectedMake,
            from,
            to
          );
          this.setState({
            inspectionData: data,
            fetchingData: false,
          });
          break;
        case 5:
          data = await ReportService.getMyTransportationReport(
            user,
            vin,
            selectedYear,
            selectedModel,
            selectedMake,
            from,
            to,
            transportCheckbox,
            rideshareUser
          );
          this.setState({
            transportationData: data,
            fetchingData: false,
          });
          break;
        case 6:
          data = await ReportService.getMyGroundingsPurchasedByOthersReport(
            user,
            vin,
            selectedYear,
            selectedModel,
            selectedMake,
            from,
            to
          );
          this.setState({
            purchasedByOthersData: data,
            fetchingData: false,
          });
          break;
        case 7:
          data = await ReportService.getMyPurchaseGoalReport(user, from);
          this.setState({
            goalReportData: data.mypurchasedGoalReportDTOCollection,
            goalCountFrntEnd: data.goalCountFrntEnd,
            goalAchieved: data.goalAchieved,
            monthYearFrndEnd: data.monthYearFrndEnd,
            fetchingData: false,
          });
          break;
        default:
          throw Error("Unhandled Tab value in populateData");
      }
    } catch (err) {
      if (vin.length > 0 && vin.length < 17) {
        this.setState({
          status: "The VIN entered is not complete, please enter full VIN",
          fetchingData: false,
        });
      } else {
        this.setState({
          status: err.message,
          fetchingData: false,
        });
      }
    }
  };

  handleVinChange = (event) => {
    this.setState({ vin: event.target.value });
  };

  handleYearChange = (event) => {
    this.setState({ selectedYear: event.target.value });
  };

  handleMakeChange = (event) => {
    const { models } = this.props;
    this.setState({
      selectedMake: event.target.value,
      models: models[event.target.value] ? models[event.target.value] : [],
      selectedModel: "ALL",
    });
  };

  handleModelChange = (event) => {
    this.setState({ selectedModel: event.target.value });
  };

  handleFromDateChange = (date) => {
    this.setState({ from: moment(date) });
  };

  handleToDateChange = (date) => {
    this.setState({ to: moment(date) });
  };

  handleArbitrationChange = (event) => {
    this.setState({ includeArbitration: event.target.checked });
  };

  handleWinsLossesChange = (event) => {
    this.setState({ selectedBidResult: event.target.value });
  };

  handleTransportCheckbox = (event) => {
    this.setState({ transportCheckbox: event.target.checked });
  };

  handleSearch = () => {
    const { tabValue } = this.props;
    this.populateData(tabValue);
  };

  handleClear = () => {
    const { tabValue } = this.props;
    this.now = moment();
    this.tomorrow = new Date(
      this.now.add(1, "days").format("YYYY-MM-DD 04:00:00")
    );
    this.lastWeek = new Date(
      this.now.subtract(8, "days").format("YYYY-MM-DD 04:00:00")
    );
    if (tabValue === 2) {
      this.setState({
        from: new Date(this.now.set("date", 1)),
        to: new Date(moment(this.now).endOf("month")),
        vin: "",
        makes: ["Lexus", "Scion", "Toyota"],
        models: [],
        includeArbitration: false,
        selectedYear: "ALL",
        selectedMake: "ALL",
        selectedModel: "ALL",
        enteredTo: null,
        selectedBidResult: "All",
        transportCheckbox: false,
        fromDateError: false,
        toDateError: false,
        fromDateErrorMessage: "",
        toDateErrorMessage: "",
      });
    } else {
      this.setState({
        from: this.lastWeek,
        to: this.tomorrow,
        vin: "",
        makes: ["Lexus", "Scion", "Toyota"],
        models: [],
        includeArbitration: false,
        selectedYear: "ALL",
        selectedMake: "ALL",
        selectedModel: "ALL",
        enteredTo: null,
        selectedBidResult: "All",
        transportCheckbox: false,
        fromDateError: false,
        toDateError: false,
        fromDateErrorMessage: "",
        toDateErrorMessage: "",
      });
    }
  };

  handleDisplayDateLabel = (tabValue) => {
    const labels = [
      "Purchase Date",
      "Grounding Date",
      "Date",
      "Purchased Date",
      "Insp. Req. Date",
      "Trans. Ordered Date",
      "Sold Date",
      "Date",
    ];
    return labels[tabValue];
  };

  handleDisplayTable = (tabValue) => {
    const { user, accountSetting, purchasedVehicles, dispatch } = this.props;
    const {
      purchaseData,
      groundedData,
      performanceData,
      performanceDataTotal,
      bidHistoryData,
      inspectionData,
      transportationData,
      purchasedByOthersData,
      goalReportData,
      goalCountFrntEnd,
      goalAchieved,
      monthYearFrndEnd,
    } = this.state;
    switch (tabValue) {
      case 0:
        return (
          <MyPurchaseReportTable
            user={user}
            data={purchaseData}
            accountSetting={accountSetting}
            purchasedVehicles={purchasedVehicles}
            dispatch={dispatch}
            toggleConditionReport={this.handleConditionReport}
          />
        );
      case 1:
        return (
          <MyGroundedReportTable
            user={user}
            data={groundedData}
            accountSetting={accountSetting}
            toggleConditionReport={this.handleConditionReport}
          />
        );
      case 2:
        return (
          <>
            <MyDealerPurchaseReportTable
              data={performanceDataTotal}
              isTotal={true}
            />

            <MyDealerPurchaseReportTable
              data={performanceData}
              isTotal={false}
            />
          </>
        );
      case 3:
        return (
          <MyBidHistoryReportTable
            data={bidHistoryData}
            setStatus={this.setStatus}
            toggleConditionReport={this.handleConditionReport}
          />
        );
      case 4:
        return (
          <MyInspectionReportTable
            data={inspectionData}
            toggleConditionReport={this.handleConditionReport}
          />
        );
      case 5:
        return (
          <MyTransportationReportTable
            data={transportationData}
            toggleConditionReport={this.handleConditionReport}
          />
        );
      case 6:
        return (
          <MyUpstreamSaleReportTable
            data={purchasedByOthersData}
            toggleConditionReport={this.handleConditionReport}
          />
        );
      case 7:
        return (
          <MyPurchaseGoalReportTable
            data={goalReportData}
            goalAchieved={goalAchieved}
            goalCountFrntEnd={goalCountFrntEnd}
            monthYearFrndEnd={monthYearFrndEnd}
          />
        );
      default:
        return null;
    }
  };

  handlePrintResponse = (response) => {
    if (response) {
      this.setState({
        printFetch: false,
      });
    } else {
      this.setState({
        printFetch: false,
        status: "No results found, or report could not be printed at this time",
      });
    }
  };

  handleDownload = async (isPdf, transportationData) => {
    const {
      vin,
      selectedYear,
      selectedMake,
      selectedModel,
      includeArbitration,
      from,
      to,
      selectedBidResult,
      transportCheckbox,
      rideshareUser,
    } = this.state;
    const { user, tabValue } = this.props;
    this.setState({ printFetch: true });
    try {
      switch (tabValue) {
        case 0:
          let response = await ReportService.getMyPurchaseReportFile(
            user,
            includeArbitration,
            vin,
            selectedYear,
            selectedModel,
            selectedMake,
            moment(from).format("MM/DD/YYYY 04:00:00"),
            moment(to).add(1, "days").format("MM/DD/YYYY 04:00:00"),
            isPdf
          );
          this.handlePrintResponse(response);
          break;
        case 1:
          let data = await ReportService.getMyGroundedReport(
            user,
            vin,
            selectedYear,
            selectedModel,
            selectedMake,
            from,
            to
          );
          isPdf
            ? this.callGroundedPrintGenerator(data)
            : this.callGroundedExcelGenerator(data);
          break;
        case 2:
          response = await ReportService.getDealerPurchasePerformanceReportFile(
            user,
            from,
            to,
            isPdf
          );
          this.handlePrintResponse(response);
          break;
        case 3:
          response = await ReportService.getMyBidHistoryReportFile(
            user,
            vin,
            selectedYear,
            selectedModel,
            selectedMake,
            from,
            to,
            selectedBidResult,
            isPdf
          );
          this.handlePrintResponse(response);
          break;
        case 4:
          response = await ReportService.getMyInspectionReportFile(
            user,
            vin,
            selectedYear,
            selectedModel,
            selectedMake,
            from,
            to,
            isPdf
          );
          this.handlePrintResponse(response);
          break;
        case 5:
          if (rideshareUser) {
            this.callPrintGenerator(transportationData);
          } else {
            response = await ReportService.getMyTransportationReportFile(
              user,
              vin,
              selectedYear,
              selectedModel,
              selectedMake,
              from,
              to,
              transportCheckbox,
              isPdf
            );
            this.handlePrintResponse(response);
          }

          break;
        case 6:
          response =
            await ReportService.getMyGroundingsPurchasedByOthersReportFile(
              user,
              vin,
              selectedYear,
              selectedModel,
              selectedMake,
              from,
              to,
              isPdf
            );
          this.handlePrintResponse(response);
          break;
        case 7:
          response = await ReportService.getMyPurchaseGoalReportFile(
            user,
            from,
            isPdf
          );
          this.handlePrintResponse(response);
          break;
        default:
          throw Error("Unhandled Tab Value");
      }
    } catch (err) {
      this.setState({
        status: err.message,
        printFetch: false,
      });
    }
  };

  callPrintGenerator = async (transportationData) => {
    let { jsPDF } = require("jspdf");
    let { autoTable } = require("jspdf-autotable");
    let doc = new jsPDF({ orientation: "landscape" });
    const finalData = this.createReportObject(transportationData);
    doc.autoTable({
      head: header,
      body: finalData,
      didDrawPage: function (data) {
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.text("My Transportation Report", data.settings.margin.left + 0, 22);
      },
      margin: { top: 30 },
    });
    doc.save(
      "My_Transport_Report_" + moment().format("MMDDYYYY_hhmmss") + ".pdf"
    );
    this.setState({
      printFetch: false,
    });
    jsPDF = null;
    autoTable = null;
  };

  callGroundedPrintGenerator = async (groundedData) => {
    let { jsPDF } = require("jspdf");
    let { autoTable } = require("jspdf-autotable");
    let doc = new jsPDF({ orientation: "landscape" });
    const finalData = this.createGroundedReportObject(groundedData);
    doc.autoTable({
      head: groundingReportHeader,
      body: finalData,
      didDrawPage: function (data) {
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.text("My Grounding Report", data.settings.margin.left + 0, 22);
      },
      margin: { top: 30 },
    });
    doc.save(
      "My_Grounding_Report_" + moment().format("MMDDYYYY_hhmmss") + ".pdf"
    );
    this.setState({
      printFetch: false,
    });
    jsPDF = null;
    autoTable = null;
  };

  createReportObject = (transportationData) => {
    let data = [];
    transportationData.forEach((element) => {
      let dataElement = [];
      dataElement[0] = moment(element.transOrderedDate).format("MM/DD/YYYY");
      dataElement[1] = element.year;
      dataElement[2] = element.vin;
      dataElement[3] = element.model;
      dataElement[4] = element.trim;
      dataElement[5] = element.color;
      dataElement[6] = Number(element.odometerReading).toLocaleString();
      dataElement[7] = element.transportDestination;
      dataElement[8] =
        element.estPickUpDate !== null
          ? moment(element.estPickUpDate).format("MM/DD/YYYY")
          : null;
      dataElement[9] =
        element.pickUpDate !== null
          ? moment(element.pickUpDate).format("MM/DD/YYYY")
          : null;
      dataElement[10] = element.rideshareProgram;
      dataElement[11] = element.transportStatus;
      data.push(dataElement);
    });
    return data;
  };

  createGroundedReportObject = (groundingData) => {
    let data = [];
    if (groundingData === null) {
      return data;
    }
    groundingData.forEach((element) => {
      let dataElement = [];
      dataElement[0] = moment(element.groundedDate).format("MM/DD/YYYY");
      dataElement[1] =
        element.customerReturnDate !== null
          ? moment(element.customerReturnDate).format("MM/DD/YYYY")
          : null;
      dataElement[2] = element.numberOfKeys;
      dataElement[3] = element.numberOfValetKeys;
      dataElement[4] = element.year;
      dataElement[5] = element.vin;
      dataElement[6] = element.model;
      dataElement[7] = element.accountType;
      dataElement[8] = element.color;
      dataElement[9] = Number(element.odometerReading).toLocaleString();
      dataElement[10] = Number(element.payoffAmount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      dataElement[11] = Number(element.residualAmount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      dataElement[12] =
        element.mbpStatus === "MBP_REQ" ? "Pending" : element.marketPrice;
      dataElement[13] = Number(element.remainingPaymentAmount).toLocaleString(
        "en-US",
        { style: "currency", currency: "USD" }
      );
      dataElement[14] = element.isPaymentGuaranteed;
      dataElement[15] = element.programCode;
      dataElement[16] = element.status;
      data.push(dataElement);
    });
    return data;
  };

  callingMyGroundedReport = async () => {
    const { vin, selectedYear, selectedMake, selectedModel, from, to } =
      this.state;
    const { user } = this.props;
    let response = await ReportService.getMyGroundedReport(
      user,
      vin,
      selectedYear,
      selectedModel,
      selectedMake,
      from,
      to
    );
    return response;
  };

  callGroundedExportGenerator = (groundedData) => {
    let dataSet = [];
    dataSet[0] = [
      { value: "Grounded Date", style: { font: { bold: true } } },
      { value: "Return Date", style: { font: { bold: true } } },
      { value: "Master Keys", style: { font: { bold: true } } },
      { value: "Valet Keys", style: { font: { bold: true } } },
      { value: "Year", style: { font: { bold: true } } },
      { value: "VIN", style: { font: { bold: true } } },
      { value: "Model", style: { font: { bold: true } } },
      { value: "Color", style: { font: { bold: true } } },
      { value: "Payoff", style: { font: { bold: true } } },
      { value: "Residual", style: { font: { bold: true } } },
      { value: "Market", style: { font: { bold: true } } },
      { value: "Remaining Payments", style: { font: { bold: true } } },
      { value: "Guaranteed", style: { font: { bold: true } } },
      { value: "Promo", style: { font: { bold: true } } },
      { value: "Status", style: { font: { bold: true } } },
    ];

    let data = this.createGroundedReportObject(groundedData);
    const multiDataSet = [
      {
        columns: [
          { title: "My Grounding Report", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
        ],
        data: dataSet.concat(data),
      },
    ];

    return multiDataSet;
  };

  callExportGenerator = (transportationData) => {
    let dataSet = [];
    dataSet[0] = [
      { value: "Transportation Ordered", style: { font: { bold: true } } },
      { value: "Year", style: { font: { bold: true } } },
      { value: "VIN", style: { font: { bold: true } } },
      { value: "Model", style: { font: { bold: true } } },
      { value: "Trim", style: { font: { bold: true } } },
      { value: "Color", style: { font: { bold: true } } },
      { value: "Odometer", style: { font: { bold: true } } },
      { value: "Transport Destination", style: { font: { bold: true } } },
      { value: "Est. Pickup Date", style: { font: { bold: true } } },
      { value: "Pickup Date", style: { font: { bold: true } } },
      { value: "Rideshare Program", style: { font: { bold: true } } },
      { value: "Transportation Status", style: { font: { bold: true } } },
    ];
    let data = this.createReportObject(transportationData);

    const multiDataSet = [
      {
        columns: [
          { title: "My Transportation Report", width: { wpx: 160 } },
          { title: "", width: { wpx: 80 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 100 } },
          { title: "", width: { wpx: 100 } },
          { title: "", width: { wpx: 80 } },
          { title: "", width: { wpx: 80 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
          { title: "", width: { wpx: 160 } },
        ],
        data: dataSet.concat(data),
      },
    ];
    return multiDataSet;
  };

  getDealerPurchasePerformance = async () => {
    const { user } = this.props;
    try {
      const performance = await ReportService.getDealerPurchasePerformance(
        user
      );
      this.setState({
        dealerPurchasePerformance: {
          dealerPenetrationStr: performance.dealerPenetrationStr,
          regionalPenetrationStr: performance.regionalPenetrationStr,
          nationalPenetrationStr: performance.nationalPenetrationStr,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  setStatus = (newStatus) => {
    const { status } = this.state;
    if (status) {
      this.setState({
        status: null,
      });
    } else {
      this.setState({
        status: newStatus,
      });
    }
  };

  setTodayDate = (name) => {
    this.now = moment();
    if (name === "from") {
      this.handleDateChangeFrom(
        new Date(this.now.format("YYYY-MM-DD 04:00:00"))
      );
    } else {
      this.handleDateChangeTo(new Date(this.now.format("YYYY-MM-DD 04:00:00")));
    }
  };

  renderDatePicker = () => {
    const { classes, tabValue } = this.props;
    const {
      from,
      to,
      fromDateError,
      toDateError,
      fromDateErrorMessage,
      toDateErrorMessage,
    } = this.state;
    return (
      <>
        <FormControl className={classes.searchParam}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="fromDatePicker"
              label={this.handleDisplayDateLabel(tabValue) + " From:"}
              value={from}
              onChange={this.handleDateChangeFrom}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              error={fromDateError}
              helperText={fromDateErrorMessage}
              className={classes.datePickerWidth}
            />
          </MuiPickersUtilsProvider>
          <Link
            className={classes.todayLink}
            variant="body2"
            onClick={() => this.setTodayDate("from")}
          >
            Today
          </Link>
        </FormControl>
        <FormControl className={classes.searchParam}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="toDatePicker"
              label={this.handleDisplayDateLabel(tabValue) + " To:"}
              value={to}
              onChange={this.handleDateChangeTo}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              error={toDateError}
              helperText={toDateErrorMessage}
              className={classes.datePickerWidth}
            />
          </MuiPickersUtilsProvider>
          <Link
            className={classes.todayLink}
            variant="body2"
            onClick={() => this.setTodayDate("to")}
          >
            Today
          </Link>
        </FormControl>
      </>
    );
  };

  handleDateChangeFrom = (date) => {
    if (isNaN(date) === true || date > this.state.to) {
      this.setState({
        from: date,
        fromDateError: true,
        fromDateErrorMessage: "Please select valid date",
      });
    } else {
      if (date <= this.state.to) {
        this.setState({
          from: date,
          fromDateError: false,
          fromDateErrorMessage: "",
          toDateError: false,
          toDateErrorMessage: "",
        });
      } else {
        this.setState({
          from: date,
          fromDateError: false,
          fromDateErrorMessage: "",
        });
      }
    }
  };

  handleDateChangeTo = (date) => {
    if (isNaN(date) === true || date < this.state.from) {
      this.setState({
        to: date,
        toDateError: true,
        toDateErrorMessage: "Please select valid date",
      });
    } else {
      if (date >= this.state.from) {
        this.setState({
          to: date,
          toDateError: false,
          toDateErrorMessage: "",
          fromDateError: false,
          fromDateErrorMessage: "",
        });
      } else {
        this.setState({
          to: date,
          toDateError: false,
          toDateErrorMessage: "",
        });
      }
    }
  };

  render() {
    const { classes, tabValue } = this.props;
    const {
      vin,
      years,
      makes,
      models,
      selectedBidResult,
      transportCheckbox,
      fetchingData,
      status,
      printFetch,
      fromDateError,
      toDateError,
      rideshareUser,
      transportationData,
      groundedData,
    } = this.state;
    const radioClasses = {
      root: classes.radioRoot,
      colorPrimary: classes.colorPrimary,
      checked: classes.checked,
    };
    return (
      <React.Fragment>
        <div className={classes.upperWrapper}>
          <div className={classes.leftUpperWrapper}>
            {tabValue === 0 && (
              <FormControlLabel
                className={classes.arbitrationLabel}
                control={
                  <Checkbox
                    checked={this.state.includeArbitration}
                    onChange={this.handleArbitrationChange}
                    value="includeArbitration"
                    color="primary"
                    className={classes.arbitrationCheck}
                  />
                }
                label="Include Pending and Resolved Arbitrations?"
              />
            )}
            {tabValue === 5 && (
              <FormControlLabel
                control={
                  <Checkbox
                    id="showVehicle"
                    checked={transportCheckbox}
                    onChange={this.handleTransportCheckbox}
                    color="primary"
                  />
                }
                label="Show vehicles delivered in last 7 business days"
              />
            )}
            {tabValue !== 2 && tabValue !== 7 && (
              <div className={classes.searchParamWrapper}>
                <FormControl className={classes.searchParam}>
                  <InputLabel htmlFor="vin">VIN Search</InputLabel>
                  <Input
                    id="vin"
                    name="vin"
                    className={classes.vinSearch}
                    onChange={this.handleVinChange}
                    value={vin}
                  />
                </FormControl>
                <FormControl className={classes.searchParam}>
                  <InputLabel htmlFor="year">Year</InputLabel>
                  <Select
                    id="year"
                    value={this.state.selectedYear}
                    onChange={(evt) => this.handleYearChange(evt)}
                    name="year"
                    displayEmpty
                  >
                    {years.map((year, index) => (
                      <MenuItem key={index} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.searchParam}>
                  <InputLabel htmlFor="make">Make</InputLabel>
                  <Select
                    id="make"
                    value={this.state.selectedMake}
                    onChange={(evt) => this.handleMakeChange(evt)}
                    name="make"
                    displayEmpty
                  >
                    <MenuItem value={"ALL"}>ALL</MenuItem>
                    {makes.map((make, index) => (
                      <MenuItem key={index} value={make}>
                        {make}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.searchParam}>
                  <InputLabel htmlFor="model">Model</InputLabel>
                  <Select
                    id="model"
                    value={this.state.selectedModel}
                    onChange={(evt) => this.handleModelChange(evt)}
                    name="model"
                    displayEmpty
                  >
                    <MenuItem value={"ALL"}>ALL</MenuItem>
                    {models.map((model, index) => (
                      <MenuItem key={index} value={model}>
                        {model}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {this.renderDatePicker()}
              </div>
            )}
            {tabValue === 2 || tabValue === 7 ? (
              <div className={classes.searchParamWrapper}>
                {this.renderDatePicker()}
              </div>
            ) : null}
            <div className={classes.buttonWrapper}>
              <Button
                id="search-btn"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => this.handleSearch()}
                disabled={fetchingData || fromDateError || toDateError}
              >
                {fetchingData ? <Loading /> : "Search"}
              </Button>
              <Button
                id="clear-btn"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => this.handleClear()}
                disabled={fetchingData}
              >
                Clear
              </Button>
              <Button
                id="print-btn"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => this.handleDownload(true, transportationData)}
                disabled={printFetch || fetchingData}
              >
                {printFetch ? <Loading /> : "Print"}
              </Button>
              {rideshareUser ? (
                <ExcelFile
                  element={
                    <Button
                      id="export-btn"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={printFetch || fetchingData}
                    >
                      {printFetch ? <Loading /> : "Export"}
                    </Button>
                  }
                  filename={
                    "My_Transport_Report_" + moment().format("MMDDYYYY_hhmmss")
                  }
                >
                  <ExcelSheet
                    dataSet={
                      transportationData !== null
                        ? this.callExportGenerator(transportationData)
                        : null
                    }
                    name="My Transportation Report"
                  />
                </ExcelFile>
              ) : tabValue === 1 ? (
                <ExcelFile
                  element={
                    <Button
                      id="export-btn"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={printFetch || fetchingData}
                    >
                      {printFetch ? <Loading /> : "Export"}
                    </Button>
                  }
                  filename={
                    "My_Grounding_Report_" + moment().format("MMDDYYYY_hhmmss")
                  }
                >
                  <ExcelSheet
                    dataSet={
                      groundedData !== null
                        ? this.callGroundedExportGenerator(groundedData)
                        : []
                    }
                    name="My_Grounding_Report"
                  />
                </ExcelFile>
              ) : (
                <Button
                  id="export-btn"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.handleDownload(false)}
                  disabled={printFetch || fetchingData}
                >
                  {printFetch ? <Loading /> : "Export"}
                </Button>
              )}

              {tabValue === 3 && (
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    id="bidResult"
                    name="bidResult"
                    className={classes.group}
                    value={selectedBidResult}
                    onChange={this.handleWinsLossesChange}
                    column
                  >
                    <FormControlLabel
                      value="Wins"
                      control={<Radio classes={radioClasses} color="primary" />}
                      label="Wins"
                    />
                    <FormControlLabel
                      value="Losses"
                      control={<Radio classes={radioClasses} color="primary" />}
                      label="Losses"
                    />
                    <FormControlLabel
                      value="All"
                      control={<Radio classes={radioClasses} color="primary" />}
                      label="All"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </div>
          </div>
          {tabValue === 7 && (
            <div className={classes.rightUpperWrapper}>
              <DealerPurchasePerformance
                dealerPurchasePerformance={this.state.dealerPurchasePerformance}
              />
            </div>
          )}
        </div>
        <div>
          {!fetchingData ? this.handleDisplayTable(tabValue) : <Loading />}
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={3000}
          open={status}
          onClose={() => this.setState({ status: null })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{status}</span>}
          style={{ top: "10rem" }}
        />
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
    storedReports: state.ui.reports,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ReportSearch));
