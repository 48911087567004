import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, InputAdornment, Snackbar } from "@material-ui/core";

import Loading from "../components/Loading";
import usernameIcon from "../assets/login/usernameIcon.svg";
import passwordIcon from "../assets/login/passwordIcon.svg";

import * as LoginServices from "../services/LoginServices";
import * as userActions from "../actions/userActions";
import * as accountSettingActions from "../actions/AccountSetting.actions";
import * as stateInfoActions from "../actions/StateInfo.actions";
import { loadStateInformation } from "../services/UtilityServices";
import { quick_search_key } from "../actions/carActions";
import queryString from "query-string";
import * as userAccess from "../services/UserRoles";
import { loadAuctionLocation } from "../services/UtilityServices";
import { auction_location_data } from "../actions/mobility.actions";
import * as AdvanceSearchServices from "../services/AdvanceSearchServices";
import * as CarfaxService from "../services/CarfaxService";
import {
  advanced_search_criteria_wrapper_data,
  advanced_search_criteria_data,
  togglz_config,
} from "../actions/uiActions";
import {
  USERNAME_PASSWORD_INCORRECT,
  RECAPTCHA_REQUIRED,
  FORGOT_PASSWORD,
  HELP,
  LOGIN_SUCCESS,
} from "../constants/UILabel";

import { ROUTE_URL, API_URL } from "../constants/Constant";
const tddReactAppUrl = process.env.REACT_APP_TDD;
const lddReactAppUrl = process.env.REACT_APP_LDD;
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

const styles = (theme) => ({
  login: {
    display: "flex",
    width: "50%",
    justifyContent: "flex-end",
    marginRight: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginRight: 0,
      position: "relative",
      top: "2rem",
      marginLeft: "8%",
    },
  },
  button: {
    margin: ".5rem",
    marginTop: "3rem",
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.gray,
    boxShadow: "none",
    borderRadius: 0,
    textTransform: "initial",
    fontWeight: "bold",
    height: "35%",
    minWidth: "10.5rem",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
    },
  },
  recaptcha: {
    position: "absolute",
    top: "125px",
  },
  textField: {
    marginLeft: ".5rem",
    marginRight: ".5rem",
    marginTop: ".5rem",
    background: theme.palette.primary.white,
    width: "15rem",
  },
  usernameTextField: {
    marginLeft: ".5rem",
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    width: "15rem",
    marginTop: "1.5rem",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginRight: "1.5rem",
    alignItems: "center",
  },
  fieldIcon: {
    maxHeight: "100%",
    backgroundColor: "rgb(232, 240, 254)",
  },
  link: {
    fontSize: ".75rem",
    alignSelf: "center",
    color: theme.palette.primary.white,
    "&:hover": {
      color: "#d3d3d3",
    },
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.secondary.white,
      fontSize: ".75rem",
      fontWeight: "bold",
      marginTop: "1rem",
    },
  },
  callingInformationIcon: {
    paddingLeft: "1.25rem",
  },
  forgetNCallingInformation: {
    display: "flex",
    fontWeight: "bold",
  },
  inputAdornment: {
    marginTop: "0 !important",
    height: "1.2rem",
  },
});

const recaptchaRef = React.createRef();

export class LoginForm extends Component {
  state = {
    name: "",
    password: "",
    error: null,
    failedLoginAttempts: null,
    recaptchaRequired: false,
    recaptchaToken: "",
  };

  loadStateInformation = async () => {
    try {
      const stateInfo = await loadStateInformation();
      return stateInfo;
    } catch (err) {
      console.log("Error occured :", err);
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  throwError = (error) => {
    this.setState({
      error,
      loading: false,
    });
  };
  handleClose = () => {
    this.setState({
      error: null,
    });
  };

  checkUserIdInLocalstroage = (userId, pageTheme) => {
    const getUserId = window.localStorage.getItem("userID");
    if (getUserId && getUserId !== userId) {
      window.localStorage.removeItem("userID");
      window.localStorage.removeItem("refresh_token");
      window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("Authorization");
      window.localStorage.removeItem("vin");
      this.removeCarfaxCookies(pageTheme);
    }
    window.localStorage.setItem("userID", userId);
  };

  removeCarfaxCookies = (pageTheme) => {
    CarfaxService.carfaxReportLogout();
    var reactUrl;
    if (pageTheme === "Toyota") {
      reactUrl = tddReactAppUrl;
    } else {
      reactUrl = lddReactAppUrl;
    }
    var win = window.open(reactUrl + "/carfax/carfaxLogout.html", "_blank");
    setTimeout(function () {
      win.close();
    }, 2);
  };

  handleLogin = async () => {
    const { history, dispatch, pageTheme } = this.props;
    const { name, password, recaptchaToken } = this.state;
    this.setState({ loading: true });
    try {
      const user = await LoginServices.login(name, password, recaptchaToken);
      if (user.message === LOGIN_SUCCESS) {
        const url = window.location.href;
        const re = /(lexus|ldd)/gm;
        this.checkUserIdInLocalstroage(user.userId, pageTheme);
        if (user.franchise === "LBSG" && !re.test(url)) {
          window.location.replace(API_URL.LEXUS_DEALER_DIRECT);
        }
        const accountSetting = await LoginServices.loadAccountSetting(
          user.userId
        );
        const stateInfo = await this.loadStateInformation();
        dispatch(
          stateInfoActions.loadStateInfoSuccess(
            stateInfo.stateDTOs.filter((state) => state.countryId === 1)
          )
        );
        dispatch(userActions.loginSuccess(user));
        dispatch(togglz_config(user.togglzMapForTDD));
        dispatch(
          accountSettingActions.loadAccountSettingSuccess(accountSetting)
        );
        if (userAccess.isRideshareUser(user.userRoles)) {
          this.loadSearchCriteriaWrapper();
          this.loadAuctionLocation();
          this.loadSearchCriteria();
        }
        const values = queryString.parse(history.location.search);
        if (values.vin) {
          dispatch(quick_search_key(values.vin));
          history.replace(ROUTE_URL.ADVANCESEARCH);
        } else {
          history.replace(
            userAccess.isRideshareUser(user.userRoles)
              ? ROUTE_URL.MOBILITY
              : ROUTE_URL.HOME
          );
        }
      } else if (user.status === 503) {
        this.throwError(user.message);
      } else {
        if (recaptchaRef.current != null) {
          recaptchaRef.current.reset();
        }

        this.setState({ failedLoginAttempts: user.failedLoginAttempts });
        console.log("LOGIN FAIL");
        dispatch(userActions.loginFail());

        this.throwError(USERNAME_PASSWORD_INCORRECT);
      }
    } catch (err) {
      this.setState({ loading: false });
      dispatch(accountSettingActions.loadAccountSettingFail());
      console.log(err);
    }
  };

  handleChallenge = () => {
    this.setState({ recaptchaToken: recaptchaRef.current.getValue() });
    this.setState({ recaptchaRequired: false });
  };

  loadSearchCriteriaWrapper = async () => {
    const { dispatch } = this.props;
    const advancedSearchCriteriaWrapperConst =
      await AdvanceSearchServices.advancedSearchCriteriaWrapper();
    dispatch(
      advanced_search_criteria_wrapper_data(advancedSearchCriteriaWrapperConst)
    );
  };

  loadSearchCriteria = async () => {
    const { dispatch } = this.props;
    const advancedSearchCriteriaConst =
      await AdvanceSearchServices.advancedSearchCriteria();
    dispatch(advanced_search_criteria_data(advancedSearchCriteriaConst));
  };

  loadAuctionLocation = async () => {
    const { dispatch } = this.props;
    const location = await loadAuctionLocation();
    dispatch(auction_location_data(location));
  };

  render() {
    const { classes } = this.props;

    const { error, loading } = this.state;

    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: "rgb(232, 240, 254)",
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    return (
      <div className={classes.login}>
        <div className={classes.wrapper}>
          <TextField
            id="filled-name"
            className={classes.usernameTextField}
            value={this.state.name}
            onChange={this.handleChange("name")}
            margin="normal"
            variant="filled"
            placeholder="Username"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                this.handleLogin();
              }
            }}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment
                  className={classes.inputAdornment}
                  position="start"
                >
                  <img
                    className={classes.fieldIcon}
                    src={usernameIcon}
                    alt="Username Icon"
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="filled-password"
            className={classes.textField}
            value={this.state.password}
            onChange={this.handleChange("password")}
            margin="normal"
            variant="filled"
            type="password"
            placeholder="Password"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                this.handleLogin();
              }
            }}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment
                  className={classes.inputAdornment}
                  position="start"
                >
                  <img
                    className={classes.fieldIcon}
                    src={passwordIcon}
                    alt="Password Icon"
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.wrapper}>
          <Button
            id="loginButton"
            variant="contained"
            className={classes.button}
            onClick={!loading ? (evt) => this.handleLogin() : null}
            disabled={this.state.recaptchaRequired}
          >
            {!loading ? "Login" : <Loading />}
          </Button>
          <div className={classes.forgetNCallingInformation}>
            <div>
              <Link className={classes.link} to={ROUTE_URL.FORGOT_PASSWORD}>
                {FORGOT_PASSWORD}{" "}
              </Link>
            </div>
            <div className={classes.callingInformationIcon}>
              <Link className={classes.link} to={ROUTE_URL.CALLING_INFORMATION}>
                {HELP}
              </Link>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={error !== null}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{error}</span>}
        />
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    pageTheme: state.ui.pageTheme,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(LoginForm));
