import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Snackbar,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignContent: "flex-end",
    marginLeft: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
    },
  },
  formControl: {
    display: "flex",
  },
  group: {
    margin: `.5rem 0`,
    display: "flex",
    width: "17rem",
    [theme.breakpoints.down("sm")]: {
      margin: "-6px",
    },
  },
  radioRoot: {
    color: theme.palette.primary.gray,
  },
  radio: {
    width: "auto",
  },
  checked: {},
  colorPrimary: {
    "&$checked": {
      color: theme.palette.grounding.main,
    },
  },
  textFieldError: {
    fontSize: "12px",
    textAlign: "left",
    color: "#f44336",
  },
  errorSnack: {
    marginTop: "2.6rem",
  },
});

class KeySelection extends Component {
  render() {
    const { classes, keys, keyType, handleChange } = this.props;
    const errorMessage =
      "Missing keys are a chargeable Excessive Wear and Tear item to the customer and they will be billed for missing keys.";
    const masterError = keyType === "masterKeys" && parseInt(keys, 10) < 2;
    const radioClasses = {
      root: classes.radioRoot,
      colorPrimary: classes.colorPrimary,
      checked: classes.checked,
    };

    return (
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="keys"
            name={keyType}
            className={classes.group}
            value={keys}
            onChange={handleChange(keyType)}
            row
          >
            <Typography className={classes.textFieldError}>
              {masterError ? errorMessage : ""}
            </Typography>
            <FormControlLabel
              id="0"
              value={"0"}
              control={
                <Radio
                  classes={radioClasses}
                  color="primary"
                  className={classes.radio}
                />
              }
              label="0"
              labelPlacement="end"
            />
            <FormControlLabel
              id={`${keyType}1`}
              value={"1"}
              control={
                <Radio
                  classes={radioClasses}
                  color="primary"
                  className={classes.radio}
                />
              }
              label="1"
              labelPlacement="end"
            />
            <FormControlLabel
              id={`${keyType}2`}
              value={"2"}
              control={
                <Radio
                  classes={radioClasses}
                  color="primary"
                  className={classes.radio}
                />
              }
              label="2"
              labelPlacement="end"
            />
            <FormControlLabel
              id={`${keyType}3`}
              value={"3"}
              control={
                <Radio
                  classes={radioClasses}
                  color="primary"
                  className={classes.radio}
                />
              }
              label="3"
              labelPlacement="end"
            />
            <FormControlLabel
              id={`${keyType}4`}
              value={"4"}
              control={
                <Radio
                  classes={radioClasses}
                  color="primary"
                  className={classes.radio}
                />
              }
              label="4"
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

KeySelection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KeySelection);
