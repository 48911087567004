import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";

import KeySelection from "../components/KeySelection";
import LocationDisplay from "../components/LocationDisplay";
import Location from "../components/Location";
import {
  ADDRESS_NOT_AVAILABLE,
  VERIFY_PICKUP_RETURN_DETAILS,
  PICKUP_LOCATION,
  ADD_VEHICLE_LOCATION,
  SPECIAL_INSTRUCTIONS,
  MASTER_KEYS_RETURNED,
  VALET_KEYS_RETURNED,
  IS_THE_VEHICLE_DRIVABLE,
} from "../constants/UILabel";
const styles = (theme) => ({
  formControl: {
    margin: ".5rem",
    minWidth: "10rem",
    height: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      height: "2rem",
    },
  },
  streetWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    margin: "auto",
  },
  textField: {
    display: "flex",
    margin: ".5rem",
    justifyContent: "right",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "92%",
    },
  },
  leftWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightWrapper: {
    display: "flex",
    width: "49%",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "51%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: ".5rem",
    },
  },
  keyReturnWrapper: {
    display: "flex",
    height: "2rem",
    alignItems: "center",
    marginBottom: "1rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  textFieldRight: {
    textAlign: "left",
    width: "12rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".75rem",
      marginLeft: ".5rem",
    },
  },
  textFieldRightError: {
    textAlign: "left",
    color: "#f44336",
  },
  selectWrapper: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    width: "22rem",
    marginBottom: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "0.5rem",
      fontSize: ".7rem",
      flexDirection: "column",
    },
  },
  select: {
    fontSize: ".7rem",
  },
  keyWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  adornment: {
    width: "9.5rem",
  },
  switchWrapper: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    textAlign: "center",
  },
  pickupTitle: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.primary.gray,
    height: "2rem",
    width: "15rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
      fontSize: ".75rem",
      height: "1rem",
    },
  },
  locationHeader: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.primary.gray,
    height: "2rem",
    width: "15rem",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: ".5rem",
      fontSize: "1rem",
      color: theme.palette.grounding.main,
      fontWeight: "600",
    },
  },
  group: {
    margin: `.5rem 0`,
    display: "flex",
    width: "15rem",
    [theme.breakpoints.down("sm")]: {
      margin: "-6px",
    },
  },
  radioRoot: {
    color: theme.palette.primary.gray,
  },
  radio: {
    width: "auto",
  },
  radioWrapper: {
    marginLeft: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
    },
  },
  colorPrimary: {
    "&$checked": {
      color: theme.palette.grounding.main,
    },
  },
  checked: {},
  errorSnack: {
    marginTop: "7rem",
    [theme.breakpoints.down("sm")]: {
      top: "6.5rem",
    },
  },
  pickupWrapper: {
    display: "flex",
    marginTop: "2rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      margin: 0,
    },
  },
  instructionWrapper: {
    display: "flex",
    alignSelf: "center",
    width: "22rem",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      margin: 0,
      width: "100%",
      marginLeft: ".5rem",
    },
  },
  lowerSnackbar: {
    top: "11rem",
    [theme.breakpoints.down("sm")]: {
      top: "9.5rem",
    },
  },
});

export class KeysLocation extends Component {
  state = {
    badKeySubmit: false,
    badVehicleSubmit: false,
  };

  populateLocations = () => {
    const { accountSetting } = this.props;
    if (accountSetting.custLocations[0]) {
      return accountSetting.custLocations.map((custLocation) => {
        return (
          <MenuItem key={custLocation.locationId} value={custLocation}>
            <em>{custLocation.locationDTO.name}</em>
          </MenuItem>
        );
      });
    } else {
      return (
        <MenuItem value="">
          <em>{ADDRESS_NOT_AVAILABLE}</em>
        </MenuItem>
      );
    }
  };

  render() {
    const {
      classes,
      handleChange,
      custLocation,
      user,
      masterKeys,
      valetKeys,
      vehicleDrivable,
      specialInstructions,
      badLocation,
      badMasterKeySubmit,
      badValetKeySubmit,
      badVehicleSubmit,
    } = this.props;
    const radioClasses = {
      root: classes.radioRoot,
      colorPrimary: classes.colorPrimary,
      checked: classes.checked,
    };
    return (
      <div className={classes.keyWrapper}>
        <div className={classes.pickupWrapper}>
          <div className={classes.leftWrapper}>
            {custLocation ? (
              <Typography variant="h4" className={classes.locationHeader}>
                {VERIFY_PICKUP_RETURN_DETAILS}
              </Typography>
            ) : null}
            <div className={classes.selectWrapper}>
              <Typography className={classes.pickupTitle}>
                {PICKUP_LOCATION}
              </Typography>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  value={custLocation}
                  onChange={handleChange("custLocation")}
                  className={classes.select}
                  inputProps={{
                    style: {
                      height: "auto",
                    },
                  }}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      name="location"
                      id="outlined-location"
                    />
                  }
                  error={badLocation}
                >
                  {this.populateLocations()}
                  {user.dealerAdmin ? (
                    <MenuItem id="add-vehicle-location" value="">
                      <em>{ADD_VEHICLE_LOCATION}</em>
                    </MenuItem>
                  ) : null}
                </Select>
              </FormControl>
            </div>
            {custLocation.customerId ? (
              <LocationDisplay custLocation={custLocation} />
            ) : null}
            {!custLocation ? <Location inGrounding={true} /> : null}
            {custLocation ? (
              <div className={classes.instructionWrapper}>
                <Typography className={classes.pickupTitle}>
                  {SPECIAL_INSTRUCTIONS}
                </Typography>
                <TextField
                  id="outlined-special-instructions"
                  multiline
                  rowsMax="4"
                  value={specialInstructions}
                  onChange={handleChange("specialInstructions")}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  placeholder="Enter specific instructions"
                  InputProps={{
                    style: {
                      height: "4rem",
                    },
                    inputProps: {
                      style: {
                        fontSize: ".5rem",
                        height: "4rem",
                      },
                    },
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className={classes.rightWrapper}>
            <div className={classes.keyReturnWrapper}>
              <Typography className={classes.textFieldRight}>
                {MASTER_KEYS_RETURNED}
              </Typography>
              <KeySelection
                keys={masterKeys}
                keyType={"masterKeys"}
                handleChange={handleChange}
              />
            </div>
            <div className={classes.keyReturnWrapper}>
              <Typography className={classes.textFieldRight}>
                {VALET_KEYS_RETURNED}
              </Typography>
              <KeySelection
                keys={valetKeys}
                keyType={"valetKeys"}
                handleChange={handleChange}
              />
            </div>
            <div className={classes.keyReturnWrapper}>
              <Typography className={classes.textFieldRight}>
                {IS_THE_VEHICLE_DRIVABLE}
              </Typography>
              <FormControl
                component="fieldset"
                className={classes.radioWrapper}
              >
                <RadioGroup
                  aria-label="drivable"
                  name={vehicleDrivable}
                  className={classes.group}
                  value={vehicleDrivable}
                  onChange={handleChange("vehicleDrivable")}
                  row
                >
                  <FormControlLabel
                    id="yes"
                    value={"true"}
                    control={
                      <Radio
                        classes={radioClasses}
                        color="primary"
                        className={classes.radio}
                      />
                    }
                    label="Yes"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    id="no"
                    value={"false"}
                    control={
                      <Radio
                        classes={radioClasses}
                        color="primary"
                        className={classes.radio}
                      />
                    }
                    label="No"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {this.props.children}
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className={classes.errorSnack}
          open={badMasterKeySubmit || badValetKeySubmit}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              {"Please make a selection for both key sets"}
            </span>
          }
        />
        <Snackbar
          className={classes.lowerSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={badVehicleSubmit}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              {"Please indicate if the vehicle is drivable"}
            </span>
          }
        />
      </div>
    );
  }
}

KeysLocation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    accountSetting: state.accountSetting,
    user: state.user,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(KeysLocation));
