export const MOBILITY_SEARCH_CRITERIA = "MOBILITY_SEARCH_CRITERIA";
export const MOBILITY_SAVED_SEARCH = "MOBILITY_SAVED_SEARCH";
export const AUCTION_LOCATION_DATA = "AUCTION_LOCATION_DATA";

export const mobility_search_criteria = (mobilitySearchCriteria) => {
  return {
    type: MOBILITY_SEARCH_CRITERIA,
    mobilitySearchCriteria,
  };
};

export const mobility_saved_search = (mobilitySavedSearch) => {
  return {
    type: MOBILITY_SAVED_SEARCH,
    mobilitySavedSearch,
  };
};

export const auction_location_data = (auctionLocationData) => {
  return {
    type: AUCTION_LOCATION_DATA,
    auctionLocationData,
  };
};
