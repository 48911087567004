import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Button, Typography } from "@material-ui/core";
import renderHTML from "react-render-html";

const styles = (theme) => ({
  label: {
    margin: "1rem",
    marginRight: "0",
    fontWeight: "bold",
  },
  labelText: {
    fontWeight: "bold",
  },
  vehicleInfoContainer: {
    width: "auto",
    display: "block",
    margin: ".5rem",
    textAlign: "center",
  },
  vehicleInfoVINEntryContainer: {
    margin: ".5rem",
    display: "inline-block",
    width: "auto",
    marginTop: ".5rem",
  },
  infoParentWrapper: {
    margin: "1rem",
  },
  infoWrapper: {
    margin: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  h1Padding: {
    margin: "1rem",
  },
  paper: {
    paddingTop: ".5rem",
    alignItems: "center",
    width: "98%",
    borderRadius: 0,
    margin: "auto",
    fontSize: ".8rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  paperCR: {
    paddingTop: ".5rem",
    alignItems: "center",
    width: "55%",
    borderRadius: 0,
    margin: "auto",
    fontSize: ".8rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  formControl: {
    minWidth: 200,
  },
  tableCellBorder: {
    borderBottom: 0,
  },
  messageContainer: {
    alignItems: "center",
    width: "80%",
    margin: "auto",
  },
  paperWrapper: {
    alignItems: "center",
    margin: "auto",
  },
  programInfoHighlight: {
    display: "inline",
    color: "#CC0000",
  },
  textField: {
    marginLeft: ".5rem",
    marginRight: ".5rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      fontSize: ".5rem",
    },
  },
  textfieldWrapper: {
    margin: "auto",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  buttonWrapper: {
    textAlign: "right",
  },
  button: {
    margin: ".5rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100px",
  },
  gpWrapper: {
    margin: ".5rem",
  },
});

export class LCPEntryForm extends Component {
  removeDiv = (text) => {
    let newText = text.replace("<div>", "");
    newText = newText.replace("&nbsp;", "");
    return newText.replace("</div>", "");
  };

  removeSpan = (text) => {
    let newText = text.replace('<span style="font-size: 11.2px;">', "");
    return newText.replace("</span>", "");
  };

  render() {
    const { classes, programDTOInfo, toggleLCPCar, from } = this.props;
    return (
      <Paper
        className={from && from === "CR" ? classes.paperCR : classes.paper}
      >
        <div>
          <div className={classes.infoWrapper}>
            <h3>
              <Typography>
                <strong>Promotion:</strong> {programDTOInfo.programName}
              </Typography>
              <Typography>
                <strong>Code:</strong> {programDTOInfo.programCode}
              </Typography>
            </h3>
            <div>
              {renderHTML(
                this.removeDiv(
                  this.removeSpan(programDTOInfo.contentManagementDTO.content)
                )
              )}
            </div>
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              id="addLocationBtn"
              className={classes.button}
              onClick={(evt) => toggleLCPCar()}
            >
              Close
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(LCPEntryForm);
