const API_URL = process.env.REACT_APP_API_URL;

export async function getPriceQuoteDetailByVin(vin) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(
      `${API_URL}/earlyTerm/getVinDetailsForPriceQuote?vin=${vin}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return {
        status: response.status,
        message: "The server is having issues, please try again later.",
      };
    }
  } catch (err) {
    return {
      status: false,
      message: err.message,
    };
  }
}

export async function getMbpPriceRange(vin, inventoryID, dealerId, mileage) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      vin: vin,
      inventoryId: inventoryID,
      dealerId: dealerId,
      mileage: mileage,
      saleChannel: "TDD",
      salePhase: "Grounding",
    }),
  };

  try {
    const millisecondsToWaitBeforeAbortingRequest = 30000;
    const controller = new AbortController();
    setTimeout(
      () => controller.abort(),
      millisecondsToWaitBeforeAbortingRequest
    );
    const response = await fetch(`${API_URL}/earlyTerm/getMbpPriceRange`, {
      ...options,
      signal: controller.signal,
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return {
        status: response.status,
        message: "The server is having issues, please try again later.",
        failedToFetch: true,
      };
    }
  } catch (err) {
    return {
      status: false,
      message: err.message,
      failedToFetch: true,
    };
  }
}
