import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Typography,
  FormControl,
  Button,
  Select,
  MenuItem,
  TextField,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import { submitNewLocation } from "../services/UtilityServices";
import * as AdminService from "../services/AdminServices";
import * as LoginServices from "../services/LoginServices";
import * as Actions from "../actions/AccountSetting.actions";
import { validateEmail, validatePhone } from "../services/ValidationServices";

const styles = (theme) => ({
  container: {
    width: "auto",
    height: "90%",
  },
  sectionContainer: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
  },
  inputLabel: {
    fontSize: ".8rem",
    padding: ".4rem",
    textAlign: "center",
    alignItems: "center",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.gray,
    width: "7rem",
    display: "inline-block",
    marginTop: ".5rem",
  },
  preferenceContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  inputField: {
    fontSize: ".8rem",
    padding: ".6rem",
    display: "inline-block",
    alignItems: "center",
    width: "11rem",
  },
  selectInput: {
    fontSize: ".8rem",
    minWidth: "10rem",
    textAlign: "center",
  },
  emailSettings: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
  },
  leftWrapper: {},
  rightWrapper: {},
  fieldWrapper: {
    display: "flex",
    width: "20rem",
    height: "2rem",
    margin: ".5rem",
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "7rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectFieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "6.7rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    margin: 0,
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    height: "2rem",
    borderRadius: 0,
  },
  formControl: {
    display: "flex",
    height: "2rem",
    flexDirection: "row",
    width: "20rem",
    margin: ".5rem",
  },
  button: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.gray,
    fontSize: theme.font.small,
    fontWeight: "bold",
    width: "4rem",
    color: theme.palette.primary.white,
  },
  buttonWrapper: {
    display: "flex",
    margin: "1rem",
    width: "10rem",
    justifyContent: "space-around",
    position: "absolute",
    left: "42%",
  },
  systemMessage: {
    marginTop: "1rem",
    marginLeft: ".5rem",
    fontSize: theme.font.small,
  },
  errorMessage: {},
  errorSnackBar: {
    marginTop: "7rem",
  },
  rowContainer: {
    display: "flex",
  },
  menuItem: {
    fontSize: ".8rem",
  },
  "@global": {
    "label + .MuiInput-formControl": {
      marginTop: 0,
      width: "12rem",
    },
  },
});

export class DealerLocation extends Component {
  state = {
    locationName: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    phone: "",
    fax: "",
    timeZoneId: "",
    stateId: "",
    contactName1: "",
    contactPhone1: "",
    contactEmail1: "",
    contactName2: "",
    contactPhone2: "",
    contactEmail2: "",
    errorMessage: null,
    isPrimary: false,
    dealerId: "",
  };

  componentDidMount = () => {
    const { stateInfo, location, addLocation } = this.props;
    if (!addLocation) {
      this.setState({
        dealerId: location.locationId,
        locationName: location.locationName,
        address1: location.address1,
        address2: location.address2 ? location.address2 : "",
        city: location.city,
        zip: location.zip,
        phone: location.phone,
        fax: location.fax ? location.fax : "",
        timeZoneId: location.timeZoneId,
        stateId: stateInfo.find((state) => state.stateCode === location.state)
          .stateId,
        contactName1: location.contactName1,
        contactPhone1: location.contactPhone1,
        contactEmail1: location.contactEmail1 ? location.contactEmail1 : "",
        contactName2: location.contactName2 ? location.contactName2 : "",
        contactPhone2: location.contactPhone2 ? location.contactPhone2 : "",
        contactEmail2: location.contactEmail2 ? location.contactEmail2 : "",
        errorMessage: location.errorMessage,
        isPrimary: location.isPrimary,
      });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleStateId = (event) => {
    this.setState({
      stateId: event.target.value,
    });
  };

  updateAccountSettings = async () => {
    const { dispatch } = this.props;
    const { user } = this.props;
    const accountSetting = await LoginServices.loadAccountSetting(user.userId);
    dispatch(Actions.loadAccountSettingSuccess(accountSetting));
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      locationName,
      address1,
      city,
      phone,
      stateId,
      zip,
      contactName1,
      contactPhone1,
      contactPhone2,
      contactName2,
      contactEmail2,
      contactEmail1,
      timeZoneId,
    } = this.state;
    if (
      !locationName ||
      !address1 ||
      !city ||
      !phone ||
      !stateId ||
      !zip ||
      !contactName1 ||
      !contactPhone1 ||
      !timeZoneId
    ) {
      this.setState({
        errorMessage: "Please populate all required fields.",
      });
      return;
    }
    if (!validatePhone(phone)) {
      this.setState({
        errorMessage: "Please enter a valid phone number for this location.",
      });
      return;
    }
    if (!validatePhone(contactPhone1)) {
      this.setState({
        errorMessage:
          "Please enter a valid phone number for the primary contact.",
      });
      return;
    }
    if (!validateEmail(contactEmail1)) {
      this.setState({
        errorMessage:
          "Please enter a valid email address for the primary contact.",
      });
      return;
    }
    if (contactPhone2 !== "" || contactName2 !== "" || contactEmail2 !== "") {
      if (!validateEmail(contactEmail2)) {
        this.setState({
          errorMessage:
            "Please enter a valid email address for the secondary contact.",
        });
        return;
      }
      if (!validatePhone(contactPhone2)) {
        this.setState({
          errorMessage:
            "Please enter a valid phone number for the secondary contact.",
        });
        return;
      }
      if (contactName2 === "") {
        this.setState({
          errorMessage:
            "Please enter a name for the secondary contact, or clear the other fields.",
        });
        return;
      }
    }

    if (this.props.addLocation) {
      try {
        const response = await submitNewLocation(this.state, this.props.user);
        if (
          response.message === "Location added" &&
          response.status === "true"
        ) {
          this.updateAccountSettings();
          this.props.cancelEditLocation();
        }
      } catch (err) {
        this.setState({ errorMessage: "Error occured :" + err.message });
      }
    } else {
      try {
        const response = await AdminService.updateDealerLocation(
          this.state,
          this.props.user
        );
        if (response === "success") {
          this.updateAccountSettings();
          this.props.cancelEditLocation();
        }
      } catch (err) {
        this.setState({ errorMessage: "Error occured : " + err.message });
      }
    }
  };

  render() {
    const { classes, addLocation, timeZoneOptions, stateInfo } = this.props;
    const {
      locationName,
      address1,
      address2,
      city,
      stateId,
      zip,
      phone,
      fax,
      timeZoneId,
      contactName1,
      contactPhone1,
      contactEmail1,
      contactName2,
      contactPhone2,
      contactEmail2,
      errorMessage,
    } = this.state;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    return (
      <div className={classes.container}>
        <Typography variant="h5" component="h3">
          {addLocation ? "Add Location" : "Manage Location"}
        </Typography>
        <form id="form" className={classes.form}>
          <div id="location" className={classes.sectionContainer}>
            <div className={classes.rowContainer}>
              <div className={classes.preferenceContainer}>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Location Name</strong>
                  </Typography>
                  <TextField
                    id="locationName"
                    placeholder="Required Field"
                    className={classes.textField}
                    value={locationName}
                    onChange={this.handleChange("locationName")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="locationName"
                    InputProps={InputProps}
                    autoFocus
                    required
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Address 1</strong>
                  </Typography>
                  <TextField
                    id="address1"
                    placeholder="Required Field"
                    className={classes.textField}
                    value={address1}
                    onChange={this.handleChange("address1")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="address1"
                    InputProps={InputProps}
                    required
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Address 2</strong>
                  </Typography>
                  <TextField
                    id="address2"
                    className={classes.textField}
                    value={address2}
                    onChange={this.handleChange("address2")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="address2"
                    InputProps={InputProps}
                  />
                </div>
              </div>
              <div className={classes.preferenceContainer}>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>City</strong>
                  </Typography>
                  <TextField
                    id="city"
                    placeholder="Required Field"
                    className={classes.textField}
                    value={city}
                    onChange={this.handleChange("city")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="city"
                    InputProps={InputProps}
                    required
                  />
                </div>
                <FormControl className={classes.formControl}>
                  <label className={classes.selectFieldLabel}>
                    <strong>State</strong>
                  </label>
                  <Select
                    id="stateId"
                    className={classes.selectInput}
                    value={stateId}
                    onChange={this.handleChange("stateId")}
                    name="stateId"
                    displayEmpty
                    autoComplete
                  >
                    <MenuItem value="" className={classes.menuItem}>
                      Required Field
                    </MenuItem>
                    {stateInfo.map((state) => (
                      <MenuItem
                        className={classes.menuItem}
                        key={state.stateId}
                        value={state.stateId}
                      >
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Zip</strong>
                  </Typography>
                  <TextField
                    id="zip"
                    placeholder="Required Field"
                    className={classes.textField}
                    value={zip}
                    onChange={this.handleChange("zip")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="zip"
                    InputProps={InputProps}
                    required
                  />
                </div>
              </div>
              <div className={classes.preferenceContainer}>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Phone</strong>
                  </Typography>
                  <TextField
                    id="phone"
                    placeholder="Required Field"
                    className={classes.textField}
                    value={phone}
                    onChange={this.handleChange("phone")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="phone"
                    InputProps={InputProps}
                    required
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Fax</strong>
                  </Typography>
                  <TextField
                    id="fax"
                    className={classes.textField}
                    value={fax}
                    onChange={this.handleChange("fax")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="fax"
                    InputProps={InputProps}
                  />
                </div>
                <FormControl className={classes.formControl}>
                  <label className={classes.selectFieldLabel}>
                    <strong>Time Zone</strong>
                  </label>
                  <Select
                    id="timeZoneId"
                    className={classes.selectInput}
                    value={timeZoneId}
                    onChange={this.handleChange("timeZoneId")}
                    name="timeZoneId"
                    displayEmpty
                  >
                    <MenuItem value="" className={classes.menuItem}>
                      Required Field
                    </MenuItem>
                    {timeZoneOptions.map((timezone) => (
                      <MenuItem
                        className={classes.menuItem}
                        key={timezone.value}
                        value={timezone.value}
                      >
                        {timezone.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <Typography className={classes.systemMessage}>
            Please provide contact information for the individuals you wish to
            be contacted by the transportation company.
          </Typography>
          <div id="contact" className={classes.sectionContainer}>
            <div className={classes.rowContainer}>
              <div className={classes.preferenceContainer}>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Contact Name 1</strong>
                  </Typography>
                  <TextField
                    id="contactName1"
                    placeholder="Required Field"
                    className={classes.textField}
                    value={contactName1}
                    onChange={this.handleChange("contactName1")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="phcontactName1one"
                    InputProps={InputProps}
                    required
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Contact Phone 1</strong>
                  </Typography>
                  <TextField
                    id="contactPhone1"
                    placeholder="Required Field"
                    className={classes.textField}
                    value={contactPhone1}
                    onChange={this.handleChange("contactPhone1")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="contactPhone1"
                    InputProps={InputProps}
                    required
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Contact Email 1</strong>
                  </Typography>
                  <TextField
                    id="contactEmail1"
                    placeholder="Required Field"
                    className={classes.textField}
                    value={contactEmail1}
                    onChange={this.handleChange("contactEmail1")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="contactEmail1"
                    InputProps={InputProps}
                    required
                  />
                </div>
              </div>
              <div className={classes.preferenceContainer}>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Contact Name 2</strong>
                  </Typography>
                  <TextField
                    id="contactName2"
                    className={classes.textField}
                    value={contactName2}
                    onChange={this.handleChange("contactName2")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="contactName2"
                    InputProps={InputProps}
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Contact Phone 2</strong>
                  </Typography>
                  <TextField
                    id="contactPhone2"
                    className={classes.textField}
                    value={contactPhone2}
                    onChange={this.handleChange("contactPhone2")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="contactPhone2"
                    InputProps={InputProps}
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Contact Email 2</strong>
                  </Typography>
                  <TextField
                    id="contactEmail2"
                    className={classes.textField}
                    value={contactEmail2}
                    onChange={this.handleChange("contactEmail2")}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    name="contactEmail2"
                    InputProps={InputProps}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              id="submitButton"
              variant="contained"
              className={classes.button}
              onClick={(evt) => this.handleSubmit(evt)}
            >
              {addLocation ? "Add" : "Update"}
            </Button>
            <Button
              id="cancelButton"
              variant="contained"
              onClick={this.props.cancelEditLocation}
              className={classes.button}
            >
              Cancel
            </Button>
          </div>
        </form>
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          className={classes.errorSnackBar}
          open={errorMessage}
          autoHideDuration={3000}
          onClose={() => this.setState({ errorMessage: null })}
        >
          <SnackbarContent
            className={classes.errorMessage}
            message={errorMessage}
          />
        </Snackbar>
      </div>
    );
  }
}

DealerLocation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    stateInfo: state.stateInfo,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(DealerLocation));
