import React, { Component } from "react";
import PropTypes from "prop-types";
import Viewer from "react-viewer";

import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

import Moment from "react-moment";

import { returnAutoGrade } from "../services/HelperServices";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import ReactSVG from "react-svg";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import carfaxLogo from "../assets/carfaxLogo.svg";
import autoCheckLogo from "../assets/autoCheckLogo.png";

import Loading from "../components/Loading";
import CarReport from "../components/CarReport";
import AutoCheckReport from "../components/AutoCheckReport";

const styles = (theme) => ({
  fullVehicleInformation: {
    overflowY: "auto",
    height: "100%",
    margin: ".5rem",
    border: `3px solid ${theme.palette.primary.gray}`,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  imageWrapper: {
    display: "flex",
    maxHeight: "13rem",
    backgroundColor: theme.palette.secondary.gray,
    borderBottom: `3px solid ${theme.palette.primary.gray}`,
    flexDirection: "column",
    overflow: "hidden",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
  topImageWrapper: {
    display: "flex",
    height: "9rem",
    margin: ".5rem",
    justifyContent: "center",
  },
  topRightImageWrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "4%",
    justifyContent: "space-evenly",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
  },
  imageCarousel: {
    height: "3rem",
    display: "flex",
    marginLeft: ".5rem",
    marginBottom: ".25rem",
    marginRight: ".75rem",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-around",
  },
  leftNav: {
    height: "100%",
    display: "flex",
    position: "absolute",
    zIndex: 2,
    left: "-.8rem",
    backgroundColor: "rgb(0,0,0,.05)",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,.1)",
    },
    cursor: "pointer",
  },
  rightNav: {
    height: "100%",
    position: "absolute",
    display: "flex",
    zIndex: 2,
    right: "-1rem",
    backgroundColor: "rgb(0,0,0,.05)",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,.1)",
    },
    cursor: "pointer",
  },
  image: {
    minWidth: "3rem",
    maxWidth: "47%",
    maxHeight: "4rem",
    cursor: "pointer",
  },
  largeImage: {
    width: "100%",
    cursor: "pointer",
    maxWidth: "12rem",
    "&:hover": {
      opacity: ".5",
    },
  },
  carouselImage: {
    height: "2.5rem",
    cursor: "pointer",
    width: "3rem",
  },
  table: {
    height: "100%",
  },
  row: {
    height: "2rem",
  },
  darkCell: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.light.gray,
    padding: 0,
    fontSize: theme.font.small,
    paddingLeft: ".5rem",
    width: "11rem",
  },
  lightCell: {
    color: theme.palette.primary.gray,
    backgroundColor: theme.palette.light.gray,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: ".5rem",
    position: "relative",
    fontSize: theme.font.small,
    "&:last-child": {
      paddingRight: 0,
    },
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    fontSize: theme.font.small,
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: ".5",
    },
  },
  carInfoIconWrapper: {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: theme.palette.light.gray,
    height: "2rem",
    borderBottom: `2px solid ${theme.palette.primary.gray}`,
  },
  carInfoIcon: {
    width: "45%",
    height: "2rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
});

export class DDOFullVehicleInformation extends Component {
  state = {
    keys: null,
    imageIndex: 0,
    carouselStartIndex: 0,
    showCarfaxReport: false,
    showAutoCheckReport: false,
    showCarfaxSnackBar: false,
  };

  getKeys = () => {
    const { conditionReport } = this.props;
    this.setState({
      keys: conditionReport.vehicleInformation.Keys,
    });
  };

  changeImage = (index) => {
    this.setState({
      imageIndex: index,
    });
  };

  componentDidMount = () => {
    const { conditionReport } = this.props;
    if (conditionReport) {
      this.getKeys();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { showCarfaxReport, showAutoCheckReport } = this.state;
    const { conditionReport } = this.props;
    if (conditionReport && conditionReport !== prevProps.conditionReport) {
      this.getKeys();
    }
    if (
      showCarfaxReport !== prevState.showCarfaxReport ||
      showAutoCheckReport !== prevState.showAutoCheckReport
    ) {
      this.setState({
        showCarfaxReport: false,
        showAutoCheckReport: false,
      });
    }
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: !this.state.viewerVisible,
    });
  };

  navigateImages = (nav) => {
    const { conditionReport } = this.props;
    if (nav === "back") {
      if (this.state.carouselStartIndex === 0) {
        this.setState({
          carouselStartIndex: conditionReport.inspectionImageDTO.length - 4,
        });
      } else {
        this.setState({
          carouselStartIndex: this.state.carouselStartIndex - 1,
        });
      }
    } else {
      if (
        conditionReport.inspectionImageDTO.length < 4 ||
        this.state.carouselStartIndex ===
          conditionReport.inspectionImageDTO.length - 4
      ) {
        this.setState({
          carouselStartIndex: 0,
        });
      } else {
        this.setState({
          carouselStartIndex: this.state.carouselStartIndex + 1,
        });
      }
    }
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  showAutoCheckReport = () => {
    this.setState({
      showAutoCheckReport: !this.state.showAutoCheckReport,
    });
  };

  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  render() {
    const { classes, car, conditionReport, toggleFullConditionReport, user } =
      this.props;
    const {
      keys,
      imageIndex,
      viewerVisible,
      carouselStartIndex,
      showCarfaxReport,
      showAutoCheckReport,
      showCarfaxSnackBar,
    } = this.state;
    return (
      <React.Fragment>
        {conditionReport ? (
          <div
            id="fullVehicleInformationPanel"
            className={classes.fullVehicleInformation}
          >
            {conditionReport.inspectionImageDTO &&
            conditionReport.inspectionImageDTO.length > 0 ? (
              <Viewer
                activeIndex={imageIndex}
                visible={viewerVisible}
                zoomSpeed={0.3}
                noClose={true}
                onMaskClick={(evt) => this.setState({ viewerVisible: false })}
                onClose={() => this.setState({ viewerVisible: false })}
                images={conditionReport.inspectionImageDTO.map((image) => {
                  return { src: image.imageUrl, alt: image.damageImageCaption };
                })}
              />
            ) : null}
            {conditionReport.inspectionImageDTO &&
            conditionReport.inspectionImageDTO.length > 0 ? (
              <div className={classes.imageWrapper}>
                <div className={classes.topImageWrapper}>
                  <img
                    id="largeImage"
                    className={classes.largeImage}
                    src={
                      conditionReport.inspectionImageDTO[imageIndex].imageUrl
                    }
                    alt=""
                    onClick={() => {
                      this.toggleImageViewer();
                    }}
                  />
                </div>
                <div className={classes.imageCarousel}>
                  <div
                    id="leftNav"
                    className={classes.leftNav}
                    onClick={(evt) => this.navigateImages("back")}
                  >
                    <ArrowLeft className={classes.navIcon} />
                  </div>
                  {conditionReport.inspectionImageDTO
                    .slice(carouselStartIndex, carouselStartIndex + 4)
                    .map((image) => {
                      return (
                        <img
                          id={`image${image.sequenceNumber}`}
                          className={classes.carouselImage}
                          src={image.imageUrl}
                          alt={image.sequenceNumber}
                          key={image.sequenceNumber}
                          onClick={(evt) =>
                            this.changeImage(image.sequenceNumber - 1)
                          }
                        />
                      );
                    })}
                  <div
                    id="rightNav"
                    className={classes.rightNav}
                    onClick={(evt) => this.navigateImages()}
                  >
                    <ArrowRight className={classes.navIcon} />
                  </div>
                </div>
              </div>
            ) : null}
            <div className={classes.carInfoIconWrapper}>
              <img
                className={classes.carInfoIcon}
                src={autoCheckLogo}
                onClick={() => this.showAutoCheckReport()}
                alt="autoCheckIcon"
              />
              <ReactSVG
                id="carfaxIcon"
                className={classes.carInfoIcon}
                svgStyle={{
                  width: "100%",
                  position: "relative",
                  top: ".60rem",
                }}
                src={carfaxLogo}
                onClick={() => this.showCarfaxReport()}
              />
            </div>
            <Table className={classes.table}>
              <TableBody>
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>VIN</TableCell>
                  <TableCell className={classes.lightCell}>
                    <Typography
                      id="toggleConditionReport"
                      className={
                        conditionReport
                          ? classes.clickableLink
                          : classes.lightCell
                      }
                      onClick={
                        conditionReport
                          ? () => toggleFullConditionReport(car)
                          : null
                      }
                    >
                      {car.vin}
                    </Typography>
                  </TableCell>
                </TableRow>
                {conditionReport ? (
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>
                      Model Number
                    </TableCell>
                    <TableCell className={classes.lightCell}>
                      {conditionReport.vehicleInformation["Model#"]}
                    </TableCell>
                  </TableRow>
                ) : null}
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>Location</TableCell>
                  <TableCell className={classes.lightCell}>
                    {`${car.city}, ${car.state}`}
                  </TableCell>
                </TableRow>
                {conditionReport ? (
                  <React.Fragment>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.darkCell}>Keys</TableCell>
                      <TableCell className={classes.lightCell}>
                        {keys}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ) : null}
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>
                    Drive Train
                  </TableCell>
                  <TableCell className={classes.lightCell}>
                    {car.driveTrain}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>Options</TableCell>
                  <TableCell className={classes.lightCell}>
                    {car.option}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>
                    Grounding Mileage
                  </TableCell>
                  <TableCell className={classes.lightCell}>
                    {conditionReport.vehicleInformation["Grounding Mileage"]
                      ? conditionReport.vehicleInformation["Grounding Mileage"]
                      : car.odometer
                      ? car.odometer.toLocaleString()
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>
                    Grounding Date
                  </TableCell>
                  <TableCell className={classes.lightCell}>
                    <Moment interval={0} format={"MM/DD/YYYY"}>
                      {car.groundingDate}
                    </Moment>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>
                    Inspection Completion Date
                  </TableCell>
                  <TableCell className={classes.lightCell}>
                    {conditionReport.inspectionDate}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>
                    {car.autoGradeAvailable ? "Auto-grade" : "Grade"}
                  </TableCell>
                  <TableCell className={classes.lightCell}>
                    {returnAutoGrade(car)}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>
                    Inspection Mileage
                  </TableCell>
                  <TableCell className={classes.lightCell}>
                    {conditionReport.vehicleInformation.Odometer}
                  </TableCell>
                </TableRow>
                {conditionReport ? (
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>EWT</TableCell>
                    <TableCell className={classes.lightCell}>
                      {conditionReport.vehicleConditions[0]
                        .excessWearAndTearSubTotal &&
                        conditionReport.vehicleConditions[0].excessWearAndTearSubTotal.toLocaleString(
                          "en-US",
                          { style: "currency", currency: "USD" }
                        )}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </div>
        ) : (
          <Loading />
        )}
        {showCarfaxReport ? (
          <CarReport
            vin={car.vin}
            reportType="carfax"
            showCarfaxReport={this.showCarfaxReport}
            showNoDataVin={this.showNoDataVin}
            user={user}
          />
        ) : null}
        {showCarfaxSnackBar ? (
          <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
        ) : null}
        {showAutoCheckReport ? (
          <AutoCheckReport
            vin={car.vin}
            reportType="carfax"
            showAutoCheckReport={this.showAutoCheckReport}
            user={user}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

DDOFullVehicleInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DDOFullVehicleInformation);
