import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Viewer from "react-viewer";

import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Snackbar,
  Chip,
  Tooltip,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import { getNotes } from "../services/NotesServices";
import { getDate, returnAccountType } from "../services/HelperServices";
import * as WatchListServices from "../services/WatchListServices";
import { removeAutoBid } from "../services/BidServices";
import * as userAccess from "../services/UserRoles";

import Notes from "../components/Notes";
import CarReport from "../components/CarReport";
import DDOTransportQuoteTile from "../components/DDOTransportQuoteTile";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import notesIcon from "../assets/notesIcon.svg";

import ReactSVG from "react-svg";
import watchlistIcon from "../assets/watchlistIcon.svg";
import activeWatchlistIcon from "../assets/activeWatchlistIcon.svg";
import groundNavIcon from "../assets/groundNavIcon.svg";
import winningBidIcon from "../assets/winningBidIcon.svg";
import losingBidIcon from "../assets/losingBidIcon.svg";
import DDOGroundedIcon from "../assets/DDOGroundedIcon.svg";

import { watchlist_count } from "../actions/carActions";

const styles = (theme) => ({
  card: {
    display: "flex",
    marginTop: ".5rem",
    borderRadius: 0,
    height: "5rem",
    width: "100%",
    position: "relative",
  },
  cardWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "left",
    flexDirection: "column",
    width: "30%",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "33%",
    },
  },
  cardTitle: {
    fontSize: "55%",
    position: "absolute",
    fontWeight: "bold",
    marginLeft: "2%",
  },
  media: {
    minWidth: "8rem",
    width: "100%",
    height: "7rem",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      minWidth: 0,
      height: "5rem",
      width: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
    },
  },
  clickableMedia: {
    minWidth: "8rem",
    width: "100%",
    height: "7rem",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {
      margin: 0,
      minWidth: 0,
      height: "5rem",
      width: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: ".5rem",
  },
  contentWrapper: {
    display: "flex",
    position: "relative",
    cursor: "pointer",
    width: "80%",
  },
  textWrapper: {
    display: "flex",
    height: "85%",
    justifyContent: "space-around",
    position: "relative",
    top: "1rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "initial",
    },
  },
  textColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "1%",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
  },
  textContainer: {
    width: "100%",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignContent: "space-between",
    height: "9rem",
    justifyContent: "center",
    position: "relative",
  },
  imageFooter: {
    height: "1.5rem",
    width: "100%",
    zIndex: 2,
    backgroundColor: "rgba(82, 78, 78, .8)",
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  smallButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute",
    bottom: 0,
    right: ".5rem",
    height: "1.25rem",
    zIndex: 2,
  },
  smallButton: {
    height: "2.5rem",
    minWidth: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingLeft: ".5rem",
    },
  },
  transportButton: {
    height: "2.5rem",
    minWidth: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingLeft: ".5rem",
      position: "absolute",
      right: "1rem",
      bottom: "1rem",
    },
  },
  smallButtonLabel: {
    marginLeft: "2%",
    marginRight: "2%",
    justifyContent: "initial",
    fontSize: ".6rem",
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    minWidth: "13%",
    [theme.breakpoints.down("md")]: {
      minWidth: "20%",
    },
  },
  buyButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100%",
    margin: 0,
  },
  passButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.pass,
    width: "100%",
    margin: 0,
  },
  guaranteedPaymentButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.guaranteedPayment,
    width: "100%",
    margin: 0,
  },
  cardText: {
    fontSize: "76%",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("md")]: {
      fontSize: "48%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  cardTextWhite: {
    fontSize: "76%",
    color: theme.palette.primary.white,
    marginLeft: ".5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "48%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  cardTextRed: {
    fontSize: "76%",
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "48%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  boldedText: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
  whiteBoldedText: {
    fontWeight: "bold",
    color: theme.palette.primary.white,
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "inherit",
    },
  },
  redBoldedText: {
    fontWeight: "bold",
    color: theme.palette.primary.redHighlight,
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "inherit",
    },
  },
  statusCardText: {
    marginLeft: ".75rem",
    position: "relative",
    bottom: "2%",
    fontSize: "76%",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  LPWLink: {},
  hasNotes: {
    color: theme.palette.primary.redHighlight,
    fill: "currentColor",
  },
  noteIcon: {
    fill: theme.palette.grounding.main,
  },
  carInfoIconWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "17%",
    minWidth: "4rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carInfoIcon: {
    width: "4rem",
    "&:hover": {
      opacity: ".5",
    },
  },
  errorSnackBar: {
    marginTop: "7rem",
  },
  exclusivityTimer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, .6)",
    height: "1.5rem",
    color: theme.palette.primary.white,
    display: "flex",
    alignItems: "center",
    width: "27%",
    margin: 0,
    justifyContent: "space-around",
  },
  exclusiveText: {
    fontSize: theme.font.small,
    display: "flex",
  },
  autoBid: {
    display: "flex",
    width: "8rem",
    alignItems: "center",
  },
  chipRoot: {
    fontSize: theme.font.small,
    height: "1rem",
    width: "4.4rem",
    marginLeft: ".5rem",
  },
  deleteIcon: {
    height: "1rem",
    "&:hover": {
      fill: theme.palette.primary.redHighlight,
    },
  },
  losingBar: {
    width: "10px",
    height: "100%",
    backgroundColor: theme.palette.primary.redHighlight,
  },
  winningBar: {
    width: "10px",
    height: "100%",
    backgroundColor: theme.palette.DDO.main,
  },
  winningBid: {
    color: theme.palette.DDO.main,
    fontSize: "48%",
  },
  losingBid: {
    color: theme.palette.primary.redHighlight,
    fontSize: "48%",
  },
  "@global": {
    ".MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
  },
});

export class MobileDDOCarListTile extends Component {
  state = {
    LPWActiveCar: false,
    guaranteePayment: false,
    openNote: false,
    notes: [],
    showCarfaxReport: false,
    viewerVisible: false,
    buyLoading: false,
    buyError: false,
    errorMessage: "An error has occured, please try again in a few minutes.",
    showMobileActionButtons: false,
    timeRemaining: null,
    hours: 0,
    minutes: 0,
    showCarfaxSnackBar: false,
  };

  componentWillMount = () => {
    this.fetchNotes();
  };

  fetchNotes = async () => {
    const { car, user } = this.props;
    if (car.noteAvailable || car.notesPresent || car.isNoteAvailable) {
      const notes = await getNotes(
        user.dealerId,
        car.id ? car.id : car.inventoryId
      );
      this.setState({
        notes,
      });
    }
  };

  removeAutoBid = async () => {
    const { user, car, updateAutoBid, watchlistCount, dispatch } = this.props;
    const response = await removeAutoBid(user, car);
    if (response.status === "true") {
      updateAutoBid(car.vin, 0);
      dispatch(watchlist_count(watchlistCount - 1));
    }
  };

  addNote = (newNote) => {
    const { accountSetting, car, user } = this.props;
    const fullNote = {
      inventoryId: car.id ? car.id : car.inventoryId,
      dealerId: user.dealerId,
      note: newNote,
      needFollowUp: false,
      userId: user.userId,
      firstName: accountSetting.firstName,
      lastName: accountSetting.lastName,
      createdDate: getDate(),
    };
    this.setState({
      notes: [...this.state.notes, fullNote],
    });
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: !this.state.viewerVisible,
    });
  };

  toggleWatchList = async () => {
    const { user, car, updateWatchlist, from } = this.props;
    let response;
    if (car.isInWatchList || (from && from === "watchlist")) {
      response = await WatchListServices.removeFromWatchList(user, car);
    } else {
      response = await WatchListServices.addToWatchList(user, car);
    }
    this.loadWatchlistData();
    if (response) {
      updateWatchlist(car.vin);
    }
  };

  loadWatchlistData = async () => {
    const { user, dispatch } = this.props;
    const watchListCars = await WatchListServices.getSavedWatchlist(user);
    if (watchListCars && watchListCars.length !== undefined) {
      dispatch(watchlist_count(watchListCars.length));
    }
  };

  renderDisplayIcon = () => {
    const { bidPlaced, isVehAtLoggedInDlrLot, highBidder } = this.props.car;
    const { name } = this.props.accountSetting.custLocations[0].locationDTO;

    if (bidPlaced) {
      if (name === highBidder) {
        return (
          <ReactSVG
            id="winningBid"
            src={winningBidIcon}
            svgStyle={{ height: "1.2rem" }}
          />
        );
      } else {
        return (
          <ReactSVG
            id="losingBid"
            src={losingBidIcon}
            svgStyle={{ height: "1.2rem" }}
          />
        );
      }
    } else if (isVehAtLoggedInDlrLot) {
      return (
        <ReactSVG
          id="dealerLot"
          src={DDOGroundedIcon}
          svgStyle={{ height: "1.2rem" }}
        />
      );
    } else {
      return null;
    }
  };

  renderPriceDisplay = () => {
    const { car, classes, currentAuction } = this.props;
    const { name } = this.props.accountSetting.custLocations[0].locationDTO;
    if (
      !car.bidPlaced &&
      car.myCurrentAutoBid > 0 &&
      currentAuction !== "sale1" &&
      currentAuction !== "sale2"
    ) {
      return (
        <div className={classes.autoBid}>
          <Typography className={classes.cardText}>
            <span className={classes.boldedText}>Autobid:</span>
          </Typography>
          <Chip
            label={`${car.myCurrentAutoBid.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}
            onDelete={this.removeAutoBid}
            className={classes.chip}
            classes={{ root: classes.chipRoot, deleteIcon: classes.deleteIcon }}
            deleteIcon={
              <Tooltip title="Remove Autobid">
                <CancelIcon />
              </Tooltip>
            }
          />
        </div>
      );
    } else if (car.statusCode === "DLR_BID_NOW" && car.bidPlaced) {
      return (
        <Typography
          className={
            name === car.highBidder ? classes.winningBid : classes.losingBid
          }
        >
          <span className={classes.boldedText}>Current Bid:</span>{" "}
          <span>
            {car.currentBid.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}

            <span
              className={
                name === car.highBidder ? classes.winningBid : classes.losingBid
              }
            >
              {" "}
              <span className={classes.boldedText}>
                {name === car.highBidder ? "WINNING" : "LOSING"}
              </span>
            </span>
          </span>
        </Typography>
      );
    } else {
      return (
        <Typography className={classes.cardText}>
          <span className={classes.redBoldedText}>Start Price: </span>
          <span className={classes.redText}>
            {car.startPrice
              ? car.startPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : null}
          </span>
        </Typography>
      );
    }
  };

  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  render() {
    const {
      classes,
      car,
      selectCar,
      user,
      selectedCar,
      conditionReport,
      from,
      openNote,
      handleOpenNote,
      handleTransportToggle,
      openTransport,
      accountSetting,
    } = this.props;
    const {
      notes,
      showCarfaxReport,
      viewerVisible,
      buyError,
      errorMessage,
      showCarfaxSnackBar,
    } = this.state;
    const { isInWatchList, buyNowDisabled, distance } = this.props.car;
    const { name } = this.props.accountSetting.custLocations[0].locationDTO;
    return (
      <React.Fragment>
        {conditionReport && conditionReport.inspectionImageDTO ? (
          <Viewer
            visible={viewerVisible}
            zoomSpeed={0.3}
            noClose={true}
            onMaskClick={(evt) => this.setState({ viewerVisible: false })}
            onClose={() => this.setState({ viewerVisible: false })}
            images={conditionReport.inspectionImageDTO.map((image) => {
              return { src: image.imagePathFc, alt: image.damageImageCaption };
            })}
          />
        ) : null}
        <Card
          className={classes.card}
          id={`MobileDDOCarListTile${car.vin}`}
          onClick={() => selectCar(car, user, "DDO")}
        >
          {car.bidPlaced ? (
            <div
              className={
                name !== car.highBidder ? classes.losingBar : classes.winningBar
              }
            ></div>
          ) : null}
          <CardActions className={classes.cardWrapper}>
            <div className={classes.imageWrapper}>
              <img
                id="primaryImage"
                src={car.imagePathFc}
                className={
                  conditionReport ? classes.clickableMedia : classes.media
                }
                alt={`${car.make} ${car.model}`}
              />
              <div className={classes.imageFooter}>
                {!buyNowDisabled ? (
                  <Typography className={classes.cardTextWhite}>
                    Buy Now Price:{" "}
                    <span className={classes.whiteBoldedText}>
                      {car.buyNowPrice.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </Typography>
                ) : (
                  <Typography className={classes.cardTextWhite}>
                    My AutoBid:{" "}
                    <span className={classes.whiteBoldedText}>
                      {car.myCurrentAutoBid.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </Typography>
                )}
              </div>
            </div>
            {userAccess.canPurchaseVehicles(user.userRoles) ? (
              <div className={classes.smallButtonWrapper}>
                {this.renderDisplayIcon()}
                {car.myCurrentAutoBid === 0 && !car.bidPlaced ? (
                  <Button
                    id="watchListBtn"
                    className={classes.smallButton}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      this.toggleWatchList();
                    }}
                  >
                    <ReactSVG
                      src={
                        isInWatchList || from === "watchlist"
                          ? activeWatchlistIcon
                          : watchlistIcon
                      }
                    />
                  </Button>
                ) : null}
                <Button
                  id="noteButton"
                  className={classes.smallButton}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    openNote !== car.vin
                      ? handleOpenNote(car)
                      : handleOpenNote();
                  }}
                >
                  <ReactSVG
                    src={notesIcon}
                    className={
                      car.notesPresent || (notes && notes.length > 0)
                        ? classes.hasNotes
                        : classes.noteIcon
                    }
                  />
                </Button>
                <Button
                  id="transportButton"
                  className={classes.transportButton}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleTransportToggle(car);
                  }}
                >
                  <ReactSVG
                    title="Transportation Quote"
                    className={classes.transportIcon}
                    src={groundNavIcon}
                    svgStyle={{ width: "1rem" }}
                  />
                </Button>
              </div>
            ) : null}
          </CardActions>
          <div className={classes.contentWrapper}>
            <div className={classes.textContainer}>
              <Typography className={classes.cardTitle}>
                {`${car.year} ${car.make} ${car.model} `}
                {car.color ? car.color : null}
              </Typography>
              <div className={classes.textWrapper}>
                <CardContent className={classes.textColumn}>
                  <Typography className={classes.cardText}>
                    Inspection Mileage:{" "}
                    <span className={classes.boldedText}>
                      {car.inspectionMileage.toLocaleString()}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Distance:{" "}
                    <span className={classes.boldedText}>{`${distance.toFixed(
                      2
                    )} mi`}</span>
                  </Typography>
                  {this.renderPriceDisplay()}
                  <Typography className={classes.cardText}>
                    Account Type:{" "}
                    <span
                      className={
                        returnAccountType(car.accountType) === "Company Car"
                          ? classes.redBoldedText
                          : classes.boldedText
                      }
                    >
                      {returnAccountType(car.accountType)}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </div>
          </div>
        </Card>
        {openNote === car.vin ? (
          <Notes
            toggleNote={handleOpenNote}
            notes={notes}
            user={user}
            addNote={this.addNote}
            car={car}
            selected={car.vin === selectedCar.vin}
          />
        ) : null}
        {showCarfaxReport ? (
          <CarReport
            vin={car.vin}
            reportType="carfax"
            showCarfaxReport={this.showCarfaxReport}
            showNoDataVin={this.showNoDataVin}
          />
        ) : null}
        {showCarfaxSnackBar ? (
          <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
        ) : null}
        {openTransport === car.vin ? (
          <DDOTransportQuoteTile
            car={car}
            user={user}
            accountSetting={accountSetting}
          />
        ) : null}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={buyError}
          autoHideDuration={3000}
          onClose={() => this.setState({ buyError: false })}
          className={classes.errorSnackBar}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{errorMessage}</span>}
        />
      </React.Fragment>
    );
  }
}

MobileDDOCarListTile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MobileDDOCarListTile);
