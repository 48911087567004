import {
  LOAD_STATE_INFO_SUCCESS,
  LOAD_STATE_INFO_FAIL,
} from "../actions/StateInfo.actions";

export const stateInfo = [];

export default (state = stateInfo, action) => {
  switch (action.type) {
    case LOAD_STATE_INFO_SUCCESS:
      return action.stateInfo;
    case LOAD_STATE_INFO_FAIL:
      return state;
    default:
      return state;
  }
};
