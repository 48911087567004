import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  FormControl,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  Select,
  MenuItem,
  Chip,
  Button,
} from "@material-ui/core";

import SearchCheckboxOptions from "../components/SearchCheckboxOptions";
import Loading from "../components/Loading";

import { mobility_search_criteria } from "../actions/mobility.actions";
import * as searchHelper from "../services/SearchHelper";

const styles = (theme) => ({
  select: {
    minWidth: "130px",
  },
  alignContent: {
    alignContent: "center",
  },
  odoDD: {
    padding: ".5rem",
    width: "50%",
    display: "flex",
  },
  odoDDSelect: {
    padding: ".5rem",
    width: "70%",
    display: "flex",
  },
  odoLabel: {
    padding: ".5rem",
  },
  selectDivStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    margin: "0.25rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.primary.gray,
    width: "50%",
    fontWeight: "bold",
    textTransform: "initial",
    fontSize: ".8rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.gray,
      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.gray,
        "&:active": {
          backgroundColor: theme.palette.primary.gray,
        },
      },
    },
  },
  chipSection: {
    marginBottom: ".5rem",
  },
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  saveSearchLink: {
    margin: "0.5rem",
  },
  seperatorDiv: {
    paddingTop: "0.2em",
  },
  countStyle: {
    padding: "0.3rem",
    marginTop: ".4rem",
  },
  criteriaWrapper: {
    padding: "0.4rem",
    height: "100%",
  },
  disabledCriteria: {
    pointerEvents: "none",
  },
  iconArrange: {
    display: "flex",
    flexDirection: "row",
  },
  scrollBar: {
    height: "70vh",
    overflow: "auto",
  },
  buttonWrapper: {
    textAlign: "center",
    display: "flex",
  },
  buttonSearch: {
    backgroundColor: theme.palette.primary.gray,
    width: "2.5rem",
    height: "2.00rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
    "&:hover": {
      opacity: ".5",
    },
  },
  formControl: {
    display: "flex",
    height: "2rem",
    marginTop: ".5rem",
    flexDirection: "row",
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "3rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fieldLabelDistance: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "7rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectInputState: {
    fontSize: ".8rem",
    width: "8rem",
    textAlign: "center",
  },
  selectInput: {
    fontSize: ".8rem",
    width: "6rem",
    textAlign: "center",
  },
  count: {
    display: "block",
  },
  mobSearchRight: {
    backgroundColor: theme.palette.secondary.gray,
    margin: ".4rem",
    width: "100%",
    maxHeight: "97%",
    overflow: "hidden",
  },
  "@global": {
    ".MuiChip-root": {
      backgroundColor: "#e0e0e0",
    },
    "#menu-State, #menu-distance, #menu-odometerFrom, #menu-odometerTo, #menu-priceFrom, #menu-priceTo, #menu-yearFrom, #menu-yearTo":
      {
        zIndex: "10001 !important",
      },
    ".MuiChip-label": {
      [theme.breakpoints.down("sm")]: {
        fontSize: ".6rem",
      },
    },
  },
});

const doorsOption = ["1", "2", "3", "4", "5"];
const driveTrainOption = ["4WD", "AWD", "FWD", "RWD"];
const regionOption = ["All", "Eastern", "Western", "Central"];
const carfaxOption = [
  "No Major Damage",
  "No Odometer Problem",
  "No Mileage Inconsistency",
  "No Open Recall",
];
const odometerFromOption = [
  { value: 0, text: "0" },
  { value: 10000, text: "10,000" },
  { value: 20000, text: "20,000" },
  { value: 30000, text: "30,000" },
  { value: 40000, text: "40,000" },
  { value: 50000, text: "50,000" },
  { value: 60000, text: "60,000" },
  { value: 75000, text: "75,000" },
  { value: 100000, text: "100,000" },
  { value: 125000, text: "125,000" },
  { value: 150000, text: "150,000" },
  { value: 200000, text: "200,000" },
];
const odometerToOption = [
  { value: 0, text: "0" },
  { value: 10000, text: "10,000" },
  { value: 20000, text: "20,000" },
  { value: 30000, text: "30,000" },
  { value: 40000, text: "40,000" },
  { value: 50000, text: "50,000" },
  { value: 60000, text: "60,000" },
  { value: 75000, text: "75,000" },
  { value: 100000, text: "100,000" },
  { value: 125000, text: "125,000" },
  { value: 150000, text: "150,000" },
  { value: 200000, text: "200,000" },
  { value: 999999, text: "200,000+" },
];
const priceFromOption = [
  { value: 0, text: "$0" },
  { value: 10000, text: "$10,000" },
  { value: 15000, text: "$15,000" },
  { value: 20000, text: "$20,000" },
  { value: 25000, text: "$25,000" },
  { value: 30000, text: "$30,000" },
  { value: 35000, text: "$35,000" },
  { value: 40000, text: "$40,000" },
  { value: 45000, text: "$45,000" },
  { value: 50000, text: "$50,000" },
  { value: 55000, text: "$55,000" },
  { value: 60000, text: "$60,000" },
  { value: 65000, text: "$65,000" },
  { value: 75000, text: "$75,000" },
  { value: 100000, text: "$100,000" },
];
const priceToOption = [
  { value: 0, text: "$0" },
  { value: 10000, text: "$10,000" },
  { value: 15000, text: "$15,000" },
  { value: 20000, text: "$20,000" },
  { value: 25000, text: "$25,000" },
  { value: 30000, text: "$30,000" },
  { value: 35000, text: "$35,000" },
  { value: 40000, text: "$40,000" },
  { value: 45000, text: "$45,000" },
  { value: 50000, text: "$50,000" },
  { value: 55000, text: "$55,000" },
  { value: 60000, text: "$60,000" },
  { value: 65000, text: "$65,000" },
  { value: 75000, text: "$75,000" },
  { value: 100000, text: "$100,000" },
  { value: 999999, text: "$100,000+" },
];

export class MobilityVehicleSearchCriteria extends Component {
  state = {
    advancedSearchCriteriaWrapper: [],
    advancedSearchCriteria: [],
    make: [],
    selectedMake: [],
    model: [],
    selectedModel: [],
    modelNumber: [],
    selectedModelNumber: [],
    series: [],
    selectedSeries: [],
    interiorColor: [],
    selectedInteriorColor: [],
    exteriorColor: [],
    selectedExteriorColor: [],
    selectedVehicleGrade: [],
    vehicleGradeFrom: null,
    errorMsgvehicleGradeFrom: "",
    vehicleGradeFromError: false,
    vehicleGradeTo: null,
    errorMsgvehicleGradeTo: "",
    vehicleGradeToError: false,
    style: [],
    selectedStyle: [],
    yearFrom: [],
    selectedYearFrom: 0,
    yearTo: [],
    selectedYearTo: 9999,
    door: doorsOption,
    selectedDoor: [],
    driveTrain: driveTrainOption,
    selectedDriveTrain: [],
    priceFrom: priceFromOption,
    selectedPriceFrom: 0,
    priceTo: priceToOption,
    selectedPriceTo: 999999,
    odometerFrom: odometerFromOption,
    selectedOdometerFrom: 0,
    odometerTo: odometerToOption,
    selectedOdometerTo: 999999,
    region: regionOption,
    selectedRegion: [],
    auction: [],
    selectedAuction: [],
    carfax: carfaxOption,
    selectedCarfax: [],
    states: [],
    selectState: [],
    transmission: [],
    selectedTransmission: [],
    engine: [],
    selectedEngine: [],
    options: [],
    selectedOptions: [],
    pageLoading: true,
  };

  clearSearch = () => {
    this.setState({
      selectedMake: [],
      selectedModel: [],
      selectedModelNumber: [],
      selectedSeries: [],
      selectedInteriorColor: [],
      selectedExteriorColor: [],
      vehicleGradeFrom: null,
      selectedvehicleGradeFrom: "",
      vehicleGradeTo: null,
      selectedvehicleGradeTo: "",
      selectedStyle: [],
      selectedYearFrom: 0,
      selectedYearTo: 9999,
      selectedDoor: [],
      selectedDriveTrain: [],
      selectedPriceFrom: 0,
      selectedPriceTo: 999999,
      selectedOdometerFrom: 0,
      selectedOdometerTo: 999999,
      selectedRegion: [],
      selectedAuction: [],
      selectedCarfax: [],
      selectState: [],
      selectedTransmission: [],
      selectedEngine: [],
      selectedOptions: [],
    });
    //clearSearchResult();
  };

  componentDidMount = () => {
    const { mobilitySearchCriteria, savedSearchInfo } = this.props;
    if (this.props.mobilitySearchCriteria === null) {
      this.loadCriteria();
    } else {
      this.setState({
        auction: mobilitySearchCriteria.auction,
        make: mobilitySearchCriteria.make,
        model: mobilitySearchCriteria.model,
        modelNumber: mobilitySearchCriteria.modelNumber,
        series: mobilitySearchCriteria.series,
        interiorColor: mobilitySearchCriteria.interiorColor,
        exteriorColor: mobilitySearchCriteria.exteriorColor,
        style: mobilitySearchCriteria.style,
        yearFrom: mobilitySearchCriteria.yearFrom,
        yearTo: mobilitySearchCriteria.yearTo,
        states: mobilitySearchCriteria.states,
        transmission: mobilitySearchCriteria.transmission,
        engine: mobilitySearchCriteria.engine,
        options: mobilitySearchCriteria.options,
        selectState: [],
        pageLoading: false,
      });
    }
    if (savedSearchInfo !== null) {
      this.prefillStateWithSavedSearchInfo(savedSearchInfo);
    }
  };

  prefillStateWithSavedSearchInfo = (savedSearchInfo) => {
    this.setState({
      selectedAuction: savedSearchInfo.searchCriteria.auctionLocations,
      selectedStyle: savedSearchInfo.searchCriteria.bodyStyle,
      selectedCarfax: savedSearchInfo.searchCriteria.carfaxOptions,
      selectedDoor: savedSearchInfo.searchCriteria.doors,
      selectedDriveTrain: savedSearchInfo.searchCriteria.driveTrain,
      selectedEngine: savedSearchInfo.searchCriteria.engine,
      selectedExteriorColor: savedSearchInfo.searchCriteria.exteriorColor,
      selectedInteriorColor: savedSearchInfo.searchCriteria.interiorColor,
      selectedMake: savedSearchInfo.searchCriteria.make,
      selectedModel: savedSearchInfo.searchCriteria.model,
      selectedModelNumber: savedSearchInfo.searchCriteria.modelNumber,
      selectedOdometerFrom:
        savedSearchInfo.searchCriteria.odometerRange.fromodometer,
      selectedOdometerTo:
        savedSearchInfo.searchCriteria.odometerRange.toodometer,
      selectedOptions: savedSearchInfo.searchCriteria.options,
      selectedPriceFrom: savedSearchInfo.searchCriteria.priceRange.fromPrice,
      selectedPriceTo: savedSearchInfo.searchCriteria.priceRange.toPrice,
      selectedRegion: savedSearchInfo.searchCriteria.region,
      selectedSeries: savedSearchInfo.searchCriteria.series,
      selectState: savedSearchInfo.searchCriteria.state,
      selectedTransmission: savedSearchInfo.searchCriteria.transmission,
      vehicleGradeFrom: savedSearchInfo.searchCriteria.vehicleGradeRange
        .fromGrade
        ? this.countDecimals(
            savedSearchInfo.searchCriteria.vehicleGradeRange.fromGrade
          ) === 0
          ? savedSearchInfo.searchCriteria.vehicleGradeRange.fromGrade
              .toFixed(1)
              .toString()
          : savedSearchInfo.searchCriteria.vehicleGradeRange.fromGrade
        : null,
      vehicleGradeTo: savedSearchInfo.searchCriteria.vehicleGradeRange.toGrade
        ? this.countDecimals(
            savedSearchInfo.searchCriteria.vehicleGradeRange.toGrade
          ) === 0
          ? savedSearchInfo.searchCriteria.vehicleGradeRange.toGrade
              .toFixed(1)
              .toString()
          : savedSearchInfo.searchCriteria.vehicleGradeRange.toGrade
        : null,
      selectedYearFrom: savedSearchInfo.searchCriteria.modelYearRange.fromYear,
      selectedYearTo: savedSearchInfo.searchCriteria.modelYearRange.toYear,
    });
  };

  loadCriteria = async () => {
    const {
      dispatch,
      advancedSearchCriteriaWrapperData,
      auctionLocationData,
      advancedSearchCriteriaData,
      stateInfo,
    } = this.props;
    const searchCriteriaData = {
      advancedSearchCriteriaWrapper: advancedSearchCriteriaWrapperData,
      auctionLocationDataConst: auctionLocationData,
      advancedSearchCriteria: advancedSearchCriteriaData,
      stateInfo: stateInfo,
    };
    const parsedDate = searchHelper.filterSearchCriteria(searchCriteriaData);
    dispatch(mobility_search_criteria(parsedDate));
    this.setState({
      auction: parsedDate.auction,
      make: parsedDate.make,
      model: parsedDate.model,
      modelNumber: parsedDate.modelNumber,
      series: parsedDate.series,
      interiorColor: parsedDate.interiorColor,
      exteriorColor: parsedDate.exteriorColor,
      style: parsedDate.style,
      yearFrom: parsedDate.yearFrom,
      yearTo: parsedDate.yearTo,
      states: parsedDate.states,
      transmission: parsedDate.transmission,
      engine: parsedDate.engine,
      options: parsedDate.options,
      selectState: [],
      pageLoading: false,
    });
  };

  componentDidUpdate = (prevState) => {
    const { handleCriteriaUpdate } = this.props;
    if (this.state !== prevState) {
      handleCriteriaUpdate(this.state);
    }
  };

  makeSelectionUpdated = (makeValue) => {
    const { advancedSearchCriteriaWrapperData } = this.props;
    let makeCount = 0,
      modelCount = 0,
      modelNumberCount = 0;
    let makeArray = [],
      modelArray = [],
      allModelNumberArray = [],
      allSeriesNameArray = [];
    if (makeValue.includes("All") || makeValue.length === 0) {
      for (let [keyA] of Object.entries(
        advancedSearchCriteriaWrapperData["typeMakeModelSeriesModelNumberMap"]
      )) {
        makeArray[makeCount] = keyA;
        makeCount++;
        for (let [keyB] of Object.entries(
          advancedSearchCriteriaWrapperData[
            "typeMakeModelSeriesModelNumberMap"
          ][keyA]
        )) {
          modelArray[modelCount] = keyB;
          modelCount++;
          for (let [, value] of Object.entries(
            advancedSearchCriteriaWrapperData[
              "typeMakeModelSeriesModelNumberMap"
            ][keyA][keyB]
          )) {
            allModelNumberArray[modelNumberCount] = value.modelNumber;
            allSeriesNameArray[modelNumberCount] = value.seriesName;
            modelNumberCount++;
          }
        }
      }
      makeArray.push("All");
    } else {
      for (let [key, value] of Object.entries(makeValue)) {
        makeArray[makeCount] = key;
        makeCount++;
        for (let [key1] of Object.entries(
          advancedSearchCriteriaWrapperData[
            "typeMakeModelSeriesModelNumberMap"
          ][value]
        )) {
          modelArray[modelCount] = key1;
          modelCount++;
          for (let [, value2] of Object.entries(
            advancedSearchCriteriaWrapperData[
              "typeMakeModelSeriesModelNumberMap"
            ][value][key1]
          )) {
            allModelNumberArray[modelNumberCount] = value2.modelNumber;
            allSeriesNameArray[modelNumberCount] = value2.seriesName;
            modelNumberCount++;
          }
        }
      }
    }
    let modelNumberArray = [
      ...new Set(allModelNumberArray.map((item) => item)),
    ];
    let seriesNameArray = [...new Set(allSeriesNameArray.map((item) => item))];
    modelArray.push("All");
    modelNumberArray.push("All");
    seriesNameArray.push("All");
    this.setState({
      model: modelArray.sort(),
      modelNumber: modelNumberArray.sort(),
      series: seriesNameArray.sort(),
    });
  };

  modelSelectionUpdated = (modelValue) => {
    const { advancedSearchCriteriaWrapperData } = this.props;
    let makeCount = 0,
      modelCount = 0,
      modelNumberCount = 0;
    let makeArray = [],
      modelArray = [],
      allModelNumberArray = [],
      allSeriesNameArray = [];
    if (modelValue.includes("All") || modelValue.length === 0) {
      for (let [keyA] of Object.entries(
        advancedSearchCriteriaWrapperData["typeMakeModelSeriesModelNumberMap"]
      )) {
        makeArray[makeCount] = keyA;
        makeCount++;
        for (let [keyB] of Object.entries(
          advancedSearchCriteriaWrapperData[
            "typeMakeModelSeriesModelNumberMap"
          ][keyA]
        )) {
          modelArray[modelCount] = keyB;
          modelCount++;
          for (let [, value] of Object.entries(
            advancedSearchCriteriaWrapperData[
              "typeMakeModelSeriesModelNumberMap"
            ][keyA][keyB]
          )) {
            allModelNumberArray[modelNumberCount] = value.modelNumber;
            allSeriesNameArray[modelNumberCount] = value.seriesName;
            modelNumberCount++;
          }
        }
      }
      makeArray.push("All");
    } else {
      for (let [keyA] of Object.entries(
        advancedSearchCriteriaWrapperData["typeMakeModelSeriesModelNumberMap"]
      )) {
        makeArray[makeCount] = keyA;
        makeCount++;
        for (let [keyB] of Object.entries(
          advancedSearchCriteriaWrapperData[
            "typeMakeModelSeriesModelNumberMap"
          ][keyA]
        )) {
          modelArray[modelCount] = keyB;
          modelCount++;
          for (let [, valueD] of Object.entries(modelValue)) {
            for (let [, value] of Object.entries(
              advancedSearchCriteriaWrapperData[
                "typeMakeModelSeriesModelNumberMap"
              ][keyA][keyB]
            )) {
              if (keyB === valueD) {
                allModelNumberArray[modelNumberCount] = value.modelNumber;
                allSeriesNameArray[modelNumberCount] = value.seriesName;
                modelNumberCount++;
              }
            }
          }
        }
      }
    }
    let modelNumberArray = [
      ...new Set(allModelNumberArray.map((item) => item)),
    ];
    let seriesNameArray = [...new Set(allSeriesNameArray.map((item) => item))];
    modelArray.push("All");
    modelNumberArray.push("All");
    seriesNameArray.push("All");
    this.setState({
      modelNumber: modelNumberArray.sort(),
      series: seriesNameArray.sort(),
    });
  };

  renderSelectComponentWithTextValue = (
    id,
    value,
    changeValue,
    paramValue,
    label
  ) => {
    const { classes, userRoleHasMobilityRideshareView } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <label className={classes.fieldLabel}>
          <strong>{label}</strong>
        </label>
        <Select
          id={id}
          className={classes.selectInput}
          value={value}
          onChange={this.onChange(changeValue)}
          disabled={userRoleHasMobilityRideshareView}
          name={id}
          displayEmpty
          style={{ marginTop: "0" }}
        >
          {paramValue.map((param) => (
            <MenuItem key={param.value} value={param.value}>
              {param.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  renderSelectComponentSimple = (
    id,
    value,
    changeValue,
    paramValue,
    label,
    defaultValue
  ) => {
    const { classes, userRoleHasMobilityRideshareView } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <label className={classes.fieldLabel}>
          <strong>{label}</strong>
        </label>
        <Select
          id={id}
          className={classes.selectInput}
          value={value}
          onChange={this.onChange(changeValue)}
          disabled={userRoleHasMobilityRideshareView}
          name={id}
          displayEmpty
          style={{ marginTop: "0" }}
        >
          <MenuItem key={"All"} value={defaultValue}>
            All
          </MenuItem>
          {paramValue.map((parma) => (
            <MenuItem key={parma} value={Number(parma)}>
              {parma}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  renderSelectComponent = (
    id,
    value,
    changeValue,
    paramValue,
    summary,
    defaultOption
  ) => {
    const { classes, userRoleHasMobilityRideshareView } = this.props;
    const { selectState } = this.state;
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.heading}>
            {summary}
            {(value.length > 0 && id === "State") ||
            (value !== defaultOption && id !== "State") ? (
              <div className={classes.chipSection}>
                Search within :
                {id === "State" ? (
                  value.map((state) => {
                    return (
                      <Chip
                        variant="outlined"
                        key={state}
                        onDelete={() =>
                          this.handleSelectChipDelete(
                            changeValue,
                            selectState.filter((keepState) => {
                              return keepState !== state;
                            })
                          )
                        }
                        disabled={userRoleHasMobilityRideshareView}
                        label={state}
                        value={state}
                      />
                    );
                  })
                ) : (
                  <Chip
                    variant="outlined"
                    key={value}
                    onDelete={() =>
                      this.handleSelectChipDelete(changeValue, defaultOption)
                    }
                    label={value}
                    value={value}
                    disabled={userRoleHasMobilityRideshareView}
                  />
                )}
              </div>
            ) : null}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.selectDivStyle}>
          <FormControl className={classes.formControl}>
            <label className={classes.fieldLabelDistance}>
              <strong>Search within: </strong>
            </label>
            <Select
              id={id}
              className={classes.selectInputState}
              value={value}
              onChange={this.onChange(changeValue)}
              name={id}
              displayEmpty
              multiple={id === "State"}
              style={{ marginTop: "0" }}
              disabled={userRoleHasMobilityRideshareView}
            >
              <MenuItem key={defaultOption} value={defaultOption}>
                {defaultOption}
              </MenuItem>
              {paramValue.map((parma) => (
                <MenuItem key={parma} value={parma}>
                  {parma}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  handleFromTextBoxChange = (name) => (event) => {
    if (
      event.target.value !== null &&
      event.target.value !== "" &&
      event.target.value !== undefined
    ) {
      let gradeValue = Number(event.target.value);
      let toGradeValue = Number(this.state.vehicleGradeTo);
      if (
        gradeValue < 1 ||
        gradeValue > 5 ||
        this.countDecimals(gradeValue) > 1
      ) {
        this.setState({
          [name]: gradeValue,
          errorMsgvehicleGradeFrom: "Entered value must be from 1.0 to 5.0.",
          vehicleGradeFromError: true,
        });
      } else if (toGradeValue !== 0 && gradeValue > toGradeValue) {
        this.setState({
          [name]: gradeValue,
          errorMsgvehicleGradeFrom: "From must be less than to.",
          vehicleGradeFromError: true,
        });
      } else {
        this.setState({
          [name]: gradeValue,
          errorMsgvehicleGradeFrom: "",
          vehicleGradeFromError: false,
        });
      }
    } else {
      this.setState({
        [name]: null,
        errorMsgvehicleGradeFrom: "",
        vehicleGradeFromError: false,
      });
    }
  };

  handleToTextBoxChange = (name) => (event) => {
    if (
      event.target.value !== null &&
      event.target.value !== "" &&
      event.target.value !== undefined
    ) {
      let gradeValue = Number(event.target.value);
      let fromGradeValue = Number(this.state.vehicleGradeFrom);
      if (
        gradeValue < 1 ||
        gradeValue > 5 ||
        this.countDecimals(gradeValue) > 1
      ) {
        this.setState({
          [name]: gradeValue,
          errorMsgvehicleGradeTo: "Entered value must be from 1.0 to 5.0.",
          vehicleGradeToError: true,
        });
      } else if (fromGradeValue !== 0 && gradeValue < fromGradeValue) {
        this.setState({
          [name]: gradeValue,
          errorMsgvehicleGradeTo: "To must be greater than from.",
          vehicleGradeToError: true,
        });
      } else {
        this.setState({
          [name]: gradeValue,
          errorMsgvehicleGradeTo: "",
          vehicleGradeToError: false,
        });
      }
    } else {
      this.setState({
        [name]: null,
        errorMsgvehicleGradeTo: "",
        vehicleGradeToError: false,
      });
    }
  };

  handleTextBoxBlurFrom = (name) => (event) => {
    if (
      this.state.vehicleGradeFrom !== null &&
      !this.state.vehicleGradeFromError
    ) {
      let gradeValue = Number(this.state.vehicleGradeFrom);
      if (this.countDecimals(gradeValue) === 0) {
        this.setState({
          vehicleGradeFrom: gradeValue.toFixed(1),
          errorMsgvehicleGradeTo:
            this.state.vehicleGradeTo === null
              ? "To cannot be empty."
              : this.state.errorMsgvehicleGradeTo,
          vehicleGradeToError:
            this.state.vehicleGradeTo === null
              ? true
              : this.state.vehicleGradeToError,
        });
      }
    }
  };

  handleTextBoxBlurTo = (name) => (event) => {
    if (this.state.vehicleGradeTo !== null && !this.state.vehicleGradeToError) {
      let gradeValue = Number(this.state.vehicleGradeTo);
      if (this.countDecimals(gradeValue) === 0) {
        this.setState({
          vehicleGradeTo: gradeValue.toFixed(1),
          errorMsgvehicleGradeFrom:
            this.state.vehicleGradeFrom === null
              ? "From cannot be empty."
              : this.state.errorMsgvehicleGradeFrom,
          vehicleGradeFromError:
            this.state.vehicleGradeFrom === null
              ? true
              : this.state.vehicleGradeFromError,
        });
      }
    }
  };

  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
  };

  renderCheckboxOptions = (summary, criteria, stateKey) => {
    const { classes, userRoleHasMobilityRideshareView } = this.props;
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.heading}>
            {summary}
            <div className={classes.chipSection}>
              {this.state[stateKey] &&
                this.state[stateKey].map((option) => {
                  return (
                    <Chip
                      variant="outlined"
                      key={option}
                      onDelete={() => this.handleChipDelete(stateKey, option)}
                      label={option}
                      value={option}
                      disabled={userRoleHasMobilityRideshareView}
                    />
                  );
                })}
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <SearchCheckboxOptions
            criteria={criteria}
            stateKey={stateKey}
            isChecked={this.isChecked}
            handleCheck={this.handleCheck}
            userRoleHasMobilityRideshareView={userRoleHasMobilityRideshareView}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  renderAuctionCheckboxOptions = (summary, criteria, stateKey) => {
    const { classes, userRoleHasMobilityRideshareView } = this.props;
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.heading}>
            {summary}
            <div className={classes.chipSection}>
              {this.state[stateKey] &&
                this.state[stateKey].map((option) => {
                  return option === "AllAuction" ? null : (
                    <Chip
                      variant="outlined"
                      key={option}
                      onDelete={() =>
                        this.handleChipDeleteForAuction(stateKey, option)
                      }
                      label={option}
                      value={option}
                      disabled={userRoleHasMobilityRideshareView}
                    />
                  );
                })}
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <SearchCheckboxOptions
            criteria={criteria}
            stateKey={stateKey}
            isChecked={this.isCheckedAuction}
            handleCheck={this.handleCheckAuction}
            userRoleHasMobilityRideshareView={userRoleHasMobilityRideshareView}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  handleChipDelete = (stateKey, option) => {
    this.setState({
      [stateKey]: this.state[stateKey].filter((value) => value !== option),
    });
    if (stateKey === "selectedMake") {
      this.makeSelectionUpdated(
        this.state[stateKey].filter((value) => value !== option)
      );
    }
    if (stateKey === "selectedModel") {
      this.modelSelectionUpdated(
        this.state[stateKey].filter((value) => value !== option)
      );
    }
  };

  handleChipDeleteForAuction = (stateKey, option) => {
    let selectedList = this.state[stateKey].filter((value) => value !== option);
    let index = selectedList.indexOf("AllAuction");
    if (index !== -1) {
      selectedList.splice(selectedList.indexOf("AllAuction"), 1);
    }
    this.setState({
      [stateKey]: selectedList,
    });
  };

  toggleLoading = () => {
    this.setState({
      pageLoading: !this.state.pageLoading,
    });
  };

  onChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    if ([name][0] === "selectedOdometerFrom") {
      this.updateTo(
        odometerToOption,
        event.target.value,
        "odometerTo",
        odometerToOption.length
      );
    }
    if ([name][0] === "selectedOdometerTo") {
      this.updateFrom(odometerFromOption, event.target.value, "odometerFrom");
    }
    if ([name][0] === "selectedPriceFrom") {
      this.updateTo(
        priceToOption,
        event.target.value,
        "priceTo",
        priceToOption.length
      );
    }
    if ([name][0] === "selectedPriceTo") {
      this.updateFrom(priceFromOption, event.target.value, "priceFrom");
    }
    if ([name][0] === "selectedYearFrom") {
      if (event.target.value !== 0) {
        let fromUpdate = [];
        for (let i = 0; i < this.state.yearFrom.length; i++) {
          if (
            parseInt(event.target.value) <= parseInt(this.state.yearFrom[i])
          ) {
            fromUpdate.push(this.state.yearFrom[i]);
          }
        }
        this.setState({
          yearTo: fromUpdate,
          selectedYearTo:
            this.state.selectedYearTo < event.target.value
              ? 9999
              : this.state.selectedYearTo,
        });
      } else {
        this.setState({
          yearTo: this.state.yearFrom,
        });
      }
    }
  };

  updateFrom = (constant, value, state) => {
    let fromUpdate = [];
    fromUpdate.push(constant[0]);
    for (let i = 1; i < constant.length; i++) {
      if (parseInt(value) >= parseInt(constant[i].value)) {
        fromUpdate.push(constant[i]);
      }
    }
    this.setState({
      [state]: fromUpdate,
    });
  };

  updateTo = (constant, value, state, length) => {
    let toUpdate = [];
    for (let i = 0; i < constant.length - 1; i++) {
      if (parseInt(value) <= parseInt(constant[i].value)) {
        toUpdate.push(constant[i]);
      }
    }
    toUpdate.push(constant[length - 1]);
    this.setState({
      [state]: toUpdate,
    });
  };

  isChecked = (key, value) => {
    return this.state[key] && this.state[key].includes(value);
  };

  isCheckedAuction = (key, value) => {
    if (value === "AllAuction") {
      return this.state[key] && this.state[key].includes(value);
    } else {
      return (
        this.state[key] &&
        this.state[key].includes(value.substr(0, value.indexOf(" ")))
      );
    }
  };

  handleCheck = (name) => (evt) => {
    if (this.state[name].includes(evt.target.value)) {
      this.setState({
        [name]: this.state[name].filter((value) => value !== evt.target.value),
      });
      if (name === "selectedMake") {
        this.makeSelectionUpdated(
          this.state[name].filter((value) => value !== evt.target.value)
        );
      }
      if (name === "selectedModel") {
        this.modelSelectionUpdated(
          this.state[name].filter((value) => value !== evt.target.value)
        );
      }
    } else {
      this.setState({
        [name]: [...this.state[name], evt.target.value],
      });
      if (name === "selectedMake") {
        this.makeSelectionUpdated([...this.state[name], evt.target.value]);
      }
      if (name === "selectedModel") {
        this.modelSelectionUpdated([...this.state[name], evt.target.value]);
      }
    }
  };

  handleCheckAuction = (name) => (evt) => {
    const { auction } = this.state;
    if (evt.target.value === "AllAuction") {
      if (this.state[name].includes("AllAuction")) {
        this.setState({
          [name]: [],
        });
      } else {
        let allAuction = ["AllAuction"];
        for (let i = 0; i < auction.length; i++) {
          allAuction.push(auction[i].substr(0, auction[i].indexOf(" ")));
        }
        this.setState({
          [name]: allAuction,
        });
      }
    } else {
      if (
        this.state[name].includes(
          evt.target.value.substr(0, evt.target.value.indexOf(" "))
        )
      ) {
        let selectedList = this.state[name].filter(
          (value) =>
            value !== evt.target.value.substr(0, evt.target.value.indexOf(" "))
        );
        let index = selectedList.indexOf("AllAuction");
        if (index !== -1) {
          selectedList.splice(selectedList.indexOf("AllAuction"), 1);
        }
        this.setState({
          [name]: selectedList,
        });
      } else {
        this.setState({
          [name]: [
            ...this.state[name],
            evt.target.value.substr(0, evt.target.value.indexOf(" ")),
          ],
        });
      }
    }
  };

  advanceSearch = async () => {};

  toggleSearchSaveButton = () => {
    const {
      selectedMake,
      selectedModel,
      selectedModelNumber,
      selectedSeries,
      selectedInteriorColor,
      selectedExteriorColor,
      selectedStyle,
      selectedYearFrom,
      selectedYearTo,
      selectedDoor,
      selectedDriveTrain,
      selectedPriceFrom,
      selectedPriceTo,
      selectedOdometerFrom,
      selectedOdometerTo,
      selectedRegion,
      selectState,
      selectedEngine,
      selectedOptions,
      selectedTransmission,
      vehicleGradeTo,
      vehicleGradeFrom,
      selectedAuction,
      selectedCarfax,
    } = this.state;
    const { criteriaSelected, userRoleHasMobilityRideshareView } = this.props;
    if (userRoleHasMobilityRideshareView) {
      return true;
    }
    if (
      selectedMake &&
      selectedMake.length === 0 &&
      selectedModel &&
      selectedModel.length === 0 &&
      selectedModelNumber &&
      selectedModelNumber.length === 0 &&
      selectedSeries &&
      selectedSeries.length === 0 &&
      selectedInteriorColor &&
      selectedInteriorColor.length === 0 &&
      selectedExteriorColor &&
      selectedExteriorColor.length === 0 &&
      selectedStyle.length === 0 &&
      selectedYearFrom &&
      selectedYearFrom === 0 &&
      selectedYearTo &&
      selectedYearTo === 9999 &&
      selectedDoor &&
      selectedDoor.length === 0 &&
      selectedDriveTrain &&
      selectedDriveTrain.length === 0 &&
      selectedPriceFrom &&
      selectedPriceFrom === 0 &&
      selectedPriceTo &&
      selectedPriceTo === 999999 &&
      selectedOdometerFrom &&
      selectedOdometerFrom === 0 &&
      selectedOdometerTo &&
      selectedOdometerTo === 999999 &&
      selectedRegion &&
      selectedRegion.length === 0 &&
      selectedAuction &&
      selectedAuction.length === 0 &&
      selectedCarfax &&
      selectedCarfax.length === 0 &&
      selectState &&
      selectState.length === 0 &&
      selectedEngine &&
      selectedEngine.length === 0 &&
      selectedOptions &&
      selectedOptions.length === 0 &&
      selectedTransmission &&
      selectedTransmission.length === 0 &&
      vehicleGradeFrom.length === 0 &&
      vehicleGradeTo.length === 0
    ) {
      criteriaSelected(false);
      return true;
    }
    criteriaSelected(true);
    return false;
  };

  handleSelectChipDelete = (option, value) => {
    this.setState({
      [option]: value,
    });
  };

  handleTextBoxChipDelete = (value1, value2, value3) => {
    this.setState({
      [value1]: null,
      [value2]: "",
      [value3]: false,
    });
  };

  render() {
    const {
      make,
      model,
      modelNumber,
      series,
      interiorColor,
      exteriorColor,
      vehicleGradeFrom,
      errorMsgvehicleGradeFrom,
      errorMsgvehicleGradeTo,
      vehicleGradeTo,
      style,
      yearFrom,
      selectedYearFrom,
      yearTo,
      selectedYearTo,
      door,
      selectedDoor,
      driveTrain,
      priceFrom,
      selectedPriceFrom,
      priceTo,
      selectedPriceTo,
      odometerFrom,
      selectedOdometerFrom,
      odometerTo,
      selectedOdometerTo,
      region,
      auction,
      carfax,
      states,
      selectState,
      pageLoading,
      transmission,
      engine,
      options,
    } = this.state;
    const { classes, userRoleHasMobilityRideshareView, isFromViewEdit } =
      this.props;
    return (
      <React.Fragment>
        {pageLoading ? (
          <Loading />
        ) : (
          <div className={classes.mobSearchRight}>
            <div className={classes.criteriaWrapper}>
              <div className={classes.scrollBar}>
                <div
                  className={
                    isFromViewEdit && userRoleHasMobilityRideshareView
                      ? classes.disabledCriteria
                      : null
                  }
                >
                  {this.renderAuctionCheckboxOptions(
                    "Auction",
                    auction,
                    "selectedAuction",
                    this.isCheckedAuction
                  )}
                  {this.renderCheckboxOptions(
                    "Body Style/Type",
                    style,
                    "selectedStyle",
                    this.isChecked
                  )}
                  {this.renderCheckboxOptions(
                    "Carfax Options",
                    carfax,
                    "selectedCarfax",
                    this.isChecked
                  )}
                  {this.renderCheckboxOptions(
                    "Doors",
                    door,
                    "selectedDoor",
                    this.isChecked,
                    selectedDoor
                  )}
                  {this.renderCheckboxOptions(
                    "Drive Train",
                    driveTrain,
                    "selectedDriveTrain",
                    this.isChecked
                  )}
                  {this.renderCheckboxOptions(
                    "Engine",
                    engine,
                    "selectedEngine",
                    this.isChecked
                  )}
                  {this.renderCheckboxOptions(
                    "Exterior Color",
                    exteriorColor,
                    "selectedExteriorColor",
                    this.isChecked
                  )}
                  {this.renderCheckboxOptions(
                    "Interior Color",
                    interiorColor,
                    "selectedInteriorColor",
                    this.isChecked
                  )}
                  {this.renderCheckboxOptions(
                    "Make",
                    make,
                    "selectedMake",
                    this.isChecked
                  )}
                  {this.renderCheckboxOptions(
                    "Model",
                    model,
                    "selectedModel",
                    this.isChecked
                  )}
                  {this.renderCheckboxOptions(
                    "Model #",
                    modelNumber,
                    "selectedModelNumber",
                    this.isChecked
                  )}
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div className={classes.heading}>
                        Odometer
                        {selectedOdometerFrom !== 0 ? (
                          <div className={classes.chipSection}>
                            {" "}
                            From :{" "}
                            <Chip
                              variant="outlined"
                              key={selectedOdometerFrom}
                              onDelete={() =>
                                this.handleSelectChipDelete(
                                  "selectedOdometerFrom",
                                  0
                                )
                              }
                              label={selectedOdometerFrom}
                              value={selectedOdometerFrom}
                              disabled={userRoleHasMobilityRideshareView}
                            />{" "}
                          </div>
                        ) : null}
                        {selectedOdometerTo !== 999999 ? (
                          <div className={classes.chipSection}>
                            {" "}
                            To :{" "}
                            <Chip
                              variant="outlined"
                              key={selectedOdometerTo}
                              onDelete={() =>
                                this.handleSelectChipDelete(
                                  "selectedOdometerTo",
                                  999999
                                )
                              }
                              label={selectedOdometerTo}
                              value={selectedOdometerTo}
                              disabled={userRoleHasMobilityRideshareView}
                            />{" "}
                          </div>
                        ) : null}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.selectDivStyle}>
                      {this.renderSelectComponentWithTextValue(
                        "odometerFrom",
                        selectedOdometerFrom,
                        "selectedOdometerFrom",
                        odometerFrom,
                        "From:"
                      )}
                      {this.renderSelectComponentWithTextValue(
                        "odometerTo",
                        selectedOdometerTo,
                        "selectedOdometerTo",
                        odometerTo,
                        "To:"
                      )}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {this.renderCheckboxOptions(
                    "Options",
                    options,
                    "selectedOptions",
                    this.isChecked
                  )}
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div className={classes.heading}>
                        Price
                        {selectedPriceFrom !== 0 ? (
                          <div className={classes.chipSection}>
                            {" "}
                            From :{" "}
                            <Chip
                              variant="outlined"
                              key={selectedPriceFrom}
                              onDelete={() =>
                                this.handleSelectChipDelete(
                                  "selectedPriceFrom",
                                  0
                                )
                              }
                              label={selectedPriceFrom}
                              value={selectedPriceFrom}
                              disabled={userRoleHasMobilityRideshareView}
                            />{" "}
                          </div>
                        ) : null}
                        {selectedPriceTo !== 999999 ? (
                          <div className={classes.chipSection}>
                            {" "}
                            To :{" "}
                            <Chip
                              variant="outlined"
                              key={selectedPriceTo}
                              onDelete={() =>
                                this.handleSelectChipDelete(
                                  "selectedPriceTo",
                                  999999
                                )
                              }
                              label={selectedPriceTo}
                              value={selectedPriceTo}
                              disabled={userRoleHasMobilityRideshareView}
                            />{" "}
                          </div>
                        ) : null}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.selectDivStyle}>
                      {this.renderSelectComponentWithTextValue(
                        "priceFrom",
                        selectedPriceFrom,
                        "selectedPriceFrom",
                        priceFrom,
                        "From:"
                      )}
                      {this.renderSelectComponentWithTextValue(
                        "priceTo",
                        selectedPriceTo,
                        "selectedPriceTo",
                        priceTo,
                        "To:"
                      )}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {this.renderCheckboxOptions(
                    "Region",
                    region,
                    "selectedRegion",
                    this.isChecked
                  )}
                  {this.renderCheckboxOptions(
                    "Series",
                    series,
                    "selectedSeries",
                    this.isChecked
                  )}
                  {this.renderSelectComponent(
                    "State",
                    selectState,
                    "selectState",
                    states,
                    "State",
                    "Select"
                  )}
                  {this.renderCheckboxOptions(
                    "Transmission",
                    transmission,
                    "selectedTransmission",
                    this.isChecked
                  )}

                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div className={classes.heading}>
                        Vehicle Grade
                        {vehicleGradeFrom ? (
                          <div className={classes.chipSection}>
                            {" "}
                            From :{" "}
                            <Chip
                              variant="outlined"
                              key={vehicleGradeFrom}
                              onDelete={() =>
                                this.handleTextBoxChipDelete(
                                  "vehicleGradeFrom",
                                  "errorMsgvehicleGradeFrom",
                                  "vehicleGradeFromError"
                                )
                              }
                              label={vehicleGradeFrom}
                              value={vehicleGradeFrom}
                              disabled={userRoleHasMobilityRideshareView}
                            />{" "}
                          </div>
                        ) : null}
                        {vehicleGradeTo ? (
                          <div className={classes.chipSection}>
                            {" "}
                            To :{" "}
                            <Chip
                              variant="outlined"
                              key={vehicleGradeTo}
                              onDelete={() =>
                                this.handleTextBoxChipDelete(
                                  "vehicleGradeTo",
                                  "errorMsgvehicleGradeTo",
                                  "vehicleGradeToError"
                                )
                              }
                              label={vehicleGradeTo}
                              value={vehicleGradeTo}
                              disabled={userRoleHasMobilityRideshareView}
                            />{" "}
                          </div>
                        ) : null}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.selectDivStyle}>
                      <TextField
                        label="Enter From Value"
                        id="selectedvehicleGradeFrom"
                        variant="filled"
                        size="small"
                        onChange={this.handleFromTextBoxChange(
                          "vehicleGradeFrom"
                        )}
                        disabled={userRoleHasMobilityRideshareView}
                        onBlur={this.handleTextBoxBlurFrom("vehicleGradeFrom")}
                        style={{ color: "red" }}
                        value={vehicleGradeFrom}
                        type="number"
                      />
                      <div
                        id="errormsg"
                        style={{ color: "red", fontSize: "medium" }}
                      >
                        {errorMsgvehicleGradeFrom}
                      </div>

                      <TextField
                        label="Enter To Value"
                        id="selectedvehicleGradeTo"
                        variant="filled"
                        size="small"
                        onChange={this.handleToTextBoxChange("vehicleGradeTo")}
                        disabled={userRoleHasMobilityRideshareView}
                        onBlur={this.handleTextBoxBlurTo("vehicleGradeTo")}
                        value={vehicleGradeTo}
                        type="number"
                      />
                      <div style={{ color: "red", fontSize: "medium" }}>
                        {errorMsgvehicleGradeTo}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div className={classes.heading}>
                        Year
                        {selectedYearFrom !== 0 ? (
                          <div className={classes.chipSection}>
                            {" "}
                            From :{" "}
                            <Chip
                              variant="outlined"
                              key={selectedYearFrom}
                              onDelete={() =>
                                this.handleSelectChipDelete(
                                  "selectedYearFrom",
                                  0
                                )
                              }
                              label={selectedYearFrom}
                              value={selectedYearFrom}
                              disabled={userRoleHasMobilityRideshareView}
                            />{" "}
                          </div>
                        ) : null}
                        {selectedYearTo !== 9999 ? (
                          <div className={classes.chipSection}>
                            {" "}
                            To :{" "}
                            <Chip
                              variant="outlined"
                              key={selectedYearTo}
                              onDelete={() =>
                                this.handleSelectChipDelete(
                                  "selectedYearTo",
                                  9999
                                )
                              }
                              label={selectedYearTo}
                              value={selectedYearTo}
                              disabled={userRoleHasMobilityRideshareView}
                            />{" "}
                          </div>
                        ) : null}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.selectDivStyle}>
                      {this.renderSelectComponentSimple(
                        "yearFrom",
                        selectedYearFrom,
                        "selectedYearFrom",
                        yearFrom,
                        "From:",
                        0
                      )}
                      {this.renderSelectComponentSimple(
                        "yearTo",
                        selectedYearTo,
                        "selectedYearTo",
                        yearTo,
                        "To:",
                        9999
                      )}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    id="advanceSearch"
                    className={classes.button}
                    onClick={(evt) => this.advanceSearch()}
                    disabled={this.toggleSearchSaveButton()}
                  >
                    Search
                  </Button>
                  <Button
                    id="clearSearch"
                    className={classes.button}
                    onClick={(evt) => this.clearSearch()}
                    disabled={this.toggleSearchSaveButton()}
                  >
                    Clear Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    stateInfo: state.stateInfo,
    advancedSearchCriteriaWrapperData:
      state.ui.advancedSearchCriteriaWrapperData,
    advancedSearchCriteriaData: state.ui.advancedSearchCriteriaData,
    auctionLocationData: state.mobilityState.auctionLocationData,
    mobilitySearchCriteria: state.mobilityState.mobilitySearchCriteria,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles)(MobilityVehicleSearchCriteria)
);
