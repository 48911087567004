const API_URL = process.env.REACT_APP_API_URL;

export async function upstreamSearch(user) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId: user.userId,
      dealerId: user.dealerId,
      dealership: user.franchise ? user.franchise : "TBSG",
      datePattern: user.datePattern,
      timeZone: user.timeZone,
      userStateId: user.userStateId,
      loggedInUserLatitude: user.latitude,
      loggedInUserLongitude: user.longitude,
    }),
  };
  try {
    const response = await fetch(`${API_URL}/search/upstreamSearch`, options);
    return response.json();
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const quickSearch = async (user, searchKeys) => {
  const token = sessionStorage.getItem("token");
  const {
    userId,
    dealerId,
    userStateId,
    timeZone,
    longitude,
    latitude,
    datePattern,
  } = user;
  const dealership = user.franchise ? user.franchise : "TBSG";
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/search/quickSearch?
		userId=${parseInt(userId, 10)}
		&dealerId=${parseInt(dealerId, 10)}
		&startIndex=0
		&userStateId=${userStateId}
		&maxResults=200
		&timeZone=${timeZone}
		&longitude=${longitude}
		&latitude=${latitude}
		&searchKey=${searchKeys}
		&dealership=${dealership}
		&datePattern=${datePattern}
		`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
