import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import moment from "moment";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Button,
  Input,
  Snackbar,
  SnackbarContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import Location from "../components/Location";

import {
  updateArbitration,
  createArbitration,
} from "../services/AdminServices";
import { validatePhone, validateEmail } from "../services/ValidationServices";

const INVALID_ARB_GRND_DLR_MSG = {
  dialogMessage1: `Vehicles grounded and purchased by the same dealer are sold "AS-IS" and are not subject to arbitration.`,
  dialogMessage2: `Please ensure that all vehicles grounded at your store are thoroughly inspected prior to making a purchase decision. 
											(DealerDirect Participating Agreement, Section 15)`,
};

const INVALID_ARB_PUR_DT_MSG = {
  dialogMessage1: `Any damage or missing equipment incorrectly disclosed on the Dealer Direct condition report, 
	affecting the value of the vehicle, must be acknowledged within Dealer Direct no later than 14 days 
	of the vehicle purchase date to be considered for arbitration/repurchase.`,
  dialogMessage2: `Any damage or missing equipment incorrectly disclosed on the Dealer Direct condition report, 
	affecting the value of the vehicle, acknowledged within Dealer Direct after 14 days of the vehicle purchase 
	date will not be subject to arbitration/repurchase and will be automatically declined.`,
};

const INVALID_ARB_DELIVERY_DT_MSG = {
  dialogMessage1: `Any damage or missing equipment incorrectly disclosed on the Dealer Direct condition report, 
					affecting the value of the vehicle, must be acknowledged within Dealer Direct no later than 14 days 
					following delivery of the vehicle to be considered for arbitration/repurchase.`,
  dialogMessage2: `Any damage or missing equipment incorrectly disclosed on the Dealer Direct condition report, affecting the value of the vehicle, 
					and acknowledged within Dealer Direct after 14 days of the vehicle delivery, will not be subject to arbitration/repurchase and will 
					be automatically declined.`,
};

const EMPTY_SELECTED_VEHICLE_LOCATION = {
  locationDTO: {
    name: "",
    addressDTO: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
    },
  },
};

const styles = (theme) => ({
  container: {
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflow: "auto",
    position: "relative",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  preferenceContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  selectInput: {
    fontSize: ".8rem",
    width: "12.3rem",
    textAlign: "center",
  },
  emailSettings: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
  },
  inspectionInformation: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    maxWidth: "33.3333%",
  },
  lowerInspectionInformation: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
  },
  fieldWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "2rem",
    margin: ".25rem",
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "11rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fieldLabelRed: {
    backgroundColor: theme.palette.primary.redHighlight,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "11rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  multilineFieldWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "5rem",
    margin: ".25rem",
    position: "relative",
  },
  shortMultilineFieldWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "2.5rem",
    margin: ".25rem",
    position: "relative",
  },
  textField: {
    margin: 0,
    width: "13rem",
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    height: "2rem",
    borderRadius: 0,
  },
  multilineTextField: {
    margin: 0,
    width: "16.5rem",
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    height: "5rem",
    borderRadius: 0,
    fontSize: theme.font.small,
  },
  systemMessage: {
    position: "absolute",
    top: "2rem",
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
  },
  helpText: {
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
  },
  arbitrationTypeSelect: {
    width: "16.5rem",
    transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    marginTop: 0,
    height: "2rem",
    padding: 0,
  },
  stateSelect: {
    width: "13rem",
    transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    marginTop: 0,
    height: "2rem",
    padding: 0,
  },
  checkboxWrapper: {
    display: "flex",
    alignItems: "center",
  },
  arbitrationTypeWrapper: {
    height: "10rem",
    overflowY: "auto",
    backgroundColor: theme.palette.light.gray,
    width: "27.5rem",
    marginLeft: ".25rem",
  },
  checkboxRoot: {
    "&$checked": {
      color: theme.palette.primary.gray,
    },
  },
  checked: {},
  checkboxText: {
    fontSize: theme.font.small,
  },
  attachmentButton: {
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.gray,
    fontSize: theme.font.small,
    fontWeight: "bold",
    width: "6rem",
    height: "2rem",
    marginLeft: ".5rem",
  },
  attachmentFileText: {
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    marginLeft: ".25rem",
    width: "9rem",
  },
  button: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.gray,
    fontSize: theme.font.small,
    fontWeight: "bold",
    width: "9rem",
    color: theme.palette.primary.white,
  },
  buttonWrapper: {
    display: "flex",
    height: "2rem",
    width: "33rem",
    justifyContent: "space-around",
    position: "absolute",
    top: "50rem",
    left: "3%",
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    fontSize: theme.font.small,
    height: "2rem",
    alignItems: "center",
  },
  attachmentWrapper: {
    display: "flex",
    width: "16rem",
    backgroundColor: theme.palette.light.gray,
    height: "5rem",
    overflowY: "auto",
    paddingLeft: ".5rem",
    flexDirection: "column",
  },
  locationCheckboxWrapper: {
    marginLeft: ".4rem",
  },
  logWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "16rem",
    backgroundColor: theme.palette.light.gray,
    height: "5rem",
    overflowY: "auto",
    paddingLeft: ".5rem",
  },
  boldedText: {
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
    fontWeight: "bold",
  },
  messageText: {
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
  },
  errorSnackBar: {
    marginTop: "7rem",
  },
  dialogTitle: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.primary.redHighlight,
    color: theme.palette.primary.white,
    padding: 0,
    paddingLeft: "1rem",
  },
  addLocDialogTitle: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    padding: 0,
    paddingLeft: "1rem",
  },
  dialogRoot: {
    maxWidth: "100rem",
  },
  cancelArbitrationCheckbox: {
    position: "absolute",
    top: "47rem",
    left: "16%",
  },
  disclosureStatement: {
    position: "absolute",
    top: "46rem",
    left: "2%",
    fontSize: theme.font.small,
  },
  fileWarningMessage: {
    position: "absolute",
    top: "2rem",
    fontSize: theme.font.small,
    color: theme.palette.primary.redHighlight,
    fontWeight: "Bold",
  },
  "@global": {
    ".MuiSelect-selectMenu": {
      height: "1.1rem",
      textAlign: "center",
    },
    ".MuiFilledInput-multiline": {
      padding: ".25rem",
      fontSize: theme.font.small,
      height: "10rem",
    },
    ".MuiDialog-paperWidthSm": {
      maxWidth: "50rem",
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "40%",
    },
  },
};

const AWS_URL = process.env.REACT_APP_WCM_CONTENT;

export class EditArbitration extends Component {
  state = {
    dealerContactName: "",
    dealerPhoneNum: "",
    dealerEmailId: "",
    dealerName: "",
    description: "",
    perSelectedLocOption: "",
    locationName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    stateCode: "",
    zip: "",
    locDealerContactName: "",
    locDealerPhoneNum: "",
    arbitrationMultiTypeId: [],
    arbitrationType: "",
    arbitrationAttachmentCollection: [],
    activityHistoryCollection: [],
    selectedFile: null,
    message: null,
    arbitrationId: "",
    arbitrationCreated: false,
    selectedVehicleLocation: EMPTY_SELECTED_VEHICLE_LOCATION,
    dialogOpen: false,
    dialogMessage: null,
    cancelArbitration: false,
    dealerCalledDate: "",
    openAddLocation: false,
    invalidArbWarningAccepted: false,
    locationAdded: false,
    fileToLarge: false,
  };

  openWarningDialog = () => {
    const { selectedArbitration } = this.props;

    if (this.props.createArbitrationToggled) {
      if (
        selectedArbitration.dealerName ===
        selectedArbitration.groundingDealerName
      ) {
        this.setState({
          dialogOpen: true,
          dialogMessage: INVALID_ARB_GRND_DLR_MSG,
          invalidArbWarningAccepted: false,
        });
      } else if (
        moment(new Date(selectedArbitration.purchaseDate))
          .add("14", "days")
          .isBefore(moment())
      ) {
        if (!selectedArbitration.transDeliveredDate) {
          this.setState({
            dialogOpen: true,
            dialogMessage: INVALID_ARB_PUR_DT_MSG,
            invalidArbWarningAccepted: false,
          });
        } else if (
          !selectedArbitration.transportLocation &&
          !selectedArbitration.transDeliveredDate &&
          !selectedArbitration.isTransDelivered &&
          selectedArbitration.isTransDelivered === true &&
          !selectedArbitration.validAllowedArbDaysForCAN
        ) {
          if (
            moment(selectedArbitration.transDeliveredDendDate).isBefore(
              moment(new Date(selectedArbitration.purchaseDate))
            )
          ) {
            this.setState({
              dialogOpen: true,
              dialogMessage: INVALID_ARB_PUR_DT_MSG,
              invalidArbWarningAccepted: false,
            });
          } else if (
            moment().diff(
              moment(selectedArbitration.transDeliveredDate),
              "days"
            ) > selectedArbitration.validAllowedArbDaysForCAN
          ) {
            this.setState({
              dialogOpen: true,
              dialogMessage: INVALID_ARB_DELIVERY_DT_MSG,
              invalidArbWarningAccepted: false,
            });
          }
        }
      }
    }
  };

  componentDidMount = () => {
    const {
      selectedArbitration,
      user,
      accountSetting,
      createArbitrationToggled,
      arbReqType,
      stateInfo,
    } = this.props;
    this.openWarningDialog();
    this.setState({
      userId: user.userId,
      arbitrationId: selectedArbitration.arbitrationId,
      dealerCalledDate: selectedArbitration.dealerCalledDate
        ? selectedArbitration.dealerCalledDate
        : moment().format("MM/DD/YYYY"),
      dealerContactName: selectedArbitration.dealerContactName
        ? selectedArbitration.dealerContactName
        : `${accountSetting.firstName} ${accountSetting.lastName}`,
      dealerPhoneNum: selectedArbitration.dealerPhoneNum
        ? selectedArbitration.dealerPhoneNum
        : accountSetting.phone,
      dealerEmailId: selectedArbitration.dealerEmailId
        ? selectedArbitration.dealerEmailId
        : accountSetting.email,
      dealerName: selectedArbitration.dealerName,
      locationName:
        !createArbitrationToggled && selectedArbitration.locationName
          ? selectedArbitration.locationName
          : "",
      perSelectedLocOption: selectedArbitration.perSelectedLocOption,
      addressLine1: selectedArbitration.addressLine1
        ? selectedArbitration.addressLine1
        : "",
      addressLine2: selectedArbitration.addressLine2
        ? selectedArbitration.addressLine2
        : "",
      city: selectedArbitration.city ? selectedArbitration.city : "",
      state: selectedArbitration.state ? selectedArbitration.state : "",
      stateCode: +selectedArbitration.stateCode
        ? +selectedArbitration.stateCode
        : selectedArbitration.state
        ? stateInfo.filter(
            (state) => state.name === selectedArbitration.state
          )[0].stateId
        : "",
      zip: selectedArbitration.zip ? selectedArbitration.zip : "",
      locDealerContactName: selectedArbitration.locDealerContactName
        ? selectedArbitration.locDealerContactName
        : "",
      locDealerPhoneNum: selectedArbitration.locDealerPhoneNum
        ? selectedArbitration.locDealerPhoneNum
        : "",
      arbitrationMultiTypeId: selectedArbitration.arbitrationMultiTypeId,
      arbitrationAttachmentCollection:
        selectedArbitration.arbitrationAttachmentCollection,
      arbReqType,
      arbitrationCreated: selectedArbitration.arbitrationId,
    });
  };

  handleChange = (name) => (event) => {
    const { arbitrationTypes, stateInfo, selectedArbitration } = this.props;
    if (name === "perSelectedLocOption") {
      if (Number(event.target.value) !== 2) {
        this.setState({
          [name]: Number(event.target.value),
          locationId: "",
          locationName: "",
          addressLine1: "",
          city: "",
          state: "",
          stateCode: "",
          zip: "",
          selectedVehicleLocation: EMPTY_SELECTED_VEHICLE_LOCATION,
        });
      } else {
        this.setState({
          [name]: Number(event.target.value),
          locationId: selectedArbitration.groundingDealerId,
          locationName: selectedArbitration.groundingDealerName,
          addressLine1: selectedArbitration.groundingAddressLine1,
          city: selectedArbitration.groundingCity,
          state: selectedArbitration.groundingState,
          stateCode: selectedArbitration.groundingStateCode,
          zip: selectedArbitration.groundingZip,
        });
      }
    } else if (name === "arbitrationMultiTypeId") {
      this.setState({
        [name]: event.target.value,
        arbitrationType: arbitrationTypes[event.target.value[0]],
      });
    } else if (name === "selectedVehicleLocation") {
      if (event.target.value) {
        const vehicleLocation = event.target.value;
        this.setState({
          [name]: vehicleLocation,
          locationId: vehicleLocation.locationId,
          locationName: vehicleLocation.locationDTO.name,
          addressLine1: vehicleLocation.locationDTO.addressDTO.addressLine1,
          city: vehicleLocation.locationDTO.addressDTO.city,
          state: vehicleLocation.locationDTO.addressDTO.state,
          zip: vehicleLocation.locationDTO.addressDTO.zip,
          stateCode: stateInfo.filter(
            (state) =>
              vehicleLocation.locationDTO.addressDTO.state === state.stateCode
          )[0].stateId,
        });
      } else {
        this.setState({
          [name]: EMPTY_SELECTED_VEHICLE_LOCATION,
          openAddLocation: true,
        });
      }
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };
  toggleCancel = () => {
    this.setState({
      cancelArbitration: !this.state.cancelArbitration,
    });
  };

  handleStateChange = (event) => {
    const { stateInfo } = this.props;
    this.setState({
      state: stateInfo.find((state) => state.stateId === event.target.value)
        .stateCode,
      stateCode: event.target.value,
    });
  };

  unSelectArbitrationTypeOption = (event) => {
    const { arbitrationMultiTypeId } = this.state;
    const arbitrationTypes = arbitrationMultiTypeId.filter(
      (option) => option !== event.target.value
    );
    this.setState({
      arbitrationMultiTypeId: arbitrationTypes,
    });
  };

  selectFile = (evt) => {
    if (this.isFileSizeWithinLimit(evt.target.files[0])) {
      this.setState({
        selectedFile: evt.target.files[0],
        fileToLarge: false,
      });
    } else {
      this.setState({
        fileToLarge: true,
      });
    }
  };

  isFileSizeWithinLimit = (file) => {
    return file.size < 4194304;
  };

  updateArbitration = async (exit) => {
    const { handleUpdateArbitration, removeCancelledArbitration } = this.props;
    const { cancelArbitration, arbitrationId } = this.state;
    const response = await updateArbitration(this.state);
    if (response) {
      if (cancelArbitration) {
        removeCancelledArbitration(arbitrationId);
      }
      if (response.status !== "false" && !exit) {
        this.setState({
          message: "Update successful!",
          arbitrationAttachmentCollection:
            response.arbitrationAttachmentCollection,
        });
      } else if (response.status !== "false") {
        handleUpdateArbitration();
      } else {
        this.setState({
          message: "Update was not successful.",
        });
      }
    } else {
      this.setState({
        message: "The server is having issues.  Please try again later.",
      });
    }
  };

  createArbitration = async (exit) => {
    const { handleCreateArbitration, selectedArbitration } = this.props;
    const data = {
      ...this.state,
      vin: selectedArbitration.vin,
    };
    if (this.validateSubmission()) {
      const response = await createArbitration(data);
      if (response) {
        if (response.arbitrationId && !exit) {
          this.setState({
            message: "Arbitration Created!",
            arbitrationAttachmentCollection:
              response.arbitrationAttachmentCollection,
            arbitrationCreated: true,
            arbitrationId: response.arbitrationId,
          });
        } else if (response.status !== "false") {
          handleCreateArbitration();
        } else {
          this.setState({
            message: "Update was not successful.",
          });
        }
      } else {
        this.setState({
          message: "The server is having issues.  Please try again later.",
        });
      }
    } else {
      this.setState({
        message:
          "Please verify location information, contact information, and select at least one arbitration type.",
      });
    }
  };

  validateSubmission = () => {
    const {
      dealerContactName,
      dealerPhoneNum,
      dealerEmailId,
      perSelectedLocOption,
      locationName,
      addressLine1,
      city,
      state,
      stateCode,
      zip,
      locDealerContactName,
      locDealerPhoneNum,
      arbitrationMultiTypeId,
      arbitrationType,
      selectedVehicleLocation,
      arbitrationCreated,
    } = this.state;
    if (arbitrationCreated) {
      return (
        validatePhone(dealerPhoneNum) &&
        dealerContactName &&
        validateEmail(dealerEmailId)
      );
    } else {
      switch (perSelectedLocOption) {
        case 0:
          return (
            validatePhone(dealerPhoneNum) &&
            dealerContactName &&
            validateEmail(dealerEmailId) &&
            locationName &&
            addressLine1 &&
            state &&
            stateCode &&
            zip &&
            city &&
            arbitrationMultiTypeId.length > 0 &&
            arbitrationType &&
            locDealerContactName &&
            validatePhone(locDealerPhoneNum)
          );
        case 1:
          return (
            validatePhone(dealerPhoneNum) &&
            dealerContactName &&
            validateEmail(dealerEmailId) &&
            selectedVehicleLocation.locationDTO.name &&
            arbitrationMultiTypeId.length > 0
          );
        case 2:
          return arbitrationMultiTypeId.length > 0;
        default:
          return false;
      }
    }
  };

  renderVehicleLocation = () => {
    const {
      perSelectedLocOption,
      locationName,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zip,
      locDealerContactName,
      locDealerPhoneNum,
      selectedVehicleLocation,
      arbitrationCreated,
    } = this.state;
    const {
      classes,
      stateInfo,
      accountSetting,
      createArbitrationToggled,
      selectedArbitration,
    } = this.props;
    const {
      groundingDealerName,
      groundingAddressLine1,
      groundingCity,
      groundingStateCode,
      groundingZip,
    } = this.props.selectedArbitration;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
          fontSize: ".8rem",
        },
      },
    };
    switch (perSelectedLocOption) {
      case 0:
        return (
          <React.Fragment>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Dealership Name
              </Typography>
              <TextField
                id="locationName"
                className={classes.textField}
                onChange={this.handleChange("locationName")}
                value={locationName}
                variant="filled"
                autoComplete="off"
                name="locationName"
                InputProps={InputProps}
                disabled={!createArbitrationToggled}
                placeholder="Required Field"
                required
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Address 1</Typography>
              <TextField
                id="addressLine1"
                className={classes.textField}
                onChange={this.handleChange("addressLine1")}
                value={addressLine1}
                variant="filled"
                autoComplete="off"
                name="addressLine1"
                InputProps={InputProps}
                disabled={!createArbitrationToggled}
                placeholder="Required Field"
                required
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Address 2</Typography>
              <TextField
                id="addressLine2"
                className={classes.textField}
                onChange={this.handleChange("addressLine2")}
                value={addressLine2}
                variant="filled"
                autoComplete="off"
                name="addressLine2"
                InputProps={InputProps}
                disabled={!createArbitrationToggled}
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>City</Typography>
              <TextField
                id="city"
                className={classes.textField}
                onChange={this.handleChange("city")}
                value={city}
                variant="filled"
                autoComplete="off"
                name="city"
                InputProps={InputProps}
                disabled={!createArbitrationToggled}
                placeholder="Required Field"
                required
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>State</Typography>
              <Select
                id="stateCode"
                className={classes.stateSelect}
                value={stateCode}
                onChange={(evt) => this.handleStateChange(evt)}
                name="stateCode"
                displayEmpty
                disableUnderline
                disabled={
                  !createArbitrationToggled || arbitrationCreated === true
                }
              >
                <MenuItem
                  style={{
                    fontSize: ".8rem",
                  }}
                  value={""}
                >
                  {"Required Field"}
                </MenuItem>
                {stateInfo.map((state) => (
                  <MenuItem
                    style={{
                      fontSize: ".8rem",
                    }}
                    key={state.stateId}
                    value={state.stateId}
                  >
                    {state.stateCode}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Zip</Typography>
              <TextField
                id="zip"
                className={classes.textField}
                onChange={this.handleChange("zip")}
                value={zip}
                variant="filled"
                autoComplete="off"
                name="zip"
                InputProps={InputProps}
                disabled={!createArbitrationToggled}
                placeholder="Required Field"
                required
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Contact Name
              </Typography>
              <TextField
                id="locDealerContactName"
                className={classes.textField}
                value={locDealerContactName}
                onChange={this.handleChange("locDealerContactName")}
                variant="filled"
                autoComplete="off"
                name="locDealerContactName"
                InputProps={InputProps}
                disabled={!createArbitrationToggled}
                placeholder="Required Field"
                required
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Phone Number
              </Typography>
              <TextField
                id="locDealerPhoneNum"
                className={classes.textField}
                value={locDealerPhoneNum}
                onChange={this.handleChange("locDealerPhoneNum")}
                variant="filled"
                autoComplete="off"
                name="locDealerPhoneNum"
                InputProps={InputProps}
                disabled={!createArbitrationToggled}
                placeholder="Required Field"
                required
              />
            </div>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Location Name
              </Typography>
              {!arbitrationCreated ? (
                <Select
                  id="selectVehicleLocation"
                  className={classes.arbitrationTypeSelect}
                  value={
                    selectedVehicleLocation
                      ? selectedVehicleLocation
                      : EMPTY_SELECTED_VEHICLE_LOCATION
                  }
                  disableUnderline
                  displayEmpty
                  disabled={
                    !createArbitrationToggled || arbitrationCreated === true
                  }
                  onChange={this.handleChange("selectedVehicleLocation")}
                  input={<Input id="select-multiple" />}
                  MenuProps={MenuProps}
                >
                  {accountSetting.custLocations.map((location) => (
                    <MenuItem
                      style={{
                        fontSize: ".8rem",
                      }}
                      key={location.locationId}
                      value={location}
                    >
                      {location.locationDTO.name}
                    </MenuItem>
                  ))}
                  <MenuItem
                    style={{
                      fontSize: ".8rem",
                    }}
                    value={null}
                  >
                    {"Add Location"}
                  </MenuItem>
                </Select>
              ) : (
                <TextField
                  id="vehicleLocation"
                  className={classes.textField}
                  value={
                    locationName
                      ? locationName
                      : selectedArbitration.locationName
                  }
                  variant="filled"
                  autoComplete="off"
                  name="vehicleLocation"
                  InputProps={InputProps}
                  disabled
                />
              )}
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Address 1</Typography>
              <TextField
                id="groundingAddress"
                className={classes.textField}
                value={
                  selectedVehicleLocation.locationDTO.addressDTO.addressLine1
                    ? selectedVehicleLocation.locationDTO.addressDTO
                        .addressLine1
                    : selectedArbitration.addressLine1
                }
                variant="filled"
                autoComplete="off"
                name="addressLine1"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>City</Typography>
              <TextField
                id="groundingCity"
                className={classes.textField}
                value={
                  selectedVehicleLocation.locationDTO.addressDTO.city
                    ? selectedVehicleLocation.locationDTO.addressDTO.city
                    : selectedArbitration.city
                }
                variant="filled"
                autoComplete="off"
                name="city"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>State</Typography>
              <Select
                id="groundingStateCode"
                className={classes.stateSelect}
                value={
                  selectedVehicleLocation.locationDTO.addressDTO.state ||
                  createArbitrationToggled
                    ? selectedVehicleLocation.locationDTO.addressDTO.state
                    : selectedArbitration.groundingState
                }
                name="stateCode"
                displayEmpty
                disableUnderline
                disabled
              >
                <MenuItem
                  style={{
                    fontSize: ".8rem",
                  }}
                  value={""}
                >
                  {"Make a selection"}
                </MenuItem>
                {stateInfo.map((state) => (
                  <MenuItem
                    style={{
                      fontSize: ".8rem",
                    }}
                    key={state.stateId}
                    value={state.stateCode}
                  >
                    {state.stateCode}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Zip</Typography>
              <TextField
                id="groundingZip"
                className={classes.textField}
                value={
                  selectedVehicleLocation.locationDTO.addressDTO.zip ||
                  createArbitrationToggled
                    ? selectedVehicleLocation.locationDTO.addressDTO.zip
                    : selectedArbitration.groundingZip
                }
                variant="filled"
                autoComplete="off"
                name="zip"
                InputProps={InputProps}
                disabled
              />
            </div>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Dealership Name
              </Typography>
              <TextField
                id="groundingDealerName"
                className={classes.textField}
                value={groundingDealerName}
                variant="filled"
                autoComplete="off"
                name="locationName"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Address 1</Typography>
              <TextField
                id="groundingAddress"
                className={classes.textField}
                value={groundingAddressLine1}
                variant="filled"
                autoComplete="off"
                name="addressLine1"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>City</Typography>
              <TextField
                id="groundingCity"
                className={classes.textField}
                value={groundingCity}
                variant="filled"
                autoComplete="off"
                name="city"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>State</Typography>
              <Select
                id="groundingStateCode"
                className={classes.stateSelect}
                value={groundingStateCode}
                name="stateCode"
                displayEmpty
                disableUnderline
                disabled
              >
                {stateInfo.map((state) => (
                  <MenuItem
                    style={{
                      fontSize: ".8rem",
                    }}
                    key={state.stateId}
                    value={state.stateId}
                  >
                    {state.stateCode}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Zip</Typography>
              <TextField
                id="groundingZip"
                className={classes.textField}
                value={groundingZip}
                variant="filled"
                autoComplete="off"
                name="zip"
                InputProps={InputProps}
                disabled
              />
            </div>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  handleAddLocation = (resp) => {
    this.setState({ locationAdded: resp });
  };

  handleLocationDialog = () => {
    const { locationAdded } = this.state;
    const { accountSetting } = this.props;

    this.setState({
      openAddLocation: false,
      selectedVehicleLocation: locationAdded
        ? accountSetting.custLocations[accountSetting.custLocations.length - 1]
        : EMPTY_SELECTED_VEHICLE_LOCATION,
      locationAdded: false,
    });
  };

  render() {
    const {
      classes,
      arbitrationTypes,
      handleCreateArbitration,
      handleUpdateArbitration,
      selectedArbitration,
      createArbitrationToggled,
    } = this.props;
    const {
      vin,
      year,
      make,
      model,
      inspectionDate,
      fieldManager,
      regionalManager,
      groundingDealerName,
      purchasingDealerName,
      purchaseType,
      purchaseDate,
      purchaseAmount,
      transportationCost,
      dealerCode,
      dealerNoteLogs,
    } = this.props.selectedArbitration;
    const closedArbitration = selectedArbitration
      ? selectedArbitration.arbitrationStatus === "Closed"
      : false;
    const {
      dealerCalledDate,
      dealerContactName,
      dealerPhoneNum,
      dealerEmailId,
      perSelectedLocOption,
      arbitrationMultiTypeId,
      arbitrationAttachmentCollection,
      description,
      dealerName,
      selectedFile,
      message,
      arbitrationCreated,
      dialogOpen,
      openAddLocation,
      cancelArbitration,
      dialogMessage,
      locationAdded,
      fileToLarge,
    } = this.state;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
          fontSize: ".8rem",
        },
      },
    };
    const ATTACHMENT_URL = process.env.REACT_APP_ARBITRATION_ATTACHMENT_URL;
    return (
      <div className={classes.container}>
        <div className={classes.sectionContainer}>
          <div className={classes.inspectionInformation}>
            <Typography variant="h5" component="h3">
              Inspection Information
            </Typography>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>VIN</Typography>
              <TextField
                id="legalName"
                className={classes.textField}
                value={vin}
                variant="filled"
                autoComplete="off"
                name="legalName"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Year</Typography>
              <TextField
                id="year"
                className={classes.textField}
                value={year}
                variant="filled"
                autoComplete="off"
                name="year"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Make</Typography>
              <TextField
                id="make"
                className={classes.textField}
                value={make}
                variant="filled"
                autoComplete="off"
                name="make"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Model</Typography>
              <TextField
                id="model"
                className={classes.textField}
                value={model}
                variant="filled"
                autoComplete="off"
                name="model"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Inspection Date
              </Typography>
              <TextField
                id="inspectionDate"
                className={classes.textField}
                value={
                  inspectionDate
                    ? moment(inspectionDate).format("MMM DD, YYYY")
                    : ""
                }
                variant="filled"
                autoComplete="off"
                name="inspectionDate"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Field Manager
              </Typography>
              <TextField
                id="fieldManager"
                className={classes.textField}
                value={fieldManager}
                variant="filled"
                autoComplete="off"
                name="fieldManager"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Regional Manager
              </Typography>
              <TextField
                id="regionalManager"
                className={classes.textField}
                value={regionalManager}
                variant="filled"
                autoComplete="off"
                name="regionalManager"
                InputProps={InputProps}
                disabled
              />
            </div>
          </div>
          <div className={classes.inspectionInformation}>
            <Typography variant="h5" component="h3">
              Purchase Information
            </Typography>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Grounding Dealership
              </Typography>
              <TextField
                id="groundingDealerName"
                className={classes.textField}
                value={groundingDealerName}
                variant="filled"
                autoComplete="off"
                name="groundingDealerName"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Purchasing Dealership
              </Typography>
              <TextField
                id="purchasingDealerName"
                className={classes.textField}
                value={purchasingDealerName}
                variant="filled"
                autoComplete="off"
                name="purchasingDealerName"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Purchase Type
              </Typography>
              <TextField
                id="purchaseType"
                className={classes.textField}
                value={purchaseType}
                variant="filled"
                autoComplete="off"
                name="purchaseType"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Date Purchased
              </Typography>
              <TextField
                id="purchaseDate"
                className={classes.textField}
                value={purchaseDate}
                variant="filled"
                autoComplete="off"
                name="purchaseDate"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Purchased Amount
              </Typography>
              <TextField
                id="purchaseAmount"
                className={classes.textField}
                value={Number(purchaseAmount).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
                variant="filled"
                autoComplete="off"
                name="purchaseAmount"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Transportation Cost
              </Typography>
              <TextField
                id="transportationCost"
                className={classes.textField}
                value={Number(transportationCost).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
                variant="filled"
                autoComplete="off"
                name="transportationCost"
                InputProps={InputProps}
                disabled
              />
            </div>
          </div>
          <div className={classes.inspectionInformation}>
            <Typography variant="h5" component="h3">
              Dealership Information
            </Typography>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Arbitration Created
              </Typography>
              <TextField
                id="dealerCalledDate"
                className={classes.textField}
                value={dealerCalledDate}
                variant="filled"
                autoComplete="off"
                name="dealerCalledDate"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>Dealership</Typography>
              <TextField
                id="purchasingDealerName"
                className={classes.textField}
                value={purchasingDealerName}
                variant="filled"
                autoComplete="off"
                name="purchasingDealerName"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Dealer Code
              </Typography>
              <TextField
                id="dealerCode"
                className={classes.textField}
                value={dealerCode}
                variant="filled"
                autoComplete="off"
                name="dealerCode"
                InputProps={InputProps}
                disabled
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Contact Name
              </Typography>
              <TextField
                id="dealerContactName"
                className={classes.textField}
                value={dealerContactName}
                onChange={this.handleChange("dealerContactName")}
                variant="filled"
                autoComplete="off"
                name="dealerContactName"
                InputProps={InputProps}
                disabled={closedArbitration}
                placeholder="Required Field"
                required
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Phone Number
              </Typography>
              <TextField
                id="dealerPhoneNum"
                className={classes.textField}
                value={dealerPhoneNum}
                onChange={this.handleChange("dealerPhoneNum")}
                variant="filled"
                autoComplete="off"
                name="dealerPhoneNum"
                InputProps={InputProps}
                disabled={closedArbitration}
                placeholder="Required Field"
                required
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Email Address
              </Typography>
              <TextField
                id="dealerEmailId"
                className={classes.textField}
                value={dealerEmailId}
                onChange={this.handleChange("dealerEmailId")}
                variant="filled"
                autoComplete="off"
                name="dealerEmailId"
                InputProps={InputProps}
                disabled={closedArbitration}
                placeholder="Required Field"
                required
              />
            </div>
          </div>
        </div>
        <div className={classes.sectionContainer}>
          <div className={classes.lowerInspectionInformation}>
            <Typography variant="h5" component="h3">
              Vehicle Location
            </Typography>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Vehicle at your dealership?
              </Typography>
              <FormGroup row className={classes.locationCheckboxWrapper}>
                <FormControlLabel
                  disabled={
                    createArbitrationToggled === false || arbitrationCreated
                  }
                  onChange={this.handleChange("perSelectedLocOption")}
                  control={
                    <Checkbox
                      checked={perSelectedLocOption === 1}
                      value={1}
                      classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={
                    <Typography className={classes.checkboxText}>
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel
                  disabled={!createArbitrationToggled || arbitrationCreated}
                  onChange={this.handleChange("perSelectedLocOption")}
                  control={
                    <Checkbox
                      checked={perSelectedLocOption === 0}
                      value={0}
                      classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={
                    <Typography className={classes.checkboxText}>No</Typography>
                  }
                />
                <FormControlLabel
                  disabled={!createArbitrationToggled || arbitrationCreated}
                  onChange={this.handleChange("perSelectedLocOption")}
                  control={
                    <Checkbox
                      checked={perSelectedLocOption === 2}
                      value={2}
                      classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={
                    <Typography className={classes.checkboxText}>
                      At Grounding Dealer
                    </Typography>
                  }
                />
              </FormGroup>
            </div>
            {this.renderVehicleLocation()}
          </div>
          <div className={classes.lowerInspectionInformation}>
            <Typography variant="h5" component="h3">
              Arbitration Information
            </Typography>
            {createArbitrationToggled ? (
              <Typography className={classes.helpText}>
                Select all that apply. Click the checkmark to remove an option.
                Each individual file size should be less than 4Mb.
              </Typography>
            ) : null}
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Arbitration Type
              </Typography>
              <Select
                id="arbitrationType"
                className={classes.arbitrationTypeSelect}
                multiple
                value={arbitrationMultiTypeId}
                disableUnderline
                onChange={this.handleChange("arbitrationMultiTypeId")}
                input={<Input id="select-multiple" />}
                MenuProps={MenuProps}
                disabled={
                  closedArbitration ||
                  !createArbitrationToggled ||
                  arbitrationCreated
                }
              >
                {Object.keys(arbitrationTypes).map((type) => (
                  <MenuItem
                    style={{
                      fontSize: ".8rem",
                    }}
                    key={type}
                    value={type}
                  >
                    {arbitrationTypes[type]}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {arbitrationMultiTypeId.length > 0 ? (
              <div className={classes.arbitrationTypeWrapper}>
                {arbitrationMultiTypeId.map((selectedOption) => {
                  return (
                    <div
                      key={selectedOption}
                      className={classes.checkboxWrapper}
                    >
                      <Checkbox
                        id={`${selectedOption}Checkbox`}
                        checked={
                          arbitrationMultiTypeId.indexOf(selectedOption) !== -1
                        }
                        onChange={this.unSelectArbitrationTypeOption}
                        value={selectedOption}
                        color="primary"
                        classes={{
                          root: classes.checkboxRoot,
                          checked: classes.checked,
                        }}
                        disabled={
                          !createArbitrationToggled || arbitrationCreated
                        }
                      />
                      <Typography className={classes.checkboxText}>
                        {arbitrationTypes[selectedOption]}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div className={classes.shortMultilineFieldWrapper}>
              <Typography
                className={
                  fileToLarge ? classes.fieldLabelRed : classes.fieldLabel
                }
              >
                Add Attachments
              </Typography>
              {!closedArbitration ? (
                <input
                  accept="*"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  name="file"
                  onChange={this.selectFile}
                />
              ) : null}
              <label htmlFor="raised-button-file">
                <Button
                  component="span"
                  className={classes.attachmentButton}
                  disabled={closedArbitration}
                >
                  Choose File
                </Button>
              </label>
              {selectedFile ? (
                <Typography className={classes.attachmentFileText}>
                  {selectedFile.name}
                </Typography>
              ) : null}
              <Typography
                className={
                  fileToLarge
                    ? classes.fileWarningMessage
                    : classes.systemMessage
                }
              >
                {" "}
                Max size 4Mb{" "}
              </Typography>
            </div>

            <div className={classes.multilineFieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Attachments
              </Typography>
              {arbitrationAttachmentCollection &&
              arbitrationAttachmentCollection.length > 0 ? (
                <div className={classes.attachmentWrapper}>
                  {arbitrationAttachmentCollection.map((attachment) => {
                    return (
                      <Typography
                        key={attachment.attachmentInfo.fileLocation}
                        className={classes.clickableLink}
                        onClick={() =>
                          window.open(
                            `${ATTACHMENT_URL}${attachment.attachmentInfo.fileLocation}${attachment.attachmentInfo.fileName}`,
                            "blank"
                          )
                        }
                      >
                        {attachment.attachmentInfo.fileName}
                      </Typography>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div className={classes.multilineFieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Description
              </Typography>
              <TextField
                id="description"
                multiline
                value={description}
                onChange={this.handleChange("description")}
                rows="4"
                className={classes.multilineTextField}
                variant="filled"
                InputProps={{
                  ...InputProps,
                  inputProps: {
                    ...InputProps.inputProps,
                    maxLength: 1000,
                  },
                }}
                disabled={closedArbitration}
              />
              <Typography className={classes.systemMessage}>
                Max 1000 characters
              </Typography>
            </div>
            <div className={classes.multilineFieldWrapper}>
              <Typography className={classes.fieldLabel}>
                Dealer Notes Log
              </Typography>
              <div className={classes.logWrapper}>
                {dealerNoteLogs &&
                  dealerNoteLogs.map((log) => {
                    return (
                      <div key={log.activityDate}>
                        <Typography className={classes.boldedText}>
                          [{log.activityDate}]
                        </Typography>
                        <Typography className={classes.boldedText}>
                          {`${dealerName} - ${log.dealerName}:`}
                        </Typography>
                        <Typography className={classes.messageText}>
                          {log.message}
                        </Typography>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Typography className={classes.disclosureStatement}>
            Prior to filing an arbitration, please review the terms and
            conditions in the{" "}
            <span
              className={classes.clickableLink}
              onClick={() =>
                window.open(
                  `${AWS_URL}/docs/toyota/160518-Dealer-Direct-Arbitration-Guidelines.pdf`,
                  "blank"
                )
              }
            >
              Dealer Direct Arbitration Guidelines
            </span>
          </Typography>
        </div>
        {!closedArbitration && !createArbitrationToggled ? (
          <FormControlLabel
            className={classes.cancelArbitrationCheckbox}
            onChange={(evt) => this.toggleCancel()}
            control={
              <Checkbox
                id="cancelArbitration"
                name="cancelArbitration"
                checked={cancelArbitration}
                classes={{
                  root: classes.checkboxRoot,
                  checked: classes.checked,
                }}
              />
            }
            label={
              <Typography className={classes.checkboxText}>
                I wish to cancel this Arbitration
              </Typography>
            }
          />
        ) : null}
        <div className={classes.buttonWrapper}>
          {!closedArbitration ? (
            <React.Fragment>
              <Button
                id="updateArbitration"
                variant="contained"
                onClick={() =>
                  !createArbitrationToggled || arbitrationCreated
                    ? this.updateArbitration(false)
                    : this.createArbitration(false)
                }
                className={classes.button}
              >
                {!createArbitrationToggled || arbitrationCreated
                  ? "Update"
                  : "Create"}
              </Button>
              <Button
                id="updateAndExit"
                variant="contained"
                onClick={() =>
                  !createArbitrationToggled || arbitrationCreated
                    ? this.updateArbitration(true)
                    : this.createArbitration(true)
                }
                className={classes.button}
              >
                {!createArbitrationToggled || arbitrationCreated
                  ? "Update and Exit"
                  : "Create and Exit"}
              </Button>
            </React.Fragment>
          ) : null}
          <Button
            id="closeArbitration"
            variant="contained"
            onClick={
              !createArbitrationToggled
                ? handleUpdateArbitration
                : handleCreateArbitration
            }
            className={classes.button}
          >
            {!closedArbitration ? "Cancel" : "Back"}
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          className={classes.errorSnackBar}
          open={message !== null}
          autoHideDuration={3000}
          onClose={() => this.setState({ message: null })}
        >
          <SnackbarContent className={classes.message} message={message} />
        </Snackbar>
        <Dialog
          open={createArbitrationToggled && dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
            {"Warning!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage ? dialogMessage.dialogMessage1 : ""}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage ? dialogMessage.dialogMessage2 : ""}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                !arbitrationCreated
                  ? handleCreateArbitration()
                  : handleUpdateArbitration()
              }
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                this.setState({
                  dialogOpen: false,
                  invalidArbWarningAccepted: true,
                })
              }
              color="primary"
              autoFocus
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAddLocation}
          onClose={() => this.handleLocationDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ root: classes.dialogRoot }}
        >
          <DialogTitle
            className={classes.addLocDialogTitle}
            id="alert-dialog-title"
          >
            {"Add Location"}
          </DialogTitle>
          <Location
            page={"Arbitration"}
            handleAddLocation={this.handleAddLocation}
          />
          <DialogActions>
            <Button onClick={() => this.handleLocationDialog()} color="primary">
              {locationAdded ? "OK" : "Cancel"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditArbitration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    stateInfo: state.stateInfo,
    accountSetting: state.accountSetting,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(EditArbitration));
