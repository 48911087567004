import { encode } from "base-64";

export async function getCarfaxData(vin) {
  const API_URL = process.env.REACT_APP_CARFAX_URL;
  const username = process.env.REACT_APP_CARFAX_USERNAME;
  const password = process.env.REACT_APP_CARFAX_PASSWORD;
  const options = {
    method: "GET",
    headers: {
      Authorization: "Basic " + encode(username + ":" + password),
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/carfax/find/by/vin/${vin}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      console.log("Fetching Carfax data failed for", vin);
      return null;
    }
  } catch {
    console.log("Fetching Carfax data failed for", vin);
    return null;
  }
}

export async function getCarfaxReportUri(vin, accesstoken, refreshtoken) {
  const API_URL = process.env.REACT_APP_CARFAX_URL;
  const username = process.env.REACT_APP_CARFAX_USERNAME;
  const password = process.env.REACT_APP_CARFAX_PASSWORD;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Basic " + encode(username + ":" + password),
    },
    body: JSON.stringify({
      accessToken: accesstoken,
      refreshToken: refreshtoken,
    }),
  };
  try {
    const response = await fetch(
      `${API_URL}/carfaxReport/reportUri/${vin}`,
      options
    );
    if (response.status === 200) {
      const jsonResponse = await response.json();
      if (jsonResponse.error) {
        return jsonResponse.error;
      }
      return jsonResponse;
    } else if (response.status === 400) {
      return "Bad Request";
    } else {
      console.log("Fetching Carfax report data failed for", vin);
      return null;
    }
  } catch {
    console.log("Fetching Carfax report data failed for", vin);
    return null;
  }
}

export async function carfaxReportLogout() {
  const API_URL = process.env.REACT_APP_CARFAX_URL;
  const username = process.env.REACT_APP_CARFAX_USERNAME;
  const password = process.env.REACT_APP_CARFAX_PASSWORD;
  var refreshtoken = window.localStorage.getItem("refresh_token");

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + encode(username + ":" + password),
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/carfaxReport/logout/${refreshtoken}`,
      options
    );
    if (response.status === 200) {
      console.log("Successfully Revoked refreshToken");
      window.localStorage.removeItem("refresh_token");
      window.localStorage.removeItem("access_token");
    } else {
      console.log("Log out from  Carfax fails with refresh token ");
    }
  } catch {
    console.log("Log out from Carfax fails with refresh token ");
  }
}
