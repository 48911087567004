import React from "react";

import PulseLoader from "react-spinners/PulseLoader";

export const Loading = (props) => {
  return (
    <PulseLoader
      css={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
      color={props.color}
    />
  );
};

export default Loading;
