import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import StarIcon from "@material-ui/icons/StarRounded";
import DealerLocation from "../components/DealerLocation";
import { formatPhoneNumber } from "../services/HelperServices";

import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    width: "95%",
    height: "97%",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
  },
  controlWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    marginBottom: "1rem",
  },
  buttonWrapper: {
    marginLeft: "1rem",
  },
  star: {
    width: "5%",
    height: "5%",
  },
  fab: {
    height: "2rem",
  },
  fabLabel: {
    textTransform: "none",
  },
});
export class ManageDealerLocation extends Component {
  state = {
    data: [],
    errorMessage: "",
    displayEdit: false,
    displayAddLocation: false,
    selectedLocation: null,
  };

  setLocations = (accountSetting) => {
    const data = [];
    accountSetting.custLocations.forEach((item) => {
      let location = {
        locationId: item.locationId,
        locationName: item.locationDTO.name,
        address1: item.locationDTO.addressDTO.addressLine1,
        address2: item.locationDTO.addressDTO.addressLine2,
        city: item.locationDTO.addressDTO.city,
        zip: item.locationDTO.addressDTO.zip,
        state: item.locationDTO.addressDTO.state,
        phone: item.locationDTO.communicationDTO.businessPhone,
        fax: item.locationDTO.communicationDTO.fax,
        timezone: accountSetting.timeZoneOptions.find(
          (zone) => +zone.value === item.locationDTO.timeZone.id
        ).label,
        timeZoneId: item.locationDTO.timeZone.id,
        contactName1:
          item.locationDTO.customerContactDTOs[0].communicationDTO.contactName,
        contactPhone1:
          item.locationDTO.customerContactDTOs[0].communicationDTO
            .businessPhone,
        contactEmail1:
          item.locationDTO.customerContactDTOs[0].communicationDTO.email,
        contactName2:
          item.locationDTO.customerContactDTOs[1].communicationDTO.contactName,
        contactPhone2:
          item.locationDTO.customerContactDTOs[1].communicationDTO
            .businessPhone,
        contactEmail2:
          item.locationDTO.customerContactDTOs[1].communicationDTO.email,
        isPrimary: item.isPrimary,
      };
      data.push(location);
    });

    this.setState({ data });
  };

  componentDidMount = () => {
    const { accountSetting } = this.props;
    this.setLocations(accountSetting);
  };

  componentDidUpdate = (prevProps) => {
    const { accountSetting } = this.props;
    if (accountSetting !== prevProps.accountSetting) {
      this.setLocations(accountSetting);
    }
  };

  handleEditLocation = (location) => {
    this.setState({ displayEdit: true, selectedLocation: location });
  };

  handleAddLocation = () => {
    this.setState({ displayAddLocation: true });
  };

  cancelEditLocation = () => {
    this.setState({ displayEdit: false, displayAddLocation: false });
  };

  render() {
    const { classes, accountSetting } = this.props;
    const { data, displayEdit, displayAddLocation, selectedLocation } =
      this.state;
    const cellStyle = {
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
      maxWidth: "5rem",
    };
    return (
      <div id="materialTable" className={classes.root}>
        {displayEdit && (
          <DealerLocation
            id="edit_location"
            location={selectedLocation}
            addLocation={false}
            timeZoneOptions={accountSetting.timeZoneOptions}
            cancelEditLocation={this.cancelEditLocation}
          />
        )}
        {displayAddLocation && (
          <DealerLocation
            id="add_location"
            addLocation={true}
            timeZoneOptions={accountSetting.timeZoneOptions}
            cancelEditLocation={this.cancelEditLocation}
          />
        )}
        {!displayEdit && !displayAddLocation && (
          <div className={classes.controlWrapper}>
            <div className={classes.buttonWrapper}>
              <Fab
                id="addLocation-btn"
                variant="extended"
                aria-label="Add"
                className={classes.fab}
                classes={{ label: classes.fabLabel }}
                onClick={(evt) => this.handleAddLocation(evt)}
              >
                <AddIcon />
                <Typography>Add A Location</Typography>
              </Fab>
            </div>
          </div>
        )}
        {!displayEdit && !displayAddLocation && (
          <MaterialTable
            id="location-table"
            title={<Typography>Dealer Locations</Typography>}
            columns={[
              {
                title: "Location Name",
                cellStyle,
                render: (rowData) => (
                  <div>
                    {rowData.isPrimary ? (
                      <StarIcon className={classes.star} />
                    ) : null}{" "}
                    {rowData.locationName}
                  </div>
                ),
              },
              {
                title: "Address 1",
                field: "address1",
                cellStyle,
              },
              {
                title: "Address 2",
                field: "address2",
                cellStyle,
              },
              {
                title: "City",
                field: "city",
                cellStyle,
              },
              {
                title: "State",
                field: "state",
                cellStyle,
              },
              {
                title: "Zip",
                field: "zip",
                cellStyle,
              },
              {
                title: "Phone",
                field: "phone",
                cellStyle,
                render: (rowData) => formatPhoneNumber(rowData.phone),
              },
              {
                title: "Fax",
                field: "fax",
                cellStyle,
                render: (rowData) => formatPhoneNumber(rowData.fax),
              },
              {
                title: "Timezone",
                field: "timezone",
                cellStyle,
              },
            ]}
            data={data}
            actions={[
              (rowData) => ({
                iconProps: { id: "edit" + rowData.locationId },
                icon: "edit",
                tooltip: "Edit the Location",
                onClick: (event, rowData) => {
                  this.handleEditLocation(rowData);
                },
              }),
            ]}
            options={{
              headerStyle: {
                backgroundColor: "#EEE",
                textAlign: "center",
                padding: ".5rem",
                fontSize: ".7rem",
              },
              showTitle: true,
              search: true,
              sorting: true,
              paging: true,
              maxBodyHeight: 500,
              actionsColumnIndex: -1,
            }}
          />
        )}
      </div>
    );
  }
}

ManageDealerLocation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles)(ManageDealerLocation)
);
