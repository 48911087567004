export const LOAD_ACCOUNT_SETTING_SUCCESS = "LOAD_ACCOUNT_SETTING_SUCCESS";
export const LOAD_ACCOUNT_SETTING_FAIL = "LOAD_ACCOUNT_SETTING_FAIL";
export const UPDATE_ACCOUNT_SETTING_SUCCESS = "UPDATE_ACCOUNT_SETTING_SUCCESS";
export const UPDATE_ACCOUNT_SETTING_FAIL = "UPDATE_ACCOUNT_SETTING_FAIL";

export const loadAccountSettingSuccess = (accountSetting) => {
  return {
    type: LOAD_ACCOUNT_SETTING_SUCCESS,
    accountSetting,
  };
};

export const loadAccountSettingFail = () => {
  return {
    type: LOAD_ACCOUNT_SETTING_FAIL,
  };
};

export const updateAccountSettingSuccess = (accountSetting) => {
  return {
    type: UPDATE_ACCOUNT_SETTING_SUCCESS,
    accountSetting,
  };
};

export const updateAccountSettingFail = () => {
  return {
    type: UPDATE_ACCOUNT_SETTING_FAIL,
  };
};
