import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import * as R from "ramda";

import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Card,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import CarDisplay from "../components/CarDisplay";
import MobileCarDisplay from "../components/MobileCarDisplay";
import FullVehicleInformation from "../components/FullVehicleInformation";
import ConditionReport from "../components/ConditionReport";
import PassVehicle from "../components/PassVehicle";
import Loading from "../components/Loading";
import BuyVehicle from "../components/BuyVehicle";
import Filter from "../components/Filter";
import EmptyListPlaceholder from "../components/EmptyListPlaceholder";
import MobilePurchaseButtons from "../components/MobilePurchaseButtons";
import MobileFilterMenu from "../components/MobileFilterMenu";

import { getVehicleBidInformation } from "../services/BidServices";
import * as ConditionReportService from "../services/ConditionReportService";
import { getGAMDVehicles } from "../services/GAMDServices";
import { getVehicleDetails } from "../services/AdminServices";
import * as userAccess from "../services/UserRoles";

import {
  add_gamd_cars,
  add_gamd_condition_report,
  select_mobile_car,
  remove_selected_mobile_car,
} from "../actions/carActions";
import {
  set_nav_location,
  hide_mobile_nav_menu,
  set_vehicles_needing_attention,
  store_filter_options,
} from "../actions/uiActions";

import circleGAMDIcon from "../assets/circleGAMDIcon.svg";
import filterCars from "../assets/filterCars.svg";
import conditionReportIcon from "../assets/conditionReportIcon.svg";

let IS_USED = {
  bodyStyleCarIsUsed: 0,
  modelCarIsUsed: 0,
  seriesCarIsUsed: 0,
  yearCarIsUsed: 0,
  makeCarIsUsed: 0,
};

const styles = (theme) => ({
  color: theme.palette.GAMD.main,
  container: {
    display: "flex",
    height: "79%",
    [theme.breakpoints.down("sm")]: {
      height: "90%",
    },
  },
  header: {
    display: "flex",
    width: "97%",
    marginTop: ".5rem",
    height: "7%",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginTop: "3.5rem",
      width: "100%",
      height: "2.5rem",
    },
  },
  leftHeaderContent: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  titleWrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  desktopButtonWrapper: {
    marginLeft: "3rem",
    width: "31rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileButtonWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "2.5rem",
    position: "fixed",
    zIndex: 3,
    top: "3.5rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileButton: {
    height: "100%",
    width: "33.333%",
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
  },
  mobileButtonText: {
    fontSize: theme.font.small,
  },
  selectedMobileButton: {
    height: "100%",
    width: "33.333%",
    backgroundColor: theme.palette.secondary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.gray,
    },
  },
  buttonText: {
    marginRight: ".5rem",
  },
  noButtonUnderline: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "5px solid transparent",
  },
  buttonUnderline: {
    borderBottom: `5px solid ${theme.palette.GAMD.main}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    minHeight: "100%",
  },
  highlightedButtonText: {
    color: theme.palette.GAMD.main,
    marginRight: ".5rem",
  },
  GAMDIcon: {
    width: "4rem",
  },
  titleTextWrapper: {
    borderBottom: `5px solid ${theme.palette.GAMD.main}`,
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  titleText: {
    color: theme.palette.GAMD.main,
    fontSize: ".8rem",
  },
  boldedTitleText: {
    color: theme.palette.GAMD.main,
    fontSize: ".8rem",
    fontWeight: "bold",
  },
  rightSelectionButtonWrapper: {
    display: "flex",
    width: "6rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  selectionButton: {
    backgroundColor: theme.palette.GAMD.main,
    width: "2.5rem",
    height: "1.78rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  navButton: {
    height: "1.5rem",
    lineHeight: 0,
    padding: 0,
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
  },
  leftWrapper: {
    width: "75%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.dark,
    margin: ".5rem",
    padding: ".2rem",
    height: "95%",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      marginTop: ".5rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      height: "100vh",
      marginTop: 0,
      paddingBottom: "3rem",
    },
  },
  rightWrapper: {
    width: "25%",
    height: "95%",
    margin: ".5rem",
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  loader: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  scrollContainer: {
    height: "100%",
    overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileScrollContainer: {
    height: "100%",
    overflow: "auto",
    position: "absolute",
    width: "100%",
    marginBottom: "3rem",
    top: "3rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  errorMessage: {},
  errorSnackBar: {
    marginTop: "7rem",
  },
});

export class GAMD extends Component {
  state = {
    carFilter: "grounded",
    displayedCars: [],
    selectedCar: {},
    selectedVehicleDetails: {},
    conditionReport: null,
    fullConditionReport: false,
    programCertNum: "",
    programCertComments: "",
    filterActive: false,
    buyVehicle: false,
    passVehicle: false,
    isFilterOn: false,
    filteredCarList: [],
    matchCount: 0,
    sortByYear: false,
    sortByBody: false,
    sortByMake: false,
    sortByModel: false,
    sortBySeries: false,
    pageLoading: true,
    mobileGuarantee: false,
    error: null,
    vehicleInformationLoading: false,
    openBid: false,
    vehicleBidInformation: null,
    fromCR: false,
    fromTab: null,
  };

  componentWillMount = () => {
    const {
      cars,
      user,
      dispatch,
      navLocation,
      history,
      mobileCarInformation,
      vehiclesNeedingAttention,
    } = this.props;
    const { buyVehicle } = this.state;
    if (
      !userAccess.canViewOnly(user.userRoles) &&
      !userAccess.canPurchaseVehicles(user.userRoles)
    ) {
      history.replace("/home");
    } else {
      if (navLocation !== "GAMD") {
        dispatch(set_nav_location("GAMD"));
      }
      if (vehiclesNeedingAttention) {
        cars.pricedCars.length > 0 &&
          this.setDisplayedCars(cars.pricedCars, "priced");
      } else {
        cars.groundedCars.length > 0 &&
          this.setDisplayedCars(cars.groundedCars, "grounded");
      }
      this.fetchGAMDVehicles();
    }
    if (mobileCarInformation && !buyVehicle) {
      dispatch(remove_selected_mobile_car());
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { history, mobileCarInformation, dispatch, cars } = this.props;
    const {
      buyVehicle,
      passVehicle,
      mobileGuarantee,
      fullConditionReport,
      carFilter,
      selectedCar,
    } = this.state;
    if (
      history.action === "POP" &&
      history.location.pathname === "/GAMD" &&
      (buyVehicle || passVehicle)
    ) {
      this.setState({
        passVehicle: false,
        buyVehicle: false,
        fullConditionReport: false,
      });
    }
    if (
      cars.groundedCars.length !== prevProps.cars.groundedCars.length &&
      carFilter === "grounded"
    ) {
      this.setState({
        displayedCars: cars.groundedCars,
        pageLoading: false,
        selectedCar:
          cars.groundedCars.length > 0 &&
          !selectedCar.vehicleIdentificationNumber
            ? cars.groundedCars[0]
            : this.state.selectedCar,
      });
    }
    if (
      cars.pricedCars.length !== prevProps.cars.pricedCars.length &&
      carFilter === "priced"
    ) {
      this.setState({
        displayedCars: cars.pricedCars,
        pageLoading: false,
        selectedCar:
          cars.pricedCars.length > 0 && !selectedCar.vehicleIdentificationNumber
            ? cars.pricedCars[0]
            : this.state.selectedCar,
      });
    }
    if (
      cars.passedCars.length !== prevProps.cars.passedCars.length &&
      carFilter === "passed"
    ) {
      this.setState({
        displayedCars: cars.passedCars,
        pageLoading: false,
        selectedCar:
          cars.passedCars.length > 0 && !selectedCar.vehicleIdentificationNumber
            ? cars.passedCars[0]
            : this.state.selectedCar,
      });
    }
    if (
      mobileCarInformation !== prevProps.mobileCarInformation &&
      (buyVehicle || mobileGuarantee || fullConditionReport)
    ) {
      if (buyVehicle) {
        this.toggleBuy();
      } else {
        this.toggleMobileGuarantee();
      }
      if (!mobileCarInformation) {
        dispatch(hide_mobile_nav_menu(false));
      }
    }
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    dispatch(set_vehicles_needing_attention(false));
  };

  fetchGAMDVehicles = async () => {
    const { dispatch, user } = this.props;
    const vehicles = await getGAMDVehicles(user.dealerId, user.userId);
    if (vehicles.status === "false") {
      this.setState({
        pageLoading: false,
      });
    } else {
      dispatch(add_gamd_cars(vehicles));
    }
  };

  setDisplayedCars = async (cars, filter) => {
    const { GAMDConditionReports, dispatch, user } = this.props;

    if (filter === "priced") {
      cars = cars.sort((a, b) => {
        return a.exclusivePeriod - b.exclusivePeriod;
      });
    }

    if (filter === "lastChance") {
      const reducedPrice = (car) => {
        let reducedPrice = false;
        car.programDTO.map((program) => {
          if (program.reducePricingProgram) {
            return (reducedPrice = true);
          } else {
            return null;
          }
        });
        return reducedPrice;
      };

      const filterDuplicates = (cars) => {
        const uniqueVins = [];
        const filteredCars = [];
        if (cars) {
          for (let car of cars) {
            if (
              !uniqueVins.includes(car.vehicleIdentificationNumber) &&
              !car.message
            ) {
              uniqueVins.push(car.vehicleIdentificationNumber);
              filteredCars.push(car);
            }
          }
        }
        return filteredCars;
      };

      const reducedPriceCars = cars.filter((car) => reducedPrice(car));
      const upstreamCars = cars.filter(
        (car) =>
          car.dealerFacingStatus === "DLR_BUY_NOW" ||
          car.dealerFacingStatus === "DLR_BID_NOW"
      );
      const remainingCars = cars.filter(
        (car) =>
          !reducedPrice(car) &&
          car.dealerFacingStatus !== "DLR_BUY_NOW" &&
          car.dealerFacingStatus !== "DLR_BID_NOW"
      );
      cars = filterDuplicates(
        [].concat(reducedPriceCars, upstreamCars, remainingCars)
      );
    }

    if (cars[0]) {
      if (
        !GAMDConditionReports[cars[0].vehicleIdentificationNumber] &&
        cars[0].conditionReportAvailable
      ) {
        const newConditionReport =
          await ConditionReportService.getSingleConditionReport(
            cars[0].id,
            user.userId,
            user.dealerId
          );
        dispatch(add_gamd_condition_report(newConditionReport));
        this.setState({
          carFilter: filter,
          displayedCars: cars,
          selectedCar: cars[0],
          conditionReport: newConditionReport,
          filteredCarList: cars,
          filterActive: false,
          pageLoading: false,
          fromTab: filter,
        });
      } else {
        this.setState({
          carFilter: filter,
          displayedCars: cars,
          selectedCar: cars[0],
          conditionReport:
            GAMDConditionReports[cars[0].vehicleIdentificationNumber],
          filteredCarList: cars,
          filterActive: false,
          pageLoading: false,
          fromTab: filter,
        });
      }
    } else {
      this.setState({
        carFilter: filter,
        displayedCars: cars,
        selectedCar: null,
        conditionReport: null,
        filteredCarList: cars,
        filterActive: false,
        pageLoading: false,
        fromTab: filter,
      });
    }
    dispatch(store_filter_options(null));
  };

  selectCar = async (car) => {
    this.setState({ vehicleInformationLoading: true });
    const { GAMDConditionReports, user, dispatch } = this.props;
    const selectedVehicleDetails = await getVehicleDetails(car.id);
    if (
      !GAMDConditionReports[car.vehicleIdentificationNumber] &&
      car.conditionReportAvailable
    ) {
      const newConditionReport =
        await ConditionReportService.getSingleConditionReport(
          car.id,
          user.userId,
          user.dealerId
        );
      dispatch(add_gamd_condition_report(newConditionReport));
      this.setState({
        selectedCar: car,
        conditionReport: newConditionReport,
        selectedVehicleDetails,
        filterActive: false,
        vehicleInformationLoading: false,
      });
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: GAMDConditionReports[car.vehicleIdentificationNumber],
        selectedVehicleDetails,
        filterActive: false,
        vehicleInformationLoading: false,
      });
    }
  };

  selectMobileCar = async (car) => {
    const { GAMDConditionReports, user, dispatch } = this.props;
    const selectedVehicleDetails = await getVehicleDetails(car.id);
    if (
      !GAMDConditionReports[car.vehicleIdentificationNumber] &&
      car.conditionReportAvailable
    ) {
      const newConditionReport =
        await ConditionReportService.getSingleConditionReport(
          car.id,
          user.userId,
          user.dealerId
        );
      dispatch(add_gamd_condition_report(newConditionReport));
      this.setState({
        selectedCar: car,
        conditionReport: newConditionReport,
        selectedVehicleDetails,
        filterActive: false,
      });
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: GAMDConditionReports[car.vehicleIdentificationNumber],
        selectedVehicleDetails,
        filterActive: false,
      });
    }
    dispatch(hide_mobile_nav_menu(true));
    dispatch(select_mobile_car(car));
  };

  selectConditionReport = async (car, open) => {
    const { GAMDConditionReports, dispatch, user } = this.props;
    if (!GAMDConditionReports[car.vehicleIdentificationNumber]) {
      const newConditionReport =
        await ConditionReportService.getSingleConditionReport(
          car.id,
          user.userId,
          user.dealerId
        );
      if (newConditionReport.status !== "false") {
        dispatch(add_gamd_condition_report(newConditionReport));
        this.setState({
          selectedCar: car,
          conditionReport: newConditionReport,
          filterActive: false,
          fullConditionReport: open ? true : false,
        });
      } else {
        this.setState({
          error: "Unable to retrieve next condition report.",
        });
      }
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: GAMDConditionReports[car.vehicleIdentificationNumber],
        filterActive: false,
        fullConditionReport: open ? true : false,
      });
    }
  };

  onHandleProgramCertNumChange = (event) => {
    this.setState({
      programCertNum: event.target.value,
    });
  };

  onHandleProgramCertCommentsChange = (event) => {
    this.setState({
      programCertComments: event.target.value,
    });
  };

  toggleFullConditionReport = () => {
    this.setState({
      fullConditionReport: !this.state.fullConditionReport,
      fromCR: true,
    });
  };

  togglePass = (passed, car) => {
    const { GAMDConditionReports } = this.props;
    const { displayedCars } = this.state;
    if (passed) {
      const newCars = displayedCars.filter(
        (newCar) =>
          newCar.vehicleIdentificationNumber !== car.vehicleIdentificationNumber
      );
      this.setState({
        passVehicle: false,
        fullConditionReport: false,
        selectedCar: displayedCars[0] ? displayedCars[0] : {},
        conditionReport:
          displayedCars[0] &&
          GAMDConditionReports[displayedCars[0].vehicleIdentificationNumber]
            ? GAMDConditionReports[displayedCars[0].vehicleIdentificationNumber]
            : null,
        displayedCars: newCars,
      });
    } else {
      this.setState({
        passVehicle: !this.state.passVehicle,
      });
    }
  };

  toggleBuy = async (car, bought) => {
    const { GAMDConditionReports } = this.props;
    const { buyVehicle, isFilterOn, filteredCarList, displayedCars } =
      this.state;
    if (!buyVehicle) {
      this.setState({
        buyVehicle: true,
        selectedCar: car,
        conditionReport: car.vin
          ? GAMDConditionReports[car.vin]
          : this.state.conditionReport,
        fullConditionReport: bought ? false : this.state.fullConditionReport,
      });
    } else {
      this.setState({
        buyVehicle: false,
        fullConditionReport: bought ? false : this.state.fullConditionReport,
        selectedCar: bought
          ? isFilterOn
            ? filteredCarList
            : displayedCars
          : this.state.selectedCar,
      });
      if (bought) {
        this.toggleBuyDone();
      }
    }
  };

  toggleBuyDone = async () => {
    const { user, dispatch } = this.props;
    dispatch(hide_mobile_nav_menu(false));
    dispatch(remove_selected_mobile_car());
    dispatch(add_gamd_cars(await getGAMDVehicles(user.dealerId, user.userId)));
  };

  handleBidOpen = async (car) => {
    const { user, dispatch } = this.props;
    const { openBid } = this.state;
    if (car && !openBid) {
      const vehicleBidInformation = await getVehicleBidInformation(car, user);
      if (vehicleBidInformation.status !== "false") {
        this.setState({
          vehicleBidInformation,
          openBid: true,
        });
      } else {
        this.setState({
          error: vehicleBidInformation.message,
        });
      }
    } else {
      this.setState({
        openBid: false,
      });
      dispatch(remove_selected_mobile_car());
      dispatch(hide_mobile_nav_menu(false));
    }
  };

  getCarInfoForGuaranteePayments = (finalGroundedVehicleData) => {
    const {
      saleEventItemId,
      groundingMileage,
      grossPayoff,
      remainingPayments,
      groundingDealerId,
      residualAmount,
      year,
      vehicleIdentificationNumber,
    } = finalGroundedVehicleData;
    const { make, model, id } = finalGroundedVehicleData.inventoryDTO;
    const { remainingPaymentAmount, marketBasedPrice } =
      finalGroundedVehicleData.inventoryDTO.leaseInformationDTO;
    const car = {
      id,
      year,
      make,
      model,
      vehicleIdentificationNumber,
      remainingPaymentsAmt: remainingPaymentAmount,
      saleEventItemId,
      groundingMileage,
      residualAmount,
      grossPayoff,
      marketBasedPrice,
      remainingPayments,
      groundingDealerId,
    };
    return car;
  };

  updateGuaranteePayment = (vin) => {
    const { displayedCars, selectedCar, buyVehicle } = this.state;
    const { mobileCarInformation, dispatch } = this.props;
    const carMap = (car) =>
      car.vehicleIdentificationNumber === vin
        ? { ...car, displayGuaranteePaymentsOnly: false }
        : car;
    const updatedDisplay = R.map(carMap, displayedCars);
    this.setState({
      displayedCars: updatedDisplay,
    });
    if (mobileCarInformation && !buyVehicle) {
      dispatch(
        select_mobile_car({
          ...selectedCar,
          displayGuaranteePaymentsOnly: false,
        })
      );
    }
  };

  toggleMobileGuarantee = () => {
    this.setState({
      mobileGuarantee: !this.state.mobileGuarantee,
    });
  };

  selectionChanged = (query, removed) => {
    const { displayedCars, filteredCarList, carFilter } = this.state;
    const { cars } = this.props;
    const filterNotActive =
      query.selectYear.length === 0 &&
      query.selectBodyStyle.length === 0 &&
      query.selectMake.length === 0 &&
      query.selectModel.length === 0 &&
      query.selectSeries.length === 0;
    let finalarray = JSON.parse(
      JSON.stringify(
        !filterNotActive && !removed
          ? filteredCarList
          : cars[`${carFilter}Cars`]
      )
    );
    if (query.selectBodyStyle.length > 0) {
      query.selectBodyStyle.map((bodyType) => {
        return (finalarray = finalarray.filter(
          (car) => car.bodyType === bodyType
        ));
      });
    }

    if (query.selectModel.length > 0) {
      query.selectModel.map((model) => {
        return (finalarray = finalarray.filter(
          (car) => car.modelWithOutTrim === model
        ));
      });
    }

    if (query.selectMake.length > 0) {
      query.selectMake.map((make) => {
        return (finalarray = finalarray.filter((car) => car.make === make));
      });
    }

    if (query.selectYear.length > 0) {
      query.selectYear.map((year) => {
        return (finalarray = finalarray.filter(
          (car) => car.year === Number(year)
        ));
      });
    }

    if (query.selectSeries.length > 0) {
      query.selectSeries.map((trim) => {
        return (finalarray = finalarray.filter((car) => car.trim === trim));
      });
    }
    this.setState({
      filteredCarList: filterNotActive ? displayedCars : finalarray,
      isFilterOn: filterNotActive ? false : true,
      matchCount: finalarray.length,
    });
  };

  sortCars = (summary) => {
    const {
      sortByModel,
      sortByBody,
      sortByMake,
      sortBySeries,
      sortByYear,
      filteredCarList,
      isFilterOn,
    } = this.state;
    const array = JSON.parse(JSON.stringify(filteredCarList));
    let model;
    let make;
    let body;
    let series;
    let year;

    if (summary === "Model") {
      model = !sortByModel;
      array.sort((a, b) => {
        return !sortByModel
          ? a.model.toLowerCase() < b.model.toLowerCase()
            ? -1
            : 1
          : a.model.toLowerCase() > b.model.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Body/Style") {
      body = !sortByBody;
      array.sort((a, b) => {
        return !sortByBody
          ? a.bodyType.toLowerCase() < b.bodyType.toLowerCase()
            ? -1
            : 1
          : a.bodyType.toLowerCase() > b.bodyType.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Make") {
      make = !sortByMake;
      array.sort((a, b) => {
        return !sortByMake
          ? a.make.toLowerCase() < b.make.toLowerCase()
            ? -1
            : 1
          : a.make.toLowerCase() > b.make.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Series") {
      series = !sortBySeries;
      array.sort((a, b) => {
        return !sortBySeries
          ? a.trim.toLowerCase() < b.trim.toLowerCase()
            ? -1
            : 1
          : a.trim.toLowerCase() > b.trim.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Year") {
      year = !sortByYear;
      array.sort((a, b) => {
        return !sortByYear ? a.year - b.year : b.year - a.year;
      });
    }
    this.setState({
      sortByModel: model,
      sortByYear: year,
      sortBySeries: series,
      sortByMake: make,
      sortByBody: body,
      filteredCarList: array,
      isFilterOn: isFilterOn ? true : !isFilterOn,
    });
  };

  resetIsUsedValue = () => {
    IS_USED.bodyStyleCarIsUsed = 0;
    IS_USED.modelCarIsUsed = 0;
    IS_USED.seriesCarIsUsed = 0;
    IS_USED.yearCarIsUsed = 0;
    IS_USED.makeCarIsUsed = 0;
  };

  updateWatchlist = (vin) => {
    const { filteredCarList, displayedCars, isFilterOn } = this.state;
    const array = isFilterOn ? filteredCarList : displayedCars;
    const modifiedDisplayed = array.map((car) => {
      if (car.vin === vin) {
        return { ...car, isInWatchList: !car.isInWatchList };
      } else {
        return car;
      }
    });
    this.setState({
      displayedCars: modifiedDisplayed,
      filteredCarList: modifiedDisplayed,
    });
  };
  render() {
    const {
      cars,
      classes,
      user,
      history,
      dispatch,
      accountSetting,
      GAMDConditionReports,
      mobileCarInformation,
      hideMobileNavMenu,
    } = this.props;
    const {
      carFilter,
      displayedCars,
      selectedCar,
      conditionReport,
      fullConditionReport,
      programCertNum,
      programCertComments,
      filterActive,
      passVehicle,
      buyVehicle,
      selectedVehicleDetails,
      isFilterOn,
      filteredCarList,
      matchCount,
      pageLoading,
      error,
      vehicleInformationLoading,
      openBid,
      fromCR,
      fromTab,
    } = this.state;
    return (
      <React.Fragment>
        {!fullConditionReport && !passVehicle && !buyVehicle && !openBid ? (
          <React.Fragment>
            {!mobileCarInformation ? (
              <div className={classes.header}>
                <div className={classes.leftHeaderContent}>
                  <div className={classes.titleWrapper}>
                    <img
                      className={classes.GAMDIcon}
                      src={circleGAMDIcon}
                      alt="GAMD Icon"
                    />
                    <div className={classes.titleTextWrapper}>
                      <Typography className={classes.boldedTitleText}>
                        Grounded
                      </Typography>
                      <Typography className={classes.titleText}>
                        At My Dealership
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.desktopButtonWrapper}>
                    <div
                      className={
                        carFilter === "grounded"
                          ? classes.buttonUnderline
                          : classes.noButtonUnderline
                      }
                    >
                      <Button
                        id={"grounded"}
                        className={classes.navButton}
                        variant="outlined"
                        onClick={(evt) =>
                          this.setDisplayedCars(cars.groundedCars, "grounded")
                        }
                      >
                        <Typography
                          className={
                            carFilter === "grounded"
                              ? classes.highlightedButtonText
                              : classes.buttonText
                          }
                        >
                          Grounded{" "}
                        </Typography>
                        {cars.groundedCars.length}
                      </Button>
                    </div>
                    <div
                      className={
                        carFilter === "priced"
                          ? classes.buttonUnderline
                          : classes.noButtonUnderline
                      }
                    >
                      <Button
                        id={"priced"}
                        className={classes.navButton}
                        variant="outlined"
                        onClick={(evt) =>
                          this.setDisplayedCars(cars.pricedCars, "priced")
                        }
                      >
                        <Typography
                          className={
                            carFilter === "priced"
                              ? classes.highlightedButtonText
                              : classes.buttonText
                          }
                        >
                          Priced{" "}
                        </Typography>
                        {cars.pricedCars.length}
                      </Button>
                    </div>
                    <div
                      className={
                        carFilter === "passed"
                          ? classes.buttonUnderline
                          : classes.noButtonUnderline
                      }
                    >
                      <Button
                        id={"passed"}
                        className={classes.navButton}
                        variant="outlined"
                        onClick={(evt) =>
                          this.setDisplayedCars(cars.passedCars, "passed")
                        }
                      >
                        <Typography
                          className={
                            carFilter === "passed"
                              ? classes.highlightedButtonText
                              : classes.buttonText
                          }
                        >
                          Passed{" "}
                        </Typography>
                        {cars.passedCars.length}
                      </Button>
                    </div>
                    <div
                      className={
                        carFilter === "lastChance"
                          ? classes.buttonUnderline
                          : classes.noButtonUnderline
                      }
                    >
                      <Button
                        id={"lastChance"}
                        className={classes.navButton}
                        variant="outlined"
                        onClick={() =>
                          this.setDisplayedCars(
                            cars.lastChanceCars,
                            "lastChance"
                          )
                        }
                      >
                        <Typography
                          className={
                            carFilter === "lastChance"
                              ? classes.highlightedButtonText
                              : classes.buttonText
                          }
                        >
                          Last Chance{" "}
                        </Typography>
                        {cars.lastChanceCars.length}
                      </Button>
                    </div>
                  </div>
                  <div className={classes.mobileButtonWrapper}>
                    <Button
                      id={"mobileGrounded"}
                      className={
                        carFilter === "grounded"
                          ? classes.selectedMobileButton
                          : classes.mobileButton
                      }
                      variant="outlined"
                      onClick={(evt) =>
                        this.setDisplayedCars(
                          cars.groundedCars,
                          "grounded",
                          true
                        )
                      }
                    >
                      <Typography
                        className={classes.mobileButtonText}
                      >{`Grounded ${cars.groundedCars.length}`}</Typography>
                    </Button>
                    <Button
                      id={"mobilePriced"}
                      className={
                        carFilter === "priced"
                          ? classes.selectedMobileButton
                          : classes.mobileButton
                      }
                      variant="outlined"
                      onClick={(evt) =>
                        this.setDisplayedCars(cars.pricedCars, "priced", true)
                      }
                    >
                      <Typography className={classes.mobileButtonText}>
                        {`Priced ${cars.pricedCars.length}`}{" "}
                      </Typography>
                    </Button>
                    <Button
                      id={"mobileLastChance"}
                      className={
                        carFilter === "lastChance"
                          ? classes.selectedMobileButton
                          : classes.mobileButton
                      }
                      variant="outlined"
                      onClick={(evt) =>
                        this.setDisplayedCars(
                          cars.lastChanceCars,
                          "lastChance",
                          true
                        )
                      }
                    >
                      <Typography className={classes.mobileButtonText}>
                        {`Last Chance ${cars.lastChanceCars.length}`}{" "}
                      </Typography>
                    </Button>
                  </div>
                </div>

                <div className={classes.rightSelectionButtonWrapper}>
                  <Card
                    className={classes.selectionButton}
                    elevation={filterActive ? 6 : 0}
                    onClick={() => this.setState({ filterActive: true })}
                  >
                    <ReactSVG
                      svgStyle={{ fill: "white", marginTop: ".4rem" }}
                      src={filterCars}
                      alt="filterCarsIcon"
                    />
                  </Card>
                  <Card
                    className={classes.selectionButton}
                    elevation={!filterActive ? 6 : 0}
                    onClick={() => this.setState({ filterActive: false })}
                  >
                    <ReactSVG
                      svgStyle={{ fill: "white", marginTop: ".4rem" }}
                      src={conditionReportIcon}
                      alt="conditionReportIcon"
                    />
                  </Card>
                </div>
              </div>
            ) : null}
            {!pageLoading ? (
              (isFilterOn && filteredCarList && filteredCarList.length > 0) ||
              (!isFilterOn && displayedCars && displayedCars.length > 0) ? (
                <div className={classes.container}>
                  <div className={classes.leftWrapper}>
                    <div className={classes.scrollContainer}>
                      <CarDisplay
                        carList={isFilterOn ? filteredCarList : displayedCars}
                        selectCar={this.selectCar}
                        user={user}
                        from={this.state.fromTab}
                        onHandleProgramCertCommentsChange={
                          this.onHandleProgramCertCommentsChange
                        }
                        onHandleProgramCertNumChange={
                          this.onHandleProgramCertNumChange
                        }
                        programCertComments={programCertComments}
                        programCertNum={programCertNum}
                        selectedCar={selectedCar}
                        sourcePage={"GAMD"}
                        GAMDConditionReports={GAMDConditionReports}
                        history={history}
                        dispatch={dispatch}
                        accountSetting={accountSetting}
                        togglePass={this.togglePass}
                        toggleBuy={this.toggleBuy}
                        handleBidOpen={this.handleBidOpen}
                        updateGuaranteePayment={this.updateGuaranteePayment}
                        selectConditionReport={this.selectConditionReport}
                      />
                    </div>
                    <div className={classes.mobileScrollContainer}>
                      <MobileCarDisplay
                        carList={isFilterOn ? filteredCarList : displayedCars}
                        selectCar={this.selectMobileCar}
                        user={user}
                        onHandleProgramCertCommentsChange={
                          this.onHandleProgramCertCommentsChange
                        }
                        onHandleProgramCertNumChange={
                          this.onHandleProgramCertNumChange
                        }
                        programCertComments={programCertComments}
                        programCertNum={programCertNum}
                        selectedCar={selectedCar}
                        sourcePage={"GAMD"}
                        GAMDConditionReports={GAMDConditionReports}
                        history={history}
                        dispatch={dispatch}
                        accountSetting={accountSetting}
                        togglePass={this.togglePass}
                        toggleBuy={this.toggleBuy}
                        mobileCarInformation={mobileCarInformation}
                        selectedVehicleDetails={selectedVehicleDetails}
                        toggleFullConditionReport={
                          this.toggleFullConditionReport
                        }
                        updateGuaranteePayment={this.updateGuaranteePayment}
                        toggleMobileGuarantee={this.toggleMobileGuarantee}
                        mobileGuarantee={this.state.mobileGuarantee}
                      />
                    </div>
                  </div>
                  <div className={classes.rightWrapper}>
                    <div
                      className={classes.scrollContainer}
                      style={filterActive ? {} : { display: "none" }}
                    >
                      <Filter
                        filterCars={filteredCarList}
                        from={"gamd"}
                        selectionChanged={this.selectionChanged}
                        matchCount={matchCount}
                        totalCount={displayedCars.length}
                        sortCars={this.sortCars}
                        fromCR={fromCR}
                        fromTab={fromTab}
                      />
                    </div>
                    <div
                      className={classes.scrollContainer}
                      style={filterActive ? { display: "none" } : {}}
                    >
                      {selectedCar.vehicleIdentificationNumber &&
                      !vehicleInformationLoading ? (
                        <FullVehicleInformation
                          car={selectedCar}
                          conditionReport={conditionReport}
                          toggleFullConditionReport={
                            this.toggleFullConditionReport
                          }
                          selectedVehicleDetails={selectedVehicleDetails}
                          user={user}
                        />
                      ) : (
                        <Loading />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyListPlaceholder page={"GAMD"} />
              )
            ) : (
              <div style={{ height: "78%" }}>
                <Loading />
              </div>
            )}
          </React.Fragment>
        ) : null}
        {fullConditionReport &&
        GAMDConditionReports[selectedCar.vehicleIdentificationNumber] &&
        !buyVehicle &&
        !passVehicle ? (
          <ConditionReport
            car={selectedCar}
            conditionReport={
              mobileCarInformation
                ? GAMDConditionReports[
                    mobileCarInformation.vehicleIdentificationNumber
                  ]
                : GAMDConditionReports[selectedCar.vehicleIdentificationNumber]
            }
            validConditionReports={
              isFilterOn
                ? filteredCarList.filter((car) => car.conditionReportAvailable)
                : displayedCars.filter((car) => car.conditionReportAvailable)
            }
            toggleFullConditionReport={this.toggleFullConditionReport}
            selectConditionReport={this.selectConditionReport}
            updateGuaranteePayment={this.updateGuaranteePayment}
            user={user}
            history={history}
            toggleBuyVehicle={this.toggleBuy}
            togglePass={this.togglePass}
            pageFrom="GAMD"
            selectMobileCar={this.selectMobileCar}
            updateWatchlist={this.updateWatchlist}
          />
        ) : null}
        {passVehicle ? (
          <PassVehicle
            togglePass={this.togglePass}
            history={history}
            updateGuaranteePayment={this.updateGuaranteePayment}
          />
        ) : null}
        {buyVehicle ? (
          <BuyVehicle
            toggleBuy={this.toggleBuy}
            page="GAMD"
            conditionReport={
              GAMDConditionReports[selectedCar.vehicleIdentificationNumber]
            }
            updateGuaranteePayment={this.updateGuaranteePayment}
            carInfo={selectedCar}
          />
        ) : null}

        {mobileCarInformation &&
        hideMobileNavMenu &&
        !passVehicle &&
        !buyVehicle &&
        !fullConditionReport ? (
          <MobilePurchaseButtons
            toggleBuy={this.toggleBuy}
            togglePass={this.togglePass}
            pageFrom="GAMD"
            user={user}
            car={mobileCarInformation}
            toggleMobileGuarantee={this.toggleMobileGuarantee}
          />
        ) : null}
        {!passVehicle &&
        !buyVehicle &&
        !fullConditionReport &&
        !mobileCarInformation ? (
          <MobileFilterMenu
            filterCars={filteredCarList}
            from={"gamd"}
            selectionChanged={this.selectionChanged}
            matchCount={matchCount}
            totalCount={displayedCars.length}
            resetIsUsedValue={this.resetIsUsedValue}
            sortCars={this.sortCars}
          />
        ) : null}
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={error}
          autoHideDuration={2000}
          className={classes.errorSnackBar}
          onClose={() => this.setState({ error: null })}
        >
          <SnackbarContent className={classes.errorMessage} message={error} />
        </Snackbar>
      </React.Fragment>
    );
  }
}

GAMD.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    cars: state.cars,
    user: state.user,
    accountSetting: state.accountSetting,
    GAMDConditionReports: state.cars.GAMDConditionReports,
    navLocation: state.ui.navLocation,
    mobileCarInformation: state.cars.mobileCarInformation,
    hideMobileNavMenu: state.ui.hideMobileNavMenu,
    vehiclesNeedingAttention: state.ui.vehiclesNeedingAttention,
    auctionTimes: state.ui.auctionTimes,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(GAMD));
