import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
    fontSize: ".65rem",
  },
  progress: {
    margin: ".5rem",
  },
});
export class MyDealerPurchaseReportTable extends Component {
  render() {
    const { classes } = this.props;
    const { isTotal } = this.props;
    const cellStyle = {
      maxWidth: "5rem",
      textAlign: "center",
      padding: 0,
      fontSize: ".7rem",
    };

    return (
      <div id="materialTable" className={classes.root}>
        <MaterialTable
          columns={[
            {
              title: isTotal ? "Total Make/Model" : "Make/Model",
              field: "makeModel",
              cellStyle,
            },
            {
              title: isTotal ? "Total Vehicles Grounded" : "Vehicles Grounded",
              field: "grounded",
              cellStyle,
            },
            {
              title: isTotal
                ? "Total Vehicles Purchased"
                : "Vehicles Purchased",
              field: "purchased",
              cellStyle,
            },
            {
              title: "My Purchase %",
              field: "myPercent",
              cellStyle,
            },
            {
              title: "Regional Purchase %",
              field: "regPercent",
              cellStyle,
            },
            {
              title: "National Purchase %",
              field: "natPercent",
              cellStyle,
            },
          ]}
          data={this.props.data}
          options={{
            headerStyle: {
              backgroundColor: "#EEE",
              textAlign: "center",
              padding: ".5rem",
            },
            showTitle: false,
            sorting: !isTotal,
            search: !isTotal,
            toolbar: !isTotal,
            paging: !isTotal,
            maxBodyHeight: 400,
          }}
        />
      </div>
    );
  }
}

MyDealerPurchaseReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyDealerPurchaseReportTable);
