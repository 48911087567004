import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";

import loginBackground from "../assets/login/loginBackground.png";
import lexusLoginBackground from "../assets/lexusLoginBackground.jpg";
import iPadGraphic from "../assets/login/iPadGraphic.png";
import lexusIpadGraphic from "../assets/lexusIpadGraphic.svg";
import clock from "../assets/login/clock.svg";
import ReactSVG from "react-svg";
import lexusIcon from "../assets/lexus.ico";

import {
  set_page_theme,
  hide_mobile_nav_menu,
  set_auction_times,
} from "../actions/uiActions";
import { setCurrentAuctionTimes } from "../services/HelperServices";
import {
  MOBILE_APP_INFO,
  MOBILE_APP_DOWNLOAD,
  LEXUS,
  TDD,
  LDD,
  LOGIN_ATTEMPT_INVALID,
  PLEASE_CONTRACT,
  DEALERSHIP,
  ADMINISTRATOR,
  TO_ENSURE_THAT,
  HAVE_VALID_LOGIN,
  WELCOME,
  DEALERS,
  TO,
  ONLINE_AUCTION,
  CROSS_LINE_AUCTION,
} from "../constants/UILabel";
import { ROUTE_URL } from "../constants/Constant";

export const styles = (theme) => ({
  container: {
    backgroundImage: `url(${loginBackground})`,
    backgroundPosition: "center bottom",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    flexGrow: 1,
    height: "100%",
    width: "100%",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "187% 87%",
      backgroundPosition: "left",
    },
  },
  lexusContainer: {
    backgroundImage: `url(${lexusLoginBackground})`,
    backgroundPosition: "right top 43%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    flexGrow: 1,
    height: "100%",
    width: "100%",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "187% 87%",
      backgroundPosition: "left",
    },
  },
  iPadGraphic: {
    height: "100%",
    marginTop: "8rem",
    marginLeft: "2rem",
    [theme.breakpoints.down("md")]: {
      width: "16rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  loginTitle: {
    color: theme.palette.primary.white,
    marginTop: "8rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lexusLoginTitle: {
    color: theme.palette.primary.black,
    marginTop: "8rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  boldText: {
    fontWeight: "bold",
  },
  upperWrapper: {
    display: "flex",
    width: "100%",
    height: "35%",
  },
  bottomLoginBanner: {
    backgroundColor: theme.palette.secondary.gray,
    borderRadius: "2rem 2rem 0 0",
    position: "absolute",
    bottom: 0,
    height: "8%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      height: "18%",
    },
    [theme.breakpoints.down("md")]: {},
  },
  bottomBannerText: {
    color: theme.palette.primary.white,
    display: "flex",
    flexWrap: "no-wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "175%",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".9rem",
      width: "15rem",
    },
  },
  lexusBottomBannerText: {
    color: theme.palette.primary.black,
    display: "flex",
    flexWrap: "no-wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "175%",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".9rem",
      width: "15rem",
    },
  },
  blueText: {
    color: theme.palette.secondary.blue,
    marginLeft: ".5rem",
    fontWeight: "initial",
  },
  greenText: {
    color: theme.palette.DDO.main,
    marginLeft: ".5rem",
    fontWeight: "initial",
  },
  clockImage: {
    marginRight: ".5rem",
    marginLeft: "1rem",
    marginTop: ".25rem",
    color: theme.palette.primary.white,
  },
  lexusClockImage: {
    marginRight: ".5rem",
    marginLeft: "1rem",
    marginTop: ".25rem",
    color: theme.palette.primary.black,
  },
  mobileAppAd: {
    color: theme.palette.primary.white,
    position: "relative",
    top: "42%",
    marginLeft: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
      top: "49%",
      marginLeft: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bottomTextWrapper: {
    display: "flex",
  },
});

export class Login extends Component {
  state = {
    error: null,
  };

  componentWillMount = () => {
    const { pageTheme, dispatch, history } = this.props;
    if (
      this.props.location.error &&
      this.props.location.error === "SSOlOGINERROR"
    ) {
      this.throwError("SSO_lOGIN_ERROR");
    }
    const token = sessionStorage.getItem("token");
    if (pageTheme === LEXUS) {
      document.title = "Lexus Dealer Direct";
      document.getElementById("favicon").href = lexusIcon;
    }
    if (pageTheme === "") {
      dispatch(set_page_theme());
    }
    if (token) {
      history.replace(ROUTE_URL.HOME);
    }
    dispatch(hide_mobile_nav_menu(true));
    dispatch(set_auction_times(setCurrentAuctionTimes()));
  };

  throwError = (error) => {
    this.setState({
      error,
      loading: false,
    });
  };

  handleClose = () => {
    this.setState({
      error: null,
    });
  };

  render() {
    const { classes, pageTheme } = this.props;
    const { stringSale1, stringSale2 } = this.props.auctionTimes;
    const { error } = this.state;

    return (
      <div
        className={
          pageTheme !== LEXUS ? classes.container : classes.lexusContainer
        }
      >
        <div className={classes.upperWrapper}>
          {pageTheme !== LEXUS ? (
            <img
              className={classes.iPadGraphic}
              src={iPadGraphic}
              alt="iPad Graphic"
            />
          ) : (
            <ReactSVG
              svgStyle={{ width: "20rem", height: "14rem" }}
              className={classes.iPadGraphic}
              src={lexusIpadGraphic}
            />
          )}

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={error !== null}
            autoHideDuration={3000}
            onClose={this.handleClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                <center>
                  {LOGIN_ATTEMPT_INVALID}
                  <br />
                  <br />
                  {PLEASE_CONTRACT}{" "}
                  <u>
                    <b>
                      <i>
                        {DEALERSHIP} <br />
                        {ADMINISTRATOR}
                      </i>
                    </b>
                  </u>{" "}
                  {TO_ENSURE_THAT} <br /> {HAVE_VALID_LOGIN}
                </center>
              </span>
            }
          />

          <Typography
            variant="h3"
            className={
              pageTheme !== LEXUS ? classes.loginTitle : classes.lexusLoginTitle
            }
          >
            {WELCOME} <span className={classes.boldText}>{DEALERS}</span> {TO}{" "}
            <span className={classes.boldText}>
              {pageTheme !== LEXUS ? TDD : LDD}
            </span>
          </Typography>
        </div>
        <Typography variant="h4" className={classes.mobileAppAd}>
          {MOBILE_APP_INFO}
        </Typography>
        <Typography variant="h6" className={classes.mobileAppAd}>
          {MOBILE_APP_DOWNLOAD}
        </Typography>
        <div className={classes.bottomLoginBanner}>
          <div className={classes.bottomTextWrapper}>
            <ReactSVG
              svgStyle={{ fill: "currentColor", height: "auto" }}
              className={
                pageTheme !== LEXUS
                  ? classes.clockImage
                  : classes.lexusClockImage
              }
              src={clock}
              alt="clock"
            />
            <Typography
              variant="h4"
              className={
                pageTheme !== LEXUS
                  ? classes.bottomBannerText
                  : classes.lexusBottomBannerText
              }
            >
              {ONLINE_AUCTION}
              <span className={classes.blueText}> {stringSale1} </span>
            </Typography>
          </div>
          <div className={classes.bottomTextWrapper}>
            <ReactSVG
              svgStyle={{ fill: "currentColor" }}
              className={
                pageTheme !== LEXUS
                  ? classes.clockImage
                  : classes.lexusClockImage
              }
              src={clock}
              alt="clock"
            />
            <Typography
              variant="h4"
              className={
                pageTheme !== LEXUS
                  ? classes.bottomBannerText
                  : classes.lexusBottomBannerText
              }
            >
              {CROSS_LINE_AUCTION}
              <span className={classes.greenText}> {stringSale2}</span>
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    pageTheme: state.ui.pageTheme,
    auctionTimes: state.ui.auctionTimes,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Login));
