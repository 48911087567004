import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { SwipeableDrawer } from "@material-ui/core";

import Filter from "../components/Filter";

import ReactSVG from "react-svg";
import filterCarsImg from "../assets/filterCars.svg";

const styles = (theme) => ({
  selectionButton: {
    width: "2.5rem",
    height: "1.78rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    position: "fixed",
    top: "1rem",
    right: "1rem",
    zIndex: 3,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
});

class MobileFilterMenu extends Component {
  state = {
    right: false,
  };

  toggleDrawer = () => {
    this.setState({
      right: !this.state.right,
    });
  };

  render() {
    const {
      classes,
      filterCars,
      matchCount,
      selectionChanged,
      resetIsUsedValue,
      sortCars,
      totalCount,
      from,
    } = this.props;
    const { right } = this.state;
    return (
      <React.Fragment>
        <ReactSVG
          className={classes.selectionButton}
          onClick={() => this.toggleDrawer("right", true)}
          svgStyle={{
            fill: "white",
            marginTop: ".4rem",
            height: "1.5rem",
            width: "1.5rem",
          }}
          src={filterCarsImg}
          alt="filterCarsIcon"
        />
        <SwipeableDrawer
          anchor="right"
          open={right}
          onClose={() => this.toggleDrawer()}
          onOpen={() => this.toggleDrawer()}
        >
          <Filter
            filterCars={filterCars}
            from={from}
            selectionChanged={selectionChanged}
            matchCount={matchCount}
            totalCount={totalCount}
            resetIsUsedValue={resetIsUsedValue}
            sortCars={sortCars}
          />
        </SwipeableDrawer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MobileFilterMenu);
