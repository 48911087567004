import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, Link } from "@material-ui/core";

import phoneIcon from "../assets/phone.svg";
import emailIcon from "../assets/emailIcon.svg";
import ReactSVG from "react-svg";

import {
  GET_HELP_HERE,
  CONTRACT_DD_SUPPORT,
  CONTRACT_TIME,
  CONTRACT_NUMBER,
  EMAIL_US,
  QUESTIONS,
  ARRIVE,
  CONTRACT_NUMBER2,
  CANCEL,
} from "../constants/UILabel";

const styles = (theme) => ({
  container: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      zIndex: 2,
    },
  },
  paper: {
    marginTop: "4rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `1.5rem 1.5rem 1.5rem`,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  phoneText: {
    color: theme.palette.primary.redHighlight,
    marginLeft: ".5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },

  link: {
    fontSize: "1rem",
    marginTop: "1rem",
    alignSelf: "center",
    color: theme.palette.primary.gray,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.white,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  phoneNumberWrapper: {
    display: "flex",
    alignItems: "center",
    margin: ".25rem",
    color: theme.palette.primary.redHighlight,
  },
  contractInformation: {
    fontSize: "medium",
    paddingTop: "0.9375rem",
    alignItems: "center",
  },
  supportNumber: {
    paddingLeft: "1.875rem",
  },
  emailWrapper: {
    display: "flex",
    margin: ".25rem",
    marginLeft: "1rem",
    color: theme.palette.primary.redHighlight,
    borderBottom: `1px solid ${theme.palette.primary.redHighlight}`,
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
});

export class CallingInformation extends Component {
  state = {
    username: "",
    emailId: "",
    errorMessage: null,
  };
  render() {
    const { classes, history } = this.props;
    return (
      <div className={classes.container} elevation={1}>
        <Paper className={classes.paper}>
          <Typography variant="h5" component="h5">
            {GET_HELP_HERE}
            <br />
          </Typography>
          <Typography className={classes.contractInformation}>
            <div className={classes.supportNumber}>
              {CONTRACT_DD_SUPPORT}
              <br />
              {CONTRACT_TIME}
              <div className={classes.phoneNumberWrapper}>
                <ReactSVG
                  src={phoneIcon}
                  svgStyle={{ fill: "currentColor", height: "1.5rem" }}
                />
                <Typography className={classes.phoneText}>
                  {CONTRACT_NUMBER}
                </Typography>

                <div
                  className={classes.emailWrapper}
                  onClick={() =>
                    (window.location = "mailto:customerserviceusa@openlane.com")
                  }
                >
                  <ReactSVG
                    src={emailIcon}
                    svgStyle={{ height: "1.5rem", fill: "currentColor" }}
                  />
                  <Typography className={classes.phoneText}>
                    {EMAIL_US}
                  </Typography>
                </div>
              </div>
            </div>
            {QUESTIONS} <br />
            {ARRIVE}
            <div className={classes.phoneNumberWrapper}>
              <ReactSVG
                src={phoneIcon}
                svgStyle={{ fill: "currentColor", height: "1.5rem" }}
              />
              <Typography className={classes.phoneText}>
                {CONTRACT_NUMBER2}
              </Typography>
            </div>
          </Typography>
          <div className={classes.form}>
            <Link className={classes.link} onClick={() => history.goBack()}>
              {CANCEL}
            </Link>
          </div>
        </Paper>
      </div>
    );
  }
}

CallingInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(CallingInformation));
