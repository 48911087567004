import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Viewer from "react-viewer";
import "react-viewer/dist/index.css";

import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core";

import { returnAccountType } from "../services/HelperServices";
import * as mobilityServices from "../services/MobilityServices";
import TFSNoImage from "../assets/TFSNoImage.png";
const styles = (theme) => ({
  card: {
    display: "flex",
    margin: ".5rem",
    borderRadius: 0,
    maxHeight: "11rem",
    width: "98%",
    position: "relative",
  },
  selectedCard: {
    display: "flex",
    margin: ".5rem",
    borderRadius: 0,
    maxHeight: "11rem",
    width: "98%",
    position: "relative",
    boxShadow: `0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.5)`,
    backgroundColor: theme.palette.light.gray,
  },
  cardWrapper: {
    display: "flex",
    position: "relative",
    alignItems: "flex-start",
    justifyContent: "left",
    flexDirection: "column",
    width: "40%",
    padding: 0,
    maxWidth: "10rem",
  },
  media: {
    width: "100%",
    height: "8rem",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "6rem",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: ".5rem",
  },
  contentWrapper: {
    display: "flex",
    margin: ".5rem",
    position: "relative",
    cursor: "pointer",
    width: "75%",
  },
  textWrapper: {
    display: "flex",
    height: "85%",
    justifyContent: "space-around",
    position: "relative",
    top: "1.75rem",
  },
  textContainer: {
    width: "100%",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignContent: "space-between",
    height: "9rem",
  },
  smallButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
  smallButton: {
    height: "2.5rem",
    minWidth: 0,
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    minWidth: "12%",
    justifyContent: "space-around",
    marginRight: ".5rem",
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  buyButton: {
    height: "40%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100%",
    margin: 0,
    fill: "currentColor",
    [theme.breakpoints.down("md")]: {
      height: "50%",
    },
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "20%",
    justifySelf: "flex-end",
  },
  bidButton: {
    height: "40%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.DDO.main,
    width: "100%",
    margin: 0,
    fill: "currentColor",
    [theme.breakpoints.down("md")]: {
      height: "50%",
    },
  },
  LPWLink: {},
  hasNotes: {
    color: theme.palette.primary.redHighlight,
    fill: "currentColor",
  },
  blankNotes: {},
  isInWatchList: {
    color: theme.palette.primary.redHighlight,
  },
  isNotInWatchList: {},
  expandButton: {
    alignSelf: "center",
    padding: 0,
  },
  cardTitle: {
    fontSize: "1.2rem",
    position: "absolute",
    width: "37rem",
  },
  selectedCardTitle: {
    fontSize: "1.2rem",
    position: "absolute",
    width: "37rem",
    fontWeight: "bold",
  },
  middleWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
  },
  GAMDIcon: {
    color: theme.palette.GAMD.main,
  },
  DDOColorText: {
    color: theme.palette.DDO.main,
  },
  winningBid: {
    color: theme.palette.DDO.main,
    fontSize: "76%",
    [theme.breakpoints.down("md")]: {
      fontSize: "60%",
    },
  },
  losingBid: {
    color: theme.palette.primary.redHighlight,
    fontSize: "76%",
    [theme.breakpoints.down("md")]: {
      fontSize: "60%",
    },
  },
  startPrice: {
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
  },
  circleIcon: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "20px",
    verticalAlign: "middle",
    alignSelf: "center",
  },
  autoBid: {
    display: "flex",
    justifyContent: "space-between",
    width: "8rem",
    [theme.breakpoints.down("md")]: {
      width: "7rem",
    },
  },
  leftTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "1%",
    width: "11rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      width: "9rem",
    },
  },
  rightTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "1%",
    width: "10.1rem",
    paddingBottom: 0,
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      width: "8rem",
    },
  },
  priceDisplayTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    width: "21rem",
    marginRight: ".7%",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      width: "18rem",
    },
  },
  cardText: {
    fontSize: "76%",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("md")]: {
      fontSize: "60%",
    },
  },
  boldedText: {
    fontWeight: "bold",
  },
  vinText: {
    fontWeight: "bold",
    color: theme.palette.grounding.main,
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
  redText: {
    color: theme.palette.primary.redHighlight,
  },
  redBoldedText: {
    fontWeight: "bold",
    color: theme.palette.primary.redHighlight,
  },
  losingBar: {
    width: "10px",
    backgroundColor: theme.palette.primary.redHighlight,
  },
  winningBar: {
    width: "10px",
    backgroundColor: theme.palette.DDO.main,
  },
  chipRoot: {
    fontSize: theme.font.small,
    height: "1rem",
    width: "4.4rem",
  },
  deleteIcon: {
    height: "1rem",
    "&:hover": {
      fill: theme.palette.primary.redHighlight,
    },
  },
  distanceWrapper: {
    display: "flex",
    alignItems: "center",
  },
  transportIcon: {
    marginLeft: ".5rem",
    fill: theme.palette.primary.gray,
    zIndex: 2,
    "&:hover": {
      opacity: ".5",
    },
  },
  noteIcon: {
    fill: theme.palette.grounding.main,
  },
  orgText: {
    color: "#e68a00",
    fontWeight: "bold",
  },
});

const stockwaveIntegrationDiv = (car, user, conditionReport) => {
  const data = `{
		VIN: ${car.vin},
		Year: ${car.year},
		Make: ${car.make},
		Model: ${car.model},
		Trim: ${car.series !== undefined ? car.series : ""},
		Engine: ${car.engine},
		Drivetrain: ${car.driveTrain},
		Transmission: ${
      conditionReport ? conditionReport.vehicleInformation.Transmission : ""
    },
		Odometer: ${car.inspectionMileage},
		Odometer Unit of Measure: ${car.unitOfOdometer},
		Color: ${car.color},
		Interior Color:,
		Interior Type:,
		CR Score: ${car.grade},
		CR URL:,
		VDP URL:,
		Lane:,
		Run:,
		inventoryId: ${car.inventoryId},
		userId: ${user.userId},
    dealershipId: ${user.dealerId},
    saleEventItemId: ${car.saleEventItemId}
	}`;
  return (
    <div hidden="hidden" className="stockwaveVehicleInfo">
      {data}
    </div>
  );
};

export class MobilityCarListTile extends Component {
  state = {
    viewerVisible: false,
    releaseHoldButtonText: "Release",
  };

  componentWillMount = () => {
    const { setTileRef, car } = this.props;
    this.myRef = createRef();
    this.setState({
      releaseHoldButtonText: car.isReservedForMobilityProgram
        ? "Release"
        : "Hold",
    });
    setTileRef(car, this.myRef);
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: true,
    });
  };

  toggleReleaseHoldButton = async () => {
    const { car } = this.props;
    if (this.state.releaseHoldButtonText === "Release") {
      const response = await mobilityServices.setIsReserved(
        car.saleEventItemId,
        false
      );
      if (response.status === 200) {
        this.setState({ releaseHoldButtonText: "Hold" });
      }
    } else {
      const response = await mobilityServices.setIsReserved(
        car.saleEventItemId,
        true
      );
      if (response.status === 200) {
        this.setState({ releaseHoldButtonText: "Release" });
      }
    }
  };

  render() {
    const {
      classes,
      car,
      selectCar,
      user,
      conditionReport,
      selectedCar,
      ref,
      toggleFullConditionReport,
      userRoleHasMobilityRideshareView,
      isCRAvailable,
    } = this.props;
    const { viewerVisible } = this.state;
    return (
      <React.Fragment>
        {conditionReport && conditionReport.inspectionImageDTO ? (
          <Viewer
            visible={viewerVisible}
            zoomSpeed={0.3}
            noClose={true}
            onMaskClick={(evt) => this.setState({ viewerVisible: false })}
            onClose={() => this.setState({ viewerVisible: false })}
            images={conditionReport.inspectionImageDTO.map((image) => {
              return {
                src: image.imageUrl,
                alt: image.damageImageCaption,
              };
            })}
          />
        ) : null}
        <Card
          className={`${
            car.vin === selectedCar.vin ? classes.selectedCard : classes.card
          } stockwaveVehicleRow`}
          id={`MobilityCarListTile${car.vin}`}
          onClick={() => selectCar(car, user, "DDO")}
          ref={this.myRef}
        >
          {stockwaveIntegrationDiv(car, user, conditionReport)}

          <CardActions className={classes.cardWrapper}>
            <div className={classes.imageWrapper}>
              <img
                id="primaryImage"
                src={isCRAvailable ? car.imagePathFc : TFSNoImage}
                className={classes.media}
                alt={`${car.make} ${car.model}`}
                onClick={() => this.toggleImageViewer()}
              />
            </div>
          </CardActions>
          <div className={classes.contentWrapper}>
            <div className={classes.textContainer}>
              <Typography
                className={
                  car.vin === selectedCar.vin
                    ? classes.selectedCardTitle
                    : classes.cardTitle
                }
              >
                {`${car.year} ${car.make} ${car.model} `}
                {car.series ? car.series : null}
                {` ${car.color ? car.color : null}`}
                <div className="stockWaveLightBulb"></div>
              </Typography>
              <div className={classes.textWrapper}>
                <CardContent className={classes.leftTextColumn}>
                  <Typography className={classes.cardText}>
                    VIN:{" "}
                    {isCRAvailable ? (
                      <span
                        className={classes.vinText}
                        onClick={() => toggleFullConditionReport(car, ref)}
                      >
                        {car.vin}
                      </span>
                    ) : (
                      <span className={classes.boldedText}>{car.vin}</span>
                    )}
                  </Typography>
                  <Typography className={classes.cardText}>
                    Inspection Mileage:{" "}
                    <span className={classes.boldedText}>
                      {car.inspectionMileage.toLocaleString()}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Promotions:{" "}
                    <span className={classes.orgText}>{car.promotion}</span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Account Type:{" "}
                    <span
                      className={
                        returnAccountType(car.accountType) === "Company Car"
                          ? classes.redBoldedText
                          : classes.boldedText
                      }
                    >
                      {returnAccountType(car.accountType)}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className={classes.rightTextColumn}>
                  <Typography className={classes.cardText}>
                    Location:{" "}
                    <span
                      className={classes.boldedText}
                    >{`${car.locationName}, ${car.state}`}</span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Engine:{" "}
                    <span className={classes.boldedText}>{car.engine}</span>
                  </Typography>

                  <Typography className={classes.cardText}>
                    Grade:
                    <span className={classes.boldedText}>{car.grade}</span>
                  </Typography>
                </CardContent>
              </div>
            </div>
          </div>

          <CardActions className={classes.cardActions}>
            <Button
              id="releaseHold"
              disabled={userRoleHasMobilityRideshareView}
              className={classes.bidButton}
              size="small"
              onClick={() => this.toggleReleaseHoldButton()}
            >
              {this.state.releaseHoldButtonText}
            </Button>
          </CardActions>
        </Card>
      </React.Fragment>
    );
  }
}

MobilityCarListTile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    accountSetting: state.accountSetting,
    watchlistCount: state.cars.watchlistCount,
    currentAuction: state.ui.currentAuction,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles)(MobilityCarListTile)
);
