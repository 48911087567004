import React, { Component } from "react";
import PropTypes from "prop-types";
import Viewer from "react-viewer";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

import Moment from "react-moment";

import CarReport from "../components/CarReport";

import { returnAccountType, returnAutoGrade } from "../services/HelperServices";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import ReactSVG from "react-svg";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import carfaxLogo from "../assets/carfaxLogo.svg";
import autoCheckLogo from "../assets/autoCheckLogo.png";

const styles = (theme) => ({
  conditionReport: {
    overflowY: "auto",
    height: "97%",
    margin: ".5rem",
    border: `3px solid ${theme.palette.primary.gray}`,
    [theme.breakpoints.down("md")]: {
      margin: 0,
      height: "100%",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "98.25%",
    },
  },
  vehicleInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "3.5rem",
  },
  imageWrapper: {
    display: "flex",
    maxHeight: "13rem",
    backgroundColor: theme.palette.secondary.gray,
    borderBottom: `3px solid ${theme.palette.primary.gray}`,
    flexDirection: "column",
    overflow: "hidden",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
  topImageWrapper: {
    display: "flex",
    height: "9rem",
    margin: ".5rem",
    justifyContent: "center",
  },
  topRightImageWrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "4%",
    justifyContent: "space-evenly",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  imageCarousel: {
    height: "3rem",
    display: "flex",
    marginLeft: ".5rem",
    marginBottom: ".25rem",
    marginRight: ".75rem",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-around",
  },
  leftNav: {
    height: "100%",
    display: "flex",
    position: "absolute",
    zIndex: 2,
    left: "-.8rem",
    backgroundColor: "rgb(0,0,0,.05)",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,.1)",
    },
    cursor: "pointer",
  },
  rightNav: {
    height: "100%",
    position: "absolute",
    display: "flex",
    zIndex: 2,
    right: "-1rem",
    backgroundColor: "rgb(0,0,0,.05)",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,.1)",
    },
    cursor: "pointer",
  },
  image: {
    minWidth: "3rem",
    maxWidth: "47%",
    maxHeight: "4rem",
    cursor: "pointer",
  },
  largeImage: {
    width: "55%",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  carouselImage: {
    height: "2.5rem",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "20%",
    },
  },
  table: {
    height: "100%",
  },
  row: {
    height: "2rem",
  },
  darkCell: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.light.gray,
    padding: 0,
    fontSize: theme.font.small,
    paddingLeft: ".5rem",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  lightCell: {
    color: theme.palette.primary.gray,
    backgroundColor: theme.palette.light.gray,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: ".5rem",
    "&:last-child": {
      paddingRight: 0,
    },
    fontSize: theme.font.small,
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  modelCell: {
    color: theme.palette.primary.gray,
    backgroundColor: theme.palette.light.gray,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: ".5rem",
    fontSize: theme.font.small,
    "&:last-child": {
      paddingRight: 0,
    },
    display: "flex",
    height: "2rem",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
      display: "table-cell",
    },
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    fontSize: theme.font.small,
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  unClickableLink: {
    fontWeight: "bold",
    fontSize: theme.font.small,
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  carInfoIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
  },
  carInfoIcon: {
    width: "40%",
  },
  vehicleTitleHeader: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  vehicleInformationHeader: {
    backgroundColor: theme.palette.GAMD.main,
    color: theme.palette.primary.white,
    height: "2rem",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: "0 0 2rem 2rem",
  },
  headerText: {
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.font.small,
    display: "flex",
  },
});

const returnStatus = (status) => {
  const statuses = {
    DLR_MBP_PENDING: "Market Price Pending",
    DLR_INELIGIBLE_PUR: "Ineligible for Purchase",
    DLR_INSP_PENDING: "Inspection Pending",
    DLR_ACTION_NEEDED: "Action Needed",
    DLR_BUY_NOW: "Buy Now",
    DLR_ARBITRATION: "Arbitration",
    DLR_OFRD_DB_AUCTION: "Offered in DB Auction",
    DLR_BID_NOW: "Bid Now",
    DLR_BAL_INSP_PENDING: "Inspection Pending",
    AWT_INSP: "Awaiting Inspection",
  };
  return statuses[status] || null;
};

export class MobileVehicleInformation extends Component {
  state = {
    keys: null,
    imageIndex: 0,
    carouselStartIndex: 0,
    showCarfaxReport: false,
    showCarfaxSnackBar: false,
  };

  getKeys = () => {
    const { conditionReport } = this.props;
    this.setState({
      keys: conditionReport.vehicleInformation.Keys,
    });
  };

  changeImage = (index) => {
    this.setState({
      imageIndex: index,
    });
  };
  componentDidMount = () => {
    const { conditionReport } = this.props;
    if (conditionReport) {
      this.getKeys();
    }
  };
  componentDidUpdate = (prevProps) => {
    const { conditionReport } = this.props;
    if (conditionReport && conditionReport !== prevProps.conditionReport) {
      this.getKeys();
    }
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: !this.state.viewerVisible,
    });
  };

  navigateImages = (nav) => {
    const { conditionReport } = this.props;
    if (nav === "back") {
      if (this.state.carouselStartIndex === 0) {
        this.setState({
          carouselStartIndex: conditionReport.inspectionImageDTO.length - 4,
        });
      } else {
        this.setState({
          carouselStartIndex: this.state.carouselStartIndex - 1,
        });
      }
    } else {
      if (
        conditionReport.inspectionImageDTO.length < 4 ||
        this.state.carouselStartIndex ===
          conditionReport.inspectionImageDTO.length - 4
      ) {
        this.setState({
          carouselStartIndex: 0,
        });
      } else {
        this.setState({
          carouselStartIndex: this.state.carouselStartIndex + 1,
        });
      }
    }
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  render() {
    const {
      classes,
      car,
      conditionReport,
      toggleFullConditionReport,
      selectedVehicleDetails,
    } = this.props;
    const {
      keys,
      imageIndex,
      viewerVisible,
      carouselStartIndex,
      showCarfaxReport,
      showCarfaxSnackBar,
    } = this.state;
    return (
      <div className={classes.vehicleInfoWrapper}>
        <div className={classes.vehicleTitleHeader}>
          <Typography className={classes.headerText}>
            {`${car.year} ${car.make} ${car.model} `}
            {car.color ? car.color : null}
          </Typography>
        </div>
        <div className={classes.vehicleInformationHeader}>
          <Typography className={classes.headerText}>
            Grounded Vehicle Information
          </Typography>
        </div>
        <div className={classes.carInfoIconWrapper}>
          <img
            className={classes.carInfoIcon}
            src={autoCheckLogo}
            onClick={() =>
              window.open(
                "https://www.autocheck.com/members/gateway.do",
                "_blank",
                ""
              )
            }
            alt="autoCheckIcon"
          />
          <ReactSVG
            id="carfaxIcon"
            className={classes.carInfoIcon}
            svgStyle={{ width: "100%", position: "relative", top: ".3rem" }}
            src={carfaxLogo}
            onClick={() => this.showCarfaxReport()}
          />
        </div>
        <div className={classes.conditionReport}>
          {conditionReport ? (
            <Viewer
              activeIndex={imageIndex}
              visible={viewerVisible}
              zoomSpeed={0.3}
              noClose={true}
              onMaskClick={(evt) => this.setState({ viewerVisible: false })}
              onClose={() => this.setState({ viewerVisible: false })}
              images={conditionReport.inspectionImageDTO.map((image) => {
                return { src: image.imageUrl, alt: image.damageImageCaption };
              })}
            />
          ) : null}
          {conditionReport ? (
            <div className={classes.imageWrapper}>
              <div className={classes.topImageWrapper}>
                <img
                  id="largeImage"
                  className={classes.largeImage}
                  src={conditionReport.inspectionImageDTO[imageIndex].imageUrl}
                  alt=""
                  onClick={() => {
                    this.toggleImageViewer();
                  }}
                />
                <div className={classes.topRightImageWrapper}>
                  {conditionReport.inspectionImageDTO
                    .slice(imageIndex + 1, imageIndex + 5)
                    .map((image) => {
                      return (
                        <img
                          key={image.imageUrl}
                          className={classes.image}
                          src={image.imageUrl}
                          alt=""
                          onClick={(evt) =>
                            this.changeImage(image.sequenceNumber - 1)
                          }
                        />
                      );
                    })}
                </div>
              </div>
              <div className={classes.imageCarousel}>
                <div
                  id="leftNav"
                  className={classes.leftNav}
                  onClick={(evt) => this.navigateImages("back")}
                >
                  <ArrowLeft className={classes.navIcon} />
                </div>
                {conditionReport.inspectionImageDTO
                  .slice(carouselStartIndex, carouselStartIndex + 4)
                  .map((image) => {
                    return (
                      <img
                        id={`image${image.sequenceNumber}`}
                        className={classes.carouselImage}
                        src={image.imageUrl}
                        alt={image.sequenceNumber}
                        key={image.sequenceNumber}
                        onClick={(evt) =>
                          this.changeImage(image.sequenceNumber - 1)
                        }
                      />
                    );
                  })}
                <div
                  id="rightNav"
                  className={classes.rightNav}
                  onClick={(evt) => this.navigateImages()}
                >
                  <ArrowRight className={classes.navIcon} />
                </div>
              </div>
            </div>
          ) : null}
          <Table className={classes.table}>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>VIN</TableCell>
                <TableCell className={classes.lightCell}>
                  <Typography
                    id="toggleConditionReport"
                    className={
                      conditionReport
                        ? classes.clickableLink
                        : classes.unClickableLink
                    }
                    onClick={
                      conditionReport ? () => toggleFullConditionReport() : null
                    }
                  >
                    {car.vehicleIdentificationNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              {conditionReport ? (
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>
                    Model Number
                  </TableCell>
                  <TableCell className={classes.modelCell}>
                    {conditionReport.vehicleInformation["Model#"]}
                  </TableCell>
                </TableRow>
              ) : null}
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>Status</TableCell>
                <TableCell className={classes.lightCell}>
                  {returnStatus(car.dealerFacingStatus)}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>Account Type</TableCell>
                <TableCell className={classes.lightCell}>
                  {returnAccountType(car.accountType)}
                </TableCell>
              </TableRow>
              {conditionReport ? (
                <React.Fragment>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>Keys</TableCell>
                    <TableCell className={classes.lightCell}>{keys}</TableCell>
                  </TableRow>
                </React.Fragment>
              ) : null}
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Remaining Payments
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.inventoryDTO.leaseInformationDTO
                    .remainingPaymentAmount &&
                  car.inventoryDTO.leaseInformationDTO
                    .remainingPaymentAmount !== null &&
                  (car.displayGuaranteePaymentsOnly ||
                    car.inventoryDTO.leaseInformationDTO.waivedPayments)
                    ? car.inventoryDTO.leaseInformationDTO.remainingPaymentAmount.toLocaleString(
                        "en-US",
                        { style: "currency", currency: "USD" }
                      )
                    : null}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>Residual</TableCell>
                <TableCell className={classes.lightCell}>
                  {car.residualAmount && car.residualAmount !== null
                    ? car.residualAmount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : null}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Payoff Amount
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.grossPayoff && car.grossPayoff !== null
                    ? car.grossPayoff.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : null}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Market Based Price
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.marketBasedPrice && car.marketBasedPrice !== null
                    ? car.marketBasedPrice.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : null}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Grounding Mileage
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.groundingMileage && car.groundingMileage !== null
                    ? car.groundingMileage.toLocaleString()
                    : null}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Grounding Date
                </TableCell>
                <TableCell className={classes.lightCell}>
                  <Moment interval={0} format={"MM/DD/YYYY"}>
                    {car.groundingDate}
                  </Moment>
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Payment Guaranteed
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.paymentGuaranteed ? "Yes" : "No"}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>Lease Type</TableCell>
                <TableCell className={classes.lightCell}>
                  {car.inventoryDTO.leaseInformationDTO.leaseType
                    ? car.inventoryDTO.leaseInformationDTO.leaseType
                    : "Close"}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Grounding Dealer
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.groundingDealer}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Grounder Name
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {selectedVehicleDetails.inventoryDTO
                    ? selectedVehicleDetails.inventoryDTO.grounder
                    : car.groundingDealerName}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Payment Waiver
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.programDTO[0] && car.programDTO[0].programCode === "LPW"
                    ? "Eligible"
                    : "Not Eligible"}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Transportation Ordered
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.transportationMethods ? "Yes" : "No"}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Transportation Status
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.transportationStatus || ""}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Grade Condition
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {returnAutoGrade(car)}
                </TableCell>
              </TableRow>
              {conditionReport ? (
                <TableRow className={classes.row}>
                  <TableCell className={classes.darkCell}>EWT</TableCell>
                  <TableCell className={classes.lightCell}>
                    {conditionReport.vehicleConditions[0]
                      .excessWearAndTearSubTotal &&
                      conditionReport.vehicleConditions[0].excessWearAndTearSubTotal.toLocaleString(
                        "en-US",
                        { style: "currency", currency: "USD" }
                      )}
                  </TableCell>
                </TableRow>
              ) : null}
              {conditionReport ? (
                <React.Fragment>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>
                      Inspection Completion Date
                    </TableCell>
                    <TableCell className={classes.lightCell}>
                      {conditionReport.inspectionDate}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.darkCell}>
                      Inspection Mileage
                    </TableCell>
                    <TableCell className={classes.lightCell}>
                      {conditionReport.vehicleInformation.Odometer}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ) : null}
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Not Inspected Reason
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.notInspReason || ""}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>Sold Date</TableCell>
                <TableCell className={classes.lightCell}>
                  {car.purchaseDate}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Purchasing Dealer
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.purchasingDealer}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Purchase Type
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.purchaseType}
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.darkCell}>
                  Gross Purchase Amount
                </TableCell>
                <TableCell className={classes.lightCell}>
                  {car.purchaseAmountWithoutFeesAndRemainingPayment}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        {showCarfaxReport ? (
          <CarReport
            vin={
              conditionReport
                ? conditionReport.vin
                : car.vehicleIdentificationNumber
            }
            reportType="carfax"
            showCarfaxReport={this.showCarfaxReport}
            showNoDataVin={this.showNoDataVin}
          />
        ) : null}
        {showCarfaxSnackBar ? (
          <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
        ) : null}
      </div>
    );
  }
}

MobileVehicleInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(MobileVehicleInformation));
