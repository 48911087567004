import React, { Component } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";

import { Typography, Checkbox } from "@material-ui/core";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const awsUrl = process.env.REACT_APP_WCM_CONTENT;

export class BidConfirmPage extends Component {
  componentWillMount = () => {
    this.interval = setInterval(() => {
      this.setState({
        timeRemaining: this.nextAuctionTime(),
      });
    }, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  nextAuctionTime = () => {
    const { preSale1, sale1, sale2 } = this.props.auctionTimes;
    const now = this.getCurrentTimeInEST();
    if (now.isBefore(sale1.end)) {
      return sale1.end;
    } else if (now.isBefore(sale2.end)) {
      return sale2.end;
    } else {
      return preSale1.begin;
    }
  };

  //get easter standard time
  getTimeInEst = () => {
    // get time date time in user timezone
    let now = moment();
    let nowObjWithUtcOffset = moment.utc(now.format());
    let _tzmOffset = 0;

    // set offset for user timezone
    if (Math.sign(nowObjWithUtcOffset._tmz) === -1) {
      _tzmOffset = Math.abs(nowObjWithUtcOffset._tzm) - 240;
    } else {
      _tzmOffset = -(240 + nowObjWithUtcOffset._tzm);
    }

    // add offset minutes
    let nowEst = now.add(_tzmOffset, "minutes");
    return nowEst;
  };

  getCurrentTimeInEST = () => {
    // Get the current time in Eastern Time (EST)
    return moment().tz('America/New_York');
  }

  timeRemaining = () => {
    let nowEst = this.getCurrentTimeInEST();
    let time = moment(this.nextAuctionTime()).diff(nowEst, "seconds");
    const minutes = time / 60;
    const rminutes = Math.floor(minutes);
    const seconds = (minutes - rminutes) * 60;
    const rseconds = Math.round(seconds);
    return { minutes: rminutes, seconds: rseconds };
  };

  verifyQuotePrice = (quote) => {
    const { car } = this.props;
    if (car.programCode === "TAP") {
      return `$${quote.quoteWithPromo}`;
    } else {
      return quote.quote.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  };

  render() {
    const {
      classes,
      paymentOptionSelected,
      bidType,
      bidAmount,
      car,
      transport,
      transportQuote,
      user,
      vehicleBidInformation,
      pageTheme,
    } = this.props;
    const progressStyles = {
      path: {
        stroke: "#3F853E",
      },
      trail: {
        stroke: "#DEE0E2",
      },
    };
    const timeRemaining = this.timeRemaining();

    return (
      <div className={classes.buyPriceWrapper}>
        <div className={classes.lowerLeftWrapper}>
          <Typography className={classes.lowerLeftTitle}>
            Bid Information
          </Typography>
          <div className={classes.infoWrapper}>
            <div className={classes.lineWrapper}>
              <Typography className={classes.bolded}>
                {bidType === "autoBid" ? "Auto Bid" : "Bid"} Amount:
              </Typography>
              <Typography className={classes.DDOBoldedColor}>
                {bidAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </Typography>
            </div>
            <div className={classes.lineWrapper}>
              <Typography className={classes.bolded}>
                Administrative Fee:
              </Typography>
              <Typography className={classes.DDOBoldedColor}>
                {car.buyFee
                  ? car.buyFee.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : vehicleBidInformation.buyFee.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </Typography>
            </div>
            <div className={classes.lineWrapper}>
              <Typography className={classes.bolded}>
                Transportation:
              </Typography>
              <Typography className={classes.DDOBoldedColor}>
                {transportQuote && transport !== "Buyer Arranged"
                  ? this.verifyQuotePrice(transportQuote)
                  : "Buyer Arranged"}
              </Typography>
            </div>
            <div className={classes.card}>
              <div className={classes.progressBarWrapper}>
                <CircularProgressbarWithChildren
                  styles={progressStyles}
                  value={(timeRemaining.minutes / 5) * 100}
                  className={classes.circularProgressBar}
                >
                  <Typography className={classes.timeDisplay}>
                    {timeRemaining.minutes}
                  </Typography>
                  <Typography className={classes.interiorText}>
                    minutes
                  </Typography>
                </CircularProgressbarWithChildren>
                <CircularProgressbarWithChildren
                  styles={progressStyles}
                  value={(timeRemaining.seconds / 60) * 100}
                  className={classes.circularProgressBar}
                >
                  <Typography className={classes.timeDisplay}>
                    {timeRemaining.seconds}
                  </Typography>
                  <Typography className={classes.interiorText}>
                    seconds
                  </Typography>
                </CircularProgressbarWithChildren>
              </div>
              <Typography className={classes.DDOCardTitle}>
                {"Time Remaining to Bid"}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.rightWrapper}>
          <div className={classes.paymentSelectionWrapper}>
            <Typography className={classes.paymentSelectionTitle}>
              Payment Method
            </Typography>
            <div className={classes.paymentLineWrapper}>
              <div className={classes.priceWrapper}>
                <Checkbox
                  classes={{
                    root: classes.checkboxRootDDO,
                    checked: classes.checked,
                  }}
                  checked={true}
                />
                <Typography
                  style={{ marginLeft: ".5rem" }}
                  className={classes.DDOBoldedColor}
                >
                  {paymentOptionSelected === "ACH"
                    ? "ACH"
                    : user.franchise === "TBSG"
                    ? "TFS Floor Plan"
                    : "LFS Floor Plan"}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.summaryWrapper}>
            <Typography className={classes.bolded}>
              {`I have read and agree to the Terms and Conditions stated in the `}
              <a
                id="participatingDealerAgreement"
                href={
                  awsUrl +
                  (pageTheme === "Lexus"
                    ? "/docs/lexus/resources/ParticipatingDealerAgreement.pdf"
                    : "/docs/toyota/resources/ParticipatingDealerAgreement.pdf")
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Participating Dealer Agreement
              </a>
            </Typography>
            <Typography className={classes.bolded}>
              {`Selected Transportation Method: ${transport}`}
            </Typography>
            <Typography className={classes.bolded}>
              {`Title will be sent upon receipt of payment.`}
            </Typography>
            <Typography className={classes.bolded}>
              {`Please allow 7-10 days for delivery.`}
            </Typography>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    pageTheme: state.ui.pageTheme,
  };
};

export default connect(mapStateToProps)(BidConfirmPage);
