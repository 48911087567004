const AME_URL = process.env.REACT_APP_AME_URL;
const OPTIONS = {
  method: "POST",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export async function startConnection(saleEventItemIds) {
  try {
    const data = await handshake();
    const response = data[0];
    if (response.successful) {
      saleEventItemIds.map(async (saleEventItemId) => {
        await subscribe(response.clientId, saleEventItemId);
      });
      return response.clientId;
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getBidHistory(clientId) {
  try {
    const connectResponse = await connect(clientId);

    if (connectResponse[0].successful) {
      return connectResponse;
    }
  } catch (err) {
    console.log(err);
  }
}

async function handshake() {
  const hanshakeMessage = JSON.stringify({
    channel: "/meta/handshake",
  });

  try {
    const resp = await fetch(
      `${AME_URL}?message=${encodeURIComponent(hanshakeMessage)}`,
      OPTIONS
    );
    return resp.json();
  } catch (error) {
    console.log(error);
    throw Error("Caught an error while handshaking");
  }
}

async function connect(clientId) {
  const connectMessage = {
    channel: "/meta/connect",
    clientId: clientId,
  };
  let message = JSON.stringify(connectMessage);

  try {
    const resp = await fetch(
      `${AME_URL}?message=${encodeURIComponent(message)}`,
      OPTIONS
    );
    return resp.json();
  } catch (error) {
    console.log(error);
    throw Error("Caught an error while connecting");
  }
}

async function subscribe(clientId, saleEventItemId) {
  const subscribeMessage = [
    {
      channel: "/meta/subscribe",
      subscription: `/bids/${saleEventItemId}`,
      clientId: clientId,
    },
    {
      channel: "/meta/subscribe",
      subscription: `/buynow/${saleEventItemId}`,
      clientId: clientId,
    },
  ];

  let message = JSON.stringify(subscribeMessage);

  try {
    const resp = await fetch(
      `${AME_URL}?message=${encodeURIComponent(message)}`,
      OPTIONS
    );
    return resp.json();
  } catch (error) {
    console.log(error);
    throw Error("Caught an error while subscribing");
  }
}
