const API_URL = process.env.REACT_APP_API_URL;

export const redeemMobileCode = async (user, franchise) => {
  const token = sessionStorage.getItem("token");
  const OPTIONS = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(
      `${API_URL}/mobileCodeRedemption/fetchAvailableRedemptionCode?userID=${user.userId}&franchise=${franchise}`,
      OPTIONS
    );
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      return {
        status: response.status,
        message: "The server is having issues, please try again later.",
      };
    }
  } catch (err) {
    return {
      status: "false",
      message: err.message,
    };
  }
};
