export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const UPDATE_DEALER_EXCLUSIVITY = "UPDATE_DEALER_EXCLUSIVITY";

const initialState = {
  failedLoginAttempts: 0,
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

export const loginFail = (state = initialState) => {
  console.log(state.failedLoginAttempts);
  return {
    type: LOGIN_FAIL,
    failedLoginAttempts: state.failedLoginAttempts + 1,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const update_dealer_exclusivity = (dlrExculsivityExtensionValue) => {
  return {
    type: UPDATE_DEALER_EXCLUSIVITY,
    dlrExculsivityExtensionValue,
  };
};

export const reset_password_success = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
};

export const reset_password_fail = () => {
  return {
    type: RESET_PASSWORD_FAIL,
  };
};
