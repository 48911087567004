import React, { PureComponent } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { set_current_auction, set_auction_times } from "../actions/uiActions";

import {
  setCurrentAuctionTimesFromOnDemand,
} from "../services/HelperServices";

const styles = (theme) => ({
  auctionTimeDisplay: {
    fontSize: theme.font.small,
    marginBottom: ".25rem",
    width: "17rem",
    [theme.breakpoints.down("md")]: {
      width: "7rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "16rem",
    },
  },
  crosslineDisplay: {
    fontSize: theme.font.small,
    marginBottom: ".25rem",
    width: "8rem",
  },
  redText: {
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
  },
  greenText: {
    color: theme.palette.DDO.main,
    fontWeight: "bold",
  },
  nextAuction: {
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
  },
});

export class DDOAuctionTimer extends PureComponent {
  state = {
    vehiclePreview: null,
    preSale1: null,
    sale1: null,
    preSale2: null,
    sale2: null,
    nextAuction: 0,
    nextSale: "preSale1",
    auctionLabel: "1st Online Auction Starts in",
    saleVerbiage: "Same Brand Buy Now",
  };

  componentWillMount = () => {
    this.auctionTicker = setInterval(async () => {
      this.setState({
        nextAuction: await this.nextAuction(),
      });
    }, 1000);
  };

  componentDidUpdate = (prevState, prevProps) => {
    const { nextSale } = this.state;
    const { dispatch, currentAuction } = this.props;
    if (nextSale !== currentAuction) {
      dispatch(set_current_auction(nextSale));
    }
  };

  nextAuctionTime = async () => {
    const { dispatch } = this.props;
    const now = moment().tz("America/New_York");

    if (this.state.vehiclePreview === null) {
      const saleTimes = await setCurrentAuctionTimesFromOnDemand();
      dispatch(set_auction_times(saleTimes));
      this.state.vehiclePreview = saleTimes.vehiclePreview;
      this.state.preSale1 = saleTimes.preSale1;
      this.state.sale1 = saleTimes.sale1;
      this.state.preSale2 = saleTimes.preSale2;
      this.state.sale2 = saleTimes.sale2;
    }

    if (
      now.isAfter(moment({ hour: 0, minutes: 0, seconds: 0 })) &&
      now.isBefore(this.state.vehiclePreview.end) &&
      this.state.sale1.begin.isBefore(this.state.sale2.begin)
    ) {
      this.setState({
        nextSale: "vehiclePreview",
        auctionLabel: "Buy Now Sale Starts in",
        saleVerbiage: "Vehicle Preview",
      });
      return this.state.vehiclePreview.end;
    } else if (
      now.isAfter(this.state.preSale1.begin) &&
      now.isBefore(this.state.preSale1.end) &&
      this.state.sale1.begin.isBefore(this.state.sale2.begin)
    ) {
      this.setState({
        nextSale: "preSale1",
        auctionLabel: "1st Online Auction Starts in",
        saleVerbiage: "Same Brand Buy Now",
      });
      return this.state.preSale1.end;
    } else if (
      now.isAfter(this.state.sale1.begin) &&
      now.isBefore(this.state.sale1.end)
    ) {
      this.setState({
        nextSale: "sale1",
        auctionLabel: "1st Online Auction Ends in",
        saleVerbiage: "Same Brand Open",
      });
      return this.state.sale1.end;
    } else if (now.isBefore(this.state.preSale2.end)) {
      this.setState({
        nextSale: "preSale2",
        auctionLabel: "2nd Online Auction Starts in",
        saleVerbiage: "Cross Brand Buy Now",
      });

      return this.state.preSale2.end;
    } else if (
      now.isAfter(this.state.sale2.begin) &&
      now.isBefore(this.state.sale2.end)
    ) {
      this.setState({
        nextSale: "sale2",
        auctionLabel: "2nd Online Auction Ends in",
        saleVerbiage: "Cross Brand Open",
      });
      return this.state.sale2.end;
    }
  };

  weekEndInfo = (auctionData) => {
    this.setState({
      auctionLabel: this.state.auctionLabel.replace("in", "on"),
    });

    return `${moment(auctionData)
      .tz("America/New_York")
      .format("MM/DD/YYYY h:mm a")} ET`;
  };

  nextAuction = async () => {
    const now = moment().tz("America/New_York");
    const currentDay = moment().tz("America/New_York").format("d");
    const auctionData = await this.nextAuctionTime();

    if (currentDay == "5") {
      if (
        (now.isSame(this.state.sale2.end, "day") &&
          now.isAfter(this.state.sale2.end)) ||
        !now.isSame(this.state.sale2.end, "day")
      ) {
        return this.weekEndInfo(auctionData);
      }
    } else if (currentDay == "0" || currentDay == "6") {
      return this.weekEndInfo(auctionData);
    }

    const time = moment(auctionData).diff(now, "milliseconds");

    const hours =
      Math.floor((time / 3600000) % 60) >= 10
        ? Math.floor(time / 3600000)
        : `0${Math.floor((time / 3600000) % 60)}`;
    const minutes =
      Math.floor((time / 60000) % 60) >= 10
        ? Math.floor((time / 60000) % 60)
        : `0${Math.floor((time / 60000) % 60)}`;
    const seconds =
      Math.floor((time / 1000) % 60) >= 10
        ? Math.floor((time / 1000) % 60)
        : `0${Math.floor((time / 1000) % 60)}`;
    return `${hours}:${minutes}:${seconds}`;
  };

  componentWillUnmount = () => {
    clearInterval(this.auctionTicker);
  };

  render() {
    const { classes } = this.props;
    const { nextAuction, auctionLabel, saleVerbiage } = this.state;
    return (
      <React.Fragment>
        <Typography className={classes.auctionTimeDisplay}>
          {auctionLabel}:{" "}
          <span className={classes.nextAuction}> {`${nextAuction} `}</span>
        </Typography>
        <Typography className={classes.crosslineDisplay}>
          {saleVerbiage}
        </Typography>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    auctionTimes: state.ui.auctionTimes,
    currentAuction: state.ui.currentAuction,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(DDOAuctionTimer));
