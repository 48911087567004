import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Card,
  FormControl,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Select,
  MenuItem,
  Chip,
  Button,
} from "@material-ui/core";

import {
  advance_search_criteria,
  advance_search_info,
  mobile_search_criteria,
} from "../actions/carActions";
import {
  advanced_search_criteria_wrapper_data,
  advanced_search_criteria_data,
} from "../actions/uiActions";

import ReactSVG from "react-svg";
import Save from "../assets/save.svg";
import Setting from "../assets/settings.svg";

import * as AdvanceSearchServices from "../services/AdvanceSearchServices";
import SearchCheckboxOptions from "../components/SearchCheckboxOptions";
import Loading from "../components/Loading";

const styles = (theme) => ({
  select: {
    minWidth: "130px",
  },
  alignContent: {
    alignContent: "center",
  },
  odoDD: {
    padding: ".5rem",
    width: "50%",
    display: "flex",
  },
  odoDDSelect: {
    padding: ".5rem",
    width: "70%",
    display: "flex",
  },
  odoLabel: {
    padding: ".5rem",
  },
  selectDivStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    margin: "0.25rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.primary.gray,
    width: "50%",
    fontWeight: "bold",
    textTransform: "initial",
    fontSize: ".8rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.gray,
      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.gray,
        "&:active": {
          backgroundColor: theme.palette.primary.gray,
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      height: "3.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "2.5rem",
    },
  },
  chipSection: {
    marginBottom: ".5rem",
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem !important",
    },
  },
  saveSearchLink: {
    margin: "0.5rem",
  },

  seperatorDiv: {
    paddingTop: "0.2em",
  },
  countStyle: {
    padding: "0.3rem",
    marginTop: ".4rem",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      marginTop: ".9rem",
      fontSize: ".8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
  criteriaWrapper: {
    padding: "0.4rem",
    maxWidth: "92%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "96%",
      height: "98%",
    },
  },
  iconArrange: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      height: "10.5%",
      alignItems: "center",
    },
  },
  scrollBar: {
    height: "79%",
    overflow: "auto",
  },
  buttonWrapper: {
    textAlign: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      height: "10.5%",
    },
  },
  buttonSearch: {
    backgroundColor: theme.palette.primary.gray,
    width: "2.5rem",
    height: "2.00rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
    "&:hover": {
      opacity: ".5",
    },
  },
  formControl: {
    display: "flex",
    height: "2rem",
    marginTop: ".5rem",
    flexDirection: "row",
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "3rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fieldLabelDistance: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "7rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectInput: {
    fontSize: ".8rem",
    minWidth: "6rem",
    textAlign: "center",
  },
  count: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "@global": {
    "label + .MuiInput-formControl": {
      marginTop: 0,
      width: "6rem",
      transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
    ".MuiChip-root": {
      backgroundColor: "#e0e0e0",
    },
    "#menu-State, #menu-distance, #menu-odometerFrom, #menu-odometerTo, #menu-priceFrom, #menu-priceTo, #menu-yearFrom, #menu-yearTo":
      {
        zIndex: "10001 !important",
      },
    ".MuiChip-label": {
      [theme.breakpoints.down("sm")]: {
        fontSize: ".6rem",
      },
    },
  },
});

const distanceOption = [
  "< 25",
  "< 50",
  "< 75",
  "< 100",
  "< 150",
  "< 200",
  "< 300",
  "< 400",
  "< 500",
  "< 750",
  "< 1000",
  "< 1500",
  "< 2000",
  "Over 2000",
];
const doorsOption = ["1", "2", "3", "4", "5"];
const driveTrainOption = ["4WD", "AWD", "FWD", "RWD"];
const regionOption = ["All", "Eastern", "Western", "Puerto Rico", "Central"];
const odometerFromOption = [
  { value: "0", text: "0" },
  { value: "10000", text: "10,000" },
  { value: "20000", text: "20,000" },
  { value: "30000", text: "30,000" },
  { value: "40000", text: "40,000" },
  { value: "50000", text: "50,000" },
  { value: "60000", text: "60,000" },
  { value: "75000", text: "75,000" },
  { value: "100000", text: "100,000" },
  { value: "125000", text: "125,000" },
  { value: "150000", text: "150,000" },
  { value: "200000", text: "200,000" },
];
const odometerToOption = [
  { value: "0", text: "0" },
  { value: "10000", text: "10,000" },
  { value: "20000", text: "20,000" },
  { value: "30000", text: "30,000" },
  { value: "40000", text: "40,000" },
  { value: "50000", text: "50,000" },
  { value: "60000", text: "60,000" },
  { value: "75000", text: "75,000" },
  { value: "100000", text: "100,000" },
  { value: "125000", text: "125,000" },
  { value: "150000", text: "150,000" },
  { value: "200000", text: "200,000" },
  { value: "200000+", text: "200,000+" },
];
const priceFromOption = [
  { value: "0", text: "$0" },
  { value: "10000", text: "$10,000" },
  { value: "15000", text: "$15,000" },
  { value: "20000", text: "$20,000" },
  { value: "25000", text: "$25,000" },
  { value: "30000", text: "$30,000" },
  { value: "35000", text: "$35,000" },
  { value: "40000", text: "$40,000" },
  { value: "45000", text: "$45,000" },
  { value: "50000", text: "$50,000" },
  { value: "55000", text: "$55,000" },
  { value: "60000", text: "$60,000" },
  { value: "65000", text: "$65,000" },
  { value: "75000", text: "$75,000" },
  { value: "100000", text: "$100,000" },
];
const priceToOption = [
  { value: "0", text: "$0" },
  { value: "10000", text: "$10,000" },
  { value: "15000", text: "$15,000" },
  { value: "20000", text: "$20,000" },
  { value: "25000", text: "$25,000" },
  { value: "30000", text: "$30,000" },
  { value: "35000", text: "$35,000" },
  { value: "40000", text: "$40,000" },
  { value: "45000", text: "$45,000" },
  { value: "50000", text: "$50,000" },
  { value: "55000", text: "$55,000" },
  { value: "60000", text: "$60,000" },
  { value: "65000", text: "$65,000" },
  { value: "75000", text: "$75,000" },
  { value: "100000", text: "$100,000" },
  { value: "+100000", text: "$100,000+" },
];

export class AdvanceSearchCriteria extends Component {
  state = {
    advancedSearchCriteriaWrapper: [],
    advancedSearchCriteria: [],
    make: [],
    selectedMake: [],
    model: [],
    selectedModel: [],
    modelNumber: [],
    selectedModelNumber: [],
    series: [],
    selectedSeries: [],
    interiorColor: [],
    selectedInteriorColor: [],
    exteriorColor: [],
    selectedExteriorColor: [],
    vehicleGrade: [],
    selectedVehicleGrade: [],
    style: [],
    selectedStyle: [],
    yearFrom: [],
    selectedYearFrom: "All",
    yearTo: [],
    selectedYearTo: "All",
    distance: distanceOption,
    selectedDistance: "All",
    door: doorsOption,
    selectedDoor: [],
    driveTrain: driveTrainOption,
    selectedDriveTrain: [],
    priceFrom: priceFromOption,
    selectedPriceFrom: "0",
    priceTo: priceToOption,
    selectedPriceTo: "+100000",
    odometerFrom: odometerFromOption,
    selectedOdometerFrom: "0",
    odometerTo: odometerToOption,
    selectedOdometerTo: "200000+",
    region: regionOption,
    selectedRegion: [],
    states: [],
    selectState: "",
    transmission: [],
    selectedTransmission: [],
    engine: [],
    selectedEngine: [],
    options: [],
    selectedOptions: [],
    pageLoading: true,
  };

  clearSearch = () => {
    const { clearSearchResult } = this.props;
    this.setState({
      selectedMake: [],
      selectedModel: [],
      selectedModelNumber: [],
      selectedSeries: [],
      selectedInteriorColor: [],
      selectedExteriorColor: [],
      selectedVehicleGrade: [],
      selectedStyle: [],
      selectedYearFrom: "All",
      selectedYearTo: "All",
      selectedDistance: "All",
      selectedDoor: [],
      selectedDriveTrain: [],
      selectedPriceFrom: "0",
      selectedPriceTo: "+100000",
      selectedOdometerFrom: "0",
      selectedOdometerTo: "200000+",
      selectedRegion: [],
      selectState: [],
      selectedTransmission: [],
      selectedEngine: [],
      selectedOptions: [],
    });
    clearSearchResult();
  };

  componentWillMount = () => {
    this.populateSearchCriteria();
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    dispatch(mobile_search_criteria(this.state));
  };

  populateSearchCriteria = async () => {
    const {
      searchParamInfo,
      advancedSearchCriteriaWrapperData,
      advancedSearchCriteriaData,
      dispatch,
      mobileSearchCriteria,
      mobileSearchEditInfo,
    } = this.props;
    let advancedSearchCriteriaWrapperConst = {};
    let advancedSearchCriteriaConst = {};
    if (
      advancedSearchCriteriaWrapperData !== null &&
      advancedSearchCriteriaData !== null
    ) {
      advancedSearchCriteriaWrapperConst = advancedSearchCriteriaWrapperData;
      advancedSearchCriteriaConst = advancedSearchCriteriaData;
    } else {
      advancedSearchCriteriaWrapperConst =
        await AdvanceSearchServices.advancedSearchCriteriaWrapper();
      advancedSearchCriteriaConst =
        await AdvanceSearchServices.advancedSearchCriteria();
      dispatch(
        advanced_search_criteria_wrapper_data(
          advancedSearchCriteriaWrapperConst
        )
      );
      dispatch(advanced_search_criteria_data(advancedSearchCriteriaConst));
    }
    this.parseSearchCriteria(
      advancedSearchCriteriaWrapperConst,
      advancedSearchCriteriaConst
    );
    if (mobileSearchEditInfo !== undefined && mobileSearchEditInfo !== null) {
      this.setState({
        selectedMake: mobileSearchEditInfo.selectedMake
          ? mobileSearchEditInfo.selectedMake
          : mobileSearchEditInfo.make,
        selectedModel: mobileSearchEditInfo.selectedModel
          ? mobileSearchEditInfo.selectedModel
          : mobileSearchEditInfo.model,
        selectedModelNumber: mobileSearchEditInfo.selectedModelNumber
          ? mobileSearchEditInfo.selectedModelNumber
          : mobileSearchEditInfo.modelNumber,
        selectedSeries: mobileSearchEditInfo.selectedSeries
          ? mobileSearchEditInfo.selectedSeries
          : mobileSearchEditInfo.series,
        selectedInteriorColor: mobileSearchEditInfo.selectedInteriorColor
          ? mobileSearchEditInfo.selectedInteriorColor
          : mobileSearchEditInfo.interiorColor,
        selectedExteriorColor: mobileSearchEditInfo.selectedExteriorColor
          ? mobileSearchEditInfo.selectedExteriorColor
          : mobileSearchEditInfo.exteriorColor,
        selectedVehicleGrade: mobileSearchEditInfo.selectedVehicleGrade
          ? mobileSearchEditInfo.selectedVehicleGrade
          : mobileSearchEditInfo.vehicleGrade,
        selectedStyle: mobileSearchEditInfo.selectedStyle
          ? mobileSearchEditInfo.selectedStyle
          : mobileSearchEditInfo.bodyStyle,
        selectedRegion: mobileSearchEditInfo.selectedRegion
          ? mobileSearchEditInfo.selectedRegion
          : mobileSearchEditInfo.regions,
        selectedDoor: mobileSearchEditInfo.selectedDoor
          ? mobileSearchEditInfo.selectedDoor
          : mobileSearchEditInfo.doors,
        selectedDriveTrain: mobileSearchEditInfo.selectedDriveTrain
          ? mobileSearchEditInfo.selectedDriveTrain
          : mobileSearchEditInfo.driveTrain,
        selectedYearFrom: mobileSearchEditInfo.fromYear
          ? mobileSearchEditInfo.fromYear
          : this.state.selectedYearFrom,
        selectedYearTo: mobileSearchEditInfo.toYear
          ? mobileSearchEditInfo.toYear
          : this.state.selectedYearTo,
        selectedDistance: mobileSearchEditInfo.distanceFromUser
          ? mobileSearchEditInfo.distanceFromUser
          : this.state.selectedDistance,
        selectedPriceFrom:
          mobileSearchEditInfo.fromPrice !== null
            ? mobileSearchEditInfo.fromPrice
            : this.state.selectedPriceFrom,
        selectedPriceTo:
          mobileSearchEditInfo.toPrice !== null
            ? mobileSearchEditInfo.toPrice
            : this.state.selectedPriceTo,
        selectedOdometerFrom:
          mobileSearchEditInfo.fromOdometer !== null
            ? mobileSearchEditInfo.fromOdometer
            : this.state.selectedOdometerFrom,
        selectedOdometerTo:
          mobileSearchEditInfo.toOdometer !== null
            ? mobileSearchEditInfo.toOdometer
            : this.state.selectedOdometerTo,
        selectState:
          mobileSearchEditInfo.states !== null &&
          mobileSearchEditInfo.states.length > 0
            ? mobileSearchEditInfo.states[0]
            : this.state.selectState,
        selectedTransmission: mobileSearchEditInfo.selectedTransmission
          ? mobileSearchEditInfo.selectedTransmission
          : mobileSearchEditInfo.transmission,
        selectedEngine: mobileSearchEditInfo.selectedEngine
          ? mobileSearchEditInfo.selectedEngine
          : mobileSearchEditInfo.engine,
        selectedOptions: mobileSearchEditInfo.selectedOptions
          ? mobileSearchEditInfo.selectedOptions
          : mobileSearchEditInfo.options,
        advancedSearchCriteriaWrapper: advancedSearchCriteriaWrapperConst,
        advancedSearchCriteria: advancedSearchCriteriaConst,
      });
    } else if (searchParamInfo) {
      this.setState({
        selectedMake: searchParamInfo.selectedMake,
        selectedModel: searchParamInfo.selectedModel,
        selectedModelNumber: searchParamInfo.selectedModelNumber,
        selectedSeries: searchParamInfo.selectedSeries,
        selectedInteriorColor: searchParamInfo.selectedInteriorColor,
        selectedExteriorColor: searchParamInfo.selectedExteriorColor,
        selectedVehicleGrade: searchParamInfo.selectedVehicleGrade,
        selectedStyle: searchParamInfo.selectedStyle,
        selectedRegion: searchParamInfo.selectedRegion,
        selectedDoor: searchParamInfo.selectedDoor,
        selectedDriveTrain: searchParamInfo.selectedDriveTrain,
        selectedYearFrom: searchParamInfo.selectedYearFrom,
        selectedYearTo: searchParamInfo.selectedYearTo,
        selectedDistance: searchParamInfo.selectedDistance,
        selectedPriceFrom: searchParamInfo.selectedPriceFrom,
        selectedPriceTo: searchParamInfo.selectedPriceTo,
        selectedOdometerFrom: searchParamInfo.selectedOdometerFrom,
        selectedOdometerTo: searchParamInfo.selectedOdometerTo,
        selectState: searchParamInfo.selectState,
        selectedTransmission: searchParamInfo.selectedTransmission,
        selectedEngine: searchParamInfo.selectedEngine,
        selectedOptions: searchParamInfo.selectedOptions,
        advancedSearchCriteriaWrapper: advancedSearchCriteriaWrapperConst,
        advancedSearchCriteria: advancedSearchCriteriaConst,
      });
    } else if (
      mobileSearchCriteria !== undefined &&
      mobileSearchCriteria !== null
    ) {
      this.setState({
        selectedMake: mobileSearchCriteria.selectedMake,
        selectedModel: mobileSearchCriteria.selectedModel,
        selectedModelNumber: mobileSearchCriteria.selectedModelNumber,
        selectedSeries: mobileSearchCriteria.selectedSeries,
        selectedInteriorColor: mobileSearchCriteria.selectedInteriorColor,
        selectedExteriorColor: mobileSearchCriteria.selectedExteriorColor,
        selectedVehicleGrade: mobileSearchCriteria.selectedVehicleGrade,
        selectedStyle: mobileSearchCriteria.selectedStyle,
        selectedRegion: mobileSearchCriteria.selectedRegion,
        selectedDoor: mobileSearchCriteria.selectedDoor,
        selectedDriveTrain: mobileSearchCriteria.selectedDriveTrain,
        selectedYearFrom: mobileSearchCriteria.selectedYearFrom,
        selectedYearTo: mobileSearchCriteria.selectedYearTo,
        selectedDistance: mobileSearchCriteria.selectedDistance,
        selectedPriceFrom: mobileSearchCriteria.selectedPriceFrom,
        selectedPriceTo: mobileSearchCriteria.selectedPriceTo,
        selectedOdometerFrom: mobileSearchCriteria.selectedOdometerFrom,
        selectedOdometerTo: mobileSearchCriteria.selectedOdometerTo,
        selectState: mobileSearchCriteria.selectState,
        selectedTransmission: mobileSearchCriteria.selectedTransmission,
        selectedEngine: mobileSearchCriteria.selectedEngine,
        selectedOptions: mobileSearchCriteria.selectedOptions,
        advancedSearchCriteriaWrapper: advancedSearchCriteriaWrapperConst,
        advancedSearchCriteria: advancedSearchCriteriaConst,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { searchParamInfo, sendQueryInfo, clearForm, toggleClearForm } =
      this.props;
    if (
      searchParamInfo &&
      searchParamInfo !== null &&
      prevProps.searchParamInfo !== searchParamInfo
    ) {
      this.setState({
        selectedMake: searchParamInfo.selectedMake
          ? searchParamInfo.selectedMake
          : searchParamInfo.make,
        selectedModel: searchParamInfo.selectedModel
          ? searchParamInfo.selectedModel
          : searchParamInfo.model,
        selectedModelNumber: searchParamInfo.selectedModelNumber
          ? searchParamInfo.selectedModelNumber
          : searchParamInfo.modelNumber,
        selectedSeries: searchParamInfo.selectedSeries
          ? searchParamInfo.selectedSeries
          : searchParamInfo.series,
        selectedInteriorColor: searchParamInfo.selectedInteriorColor
          ? searchParamInfo.selectedInteriorColor
          : searchParamInfo.interiorColor,
        selectedExteriorColor: searchParamInfo.selectedExteriorColor
          ? searchParamInfo.selectedExteriorColor
          : searchParamInfo.exteriorColor,
        selectedVehicleGrade: searchParamInfo.selectedVehicleGrade
          ? searchParamInfo.selectedVehicleGrade
          : searchParamInfo.vehicleGrade,
        selectedStyle: searchParamInfo.selectedStyle
          ? searchParamInfo.selectedStyle
          : searchParamInfo.bodyStyle,
        selectedRegion: searchParamInfo.selectedRegion
          ? searchParamInfo.selectedRegion
          : searchParamInfo.regions,
        selectedDoor: searchParamInfo.selectedDoor
          ? searchParamInfo.selectedDoor
          : searchParamInfo.doors,
        selectedDriveTrain: searchParamInfo.selectedDriveTrain
          ? searchParamInfo.selectedDriveTrain
          : searchParamInfo.driveTrain,
        selectedYearFrom: searchParamInfo.fromYear
          ? searchParamInfo.fromYear
          : this.state.selectedYearFrom,
        selectedYearTo: searchParamInfo.toYear
          ? searchParamInfo.toYear
          : this.state.selectedYearTo,
        selectedDistance: searchParamInfo.distanceFromUser
          ? searchParamInfo.distanceFromUser
          : this.state.selectedDistance,
        selectedPriceFrom:
          searchParamInfo.fromPrice !== null
            ? searchParamInfo.fromPrice
            : this.state.selectedPriceFrom,
        selectedPriceTo:
          searchParamInfo.toPrice !== null
            ? searchParamInfo.toPrice
            : this.state.selectedPriceTo,
        selectedOdometerFrom:
          searchParamInfo.fromOdometer !== null
            ? searchParamInfo.fromOdometer
            : this.state.selectedOdometerFrom,
        selectedOdometerTo:
          searchParamInfo.toOdometer !== null
            ? searchParamInfo.toOdometer
            : this.state.selectedOdometerTo,
        selectState:
          searchParamInfo.states !== null && searchParamInfo.states.length > 0
            ? searchParamInfo.states[0]
            : this.state.selectState,
        selectedTransmission: searchParamInfo.selectedTransmission
          ? searchParamInfo.selectedTransmission
          : searchParamInfo.transmission,
        selectedEngine: searchParamInfo.selectedEngine
          ? searchParamInfo.selectedEngine
          : searchParamInfo.engine,
        selectedOptions: searchParamInfo.selectedOptions
          ? searchParamInfo.selectedOptions
          : searchParamInfo.options,
      });
    }
    if (clearForm) {
      this.clearSearch();
      toggleClearForm();
    }
    sendQueryInfo(this.parseSelection());
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    dispatch(mobile_search_criteria(this.state));
  };

  range = (start, end) => {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  };

  parseSearchCriteria = (
    advancedSearchCriteriaWrapper,
    advancedSearchCriteria
  ) => {
    const { stateInfo } = this.props;
    const criteriaWrapperData =
      advancedSearchCriteriaWrapper !== undefined
        ? advancedSearchCriteriaWrapper
        : this.props.advancedSearchCriteriaWrapperData;
    const criteriaData =
      advancedSearchCriteria !== undefined
        ? advancedSearchCriteria
        : this.props.advancedSearchCriteriaData;
    let interiorColorCount = 0,
      exteriorColorCount = 0,
      vehicleGradeCount = 0,
      yearCount = 0,
      makeCount = 0,
      modelCount = 0,
      modelNumberCount = 0,
      styleCount = 0,
      transmissionCount = 0,
      engineCount = 0,
      optionCount = 0;
    let allInteriorColor = [],
      allExteriorColor = [],
      allVehicleGradeArray = [],
      year = [],
      makeArray = [],
      modelArray = [],
      allModelNumberArray = [],
      allSeriesNameArray = [],
      allStyleArray = [],
      allTransmission = [],
      allEngine = [],
      allOption = [];
    // Interior Color
    for (let [, value] of Object.entries(criteriaData["Interior Color"])) {
      allInteriorColor[interiorColorCount] = value.criteriaValue;
      interiorColorCount++;
    }
    let interiorColor = [...new Set(allInteriorColor.map((item) => item))];
    // Exterior Color
    for (let [, value] of Object.entries(criteriaData["Exterior Color"])) {
      allExteriorColor[exteriorColorCount] = value.criteriaValue;
      exteriorColorCount++;
    }
    let exteriorColor = [...new Set(allExteriorColor.map((item) => item))];
    // Vehicle Grade
    for (let [, value] of Object.entries(criteriaData["Vehicle Grade"])) {
      allVehicleGradeArray[vehicleGradeCount] = {
        id: value.id,
        grade: value.criteriaValue,
      };
      vehicleGradeCount++;
    }
    let vehicleGradeArray = [
      ...new Set(allVehicleGradeArray.map((item) => item.grade)),
    ];
    // Year
    for (let [, value] of Object.entries(criteriaData["Year"])) {
      year[yearCount] = value.id;
      yearCount++;
    }
    let yearRange = this.range(
      Math.min.apply(null, year),
      Math.max.apply(null, year)
    ).reverse();
    let yearRangeString = [
      ...new Set(yearRange.map((item) => item.toString())),
    ];
    // Make Model Series
    for (let [key] of Object.entries(
      criteriaWrapperData["typeMakeModelSeriesModelNumberMap"]
    )) {
      makeArray[makeCount] = key;
      makeCount++;
      for (let [key1] of Object.entries(
        criteriaWrapperData["typeMakeModelSeriesModelNumberMap"][key]
      )) {
        modelArray[modelCount] = key1;
        modelCount++;
        for (let [, value] of Object.entries(
          criteriaWrapperData["typeMakeModelSeriesModelNumberMap"][key][key1]
        )) {
          allModelNumberArray[modelNumberCount] = value.modelNumber;
          allSeriesNameArray[modelNumberCount] = value.seriesName;
          modelNumberCount++;
        }
      }
    }
    let modelNumberArray = [
      ...new Set(allModelNumberArray.map((item) => item)),
    ];
    let seriesNameArray = [...new Set(allSeriesNameArray.map((item) => item))];
    makeArray.push("All");
    modelArray.push("All");
    modelNumberArray.push("All");
    seriesNameArray.push("All");
    // Style
    for (let [, value] of Object.entries(criteriaWrapperData["styles"])) {
      allStyleArray[styleCount] = value.style;
      styleCount++;
    }
    let styleArray = [...new Set(allStyleArray.map((item) => item))];
    styleArray.push("All");
    // State
    let stateInfoArray = [...new Set(stateInfo.map((item) => item.name))];
    // Transmission
    for (let [, value] of Object.entries(
      criteriaData["Vehicle Transmission"]
    )) {
      allTransmission[transmissionCount] = value.criteriaValue;
      transmissionCount++;
    }
    let transmission = [...new Set(allTransmission.map((item) => item))];
    // Engine
    for (let [, value] of Object.entries(criteriaData["Engine Type"])) {
      allEngine[engineCount] = value.criteriaValue;
      engineCount++;
    }
    let engine = [...new Set(allEngine.map((item) => item))];
    // Options
    for (let [, value] of Object.entries(criteriaData["Vehicle Options"])) {
      allOption[optionCount] = value.displayCode;
      optionCount++;
    }
    let option = [...new Set(allOption.map((item) => item))];
    this.setState({
      make: makeArray.sort(),
      model: modelArray.sort(),
      modelNumber: modelNumberArray.sort(),
      series: seriesNameArray.sort(),
      interiorColor: interiorColor.sort(),
      exteriorColor: exteriorColor.sort(),
      vehicleGrade: vehicleGradeArray.sort(),
      style: styleArray.sort(),
      yearFrom: yearRangeString.sort((a, b) => {
        return parseInt(b) - parseInt(a);
      }),
      yearTo: yearRangeString.sort((a, b) => {
        return parseInt(b) - parseInt(a);
      }),
      states: stateInfoArray.sort(),
      transmission: transmission.sort(),
      engine: engine.sort(),
      options: option.sort(),
      selectState: [],
      pageLoading: false,
    });
  };

  makeSelectionUpdated = (makeValue) => {
    const { advancedSearchCriteriaWrapperData } = this.props;
    let makeCount = 0,
      modelCount = 0,
      modelNumberCount = 0;
    let makeArray = [],
      modelArray = [],
      allModelNumberArray = [],
      allSeriesNameArray = [];
    if (makeValue.includes("All") || makeValue.length === 0) {
      for (let [keyA] of Object.entries(
        advancedSearchCriteriaWrapperData["typeMakeModelSeriesModelNumberMap"]
      )) {
        makeArray[makeCount] = keyA;
        makeCount++;
        for (let [keyB] of Object.entries(
          advancedSearchCriteriaWrapperData[
            "typeMakeModelSeriesModelNumberMap"
          ][keyA]
        )) {
          modelArray[modelCount] = keyB;
          modelCount++;
          for (let [, value] of Object.entries(
            advancedSearchCriteriaWrapperData[
              "typeMakeModelSeriesModelNumberMap"
            ][keyA][keyB]
          )) {
            allModelNumberArray[modelNumberCount] = value.modelNumber;
            allSeriesNameArray[modelNumberCount] = value.seriesName;
            modelNumberCount++;
          }
        }
      }
      makeArray.push("All");
    } else {
      for (let [key, value] of Object.entries(makeValue)) {
        makeArray[makeCount] = key;
        makeCount++;
        for (let [key1] of Object.entries(
          advancedSearchCriteriaWrapperData[
            "typeMakeModelSeriesModelNumberMap"
          ][value]
        )) {
          modelArray[modelCount] = key1;
          modelCount++;
          for (let [, value2] of Object.entries(
            advancedSearchCriteriaWrapperData[
              "typeMakeModelSeriesModelNumberMap"
            ][value][key1]
          )) {
            allModelNumberArray[modelNumberCount] = value2.modelNumber;
            allSeriesNameArray[modelNumberCount] = value2.seriesName;
            modelNumberCount++;
          }
        }
      }
    }
    let modelNumberArray = [
      ...new Set(allModelNumberArray.map((item) => item)),
    ];
    let seriesNameArray = [...new Set(allSeriesNameArray.map((item) => item))];
    modelArray.push("All");
    modelNumberArray.push("All");
    seriesNameArray.push("All");
    this.setState({
      model: modelArray.sort(),
      modelNumber: modelNumberArray.sort(),
      series: seriesNameArray.sort(),
    });
  };

  modelSelectionUpdated = (modelValue) => {
    const { advancedSearchCriteriaWrapperData } = this.props;
    let makeCount = 0,
      modelCount = 0,
      modelNumberCount = 0;
    let makeArray = [],
      modelArray = [],
      allModelNumberArray = [],
      allSeriesNameArray = [];
    if (modelValue.includes("All") || modelValue.length === 0) {
      for (let [keyA] of Object.entries(
        advancedSearchCriteriaWrapperData["typeMakeModelSeriesModelNumberMap"]
      )) {
        makeArray[makeCount] = keyA;
        makeCount++;
        for (let [keyB] of Object.entries(
          advancedSearchCriteriaWrapperData[
            "typeMakeModelSeriesModelNumberMap"
          ][keyA]
        )) {
          modelArray[modelCount] = keyB;
          modelCount++;
          for (let [, value] of Object.entries(
            advancedSearchCriteriaWrapperData[
              "typeMakeModelSeriesModelNumberMap"
            ][keyA][keyB]
          )) {
            allModelNumberArray[modelNumberCount] = value.modelNumber;
            allSeriesNameArray[modelNumberCount] = value.seriesName;
            modelNumberCount++;
          }
        }
      }
      makeArray.push("All");
    } else {
      for (let [keyA] of Object.entries(
        advancedSearchCriteriaWrapperData["typeMakeModelSeriesModelNumberMap"]
      )) {
        makeArray[makeCount] = keyA;
        makeCount++;
        for (let [keyB] of Object.entries(
          advancedSearchCriteriaWrapperData[
            "typeMakeModelSeriesModelNumberMap"
          ][keyA]
        )) {
          modelArray[modelCount] = keyB;
          modelCount++;
          for (let [, valueD] of Object.entries(modelValue)) {
            for (let [, value] of Object.entries(
              advancedSearchCriteriaWrapperData[
                "typeMakeModelSeriesModelNumberMap"
              ][keyA][keyB]
            )) {
              if (keyB === valueD) {
                allModelNumberArray[modelNumberCount] = value.modelNumber;
                allSeriesNameArray[modelNumberCount] = value.seriesName;
                modelNumberCount++;
              }
            }
          }
        }
      }
    }
    let modelNumberArray = [
      ...new Set(allModelNumberArray.map((item) => item)),
    ];
    let seriesNameArray = [...new Set(allSeriesNameArray.map((item) => item))];
    modelArray.push("All");
    modelNumberArray.push("All");
    seriesNameArray.push("All");
    this.setState({
      modelNumber: modelNumberArray.sort(),
      series: seriesNameArray.sort(),
    });
  };

  renderSelectComponentWithTextValue = (
    id,
    value,
    changeValue,
    paramValue,
    label
  ) => {
    const { classes } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <label className={classes.fieldLabel}>
          <strong>{label}</strong>
        </label>
        <Select
          id={id}
          className={classes.selectInput}
          value={value}
          onChange={this.onChange(changeValue)}
          name={id}
          displayEmpty
        >
          {paramValue.map((param) => (
            <MenuItem key={param.value} value={param.value}>
              {param.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  renderSelectComponentSimple = (id, value, changeValue, paramValue, label) => {
    const { classes } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <label className={classes.fieldLabel}>
          <strong>{label}</strong>
        </label>
        <Select
          id={id}
          className={classes.selectInput}
          value={value}
          onChange={this.onChange(changeValue)}
          name={id}
          displayEmpty
        >
          <MenuItem key={"All"} value={"All"}>
            All
          </MenuItem>
          {paramValue.map((parma) => (
            <MenuItem key={parma} value={parma}>
              {parma}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  renderSelectComponent = (
    id,
    value,
    changeValue,
    paramValue,
    summary,
    defaultOption
  ) => {
    const { classes } = this.props;
    const { selectState } = this.state;
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.heading}>
            {summary}
            {(value.length > 0 && id === "State") ||
            (value !== defaultOption && id !== "State") ? (
              <div className={classes.chipSection}>
                Search within :
                {id === "State" ? (
                  value.map((state) => {
                    return (
                      <Chip
                        variant="outlined"
                        key={state}
                        onDelete={() =>
                          this.handleSelectChipDelete(
                            changeValue,
                            selectState.filter((keepState) => {
                              return keepState !== state;
                            })
                          )
                        }
                        label={state}
                        value={state}
                      />
                    );
                  })
                ) : (
                  <Chip
                    variant="outlined"
                    key={value}
                    onDelete={() =>
                      this.handleSelectChipDelete(changeValue, defaultOption)
                    }
                    label={value}
                    value={value}
                  />
                )}
              </div>
            ) : null}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.selectDivStyle}>
          <FormControl className={classes.formControl}>
            <label className={classes.fieldLabelDistance}>
              <strong>Search within: </strong>
            </label>
            <Select
              id={id}
              className={classes.selectInput}
              value={value}
              onChange={this.onChange(changeValue)}
              name={id}
              displayEmpty
              multiple={id === "State"}
            >
              <MenuItem key={defaultOption} value={defaultOption}>
                {defaultOption}
              </MenuItem>
              {paramValue.map((parma) => (
                <MenuItem key={parma} value={parma}>
                  {parma}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  renderCheckboxOptions = (summary, criteria, stateKey) => {
    const { classes } = this.props;
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.heading}>
            {summary}
            <div className={classes.chipSection}>
              {this.state[stateKey] &&
                this.state[stateKey].map((option) => {
                  return (
                    <Chip
                      variant="outlined"
                      key={option}
                      onDelete={() => this.handleChipDelete(stateKey, option)}
                      label={
                        summary === "Vehicle Grade"
                          ? this.chipLabelForVehicleGrade(option)
                          : option
                      }
                      value={option}
                    />
                  );
                })}
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <SearchCheckboxOptions
            criteria={criteria}
            stateKey={stateKey}
            isChecked={this.isChecked}
            handleCheck={this.handleCheck}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  chipLabelForVehicleGrade = (option) => {
    return option === "5" ? "5" : option + "-" + (parseInt(option) + 0.99);
  };

  handleChipDelete = (stateKey, option) => {
    this.setState({
      [stateKey]: this.state[stateKey].filter((value) => value !== option),
    });
    if (stateKey === "selectedMake") {
      this.makeSelectionUpdated(
        this.state[stateKey].filter((value) => value !== option)
      );
    }
    if (stateKey === "selectedModel") {
      this.modelSelectionUpdated(
        this.state[stateKey].filter((value) => value !== option)
      );
    }
  };

  toggleLoading = () => {
    this.setState({
      pageLoading: !this.state.pageLoading,
    });
  };

  onChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    if ([name][0] === "selectedOdometerFrom") {
      this.updateTo(
        odometerToOption,
        event.target.value,
        "odometerTo",
        odometerToOption.length
      );
    }
    if ([name][0] === "selectedOdometerTo") {
      this.updateFrom(odometerFromOption, event.target.value, "odometerFrom");
    }
    if ([name][0] === "selectedPriceFrom") {
      this.updateTo(
        priceToOption,
        event.target.value,
        "priceTo",
        priceToOption.length
      );
    }
    if ([name][0] === "selectedPriceTo") {
      this.updateFrom(priceFromOption, event.target.value, "priceFrom");
    }
    if ([name][0] === "selectedYearFrom") {
      if (event.target.value !== "All") {
        let fromUpdate = [];
        for (let i = 0; i < this.state.yearFrom.length; i++) {
          if (
            parseInt(event.target.value) <= parseInt(this.state.yearFrom[i])
          ) {
            fromUpdate.push(this.state.yearFrom[i]);
          }
        }
        this.setState({
          yearTo: fromUpdate,
          selectedYearTo:
            this.state.selectedYearTo < event.target.value
              ? "All"
              : this.state.selectedYearTo,
        });
      } else {
        this.setState({
          yearTo: this.state.yearFrom,
        });
      }
    }
  };

  updateFrom = (constant, value, state) => {
    let fromUpdate = [];
    fromUpdate.push(constant[0]);
    for (let i = 1; i < constant.length; i++) {
      if (parseInt(value) >= parseInt(constant[i].value)) {
        fromUpdate.push(constant[i]);
      }
    }
    this.setState({
      [state]: fromUpdate,
    });
  };

  updateTo = (constant, value, state, length) => {
    let toUpdate = [];
    for (let i = 0; i < constant.length - 1; i++) {
      if (parseInt(value) <= parseInt(constant[i].value)) {
        toUpdate.push(constant[i]);
      }
    }
    toUpdate.push(constant[length - 1]);
    this.setState({
      [state]: toUpdate,
    });
  };

  isChecked = (key, value) => {
    return this.state[key] && this.state[key].includes(value);
  };

  handleCheck = (name) => (evt) => {
    if (this.state[name].includes(evt.target.value)) {
      this.setState({
        [name]: this.state[name].filter((value) => value !== evt.target.value),
      });
      if (name === "selectedMake") {
        this.makeSelectionUpdated(
          this.state[name].filter((value) => value !== evt.target.value)
        );
      }
      if (name === "selectedModel") {
        this.modelSelectionUpdated(
          this.state[name].filter((value) => value !== evt.target.value)
        );
      }
    } else {
      this.setState({
        [name]: [...this.state[name], evt.target.value],
      });
      if (name === "selectedMake") {
        this.makeSelectionUpdated([...this.state[name], evt.target.value]);
      }
      if (name === "selectedModel") {
        this.modelSelectionUpdated([...this.state[name], evt.target.value]);
      }
    }
  };

  advanceSearch = async () => {
    const { user, updateCarList, toggleLoading, dispatch, toggleDrawer } =
      this.props;
    const stateValue = this.state;
    let query = this.parseSelection();
    toggleLoading();
    const response = await AdvanceSearchServices.advanceSearch(query, user);
    dispatch(advance_search_criteria(query));
    dispatch(advance_search_info(stateValue));
    toggleLoading();
    toggleDrawer();
    updateCarList(response, stateValue, response.length);
  };

  parseSelection = () => {
    const {
      make,
      selectedMake,
      model,
      selectedModel,
      modelNumber,
      selectedModelNumber,
      series,
      selectedSeries,
      selectedInteriorColor,
      selectedExteriorColor,
      selectedVehicleGrade,
      style,
      selectedStyle,
      selectedYearFrom,
      selectedYearTo,
      selectedDistance,
      selectedDoor,
      selectedDriveTrain,
      selectedPriceFrom,
      selectedPriceTo,
      selectedOdometerFrom,
      selectedOdometerTo,
      region,
      selectedRegion,
      selectState,
      selectedEngine,
      selectedOptions,
      selectedTransmission,
    } = this.state;

    return {
      // Make
      make:
        selectedMake !== [] &&
        selectedMake !== undefined &&
        selectedMake.length > 0
          ? this.buildCriteriaAttributeInfo(
              "make",
              selectedMake.includes("All")
                ? make.filter((value) => value !== "All")
                : selectedMake
            )
          : null,
      // Model
      model:
        selectedModel !== [] &&
        selectedModel !== undefined &&
        selectedModel.length > 0
          ? this.buildCriteriaAttributeInfo(
              "model",
              selectedModel.includes("All")
                ? model.filter((value) => value !== "All")
                : selectedModel
            )
          : null,
      // ModelNumber
      modelNumber:
        selectedModelNumber !== [] &&
        selectedModelNumber !== undefined &&
        selectedModelNumber.length > 0
          ? this.buildCriteriaAttributeInfo(
              "modeNumber",
              selectedModelNumber.includes("All")
                ? modelNumber.filter((value) => value !== "All")
                : selectedModelNumber
            )
          : null,
      // Style
      bodyStyle:
        selectedStyle !== [] &&
        selectedStyle !== undefined &&
        selectedStyle.length > 0
          ? this.buildCriteriaAttributeInfo(
              "bodyStyle",
              selectedStyle.includes("All")
                ? style.filter((value) => value !== "All")
                : selectedStyle
            )
          : null,
      // Vehicle Grade
      vehicleGrade:
        selectedVehicleGrade !== [] &&
        selectedVehicleGrade !== undefined &&
        selectedVehicleGrade.length > 0
          ? this.buildCriteriaAttributeInfo(
              "vehicleGrade",
              selectedVehicleGrade
            )
          : null,
      // Exterior Color
      exteriorColor:
        selectedExteriorColor !== [] &&
        selectedExteriorColor !== undefined &&
        selectedExteriorColor.length > 0
          ? this.buildCriteriaAttributeInfo(
              "exteriorColor",
              selectedExteriorColor
            )
          : null,
      // Interior Color
      interiorColor:
        selectedInteriorColor !== [] &&
        selectedInteriorColor !== undefined &&
        selectedInteriorColor.length > 0
          ? this.buildCriteriaAttributeInfo(
              "interiorColor",
              selectedInteriorColor
            )
          : null,
      // Door
      doors:
        selectedDoor !== [] &&
        selectedDoor !== undefined &&
        selectedDoor.length > 0
          ? this.buildCriteriaAttributeInfo("doors", selectedDoor)
          : null,
      // Drive Train
      driveTrain:
        selectedDriveTrain !== [] &&
        selectedDriveTrain !== undefined &&
        selectedDriveTrain.length > 0
          ? this.buildCriteriaAttributeInfo("driveTrain", selectedDriveTrain)
          : null,
      // Region
      region:
        selectedRegion !== [] &&
        selectedRegion !== undefined &&
        selectedRegion.length > 0
          ? this.buildCriteriaAttributeInfo(
              "region",
              selectedRegion.includes("All")
                ? region.filter((value) => value !== "All")
                : selectedRegion
            )
          : null,
      // Series
      series:
        selectedSeries !== [] &&
        selectedSeries !== undefined &&
        selectedSeries.length > 0
          ? this.buildCriteriaAttributeInfo(
              "series",
              selectedSeries.includes("All")
                ? series.filter((value) => value !== "All")
                : selectedSeries
            )
          : null,
      // Year From
      fromYear:
        selectedYearFrom !== [] &&
        selectedYearFrom !== undefined &&
        selectedYearFrom.includes("All")
          ? null
          : this.buildCriteriaAttributeInfo("fromYear", [selectedYearFrom]),
      // Year To
      toYear:
        selectedYearTo !== [] &&
        selectedYearTo !== undefined &&
        selectedYearTo.includes("All")
          ? null
          : this.buildCriteriaAttributeInfo("toYear", [selectedYearTo]),
      // State
      state:
        selectState !== undefined && selectState.length !== 0
          ? this.buildCriteriaAttributeInfo("state", [...selectState])
          : null,
      // Odometer
      odometerRange: [
        this.buildValueRange(selectedOdometerFrom, selectedOdometerTo, true),
      ],
      // Price Range
      priceRange: [
        this.buildValueRange(selectedPriceFrom, selectedPriceTo, true),
      ],
      // Selected Distance
      distanceFromUser:
        selectedDistance !== [] &&
        selectedDistance !== undefined &&
        selectedDistance !== "All"
          ? selectedDistance === "Over 2000"
            ? "> 2000"
            : selectedDistance
          : null,
      // Engine
      engine:
        selectedEngine !== [] &&
        selectedEngine !== undefined &&
        selectedEngine.length > 0
          ? this.buildCriteriaAttributeInfo("engine", selectedEngine)
          : null,
      // Options
      options:
        selectedOptions !== [] &&
        selectedOptions !== undefined &&
        selectedOptions.length > 0
          ? this.buildCriteriaAttributeInfo("options", selectedOptions)
          : null,
      // Transmission
      transmission:
        selectedTransmission !== [] &&
        selectedTransmission !== undefined &&
        selectedTransmission.length > 0
          ? this.buildCriteriaAttributeInfo(
              "transmission",
              selectedTransmission
            )
          : null,
    };
  };

  buildCriteriaAttributeInfo = (name, keys) => {
    return {
      attributeName: name,
      isSorted: null,
      isSortDescending: null,
      sortPriority: 0,
      items: this.buildItemArray(keys),
      isSynonym: null,
      keys: keys,
    };
  };

  buildValueRange = (fromValue, toValue, isInclude) => {
    return {
      fromValue: fromValue,
      toValue: toValue,
      isInclude: isInclude,
      actualFromValue: null,
      actualToValue: null,
    };
  };

  buildItemArray = (keys) => {
    let array = {};
    keys.forEach((key) => {
      array[key] = true;
    });
    return array;
  };

  toggleAutoSearchSaveTrigger = () => {
    const { toggleAutoSearchSave } = this.props;
    let query = this.parseSelection();
    toggleAutoSearchSave(query);
  };

  toggleSearchSaveButton = () => {
    const {
      selectedMake,
      selectedModel,
      selectedModelNumber,
      selectedSeries,
      selectedInteriorColor,
      selectedExteriorColor,
      selectedVehicleGrade,
      selectedStyle,
      selectedYearFrom,
      selectedYearTo,
      selectedDistance,
      selectedDoor,
      selectedDriveTrain,
      selectedPriceFrom,
      selectedPriceTo,
      selectedOdometerFrom,
      selectedOdometerTo,
      selectedRegion,
      selectState,
      selectedEngine,
      selectedOptions,
      selectedTransmission,
    } = this.state;
    if (
      selectedMake &&
      selectedMake.length === 0 &&
      selectedModel &&
      selectedModel.length === 0 &&
      selectedModelNumber &&
      selectedModelNumber.length === 0 &&
      selectedSeries &&
      selectedSeries.length === 0 &&
      selectedInteriorColor &&
      selectedInteriorColor.length === 0 &&
      selectedExteriorColor &&
      selectedExteriorColor.length === 0 &&
      selectedVehicleGrade &&
      selectedVehicleGrade.length === 0 &&
      selectedVehicleGrade &&
      selectedStyle.length === 0 &&
      selectedYearFrom &&
      selectedYearFrom === "All" &&
      selectedYearTo &&
      selectedYearTo === "All" &&
      selectedDistance &&
      selectedDistance === "All" &&
      selectedDoor &&
      selectedDoor.length === 0 &&
      selectedDriveTrain &&
      selectedDriveTrain.length === 0 &&
      selectedPriceFrom &&
      selectedPriceFrom === "0" &&
      selectedPriceTo &&
      selectedPriceTo === "+100000" &&
      selectedOdometerFrom &&
      selectedOdometerFrom === "0" &&
      selectedOdometerTo &&
      selectedOdometerTo === "200000+" &&
      selectedRegion &&
      selectedRegion.length === 0 &&
      selectState &&
      selectState.length === 0 &&
      selectedEngine &&
      selectedEngine.length === 0 &&
      selectedOptions &&
      selectedOptions.length === 0 &&
      selectedTransmission &&
      selectedTransmission.length === 0
    ) {
      return true;
    }
    return false;
  };

  handleSelectChipDelete = (option, value) => {
    this.setState({
      [option]: value,
    });
  };

  render() {
    const {
      make,
      model,
      modelNumber,
      series,
      interiorColor,
      exteriorColor,
      vehicleGrade,
      style,
      yearFrom,
      selectedYearFrom,
      yearTo,
      selectedYearTo,
      distance,
      selectedDistance,
      door,
      selectedDoor,
      driveTrain,
      priceFrom,
      selectedPriceFrom,
      priceTo,
      selectedPriceTo,
      odometerFrom,
      selectedOdometerFrom,
      odometerTo,
      selectedOdometerTo,
      region,
      states,
      selectState,
      pageLoading,
      transmission,
      engine,
      options,
    } = this.state;
    const { classes, toggleAutoSearch, totalCount } = this.props;
    return (
      <React.Fragment>
        {pageLoading ? (
          <Loading />
        ) : (
          <div className={classes.criteriaWrapper}>
            <div className={classes.iconArrange}>
              <div className={classes.count}>
                <Typography className={classes.countStyle}>
                  {" "}
                  <strong>{totalCount}</strong> Matches{" "}
                </Typography>
              </div>
              <Card
                id="autoSearchCard"
                title={"Show Saved Searches"}
                className={classes.buttonSearch}
              >
                <ReactSVG
                  id="autoSearch"
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={Setting}
                  alt="autoSearch"
                  onClick={toggleAutoSearch}
                />
              </Card>
              <Card id="saveSearchCard" className={classes.buttonSearch}>
                <ReactSVG
                  id="saveSearch"
                  svgStyle={
                    this.toggleSearchSaveButton()
                      ? { fill: "#000000", marginTop: ".4rem" }
                      : { fill: "white", marginTop: ".4rem" }
                  }
                  src={Save}
                  alt="conditionReportIcon"
                  onClick={
                    this.toggleSearchSaveButton()
                      ? null
                      : () => this.toggleAutoSearchSaveTrigger()
                  }
                  disabled={this.toggleSearchSaveButton()}
                />
              </Card>
            </div>
            <div className={classes.scrollBar}>
              {this.renderCheckboxOptions(
                "Body Style/Type",
                style,
                "selectedStyle",
                this.isChecked
              )}
              {this.renderSelectComponent(
                "distance",
                selectedDistance,
                "selectedDistance",
                distance,
                "Distance",
                "All"
              )}
              {this.renderCheckboxOptions(
                "Doors",
                door,
                "selectedDoor",
                this.isChecked,
                selectedDoor
              )}
              {this.renderCheckboxOptions(
                "Drive Train",
                driveTrain,
                "selectedDriveTrain",
                this.isChecked
              )}
              {this.renderCheckboxOptions(
                "Engine",
                engine,
                "selectedEngine",
                this.isChecked
              )}
              {this.renderCheckboxOptions(
                "Exterior Color",
                exteriorColor,
                "selectedExteriorColor",
                this.isChecked
              )}
              {this.renderCheckboxOptions(
                "Interior Color",
                interiorColor,
                "selectedInteriorColor",
                this.isChecked
              )}
              {this.renderCheckboxOptions(
                "Make",
                make,
                "selectedMake",
                this.isChecked
              )}
              {this.renderCheckboxOptions(
                "Model",
                model,
                "selectedModel",
                this.isChecked
              )}
              {this.renderCheckboxOptions(
                "Model #",
                modelNumber,
                "selectedModelNumber",
                this.isChecked
              )}
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div className={classes.heading}>
                    Odometer
                    {selectedOdometerFrom !== "0" ? (
                      <div className={classes.chipSection}>
                        {" "}
                        From :{" "}
                        <Chip
                          variant="outlined"
                          key={selectedOdometerFrom}
                          onDelete={() =>
                            this.handleSelectChipDelete(
                              "selectedOdometerFrom",
                              "0"
                            )
                          }
                          label={selectedOdometerFrom}
                          value={selectedOdometerFrom}
                        />{" "}
                      </div>
                    ) : null}
                    {selectedOdometerTo !== "200000+" ? (
                      <div className={classes.chipSection}>
                        {" "}
                        To :{" "}
                        <Chip
                          variant="outlined"
                          key={selectedOdometerTo}
                          onDelete={() =>
                            this.handleSelectChipDelete(
                              "selectedOdometerTo",
                              "200000+"
                            )
                          }
                          label={selectedOdometerTo}
                          value={selectedOdometerTo}
                        />{" "}
                      </div>
                    ) : null}
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.selectDivStyle}>
                  {this.renderSelectComponentWithTextValue(
                    "odometerFrom",
                    selectedOdometerFrom,
                    "selectedOdometerFrom",
                    odometerFrom,
                    "From:"
                  )}
                  {this.renderSelectComponentWithTextValue(
                    "odometerTo",
                    selectedOdometerTo,
                    "selectedOdometerTo",
                    odometerTo,
                    "To:"
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {this.renderCheckboxOptions(
                "Options",
                options,
                "selectedOptions",
                this.isChecked
              )}
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div className={classes.heading}>
                    Price
                    {selectedPriceFrom !== "0" ? (
                      <div className={classes.chipSection}>
                        {" "}
                        From :{" "}
                        <Chip
                          variant="outlined"
                          key={selectedPriceFrom}
                          onDelete={() =>
                            this.handleSelectChipDelete(
                              "selectedPriceFrom",
                              "0"
                            )
                          }
                          label={selectedPriceFrom}
                          value={selectedPriceFrom}
                        />{" "}
                      </div>
                    ) : null}
                    {selectedPriceTo !== "+100000" ? (
                      <div className={classes.chipSection}>
                        {" "}
                        To :{" "}
                        <Chip
                          variant="outlined"
                          key={selectedPriceTo}
                          onDelete={() =>
                            this.handleSelectChipDelete(
                              "selectedPriceTo",
                              "+100000"
                            )
                          }
                          label={selectedPriceTo}
                          value={selectedPriceTo}
                        />{" "}
                      </div>
                    ) : null}
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.selectDivStyle}>
                  {this.renderSelectComponentWithTextValue(
                    "priceFrom",
                    selectedPriceFrom,
                    "selectedPriceFrom",
                    priceFrom,
                    "From:"
                  )}
                  {this.renderSelectComponentWithTextValue(
                    "priceTo",
                    selectedPriceTo,
                    "selectedPriceTo",
                    priceTo,
                    "To:"
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {this.renderCheckboxOptions(
                "Region",
                region,
                "selectedRegion",
                this.isChecked
              )}
              {this.renderCheckboxOptions(
                "Series",
                series,
                "selectedSeries",
                this.isChecked
              )}
              {this.renderSelectComponent(
                "State",
                selectState,
                "selectState",
                states,
                "State",
                "Select"
              )}
              {this.renderCheckboxOptions(
                "Transmission",
                transmission,
                "selectedTransmission",
                this.isChecked
              )}
              {this.renderCheckboxOptions(
                "Vehicle Grade",
                vehicleGrade,
                "selectedVehicleGrade",
                this.isChecked
              )}
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div className={classes.heading}>
                    Year
                    {selectedYearFrom !== "All" ? (
                      <div className={classes.chipSection}>
                        {" "}
                        From :{" "}
                        <Chip
                          variant="outlined"
                          key={selectedYearFrom}
                          onDelete={() =>
                            this.handleSelectChipDelete(
                              "selectedYearFrom",
                              "All"
                            )
                          }
                          label={selectedYearFrom}
                          value={selectedYearFrom}
                        />{" "}
                      </div>
                    ) : null}
                    {selectedYearTo !== "All" ? (
                      <div className={classes.chipSection}>
                        {" "}
                        To :{" "}
                        <Chip
                          variant="outlined"
                          key={selectedYearTo}
                          onDelete={() =>
                            this.handleSelectChipDelete("selectedYearTo", "All")
                          }
                          label={selectedYearTo}
                          value={selectedYearTo}
                        />{" "}
                      </div>
                    ) : null}
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.selectDivStyle}>
                  {this.renderSelectComponentSimple(
                    "yearFrom",
                    selectedYearFrom,
                    "selectedYearFrom",
                    yearFrom,
                    "From:"
                  )}
                  {this.renderSelectComponentSimple(
                    "yearTo",
                    selectedYearTo,
                    "selectedYearTo",
                    yearTo,
                    "To:"
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                id="advanceSearch"
                className={classes.button}
                onClick={(evt) => this.advanceSearch()}
                disabled={this.toggleSearchSaveButton()}
              >
                Search
              </Button>
              <Button
                id="clearSearch"
                className={classes.button}
                onClick={(evt) => this.clearSearch()}
                disabled={this.toggleSearchSaveButton()}
              >
                Clear Search
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    stateInfo: state.stateInfo,
    advancedSearchCriteriaWrapperData:
      state.ui.advancedSearchCriteriaWrapperData,
    advancedSearchCriteriaData: state.ui.advancedSearchCriteriaData,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(AdvanceSearchCriteria)
);
