import React, { Component } from "react";
import { connect } from "react-redux";
import Viewer from "react-viewer";
import "react-viewer/dist/index.css";

import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  Button,
  Link,
  Typography,
  CardContent,
  CardActions,
} from "@material-ui/core";
import CarReport from "../components/CarReport";
import Notes from "../components/Notes";
import TransportationQuote from "../components/TransportationQuote";
import BidHistory from "../components/BidHistory";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import * as NotesServices from "../services/NotesServices";
import { returnAccountType } from "../services/HelperServices";

import ReactSVG from "react-svg";
import groundNavIcon from "../assets/groundNavIcon.svg";
import notesIcon from "../assets/notesIcon.svg";
import bidIcon from "../assets/bidIcon.svg";

const styles = (theme) => ({
  card: {
    display: "flex",
    marginTop: ".5rem",
    borderRadius: 0,
    height: "7rem",
    width: "100%",
    position: "relative",
  },
  cardWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "left",
    flexDirection: "column",
    width: "30%",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "50%",
    },
  },
  cardTitle: {
    fontSize: "61%",
    position: "absolute",
    fontWeight: "bold",
    marginLeft: "2%",
  },
  media: {
    minWidth: "8rem",
    width: "100%",
    height: "7rem",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      minWidth: 0,
      height: "5rem",
      width: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
    },
  },
  clickableMedia: {
    minWidth: "8rem",
    width: "100%",
    height: "7rem",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {
      margin: 0,
      minWidth: 0,
      height: "5rem",
      width: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: ".5rem",
  },
  contentWrapper: {
    display: "flex",
    position: "relative",
    cursor: "pointer",
    width: "80%",
  },
  textWrapper: {
    display: "flex",
    height: "85%",
    justifyContent: "space-around",
    position: "relative",
    top: "1rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "initial",
    },
  },
  textColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: "1%",
    height: "5rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
  },
  rightTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: "1%",
    height: "3rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
  },
  textContainer: {
    width: "100%",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignContent: "space-between",
    height: "9rem",
    justifyContent: "center",
    position: "relative",
  },
  imageFooter: {
    height: "1.5rem",
    width: "100%",
    zIndex: 2,
    backgroundColor: "rgba(82, 78, 78, .8)",
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  smallButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute",
    bottom: 0,
    right: ".5rem",
    height: "1.25rem",
    zIndex: 2,
  },
  smallButton: {
    height: "2.5rem",
    minWidth: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingLeft: ".5rem",
    },
  },
  bidHistory: {
    height: "2.5rem",
    minWidth: 0,
    zIndex: 2,
    position: "absolute",
    right: 0,
    top: "3.5rem",
  },
  transportButton: {
    height: "2.5rem",
    minWidth: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingLeft: ".5rem",
      position: "absolute",
      right: "1rem",
      bottom: "1rem",
    },
  },
  smallButtonLabel: {
    marginLeft: "2%",
    marginRight: "2%",
    justifyContent: "initial",
    fontSize: ".6rem",
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    minWidth: "13%",
    [theme.breakpoints.down("md")]: {
      minWidth: "20%",
    },
  },
  buyButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100%",
    margin: 0,
  },
  passButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.pass,
    width: "100%",
    margin: 0,
  },
  guaranteedPaymentButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.guaranteedPayment,
    width: "100%",
    margin: 0,
  },
  cardText: {
    fontSize: "76%",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("md")]: {
      fontSize: "48%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  cardTextWhite: {
    fontSize: "76%",
    color: theme.palette.primary.white,
    marginLeft: ".5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "48%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  cardTextRed: {
    fontSize: "76%",
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "48%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  boldedText: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
  whiteBoldedText: {
    fontWeight: "bold",
    color: theme.palette.primary.white,
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "inherit",
    },
  },
  redBoldedText: {
    fontWeight: "bold",
    color: theme.palette.primary.redHighlight,
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "inherit",
    },
  },
  statusCardText: {
    marginLeft: ".75rem",
    position: "relative",
    bottom: "2%",
    fontSize: "76%",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  LPWLink: {},
  hasNotes: {
    color: theme.palette.primary.redHighlight,
    fill: "currentColor",
  },
  noteIcon: {
    fill: theme.palette.grounding.main,
  },
  carInfoIconWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "17%",
    minWidth: "4rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carInfoIcon: {
    width: "4rem",
    "&:hover": {
      opacity: ".5",
    },
  },
  errorSnackBar: {
    marginTop: "7rem",
  },
  exclusivityTimer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, .6)",
    height: "1.5rem",
    color: theme.palette.primary.white,
    display: "flex",
    alignItems: "center",
    width: "27%",
    margin: 0,
    justifyContent: "space-around",
  },
  exclusiveText: {
    fontSize: theme.font.small,
    display: "flex",
  },
  autoBid: {
    display: "flex",
    width: "8rem",
    alignItems: "center",
  },
  chipRoot: {
    fontSize: theme.font.small,
    height: "1rem",
    width: "4.4rem",
    marginLeft: ".5rem",
  },
  deleteIcon: {
    height: "1rem",
    "&:hover": {
      fill: theme.palette.primary.redHighlight,
    },
  },
  losingBar: {
    width: "10px",
    height: "100%",
    backgroundColor: theme.palette.primary.redHighlight,
  },
  winningBar: {
    width: "10px",
    height: "100%",
    backgroundColor: theme.palette.DDO.main,
  },
  winningBid: {
    color: theme.palette.DDO.main,
    fontSize: "48%",
  },
  losingBid: {
    color: theme.palette.primary.redHighlight,
    fontSize: "48%",
  },
  "@global": {
    ".MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
  },
});

const awsUrl = process.env.REACT_APP_WCM_CONTENT;

export class WinLossCard extends Component {
  state = {
    viewerVisible: false,
    openNote: false,
    notes: [],
    vehicleHasNotes: false,
    openTransportationQuote: false,
    showCarfaxReport: false,
    showBidHistory: false,
    showCarfaxSnackBar: false,
  };

  componentWillMount = () => {
    const { isNoteAvailable } = this.props.car;
    this.setState({
      vehicleHasNotes: isNoteAvailable ? true : false,
    });
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: true,
    });
  };

  toggleNote = async () => {
    const { dealerId } = this.props.user;
    const { inventoryId } = this.props.car;
    const response = await NotesServices.getNotes(inventoryId, dealerId);
    this.setState({
      openNote: !this.state.openNote,
      notes: response,
      openTransportationQuote: false,
      showBidHistory: false,
    });
  };

  toggleTransportationQuote = () => {
    this.setState({
      openTransportationQuote: !this.state.openTransportationQuote,
      showBidHistory: false,
      openNote: false,
    });
  };

  addedNote = async () => {
    const { dealerId } = this.props.user;
    const { inventoryId } = this.props.car;
    const response = await NotesServices.getNotes(inventoryId, dealerId);
    this.setState({
      notes: response,
      vehicleHasNotes: true,
      openNote: false,
    });
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  toggleBidCount = () => {
    this.setState({
      showBidHistory: !this.state.showBidHistory,
      openTransportationQuote: false,
      openNote: false,
    });
  };

  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  render() {
    const {
      viewerVisible,
      openNote,
      notes,
      vehicleHasNotes,
      openTransportationQuote,
      showCarfaxReport,
      showBidHistory,
      showCarfaxSnackBar,
    } = this.state;
    const {
      classes,
      car,
      selectCar,
      selectedCarConditionReport,
      isWinCard,
      user,
    } = this.props;
    const {
      year,
      make,
      model,
      series,
      accountType,
      distance,
      myCurrentAutoBid,
      bidCount,
      isImageAvailable,
      currentBid,
      purchaseAmt,
      saleEventItemId,
      highBidder,
    } = car;
    return (
      <React.Fragment>
        {selectedCarConditionReport &&
        selectedCarConditionReport.inspectionImageDTO ? (
          <Viewer
            visible={viewerVisible}
            zoomSpeed={0.3}
            noClose={true}
            onMaskClick={(evt) => this.setState({ viewerVisible: false })}
            onClose={() => this.setState({ viewerVisible: false })}
            images={selectedCarConditionReport.inspectionImageDTO.map(
              (image) => {
                return { src: image.imageUrl, alt: image.damageImageCaption };
              }
            )}
          />
        ) : null}
        <Card
          className={classes.card}
          id={`MobileWinLossCard-${car.vin}`}
          onClick={() => selectCar(car)}
        >
          <CardActions className={classes.cardWrapper}>
            <div className={classes.imageWrapper}>
              <img
                id="primaryImage"
                src={
                  isImageAvailable
                    ? car.imagePathFc
                    : awsUrl + "images/toyota/help/logo/TFS_NoImage-logo.png"
                }
                className={classes.media}
                alt={`${car.make} ${car.model}`}
                onClick={() => this.toggleImageViewer()}
              />
              <div className={classes.imageFooter}>
                {isWinCard ? (
                  <Typography className={classes.cardTextWhite}>
                    <span className={classes.boldedText}>Purchase Price: </span>
                    <span className={classes.whiteBoldedText}>
                      {purchaseAmt &&
                        purchaseAmt.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </span>
                  </Typography>
                ) : (
                  <Typography className={classes.cardTextWhite}>
                    <span className={classes.boldedText}>Winning Bid: </span>
                    <span className={classes.whiteBoldedText}>
                      {currentBid &&
                        currentBid.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </span>
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.smallButtonWrapper}>
              <Button
                id="noteButton"
                className={classes.smallButton}
                onClick={(evt) => {
                  evt.stopPropagation();
                  this.toggleNote();
                }}
              >
                <ReactSVG
                  className={
                    vehicleHasNotes ? classes.hasNotes : classes.blankNotes
                  }
                  src={notesIcon}
                  svgStyle={{ width: "1.3rem" }}
                />
              </Button>
              <Button
                id="transportButton"
                className={classes.smallButton}
                onClick={(evt) => {
                  evt.stopPropagation();
                  this.toggleTransportationQuote();
                }}
              >
                <ReactSVG
                  className={classes.transportIcon}
                  src={groundNavIcon}
                  svgStyle={{ width: "1.3rem" }}
                />
              </Button>
            </div>
            {bidCount > 0 ? (
              <Button
                id="bidHistory"
                className={classes.bidHistory}
                onClick={(evt) => {
                  evt.stopPropagation();
                  this.toggleBidCount();
                }}
              >
                <ReactSVG
                  className={classes.transportIcon}
                  src={bidIcon}
                  svgStyle={{ width: "1.3rem" }}
                />
              </Button>
            ) : null}
          </CardActions>
          <div className={classes.contentWrapper}>
            <div style={{ width: "100%" }}>
              <Typography className={classes.cardTitle}>
                {`${year} ${make} ${model} `}
                {series ? series : null}
              </Typography>
              <div className={classes.textWrapper}>
                <div className={classes.textColumn}>
                  <Typography className={classes.cardText}>
                    Inspection Mileage:{" "}
                    <span className={classes.boldedText}>
                      {car.inspectionMileage.toLocaleString()}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Distance:{" "}
                    <span className={classes.boldedText}>
                      {distance.toFixed(2)} mi
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    Account Type:{" "}
                    <span
                      className={
                        returnAccountType(accountType) === "Company Car"
                          ? classes.redBoldedText
                          : classes.boldedText
                      }
                    >
                      {returnAccountType(car.accountType)}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    <span className={classes.boldedText}>High Bidder: </span>
                    <span>{highBidder}</span>
                  </Typography>
                </div>
                <CardContent className={classes.rightTextColumn}>
                  <Typography className={classes.cardText}>
                    <span className={classes.boldedText}>
                      {car.saleEventName}
                    </span>
                  </Typography>
                  <Typography className={classes.cardText}>
                    <span className={classes.boldedText}>My AutoBid: </span>
                    <span className={classes.DDOColorText}>
                      {myCurrentAutoBid.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </Typography>

                  <Typography className={classes.cardText}>
                    <span className={classes.boldedText}>Bids: </span>
                    <span className={classes.DDOColorText}>
                      {bidCount === 0 ? (
                        0
                      ) : (
                        <Link
                          id="bidCountLink"
                          component="button"
                          onClick={() => this.toggleBidCount()}
                        >
                          {bidCount}
                        </Link>
                      )}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </div>
          </div>
        </Card>
        <div className={classes.infoCard}>
          {openNote ? (
            <Notes
              toggleNote={this.toggleNote}
              notes={notes}
              user={user}
              addNote={this.addedNote}
              car={car}
            />
          ) : null}
          {openTransportationQuote ? (
            <TransportationQuote
              car={car}
              toggleTransportationQuote={this.toggleTransportationQuote}
            />
          ) : null}
          {showCarfaxReport ? (
            <CarReport
              vin={car.vin}
              reportType="carfax"
              showCarfaxReport={this.showCarfaxReport}
              showNoDataVin={this.showNoDataVin}
            />
          ) : null}
          {showCarfaxSnackBar ? (
            <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
          ) : null}
          {showBidHistory ? (
            <BidHistory
              saleEventItemId={saleEventItemId}
              toggleBidCount={this.toggleBidCount}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(WinLossCard));
