import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import {
  Typography,
  FormControl,
  Button,
  Select,
  Checkbox,
  MenuItem,
  FormControlLabel,
  FormGroup,
  TextField,
  Snackbar,
  SnackbarContent,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { updateAccountSettingSuccess } from "../actions/AccountSetting.actions";
import * as LoginService from "../services/LoginServices";
import * as Actions from "../actions/AccountSetting.actions";
import * as AdminService from "../services/AdminServices";
import { validateEmail, validatePhone } from "../services/ValidationServices";

import ReactSVG from "react-svg";
import smallerRadio from "../assets/smallerRadio.svg";
import smallerCheckedRadio from "../assets/smallerCheckedRadio.svg";

const styles = (theme) => ({
  container: {
    width: "100%",
    minHeight: "100vh",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  formControl: {
    display: "flex",
    height: "2rem",
    marginTop: ".5rem",
    flexDirection: "row",
  },
  manageAccessRoleWrapper: {
    marginLeft: "3rem",
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  preferenceContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  inputRow: {
    display: "flex",
    height: "2rem",
    border: `1px solid ${theme.palette.primary.gray}`,
    marginTop: "5%",
  },
  inputLabelEmailSettings: {
    fontSize: ".8rem",
    textAlign: "center",
    alignItems: "center",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.gray,
    width: "6rem",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
      height: "1.8rem",
    },
  },
  inputField: {
    fontSize: ".8rem",
    display: "flex",
    alignItems: "center",
    width: "11rem",
    height: "100%",
    border: "none",
    padding: 0,
    paddingLeft: ".5rem",
  },
  selectInput: {
    fontSize: ".8rem",
    minWidth: "10rem",
    textAlign: "center",
  },
  emailSettingsCheckboxWrapper: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    marginTop: ".75rem",
  },
  leftWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  rightWrapper: {
    width: "31rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  userName: {
    fontSize: theme.font.small,
    display: "flex",
    alignItems: "center",
  },
  choiceTitle: {
    display: "flex",
    alignContent: "center",
    marginLeft: "5rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
    },
  },
  emailSettingsSelect: {
    fontSize: ".8rem",
    maxWidth: "20rem",
    minWidth: "10rem",
  },
  button: {
    marginLeft: "0.7rem",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.gray,
    fontSize: theme.font.small,
    fontWeight: "bold",
    width: "4rem",
    color: theme.palette.primary.white,
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: ".5rem",
      height: "2rem",
      fontSize: ".8rem",
      fontWeight: "unset",
    },
  },
  cancelButton: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.gray,
    fontSize: theme.font.small,
    fontWeight: "bold",
    width: "4rem",
    color: theme.palette.primary.white,
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: ".5rem",
      height: "2rem",
      fontSize: ".8rem",
      backgroundColor: theme.palette.button.pass,
      fontWeight: "unset",
    },
  },
  buttonWrapper: {
    display: "flex",
    margin: "1rem",
    width: "10rem",
    justifyContent: "space-around",
    position: "absolute",
    left: "42%",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
      left: 0,
      flexDirection: "row-reverse",
    },
  },
  fieldWrapper: {
    display: "flex",
    width: "20rem",
    height: "2rem",
    margin: ".5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: ".5rem",
      marginBottom: ".5rem",
    },
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "6rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
      width: "28%",
      height: "1.8rem",
    },
  },
  textField: {
    margin: 0,
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    height: "2rem",
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      width: "78%",
      fontSize: ".6rem",
    },
  },
  checkBoxLabel: {
    fontSize: ".6rem",
    display: "flex",
    alignItems: "center",
  },
  checkboxRoot: {
    "&$checked": {
      color: theme.palette.primary.gray,
      "&:hover": {
        backgroundColor: fade(
          theme.palette.primary.gray,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "&:hover": {
      backgroundColor: fade(
        theme.palette.primary.gray,
        theme.palette.action.hoverOpacity
      ),
    },
    padding: ".25rem",
    marginLeft: ".5rem",
  },
  checkboxWrapper: {
    display: "flex",
  },
  emailCheckboxWrapper: {
    height: "10rem",
    overflowY: "auto",
    backgroundColor: theme.palette.light.gray,
    [theme.breakpoints.down("sm")]: {
      marginTop: ".05rem",
    },
  },
  errorMessage: {},
  checked: {},
  errorSnackBar: {
    marginTop: "7rem",
  },
  helpText: {
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
    },
  },
  "@global": {
    "label + .MuiInput-formControl": {
      width: "15rem",
      transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      backgroundColor: "rgba(0, 0, 0, 0.09)",
      marginTop: 0,
      height: "2rem",
      [theme.breakpoints.down("sm")]: {
        height: "1.8rem",
      },
    },
    ".MuiInputBase-root": {
      [theme.breakpoints.down("sm")]: {
        fontSize: ".6rem",
      },
    },
    ".MuiRadio-colorPrimary.Mui-checked": {
      color: theme.palette.primary.gray,
    },
  },
  mobContainerHeadWrapper: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
      textAlign: "center",
      color: theme.palette.primary.gray,
    },
  },
  mobContainerHead: {
    fontSize: "1.5rem",
  },
  mobContainerMessage: {
    fontSize: ".6rem",
  },
  emailSettingsMenuItem: {
    width: "auto",
    fontSize: ".8rem",
    whiteSpace: "normal",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "normal",
      width: "12rem",
      fontSize: ".8rem",
    },
  },
  emailRadio: {
    fontSize: ".7rem",
  },
  emailRadioContainer: {
    marginLeft: "2rem",
  },
  boldedRedText: {
    display: "flex",
    marginLeft: "12rem",
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.primary.redHighlight,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "40%",
    },
  },
};
const ROLES = [
  "DLR_VIEW_ONLY",
  "BUYER",
  "GROUNDING_CLERK",
  "OPEN_PROCESS_ARBITRATION",
  "ACH_BANK_INFO",
  "DEALERSHIP_ADMIN",
];

export class MyPreferences extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;

    this.state = {
      firstName: "",
      lastName: "",
      selectedLocation: "",
      userTitle: "",
      aaNumber: "",
      selectedTimeZone: "",
      email: "",
      phone: "",
      cell: "",
      emailNotificationCheckBox: "",
      userId: user.userId,
      selectedEmailOptions: [],
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      },
      selectedAccessRoles: [],
      accessRoles: [
        ["Allow user to view only", "DLR_VIEW_ONLY"],
        ["Allow user to purchase vehicles", "BUYER"],
        ["Allow user to ground vehicles", "GROUNDING_CLERK"],
        ["Allow user to open/process Arbitration", "OPEN_PROCESS_ARBITRATION"],
        ["ACH/Bank Information", "ACH_BANK_INFO"],
        ["Full Admin Access", "DEALERSHIP_ADMIN"],
      ],
      isDisableUser: false,
      selectedDisableFromDate: null,
      selectedDisableToDate: null,
      errorMessage: null,
      dealerExclusivityEmailPreference: "",
      emailChanged: false,
      showEmailChange: false,
      previousEmail: "",
    };
  }

  componentDidMount = () => {
    const { user, accountSetting, addUser } = this.props;
    if (!addUser) {
      this.setState({
        firstName: accountSetting.firstName,
        lastName: accountSetting.lastName,
        selectedLocation: accountSetting.selectedLocation,
        userTitle: accountSetting.userTitle || "",
        aaNumber: accountSetting.aaNumber || "",
        selectedTimeZone: accountSetting.selectedTimeZone,
        email: accountSetting.email,
        previousEmail: accountSetting.email,
        phone: accountSetting.phone,
        cell: accountSetting.cell || "",
        emailNotificationCheckBox: accountSetting.emailNotificationCheckBox,
        selectedEmailOptions: accountSetting.selectedEmailOptions
          ? accountSetting.selectedEmailOptions
          : [],
        address: {
          address1:
            accountSetting.custLocations[0].locationDTO.addressDTO.addressLine1,
          address2:
            accountSetting.custLocations[0].locationDTO.addressDTO.addressLine2,
          city: accountSetting.custLocations[0].locationDTO.addressDTO.city,
          state: accountSetting.custLocations[0].locationDTO.addressDTO.state,
          zip: accountSetting.custLocations[0].locationDTO.addressDTO.zip,
        },
        selectedAccessRoles: user.userRoles,
        isDisableUser: user.isDisabledUser,
        selectedDisableFromDate: user.fromDisableDate
          ? user.fromDisableDate
          : null,
        selectedDisableToDate: user.toDisableDate ? user.toDisableDate : null,
        dealerExclusivityEmailPreference:
          accountSetting.dealerExclusivityEmailPreference
            ? accountSetting.dealerExclusivityEmailPreference
            : "12",
      });
    }
  };

  handleChange = (name) => (event) => {
    if (name === "email") {
      this.setState({
        [name]: event.target.value,
        emailChanged: true,
      });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleFromDateChange = (date) => {
    this.setState({ selectedDisableFromDate: moment(date) });
  };

  handleToDateChange = (date) => {
    this.setState({ selectedDisableToDate: moment(date) });
  };

  handleDisableUser = (event) => {
    if (!event.target.checked) {
      this.setState({
        isDisableUser: event.target.checked,
        selectedDisableFromDate: null,
        selectedDisableToDate: null,
      });
    } else {
      this.setState({ isDisableUser: event.target.checked });
    }
  };

  handleCheckChange = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  handleEmailOptions = (event) => {
    this.setState({
      selectedEmailOptions: event.target.value,
    });
  };

  unSelectEmailOption = (event) => {
    const { selectedEmailOptions } = this.state;
    const emailOptions = selectedEmailOptions.filter(
      (option) => option !== event.target.value
    );
    this.setState({
      selectedEmailOptions: emailOptions,
    });
  };

  handleAccessRoles = (event, role) => {
    if (event.target.checked) {
      if (role === "DEALERSHIP_ADMIN") {
        this.setState({ selectedAccessRoles: ROLES });
      } else {
        this.setState({
          selectedAccessRoles: [...this.state.selectedAccessRoles, role],
        });
      }
    } else {
      this.setState({
        selectedAccessRoles: this.state.selectedAccessRoles.filter(function (
          val
        ) {
          return val !== role;
        }),
      });
    }
  };

  handleLocationChange = (event) => {
    const { accountSetting } = this.props;

    const location = accountSetting.custLocations.find(
      (e) => e.locationDTO.id === event.target.value
    );

    this.setState({
      selectedLocation: event.target.value,
      address: {
        address1: location.locationDTO.addressDTO.addressLine1,
        address2: location.locationDTO.addressDTO.addressLine2,
        city: location.locationDTO.addressDTO.city,
        state: location.locationDTO.addressDTO.state,
        zip: location.locationDTO.addressDTO.zip,
      },
    });
  };

  updateAccountSettings = async () => {
    const { dispatch } = this.props;
    const { user } = this.props;
    const accountSetting = await LoginService.loadAccountSetting(user.userId);
    dispatch(Actions.loadAccountSettingSuccess(accountSetting));
  };

  handleSubmit = async (event, showEmailChange) => {
    event.preventDefault();
    const { history, dispatch } = this.props;
    const {
      firstName,
      lastName,
      selectedLocation,
      userTitle,
      aaNumber,
      selectedTimeZone,
      email,
      phone,
      cell,
      emailNotificationCheckBox,
      userId,
      selectedEmailOptions,
      isDisableUser,
      selectedAccessRoles,
      selectedDisableFromDate,
      selectedDisableToDate,
      dealerExclusivityEmailPreference,
      emailChanged,
      previousEmail,
    } = this.state;
    const payload = {
      firstName: firstName,
      lastName: lastName,
      selectedLocation: selectedLocation,
      userTitle: userTitle,
      aaNumber: aaNumber,
      selectedTimeZone: selectedTimeZone,
      email: email,
      phone: phone,
      cell: cell,
      emailNotificationCheckBox: emailNotificationCheckBox,
      userId: userId,
      selectedEmailOptions: selectedEmailOptions,
      disableUser: isDisableUser,
      selectedUserRoles: selectedAccessRoles,
      disabledFrom: selectedDisableFromDate
        ? selectedDisableFromDate.format("MM/DD/YYYY")
        : null,
      disabledTo: selectedDisableToDate
        ? selectedDisableToDate.format("MM/DD/YYYY")
        : null,
      dealerExclusivityEmailPreference,
    };

    if (!firstName || !lastName || !selectedLocation || !email || !phone) {
      this.setState({ errorMessage: "Please populate all required fields!" });
      return;
    }

    if (selectedAccessRoles.length === 0) {
      this.setState({
        errorMessage: "User must have at least one selected role.",
      });
      return;
    }

    if (!validateEmail(email)) {
      this.setState({
        errorMessage: "Please enter a valid email address.",
      });
      return;
    }

    if (!validatePhone(phone) || (cell && !validatePhone(cell))) {
      this.setState({
        errorMessage: "Please enter a valid phone/mobile number.",
      });
      return;
    }
    try {
      if (this.props.fromAdmin) {
        if (this.props.addUser) {
          const response = await AdminService.addNewUser(payload);
          if (response === "success") {
            await this.props.getCurrentUserData();
            this.props.cancelEditUser();
          } else if (response.message === "Email is already in use") {
            this.setState({
              errorMessage:
                "Users email is already in use, please use different email",
            });
            return;
          }
        } else {
          const response = await AdminService.updateAccountsettings(payload);
          if (response === "success") {
            await this.props.getCurrentUserData();
            this.props.cancelEditUser();
          }
        }
      } else if (emailChanged === true && previousEmail !== email) {
        if (showEmailChange === true) {
          const response = await LoginService.updateAccountsettings(payload);
          if (response === "success") {
            dispatch(updateAccountSettingSuccess(payload));
            history.goBack();
          }
        } else {
          this.setState({
            showEmailChange: true,
          });
        }
      } else {
        const response = await LoginService.updateAccountsettings(payload);
        if (response === "success") {
          dispatch(updateAccountSettingSuccess(payload));
          history.goBack();
        }
      }
    } catch (err) {
      console.log(err);
      this.setState({
        errorMessage: "Error has occurred while updating an account setting.",
      });
    }
  };

  render() {
    const {
      accessRoles,
      selectedAccessRoles,
      selectedDisableFromDate,
      selectedDisableToDate,
      firstName,
      lastName,
      userTitle,
      email,
      phone,
      cell,
      aaNumber,
      selectedTimeZone,
      selectedEmailOptions,
      errorMessage,
      isDisableUser,
      dealerExclusivityEmailPreference,
      showEmailChange,
    } = this.state;
    const { classes, accountSetting, history, fromAdmin, addUser } = this.props;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    return (
      <div className={classes.container}>
        <div className={classes.mobContainerHeadWrapper}>
          <Typography className={classes.mobContainerHead}>
            Account Settings
          </Typography>
          <Typography className={classes.mobContainerMessage}>
            Set up your account profile preferences
          </Typography>
        </div>
        <Typography className={classes.choiceTitle}>
          {`${fromAdmin ? "User Details" : "Preferences"} ${
            !addUser ? `- ${accountSetting.loginID}` : ""
          }`}
        </Typography>
        <form id="form" className={classes.form}>
          <div className={classes.contentWrapper}>
            <div className={classes.leftWrapper}>
              <div className={classes.fieldWrapper}>
                <Typography className={classes.fieldLabel}>
                  <strong>First Name:</strong>
                </Typography>
                <TextField
                  id="firstName"
                  placeholder="Required Field"
                  className={classes.textField}
                  value={firstName}
                  onChange={this.handleChange("firstName")}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  name="firstName"
                  InputProps={InputProps}
                  autoFocus
                  required
                />
              </div>
              <div className={classes.fieldWrapper}>
                <Typography className={classes.fieldLabel}>
                  <strong>Last Name:</strong>
                </Typography>
                <TextField
                  id="lastName"
                  placeholder="Required Field"
                  className={classes.textField}
                  value={lastName}
                  onChange={this.handleChange("lastName")}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  name="lastName"
                  InputProps={InputProps}
                  required
                />
              </div>
              <div className={classes.fieldWrapper}>
                <Typography className={classes.fieldLabel}>
                  <strong>Title:</strong>
                </Typography>
                <TextField
                  id="userTitle"
                  className={classes.textField}
                  value={userTitle}
                  onChange={this.handleChange("userTitle")}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  name="userTitle"
                  InputProps={InputProps}
                />
              </div>
              <div className={classes.fieldWrapper}>
                <Typography className={classes.fieldLabel}>
                  <strong>Email:</strong>
                </Typography>
                <TextField
                  id="email"
                  className={classes.textField}
                  placeholder="Required Field"
                  value={email}
                  onChange={this.handleChange("email")}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  name="email"
                  InputProps={InputProps}
                  required
                  type="email"
                />
              </div>
              <div className={classes.fieldWrapper}>
                <Typography className={classes.fieldLabel}>
                  <strong>Phone:</strong>
                </Typography>
                <TextField
                  id="phone"
                  className={classes.textField}
                  placeholder="Required Field"
                  value={phone}
                  onChange={this.handleChange("phone")}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  name="phone"
                  InputProps={InputProps}
                  required
                />
              </div>
              <div className={classes.fieldWrapper}>
                <Typography className={classes.fieldLabel}>
                  <strong>Mobile:</strong>
                </Typography>
                <TextField
                  id="mobile"
                  className={classes.textField}
                  value={cell}
                  onChange={this.handleChange("cell")}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  name="cell"
                  InputProps={InputProps}
                />
              </div>
              <div className={classes.fieldWrapper}>
                <Typography className={classes.fieldLabel}>
                  <strong>AA Number:</strong>
                </Typography>
                <TextField
                  id="aaNumber"
                  className={classes.textField}
                  value={aaNumber}
                  onChange={this.handleChange("aaNumber")}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                  name="aaNumber"
                  InputProps={InputProps}
                />
              </div>
            </div>
            <div className={classes.rightWrapper}>
              <div>
                <FormControl className={classes.formControl}>
                  <label className={classes.fieldLabel}>
                    <strong>Location</strong>
                  </label>
                  <Select
                    id="location"
                    className={classes.selectInput}
                    value={this.state.selectedLocation}
                    onChange={(evt) => this.handleLocationChange(evt)}
                    name="location"
                    displayEmpty
                    required
                    disableUnderline
                  >
                    <MenuItem
                      value=""
                      style={{
                        fontSize: ".8rem",
                      }}
                    >
                      Required Field
                    </MenuItem>
                    {accountSetting.custLocations.map((location) => (
                      <MenuItem
                        style={{
                          fontSize: ".8rem",
                        }}
                        id={location.locationDTO.name}
                        key={location.locationDTO.id}
                        value={location.locationDTO.id}
                      >
                        {location.locationDTO.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl className={classes.formControl}>
                  <label className={classes.fieldLabel}>
                    <strong>Time Zone</strong>
                  </label>
                  <Select
                    id="timezone"
                    className={classes.selectInput}
                    value={selectedTimeZone}
                    onChange={this.handleChange("selectedTimeZone")}
                    name="timezone"
                    displayEmpty
                    disableUnderline
                  >
                    <MenuItem
                      value=""
                      style={{
                        fontSize: ".8rem",
                      }}
                    >
                      Required Field
                    </MenuItem>
                    {accountSetting.timeZoneOptions.map((timezone) => (
                      <MenuItem
                        style={{
                          fontSize: ".8rem",
                        }}
                        key={timezone.value}
                        value={timezone.value}
                      >
                        {timezone.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.emailSettingsCheckboxWrapper}>
                  {!fromAdmin && (
                    <div>
                      <FormControlLabel
                        id="emailCheckbox"
                        control={
                          <Checkbox
                            id="emailCheckbox"
                            checked={this.state.emailNotificationCheckBox}
                            onChange={this.handleCheckChange(
                              "emailNotificationCheckBox"
                            )}
                            value="emailNotificationCheckBox"
                            color="primary"
                            classes={{
                              root: classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={
                          <Typography className={classes.checkBoxLabel}>
                            HTML Format - When possible, send e-mail as HTML
                            Format instead of plain text.
                          </Typography>
                        }
                      />
                    </div>
                  )}
                  <Typography className={classes.helpText}>
                    Select all that you would like to apply. Click the checkmark
                    to remove an option.
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <label className={classes.inputLabelEmailSettings}>
                      <strong>Email Settings</strong>
                    </label>
                    <Select
                      id="emailSettings"
                      className={classes.emailSettingsSelect}
                      multiple
                      value={selectedEmailOptions}
                      onChange={this.handleEmailOptions}
                      name="emailSettings"
                      MenuProps={MenuProps}
                      disableUnderline
                    >
                      {/*Added a filter that cuts out unwanted options, ideally this should be fixed in the API */}
                      {accountSetting.emailOptions
                        .filter(
                          (option) =>
                            option.value !== "30042" &&
                            option.value !== "30043" &&
                            option.value !== "30048"
                        )
                        .map((option) => (
                          <MenuItem
                            className={classes.emailSettingsMenuItem}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {selectedEmailOptions.length > 0 ? (
                    <div className={classes.emailCheckboxWrapper}>
                      {selectedEmailOptions.map((selectedOption) => {
                        return (
                          <React.Fragment>
                            <div className={classes.checkboxWrapper}>
                              <Checkbox
                                id={`${selectedOption}Checkbox`}
                                checked={
                                  selectedEmailOptions.indexOf(
                                    selectedOption
                                  ) !== -1
                                }
                                onChange={this.unSelectEmailOption}
                                value={selectedOption}
                                color="primary"
                                classes={{
                                  root: classes.checkboxRoot,
                                  checked: classes.checked,
                                }}
                              />
                              <Typography className={classes.checkBoxLabel}>
                                {
                                  accountSetting.emailOptions.filter(
                                    (option) => option.value === selectedOption
                                  )[0].label
                                }
                              </Typography>
                            </div>
                            {selectedOption === "26084" ? (
                              <FormControl
                                className={classes.emailRadioContainer}
                              >
                                <RadioGroup
                                  row
                                  value={dealerExclusivityEmailPreference}
                                  onChange={this.handleChange(
                                    "dealerExclusivityEmailPreference"
                                  )}
                                >
                                  <FormControlLabel
                                    value="12"
                                    control={
                                      <Radio
                                        color="primary"
                                        icon={
                                          <ReactSVG
                                            src={smallerRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                        checkedIcon={
                                          <ReactSVG
                                            src={smallerCheckedRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                      />
                                    }
                                    label="12 hrs"
                                    labelPlacement="start"
                                    classes={{ label: classes.emailRadio }}
                                  />
                                  <FormControlLabel
                                    value="10"
                                    control={
                                      <Radio
                                        color="primary"
                                        icon={
                                          <ReactSVG
                                            src={smallerRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                        checkedIcon={
                                          <ReactSVG
                                            src={smallerCheckedRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                      />
                                    }
                                    label="10 hrs"
                                    labelPlacement="start"
                                    classes={{ label: classes.emailRadio }}
                                  />
                                  <FormControlLabel
                                    value="8"
                                    control={
                                      <Radio
                                        color="primary"
                                        icon={
                                          <ReactSVG
                                            src={smallerRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                        checkedIcon={
                                          <ReactSVG
                                            src={smallerCheckedRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                      />
                                    }
                                    label="8 hrs"
                                    labelPlacement="start"
                                    classes={{ label: classes.emailRadio }}
                                  />
                                  <FormControlLabel
                                    value="6"
                                    control={
                                      <Radio
                                        color="primary"
                                        icon={
                                          <ReactSVG
                                            src={smallerRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                        checkedIcon={
                                          <ReactSVG
                                            src={smallerCheckedRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                      />
                                    }
                                    label="6 hrs"
                                    labelPlacement="start"
                                    classes={{ label: classes.emailRadio }}
                                  />
                                  <FormControlLabel
                                    value="4"
                                    control={
                                      <Radio
                                        color="primary"
                                        icon={
                                          <ReactSVG
                                            src={smallerRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                        checkedIcon={
                                          <ReactSVG
                                            src={smallerCheckedRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                      />
                                    }
                                    label="4 hrs"
                                    labelPlacement="start"
                                    classes={{ label: classes.emailRadio }}
                                  />
                                  <FormControlLabel
                                    value="2"
                                    control={
                                      <Radio
                                        color="primary"
                                        icon={
                                          <ReactSVG
                                            src={smallerRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                        checkedIcon={
                                          <ReactSVG
                                            src={smallerCheckedRadio}
                                            svgStyle={{ fill: "currentColor" }}
                                          />
                                        }
                                      />
                                    }
                                    label="2 hrs"
                                    labelPlacement="start"
                                    classes={{ label: classes.emailRadio }}
                                  />
                                </RadioGroup>
                              </FormControl>
                            ) : null}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {fromAdmin && (
              <div className={classes.manageAccessRoleWrapper}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <label
                    id="manageAccessRole"
                    className={classes.inputLabel}
                    style={{ width: "10rem" }}
                  >
                    <strong>Manage Access Roles</strong>
                  </label>
                  <FormGroup>
                    {accessRoles.map((item) => (
                      <FormControlLabel
                        key={item[0]}
                        control={
                          <Checkbox
                            id={item[1]}
                            checked={selectedAccessRoles.indexOf(item[1]) > -1}
                            classes={{
                              root: classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                            onChange={(evt) =>
                              this.handleAccessRoles(evt, item[1])
                            }
                            value={item[1]}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: ".75rem",
                            }}
                          >
                            {item[0]}
                          </Typography>
                        }
                      />
                    ))}
                    <FormControlLabel
                      id="disableUser"
                      control={
                        <Checkbox
                          id="disableUser"
                          checked={this.state.isDisableUser}
                          classes={{
                            root: classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                          onChange={(evt) => this.handleDisableUser(evt)}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: ".75rem",
                          }}
                        >
                          Disable User
                        </Typography>
                      }
                    />
                    <FormControl className={classes.contentWrapper}>
                      <label className={classes.fieldLabel}>
                        <strong>Disable Date</strong>
                      </label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          id="fromDate"
                          variant="inline"
                          placeholder="From"
                          disabled={!isDisableUser}
                          value={selectedDisableFromDate}
                          format="MM/dd/yyyy"
                          autoOk="true"
                          onChange={(date) => this.handleFromDateChange(date)}
                        />
                        <DatePicker
                          id="toDate"
                          variant="inline"
                          placeholder="To"
                          disabled={!isDisableUser}
                          value={selectedDisableToDate}
                          format="MM/dd/yyyy"
                          autoOk="true"
                          onChange={(date) => this.handleToDateChange(date)}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </FormGroup>
                </FormControl>
              </div>
            )}
          </div>
          {showEmailChange === true ? (
            <div className={classes.boldedRedText}>
              <Typography>
                You will receive an email to your original email account, If you
                do not have access to that account call Dealer Direct Support at
                1-877-522-3372
                <Button
                  id="submitButton"
                  variant="contained"
                  className={classes.button}
                  onClick={(evt) => this.handleSubmit(evt, showEmailChange)}
                >
                  Accept
                </Button>
              </Typography>
            </div>
          ) : null}
          <div className={classes.buttonWrapper}>
            <Button
              id="submitButton"
              variant="contained"
              className={classes.button}
              disabled={showEmailChange}
              onClick={(evt) => this.handleSubmit(evt)}
            >
              {addUser ? "Add" : "Update"}
            </Button>
            <Button
              id="cancelButton"
              variant="contained"
              className={classes.cancelButton}
              onClick={
                fromAdmin ? this.props.cancelEditUser : () => history.goBack()
              }
            >
              Cancel
            </Button>
          </div>
        </form>
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          className={classes.errorSnackBar}
          open={errorMessage}
          autoHideDuration={3000}
          onClose={() => this.setState({ errorMessage: null })}
        >
          <SnackbarContent
            className={classes.errorMessage}
            message={errorMessage}
          />
        </Snackbar>
      </div>
    );
  }
}

MyPreferences.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(MyPreferences));
