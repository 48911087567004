import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import * as userAccess from "../services/UserRoles";
import { set_nav_location } from "../actions/uiActions";

import * as MobilityServices from "../services/MobilityServices";
import { mobility_saved_search } from "../actions/mobility.actions";

import MobilitySavedSearch from "../components/MobilitySavedSearch";
import MobilityVehicleSearch from "../components/MobilityVehicleSearch";

const styles = (theme) => ({
  homeWrapper: {
    display: "flex",
    position: "relative",
    height: "100%",
  },
});

export class MobilityHome extends Component {
  state = {
    showCreateSearch: false,
    showSavedSearch: true,
    savedSearchInfo: {},
    userRoleHasMobilityRideshareView: false,
  };

  componentWillMount = () => {
    const { user, dispatch, navLocation, history } = this.props;
    if (!userAccess.isRideshareUser(user.userRoles)) {
      history.replace("/home");
    } else if (user.resetPassword) {
      history.replace("/changePassword");
    } else {
      if (navLocation !== "mobilityHome") {
        dispatch(set_nav_location("mobilityHome"));
      }
    }
    this.loadSavedSearch();
    this.checkUserHasViewAccess();
  };

  checkUserHasViewAccess = () => {
    const { user } = this.props;
    if (user.userRoles.includes("MOBILITY_RIDESHARE_VIEW")) {
      this.setState({
        userRoleHasMobilityRideshareView: true,
      });
    }
  };

  loadSavedSearch = async () => {
    const { user, dispatch } = this.props;
    const savedSearch = await MobilityServices.loadSavedSearch(user.userId);
    dispatch(mobility_saved_search(savedSearch));
  };

  showCreateSearch = (info) => {
    this.setState({
      showCreateSearch: true,
      showSavedSearch: false,
      savedSearchInfo: info ? info : null,
    });
  };

  hideCreateSearch = () => {
    this.setState({
      showCreateSearch: false,
      showSavedSearch: true,
    });
  };

  render() {
    const {
      showCreateSearch,
      showSavedSearch,
      savedSearchInfo,
      userRoleHasMobilityRideshareView,
    } = this.state;
    return (
      <React.Fragment>
        {showSavedSearch ? (
          <MobilitySavedSearch
            showCreateSearch={this.showCreateSearch}
            userRoleHasMobilityRideshareView={userRoleHasMobilityRideshareView}
          />
        ) : null}
        {showCreateSearch ? (
          <MobilityVehicleSearch
            hideCreateSearch={this.hideCreateSearch}
            savedSearchInfo={savedSearchInfo}
            loadSavedSearch={this.loadSavedSearch}
            userRoleHasMobilityRideshareView={userRoleHasMobilityRideshareView}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(MobilityHome));
