import React, { Component } from "react";
import { connect } from "react-redux";
import { encode } from "base-64";
import * as CarfaxService from "../services/CarfaxService";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const username = process.env.REACT_APP_CARFAX_USERNAME;
const password = process.env.REACT_APP_CARFAX_PASSWORD;
const url_carfax_auth = process.env.REACT_APP_API_CARFAX_AUTH;
const url_carfax_microservices = process.env.REACT_APP_CARFAX_URL;

const styles = (theme) => ({
  errorSnackBar: {
    marginTop: "7rem",
  },
});

export class CarReport extends Component {
  state = {
    showError: false,
  };

  componentWillMount = () => {
    this.getCarfaxData();
  };

  getCarfaxData = async () => {
    const { pageTheme } = this.props;
    const { vin } = this.props;
    var refreshtoken = window.localStorage.getItem("refresh_token");
    var accesstoken = window.localStorage.getItem("access_token");
    window.localStorage.setItem(
      "url_carfax_microservices",
      url_carfax_microservices + "/carfaxReport/reportUri/"
    );
    window.localStorage.setItem("vin", vin);
    let carfax_auth_url = url_carfax_auth;
    if (pageTheme === "Toyota") {
      carfax_auth_url = carfax_auth_url.replace("theme_value", "tdd");
    } else {
      carfax_auth_url = carfax_auth_url.replace("theme_value", "ldd");
    }
    if (
      (refreshtoken === null || refreshtoken === undefined) &&
      (accesstoken === null || accesstoken === undefined)
    ) {
      window.localStorage.setItem(
        "Authorization",
        "Basic " + encode(username + ":" + password)
      );
      window
        .open(
          carfax_auth_url,
          "_blank",
          "resizable=1,scrollbars=1,location=0,status=0,toolbar=0,menubar=0,width=750,height=650"
        )
        .focus();
    } else {
      const response = await CarfaxService.getCarfaxReportUri(
        vin,
        accesstoken,
        refreshtoken
      );
      if (response) {
        if (
          response === "Both token are expired." ||
          response === "Bad Request"
        ) {
          console.log(response);
          window
            .open(
              carfax_auth_url,
              "_blank",
              "resizable=1,scrollbars=1,location=0,status=0,toolbar=0,menubar=0,width=750,height=650"
            )
            .focus();
          return;
        } else if (response === "VALID_VIN_NO_DATA") {
          console.log("Valid_vin_no_data");
          this.props.showNoDataVin();
          return;
        } else {
          window
            .open(
              response.reportUrl,
              "_blank",
              "resizable=1,scrollbars=1,location=0,status=0,toolbar=0,menubar=0,width=750,height=650"
            )
            .focus();
        }
      }
    }
  };

  componentWillUnmount = () => {
    const { showCarfaxReport } = this.props;
    showCarfaxReport();
  };

  render() {
    return <div> </div>;
  }
}

CarReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    pageTheme: state.ui.pageTheme,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(CarReport));
