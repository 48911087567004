import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import { SnackbarContent } from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

const variantIcon = {
  success: CheckCircleIcon,
  warn: ErrorIcon,
  error: ErrorIcon,
};

const styles = (theme) => ({
  success: {
    backgroundColor: theme.palette.light.green,
    color: theme.palette.primary.gray,
    borderBottom: `5px solid ${theme.palette.DDO.main}`,
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  warn: {
    backgroundColor: theme.palette.GAMD.light,
    color: theme.palette.primary.gray,
    borderBottom: `5px solid ${theme.palette.GAMD.main}`,
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: ".5rem",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  widthTemp: {
    width: "auto",
    display: "flex",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "92%",
      maxWidth: "92%",
    },
  },
});

function MySnackbarContent(props) {
  const {
    classes,
    className,
    message,
    onClose,
    variant,
    hideIcon,
    ...other
  } = props;

  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className, classes.widthTemp)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {hideIcon !== true && (
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
          )}
          {message}
        </span>
      }
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "error"]).isRequired,
  hideIcon: PropTypes.bool,
};

export default withStyles(styles)(MySnackbarContent);
