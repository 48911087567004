import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, Snackbar } from "@material-ui/core";

import BuyVehicleInformation from "../components/BuyVehicleInformation";
import PassReasonOptions from "../components/PassReasonOptions";
import Loading from "../components/Loading";
import GuaranteePayments from "../components/GuaranteePayments";

import { passVehicle } from "../services/GAMDServices";

import { pass_car, remove_selected_mobile_car } from "../actions/carActions";
import { hide_mobile_nav_menu } from "../actions/uiActions";

const styles = (theme) => ({
  passVehicleRoot: {
    marginLeft: ".5rem",
    marginRight: ".5rem",
    height: "inherit",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginTop: "3.5rem",
    },
  },
  lowerWrapper: {
    display: "flex",
    margin: ".5rem",
  },
  text: {
    margin: ".5rem",
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  reasonWrapper: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  buttonRow1: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
  },
  buttonRow2: {
    display: "flex",
    justifyContent: "space-around",
  },
  button: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "7rem",
    fontWeight: "bold",
  },
  buttonGAMD: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "7rem",
    fontWeight: "bold",
  },
  passButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "14rem",
    fontWeight: "bold",
  },
  passButtonGAMD: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "14rem",
    fontWeight: "bold",
  },
  thanksHeader: {
    backgroundColor: theme.palette.grounding.main,
    borderRadius: "1rem 1rem 0 0",
    color: theme.palette.primary.white,
    fontSize: "1rem",
    fontWeight: "bold",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      borderRadius: "0 0 .75rem .75rem",
    },
  },
  thanksHeaderGAMD: {
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: "1rem 1rem 0 0",
    color: theme.palette.primary.white,
    fontSize: "1rem",
    fontWeight: "bold",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
});

export class PassVehicle extends Component {
  state = {
    condition: false,
    //subCondition
    tireReasonValueChecked: false,
    //subTire
    selectedLFTire: "",
    selectedLRTire: "",
    selectedRFTire: "",
    selectedRRTire: "",
    //end subTire
    alloyWheelDamaged: false,
    previouslyRepaired: false,
    windshieldReasonReported: false,
    odorReasonReported: false,
    missingEquipmentReasonReported: false,
    condOther: false,
    condOtherReasonReported: "",
    //end subCondition
    vehicleHistoryReasonReported: false,
    notRequiredForInventoryReasonReported: false,
    outOfPriceRange: false,
    outOfPriceRangeReason: "marketPriceHighReasonReported",
    //subOutOfPriceRangeReason
    marketPriceHighReasonReported: false,
    damageNotAccountedReasonReported: false,
    //end subOutOfPriceRangeReason
    remainingPaymentReasonReported: false,
    other: false,
    otherReasonReported: "",
    passError: false,
    errorMessage: "",
    //UI
    loading: false,
    paymentGuaranteed: false,
    guaranteePaymentToggled: false,
  };

  handleChange = (name) => (event) => {
    if (name === "outOfPriceRange" && !this.state.outOfPriceRange) {
      this.setState({
        [name]: event.target.checked,
        marketPriceHighReasonReported: true,
        passError: false,
      });
    } else if (name === "outOfPriceRange" && this.state.outOfPriceRange) {
      this.setState({
        [name]: event.target.checked,
        marketPriceHighReasonReported: false,
        damageNotAccountedReasonReported: false,
        passError: false,
      });
    } else {
      this.setState({
        [name]: event.target.checked,
        passError: false,
      });
    }
  };

  handleRadio = (event) => {
    if (event.target.value === "marketPriceHighReasonReported") {
      this.setState({
        outOfPriceRangeReason: event.target.value,
        marketPriceHighReasonReported: true,
        damageNotAccountedReasonReported: false,
        passError: false,
      });
    } else {
      this.setState({
        outOfPriceRangeReason: event.target.value,
        marketPriceHighReasonReported: false,
        damageNotAccountedReasonReported: true,
        passError: false,
      });
    }
  };

  handleSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePass = async () => {
    const {
      dispatch,
      car,
      user,
      carInfo,
      togglePass,
      ground,
      history,
      mobileCarInformation,
    } = this.props;
    this.setState({ loading: true });
    if (this.verifyValidPass()) {
      if (carInfo !== undefined) {
        const passedVehicle = await passVehicle(carInfo, user, this.state);
        if (
          passedVehicle.message !== "Failed" &&
          passedVehicle.groundingPassReasonDTOs[0]
        ) {
          if (!ground) {
            dispatch(pass_car(passedVehicle));
            if (mobileCarInformation) {
              togglePass(true, car);
              dispatch(remove_selected_mobile_car());
              dispatch(hide_mobile_nav_menu(false));
            }
            togglePass(true, car);
          } else {
            history.replace("/home");
          }
        } else {
          this.setState({
            passError: true,
            errorMessage: passedVehicle.status,
          });
        }
      } else {
        const passedVehicle = await passVehicle(car, user, this.state);
        if (
          passedVehicle.message !== "Failed" &&
          passedVehicle.groundingPassReasonDTOs[0]
        ) {
          dispatch(pass_car(passedVehicle));
          if (mobileCarInformation) {
            dispatch(remove_selected_mobile_car());
            dispatch(hide_mobile_nav_menu(false));
          }
          togglePass(true, car);
        } else {
          this.setState({
            passError: true,
            errorMessage: passedVehicle.status,
            loading: false,
          });
        }
      }
    } else {
      this.setState({
        passError: true,
        loading: false,
      });
    }
  };

  verifyValidPass = () => {
    const {
      condition,
      tireReasonValueChecked,
      selectedLFTire,
      selectedLRTire,
      selectedRFTire,
      selectedRRTire,
      alloyWheelDamaged,
      previouslyRepaired,
      windshieldReasonReported,
      odorReasonReported,
      missingEquipmentReasonReported,
      condOtherReasonReported,
      vehicleHistoryReasonReported,
      notRequiredForInventoryReasonReported,
      outOfPriceRange,
      marketPriceHighReasonReported,
      damageNotAccountedReasonReported,
      remainingPaymentReasonReported,
      otherReasonReported,
    } = this.state;
    let validPass = true;
    if (condition) {
      if (
        tireReasonValueChecked &&
        selectedLFTire === "" &&
        selectedLRTire === "" &&
        selectedRFTire === "" &&
        selectedRRTire === ""
      ) {
        validPass = false;
        this.setState({
          errorMessage: "Please select a depth for at least one tire",
        });
      }
      if (
        !tireReasonValueChecked &&
        !alloyWheelDamaged &&
        !previouslyRepaired &&
        !windshieldReasonReported &&
        !odorReasonReported &&
        !missingEquipmentReasonReported &&
        condOtherReasonReported === ""
      ) {
        validPass = false;
        this.setState({
          errorMessage: "Please indicate a reason for passing under condition.",
        });
      }
    }
    if (
      !condition &&
      !vehicleHistoryReasonReported &&
      !notRequiredForInventoryReasonReported &&
      !outOfPriceRange &&
      !marketPriceHighReasonReported &&
      !damageNotAccountedReasonReported &&
      !remainingPaymentReasonReported &&
      otherReasonReported === ""
    ) {
      validPass = false;
      this.setState({
        errorMessage: "Please indicate a reason for passing.",
      });
    }

    return validPass;
  };

  toggleGuaranteePayment = () => {
    this.setState({
      guaranteePaymentToggled: !this.state.guaranteePaymentToggled,
    });
  };

  toggleGuaranteePaymentDone = () => {
    this.setState({
      paymentGuaranteed: true,
      guaranteePaymentToggled: !this.state.guaranteePaymentToggled,
    });
  };

  getCarInfoForGuaranteePayments = () => {
    const {
      make,
      model,
      year,
      vehicleIdentificationNumber,
      id,
      remainingPaymentsAmt,
    } = this.props.car;
    const car = {
      id: id,
      year: year,
      make: make,
      model: model,
      vehicleIdentificationNumber: vehicleIdentificationNumber,
      remainingPaymentsAmt: remainingPaymentsAmt,
    };
    return car;
  };

  render() {
    const {
      car,
      classes,
      togglePassVehicle,
      ground,
      togglePass,
      updateGuaranteePayment,
    } = this.props;
    const {
      passError,
      errorMessage,
      loading,
      paymentGuaranteed,
      guaranteePaymentToggled,
    } = this.state;
    const {
      guaranteedPayments,
      remainingPaymentsAmt,
      displayGuaranteePaymentsOnly,
    } = this.props.car;
    return (
      <React.Fragment>
        {!guaranteePaymentToggled ? (
          <div className={classes.passVehicleRoot}>
            <Typography
              variant="h5"
              className={
                !ground ? classes.thanksHeaderGAMD : classes.thanksHeader
              }
            >
              I do not want to purchase this vehicle
            </Typography>
            <div className={classes.contentWrapper}>
              <div>
                <BuyVehicleInformation car={car} ground={ground} />
                <div className={classes.buttonWrapper}>
                  <div className={classes.buttonRow1}>
                    <Button
                      className={!ground ? classes.buttonGAMD : classes.button}
                      id="passButton"
                      onClick={!loading ? () => this.handlePass() : null}
                    >
                      {!loading ? "Pass" : <Loading color={"#FFFFFF"} />}
                    </Button>
                    <Button
                      id="cancelButton"
                      className={!ground ? classes.buttonGAMD : classes.button}
                      onClick={() =>
                        ground ? togglePassVehicle() : togglePass(null, car)
                      }
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={classes.buttonRow2}>
                    {remainingPaymentsAmt > 0 &&
                    guaranteedPayments === 0 &&
                    displayGuaranteePaymentsOnly ? (
                      <Button
                        id="guarPayButton"
                        onClick={() => this.toggleGuaranteePayment()}
                        className={
                          !ground ? classes.passButtonGAMD : classes.passButton
                        }
                        disabled={paymentGuaranteed}
                      >
                        Guarantee Payments Only
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={classes.reasonWrapper}>
                <PassReasonOptions
                  handleChange={this.handleChange}
                  passOptions={this.state}
                  handleRadio={this.handleRadio}
                  handleSelect={this.handleSelect}
                  ground={ground}
                />
              </div>

              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={passError}
                ContentProps={{
                  "aria-describedby": "message-id",
                }}
                message={<span id="message-id">{errorMessage}</span>}
                style={{ top: "10rem" }}
              />
            </div>
          </div>
        ) : null}
        {guaranteePaymentToggled ? (
          <GuaranteePayments
            car={this.getCarInfoForGuaranteePayments()}
            toggleGuaranteePayment={this.toggleGuaranteePayment}
            toggleGuaranteePaymentDone={this.toggleGuaranteePaymentDone}
            user={this.props.user}
            updateGuaranteePayment={updateGuaranteePayment}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

PassVehicle.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    car: state.cars.passCar,
    user: state.user,
    mobileCarInformation: state.cars.mobileCarInformation,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(PassVehicle));
