export function filterSearchCriteria(params) {
  const criteriaWrapperData = params.advancedSearchCriteriaWrapper;
  const auctionData = params.auctionLocationDataConst;
  const criteriaData = params.advancedSearchCriteria;
  const stateInfo = params.stateInfo;
  let interiorColorCount = 0,
    exteriorColorCount = 0,
    yearCount = 0,
    makeCount = 0,
    modelCount = 0,
    modelNumberCount = 0,
    styleCount = 0,
    transmissionCount = 0,
    engineCount = 0,
    optionCount = 0,
    auctionCount = 0;
  let allInteriorColor = [],
    allExteriorColor = [],
    year = [],
    makeArray = [],
    modelArray = [],
    allModelNumberArray = [],
    allSeriesNameArray = [],
    allStyleArray = [],
    allTransmission = [],
    allEngine = [],
    allOption = [],
    allAuction = [];
  // Interior Color
  for (let [, value] of Object.entries(criteriaData["Interior Color"])) {
    allInteriorColor[interiorColorCount] = value.criteriaValue;
    interiorColorCount++;
  }
  let interiorColor = [...new Set(allInteriorColor.map((item) => item))];
  // Exterior Color
  for (let [, value] of Object.entries(criteriaData["Exterior Color"])) {
    allExteriorColor[exteriorColorCount] = value.criteriaValue;
    exteriorColorCount++;
  }
  let exteriorColor = [...new Set(allExteriorColor.map((item) => item))];
  //Auction Location
  for (var i = 0; i < auctionData.length; i++) {
    allAuction[auctionCount] =
      auctionData[i].auctionCode + " " + auctionData[i].auctionDesc;
    auctionCount++;
  }
  let auctionLocation = [...new Set(allAuction.map((item) => item))];
  // Year
  for (let [, value] of Object.entries(criteriaData["Year"])) {
    year[yearCount] = value.id;
    yearCount++;
  }
  let yearRange = range(
    Math.min.apply(null, year),
    Math.max.apply(null, year)
  ).reverse();
  let yearRangeString = [...new Set(yearRange.map((item) => item.toString()))];
  // Make Model Series
  for (let [key] of Object.entries(
    criteriaWrapperData["typeMakeModelSeriesModelNumberMap"]
  )) {
    if (key === "Toyota" || key === "Lexus") {
      makeArray[makeCount] = key;
      makeCount++;
      for (let [key1] of Object.entries(
        criteriaWrapperData["typeMakeModelSeriesModelNumberMap"][key]
      )) {
        modelArray[modelCount] = key1;
        modelCount++;
        for (let [, value] of Object.entries(
          criteriaWrapperData["typeMakeModelSeriesModelNumberMap"][key][key1]
        )) {
          allModelNumberArray[modelNumberCount] = value.modelNumber;
          allSeriesNameArray[modelNumberCount] = value.seriesName;
          modelNumberCount++;
        }
      }
    }
  }
  let modelNumberArray = [...new Set(allModelNumberArray.map((item) => item))];
  let seriesNameArray = [...new Set(allSeriesNameArray.map((item) => item))];
  modelArray.push("All");
  modelNumberArray.push("All");
  seriesNameArray.push("All");
  // Style
  for (let [, value] of Object.entries(criteriaWrapperData["styles"])) {
    allStyleArray[styleCount] = value.style;
    styleCount++;
  }
  let styleArray = [...new Set(allStyleArray.map((item) => item))];
  styleArray.push("All");
  // State
  let stateInfoArray = [...new Set(stateInfo.map((item) => item.name))];
  // Transmission
  for (let [, value] of Object.entries(criteriaData["Vehicle Transmission"])) {
    allTransmission[transmissionCount] = value.criteriaValue;
    transmissionCount++;
  }
  let transmission = [...new Set(allTransmission.map((item) => item))];
  // Engine
  for (let [, value] of Object.entries(criteriaData["Engine Type"])) {
    allEngine[engineCount] = value.criteriaValue;
    engineCount++;
  }
  let engine = [...new Set(allEngine.map((item) => item))];
  // Options
  for (let [, value] of Object.entries(criteriaData["Vehicle Options"])) {
    allOption[optionCount] = value.displayCode;
    optionCount++;
  }
  let option = [...new Set(allOption.map((item) => item))];

  let finalCriteriaOptions = {
    auction: auctionLocation.sort(),
    make: makeArray.sort(),
    model: modelArray.sort(),
    modelNumber: modelNumberArray.sort(),
    series: seriesNameArray.sort(),
    interiorColor: interiorColor.sort(),
    exteriorColor: exteriorColor.sort(),
    style: styleArray.sort(),
    yearFrom: yearRangeString.sort((a, b) => {
      return parseInt(b) - parseInt(a);
    }),
    yearTo: yearRangeString.sort((a, b) => {
      return parseInt(b) - parseInt(a);
    }),
    states: stateInfoArray.sort(),
    transmission: transmission.sort(),
    engine: engine.sort(),
    options: option.sort(),
    selectState: [],
  };

  return finalCriteriaOptions;
}

const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};
