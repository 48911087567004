import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import moment from "moment";
import { Typography } from "@material-ui/core";
import EditManagePayment from "../components/EditManagePayment";

const styles = (theme) => ({
  root: {
    width: "95%",
    height: "90%",
    display: "flex",
    flexDirection: "column",
  },
  topWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  bottomWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  tableHead: {
    backgroundColor: "#EEE",
  },
  tableCell: {
    width: "auto",
    height: "auto",
  },
  paymentSetup: {
    width: "75%",
  },
  creditLimit: {
    width: "18%",
    marginLeft: "2rem",
    height: "100%",
  },
});
export class ManagePayments extends Component {
  state = {
    paymentData: [],
    displayEdit: false,
    paymentType: "",
  };

  cancelEditPayment = () => {
    this.setState({ displayEdit: false });
  };

  handleEditPayment = () => {
    this.setState({ displayEdit: true });
  };

  paymentType = (paymentOptionDTOCollection) => {
    let code = "";
    paymentOptionDTOCollection.map((item) => {
      if (item.isSelectedPaymentOption) {
        return (code = item.optionCode);
      } else {
        return null;
      }
    });
    switch (code) {
      case "CHQ":
        return "Check";
      case "ACH":
        return "ACH";
      case "FP":
        return "Floor Plan";
      default:
        break;
    }
  };

  render() {
    const { classes, data, user } = this.props;
    const { displayEdit } = this.state;
    const cellStyle = {
      textAlign: "center",
      padding: 0,
      fontSize: 14,
      height: "2.45rem",
    };
    return (
      <React.Fragment>
        <div id="materialTable" className={classes.root}>
          {!displayEdit ? (
            <div className={classes.topWrapper}>
              <div className={classes.paymentSetup}>
                <MaterialTable
                  id="manage_payment_table"
                  title={<Typography>Payment Setup</Typography>}
                  columns={[
                    {
                      title: "Legal Name",
                      field: "dealershipLegalName",
                      cellStyle,
                    },
                    {
                      title: "Bank Name",
                      field: "bankName",
                      cellStyle,
                    },
                    {
                      title: "Account Number",
                      field: "accountNumber",
                      cellStyle,
                      render: (rowData) =>
                        rowData.accountNumber
                          ? rowData.accountNumber.replace(/.(?=.{4})/g, "*")
                          : "",
                    },
                    {
                      title: "Routing Number",
                      field: "routingNumber",
                      cellStyle,
                      render: (rowData) =>
                        rowData.routingNumber
                          ? rowData.routingNumber.replace(/.(?=.{4})/g, "*")
                          : "",
                    },
                    {
                      title: "Payment Type",
                      cellStyle,
                      render: (rowData) =>
                        this.paymentType(rowData.paymentOptionDTOCollection),
                    },
                    {
                      title: "Last Updated",
                      field: "lastUpdated",
                      cellStyle,
                      render: (rowData) =>
                        moment(rowData.lastUpdated).format("MM/DD/YYYY"),
                    },
                  ]}
                  data={this.props.data}
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Manage Payments",
                      onClick: (event, rowData) => {
                        this.handleEditPayment();
                      },
                    },
                  ]}
                  options={{
                    headerStyle: {
                      backgroundColor: "#EEE",
                      textAlign: "center",
                      padding: ".5rem",
                    },
                    showTitle: true,
                    sorting: true,
                    maxBodyHeight: 500,
                    actionsColumnIndex: -1,
                    search: false,
                    paging: false,
                  }}
                />
              </div>
              <div className={classes.creditLimit}>
                <MaterialTable
                  className={classes.creditLimit}
                  id="credit_limit_table"
                  title={
                    <Typography>{`${
                      user.franchise === "TBSG" ? "TFS" : "LFS"
                    } Floor Plan Info`}</Typography>
                  }
                  columns={[
                    {
                      title: "Credit Limit",
                      field: "creditLimit",
                      cellStyle,
                      render: (rowData) =>
                        rowData.creditLimit && rowData.creditLimit > 0
                          ? "Y"
                          : "N",
                    },
                  ]}
                  data={data}
                  options={{
                    headerStyle: {
                      backgroundColor: "#EEE",
                      textAlign: "center",
                      padding: ".5rem",
                    },
                    showTitle: true,
                    search: false,
                    paging: false,
                  }}
                />
              </div>
            </div>
          ) : null}
          {displayEdit && (
            <EditManagePayment
              cancelEditPayment={this.cancelEditPayment}
              data={this.props.data[0]}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

ManagePayments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(ManagePayments));
