import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  FormControl,
  Input,
  InputLabel,
  Button,
  Snackbar,
  SnackbarContent,
  Link,
} from "@material-ui/core";

import * as LoginServices from "../services/LoginServices";
import {
  CANCEL,
  USERNAME,
  FORGOT_PASSWORD,
  ENTER,
  ENTER_USER_ID_AND_EMAIL,
  FORGET_PASSWORD_ERROR_MSG,
  EMAIL_ADDRESS,
} from "../constants/UILabel";

const styles = (theme) => ({
  container: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      zIndex: 2,
    },
  },
  paper: {
    marginTop: "4rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `1.5rem 1.5rem 1.5rem`,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  underline: {
    "&:after": {
      borderBottom: `2px solid ${theme.palette.grounding.main}`,
    },
  },
  submit: {
    backgroundColor: theme.palette.button.main,
  },
  errorMessage: {},
  errorSnackBar: {
    marginTop: "7rem",
  },
  link: {
    fontSize: "1rem",
    marginTop: "1rem",
    alignSelf: "center",
    color: theme.palette.primary.gray,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.white,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
});

export class ForgotPassword extends Component {
  state = {
    username: "",
    emailId: "",
    errorMessage: null,
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    const { history } = this.props;
    const { username, emailId } = this.state;
    let errors = {
      username: false,
      emailId: false,
    };
    if (username === "") {
      errors.username = true;
    }
    if (emailId === "") {
      errors.emailId = true;
    }
    if (errors.Username || errors.emailId) {
      this.setState({
        errorMessage: FORGET_PASSWORD_ERROR_MSG.FILL_BOTH_FIELDS,
      });
      return;
    }
    try {
      const resp = await LoginServices.forgotPassword(username, emailId);

      if ((await resp.status) === "false") {
        this.setState({
          errorMessage: FORGET_PASSWORD_ERROR_MSG.PASSWORD_WAS_NOT_CHANGED,
        });
      } else {
        this.setState({
          errorMessage: FORGET_PASSWORD_ERROR_MSG.PASSWORD_EMAIL_SENT,
        });
        setTimeout(() => {
          history.push("/");
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { classes, history } = this.props;
    const { errorMessage } = this.state;

    return (
      <div className={classes.container} elevation={1}>
        <Paper className={classes.paper}>
          <Typography variant="h5" component="h3">
            {FORGOT_PASSWORD}
          </Typography>
          <Typography component="p">{ENTER_USER_ID_AND_EMAIL}</Typography>
          <div className={classes.form}>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="userId">{USERNAME}</InputLabel>
              <Input
                id="userId"
                name="userId"
                value={this.state.userId}
                onChange={this.handleChange("username")}
                autoFocus
                classes={{ underline: classes.underline }}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="email">{EMAIL_ADDRESS}</InputLabel>
              <Input
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange("emailId")}
                classes={{ underline: classes.underline }}
              />
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => this.handleSubmit()}
            >
              {ENTER}
            </Button>
            <Link className={classes.link} onClick={() => history.goBack()}>
              {CANCEL}
            </Link>
          </div>
        </Paper>
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          className={classes.errorSnackBar}
          open={errorMessage}
          autoHideDuration={3000}
          onClose={() => this.setState({ errorMessage: null })}
        >
          <SnackbarContent
            className={classes.errorMessage}
            message={errorMessage}
          />
        </Snackbar>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(ForgotPassword));
