import { saveAs } from "file-saver";

const API_URL = process.env.REACT_APP_API_URL;

export async function buy(
  inventoryId,
  userId,
  dealerId,
  saleEventItemId,
  loginId
) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/vehiclePurchase/buy?inventoryId=${inventoryId}&userId=${userId}&dealerId=${dealerId}&saleEventItemId=${saleEventItemId}&loginId=${loginId.trim()}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return {
        status: "false",
      };
    }
  } catch (err) {
    console.log(err);
    return { status: "false", message: err.message };
  }
}

export async function buyNow(userId, dealerId, saleEventItemId) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/vehiclePurchase/buyNow?dealerId=${dealerId}&saleEventItemId=${saleEventItemId}&userId=${userId}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return {
        status: "false",
      };
    }
  } catch (err) {
    console.log(err);
    return {
      status: "false",
      message: err.message,
    };
  }
}

export async function getPurchaseReceiptPdf(inventoryId) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream",
    },
  };
  try {
    await fetch(
      `${API_URL}/vehiclePurchase/getPurchaseReceiptPdf?inventoryId=${inventoryId}`,
      options
    )
      .then(function (resp) {
        return resp.blob();
      })
      .then(function (blob) {
        saveAs(blob, "purchase-receipt.pdf");
      });
  } catch (err) {
    console.log(err);
  }
}

export async function buyBalloonVehicle(inventoryId, userId, storeName) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/vehiclePurchase/balloonbuy?inventoryId=${inventoryId}&userId=${userId}&storeName=${storeName}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return {
        status: "false",
      };
    }
  } catch (err) {
    console.log(err);
    return { status: "false", message: err.message };
  }
}

export const cancelPurchase = async (car, user, fromReport) => {
  const token = sessionStorage.getItem("token");
  const { loggedInUserId } = user;
  const {
    id,
    dealerFacingStatus,
    preAuctionStatus,
    finalStatus,
    isLatestStatusCategory,
    saleEventItemId,
  } = car;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      loggedInUserId,
      inventoryId: id,
      dealerFacingStatus,
      preAuctionStatus,
      finalStatus,
      isLatestStatusCategory,
      saleEventItemId,
      cancelPurchaseFromReport: fromReport, //If this is false, it removes the car from GAMD
    }),
  };
  try {
    const response = await fetch(
      `${API_URL}/vehiclePurchase/cancelPurchase`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else if (response.status === 500) {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const completePurchase = async (car, user, purchaseOptions) => {
  const token = sessionStorage.getItem("token");
  const {
    id,
    saleEventItemId,
    isUpStream,
    remainingPaymentFeeAmount,
    isPilotEnabled,
    hideGuaranteePopup,
    invStatusInAuction,
    adminFee,
    promotionalFee,
    programDTO,
    groundingDealerId,
    checkFee,
    purchaseOptionAvailable,
    paymentGuaranteed,
    remainingPayments,
    waivedPayments,
  } = car;
  const { dealerId, userId } = user;
  const {
    isBuyNow,
    priceOptionCode,
    paymentOptionSelected,
    paymentOptionCode,
    transportMethodSelected,
    isGuaranteedFromBuy,
    specialInstructions,
    transportDTO,
    totalPriceAmount,
    selectedLocation,
    selectedLocationName,
    selectedPrice,
  } = purchaseOptions;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      inventoryId: id,
      saleEventId: saleEventItemId,
      dealerId: parseInt(dealerId, 10),
      userId: parseInt(userId, 10),
      isUpstream: priceOptionCode !== "MBP" ? isUpStream : null,
      priceOptionSelected: null, // expecting a long but the code doesn't match the database
      priceOptionCode,
      paymentOptionSelected,
      paymentOptionCode,
      selectedTransMethod: transportDTO.quote
        ? transportMethodSelected
        : "Buyer Arranged",
      transportLocation: transportDTO.quote ? selectedLocationName : null,
      transportQuote:
        car.promotions === "TAP"
          ? Number(transportDTO.quoteWithPromo)
          : transportDTO.quote,
      transportLocationId: transportDTO.quote ? selectedLocation : null,
      specialInstuction: specialInstructions,
      priceAmount: isBuyNow
        ? car.discountedBuyNowPrice
          ? car.discountedBuyNowPrice
          : car.buyNowPrice
        : selectedPrice,
      totalPriceAmount: totalPriceAmount,
      adminFees: adminFee,
      promotionalFees: promotionalFee,
      programDTOs: programDTO,
      isGroundingDealer: parseInt(dealerId, 10) === groundingDealerId,
      groundingDealerId,
      checkFee,
      purchaseOptionAvailable,
      achCode: null,
      fpCode: null,
      paymentGuaranteed,
      remainingPayments,
      invStatusInAuction,
      isPilotEnabled,
      hideGuaranteePopup,
      isGuaranteedFromBuy,
      remainingPaymentFeeAmount,
      waivedPayments,
      promotionEntries: null,
      rewardEntries: null,
    }),
  };
  try {
    const response = await fetch(
      `${API_URL}/vehiclePurchase/completePurchaseWeb`,
      options
    );
    if (response.status === 200) {
      return response.json();
    }
  } catch (err) {
    console.log(err);
    return { status: false, message: err.message };
  }
};

export const sendPurchaseDetailsNotification = async (
  car,
  purchaseOptions,
  toContacts,
  ccContacts
) => {
  const token = sessionStorage.getItem("token");
  const {
    id,
    adminFee,
    checkFee,
    vehicleIdentificationNumber,
    grossPayoff,
    residualAmount,
    remainingPaymentsAmt,
    remPayNotGuaranDuringPurchase,
    marketBasedPrice,
    buyNowPrice,
    promotionalFee,
  } = car;
  const { totalPriceAmount } = purchaseOptions;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      inventoryId: id,
      vehicleIdentificationNumber: vehicleIdentificationNumber,
      grossPayoff: grossPayoff,
      residualAmount: residualAmount,
      remainingPaymentsAmt: remainingPaymentsAmt,
      remPayNotGuaranDuringPurchase: remPayNotGuaranDuringPurchase,
      marketBasedPrice: marketBasedPrice,
      buyNowPrice: buyNowPrice,
      checkFee: checkFee,
      promotionalFee: promotionalFee,
      adminFee: adminFee,
      purchaseAmount: totalPriceAmount,
      dealerBlockBuyerName: null,
      toContacts: toContacts,
      ccContacts: ccContacts,
      promotionEntries: null,
      rewardEntries: null,
    }),
  };
  try {
    const response = await fetch(
      `${API_URL}/vehiclePurchase/sendPurchaseEmail`,
      options
    );
    return response.json();
  } catch (err) {
    return { status: false, message: err.message };
  }
};
