import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import ReactSVG from "react-svg";

import { withStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Typography } from "@material-ui/core";

import home from "../assets/home.svg";
import watchlistIcon from "../assets/watchlistIcon.svg";
import reportsIcon from "../assets/reportsIcon.svg";
import adminIcon from "../assets/adminIcon.svg";
import DDONavIcon from "../assets/DDONavIcon.svg";
import GAMDNavIcon from "../assets/GAMDNavIcon.svg";
import groundNavIcon from "../assets/groundNavIcon.svg";

import { set_nav_location } from "../actions/uiActions";

import * as userAccess from "../services/UserRoles";

export function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const indicatorThickness = "3px";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    marginTop: "3.5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tab: {
    color: theme.palette.text.navigationTab,
    textTransform: "initial",
    fontWeight: "bold",
    height: "100%",
    opacity: 1,
    width: "14%",
  },
  appBar: {
    backgroundColor: theme.palette.background.main,
    borderRadius: "0 0 20px",
    maxWidth: "95%",
    boxShadow: "2px 5px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08)",
    display: "flex",
  },
  tabs: {},
  ground: {
    color: theme.palette.grounding.main,
  },
  grounded: {
    color: theme.palette.GAMD.main,
  },
  dealerDirect: {
    color: theme.palette.DDO.main,
  },
  watchlistTab: {},
  reportsTab: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  adminTab: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  homeIndicator: {
    backgroundColor: theme.palette.primary.main,
    height: indicatorThickness,
  },
  groundIndicator: {
    backgroundColor: theme.palette.grounding.main,
    height: indicatorThickness,
  },
  groundedIndicator: {
    backgroundColor: theme.palette.GAMD.main,
    height: indicatorThickness,
  },
  dealerDirectIndicator: {
    backgroundColor: theme.palette.DDO.main,
    height: indicatorThickness,
  },
  selectedTab: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.gray,
    fill: theme.palette.primary.white,
  },
  navImage: {
    zIndex: 2,
    position: "relative",
    top: "5px",
    fill: "currentColor",
    height: "1rem",
  },
  GAMDNavImage: {
    zIndex: 2,
    position: "relative",
    top: "-.25rem",
    fill: "currentColor",
    height: "1rem",
  },
  labelIcon: {
    padding: 0,
    minHeight: 0,
  },
  tabWrapper: {
    height: "3.3rem",
    width: "13.5rem",
    position: "relative",
    display: "flex",
    justifyContent: "space-around",
  },
  GAMDTabWrapper: {
    height: "3.3rem",
    width: "13.5rem",
  },
  DDOTabWrapper: {
    height: "3.3rem",
    width: "13.5rem",
    position: "relative",
    top: "-.3rem",
  },
  advSearchTab: {
    display: "none",
  },
  "@global": {
    ".MuiTab-labelIcon .MuiTab-wrapper div:first-child": {
      marginBottom: 0,
    },
  },
});

export class DesktopNavMenu extends React.Component {
  handleChange = (event, value) => {
    const { dispatch, navLocation } = this.props;
    if (navLocation === value) {
      window.location.reload();
    } else {
      dispatch(set_nav_location(value));
    }
  };

  indicatorColor = () => {
    const { classes, navLocation } = this.props;
    switch (navLocation) {
      case "ground":
        return classes.groundIndicator;
      case "GAMD":
        return classes.groundedIndicator;
      case "DDO":
        return classes.dealerDirectIndicator;
      default:
        return classes.homeIndicator;
    }
  };
  render() {
    const { classes, history, user, navLocation, watchlistCount } = this.props;
    const token = sessionStorage.getItem("token");
    return (
      <React.Fragment>
        {token ? (
          <div className={classes.root}>
            <AppBar
              className={classes.appBar}
              color="inherit"
              position="static"
            >
              <Tabs
                variant="scrollable"
                scrollButtons="on"
                classes={{
                  root: classes.tabs,
                  indicator: this.indicatorColor(),
                }}
                value={navLocation}
                onChange={this.handleChange}
              >
                {userAccess.isRideshareUser(user.userRoles) ? (
                  <Tab
                    id="mobilityHomeTab"
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                      labelIcon: classes.labelIcon,
                      wrapper: classes.tabWrapper,
                    }}
                    label="Home"
                    onClick={(evt) => history.push("/mobility")}
                    value="mobilityHome"
                    icon={<ReactSVG className={classes.navImage} src={home} />}
                  />
                ) : (
                  <Tab
                    id="homeTab"
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                      labelIcon: classes.labelIcon,
                      wrapper: classes.tabWrapper,
                    }}
                    label="Home"
                    onClick={(evt) => history.push("/home")}
                    value="home"
                    icon={<ReactSVG className={classes.navImage} src={home} />}
                  />
                )}
                {userAccess.isRideshareUser(user.userRoles) ? (
                  <Tab
                    className={classes.reportsTab}
                    id="mobilityReportsTab"
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                      labelIcon: classes.labelIcon,
                      wrapper: classes.tabWrapper,
                    }}
                    label="Reports"
                    onClick={(evt) => history.push("/mobilityReports")}
                    value="mobilityReports"
                    icon={
                      <ReactSVG
                        className={classes.navImage}
                        src={reportsIcon}
                      />
                    }
                  />
                ) : null}
                {userAccess.canGroundVehicles(user.userRoles) && !user.dealerSuspendedGrounding ? (
                  <Tab
                    id="groundTab"
                    className={classes.ground}
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                      labelIcon: classes.labelIcon,
                      wrapper: classes.tabWrapper,
                    }}
                    label="Ground"
                    onClick={(evt) => history.push("/ground")}
                    value="ground"
                    icon={
                      <ReactSVG
                        className={classes.navImage}
                        src={groundNavIcon}
                        svgStyle={{ width: "25px" }}
                        alt="ground Nav Icon"
                      />
                    }
                  />
                ) : null}
                {userAccess.canPurchaseVehicles(user.userRoles) ||
                userAccess.canViewOnly(user.userRoles) ? (
                  <Tab
                    id="GAMDTab"
                    className={classes.grounded}
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                      labelIcon: classes.labelIcon,
                      wrapper: classes.tabWrapper,
                    }}
                    label="Grounded"
                    onClick={(evt) => history.push("/GAMD")}
                    value="GAMD"
                    icon={
                      <ReactSVG
                        className={classes.GAMDNavImage}
                        src={GAMDNavIcon}
                        svgStyle={{ height: "2.2rem" }}
                        alt="GAMD Nav Icon"
                      />
                    }
                  />
                ) : null}
                {userAccess.canPurchaseVehicles(user.userRoles) ||
                userAccess.canViewOnly(user.userRoles) ? (
                  <Tab
                    id="DDOTab"
                    className={classes.dealerDirect}
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                      labelIcon: classes.labelIcon,
                      wrapper: classes.tabWrapper,
                    }}
                    label="Dealer Direct"
                    onClick={(evt) => history.push("/DDO")}
                    value="DDO"
                    icon={
                      <ReactSVG
                        className={classes.navImage}
                        src={DDONavIcon}
                        svgStyle={{ width: "50px" }}
                        alt="DDO Nav Icon"
                      />
                    }
                  />
                ) : null}
                {userAccess.canPurchaseVehicles(user.userRoles) ||
                userAccess.canViewOnly(user.userRoles) ? (
                  <Tab
                    className={classes.watchListTab}
                    id="watchlistTab"
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                      labelIcon: classes.labelIcon,
                      wrapper: classes.tabWrapper,
                    }}
                    label={"My Watchlist (" + watchlistCount + ")"}
                    onClick={(evt) => history.push("/watchlist")}
                    value="watchlist"
                    icon={
                      <ReactSVG
                        className={classes.navImage}
                        src={watchlistIcon}
                      />
                    }
                  />
                ) : null}
                {userAccess.canViewOnly(user.userRoles) ? (
                  <Tab
                    className={classes.reportsTab}
                    id="reportsTab"
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                      labelIcon: classes.labelIcon,
                      wrapper: classes.tabWrapper,
                    }}
                    label="Reports"
                    onClick={(evt) => history.push("/reports")}
                    value="reports"
                    icon={
                      <ReactSVG
                        className={classes.navImage}
                        src={reportsIcon}
                      />
                    }
                  />
                ) : null}
                {userAccess.canViewOnly(user.userRoles) ||
                userAccess.canManageArbitrations(user.userRoles) ||
                userAccess.canManagePayments(user.userRoles) ? (
                  <Tab
                    className={classes.adminTab}
                    id="adminTab"
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                      labelIcon: classes.labelIcon,
                      wrapper: classes.tabWrapper,
                    }}
                    label="Administrator"
                    onClick={(evt) => history.push("/admin")}
                    value="admin"
                    icon={
                      <ReactSVG className={classes.navImage} src={adminIcon} />
                    }
                  />
                ) : null}
                <Tab
                  id="advSearch"
                  className={classes.advSearchTab}
                  label="Advance Search"
                  onClick={(evt) => history.push("/advancesearch")}
                  value="advSearch"
                  icon={
                    <ReactSVG className={classes.navImage} src={adminIcon} />
                  }
                />
                <Tab
                  id="accountSettings"
                  className={classes.advSearchTab}
                  label="Account Settings"
                  onClick={(evt) => history.push("/accountsetting")}
                  value="accountsetting"
                  icon={
                    <ReactSVG className={classes.navImage} src={adminIcon} />
                  }
                />
                <Tab
                  id="help"
                  className={classes.advSearchTab}
                  label="Help"
                  onClick={(evt) => history.push("/help")}
                  value="help"
                  icon={
                    <ReactSVG className={classes.navImage} src={adminIcon} />
                  }
                />
              </Tabs>
            </AppBar>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

DesktopNavMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    navLocation: state.ui.navLocation,
    watchlistCount: state.cars.watchlistCount,
  };
};

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(DesktopNavMenu))
);
