import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import ReactSVG from "react-svg";

import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import watchlistIcon from "../assets/watchlistIcon.svg";
import activeWatchlistIcon from "../assets/activeWatchlistIcon.svg";
import rotateRight from "../assets/rotateRight.svg";
import shoppingCartLarger from "../assets/shoppingCartLarger.svg";
import circledCheck from "../assets/circledCheck.svg";
import carfaxLogo from "../assets/carfaxLogo.svg";
import autoCheckLogo from "../assets/autoCheckLogo.svg";

import CarReport from "../components/CarReport";
import AutoCheckReport from "../components/AutoCheckReport";
import GroundingInformationDisplay from "../components/GroundingInformationDisplay";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import * as WatchListServices from "../services/WatchListServices";
import * as GroundingServices from "../services/GroundingServices";
import * as userAccess from "../services/UserRoles";

import { watchlist_count } from "../actions/carActions";
import {
  CALL_ADESA_SUPPORT,
  PAYOFF_PRICE_NOT_AVAILABLE,
} from "../constants/UILabel";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  payOffPrice: {
    color: "red",
  },
  topDisplay: {
    display: "flex",
    flexDirection: "column",
    minHeight: "7rem",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "98%",
      paddingLeft: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      width: "100%",
    },
  },
  upperWrapper: {
    width: "99%",
    marginBottom: ".25rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 0,
    },
  },
  lowerWrapper: {
    display: "flex",
    height: "50%",
    width: "100%",
    marginLeft: "4rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      display: "block",
      flexDirection: "column",
    },
  },
  companyCarDisclaimer: {
    textAlign: "center",
    color: "#f44336",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      margin: ".5rem",
    },
  },
  lowerWrapperBalloon: {
    display: "flex",
    height: "50%",
    width: "100%",
    marginLeft: "28rem",
    alignItems: "self-end",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  rightLowerWrapper: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginRight: "2rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  leftLowerWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    justifyContent: "left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  gridItem: {
    padding: "20px",
    textAlign: "left",
    width: "70%",
  },
  gridButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "60%",
    alignSelf: "flex-end",
    marginRight: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  gridButtonWrapperMob: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      margin: 0,
      flexDirection: "column",
    },
  },
  gridButtonWrapperBalloon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginRight: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  gridButtonWrapperBalloonMob: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      margin: 0,
      flexDirection: "column",
    },
  },
  topButtonWrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  gridButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "80%",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-evenly",
    marginTop: "1rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  upperButtonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    marginTop: "1rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  upperButtonWrapperMob: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "100%",
      justifyContent: "space-evenly",
      marginTop: ".5rem",
      "@supports (-ms-ime-align:auto)": {
        justifyContent: "space-around",
      },
    },
  },
  lowerButtonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    marginTop: "1rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  groundHomeButtonWrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
      "@supports (-ms-ime-align:auto)": {
        justifyContent: "space-around",
      },
    },
  },
  caLienButtonWrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
      marginTop: ".5rem",
      "@supports (-ms-ime-align:auto)": {
        justifyContent: "space-around",
      },
    },
  },
  lowerButtonWrapperMob: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "space-evenly",
      marginTop: ".5rem",
      marginBottom: ".5rem",
      "@supports (-ms-ime-align:auto)": {
        justifyContent: "space-around",
      },
    },
  },
  button: {
    marginTop: "15px",
    marginLeft: "2rem",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("sm")]: {
      margin: ".5rem",
    },
  },
  toDoButton: {
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2rem",
    boxShadow: "none",
    width: "9rem",
    textTransform: "initial",
    fontSize: ".6rem",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      height: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "9rem",
      height: "3rem",
    },
  },
  toDoButtonPass: {
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.pass,
    borderRadius: 0,
    height: "2rem",
    boxShadow: "none",
    width: "9rem",
    textTransform: "initial",
    fontSize: ".6rem",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      height: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "9rem",
      height: "3rem",
    },
  },
  toDoButtonGuarantee: {
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.guaranteedPayment,
    borderRadius: 0,
    height: "2rem",
    boxShadow: "none",
    width: "9rem",
    textTransform: "initial",
    fontSize: ".6rem",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      height: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "9rem",
      height: "3rem",
    },
  },
  cscButton: {
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2rem",
    boxShadow: "none",
    width: "10rem",
    textTransform: "initial",
    fontSize: ".6rem",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      height: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "9rem",
      height: "3rem",
    },
  },
  logoBtn: {
    paddingLeft: "0.5rem",
    paddingRight: ".5rem",
    border: "1px solid #dddddd",
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  thanksHeader: {
    backgroundColor: theme.palette.grounding.main,
    borderRadius: "1rem 1rem 0 0",
    fill: theme.palette.grounding.main,
    color: theme.palette.primary.white,
    fontSize: "1rem",
    fontWeight: "bold",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tableWrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  infoWrapper: {
    width: "22rem",
    [theme.breakpoints.down("md")]: {
      width: "18rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".75rem",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    paddingBottom: ".2rem",
  },
  bolded: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.primary.gray,
  },
  boldedColor: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.grounding.main,
  },
  pricingInfoHeader: {
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.primary.gray,
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.grounding.main,
      fontSize: "1rem",
      margin: ".5rem",
    },
  },
  vehicleInfoHeader: {
    fontSize: "1.5rem",
    color: theme.palette.primary.gray,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      margin: ".5rem",
      color: theme.palette.grounding.main,
      fontSize: "1rem",
    },
  },
  redForColor: {
    color: "#f44336",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      margin: ".5rem",
    },
  },
  message: {
    textAlign: "center",
    color: theme.palette.grounding.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      margin: ".5rem",
    },
  },
  groundingInformationTableMob: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
    },
  },
  tableBody: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tableRow: {
    height: "1rem",
    [theme.breakpoints.down("sm")]: {
      height: "1.6rem",
      width: "100%",
      justifyContent: "space-evenly",
      display: "flex",
      overflowY: "auto",
      "@supports (-ms-ime-align:auto)": {
        justifyContent: "space-around",
      },
    },
  },
  tableRowDark: {
    height: "1rem",
    [theme.breakpoints.down("sm")]: {
      height: "1.6rem",
      width: "100%",
      justifyContent: "space-evenly",
      display: "flex",
      backgroundColor: "#eef0f2",
      overflowY: "auto",
      "@supports (-ms-ime-align:auto)": {
        justifyContent: "space-around",
      },
    },
  },
  tableRowDarkDate: {
    height: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-evenly",
      display: "flex",
      backgroundColor: "#eef0f2",
      height: ".8rem",
      overflowY: "auto",
      "@supports (-ms-ime-align:auto)": {
        justifyContent: "space-around",
      },
    },
  },
  tableCell: {
    borderBottom: 0,
    width: "15rem",
    padding: 0,
    color: theme.palette.primary.gray,
    height: "auto !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      height: "1.5rem",
      width: "100%",
      justifyContent: "space-between",
      padding: "0 !important",
      alignItems: "center",
    },
  },
  tableCellDate: {
    borderBottom: 0,
    width: "15rem",
    padding: 0,
    color: theme.palette.primary.gray,
    height: "auto !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      height: ".8rem",
      width: "100%",
      justifyContent: "space-between",
      padding: "0 !important",
      alignItems: "center",
    },
  },
  tableLabel: {
    textAlign: "left",
    paddingLeft: ".5rem",
    borderLeft: `.5rem solid ${theme.palette.primary.gray}`,
    lineHeight: "1.6rem",
    fontWeight: "600",
  },
  tableLabelDate: {
    textAlign: "left",
    paddingLeft: ".5rem",
    borderLeft: `.5rem solid ${theme.palette.primary.gray}`,
    lineHeight: ".8rem",
    fontSize: ".5rem",
  },
  tableData: {
    textAlign: "right",
    paddingRight: ".5rem",
  },
  goodThroughWrapper: {
    width: "100%",
  },
  watchlistIconWrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  watchlistIconButton: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "2.5rem",
      minWidth: 0,
    },
  },
});

export class GroundingConfirmation extends Component {
  state = {
    showCarFaxReport: false,
    showAutoCheckReport: false,
    isInspectionNoteVisible: false,
    isInspectionNoteForBalloonVehicleVisible: false,
    isInspectionNoteForIneligibleVehicleVisible: false,
    isResidualMarketPriceNoteVisible: false,
    isPayoffPriceVisible: false,
    isResidualPriceVisible: false,
    isMarketPriceVisible: false,
    isRemainingPaymentsVisible: false,
    isAdminFeeVisible: false,
    isAttentionMsgVisible: false,
    isBuyBtnVisible: false,
    isBuyThroughCSCBtnVisible: false,
    isPassBtnVisible: false,
    isGuaranteeVisible: false,
    isAddToWatchListBtnVisible: false,
    isInspectionNoteForRepoVehicleVisible: false,
    hadGuaranteedPayments: false,
    showCarfaxSnackBar: false,
  };

  componentDidMount = () => {
    const { odoPDFDownloaded, togglePDFDownloaded } = this.props;
    this.isVehicleEligibleForPurchase();
    if (this.props.finalGroundedVehicleData && !odoPDFDownloaded) {
      togglePDFDownloaded();
      if (this.props.finalGroundedVehicleData.odometerInformationDTO) {
        GroundingServices.downloadPdf(
          this.props.finalGroundedVehicleData.odometerInformationDTO.odometerPdf
        );
      } else {
        console.log("There was no Odometer Information in the response");
      }
    }
  };

  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  isVehicleEligibleForPurchase = () => {
    const { odometerStatementCodeId } = this.props.finalGroundedVehicleData;
    const { accountType, bankRuptcyCode, termType, leaseType } =
      this.props.finalGroundedVehicleData.inventoryDTO.leaseInformationDTO;

    if (accountType === "BALLOON") {
      // Balloon Vehicle
      this.setState(() => {
        return {
          isInspectionNoteForBalloonVehicleVisible: true,
          isAttentionMsgVisible: true,
          isBuyThroughCSCBtnVisible: true,
          isPassBtnVisible: true,
        };
      });
    } else if (
      bankRuptcyCode === "A" ||
      bankRuptcyCode === "B" ||
      bankRuptcyCode === "C" ||
      bankRuptcyCode === "D"
    ) {
      // Bankruptcy VIN
      this.setState(() => {
        return {
          isInspectionNoteForIneligibleVehicleVisible: true,
          isPayoffPriceVisible: true,
          isResidualPriceVisible: true,
        };
      });
    } else if (
      odometerStatementCodeId === 114 ||
      odometerStatementCodeId === 115
    ) {
      // Ineligible Odo
      this.setState(() => {
        return {
          isInspectionNoteForIneligibleVehicleVisible: true,
          isPayoffPriceVisible: true,
          isResidualPriceVisible: true,
        };
      });
    } else if (
      termType &&
      termType !== null &&
      (termType.id === 99 || termType.id === 104 || termType.id === 27146)
    ) {
      // Repo Vehicles
      // Term Type 99 => Involuntary Repossession - IR
      // Term Type 104 => Voluntary Repossession - VR
      // Term Type 27146 => Voluntary Term - VT
      this.setState(() => {
        return {
          isInspectionNoteForRepoVehicleVisible: true,
          isPayoffPriceVisible: true,
          isResidualPriceVisible: true,
          isRemainingPaymentsVisible: true,
        };
      });
    } else if (leaseType === "02") {
      // Lease type 'Open'
      this.setState(() => {
        return {
          isInspectionNoteForIneligibleVehicleVisible: true,
          isPayoffPriceVisible: true,
          isResidualPriceVisible: true,
          isRemainingPaymentsVisible: true,
        };
      });
    } else {
      // Default
      this.setState(() => {
        return {
          isInspectionNoteVisible: true,
          isResidualMarketPriceNoteVisible: true,
          isPayoffPriceVisible: true,
          isResidualPriceVisible: true,
          isMarketPriceVisible: true,
          isRemainingPaymentsVisible: true,
          isAdminFeeVisible: true,
          isBuyBtnVisible: true,
          isPassBtnVisible: true,
          isGuaranteeVisible: true,
          isAddToWatchListBtnVisible: true,
        };
      });
    }
  };

  handleClick = (name) => (event) => {
    const { history } = this.props;
    if (name === "goToGround") {
      history.push("/ground");
    }
    if (name === "goToGAMD") {
      history.push("/GAMD");
    }
    if (name === "goToDDO") {
      history.push("/DDO");
    }
    if (name === "goToCALien") {
      history.push("https://www.dmv.ca.gov/portal/dmv");
    }
    if (name === "goToHome") {
      history.push("/home");
    }
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  showAutoCheckReport = () => {
    this.setState({
      showAutoCheckReport: !this.state.showAutoCheckReport,
    });
  };

  toggleGuaranteePayment = () => {
    this.setState({
      guaranteePayment: !this.state.guaranteePayment,
    });
  };

  toggleGuaranteePaymentDone = () => {
    this.setState({
      guaranteePayment: !this.state.guaranteePayment,
      userHasGuaranteedPayments: !this.state.userHasGuaranteedPayments,
    });
  };

  getCarInfoForGuaranteePayments = (finalGroundedVehicleData) => {
    const { saleEventItemId, dealerId } = finalGroundedVehicleData;
    const { make, model, vehicleYear, vehicleIdentificationNumber, id } =
      finalGroundedVehicleData.inventoryDTO;
    const { mileage } = finalGroundedVehicleData.odometerInformationDTO;
    const {
      remainingPaymentAmount,
      residualPayAmount,
      grossPayAmount,
      marketBasedPrice,
    } = finalGroundedVehicleData.inventoryDTO.leaseInformationDTO;
    const car = {
      id: id,
      year: vehicleYear,
      make: make,
      model: model,
      vehicleIdentificationNumber: vehicleIdentificationNumber,
      remainingPaymentsAmt: remainingPaymentAmount,
      saleEventItemId: saleEventItemId,
      groundingMileage: mileage,
      residualAmount: residualPayAmount,
      grossPayoff: grossPayAmount,
      marketBasedPrice: marketBasedPrice,
      remainingPayments: remainingPaymentAmount,
      groundingDealerId: dealerId,
    };
    return car;
  };

  loadWatchlistData = async () => {
    const { user, dispatch } = this.props;
    const watchListCars = await WatchListServices.getSavedWatchlist(user);
    if (watchListCars && watchListCars.length !== undefined) {
      dispatch(watchlist_count(watchListCars.length));
    }
  };

  render() {
    const {
      showCarfaxReport,
      showAutoCheckReport,
      isInspectionNoteVisible,
      isInspectionNoteForBalloonVehicleVisible,
      isInspectionNoteForIneligibleVehicleVisible,
      isAttentionMsgVisible,
      isBuyBtnVisible,
      isPassBtnVisible,
      isGuaranteeVisible,
      isAddToWatchListBtnVisible,
      isInspectionNoteForRepoVehicleVisible,
      isBuyThroughCSCBtnVisible,
      showCarfaxSnackBar,
    } = this.state;
    const {
      classes,
      accountSetting,
      userBoughtVehicle,
      toggleBuyVehicle,
      togglePassVehicle,
      userHasGuaranteedPayments,
      toggleGuaranteePayment,
      userPassedVehicle,
      user,
      fullVinDetails,
      resetState,
      finalGroundedVehicleData,
      addedToWatchlist,
      handleAddToWatchList,
      confirmError,
      toggleError,
      groundInfo,
    } = this.props;
    const { accountType } =
      this.props.finalGroundedVehicleData.inventoryDTO.leaseInformationDTO;
    const { adminFee, balloonVehicle } = this.props.finalGroundedVehicleData;
    const { make, model, vehicleYear, vehicleIdentificationNumber } =
      this.props.finalGroundedVehicleData.inventoryDTO;
    const {
      residualPayAmount,
      remainingPaymentAmount,
      grossPayAmount,
      payOutDate,
    } = this.props.finalGroundedVehicleData.inventoryDTO.leaseInformationDTO;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.upperWrapper}>
            <Typography variant="h5" className={classes.thanksHeader}>
              Thank you for grounding this vehicle. It is now listed in the
              "Grounded At My Dealership" section.
            </Typography>
            <Paper className={classes.topDisplay}>
              <div className={classes.tableWrapper}>
                <div className={classes.watchlistIconWrapper}>
                  <Typography className={classes.vehicleInfoHeader}>
                    {`${make} ${model} ${vehicleYear}`}
                  </Typography>
                  {!userBoughtVehicle ? (
                    <Button
                      id="watchListBtn"
                      className={classes.watchlistIconButton}
                      onClick={() => handleAddToWatchList()}
                    >
                      <ReactSVG
                        src={
                          addedToWatchlist ? activeWatchlistIcon : watchlistIcon
                        }
                      />
                    </Button>
                  ) : null}
                </div>
                <GroundingInformationDisplay
                  finalGroundedVehicleData={finalGroundedVehicleData}
                  from={"groundingConfirmation"}
                  originalleaseType={
                    fullVinDetails.inventoryDTO.leaseInformationDTO.leaseType
                  }
                  vinDetails={this.props.finalGroundedVehicleData}
                  groundInfo={groundInfo}
                />
              </div>
            </Paper>
          </div>
          <div
            className={
              balloonVehicle
                ? classes.lowerWrapperBalloon
                : classes.lowerWrapper
            }
          >
            {balloonVehicle ? null : (
              <div className={classes.leftLowerWrapper}>
                <Typography className={classes.pricingInfoHeader}>
                  Vehicle Information
                </Typography>
                {!grossPayAmount ? (
                  <div className={classes.payOffPrice}>
                    {PAYOFF_PRICE_NOT_AVAILABLE} <br />
                    {CALL_ADESA_SUPPORT}
                  </div>
                ) : null}
                <div className={classes.infoWrapper}>
                  <div className={classes.lineWrapper}>
                    <Typography className={classes.bolded}>
                      Payoff Price (Good through{" "}
                      {moment(payOutDate).format("MM/DD/YYYY")})
                    </Typography>
                    <Typography className={classes.boldedColor}>
                      {grossPayAmount ? (
                        grossPayAmount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      ) : (
                        <div style={{ color: "black" }}>Pending</div>
                      )}
                    </Typography>
                  </div>
                  <div className={classes.lineWrapper}>
                    <Typography className={classes.bolded}>
                      Residual Price
                    </Typography>
                    <Typography className={classes.boldedColor}>
                      {residualPayAmount &&
                        residualPayAmount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                    </Typography>
                  </div>
                  <div className={classes.lineWrapper}>
                    <Typography className={classes.bolded}>
                      Market Price
                    </Typography>
                    <Typography className={classes.boldedColor}>
                      Awaiting Inspection
                    </Typography>
                  </div>
                  <div className={classes.lineWrapper}>
                    <Typography className={classes.bolded}>
                      Administrative Fee
                    </Typography>
                    <Typography className={classes.boldedColor}>
                      {adminFee &&
                        adminFee.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                    </Typography>
                  </div>
                </div>
                <Table className={classes.groundingInformationTableMob}>
                  <TableBody className={classes.tableBody}>
                    <TableRow className={classes.tableRowDark}>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.tableLabel}>Payoff Price</div>
                        <div className={classes.tableData}>
                          {grossPayAmount &&
                            grossPayAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRowDarkDate}>
                      <TableCell className={classes.tableCellDate}>
                        <div className={classes.tableLabelDate}>
                          (Good through{" "}
                          {moment(payOutDate).format("MM/DD/YYYY")})
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.tableLabel}>Residual Price</div>
                        <div className={classes.tableData}>
                          {residualPayAmount &&
                            residualPayAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRowDark}>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.tableLabel}>Market Price</div>
                        <div className={classes.tableData}>
                          Awaiting Inspection
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.tableLabel}>
                          Administrative Fee
                        </div>
                        <div className={classes.tableData}>
                          {adminFee &&
                            adminFee.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            )}
            <div className={classes.rightLowerWrapper}>
              {isAttentionMsgVisible ? (
                <Typography variant="subtitle2" className={classes.redForColor}>
                  Attention: Balloon vehicles will be removed from Dealer Direct
                  upon vehicle inspection completion. Passing on Balloon
                  vehicles will also remove the listing from Dealer Direct.
                </Typography>
              ) : null}
              {isAttentionMsgVisible ? (
                <Typography variant="subtitle2" className={classes.redForColor}>
                  If you choose to Buy this vehicle in Dealer Direct, you will
                  be directed to CSC fof the vehicle purchase as it cannot be
                  completed in Dealer Direct.
                </Typography>
              ) : null}
              {isInspectionNoteVisible ? (
                <Typography variant="h6" className={classes.message}>
                  Inspection has been ordered. Market Price has been requested.
                </Typography>
              ) : null}
              {accountType && accountType === "COMPANY CAR" ? (
                <Typography
                  variant="subtitle2"
                  className={classes.companyCarDisclaimer}
                >
                  *Attention: This Company Car vehicle is only available to
                  purchase at Residual or Payoff price. Once the inspection is
                  completed, the vehicle will be available to purchase on a Buy
                  Now/Bid Now venue.
                </Typography>
              ) : null}
              {isInspectionNoteForBalloonVehicleVisible ? (
                <Typography variant="subtitle2" className={classes.message}>
                  Inspection has been ordered. Balloon vehicles are not
                  available for purchase online via Dealer Direct. Please
                  contact the CSC to inquire about purchasing this vehicle.
                </Typography>
              ) : null}
              {isInspectionNoteForIneligibleVehicleVisible ? (
                <Typography variant="h6" className={classes.redForColor}>
                  This vehicle is not eligible for purchase through Dealer
                  Direct and must be sold at auction due to legal or contractual
                  obligation. Transportation to auction will be ordered
                  immediately.
                </Typography>
              ) : null}
              {isInspectionNoteForRepoVehicleVisible ? (
                <Typography variant="h6" className={classes.message}>
                  This vehicle is not eligible for purchase through Dealer
                  Direct and must be sold at auction due to legal or contractual
                  obligations. The CSC will make arrangements for transportation
                  to auction immediately.
                </Typography>
              ) : null}
              <div
                className={
                  balloonVehicle
                    ? classes.gridButtonWrapperBalloon
                    : classes.gridButtonWrapper
                }
              >
                <ReactSVG
                  id="carfaxLink"
                  className={classes.logoBtn}
                  src={carfaxLogo}
                  alt="carfax"
                  onClick={() => this.showCarfaxReport()}
                  style={{ backgroundColor: "#efefef" }}
                  svgStyle={{
                    width: "7.5rem",
                    height: "3rem",
                    marginTop: "-4px",
                  }}
                />
                <ReactSVG
                  id="autoCheckLogo"
                  className={classes.logoBtn}
                  src={autoCheckLogo}
                  alt="autocheck"
                  onClick={() => this.showAutoCheckReport()}
                  svgStyle={{ backgroundColor: "#ffffff", marginTop: "2px" }}
                />
                <img
                  id="kbbLogo"
                  className={classes.logoBtn}
                  style={{ maxWidth: "165px", backgroundColor: "#efefef" }}
                  src="https://media.adesa.com/dealerdirect/tfs/ui/kbb-logo.gif"
                  alt="autocheck"
                  onClick={() =>
                    window.open("https://www.kbb.com/", "_blank", "")
                  }
                />
              </div>
              <div
                className={
                  balloonVehicle
                    ? classes.gridButtonWrapperBalloonMob
                    : classes.gridButtonWrapperMob
                }
              >
                <div className={classes.topButtonWrapper}>
                  <ReactSVG
                    id="carfaxLink"
                    className={classes.button}
                    src={carfaxLogo}
                    alt="carfax"
                    onClick={() => this.showCarfaxReport()}
                    svgStyle={{ width: "5rem", height: "2rem" }}
                  />
                  <ReactSVG
                    id="autoCheckLogo"
                    className={classes.button}
                    src={autoCheckLogo}
                    alt="autocheck"
                    onClick={() => this.showAutoCheckReport()}
                  />
                </div>
                <>
                  <img
                    id="kbbLogo"
                    className={classes.button}
                    style={{ maxWidth: "165px" }}
                    src="https://media.adesa.com/dealerdirect/tfs/ui/kbb-logo.gif"
                    alt="autocheck"
                    onClick={() =>
                      window.open("https://www.kbb.com/", "_blank", "")
                    }
                  />
                </>
              </div>
              <div className={classes.buttonWrapper}>
                <div className={classes.upperButtonWrapper}>
                  {isBuyBtnVisible &&
                  userAccess.canPurchaseVehicles(user.userRoles) ? (
                    <Button
                      id="buyButton"
                      variant="contained"
                      className={classes.toDoButton}
                      disabled={userBoughtVehicle}
                      onClick={(evt) => toggleBuyVehicle()}
                    >
                      Buy This Vehicle
                    </Button>
                  ) : null}
                  {isBuyThroughCSCBtnVisible ? (
                    <Button
                      id="buyButton"
                      variant="contained"
                      className={classes.cscButton}
                      disabled={userBoughtVehicle}
                      onClick={(evt) => toggleBuyVehicle()}
                    >
                      Buy Through the CSC
                    </Button>
                  ) : null}
                  {isPassBtnVisible &&
                  userAccess.canPurchaseVehicles(user.userRoles) ? (
                    <Button
                      id="passButton"
                      variant="contained"
                      className={classes.toDoButton}
                      disabled={
                        userPassedVehicle || userBoughtVehicle ? true : false
                      }
                      onClick={() => togglePassVehicle()}
                    >
                      Pass On This Vehicle
                    </Button>
                  ) : null}
                  {isGuaranteeVisible &&
                  userAccess.canPurchaseVehicles(user.userRoles) ? (
                    <Button
                      id="guaranteeButton"
                      variant="contained"
                      className={classes.toDoButton}
                      disabled={
                        userHasGuaranteedPayments ||
                        remainingPaymentAmount === 0 ||
                        remainingPaymentAmount === null ||
                        remainingPaymentAmount < 0 ||
                        userBoughtVehicle
                      }
                      onClick={() => toggleGuaranteePayment()}
                    >
                      Guarantee Payments
                    </Button>
                  ) : null}
                  {isAddToWatchListBtnVisible &&
                  userAccess.canPurchaseVehicles(user.userRoles) ? (
                    <Button
                      id="watchlistButton"
                      variant="contained"
                      className={classes.toDoButton}
                      onClick={handleAddToWatchList}
                      disabled={addedToWatchlist ? true : false}
                    >
                      Add To Watchlist
                    </Button>
                  ) : null}
                </div>
                <div className={classes.upperButtonWrapperMob}>
                  {isBuyBtnVisible &&
                  userAccess.canPurchaseVehicles(user.userRoles) ? (
                    <Button
                      id="buyButton"
                      variant="contained"
                      className={classes.toDoButton}
                      disabled={userBoughtVehicle ? true : false}
                      onClick={(evt) => toggleBuyVehicle()}
                    >
                      <ReactSVG
                        svgStyle={{
                          height: "1.5rem",
                          width: "1.5rem",
                          marginRight: "1rem",
                        }}
                        src={shoppingCartLarger}
                      />
                      Buy
                    </Button>
                  ) : null}
                  {isBuyThroughCSCBtnVisible === true ? (
                    <Button
                      id="buyButton"
                      variant="contained"
                      className={classes.cscButton}
                      disabled={userBoughtVehicle ? true : false}
                      onClick={(evt) => toggleBuyVehicle()}
                    >
                      <ReactSVG
                        svgStyle={{
                          height: "1.5rem",
                          width: "1.5rem",
                          marginRight: "1rem",
                        }}
                        src={shoppingCartLarger}
                      />
                      Buy Through the CSC
                    </Button>
                  ) : null}
                  {isPassBtnVisible === true &&
                  userAccess.canPurchaseVehicles(user.userRoles) ? (
                    <Button
                      id="passButton"
                      variant="contained"
                      className={classes.toDoButtonPass}
                      disabled={userPassedVehicle || userBoughtVehicle}
                      onClick={() => togglePassVehicle()}
                    >
                      <ReactSVG
                        svgStyle={{
                          height: "1.5rem",
                          width: "1.5rem",
                          marginRight: "1rem",
                        }}
                        src={rotateRight}
                      />
                      Pass
                    </Button>
                  ) : null}
                  {isGuaranteeVisible === true &&
                  userAccess.canPurchaseVehicles(user.userRoles) ? (
                    <Button
                      id="guaranteeButton"
                      variant="contained"
                      className={classes.toDoButtonGuarantee}
                      disabled={
                        userHasGuaranteedPayments ||
                        remainingPaymentAmount === 0 ||
                        remainingPaymentAmount === null ||
                        remainingPaymentAmount < 0 ||
                        userBoughtVehicle
                      }
                      onClick={() => toggleGuaranteePayment()}
                    >
                      <ReactSVG
                        svgStyle={{
                          position: "relative",
                          top: ".25rem",
                          marginRight: ".25rem",
                          height: "1.5rem",
                          width: "1.5rem",
                        }}
                        src={circledCheck}
                      />
                      Guarantee
                    </Button>
                  ) : null}
                </div>
                <div className={classes.lowerButtonWrapper}>
                  {accountSetting.state === "CA" &&
                  userAccess.canPurchaseVehicles(user.userRoles) ? (
                    <Button
                      id="CA_lien"
                      variant="contained"
                      className={classes.toDoButton}
                      onClick={() =>
                        window.open(
                          "https://www.dmv.ca.gov/portal/dmv",
                          "_blank",
                          ""
                        )
                      }
                    >
                      California Lien Release
                    </Button>
                  ) : null}
                  <Button
                    id="homeButton"
                    variant="contained"
                    className={classes.toDoButton}
                    onClick={this.handleClick("goToHome")}
                  >
                    Home Page
                  </Button>
                </div>
                <div className={classes.lowerButtonWrapperMob}>
                  <div className={classes.groundHomeButtonWrapper}>
                    <Button
                      id="homeButton"
                      variant="contained"
                      className={classes.toDoButton}
                      onClick={() => resetState()}
                    >
                      Ground Another Vehicle
                    </Button>
                    <Button
                      id="homeButton"
                      variant="contained"
                      className={classes.toDoButton}
                      onClick={this.handleClick("goToHome")}
                    >
                      Home Page
                    </Button>
                  </div>
                  {accountSetting.state === "CA" &&
                  userAccess.canPurchaseVehicles(user.userRoles) ? (
                    <div className={classes.caLienButtonWrapper}>
                      <Button
                        id="CA_lien"
                        variant="contained"
                        className={classes.toDoButton}
                        onClick={() =>
                          window.open(
                            "https://www.dmv.ca.gov/portal/dmv",
                            "_blank",
                            ""
                          )
                        }
                      >
                        California Lien Release
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {showAutoCheckReport ? (
            <AutoCheckReport
              vin={vehicleIdentificationNumber}
              reportType="autoCheck"
              showAutoCheckReport={this.showAutoCheckReport}
              user={user}
            />
          ) : null}
          {showCarfaxReport ? (
            <CarReport
              user={user}
              vin={vehicleIdentificationNumber}
              reportType="carfax"
              showCarfaxReport={this.showCarfaxReport}
              showNoDataVin={this.showNoDataVin}
            />
          ) : null}
          {showCarfaxSnackBar ? (
            <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
          ) : null}
        </div>
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={confirmError}
          autoHideDuration={2000}
          className={classes.errorSnackBar}
          onClose={() => toggleError("confirmError", null)}
        >
          <SnackbarContent
            className={classes.errorMessage}
            message={confirmError}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

GroundingConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(GroundingConfirmation)
);
