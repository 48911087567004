import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { Typography, Button } from "@material-ui/core";

import { set_nav_location, hide_mobile_nav_menu } from "../actions/uiActions";

import ReactSVG from "react-svg";
import phoneIcon from "../assets/phone.svg";
import emailIcon from "../assets/emailIcon.svg";
import infoIcon from "../assets/infoIcon.svg";
import PDFIcon from "../assets/PDFIcon.svg";
import playIcon from "../assets/playIcon.svg";
import linkIcon from "../assets/linkIcon.svg";

import Loading from "../components/Loading";

import * as TDDStaticContentServices from "../services/TDDStaticContentServices";

import * as LDDStaticContentServices from "../services/LDDStaticContentServices";

const styles = (theme) => ({
  container: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    marginBottom: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem",
      display: "grid",
      marginTop: "3.5rem",
    },
  },
  headerWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginLeft: "2rem",
    marginTop: "2vh",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginLeft: ".5rem",
      width: "95%",
    },
  },
  headerText: {
    fontSize: "2rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  contentWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    height: "70%",
    marginLeft: "1rem",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      display: "block",
      marginLeft: ".5rem",
      marginBottom: ".5rem",
    },
  },
  contentText: {
    fontSize: ".8rem",
    color: theme.palette.primary.gray,
  },
  leftContent: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    marginTop: "1rem",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 0,
    },
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    marginTop: "1rem",
    height: "89%",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      height: "12rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  rightContent: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    marginTop: "1rem",
    height: "89%",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 0,
    },
  },
  columnTitle: {
    fontWeight: "bold",
    fontSize: ".8rem",
    color: theme.palette.primary.gray,
  },
  columnTitleUser: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      fontWeight: "bold",
      fontSize: ".8rem",
      color: theme.palette.primary.gray,
      marginTop: ".5rem",
    },
  },
  overflowWrapper: {
    overflowY: "auto",
  },
  lowerWrapper: {
    borderTop: `1px solid ${theme.palette.primary.gray}`,
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      width: "95%",
      marginLeft: ".5rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginLeft: 0,
    },
  },
  lowerContent: {
    display: "flex",
    flexDirection: "column",
    width: "22rem",
    height: "12rem",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginLeft: ".5rem",
      height: "fit-content",
      marginBottom: ".5rem",
    },
  },
  phoneNumberWrapper: {
    display: "flex",
    margin: ".25rem",
    color: theme.palette.primary.redHighlight,
  },
  emailWrapper: {
    display: "flex",
    margin: ".25rem",
    marginLeft: "1rem",
    color: theme.palette.primary.redHighlight,
    borderBottom: `1px solid ${theme.palette.primary.redHighlight}`,
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  DDOContactWrapper: {
    display: "flex",
  },
  phoneText: {
    color: theme.palette.primary.redHighlight,
    marginLeft: ".5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
  pageHeader: {
    height: "3rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    width: "70%",
    marginTop: ".5rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobilePageHeader: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  selectedMobileButton: {
    height: "100%",
    width: "50%",
    backgroundColor: theme.palette.secondary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.gray,
    },
  },
  mobileButton: {
    height: "100%",
    width: "50%",
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
  },
  titleWrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    color: theme.palette.primary.gray,
  },
  titleTextWrapper: {
    borderBottom: `5px solid ${theme.palette.primary.gray}`,
    height: "100%",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
    width: "2.1rem",
    marginLeft: "1rem",
    alignItems: "center",
  },
  titleText: {
    color: theme.palette.primary.gray,
    fontSize: ".8rem",
    width: "2rem",
    marginBottom: ".5rem",
    fontWeight: "bold",
  },
  infoIcon: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "1rem",
    paddingTop: ".25rem",
  },
  navButton: {
    height: "1.5rem",
    lineHeight: 0,
    padding: 0,
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
  },
  buttonWrapper: {
    marginLeft: "3rem",
    width: "18rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  buttonText: {
    marginRight: ".5rem",
  },
  noButtonUnderline: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "5px solid transparent",
  },
  buttonUnderline: {
    borderBottom: `5px solid ${theme.palette.primary.gray}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  clickableLink: {
    display: "flex",
    alignItems: "center",
    minHeight: "3rem",
    "&:hover": {
      opacity: ".5",
      cursor: "pointer",
    },
  },
  linkIcon: {
    color: theme.palette.primary.gray,
    marginRight: ".5rem",
  },
  PDFIcon: {
    color: theme.palette.primary.redHighlight,
    marginRight: ".5rem",
  },
  playIcon: {
    color: theme.palette.primary.redHighlight,
    marginRight: ".5rem",
  },
  promotionPlaceholder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    fontWeight: "bold",
    color: theme.palette.primary.gray,
    fontSize: ".8rem",
  },
  centerLoading: {
    display: "flex",
    height: "100%",
    widht: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});

export class Help extends Component {
  state = {
    view: "newUser",
    links: [],
    videos: [],
    documents: [],
    newUserDocuments: [],
    promotions: [],
    loading: true,
  };

  componentWillMount = () => {
    this.fetchContent();
  };

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(set_nav_location("help"));
    dispatch(hide_mobile_nav_menu(true));
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    dispatch(hide_mobile_nav_menu(false));
  };

  fetchContent = async () => {
    const { pageTheme } = this.props;
    const helpPage =
      pageTheme !== "Lexus"
        ? await TDDStaticContentServices.helpPageContent()
        : await LDDStaticContentServices.helpPageContent();
    const resourcePage =
      pageTheme !== "Lexus"
        ? await TDDStaticContentServices.resourcePageContent()
        : await LDDStaticContentServices.resourcePageContent();
    this.setState({
      newUserDocuments: helpPage.newUserDocument,
      videos: resourcePage.resourcesVideo,
      links: resourcePage.resourcesLink,
      documents: resourcePage.resourcesDocument,
      loading: false,
    });
  };

  changeView = (view) => {
    this.setState({
      view,
    });
  };

  openLink = (location) => {
    return window.open(location, "_blank");
  };

  render() {
    const { classes } = this.props;
    const {
      view,
      documents,
      videos,
      links,
      newUserDocuments,
      promotions,
      loading,
    } = this.state;
    const awsUrl = process.env.REACT_APP_WCM_CONTENT;
    return (
      <div className={classes.container}>
        <div className={classes.pageHeader}>
          <div className={classes.titleWrapper}>
            <ReactSVG
              className={classes.infoIcon}
              src={infoIcon}
              svgStyle={{ fill: "currentColor" }}
            />
            <div className={classes.titleTextWrapper}>
              <Typography className={classes.titleText}>Help</Typography>
            </div>
          </div>
          <div className={classes.buttonWrapper}>
            <div
              className={
                view === "newUser"
                  ? classes.buttonUnderline
                  : classes.noButtonUnderline
              }
            >
              <Button
                id={"newUser"}
                className={classes.navButton}
                variant="outlined"
                onClick={() => this.changeView("newUser")}
              >
                <Typography>New Users</Typography>
              </Button>
            </div>
            <div
              className={
                view === "resources"
                  ? classes.buttonUnderline
                  : classes.noButtonUnderline
              }
            >
              <Button
                id={"resources"}
                className={classes.navButton}
                variant="outlined"
                onClick={() => this.changeView("resources")}
              >
                <Typography>Resources</Typography>
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.mobilePageHeader}>
          <Button
            id={"mobilenNewUser"}
            className={
              view === "newUser"
                ? classes.selectedMobileButton
                : classes.mobileButton
            }
            variant="outlined"
            onClick={() => this.changeView("newUser")}
          >
            <Typography className={classes.mobileButtonText}>
              New Users
            </Typography>
          </Button>
          <Button
            id={"mobilepassword"}
            className={
              view === "resources"
                ? classes.selectedMobileButton
                : classes.mobileButton
            }
            variant="outlined"
            onClick={() => this.changeView("resources")}
          >
            <Typography className={classes.mobileButtonText}>
              Resources
            </Typography>
          </Button>
        </div>
        {view === "newUser" ? (
          <React.Fragment>
            <div className={classes.headerWrapper}>
              <Typography className={classes.headerText}>
                New User Start Here
              </Typography>
              <Typography className={classes.contentText}>
                Get a brief overview to get you up to speed quickly
              </Typography>
            </div>
            <div className={classes.contentWrapper}>
              <Typography className={classes.columnTitleUser}>
                Documents
              </Typography>
              <div className={classes.leftContent}>
                {!loading ? (
                  newUserDocuments.map((document) => {
                    return (
                      <div
                        key={document.title}
                        className={classes.clickableLink}
                        onClick={() =>
                          this.openLink(`${awsUrl}/${document.link}`)
                        }
                      >
                        <ReactSVG
                          className={classes.PDFIcon}
                          src={PDFIcon}
                          svgStyle={{ fill: "currentColor" }}
                        />
                        <Typography className={classes.contentText}>
                          {document.title}
                        </Typography>
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.centerLoading}>
                    <Loading />
                  </div>
                )}
              </div>
              <Typography className={classes.columnTitleUser}>
                Videos
              </Typography>
              <div className={classes.rightContent}>
                {!loading ? (
                  videos.map((video) => {
                    return (
                      <div
                        key={video.title}
                        className={classes.clickableLink}
                        onClick={() => this.openLink(video.link)}
                      >
                        <ReactSVG
                          className={classes.playIcon}
                          src={playIcon}
                          svgStyle={{ fill: "currentColor" }}
                        />
                        <Typography className={classes.contentText}>
                          {video.title}
                        </Typography>
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.centerLoading}>
                    <Loading />
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : null}
        {view === "resources" ? (
          <React.Fragment>
            <div className={classes.headerWrapper}>
              <Typography className={classes.headerText}>Resources</Typography>
            </div>
            <div className={classes.contentWrapper}>
              <div className={classes.leftContent}>
                <Typography className={classes.columnTitle}>
                  Promotions
                </Typography>
                {promotions.length > 0 ? (
                  promotions.map((promotion) => {
                    return (
                      <div
                        key={promotion.title}
                        className={classes.clickableLink}
                        onClick={() =>
                          this.openLink(`${awsUrl}/${promotion.link}`)
                        }
                      >
                        <ReactSVG
                          className={classes.playIcon}
                          src={PDFIcon}
                          svgStyle={{ fill: "currentColor" }}
                        />
                        <Typography className={classes.contentText}>
                          {promotion.title}
                        </Typography>
                      </div>
                    );
                  })
                ) : (
                  <Typography className={classes.promotionPlaceholder}>
                    No Promotions Available at This Time
                  </Typography>
                )}
              </div>
              <div className={classes.centerContent}>
                <Typography className={classes.columnTitle}>
                  Documents
                </Typography>
                {!loading ? (
                  videos.map((video) => {
                    return (
                      <div
                        key={video.title}
                        className={classes.clickableLink}
                        onClick={() => this.openLink(video.link)}
                      >
                        <ReactSVG
                          className={classes.playIcon}
                          src={playIcon}
                          svgStyle={{ fill: "currentColor" }}
                        />
                        <Typography className={classes.contentText}>
                          {video.title}
                        </Typography>
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.centerLoading}>
                    <Loading />
                  </div>
                )}
                {!loading ? (
                  documents.map((document) => {
                    return (
                      <div
                        key={document.title}
                        className={classes.clickableLink}
                        onClick={() =>
                          this.openLink(`${awsUrl}/${document.link}`)
                        }
                      >
                        <ReactSVG
                          className={classes.PDFIcon}
                          src={PDFIcon}
                          svgStyle={{ fill: "currentColor" }}
                        />
                        <Typography className={classes.contentText}>
                          {document.title}
                        </Typography>
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.centerLoading}>
                    <Loading />
                  </div>
                )}
              </div>
              <div className={classes.rightContent}>
                <Typography className={classes.columnTitle}>Links</Typography>
                {!loading ? (
                  links.map((link) => {
                    return (
                      <div
                        key={link.title}
                        className={classes.clickableLink}
                        onClick={() => this.openLink(link.link)}
                      >
                        <ReactSVG
                          className={classes.linkIcon}
                          src={linkIcon}
                          svgStyle={{ fill: "currentColor" }}
                        />
                        <Typography className={classes.contentText}>
                          {link.title}
                        </Typography>
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.centerLoading}>
                    <Loading />
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <div className={classes.lowerWrapper}>
          <div className={classes.lowerContent}>
            <Typography className={classes.headerText}>
              Get Help Here
            </Typography>
            <Typography className={classes.contentText}>
              Contact Dealer Direct Support by Telephone
            </Typography>
            <Typography className={classes.contentText}>
              Monday through Friday 9:00am - 8:00pm EST
            </Typography>
            <div className={classes.DDOContactWrapper}>
              <div className={classes.phoneNumberWrapper}>
                <ReactSVG
                  src={phoneIcon}
                  svgStyle={{ fill: "currentColor", height: "1.5rem" }}
                />
                <Typography className={classes.phoneText}>
                  877-522-3372
                </Typography>
              </div>
              <div
                className={classes.emailWrapper}
                onClick={() =>
                  (window.location = "mailto:customerserviceusa@openlane.com")
                }
              >
                <ReactSVG
                  src={emailIcon}
                  svgStyle={{ height: "1.5rem", fill: "currentColor" }}
                />
                <Typography className={classes.phoneText}>Email Us</Typography>
              </div>
            </div>
            <Typography className={classes.contentText}>
              Questions regarding outstanding transportation - CarsArrive
            </Typography>
            <div className={classes.phoneNumberWrapper}>
              <ReactSVG src={phoneIcon} svgStyle={{ height: "1.5rem" }} />
              <Typography className={classes.phoneText}>
                1-866-457-2277
              </Typography>
            </div>
          </div>
          <div className={classes.lowerContent}>
            <Typography className={classes.headerText}>Contact List</Typography>
            <Typography className={classes.contentText}>
              Key Contact Information in one convenient location
            </Typography>
          </div>
          <div className={classes.lowerContent}>
            <Typography className={classes.headerText}>FAQ's</Typography>
            <Typography className={classes.contentText}>
              Find answers to common dealer questions
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    pageTheme: state.ui.pageTheme,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Help));
