import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import * as R from "ramda";

import {
  Card,
  SwipeableDrawer,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import {
  advance_search_cars,
  advance_search_criteria,
  advance_search_info,
  quick_search_key,
  add_watchlist_condition_report,
  mobile_search_criteria,
  mobile_search_edit_info,
  select_car_for_purchase,
  remove_selected_mobile_car,
  select_mobile_car,
} from "../actions/carActions";

import AdvanceSearchCriteria from "../components/AdvanceSearchCriteria";
import Loading from "../components/Loading";
import AutoSearchSave from "../components/AutoSearchSave";
import AutoSearch from "../components/AutoSearch";
import SearchResult from "../components/SearchResult";
import FullVehicleInformation from "../components/FullVehicleInformation";
import DDOFullVehicleInformation from "../components/DDOFullVehicleInformation";
import ConditionReport from "../components/ConditionReport";
import Filter from "../components/Filter";
import EmptyListPlaceholder from "../components/EmptyListPlaceholder";

import * as AdvanceSearchServices from "../services/AdvanceSearchServices";
import * as SearchServices from "../services/SearchServices";
import * as ConditionReportService from "../services/ConditionReportService";
import * as VehiclePurchaseServices from "../services/VehiclePurchaseServices";
import { getVehicleBidInformation } from "../services/BidServices";

import {
  set_nav_location,
  hide_mobile_nav_menu,
  show_saved_search_page,
  store_filter_options,
} from "../actions/uiActions";

import ReactSVG from "react-svg";
import Save from "../assets/save.svg";
import Clear from "../assets/Clear.svg";
import filterCars from "../assets/filterCars.svg";
import searchIcon from "../assets/search.svg";

const styles = (theme) => ({
  advSearchWrapper: {
    display: "flex",
    height: "82%",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
  advSearchLeft: {
    backgroundColor: theme.palette.secondary.gray,
    margin: ".4rem",
    padding: ".2rem",
    width: "75%",
    display: "block",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "98%",
      margin: "0",
    },
  },
  advSearchLeftLoading: {
    backgroundColor: theme.palette.secondary.gray,
    margin: ".4rem",
    padding: ".2rem",
    width: "75%",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      width: "98%",
      margin: "0",
    },
  },
  advSearchLeftWhite: {
    margin: ".4rem",
    padding: ".2rem",
    width: "75%",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0",
      padding: "0",
      marginBottom: "6rem",
    },
  },
  advSearchLeftWhiteFull: {
    margin: ".4rem",
    padding: ".2rem",
    width: "100%",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      padding: "0",
    },
  },
  advSearchCardWrapper: {
    paddingBottom: ".5rem",
  },
  advSearchRight: {
    backgroundColor: theme.palette.secondary.gray,
    margin: ".4rem",
    width: "25%",
    maxHeight: "97%",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      width: "0",
    },
  },
  linkWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  seperatorDiv: {
    paddingTop: "0.2em",
  },
  countStyle: {
    padding: "0.3rem",
    marginTop: "0.7rem",
    fontSize: ".8rem",
  },
  buttonSearch: {
    backgroundColor: theme.palette.primary.gray,
    width: "2.5rem",
    height: "2.00rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
    "&:hover": {
      opacity: ".5",
    },
  },
  scrollBar: {
    maxHeight: "88%",
    maxWidth: "98%",
  },
  vehicleInfoWrapper: {
    maxHeight: "88%",
    maxWidth: "98%",
  },
  advSearchMobileHeader: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.secondary.gray,
      margin: ".4rem",
      width: "96%",
      height: "98%",
      overflow: "auto",
      display: "block",
    },
  },
  selectionButton: {
    width: "2.5rem",
    height: "1.78rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    position: "absolute",
    top: "1rem",
    right: "1rem",
    zIndex: 3,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  errorSnackBar: {
    marginTop: "7rem",
  },
});

export class AdvanceSearch extends Component {
  state = {
    cars: [],
    showResults: false,
    pageLoading: false,
    buyPassFlowVissible: false,
    showAutoSearch: false,
    showSaveSearch: false,
    query: {},
    showVehicleInformation: false,
    conditionReport: null,
    selectedCar: null,
    carFrom: null,
    searchParamInfo: null,
    clearForm: false,
    fullConditionReport: false,
    buyVehicle: false,
    ddoBuyVehicle: false,
    passVehicle: false,
    openBid: false,
    newSave: false,
    searchParamInfoTemp: [],
    matchCount: 0,
    totalCount: 0,
    viewMode: false,
    showFilter: false,
    isFilterOn: false,
    filteredCarList: [],
    sortByColor: false,
    sortByDistance: false,
    sortByYear: false,
    sortByBody: false,
    sortByMake: false,
    sortByModel: false,
    sortBySeries: false,
    sortByGrade: false,
    sortByPrice: false,
    sortByOdometer: false,
    sortByState: false,
    sortByStatus: false,
    sortCount: 0,
    isQuickSearch: false,
    carsWithCR: {},
    right: false,
    vehicleInformationLoading: false,
    error: null,
    viewingSavedSearch: false,
  };

  componentWillMount = () => {
    const { advanceSearchCars, quickSearchKey, dispatch, showsavedsearchpage } =
      this.props;
    dispatch(set_nav_location("advSearch"));
    if (advanceSearchCars && advanceSearchCars.length > 0) {
      this.loadCars();
    } else {
      dispatch(store_filter_options(null));
    }
    if (quickSearchKey !== "" && quickSearchKey !== null) {
      this.setState({
        isQuickSearch: true,
      });
      this.loadQuickSearchCars();
    }
    if (showsavedsearchpage) {
      this.toggleAutoSearch();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      buyVehicle,
      ddoBuyVehicle,
      passVehicle,
      openBid,
      viewingSavedSearch,
    } = this.state;
    const { history, quickSearchKey } = this.props;
    if (
      history.action === "POP" &&
      history.location.pathname === "/advancesearch" &&
      (buyVehicle || ddoBuyVehicle || passVehicle || openBid)
    ) {
      this.setState({
        buyVehicle: false,
        ddoBuyVehicle: false,
        passVehicle: false,
        openBid: false,
        showResults: true,
        showVehicleInformation: true,
        showAutoSearch: false,
        showSaveSearch: false,
        buyPassFlowVissible: false,
      });
    }
    if (history.action === "POP" && viewingSavedSearch) {
      this.setState({
        showAutoSearch: true,
        viewingSavedSearch: false,
        cars: [],
        matchCount: 0,
        totalCount: 0,
      });
    }
    if (quickSearchKey !== prevProps.quickSearchKey) {
      this.loadQuickSearchCars();
    }
  };

  loadQuickSearchCars = async () => {
    const { quickSearchKey, user } = this.props;
    this.setState({ pageLoading: true });
    let response = await SearchServices.quickSearch(user, quickSearchKey);
    if (response !== null && response.message !== "No Results Found !!") {
      this.setState({
        cars: response,
        filteredCarList: response,
        carsWithCR: response.filter((car) => car.inspectionId),
        showResults: true,
        searchParamInfoTemp: null,
        matchCount: response.length,
        viewMode: false,
        showAutoSearch: false,
        showSaveSearch: false,
        isQuickSearch: true,
        selectedCar: response[0],
        showVehicleInformation: true,
        pageLoading: false,
      });
    } else {
      this.setState({
        showVehicleInformation: false,
        cars: [],
        filteredCarList: [],
        searchParamInfo: null,
        isQuickSearch: false,
        pageLoading: false,
      });
    }
  };

  loadCars = () => {
    const { advanceSearchCars } = this.props;
    if (advanceSearchCars.length > 0) {
      this.setState({
        cars: advanceSearchCars,
        filteredCarList: advanceSearchCars,
        carsWithCR:
          advanceSearchCars.length > 0
            ? advanceSearchCars.filter((car) => car.inspectionId)
            : [],
        showResults: true,
        searchParamInfoTemp: null,
        matchCount: advanceSearchCars.length,
        totalCount: advanceSearchCars.length,
        viewMode: false,
        showAutoSearch: false,
        showSaveSearch: false,
        isQuickSearch: false,
        selectedCar: advanceSearchCars[0],
        showVehicleInformation: true,
      });
    }
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    dispatch(advance_search_cars(null));
    dispatch(advance_search_criteria(null));
    dispatch(advance_search_info(null));
    dispatch(quick_search_key(null));
    dispatch(hide_mobile_nav_menu(false));
    dispatch(mobile_search_criteria(null));
    dispatch(mobile_search_edit_info(null));
    dispatch(show_saved_search_page(false));
    dispatch(store_filter_options(null));
  };

  updateCarList = (cars, query, count) => {
    const { dispatch } = this.props;
    this.setState({
      cars: cars,
      filteredCarList: cars,
      carsWithCR:
        cars && cars.length > 0 && cars.filter((car) => car.inspectionId),
      showResults: true,
      searchParamInfoTemp: query,
      matchCount: count,
      totalCount: count,
      viewMode: false,
      showAutoSearch: false,
      showSaveSearch: false,
      pageLoading: false,
      showVehicleInformation: cars.length > 0,
      fullConditionReport: false,
      selectedCar: cars[0],
    });
    dispatch(advance_search_cars(cars));
  };

  updateGuaranteePayment = (vin) => {
    const { searchCars, selectedCar, buyVehicle, filteredCarList } = this.state;
    const { mobileCarInformation, dispatch } = this.props;
    const carMap = (car) =>
      car.vehicleIdentificationNumber === vin
        ? { ...car, displayGuaranteePaymentsOnly: false }
        : car;
    const updatedDisplay = R.map(carMap, searchCars);
    const updatedFilteredCars = R.map(carMap, filteredCarList);
    this.setState({
      searchCars: updatedDisplay,
      filteredCarList: updatedFilteredCars,
    });
    if (mobileCarInformation && !buyVehicle) {
      dispatch(
        select_mobile_car({
          ...selectedCar,
          displayGuaranteePaymentsOnly: false,
        })
      );
    }
  };

  toggleAutoSearch = () => {
    const { dispatch } = this.props;
    this.setState({
      showAutoSearch: !this.state.showAutoSearch,
      showSaveSearch: this.state.showSaveSearch
        ? !this.state.showSaveSearch
        : this.state.showSaveSearch,
      right: false,
    });
    if (this.state.showSaveSearch) {
      dispatch(mobile_search_edit_info(null));
      dispatch(mobile_search_criteria(null));
    }
  };

  toggleAutoSearchSave = (query) => {
    const { dispatch } = this.props;
    this.setState({
      clearForm: this.state.showSaveSearch ? true : false,
      showSaveSearch: !this.state.showSaveSearch,
      showAutoSearch: this.state.showSaveSearch ? true : false,
      query: query,
      right: false,
    });
    if (this.state.showSaveSearch) {
      dispatch(mobile_search_edit_info(null));
      dispatch(mobile_search_criteria(null));
    }
  };

  toggleClearForm = () => {
    this.setState({
      clearForm: false,
      searchParamInfo: null,
    });
  };

  toggleSearchCount = async (info) => {
    const { user, dispatch, history } = this.props;
    history.push("/advanceSearch");
    this.setState({
      pageLoading: true,
    });
    const savedSearchInfo = await AdvanceSearchServices.showVehicleSearch(
      user,
      info.searchName
    );
    if (savedSearchInfo !== null) {
      this.setState({
        cars: savedSearchInfo,
        filteredCarList: savedSearchInfo,
        carsWithCR:
          savedSearchInfo.length > 0
            ? savedSearchInfo.filter((car) => car.inspectionId)
            : null,
        searchParamInfo: info,
        showResults: true,
        showAutoSearch: false,
        showSaveSearch: false,
        pageLoading: false,
        viewMode: true,
        matchCount: savedSearchInfo.length,
        totalCount: savedSearchInfo.length,
        selectedCar: savedSearchInfo[0],
        showVehicleInformation: true,
        viewingSavedSearch: true,
      });
      dispatch(advance_search_info(info));
      dispatch(advance_search_cars(savedSearchInfo));
    } else {
      this.setState({
        pageLoading: false,
        error: "Search timed out, please try again.",
      });
    }
  };

  toggleSearchEdit = (info) => {
    const { dispatch } = this.props;
    this.setState({
      searchParamInfo: info,
      showResults: false,
      showAutoSearch: false,
      showSaveSearch: true,
      pageLoading: false,
    });
    dispatch(mobile_search_edit_info(info));
  };

  toggleBackButton = () => {
    const { dispatch } = this.props;
    const { viewingSavedSearch } = this.state;
    this.setState({
      showVehicleInformation: false,
      cars: [],
      filteredCarList: [],
      searchParamInfo: null,
      isQuickSearch: false,
      totalCount: 0,
      showAutoSearch: viewingSavedSearch,
    });
    dispatch(advance_search_cars(null));
    dispatch(mobile_search_criteria(null));
  };

  sendQueryInfo = (query) => {
    this.setState({
      query: query,
    });
  };

  toggleFullConditionReport = async (car) => {
    this.setState({ pageLoading: true });
    const { user, dispatch, searchResultConditionReports } = this.props;
    const { fullConditionReport, filteredCarList, cars } = this.state;
    let conditionReport;
    if (
      !searchResultConditionReports[
        car.vin ? car.vin : car.vehicleIdentificationNumber
      ]
    ) {
      const id = car.id ? car.id : car.inventoryId;
      conditionReport = await ConditionReportService.getSingleConditionReport(
        id,
        user.userId,
        user.dealerId
      );
      dispatch(
        add_watchlist_condition_report(
          conditionReport,
          "searchResultConditionReports"
        )
      );
    } else {
      conditionReport =
        searchResultConditionReports[
          car.vin ? car.vin : car.vehicleIdentificationNumber
        ];
    }
    this.setState({
      conditionReport,
      fullConditionReport: !fullConditionReport,
      showVehicleInformation: cars.length > 0,
      selectedCar: car,
      buyPassFlowVissible: fullConditionReport
        ? false
        : this.state.buyPassFlowVissible,
      matchCount: filteredCarList.length,
      totalCount: cars.length,
      pageLoading: false,
    });
  };

  toggleLoading = () => {
    this.setState({
      pageLoading: !this.state.pageLoading,
    });
  };

  clearSearchResult = () => {
    const { dispatch } = this.props;
    this.setState({
      cars: [],
      filteredCarList: [],
      showResults: false,
      viewMode: false,
      pageLoading: false,
      totalCount: 0,
    });
    dispatch(store_filter_options(null));
    dispatch(advance_search_criteria(null));
  };

  toggleConditionReport = (selectedCar, conditionReport, from) => {
    this.setState({
      showVehicleInformation: !this.state.showVehicleInformation
        ? true
        : this.state.showVehicleInformation,
      selectedCar: selectedCar,
      conditionReport: conditionReport,
      carFrom: from,
    });
  };

  handleBidOpen = async (car) => {
    const { user, dispatch, searchResultConditionReports } = this.props;
    const { openBid } = this.state;
    if (car && !openBid) {
      const vehicleBidInformation = await getVehicleBidInformation(car, user);
      if (vehicleBidInformation.status !== "false") {
        this.setState({
          vehicleBidInformation,
          openBid: true,
          buyPassFlowVissible: true,
          conditionReport:
            searchResultConditionReports[
              car.vin ? car.vin : car.vehicleIdentificationNumber
            ],
        });
        return;
      } else {
        this.setState({
          error: vehicleBidInformation.message,
        });
        return;
      }
    } else {
      this.setState({
        openBid: false,
        buyPassFlowVissible: false,
      });
      dispatch(remove_selected_mobile_car());
      dispatch(hide_mobile_nav_menu(false));
    }
  };

  updateAutoBid = (vin, autoBid) => {
    const { cars, filteredCarList } = this.state;
    const carMap = (car) =>
      car.vin === vin
        ? { ...car, myCurrentAutoBid: autoBid, isInWatchlist: autoBid > 0 }
        : car;
    const updatedCars = R.map(carMap, cars);
    const updatedFilteredCars = R.map(carMap, filteredCarList);
    this.setState({
      cars: updatedCars,
      filteredCarList: updatedFilteredCars,
      openBid: false,
      buyPassFlowVissible: false,
      showResults: true,
      fullConditionReport: false,
      pageLoading: false,
      showAutoSearch: false,
      showSaveSearch: false,
    });
  };

  toggleOpenBid = () => {
    const { advanceSearchCars, quickSearchKey } = this.props;
    this.setState({
      openBid: !this.state.openBid,
      buyPassFlowVissible: !this.state.buyPassFlowVissible,
    });
    if (quickSearchKey !== null && !this.state.openBid) {
      this.loadQuickSearchCars();
    } else if (advanceSearchCars !== null) {
      this.loadCars();
    }
  };

  closeBidFlow = () => {
    this.setState({
      openBid: false,
      buyPassFlowVissible: false,
      showResults: true,
      fullConditionReport: false,
      pageLoading: false,
      showAutoSearch: false,
      showSaveSearch: false,
    });
  };

  toggleBuyPassFlow = () => {
    const { advanceSearchCars } = this.props;
    this.setState({
      buyPassFlowVissible: !this.state.buyPassFlowVissible,
    });
    if (advanceSearchCars !== null) {
      this.loadCars();
    }
  };

  toggleBuyVehicle = (bought, car) => {
    const { advanceSearchInfo, quickSearchKey } = this.props;
    const { buyVehicle } = this.state;
    if (!buyVehicle) {
      this.setState({
        buyVehicle: true,
        buyPassFlowVissible: true,
        selectedCar: car,
      });
    } else {
      this.setState({
        buyVehicle: false,
        buyPassFlowVissible: false,
        fullConditionReport: bought ? false : this.state.fullConditionReport,
        showVehicleInformation: bought
          ? false
          : this.state.showVehicleInformation,
      });
      if (bought) {
        if (quickSearchKey !== "" && quickSearchKey !== null) {
          this.loadQuickSearchCars();
        } else if (advanceSearchInfo && advanceSearchInfo.searchName) {
          this.reloadCarForAutoSearch();
        } else {
          this.reloadCar();
        }
      }
    }
  };

  togglePassVehicle = (passed, car) => {
    const { searchResultConditionReports } = this.props;
    const { passVehicle } = this.state;
    if (!passVehicle) {
      this.setState({
        passVehicle: true,
        buyPassFlowVissible: true,
      });
    } else {
      this.setState({
        passVehicle: false,
        buyPassFlowVissible: false,
        conditionReport: !passed
          ? searchResultConditionReports[
              car.vin ? car.vin : car.vehicleIdentificationNumber
            ]
          : null,
        fullConditionReport: passed ? false : this.state.fullConditionReport,
        cars: passed
          ? this.state.cars.map((listCar) => {
              if (
                (listCar.vin && listCar.vin === car.vin) ||
                (listCar.vehicleIdentificationNumber &&
                  listCar.vehicleIdentificationNumber ===
                    car.vehicleIdentificationNumber)
              ) {
                return { ...listCar, passedVehicle: true, vehiclePassed: true };
              } else {
                return listCar;
              }
            })
          : this.state.cars,
        filteredCarList: passed
          ? this.state.filteredCarList.map((listCar) => {
              if (
                (listCar.vin && listCar.vin === car.vin) ||
                (listCar.vehicleIdentificationNumber &&
                  listCar.vehicleIdentificationNumber ===
                    car.vehicleIdentificationNumber)
              ) {
                return { ...car, passedVehicle: true, vehiclePassed: true };
              } else {
                return listCar;
              }
            })
          : this.state.filteredCarList,
      });
    }
  };

  toggleDDOBuyVehicle = () => {
    const { ddoBuyVehicle } = this.state;
    if (!ddoBuyVehicle) {
      this.setState({
        ddoBuyVehicle: true,
        buyPassFlowVissible: true,
      });
    } else {
      this.setState({
        ddoBuyVehicle: false,
        buyPassFlowVissible: false,
      });
    }
  };

  toggleBuy = async (car, bought) => {
    const { searchResultConditionReports, dispatch, user } = this.props;
    if (!bought) {
      if (
        !searchResultConditionReports[
          car.vin ? car.vin : car.vehicleIdentificationNumber
        ] &&
        car.isCRAvailable
      ) {
        const conditionReport =
          await ConditionReportService.getSingleConditionReport(
            car.inventoryId,
            user.userId,
            user.dealerId
          );
        dispatch(
          add_watchlist_condition_report(
            conditionReport,
            "searchResultConditionReports"
          )
        );
        this.setState({
          conditionReport,
        });
      } else {
        this.setState({
          conditionReport: car.isCRAvailable
            ? searchResultConditionReports[
                car.vin ? car.vin : car.vehicleIdentificationNumber
              ]
            : null,
        });
      }
    }
    this.toggleBuyVehicle(bought, car);
  };

  handleBuyNow = async (car) => {
    const { ddoBuyVehicle } = this.state;
    const { dispatch } = this.props;
    const { userId, dealerId } = this.props.user;
    let buyNowDetails = await VehiclePurchaseServices.buyNow(
      userId,
      dealerId,
      car.saleEventItemId
    );
    if (buyNowDetails.status !== "false") {
      dispatch(select_car_for_purchase(buyNowDetails));
      if (!ddoBuyVehicle) {
        this.setState({
          ddoBuyVehicle: true,
          buyPassFlowVissible: true,
          selectedCar: car,
        });
      } else {
        this.setState({
          ddoBuyVehicle: false,
          buyPassFlowVissible: false,
        });
      }
    } else {
      console.log(
        "Error occured while retriving buy now details",
        buyNowDetails
      );
    }
  };

  saveSearchTemp = () => {
    this.toggleAutoSearchSave(this.state.query);
    this.setState({
      showVehicleInformation: false,
      searchParamInfo: this.state.searchParamInfoTemp,
      newSave: true,
    });
  };

  toggleFilter = () => {
    this.setState({
      showFilter: !this.state.showFilter,
    });
  };

  selectionChanged = (query, removed) => {
    const { cars, filteredCarList } = this.state;
    let finalarray = JSON.parse(
      JSON.stringify(!removed ? filteredCarList : cars)
    );
    if (query.selectState.length > 0) {
      finalarray = cars.filter((car) =>
        R.includes(car.state, query.selectState)
      );
    }
    if (query.selectBodyStyle.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.bodyStyle, query.selectBodyStyle)
      );
    }
    if (query.selectModel.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.model, query.selectModel)
      );
    }
    if (query.selectedColor.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.color, query.selectedColor)
      );
    }
    if (query.selectSeries.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.series, query.selectSeries)
      );
    }
    if (query.selectYear.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(String(car.year), query.selectYear)
      );
    }
    if (query.selectMake.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.make, query.selectMake)
      );
    }
    if (query.selectOdometer.length > 0) {
      query.selectOdometer.map((odometerSelection) => {
        return (finalarray = finalarray.filter((car) => {
          if (odometerSelection === "0-10,000") {
            return car.odometer < 10000;
          } else if (odometerSelection === "10,000-20,000") {
            return car.odometer >= 10000 && car.odometer < 20000;
          } else if (odometerSelection === "20,000-30,000") {
            return car.odometer >= 20000 && car.odometer < 30000;
          } else if (odometerSelection === "30,000-40,000") {
            return car.odometer >= 30000 && car.odometer < 40000;
          } else if (odometerSelection === "40,000-50,000") {
            return car.odometer >= 40000 && car.odometer < 50000;
          } else if (odometerSelection === "50,000-60,000") {
            return car.odometer >= 50000 && car.odometer < 60000;
          } else if (odometerSelection === "60,000-70,000") {
            return car.odometer >= 60000 && car.odometer < 70000;
          } else if (odometerSelection === "70,000-80,000") {
            return car.odometer >= 70000 && car.odometer < 80000;
          } else if (odometerSelection === "80,000-90,000") {
            return car.odometer >= 80000 && car.odometer < 90000;
          } else if (odometerSelection === "90,000-100,000") {
            return car.odometer >= 90000 && car.odometer < 100000;
          } else {
            return false;
          }
        }));
      });
    }
    if (query.selectGrade.length > 0) {
      query.selectGrade.map((grade) => {
        return (finalarray = finalarray.filter((car) => {
          if (grade === "1-2") {
            return Number(car.grade) >= 1 && Number(car.grade) < 2;
          } else if (grade === "2-3") {
            return Number(car.grade) >= 2 && Number(car.grade) < 3;
          } else if (grade === "3-4") {
            return Number(car.grade) >= 3 && Number(car.grade) < 4;
          } else if (grade === "4-5") {
            return Number(car.grade) >= 4 && Number(car.grade) < 5;
          } else if (grade === "5") {
            return Number(car.grade) === 5;
          } else {
            return false;
          }
        }));
      });
    }
    if (query.selectStatus.length > 0) {
      finalarray = finalarray.filter((car) =>
        R.includes(car.dlrFacingStatusName, query.selectStatus)
      );
    }
    if (query.selectedDistance.length > 0) {
      query.selectedDistance.map((distance) => {
        return (finalarray = finalarray.filter((car) => {
          if (distance === "<50 mi") {
            return car.distance < 50;
          } else if (distance === "<100 mi") {
            return car.distance < 100;
          } else if (distance === "<200 mi") {
            return car.distance < 200;
          } else if (distance === "<300 mi") {
            return car.distance < 300;
          } else if (distance === "<400 mi") {
            return car.distance < 400;
          } else if (distance === "<500 mi") {
            return car.distance < 500;
          } else if (distance === "<750 mi") {
            return car.distance < 750;
          } else if (distance === "<1000 mi") {
            return car.distance < 1000;
          } else if (distance === "<1500 mi") {
            return car.distance < 1500;
          } else if (distance === "<2000 mi") {
            return car.distance < 2000;
          } else {
            return false;
          }
        }));
      });
    }
    const filterOn =
      query.selectedColor.length === 0 &&
      query.selectYear.length === 0 &&
      query.selectBodyStyle.length === 0 &&
      query.selectMake.length === 0 &&
      query.selectModel.length === 0 &&
      query.selectSeries.length === 0 &&
      query.selectState.length === 0 &&
      query.selectGrade.length === 0 &&
      query.selectStatus.length === 0 &&
      query.selectPrice.length === 0 &&
      query.selectOdometer.length === 0 &&
      query.selectedDistance.length === 0;
    this.setState({
      filteredCarList: filterOn ? cars : finalarray,
      isFilterOn: filterOn ? false : true,
      matchCount: filterOn ? cars.length : finalarray.length,
    });
  };

  sortCars = (summary) => {
    const {
      sortByColor,
      sortByDistance,
      sortByModel,
      sortByBody,
      sortByMake,
      sortBySeries,
      sortByOdometer,
      sortByGrade,
      sortByYear,
      sortByState,
      filteredCarList,
      sortByStatus,
      isFilterOn,
    } = this.state;
    let color;
    let distance;
    let model;
    let body;
    let make;
    let series;
    let price;
    let odometer;
    let grade;
    let year;
    let state;
    let status;
    const array = JSON.parse(JSON.stringify(filteredCarList));

    if (summary === "Color") {
      color = !sortByColor;
      array.sort((a, b) => {
        return !sortByColor
          ? a.color.toLowerCase() < b.color.toLowerCase()
            ? -1
            : 1
          : a.color.toLowerCase() > b.color.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Distance") {
      distance = !sortByDistance;
      array.sort((a, b) => {
        return !sortByDistance
          ? a.distance < b.distance
            ? -1
            : 1
          : a.distance > b.distance
          ? -1
          : 1;
      });
    }

    if (summary === "Model") {
      model = !sortByModel;
      array.sort((a, b) => {
        return !sortByModel
          ? a.model.toLowerCase() < b.model.toLowerCase()
            ? -1
            : 1
          : a.model.toLowerCase() > b.model.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Body/Style") {
      body = !sortByBody;
      array.sort((a, b) => {
        return a.bodyStyle && b.bodyStyle
          ? !sortByBody
            ? a.bodyStyle.toLowerCase() < b.bodyStyle.toLowerCase()
              ? -1
              : 1
            : a.bodyStyle.toLowerCase() > b.bodyStyle.toLowerCase()
            ? -1
            : 1
          : 0;
      });
    }

    if (summary === "Make") {
      make = !sortByMake;
      array.sort((a, b) => {
        return !sortByMake
          ? a.make.toLowerCase() < b.make.toLowerCase()
            ? -1
            : 1
          : a.make.toLowerCase() > b.make.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Series") {
      series = !sortBySeries;
      array.sort((a, b) => {
        return a.series && b.series
          ? !sortBySeries
            ? a.series.toLowerCase() < b.series.toLowerCase()
              ? -1
              : 1
            : a.series.toLowerCase() > b.series.toLowerCase()
            ? -1
            : 1
          : 0;
      });
    }

    if (summary === "Odometer") {
      odometer = !sortByOdometer;
      array.sort((a, b) => {
        return !sortByOdometer
          ? (a.inspectionMileage ? a.inspectionMileage : a.odometer) -
              (b.inspectionMileage ? b.inspectionMileage : b.odometer)
          : (b.inspectionMileage ? b.inspectionMileage : b.odometer) -
              (a.inspectionMileage ? a.inspectionMileage : a.odometer);
      });
    }

    if (summary === "Grade") {
      grade = !sortByGrade;
      array.sort((a, b) => {
        return !sortByGrade
          ? parseFloat(a.grade) - parseFloat(b.grade)
          : parseFloat(b.grade) - parseFloat(a.grade);
      });
    }

    if (summary === "Year") {
      year = !sortByYear;
      array.sort((a, b) => {
        return !sortByYear ? a.year - b.year : b.year - a.year;
      });
    }

    if (summary === "State") {
      state = !sortByState;
      array.sort((a, b) => {
        return !sortByState
          ? a.state.toLowerCase() < b.state.toLowerCase()
            ? -1
            : 1
          : a.state.toLowerCase() > b.state.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (summary === "Status") {
      status = !sortByStatus;
      array.sort((a, b) => {
        return !sortByStatus
          ? a.dlrFacingStatusName.toLowerCase() <
            b.dlrFacingStatusName.toLowerCase()
            ? -1
            : 1
          : a.dlrFacingStatusName.toLowerCase() >
            b.dlrFacingStatusName.toLowerCase()
          ? -1
          : 1;
      });
    }

    this.setState({
      sortByColor: color,
      sortByDistance: distance,
      sortByModel: model,
      sortByBody: body,
      sortByMake: make,
      sortBySeries: series,
      sortByPrice: price,
      sortByOdometer: odometer,
      sortByGrade: grade,
      sortByYear: year,
      sortByState: state,
      sortByStatus: status,
      filteredCarList: array,
      isFilterOn: isFilterOn ? true : !this.state.isFilterOn,
    });
  };

  updateWatchlist = async () => {
    const { advanceSearchInfo, quickSearchKey } = this.props;
    if (quickSearchKey !== "" && quickSearchKey !== null) {
      this.loadQuickSearchCars();
    } else if (advanceSearchInfo && advanceSearchInfo.searchName) {
      this.reloadCarForAutoSearch();
    } else {
      this.reloadCar();
    }
  };

  reloadCar = async () => {
    const { advanceSearchInfo, advanceSearchCriteria, user } = this.props;
    this.setState({
      pageLoading: true,
    });
    const response = await AdvanceSearchServices.advanceSearch(
      advanceSearchCriteria,
      user
    );
    this.updateCarList(response, advanceSearchInfo, response.length);
    this.setState({
      pageLoading: false,
    });
  };

  reloadCarForAutoSearch = async () => {
    const { user, advanceSearchInfo } = this.props;
    this.toggleLoading();
    const response = await AdvanceSearchServices.showVehicleSearch(
      user,
      advanceSearchInfo.searchName
    );
    this.updateCarList(response, null, response.length);
  };

  updateCount = (count) => {
    this.setState({
      matchCount: count,
    });
  };

  selectCar = async (car) => {
    this.setState({ vehicleInformationLoading: true });
    const { user, dispatch, searchResultConditionReports } = this.props;
    if (car.inspectionId) {
      if (
        !searchResultConditionReports[
          car.vin ? car.vin : car.vehicleIdentificationNumber
        ]
      ) {
        const conditionReport =
          await ConditionReportService.getSingleConditionReport(
            car.inventoryId,
            user.userId,
            user.dealerId
          );
        dispatch(
          add_watchlist_condition_report(
            conditionReport,
            "searchResultConditionReports"
          )
        );
        this.setState({
          selectedCar: car,
          conditionReport: conditionReport,
          vehicleInformationLoading: false,
        });
      } else {
        this.setState({
          selectedCar: car,
          conditionReport: searchResultConditionReports[car.vin],
          vehicleInformationLoading: false,
        });
      }
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: null,
        vehicleInformationLoading: false,
      });
    }
  };

  setConditionReport = (cr) => {
    this.setState({
      conditionReport: cr,
    });
  };

  toggleDrawer = () => {
    this.setState({
      right: !this.state.right,
    });
  };

  selectMobileCar = async (car, user, from) => {
    const {
      searchResultConditionReports,
      dispatch,
      toggleFullConditionReport,
      fullConditionReport,
      toggleConditionReport,
    } = this.props;
    if (car.inspectionId || car.inspectionInfo) {
      if (
        !searchResultConditionReports[
          car.vin ? car.vin : car.vehicleIdentificationNumber
        ]
      ) {
        const crInfo = await ConditionReportService.getSingleConditionReport(
          car.inventoryId ? car.inventoryId : car.id,
          user.userId,
          user.dealerId
        );
        dispatch(
          add_watchlist_condition_report(crInfo, "searchResultConditionReports")
        );
        this.setState({
          selectedCar: car,
          conditionReport: crInfo,
          getCRFinished: true,
        });
        toggleConditionReport(car, crInfo, from);
      } else {
        this.setState({
          selectedCar: car,
          conditionReport:
            searchResultConditionReports[
              car.vin ? car.vin : car.vehicleIdentificationNumber
            ],
          getCRFinished: true,
        });
        toggleConditionReport(
          car,
          searchResultConditionReports[
            car.vin ? car.vin : car.vehicleIdentificationNumber
          ],
          from
        );
      }
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: null,
        getCRFinished: true,
      });
    }
    if (fullConditionReport) {
      toggleFullConditionReport();
    }
    dispatch(hide_mobile_nav_menu(true));
    dispatch(select_mobile_car(car));
  };

  render() {
    const {
      classes,
      user,
      history,
      mobileSearchCriteria,
      mobileSearchEditInfo,
    } = this.props;
    const {
      cars,
      showResults,
      pageLoading,
      buyPassFlowVissible,
      showAutoSearch,
      showSaveSearch,
      query,
      showVehicleInformation,
      selectedCar,
      conditionReport,
      carFrom,
      searchParamInfo,
      clearForm,
      fullConditionReport,
      buyVehicle,
      ddoBuyVehicle,
      passVehicle,
      openBid,
      newSave,
      matchCount,
      viewMode,
      showFilter,
      filteredCarList,
      isFilterOn,
      sortCount,
      isQuickSearch,
      right,
      vehicleInformationLoading,
      error,
      vehicleBidInformation,
      totalCount,
      refs,
    } = this.state;
    return (
      <React.Fragment>
        {fullConditionReport &&
        conditionReport &&
        !buyVehicle &&
        !passVehicle &&
        !ddoBuyVehicle &&
        !openBid ? (
          <ConditionReport
            car={selectedCar}
            user={user}
            conditionReport={conditionReport}
            toggleFullConditionReport={this.toggleFullConditionReport}
            searchCar={filteredCarList.filter((car) => car.inspectionId)}
            page="Search"
            selectCar={this.selectCar}
            vehicleInformationLoading={vehicleInformationLoading}
            history={history}
            handleBuyNow={this.handleBuyNow}
            toggleBuyVehicle={this.toggleBuyVehicle}
            handleBidOpen={this.handleBidOpen}
            togglePass={this.togglePassVehicle}
            pageFrom="advanceSearch"
            updateWatchlist={this.updateWatchlist}
          />
        ) : (
          <div className={classes.advSearchWrapper}>
            <ReactSVG
              className={classes.selectionButton}
              onClick={() => this.toggleDrawer()}
              svgStyle={{
                fill: "white",
                marginTop: ".4rem",
                height: "1.5rem",
                width: "1.5rem",
              }}
              src={searchIcon}
              alt="searchCarsIcon"
            />
            <SwipeableDrawer
              anchor="right"
              open={right}
              onClose={() => this.toggleDrawer()}
              onOpen={() => this.toggleDrawer()}
              disableBackdropTransition
            >
              <div className={classes.advSearchMobileHeader}>
                <AdvanceSearchCriteria
                  searchParamInfo={searchParamInfo}
                  updateCarList={this.updateCarList}
                  handleChipDelete={this.handleChipDelete}
                  clearSearchResult={this.clearSearchResult}
                  toggleLoading={this.toggleLoading}
                  toggleAutoSearch={this.toggleAutoSearch}
                  toggleAutoSearchSave={this.toggleAutoSearchSave}
                  sendQueryInfo={this.sendQueryInfo}
                  clearForm={clearForm}
                  toggleClearForm={this.toggleClearForm}
                  newSave={newSave}
                  mobileSearchCriteria={mobileSearchCriteria}
                  toggleDrawer={this.toggleDrawer}
                  mobileSearchEditInfo={mobileSearchEditInfo}
                  totalCount={totalCount}
                />
              </div>
            </SwipeableDrawer>
            {pageLoading ? (
              <div className={classes.advSearchLeftLoading}>
                <Loading />
              </div>
            ) : (
              <div
                className={
                  buyPassFlowVissible ||
                  buyVehicle ||
                  ddoBuyVehicle ||
                  passVehicle ||
                  openBid
                    ? classes.advSearchLeftWhiteFull
                    : showAutoSearch ||
                      showSaveSearch ||
                      !(cars && cars.length > 0)
                    ? classes.advSearchLeftWhite
                    : classes.advSearchLeft
                }
              >
                {showAutoSearch ? (
                  <Scrollbars autoHide autoHideTimeout={1000}>
                    <AutoSearch
                      toggleAutoSearch={this.toggleAutoSearch}
                      toggleSearchCount={this.toggleSearchCount}
                      toggleSearchEdit={this.toggleSearchEdit}
                    />
                  </Scrollbars>
                ) : showSaveSearch ? (
                  <AutoSearchSave
                    toggleAutoSearchSave={this.toggleAutoSearchSave}
                    query={query}
                    searchParamInfo={searchParamInfo}
                  />
                ) : showResults ? (
                  cars && cars.length > 0 ? (
                    <SearchResult
                      searchCars={isFilterOn ? filteredCarList : cars}
                      toggleBuyPassFlow={this.toggleBuyPassFlow}
                      toggleLoading={this.toggleLoading}
                      clearSearchResult={this.clearSearchResult}
                      toggleConditionReport={this.toggleConditionReport}
                      history={history}
                      pageFrom="advanceSearch"
                      buyVehicle={buyVehicle}
                      ddoBuyVehicle={ddoBuyVehicle}
                      passVehicle={passVehicle}
                      openBid={openBid}
                      toggleBuy={this.toggleBuy}
                      handleBuyNow={this.handleBuyNow}
                      toggleDDOBuyVehicle={this.toggleDDOBuyVehicle}
                      toggleBuyVehicle={this.toggleBuyVehicle}
                      togglePassVehicle={this.togglePassVehicle}
                      handleBidOpen={this.handleBidOpen}
                      updateAutoBid={this.updateAutoBid}
                      updateWatchlist={this.updateWatchlist}
                      searchParamInfo={searchParamInfo}
                      sortCount={sortCount}
                      from="advanceSearch"
                      updateCount={this.updateCount}
                      closeBidFlow={this.closeBidFlow}
                      toggleFullConditionReport={this.toggleFullConditionReport}
                      matchCount={matchCount}
                      viewMode={viewMode}
                      toggleBackButton={this.toggleBackButton}
                      saveSearchTemp={this.saveSearchTemp}
                      selectCar={this.selectCar}
                      setConditionReport={this.setConditionReport}
                      vehicleBidInformation={vehicleBidInformation}
                      conditionReport={conditionReport}
                      selectedCar={selectedCar}
                      updateGuaranteePayment={this.updateGuaranteePayment}
                      selectMobileCar={this.selectMobileCar}
                      refs={refs}
                    />
                  ) : (
                    <EmptyListPlaceholder page={"Search"} />
                  )
                ) : (
                  <EmptyListPlaceholder page={"Search"} />
                )}
              </div>
            )}
            {buyPassFlowVissible ||
            buyVehicle ||
            ddoBuyVehicle ||
            passVehicle ||
            openBid ||
            fullConditionReport ? null : (
              <div className={classes.advSearchRight}>
                {showVehicleInformation ? (
                  <>
                    <div className={classes.linkWrapper}>
                      <Card
                        id="backButton"
                        title={"Clear Search"}
                        className={classes.buttonSearch}
                        onClick={() => this.toggleBackButton()}
                      >
                        <ReactSVG
                          svgStyle={{ fill: "white", marginTop: ".4rem" }}
                          src={Clear}
                        />
                      </Card>
                      {showFilter ? null : (
                        <Typography className={classes.countStyle}>
                          {" "}
                          <strong>{matchCount}</strong> Matches{" "}
                        </Typography>
                      )}
                      <Card
                        id="filterButton"
                        title={"Filter Results"}
                        className={classes.buttonSearch}
                        onClick={() => this.toggleFilter()}
                      >
                        <ReactSVG
                          svgStyle={{ fill: "white", marginTop: ".4rem" }}
                          src={filterCars}
                          alt="filterCarsIcon"
                        />
                      </Card>
                      {viewMode || isQuickSearch ? null : (
                        <Card
                          id="saveButton"
                          title={"Save Search"}
                          className={classes.buttonSearch}
                          onClick={() => this.saveSearchTemp()}
                        >
                          <ReactSVG
                            svgStyle={{ fill: "white", marginTop: ".4rem" }}
                            src={Save}
                          />
                        </Card>
                      )}
                    </div>
                    {showFilter ? (
                      <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        className={classes.scrollBar}
                      >
                        <Filter
                          filterCars={filteredCarList}
                          from={"search"}
                          selectionChanged={this.selectionChanged}
                          matchCount={matchCount}
                          totalCount={totalCount}
                          unfilteredCars={cars}
                          sortCars={this.sortCars}
                        />
                      </Scrollbars>
                    ) : (
                      <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        className={classes.scrollBar}
                      >
                        {!vehicleInformationLoading ? (
                          <React.Fragment>
                            {carFrom === "DDO" ? (
                              <DDOFullVehicleInformation
                                user={user}
                                car={selectedCar}
                                conditionReport={conditionReport}
                                toggleFullConditionReport={
                                  this.toggleFullConditionReport
                                }
                              />
                            ) : (
                              <FullVehicleInformation
                                car={selectedCar}
                                conditionReport={conditionReport}
                                toggleFullConditionReport={
                                  this.toggleFullConditionReport
                                }
                                selectedVehicleDetails={selectedCar}
                                user={user}
                              />
                            )}
                          </React.Fragment>
                        ) : (
                          <Loading />
                        )}
                      </Scrollbars>
                    )}
                  </>
                ) : isQuickSearch ? null : (
                  <AdvanceSearchCriteria
                    searchParamInfo={searchParamInfo}
                    updateCarList={this.updateCarList}
                    handleChipDelete={this.handleChipDelete}
                    clearSearchResult={this.clearSearchResult}
                    toggleLoading={this.toggleLoading}
                    toggleAutoSearch={this.toggleAutoSearch}
                    toggleAutoSearchSave={this.toggleAutoSearchSave}
                    sendQueryInfo={this.sendQueryInfo}
                    clearForm={clearForm}
                    toggleClearForm={this.toggleClearForm}
                    newSave={newSave}
                    toggleDrawer={this.toggleDrawer}
                    totalCount={totalCount}
                  />
                )}
              </div>
            )}
          </div>
        )}
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={error}
          autoHideDuration={2000}
          className={classes.errorSnackBar}
          onClose={() => this.setState({ error: null })}
        >
          <SnackbarContent className={classes.errorMessage} message={error} />
        </Snackbar>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    searchCriteria: state.ui.searchCriteria,
    advanceSearchCars: state.cars.advanceSearchCars,
    advanceSearchCriteria: state.cars.advanceSearchCriteria,
    advanceSearchInfo: state.cars.advanceSearchInfo,
    mobileSearchCriteria: state.cars.mobileSearchCriteria,
    mobileSearchEditInfo: state.cars.mobileSearchEditInfo,
    quickSearchKey: state.cars.quickSearchKey,
    showsavedsearchpage: state.ui.showsavedsearchpage,
    searchResultConditionReports: state.cars.searchResultConditionReports,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AdvanceSearch));
