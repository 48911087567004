import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { GridListTile, GridListTileBar } from "@material-ui/core";

import clock from "../assets/clock.svg";
import TFSNoImage from "../assets/TFSNoImage.png";
import ReactSVG from "react-svg";

const styles = (theme) => ({
  root: {
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    maxWidth: "8rem",
    height: "100%",
  },
  title: {
    color: theme.palette.text.carDisplay,
    position: "relative",
    top: "3px",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  icon: {
    marginRight: ".5rem",
    marginLeft: "1rem",
    fill: theme.palette.primary.white,
    position: "relative",
    top: "5px",
  },
});

export class CarTile extends Component {
  state = {
    countdown: "",
    exclusivePeriod: null,
  };

  renderTitle = (car) => {
    const { exclusivePeriod } = this.state;
    if (!exclusivePeriod && exclusivePeriod !== 0) {
      let hours = parseInt(car.exclusivePeriod) / 60;
      let rHours = Math.floor(hours);
      let minutes = Math.round((hours - rHours) * 60);
      return this.setState({
        exclusivePeriod: car.exclusivePeriod,
        countdown: `${rHours}h ${minutes}m`,
      });
    } else if (exclusivePeriod > 0) {
      let exclusivePeriod = this.state.exclusivePeriod - 1;
      let hours = parseInt(exclusivePeriod) / 60;
      let rHours = Math.floor(hours);
      let minutes = Math.round((hours - rHours) * 60);
      return this.setState({
        countdown: `${rHours}h ${minutes}m`,
        exclusivePeriod: this.state.exclusivePeriod - 1,
      });
    } else {
      this.setState({ countdown: "Expired" });
      clearInterval(this.interval);
    }
  };

  componentDidMount = () => {
    const { car } = this.props;
    this.renderTitle(car);
    this.interval = setInterval(() => {
      this.renderTitle(car);
    }, 60000);
  };
  componentWillUnmount = () => {
    clearInterval(this.interval);
  };
  loadDefaultImage = (evt) => {
    evt.target.src = TFSNoImage;
  };

  render() {
    const { classes, car } = this.props;

    return (
      <GridListTile
        id={car.vehicleIdentificationNumber}
        className={classes.root}
      >
        <img
          src={car.imageUrl}
          alt={car.model}
          className={classes.img}
          onError={this.loadDefaultImage}
        />
        <GridListTileBar
          title={car.exclusivePeriod > 0 ? this.state.countdown : car.model}
          classes={{
            root: classes.titleBar,
            title: classes.title,
          }}
          actionIcon={
            car.exclusivePeriod > 0 ? (
              <ReactSVG src={clock} className={classes.icon} />
            ) : null
          }
          actionPosition="left"
        />
      </GridListTile>
    );
  }
}
CarTile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CarTile);
