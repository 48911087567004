import { createMuiTheme } from "@material-ui/core/styles";

export const toyotaTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#58595B",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#1B55A6",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "#1B55A6",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "#1B55A6",
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#1B55A6",
        },
      },
    },
    MuiStepIcon: {
      root: {
        "&$active": {
          color: "#58595B",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "initial",
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: `2px solid #1B55A6`,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#58595B",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: "#58595B",
      },
      isSelected: {
        backgroundColor: "#58595B",
        color: "#FFFFFF",
      },
      current: {
        color: "#EB0A1E",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#FFFFFF",
      },
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "Montserrat",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
    ],
  },
  palette: {
    primary: {
      main: "#EB0A1E",
      black: "#000000",
      gray: "#58595B",
      white: "#FFFFFF",
      redHighlight: "#EB0A1E",
    },
    secondary: {
      main: "#004d40",
      gray: "#C2BFBF",
      blue: "#3377D6",
    },
    light: {
      gray: "#DEE0E2",
      green: "rgba(51, 204, 51, .2);",
    },
    grounding: {
      main: "#1B55A6",
    },
    GAMD: {
      main: "#E4B042",
      light: "#F9D382",
    },
    DDO: {
      main: "#3F853E",
      light: "#A5D2AS",
    },
    text: {
      carDisplay: "#FFFFFF",
      navigationTab: "#000000",
    },
    background: {
      main: "#FFFFFF",
      dark: "#e0e0e0",
    },
    button: {
      main: "#1B55A6",
      pass: "#9e9e9e",
      text: "#FFFFFF",
      guaranteedPayment: "#5472d3",
    },
    bid: {
      win: "#669904",
      loss: "#EB0A1E",
    },
    headerColor: {
      gray: "rgb(238, 238, 238)",
    },
  },
  stepper: {
    height: "3.2rem",
    margin: "1rem",
    label: {
      fontSize: ".7rem",
    },
  },
  font: {
    small: ".7rem",
  },
  breakpoints: {
    values: {
      xs: 200,
      sm: 450,
      md: 1000,
      lg: 1280,
      xl: 1900,
    },
  },
});

export const lexusTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#58595B",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#1B55A6",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "#1B55A6",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "#1B55A6",
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#1B55A6",
        },
      },
    },
    MuiStepIcon: {
      root: {
        "&$active": {
          color: "#58595B",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "initial",
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: `2px solid #1B55A6`,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#58595B",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: "#58595B",
      },
      isSelected: {
        backgroundColor: "#58595B",
        color: "#FFFFFF",
      },
      current: {
        color: "#EB0A1E",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#FFFFFF",
      },
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "Montserrat",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
    ],
  },
  palette: {
    primary: {
      main: "#000000",
      black: "#000000",
      gray: "#58595B",
      white: "#FFFFFF",
      redHighlight: "#EB0A1E",
    },
    secondary: {
      main: "#004d40",
      gray: "#C2BFBF",
      blue: "#3377D6",
    },
    light: {
      gray: "#DEE0E2",
      green: "rgba(51, 204, 51, .2);",
    },
    grounding: {
      main: "#1B55A6",
    },
    GAMD: {
      main: "#E4B042",
      light: "#F9D382",
    },
    DDO: {
      main: "#3F853E",
      light: "#A5D2AS",
    },
    text: {
      carDisplay: "#FFFFFF",
      navigationTab: "#000000",
    },
    background: {
      main: "#FFFFFF",
      dark: "#e0e0e0",
    },
    button: {
      main: "#1B55A6",
      pass: "#9e9e9e",
      text: "#FFFFFF",
      guaranteedPayment: "#5472d3",
    },
    bid: {
      win: "#669904",
      loss: "#EB0A1E",
    },
    headerColor: {
      gray: "rgb(238, 238, 238)",
    },
  },
  stepper: {
    height: "3.2rem",
    margin: "1rem",
    label: {
      fontSize: ".7rem",
    },
  },
  font: {
    small: ".7rem",
  },
  breakpoints: {
    values: {
      xs: 200,
      sm: 450,
      md: 1000,
      lg: 1280,
      xl: 1900,
    },
  },
});
