import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TableCell,
  TableRow,
  Link,
  Checkbox,
  Typography,
} from "@material-ui/core";

const returnEmailFreq = (status) => {
  const statuses = {
    18108: "Week Days",
    18107: "Every Day",
    26689: "No E-mail",
    18109: "M",
    18110: "Tu",
    18111: "W",
    18112: "Th",
    18113: "F",
    18114: "Sa",
    18115: "Su",
  };
  return statuses[status] || null;
};

const styles = (theme) => ({
  savedSearchButtonWrapper: {
    textAlign: "right",
  },
  saveSearchLink: {
    margin: "0.2rem",
  },
  tableRowFull: {
    border: `1px solid ${theme.palette.secondary.gray}`,
  },
  viewInfo: {
    display: "flex",
    width: "97%",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  viewInfoRight: {
    margin: "0.5rem",
    maxWidth: "50%",
  },
  viewInfoLeft: {
    margin: "0.5rem",
    maxWidth: "50%",
  },
  muitablecellRoot: {
    padding: "0.2rem",
    paddingRight: "0.6rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  muitablecellRootDetails: {
    padding: "0rem",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    borderTop: `1px solid ${theme.palette.secondary.gray}`,
    color: theme.palette.primary.gray,
    fontSize: "0.8rem",
  },
  linkStyle: {
    color: theme.palette.button.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  detailLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
});

export class AutoSearchEntry extends Component {
  state = {
    savedSearch: {},
    pageLoading: true,
    showSavedSearchView: false,
  };

  toggleAutoSearchView = () => {
    this.setState({
      showSavedSearchView: !this.state.showSavedSearchView,
    });
  };

  renderViewDetails = () => {
    const { classes, info } = this.props;
    return (
      <TableRow
        id={`${info.searchId}-Row2`}
        key={`${info.searchId}-Row2Key`}
        className={classes.tableRow}
      >
        <TableCell colSpan={5} className={classes.muitablecellRootDetails}>
          <div className={classes.viewInfo}>
            <div className={classes.viewInfoRight}>
              {info.make.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Make : </strong> {this.mapContent(info.make)}
                </Typography>
              ) : null}
              {info.model.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Model : </strong> {this.mapContent(info.model)}
                </Typography>
              ) : null}
              {info.modelNumber.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Model Number : </strong>{" "}
                  {this.mapContent(info.modelNumber)}
                </Typography>
              ) : null}
              {info.series.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Series : </strong> {this.mapContent(info.series)}
                </Typography>
              ) : null}
              {(info.fromOdometer !== null || info.toOdometer !== null) &&
              (info.fromOdometer !== "0" || info.toOdometer !== "200000+") ? (
                <Typography className={classes.detailLabel}>
                  <strong>Odometer : </strong>{" "}
                  {info.fromOdometer === null
                    ? "0"
                    : parseInt(info.fromOdometer, 10).toLocaleString()}{" "}
                  -{" "}
                  {info.toOdometer === null
                    ? "200,000+"
                    : parseInt(info.toOdometer, 10).toLocaleString()}
                </Typography>
              ) : null}
              {(info.fromPrice !== null || info.toPrice !== null) &&
              (info.fromPrice !== "0" || info.toPrice !== "+100000") ? (
                <Typography className={classes.detailLabel}>
                  <strong>Price : </strong>{" "}
                  {info.fromPrice === null
                    ? "$0"
                    : parseInt(info.fromPrice, 10).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}{" "}
                  -{" "}
                  {info.toPrice === null
                    ? "$100,000+"
                    : parseInt(info.toPrice, 10).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                </Typography>
              ) : null}
              {info.doors.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Doors : </strong> {this.mapContent(info.doors)}
                </Typography>
              ) : null}
              {info.bodyStyle.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Body Style/Type : </strong>{" "}
                  {this.mapContent(info.bodyStyle)}
                </Typography>
              ) : null}
              {info.driveTrain.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Drive Train : </strong>{" "}
                  {this.mapContent(info.driveTrain)}
                </Typography>
              ) : null}
            </div>
            <div className={classes.viewInfoLeft}>
              {info.exteriorColor.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Exterior Color : </strong>{" "}
                  {this.mapContent(info.exteriorColor)}
                </Typography>
              ) : null}
              {info.interiorColor.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Interior Color : </strong>{" "}
                  {this.mapContent(info.interiorColor)}
                </Typography>
              ) : null}
              {info.options.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Options : </strong> {this.mapContent(info.options)}
                </Typography>
              ) : null}
              {info.regions.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Region : </strong> {this.mapContent(info.regions)}
                </Typography>
              ) : null}
              {info.states.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>States : </strong> {this.mapContent(info.states)}
                </Typography>
              ) : null}
              {info.transmission.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Transmission : </strong>{" "}
                  {this.mapContent(info.transmission)}
                </Typography>
              ) : null}
              {info.vehicleGrade.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Vehicle Grade : </strong>{" "}
                  {this.mapContent(info.vehicleGrade)}
                </Typography>
              ) : null}
              {info.engine.length > 0 ? (
                <Typography className={classes.detailLabel}>
                  <strong>Engine : </strong> {this.mapContent(info.engine)}
                </Typography>
              ) : null}
              {info.distanceFromUser && info.distanceFromUser !== null ? (
                <Typography className={classes.detailLabel}>
                  <strong>Distance From User : </strong> {info.distanceFromUser}
                </Typography>
              ) : null}
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  mapContent = (info) => {
    return info.map((item, index) => {
      return <span key={`item_${index}`}>{(index ? ", " : "") + item}</span>;
    });
  };

  mapEmailContent = (info) => {
    return info.map((item, index) => {
      return (
        <span key={`item_${index}`}>
          {(index ? ", " : "") + returnEmailFreq(item)}
        </span>
      );
    });
  };

  render() {
    const { showSavedSearchView } = this.state;
    const {
      classes,
      info,
      toggleEntryCheck,
      toggleSearchCount,
      toggleSearchEdit,
    } = this.props;
    return (
      <React.Fragment>
        <TableRow
          id={`${info.searchId}-Row`}
          key={`${info.searchId}-RowKey`}
          className={classes.tableRow}
        >
          <TableCell className={classes.muitablecellRoot}>
            {info.searchName}
          </TableCell>
          <TableCell className={classes.muitablecellRoot}>
            <Link
              id={`${info.searchId}-View`}
              className={classes.linkStyle}
              component="button"
              variant="body2"
              onClick={() => this.toggleAutoSearchView()}
            >
              View
            </Link>
            /
            <Link
              id={`${info.searchId}-Edit`}
              className={classes.linkStyle}
              component="button"
              variant="body2"
              onClick={() => toggleSearchEdit(info)}
            >
              Edit
            </Link>
          </TableCell>
          <TableCell className={classes.muitablecellRoot}>
            <Link
              id={`${info.searchId}-Count`}
              className={classes.linkStyle}
              component="button"
              variant="body2"
              onClick={() => toggleSearchCount(info)}
            >
              {info.matchesCount}
            </Link>
          </TableCell>
          <TableCell className={classes.muitablecellRoot}>
            {info.alertFrequencies.length > 1 ? "Day(s) of the Week " : null}
            {this.mapEmailContent(info.alertFrequencies)}
          </TableCell>
          <TableCell className={classes.muitablecellRoot}>
            <Checkbox
              id={`${info.searchId}-Delete`}
              color="default"
              value={info.searchId}
              onChange={() => toggleEntryCheck(info.searchId)}
              inputProps={{
                "aria-label": "checkbox with default color",
              }}
            />
          </TableCell>
        </TableRow>
        {showSavedSearchView ? this.renderViewDetails() : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AutoSearchEntry);
