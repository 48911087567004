import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { TextField, Typography, Snackbar } from "@material-ui/core";

import OdometerDisclosureStatement from "../components/OdometerDisclosureStatement";
import OdometerSigningPad from "../components/OdometerSigningPad";
import {
  GROUNDING_MESSAGES,
  ODOMETER_DISCLOSURE,
  ODOMETER_LEGAL_DISCLOSURE,
  ODOMETER_LEGAL_DISCLOSURE_NOTICE,
  ODOMETER_LEGAL_DISCLOSURE_NOTICE_NEW,
  REENTER,
} from "../constants/UILabel";

const styles = (theme) => ({
  odoWrapper: {
    marginLeft: "4rem",
    marginRight: "4rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: 0,
    },
  },
  leftWrapper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "40%",
    margin: ".5rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  rightWrapper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "50%",
    margin: ".5rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      margin: 0,
      marginTop: "10px",
    },
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  button: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100px",
  },
  fieldWrapper: {
    display: "flex",
    width: "20rem",
    height: "2rem",
    margin: ".5rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      marginLeft: 0,
    },
  },
  textField: {
    margin: 0,
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    height: "2rem",
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  fieldLabel: {
    backgroundColor: theme.palette.grounding.main,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "5rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  disclosureText: {
    fontSize: ".6rem",
  },
  odometerTitle: {
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      color: theme.palette.grounding.main,
      fontWeight: "600",
    },
  },
  snackBarStyle: {
    top: "100px",
    [theme.breakpoints.down("sm")]: {
      top: "5rem",
    },
  },
});

const InputProps = {
  disableUnderline: true,
  style: {
    paddingLeft: 12,
    paddingTop: 5,
    paddingBottom: 5,
  },
  inputProps: {
    style: {
      padding: 5,
      height: "100%",
    },
    maxLength: 11,
    min: 0,
  },
};

export class OdometerInformation extends Component {
  render() {
    const {
      classes,
      odometer = "",
      odometerConfirmation = "",
      disclosureStatementValue,
      padValue,
      handleChange,
      badOdoSubmit,
      badValueSubmit,
      customerHasSigned,
      odoWarningConfirmation,
      handleDateChange,
      showMissingSignatureMessage,
      disableSignaturePad,
      customerWillSignPaperStatement,
      customerWillDDValue,
    } = this.props;
    const { TDDMAIN_4047_TDD_E2E_DEALER_ENGAGEMENT } = this.props.togglzMap;
    return (
      <div className={classes.odoWrapper}>
        <div className={classes.leftWrapper}>
          <div className={classes.textBox}>
            <Typography variant="h5" className={classes.odometerTitle}>
              {ODOMETER_DISCLOSURE}
            </Typography>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                <strong>Odometer:</strong>
              </Typography>
              <TextField
                id="filled-odometer"
                placeholder="Required Field"
                className={classes.textField}
                value={
                  odometer === ""
                    ? ""
                    : Number(odometer).toLocaleString("en-US")
                }
                onChange={handleChange("odometer")}
                margin="normal"
                variant="filled"
                autoComplete="off"
                name="odometer"
                InputProps={InputProps}
                autoFocus
              />
            </div>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                <strong>{REENTER}</strong>
              </Typography>
              <TextField
                id="filled-odometerConfirmation"
                placeholder="Required Field"
                className={classes.textField}
                value={
                  odometerConfirmation === ""
                    ? ""
                    : Number(odometerConfirmation).toLocaleString("en-US")
                }
                onChange={handleChange("odometerConfirmation")}
                margin="normal"
                variant="filled"
                autoComplete="off"
                name="odometerConfirmation"
                InputProps={InputProps}
              />
            </div>
          </div>
          <Typography variant="caption" className={classes.disclosureText}>
            <strong>{ODOMETER_LEGAL_DISCLOSURE}</strong>
            {TDDMAIN_4047_TDD_E2E_DEALER_ENGAGEMENT
              ? ODOMETER_LEGAL_DISCLOSURE_NOTICE_NEW
              : ODOMETER_LEGAL_DISCLOSURE_NOTICE}
          </Typography>
          <OdometerSigningPad
            handleChange={handleChange}
            padValue={padValue}
            showMissingSignatureMessage={showMissingSignatureMessage}
            disableSignaturePad={disableSignaturePad}
            customerWillSignPaperStatement={customerWillSignPaperStatement}
          />
        </div>
        <div className={classes.rightWrapper}>
          <OdometerDisclosureStatement
            handleChange={handleChange}
            value={disclosureStatementValue}
            badValueSubmit={badValueSubmit}
            customerHasSigned={customerHasSigned}
            odoWarningConfirmation={odoWarningConfirmation}
            handleDateChange={handleDateChange}
            customerWillSignPaperStatement={customerWillSignPaperStatement}
            padValue={padValue}
            customerWillDDValue={customerWillDDValue}
          />
          {this.props.children}
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={badOdoSubmit}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              {GROUNDING_MESSAGES.ERROR_ODOMETER_VALUE}
            </span>
          }
          autoHideDuration={3000}
          className={classes.snackBarStyle}
        />
      </div>
    );
  }
}

OdometerInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    togglzMap: state.ui.togglzMap,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(OdometerInformation)
);
