import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import Loading from "../components/Loading";
import WinLossCard from "../components/WinLossCard";
import ConditionReport from "../components/ConditionReport";
import DDOFullVehicleInformation from "../components/DDOFullVehicleInformation";
import EmptyListPlaceholder from "../components/EmptyListPlaceholder";
import MobileWinLossCard from "../components/MobileWinLossCard";

import {
  add_watchlist_condition_report,
  select_mobile_car,
} from "../actions/carActions";
import { hide_mobile_nav_menu } from "../actions/uiActions";

import * as ConditionReportService from "../services/ConditionReportService";

export const styles = (theme) => ({
  winListCardListWrapper: {
    display: "flex",
    height: "100%",
    width: "100vw",
  },
  winListCardListLeft: {
    backgroundColor: theme.palette.secondary.gray,
    margin: ".5rem",
    padding: "0 0 .5rem 0",
    width: "70%",
    display: "block",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
    },
  },

  winListCardListRight: {
    margin: "0 .5rem 0 .5rem",
    width: "30%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      marginTop: "3.5rem",
    },
  },
  scrollContainer: {
    height: "97%",
    overflow: "auto",
  },
  desktopCardList: {
    display: "flex",
    height: "88%",
    [theme.breakpoints.down("md")]: {
      height: "87%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileCardList: {
    display: "flex",
    height: "71%",
    width: "100vw",
    paddingBottom: "6rem",
    backgroundColor: theme.palette.secondary.gray,
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
});

export class WinLossCardList extends Component {
  state = {
    selectedCar: {},
    selectedCarConditionReport: null,
    getCRFinished: false,
    vehicleInformationLoading: false,
  };

  componentWillMount = () => {
    this.refs = {};
  };

  componentDidUpdate = (prevProps) => {
    const { displayedTab, updateData, displayedCars, list, toggleLoading } =
      this.props;
    if (prevProps.displayedTab !== displayedTab) {
      this.setState({
        selectedCar: {},
        selectedCarConditionReport: null,
      });
      updateData(`${list}Data`, displayedTab);
    }
    if (prevProps.displayedCars !== displayedCars && displayedCars.length > 0) {
      this.selectCar(displayedCars[0]);
      toggleLoading(false);
    }
    if (
      prevProps.displayedCars !== displayedCars &&
      displayedCars.length === 0
    ) {
      this.setState({
        selectedCar: {},
        selectedCarConditionReport: null,
        getCRFinished: true,
      });
      toggleLoading(false);
    }
  };

  selectCar = async (car) => {
    this.setState({ vehicleInformationLoading: true });
    const { user, dispatch, watchListConditionReports } = this.props;
    if (car.inspectionId) {
      if (!watchListConditionReports[car.vin]) {
        const conditionReport =
          await ConditionReportService.getSingleConditionReport(
            car.inventoryId,
            user.userId,
            user.dealerId
          );
        dispatch(
          add_watchlist_condition_report(
            conditionReport,
            `watchListConditionReports`
          )
        );
        this.setState({
          selectedCar: car,
          selectedCarConditionReport: conditionReport,
          getCRFinished: true,
          vehicleInformationLoading: false,
        });
      } else {
        this.setState({
          selectedCar: car,
          selectedCarConditionReport: watchListConditionReports[car.vin],
          getCRFinished: true,
          vehicleInformationLoading: false,
        });
      }
    } else {
      this.setState({
        selectedCar: car,
        selectedCarConditionReport: null,
        getCRFinished: true,
        vehicleInformationLoading: false,
      });
    }
  };

  selectMobileCar = async (car, user, from) => {
    this.setState({ vehicleInformationLoading: true });
    const {
      watchListConditionReports,
      dispatch,
      toggleFullConditionReport,
      fullConditionReport,
    } = this.props;
    if (car.inspectionId || car.inspectionInfo) {
      if (
        !watchListConditionReports[
          car.vin ? car.vin : car.vehicleIdentificationNumber
        ]
      ) {
        const crInfo = await ConditionReportService.getSingleConditionReport(
          car.inventoryId ? car.inventoryId : car.id,
          user.userId,
          user.dealerId
        );
        dispatch(
          add_watchlist_condition_report(crInfo, "watchListConditionReports")
        );
        this.setState({
          selectedCar: car,
          conditionReport: crInfo,
          getCRFinished: true,
          vehicleInformationLoading: false,
        });
      } else {
        this.setState({
          selectedCar: car,
          conditionReport:
            watchListConditionReports[
              car.vin ? car.vin : car.vehicleIdentificationNumber
            ],
          getCRFinished: true,
          vehicleInformationLoading: false,
        });
      }
    } else {
      this.setState({
        selectedCar: car,
        conditionReport: null,
        getCRFinished: true,
        vehicleInformationLoading: false,
      });
    }
    if (fullConditionReport) {
      toggleFullConditionReport();
    }
    dispatch(hide_mobile_nav_menu(true));
    dispatch(select_mobile_car(car));
  };

  setTileRef = (car, ref) => {
    this.refs[car.vin ? car.vin : car.vehicleIdentificationNumber] = ref;
  };

  scrollToCar = (car) => {
    this.refs[
      car.vin ? car.vin : car.vehicleIdentificationNumber
    ].current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  render() {
    const { selectedCarConditionReport, selectedCar, getCRFinished } =
      this.state;
    const {
      classes,
      searchCriteria,
      user,
      listLoading,
      displayedCars,
      list,
      fullConditionReport,
      toggleFullConditionReport,
      mobileCarInformation,
    } = this.props;
    return (
      <React.Fragment>
        {!fullConditionReport ? (
          <div className={classes.desktopCardList}>
            {listLoading ? (
              <Loading />
            ) : displayedCars && displayedCars.length > 0 ? (
              <div className={classes.winListCardListWrapper}>
                <div className={classes.winListCardListLeft}>
                  <div className={classes.scrollContainer}>
                    {displayedCars.map((car) =>
                      list === "winList" ? (
                        <WinLossCard
                          key={car.vin}
                          car={car}
                          selectCar={this.selectCar}
                          selectedCarConditionReport={
                            selectedCarConditionReport
                          }
                          isWinCard={true}
                          selectedCar={selectedCar}
                          setTileRef={this.setTileRef}
                        />
                      ) : (
                        <WinLossCard
                          key={car.vin}
                          car={car}
                          selectCar={this.selectCar}
                          selectedCarConditionReport={
                            selectedCarConditionReport
                          }
                          isWinCard={false}
                          selectedCar={selectedCar}
                          setTileRef={this.setTileRef}
                        />
                      )
                    )}
                  </div>
                </div>
                <div className={classes.winListCardListRight}>
                  {getCRFinished && selectedCarConditionReport ? (
                    <DDOFullVehicleInformation
                      car={selectedCar}
                      user={user}
                      conditionReport={selectedCarConditionReport}
                      toggleFullConditionReport={toggleFullConditionReport}
                    />
                  ) : (
                    <Loading />
                  )}
                </div>
              </div>
            ) : (
              <EmptyListPlaceholder page={"Watchlist"} />
            )}
          </div>
        ) : null}
        {!fullConditionReport && !mobileCarInformation ? (
          <div className={classes.mobileCardList}>
            {listLoading ? (
              <Loading />
            ) : displayedCars && displayedCars.length > 0 ? (
              <div className={classes.winListCardListWrapper}>
                <div className={classes.winListCardListLeft}>
                  <div className={classes.scrollContainer}>
                    {displayedCars.map((car) =>
                      list === "winList" ? (
                        <MobileWinLossCard
                          key={car.vin}
                          car={car}
                          selectCar={this.selectMobileCar}
                          selectedCarConditionReport={
                            selectedCarConditionReport
                          }
                          isWinCard={true}
                          selectedCar={selectedCar}
                        />
                      ) : (
                        <MobileWinLossCard
                          key={car.vin}
                          car={car}
                          selectCar={this.selectMobileCar}
                          selectedCarConditionReport={
                            selectedCarConditionReport
                          }
                          isWinCard={false}
                          selectedCar={selectedCar}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <EmptyListPlaceholder page={"Watchlist"} />
            )}
          </div>
        ) : null}
        {mobileCarInformation && !fullConditionReport ? (
          <div className={classes.winListCardListRight}>
            <DDOFullVehicleInformation
              car={selectedCar}
              user={user}
              conditionReport={selectedCarConditionReport}
              toggleFullConditionReport={toggleFullConditionReport}
            />
          </div>
        ) : null}
        {fullConditionReport ? (
          <div className={classes.scrollContainer}>
            <ConditionReport
              searchCriteria={searchCriteria}
              car={selectedCar}
              user={user}
              conditionReport={selectedCarConditionReport}
              watchlistCar={displayedCars}
              toggleFullConditionReport={toggleFullConditionReport}
              page="Watchlist"
              selectCar={this.selectCar}
              winLossList
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    searchCriteria: state.ui.searchCriteria,
    watchListConditionReports: state.cars.watchListConditionReports,
    mobileCarInformation: state.cars.mobileCarInformation,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(WinLossCardList));
