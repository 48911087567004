import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";

import { set_nav_location, hide_mobile_nav_menu } from "../actions/uiActions";

import MyPreferences from "../components/MyPreferences";
import PasswordReset from "../components/PasswordReset";

import ReactSVG from "react-svg";
import adminIcon from "../assets/adminIcon.svg";

const styles = (theme) => ({
  color: theme.palette.secondary.main,
  container: {
    display: "flex",
    height: "100%",
  },
  header: {
    display: "flex",
    width: "100%",
    height: "10%",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      height: "6%",
      marginTop: "3.5rem",
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobHeaderContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
    },
  },
  titleWrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  buttonWrapper: {
    marginLeft: "3rem",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  noButtonUnderline: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "5px solid transparent",
  },
  buttonUnderline: {
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    minHeight: "100%",
  },
  highlightedButtonText: {
    color: theme.palette.primary.main,
    marginRight: ".5rem",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
      fontSize: ".6rem",
    },
  },
  buttonText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  AdminIcon: {
    width: "1rem",
    height: "1rem",
    margin: "1rem",
    border: `.5rem solid ${theme.palette.primary.main}`,
    borderRadius: "1.5rem",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.main,
    fill: theme.palette.primary.white,
  },
  titleTextWrapper: {
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    width: "7rem",
  },
  titleText: {
    color: theme.palette.primary.main,
    fontSize: ".8rem",
    fontWeight: "bold",
  },
  navButton: {
    height: "1.5rem",
    lineHeight: 0,
    padding: 0,
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    width: "11rem",
  },
  components: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
      alignItems: "normal",
    },
  },
  selectedMobileButton: {
    height: "100%",
    width: "50%",
    backgroundColor: theme.palette.secondary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.gray,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
  mobileButton: {
    height: "100%",
    width: "50%",
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
});

export class AccountSettings extends Component {
  state = {
    settingsFilter: "preferences",
  };

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(set_nav_location("accountsetting"));
    dispatch(hide_mobile_nav_menu(true));
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    dispatch(hide_mobile_nav_menu(false));
  };

  setDisplayedNav = (nav) => {
    this.setState({ settingsFilter: nav });
  };

  render() {
    const { classes, history, user, accountSetting } = this.props;
    const { settingsFilter } = this.state;

    return (
      <React.Fragment>
        <div className={classes.header}>
          <div className={classes.headerContainer}>
            <div className={classes.titleWrapper}>
              <ReactSVG
                className={classes.AdminIcon}
                src={adminIcon}
                alt="Admin Icon"
                svgStyle={{ height: "1rem", width: "1rem" }}
              />
              <div className={classes.titleTextWrapper}>
                <Typography className={classes.titleText}>
                  Account Settings
                </Typography>
              </div>
            </div>
            <div className={classes.buttonWrapper}>
              <div
                className={
                  settingsFilter === "preferences"
                    ? classes.buttonUnderline
                    : classes.noButtonUnderline
                }
              >
                <Button
                  id={"preferences"}
                  className={classes.navButton}
                  variant="outlined"
                  onClick={(evt) => this.setDisplayedNav("preferences")}
                >
                  <Typography
                    className={
                      settingsFilter === "preferences"
                        ? classes.highlightedButtonText
                        : classes.buttonText
                    }
                  >
                    My Preferences
                  </Typography>
                </Button>
              </div>
            </div>
            <div className={classes.buttonWrapper}>
              <div
                className={
                  settingsFilter === "password"
                    ? classes.buttonUnderline
                    : classes.noButtonUnderline
                }
              >
                <Button
                  id={"password"}
                  className={classes.navButton}
                  variant="outlined"
                  onClick={(evt) => this.setDisplayedNav("password")}
                >
                  <Typography
                    className={
                      settingsFilter === "password"
                        ? classes.highlightedButtonText
                        : classes.buttonText
                    }
                  >
                    Change Password
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.mobHeaderContainer}>
            <Button
              id={"mobilepreferences"}
              className={
                settingsFilter === "preferences"
                  ? classes.selectedMobileButton
                  : classes.mobileButton
              }
              variant="outlined"
              onClick={(evt) => this.setDisplayedNav("preferences")}
            >
              My Preferences
            </Button>
            <Button
              id={"mobilepassword"}
              className={
                settingsFilter === "password"
                  ? classes.selectedMobileButton
                  : classes.mobileButton
              }
              variant="outlined"
              onClick={(evt) => this.setDisplayedNav("password")}
            >
              Change Password
            </Button>
          </div>
        </div>
        <div className={classes.components}>
          {settingsFilter === "preferences" && (
            <MyPreferences
              user={user}
              accountSetting={accountSetting}
              fromAdmin={false}
              history={history}
            />
          )}
          {settingsFilter === "password" && (
            <PasswordReset fromAccountSetting />
          )}
        </div>
      </React.Fragment>
    );
  }
}

AccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AccountSettings));
