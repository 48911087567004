import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "react-moment";
import ReactToPrint from "react-to-print";

import Viewer from "react-viewer";
import "react-viewer/dist/index.css";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {
  watchlist_count,
  select_car_for_purchase,
  select_pass_car,
} from "../actions/carActions";

import { hide_mobile_nav_menu } from "../actions/uiActions";

import CancelIcon from "@material-ui/icons/Cancel";
import NoteAdd from "@material-ui/icons/NoteAdd";

import Notes from "../components/Notes";
import CarReport from "../components/CarReport";
import Loading from "../components/Loading";
import GuaranteePayments from "../components/GuaranteePayments";
import LCPEntryForm from "../components/LCPEntryForm";
import AutoCheckReport from "../components/AutoCheckReport";
import { getNotes } from "../services/NotesServices";
import * as WatchListServices from "../services/WatchListServices";
import { getDate } from "../services/HelperServices";
import * as userAccess from "../services/UserRoles";
import { removeAutoBid } from "../services/BidServices";
import * as VehiclePurchaseServices from "../services/VehiclePurchaseServices";
import * as carfaxService from "../services/CarfaxService";
import ReactSVG from "react-svg";
import carfaxLogo from "../assets/carfaxLogo.svg";
import autoCheckLogo from "../assets/autoCheckLogo.svg";
import bidIcon from "../assets/bidIcon.svg";
import shoppingCart from "../assets/shoppingCartSmaller.svg";
import rotateRight from "../assets/rotateRight.svg";
import circledCheck from "../assets/circledCheck.svg";
import watchlistIcon from "../assets/watchlistIcon.svg";
import activeWatchlistIcon from "../assets/activeWatchlistIcon.svg";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

const styles = (theme) => ({
  conditionReportWrapper: {
    height: "100%",
    overflowY: "auto",
    margin: ".5rem",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "6rem",
      marginTop: "3.5rem",
      "@media print": {
        maxHeight: "100rem",
      },
    },
  },
  headerTitle: {
    fontSize: "2rem !important",
    display: "flex",
    alignItems: "center",
    "@media print": {
      maxWidth: "15%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem !important",
      maxWidth: "24rem",
      "@media print": {
        maxWidth: "35%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".9rem !important",
    },
  },
  upperWrapper: {
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  leftUpperWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "10rem",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carReportLink: {
    width: "9rem",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  subTable: {
    padding: 0,
    border: "none",
  },
  basicInfo: {
    maxHeight: "300px",
  },
  row: {
    height: "1rem",
  },
  table: {
    padding: "5px",
  },
  boldRow: {
    height: "1rem",
    backgroundColor: theme.palette.light.gray,
  },
  tableLeft: {
    width: "50%",
    border: "1px solid",
    float: "left",
  },

  tableRight: {
    width: "49.5%",
    border: "1px solid",
    float: "right",
  },
  cell: {
    border: "none",
    height: "auto",
    padding: 0,
    fontSize: ".8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".5rem",
    },
  },
  boldCell: {
    border: "none",
    height: "auto",
    padding: 0,
    fontSize: ".8rem",
    fontWeight: "bold",
    width: "9rem",
  },
  popupCell: {
    border: "none",
    height: "auto",
    width: "8rem",
    padding: "2px !important",
    textAlign: "left",
    fontSize: ".8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".5rem",
    },
  },
  popupBoldCell: {
    border: "none",
    height: "auto",
    padding: "2px !important",
    fontSize: ".8rem",
    fontWeight: "bold",
    width: "19rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".5rem",
      "@media print": {
        width: "5rem",
      },
    },
  },
  centeredCell: {
    verticalAlign: "top",
    textAlign: "center",
  },
  damageDescriptionCell: {
    width: "31rem",
    [theme.breakpoints.down("sm")]: {
      width: "13rem",
    },
  },
  damageImageCell: {
    width: "16rem",
  },
  header: {
    width: "58%",
    minHeight: "3rem",
    maxHeight: "5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "1rem",
    "@media print": {
      width: "20%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
    },
  },
  buttonWrapper: {
    alignSelf: "flex-end",
    width: "23rem",
    "@media print": {
      display: "none",
    },
    position: "absolute",
    top: "7rem",
    zIndex: 2,
    right: "2.5rem",
    background: theme.palette.primary.white,
    [theme.breakpoints.down("md")]: {
      width: "23rem",
    },
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      alignSelf: "center",
      top: "auto",
      width: "100vw",
      right: 0,
    },
    marginTop: "9px",
  },
  externalConditionReportViewButtonWrapper: {
    float: "right",
  },
  button: {
    color: theme.palette.button.text,
    margin: ".2rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2rem",
    boxShadow: "none",
    width: "7rem",
    fontSize: theme.font.small,
    "&:hover": {
      backgroundColor: theme.palette.button.main,
      "@media (hover: none)": {
        backgroundColor: theme.palette.button.main,
        "&:active": {
          backgroundColor: theme.palette.button.main,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "50%",
      "&:hover": {
        backgroundColor: theme.palette.button.main,
        "@media (hover: none)": {
          backgroundColor: theme.palette.button.main,
          "&:active": {
            backgroundColor: theme.palette.button.main,
          },
        },
      },
    },
  },
  printButton: {
    color: theme.palette.button.text,
    margin: ".2rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2rem",
    boxShadow: "none",
    width: "7rem",
    fontSize: theme.font.small,
    "&:hover": {
      backgroundColor: theme.palette.button.main,
      "@media (hover: none)": {
        backgroundColor: theme.palette.button.main,
        "&:active": {
          backgroundColor: theme.palette.button.main,
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
      "&:hover": {
        backgroundColor: theme.palette.button.main,
        "@media (hover: none)": {
          backgroundColor: theme.palette.button.main,
          "&:active": {
            backgroundColor: theme.palette.button.main,
          },
        },
      },
    },
  },
  vehicleInfoWrapper: {
    "@media print": {
      position: "absolute",
      right: 0,
      top: "5rem",
      marginRight: ".5rem",
      height: "20rem",
      width: "33rem",
    },
  },
  vehicleConditionWrapper: {
    "@media print": {
      position: "absolute",
      top: "63rem",
      width: "97%",
      pageBreakInside: "avoid",
    },
  },
  sectionHeader: {
    height: "25px",
    backgroundColor: "grey",
    color: theme.palette.primary.white,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  vehicleInfoTableWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: ".5rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "@media print": {
        // flexDirection: 'row',
        margin: 0,
      },
    },
  },
  imageWrapper: {
    height: "50%",
    margin: ".5rem",
    display: "flex",
    flexDirection: "column",
    "@media print": {
      height: "20rem",
      marginTop: "1.5rem",
      position: "absolute",
      top: "41rem",
    },
  },
  imageViewer: {
    display: "flex",
    maxHeight: "100%",
    margin: ".5rem",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "@media print": {
        flexDirection: "row",
      },
    },
  },
  smallImageWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "80%",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "@media print": {
        height: "24rem",
      },
    },
  },
  smallImage: {
    maxHeight: "32%",
    width: "8%",
    marginLeft: ".5rem",
    maxWidth: "25%",
    cursor: "pointer",
    marginBottom: ".2rem",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginLeft: 0,
      maxWidth: "50%",
      height: "4rem",
      "@media print": {
        maxHeight: "2rem",
        width: "8%",
        marginLeft: ".5rem",
        maxWidth: "25%",
        marginBottom: ".2rem",
      },
    },
  },
  bigImage: {
    maxHeight: "300px",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  damageImage: {
    maxWidth: "25%",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    maxHeight: "2rem",
    [theme.breakpoints.down("sm")]: {
      height: "2rem",
      width: "2rem",
      maxWidth: "100%",
    },
  },
  hasNotes: {
    color: theme.palette.primary.redHighlight,
  },
  blankNotes: {},
  twoColumnDiv: {
    display: "flex",
    justifyContent: "space-around",
    "@media print": {
      height: "20rem",
      flexDirection: "column",
      position: "absolute",
      top: "20rem",
      width: "97%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  smallColumn: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    margin: ".5rem",
    "@media print": {
      width: "100%",
      height: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buildDataWrapper: {
    marginTop: ".5rem",
  },
  accessoriesWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "20rem",
    "@media print": {
      fontSize: theme.font.small,
      marginBottom: "1rem",
      maxHeight: "5rem",
    },
  },
  optionsWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "5rem",
    borderTop: `2px solid ${theme.palette.primary.gray}`,
  },
  viewText: {
    fontSize: ".9rem",
    marginLeft: ".5rem",
    fontWeight: "bold",
    width: "10rem",
    [theme.breakpoints.down("md")]: {
      fontSize: ".8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  redText: {
    color: theme.palette.primary.redHighlight,
  },
  disclaimer: {
    fontSize: theme.font.small,
  },
  grandTotalWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  purchaseVehicleWrapper: {
    height: "10rem",
    marginTop: "1rem",
    width: "17rem",
    display: "flex",
    position: "absolute",
    right: "2.5rem",
    top: "1rem",
    "@media print": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      right: "3rem",
      top: "3rem",
      height: "7rem",
    },
    [theme.breakpoints.down("sm")]: {
      position: "initial",
      marginBottom: ".5rem",
    },
  },
  GAMDPurchaseVehicleWrapper: {
    height: "10rem",
    marginTop: "2rem",
    width: "20rem",
    display: "flex",
    position: "absolute",
    right: "6.5rem",
    "@media print": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      right: "1.5rem",
      top: "3rem",
      height: "9rem",
      width: "18rem",
      marginTop: 0,
    },
    [theme.breakpoints.down("sm")]: {
      position: "initial",
      marginBottom: ".5rem",

      height: "10rem",
    },
  },
  cardText: {
    fontSize: "76%",
    color: theme.palette.primary.gray,
  },
  boldedText: {
    fontWeight: "bold",
  },
  DDOColorText: {
    color: theme.palette.DDO.main,
  },
  redBoldedText: {
    fontWeight: "bold",
    color: theme.palette.primary.redHighlight,
  },
  priceDisplayTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    width: "64%",
    marginRight: "1rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  GAMDPriceDisplayTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    width: "58%",
    marginRight: "1rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("sm")]: {
      width: "56%",
    },
  },
  winningBid: {
    color: theme.palette.DDO.main,
    fontSize: "76%",
  },
  losingBid: {
    color: theme.palette.primary.redHighlight,
    fontSize: "76%",
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    minWidth: "12%",
    justifyContent: "space-around",
    marginRight: ".5rem",
    width: "30%",
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  GAMDCardActions: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    minWidth: "12%",
    width: "37%",
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  buyButton: {
    height: "40%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100%",
    margin: 0,
    fill: "currentColor",
    [theme.breakpoints.down("md")]: {
      height: "50%",
      "&:hover": {
        backgroundColor: theme.palette.button.main,
        "@media (hover: none)": {
          backgroundColor: theme.palette.button.main,
          "&:active": {
            backgroundColor: theme.palette.button.main,
          },
        },
      },
    },
  },
  GAMDBuyButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100%",
    margin: 0,
    fill: "currentColor",
    [theme.breakpoints.down("md")]: {
      "&:hover": {
        backgroundColor: theme.palette.button.main,
        "@media (hover: none)": {
          backgroundColor: theme.palette.button.main,
          "&:active": {
            backgroundColor: theme.palette.button.main,
          },
        },
      },
    },
  },
  bidButton: {
    height: "40%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.DDO.main,
    width: "100%",
    margin: 0,
    fill: "currentColor",
    [theme.breakpoints.down("md")]: {
      height: "50%",
      "&:hover": {
        backgroundColor: theme.palette.DDO.main,
        "@media (hover: none)": {
          backgroundColor: theme.palette.DDO.main,
          "&:active": {
            backgroundColor: theme.palette.DDO.main,
          },
        },
      },
    },
  },
  passButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.pass,
    width: "100%",
    margin: 0,
    fill: "currentColor",
    [theme.breakpoints.down("md")]: {
      "&:hover": {
        backgroundColor: theme.palette.button.pass,
        "@media (hover: none)": {
          backgroundColor: theme.palette.button.pass,
          "&:active": {
            backgroundColor: theme.palette.button.pass,
          },
        },
      },
    },
  },
  clickableLink: {
    color: theme.palette.grounding.main,
    fontWeight: "bold",
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: ".5",
    },
    fontSize: theme.font.small,
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  guaranteedPaymentButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.guaranteedPayment,
    width: "100%",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      "&:hover": {
        backgroundColor: theme.palette.button.guaranteedPayment,
        "@media (hover: none)": {
          backgroundColor: theme.palette.button.guaranteedPayment,
          "&:active": {
            backgroundColor: theme.palette.button.guaranteedPayment,
          },
        },
      },
    },
  },
  chipRoot: {
    fontSize: theme.font.small,
    height: "1rem",
    width: "4.4rem",
  },
  deleteIcon: {
    height: "1rem",
    "&:hover": {
      fill: theme.palette.primary.redHighlight,
    },
  },
  autoBid: {
    display: "flex",
    justifyContent: "space-between",
    width: "8rem",
  },
  smallButtonLabel: {
    marginLeft: "2%",
    marginRight: "2%",
    justifyContent: "space-around",
    fontSize: ".6rem",
  },
  buyBidButtonLabel: {
    marginLeft: "2%",
    marginRight: "2%",
    justifyContent: "space-around",
    fontSize: ".6rem",
  },
  "@global": {
    ".MuiTypography-root": {
      fontSize: theme.font.small,
      [theme.breakpoints.down("sm")]: {
        fontSize: ".5rem",
      },
    },
    ".MuiTableCell-root": {
      fontSize: theme.font.small,
      padding: 0,
      minWidth: "5rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: ".5rem",
        minWidth: 0,
        "@media print": {
          maxWidth: "10rem",
        },
      },
    },
    ".MuiTableCell-root:last-child": {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    ".MuiDialogActions-root": {
      padding: "0px",
    },
    "#print": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "#closeButton": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  LCPWrapper: {
    width: "70%",
  },
  linkColor: {
    color: theme.palette.button.main,
  },
  interiorVehicleInfoTableWrapper: {
    display: "flex",
  },
  dialogPaper: {
    minHeight: "41vh",
    maxHeight: "41vh",
    maxWidth: "70%",
    width: "80%",
  },
  dialogText: {
    backgroundColor: "#EB0A1E",
    color: theme.palette.primary.white,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px !important",
    height: "0px !important",
  },
  closeButton: {
    color: "red",
    fontSize: "small",
    fontWeight: "bold",
    justifyContent: "flex-end",
  },
  disabledCriteria: {
    pointerEvents: "none",
  },
});

const noPurchaseStatuses = [
  "DLR_INELIGIBLE_PUR",
  "DLR_ARBITRATION",
  "DLR_OFRD_DB_AUCTION",
];

const stockwaveIntegrationDiv = (car, user, conditionReport) => {
  const data = `{
		VIN: ${conditionReport.vehicleInformation.VIN},
		Year: ${conditionReport.vehicleInformation.Year},
		Make: ${conditionReport.vehicleInformation.Make},
		Model: ${conditionReport.vehicleInformation.Model},
		Trim: ${conditionReport.vehicleInformation.seriesTrim},
		Engine: ${conditionReport.vehicleInformation.Engine},
		Drivetrain: ${conditionReport.vehicleInformation.DriveTrain},
		Transmission: ${conditionReport.vehicleInformation.Transmission},
		Odometer: ${conditionReport.vehicleInformation.Odometer},
		Odometer Unit of Measure: ${conditionReport.vehicleInformation.ODOMETER_UOM},
		Color: ${conditionReport.vehicleInformation.ExtColor},
		Interior Color: ${conditionReport.vehicleInformation.IntColor},
		Interior Type:,
		CR Score: ${conditionReport.gradeScale},
		CR URL:,
		VDP URL:,
		Lane:,
		Run:,
		inventoryId: ${car ? (car.id ? car.id : car.inventoryId) : ""},
		userId: ${user ? user.userId : ""},
		dealershipId: ${user ? user.dealerId : ""}
	}`;
  return (
    <div hidden="hidden" className="stockwaveVehicleInfo">
      {data}
    </div>
  );
};

export class ConditionReport extends Component {
  state = {
    notes: [],
    openNote: false,
    selectedImage: 0,
    previewImage: 0,
    showCarfaxReport: false,
    showAutoCheckReport: false,
    viewerVisible: false,
    view: "dealer",
    bidLoading: false,
    buyLoading: false,
    LCPActiveCar: false,
    hideGuaranteed: false,
    isInWatchList: false,
    carfaxFileDetailsPopup: false,
    carfaxDetails: false,
    showCarfaxSnackBar: false,
  };

  componentWillMount = () => {
    const { dispatch, car, fromReports } = this.props;
    dispatch(hide_mobile_nav_menu(true));
    this.getNotes();
    this.componentRef = React.createRef();
    if (car && car.isInWatchList && !fromReports) {
      this.setState({ isInWatchList: true });
    }
    this.getCarfaxData();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { showCarfaxReport, showAutoCheckReport } = this.state;
    if (
      showCarfaxReport !== prevState.showCarfaxReport ||
      showAutoCheckReport !== prevState.showAutoCheckReport
    ) {
      this.setState({
        showCarfaxReport: false,
        showAutoCheckReport: false,
      });
    }
  };

  getCarfaxData = async () => {
    const { car } = this.props;
    const { conditionReport } = this.props;
    const carfaxDetails = await carfaxService.getCarfaxData(
      car
        ? car.vehicleIdentificationNumber
          ? car.vehicleIdentificationNumber
          : car.vin
        : conditionReport
        ? conditionReport.vin
        : ""
    );
    if (carfaxDetails !== null) {
      this.setState({
        ...this.state,
        carfaxDetails,
      });
    }
  };

  getNotes = async () => {
    const { car, user } = this.props;
    if (car && car.noteAvailable && user) {
      const notes = await getNotes(user.dealerId, car.id);
      this.setState({
        ...this.state,
        notes,
      });
    }
  };

  toggleNote = () => {
    this.setState({
      openNote: !this.state.openNote,
    });
  };

  toggleWatchList = async () => {
    const { user, car, from, updateWatchlist } = this.props;
    let watchListResponse;
    if (car.isInWatchList || (from && from === "watchlist")) {
      watchListResponse = await WatchListServices.removeFromWatchList(
        user,
        car
      );
    } else {
      watchListResponse = await WatchListServices.addToWatchList(user, car);
    }
    this.loadWatchlistData();
    if (watchListResponse) {
      this.setState({
        isInWatchList: !this.state.isInWatchList,
      });
      updateWatchlist(car.vin);
    }
  };

  loadWatchlistData = async () => {
    const { user, dispatch } = this.props;
    const watchListCars = await WatchListServices.getSavedWatchlist(user);
    if (watchListCars && watchListCars.length !== undefined) {
      dispatch(watchlist_count(watchListCars.length));
    }
  };

  toggleGuaranteePayment = (guaranteed) => {
    this.setState({
      guaranteePayment: !this.state.guaranteePayment,
      hideGuaranteed: guaranteed,
    });
  };

  addNote = (newNote) => {
    const { accountSetting, car, user } = this.props;
    const fullNote = {
      inventoryId: car.id,
      dealerId: user.dealerId,
      note: newNote,
      needFollowUp: false,
      userId: user.userId,
      firstName: accountSetting.firstName,
      lastName: accountSetting.lastName,
      createdDate: getDate(),
    };
    this.setState({
      notes: [...this.state.notes, fullNote],
    });
  };

  selectNewImage = (index) => {
    this.setState({
      selectedImage: index,
    });
  };

  previewNewImage = (index) => {
    this.setState({
      previewImage: index,
    });
  };

  resetPreviewImage = () => {
    this.setState({
      previewImage: this.state.selectedImage,
    });
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: true,
    });
  };

  showAutoCheckReport = () => {
    this.setState({
      showAutoCheckReport: !this.state.showAutoCheckReport,
    });
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: true,
    });
  };

  expandDamageImage = (id) => {
    const { inspectionImageDTO } = this.props.conditionReport;
    const imageIndex = inspectionImageDTO.findIndex(
      (image) => image.inspectionImagesId === id
    );
    this.setState({
      viewerVisible: true,
      selectedImage: imageIndex,
    });
  };

  changeReportView = (view) => {
    this.setState({
      view,
    });
  };

  selectNextConditionReport = () => {
    const {
      validConditionReports,
      conditionReport,
      selectConditionReport,
      upstreamCars,
      page,
      selectCar,
      watchlistCar,
      searchCar,
      mobileCarInformation,
      selectMobileCar,
    } = this.props;
    let currentIndex;
    let newCar;
    if (page === "DDO") {
      currentIndex = upstreamCars.indexOf(
        upstreamCars.filter((car) => car.vin === conditionReport.vin)[0]
      );
      newCar =
        currentIndex !== upstreamCars.length - 1
          ? upstreamCars[currentIndex + 1]
          : upstreamCars[0];
      selectCar(newCar);
    } else if (page === "Watchlist") {
      currentIndex = watchlistCar.indexOf(
        watchlistCar.filter((car) => car.vin === conditionReport.vin)[0]
      );
      newCar =
        currentIndex !== watchlistCar.length - 1
          ? watchlistCar[currentIndex + 1]
          : watchlistCar[0];
      selectCar(newCar);
    } else if (page === "Search") {
      currentIndex = searchCar.indexOf(
        searchCar.filter((car) => car.vin === conditionReport.vin)[0]
      );
      newCar =
        currentIndex !== searchCar.length - 1
          ? searchCar[currentIndex + 1]
          : searchCar[0];
      selectCar(newCar);
    } else {
      currentIndex = validConditionReports.indexOf(
        validConditionReports.filter(
          (car) => car.vehicleIdentificationNumber === conditionReport.vin
        )[0]
      );
      newCar =
        currentIndex !== validConditionReports.length - 1
          ? validConditionReports[currentIndex + 1]
          : validConditionReports[0];
      if (mobileCarInformation) {
        selectMobileCar(newCar);
      } else {
        selectConditionReport(newCar, true);
      }
    }
  };

  selectPrevConditionReport = () => {
    const {
      validConditionReports,
      conditionReport,
      selectConditionReport,
      upstreamCars,
      page,
      selectCar,
      watchlistCar,
      searchCar,
      mobileCarInformation,
      selectMobileCar,
    } = this.props;
    let currentIndex;
    let newCar;
    if (page === "DDO") {
      currentIndex = upstreamCars.indexOf(
        upstreamCars.filter((car) => car.vin === conditionReport.vin)[0]
      );
      newCar =
        currentIndex !== 0
          ? upstreamCars[currentIndex - 1]
          : upstreamCars[upstreamCars.length - 1];
      selectCar(newCar);
    } else if (page === "Watchlist") {
      currentIndex = watchlistCar.indexOf(
        watchlistCar.filter((car) => car.vin === conditionReport.vin)[0]
      );
      newCar =
        currentIndex !== 0
          ? watchlistCar[currentIndex - 1]
          : watchlistCar[watchlistCar.length - 1];
      selectCar(newCar);
    } else if (page === "Search") {
      currentIndex = searchCar.indexOf(
        searchCar.filter((car) => car.vin === conditionReport.vin)[0]
      );
      newCar =
        currentIndex !== 0
          ? searchCar[currentIndex - 1]
          : searchCar[searchCar.length - 1];
      selectCar(newCar);
    } else {
      currentIndex = validConditionReports.indexOf(
        validConditionReports.filter(
          (car) => car.vehicleIdentificationNumber === conditionReport.vin
        )[0]
      );
      newCar =
        currentIndex !== 0
          ? validConditionReports[currentIndex - 1]
          : validConditionReports[validConditionReports.length - 1];
      if (mobileCarInformation) {
        selectMobileCar(newCar);
      } else {
        selectConditionReport(newCar, true);
      }
    }
  };

  renderPriceDisplay = () => {
    const { car, classes, currentAuction } = this.props;
    const { name } = this.props.accountSetting.custLocations[0].locationDTO;
    if (
      currentAuction !== "sale1" &&
      currentAuction !== "sale2" &&
      !car.currentBid &&
      car.myCurrentAutoBid > 0
    ) {
      return (
        <div className={classes.autoBid}>
          <Typography className={classes.cardText}>
            <span className={classes.boldedText}>Autobid:</span>
          </Typography>
          <Chip
            label={`$${car.myCurrentAutoBid}`}
            onDelete={this.removeAutoBid}
            className={classes.chip}
            classes={{ root: classes.chipRoot, deleteIcon: classes.deleteIcon }}
            deleteIcon={
              <Tooltip title="Remove Autobid">
                <CancelIcon title="Remove Autobid" alt="Remove Autobid" />
              </Tooltip>
            }
          />
        </div>
      );
    } else if (car.statusCode === "DLR_BID_NOW" && car.bidPlaced) {
      return (
        <Typography
          className={
            name === car.highBidder ? classes.winningBid : classes.losingBid
          }
        >
          <span className={classes.boldedText}>Current Bid:</span>{" "}
          <span>
            {car.currentBid.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            <span
              className={
                name === car.highBidder ? classes.winningBid : classes.losingBid
              }
            >
              {" "}
              <span className={classes.boldedText}>
                {name === car.highBidder ? "WINNING" : "LOSING"}
              </span>
            </span>
          </span>
        </Typography>
      );
    } else {
      return (
        <Typography className={classes.cardText}>
          <span className={classes.redBoldedText}>Start Price: </span>
          <span className={classes.redText}>
            {car.discountedStartPrice
              ? car.discountedStartPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : car.startPrice
              ? car.startPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : null}
          </span>
        </Typography>
      );
    }
  };

  removeAutoBid = async () => {
    const { user, car, updateAutoBid, watchlistCount, dispatch } = this.props;
    const response = await removeAutoBid(user, car);
    if (response.status === "true") {
      updateAutoBid(car.vin, 0, true);
      dispatch(watchlist_count(watchlistCount - 1));
    }
  };

  handleBuy = async () => {
    const { toggleBuyVehicle, dispatch, car } = this.props;
    const { inventoryDTO, inventoryId, saleEventItemId, accountType } =
      this.props.car;
    const { userId, dealerId, loginId } = this.props.user;
    if (accountType === "BALLOON") {
      dispatch(select_car_for_purchase(car));
      toggleBuyVehicle(car, false);
    } else {
      const buyDetails = await VehiclePurchaseServices.buy(
        inventoryDTO ? inventoryDTO.id : inventoryId,
        userId,
        dealerId,
        saleEventItemId,
        loginId
      );
      const buyNowDetails = await VehiclePurchaseServices.buyNow(
        userId,
        dealerId,
        saleEventItemId
      );
      if (buyDetails.status !== "false") {
        if (
          car.inventoryDTO != null &&
          car.inventoryDTO.leaseInformationDTO != null
        ) {
          buyDetails.leaseEndDate =
            car.inventoryDTO.leaseInformationDTO.leaseEndDate;
        }
        dispatch(
          select_car_for_purchase({
            ...buyDetails,
            discountedBuyNowPrice: buyNowDetails.discountedBuyNowPrice,
            buyNowPrice: buyNowDetails.buyNowPrice,
            invStatusInAuction: buyNowDetails.invStatusInAuction,
          })
        );
        toggleBuyVehicle(car, false);
      } else {
        this.setState({
          buyLoading: false,
          buyError: true,
        });
        console.error(
          "Error occurred while retrieving buy details",
          buyDetails
        );
      }
    }
  };

  handlePass = () => {
    const { dispatch, car, togglePass } = this.props;
    dispatch(select_pass_car(car));
    togglePass(false, car);
  };

  toggleLCPCar = () => {
    this.setState({
      LCPActiveCar: !this.state.LCPActiveCar,
    });
  };

  closeConditionReport = (car) => {};
  toggleCarfaxDetailPopup = () => {
    this.setState({
      carfaxFileDetailsPopup: !this.state.carfaxFileDetailsPopup,
    });
  };

  renderGAMDPrice = (remainingPaymentAmount) => {
    const { classes, conditionReport, car, pageFrom, fromMobility } =
      this.props;
    const { hideGuaranteed } = this.state;
    return (
      <React.Fragment>
        {!fromMobility && (
          <React.Fragment>
            <CardContent className={classes.GAMDPriceDisplayTextColumn}>
              {(car &&
                pageFrom === "GAMD" &&
                !car.waivedPayments &&
                car.renderResidualAmount) ||
              (pageFrom !== "GAMD" && car.enableResidualPrice) ? (
                <Typography className={classes.cardText}>
                  <span className={classes.boldedText}>Residual Price: </span>
                  {!fromMobility && (
                    <span className={classes.DDOColorText}>
                      {car.residualAmount
                        ? car.residualAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : car.residualPayAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                    </span>
                  )}
                </Typography>
              ) : null}
              <Typography className={classes.cardText}>
                <span className={classes.boldedText}>Payoff Price: </span>
                <span className={classes.DDOColorText}>
                  {car.grossPayoff
                    ? car.grossPayoff.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : car.grossPayAmount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                </span>
              </Typography>
              <Typography className={classes.cardText}>
                <span className={classes.boldedText}>Market Price: </span>
                <span className={classes.DDOColorText}>
                  {car.isMBPApproved
                    ? car.marketBasedPrice.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "Pending"}
                </span>
              </Typography>
              {!hideGuaranteed &&
              remainingPaymentAmount &&
              remainingPaymentAmount !== null &&
              ((car.displayGuaranteePaymentsOnly !== undefined &&
                car.displayGuaranteePaymentsOnly) ||
                (car.remainingPayGuaranteed !== undefined &&
                  !car.remainingPayGuaranteed)) ? (
                <Typography className={classes.cardText}>
                  <span className={classes.redBoldedText}>
                    Remaining Payments:{" "}
                  </span>
                  <span className={classes.redText}>
                    {remainingPaymentAmount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </Typography>
              ) : null}
              <Typography className={classes.cardText}>
                <span className={classes.boldedText}>Administration Fee:</span>
                <span>
                  {conditionReport.adminFee
                    ? conditionReport.adminFee.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : null}
                </span>
              </Typography>
            </CardContent>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  render() {
    const {
      classes,
      conditionReport,
      car,
      user,
      toggleFullConditionReport,
      fromReports,
      pageFrom,
      handleBuyNow,
      handleBidOpen,
      history,
      updateGuaranteePayment,
      vehicleInformationLoading,
      winLossList,
      externalConditionReportView,
      fromMobility,
    } = this.props;

    const { vin, year, make, model, whyPassed, vehicleInformation } =
      this.props.conditionReport;
    let seriesTrim = vehicleInformation ? vehicleInformation.seriesTrim : null;
    const {
      vehicleLocationName,
      addressLine1,
      vehicleLocationCity,
      vehicleLocationState,
      vehicleLocationPostalCode,
      sellerName,
      businessPhone,
      distance,
      from,
    } = this.props.conditionReport.consignorInformation;
    const {
      notes,
      openNote,
      selectedImage,
      showCarfaxReport,
      viewerVisible,
      view,
      print,
      buyLoading,
      bidLoading,
      guaranteePayment,
      LCPActiveCar,
      hideGuaranteed,
      isInWatchList,
      showAutoCheckReport,
      carfaxFileDetailsPopup,
      carfaxDetails,
      previewImage,
      showCarfaxSnackBar,
    } = this.state;
    let remainingPaymentAmount = null;
    if (car !== undefined) {
      remainingPaymentAmount = car.remainingPaymentAmount
        ? car.remainingPaymentAmount
        : car.remainingPaymentsAmt;
      if (!remainingPaymentAmount && remainingPaymentAmount !== 0) {
        remainingPaymentAmount = car.inventoryDTO
          ? car.inventoryDTO.leaseInformationDTO.remainingPaymentAmount
          : null;
      }
    }
    return (
      <React.Fragment>
        {!vehicleInformationLoading ? (
          <div
            id="conditionReport"
            className={classes.conditionReportWrapper}
            ref={this.componentRef}
          >
            {stockwaveIntegrationDiv(car, user, conditionReport)}
            <div
              className={
                externalConditionReportView
                  ? classes.externalConditionReportViewButtonWrapper
                  : classes.buttonWrapper
              }
            >
              {view !== "retail" ? (
                <Button
                  id="retail"
                  className={classes.button}
                  onClick={() => this.changeReportView("retail")}
                >
                  Retail View
                </Button>
              ) : null}
              {view !== "customer" ? (
                <Button
                  id="customer"
                  className={classes.button}
                  onClick={() => this.changeReportView("customer")}
                >
                  Customer View
                </Button>
              ) : null}
              {view !== "dealer" ? (
                <Button
                  id="dealer"
                  className={classes.button}
                  onClick={() => this.changeReportView("dealer")}
                >
                  Dealer View
                </Button>
              ) : null}
              <ReactToPrint
                trigger={() => (
                  <Button id="print" className={classes.printButton}>
                    Print
                  </Button>
                )}
                content={() => this.componentRef.current}
              />
              {!fromReports && !externalConditionReportView ? (
                <Button
                  id="prevButton"
                  className={classes.button}
                  onClick={() => this.selectPrevConditionReport()}
                >
                  Prev
                </Button>
              ) : null}
              {!fromReports && !externalConditionReportView ? (
                <Button
                  id="nextButton"
                  className={classes.button}
                  onClick={() => this.selectNextConditionReport()}
                >
                  Next
                </Button>
              ) : null}
              {!externalConditionReportView ? (
                <Button
                  id="closeButton"
                  onClick={() => toggleFullConditionReport(car)}
                  className={classes.button}
                >
                  Close
                </Button>
              ) : null}
            </div>
            {conditionReport && conditionReport.inspectionImageDTO ? (
              <Viewer
                activeIndex={selectedImage}
                visible={viewerVisible}
                zoomSpeed={0.3}
                noClose={true}
                onMaskClick={() => this.setState({ viewerVisible: false })}
                onClose={() => this.setState({ viewerVisible: false })}
                images={conditionReport.inspectionImageDTO.map((image) => {
                  return {
                    src: image.imageUrl,
                    alt: image.damageImageCaption,
                  };
                })}
              />
            ) : null}
            <div className={classes.header}>
              <Typography className={classes.headerTitle}>
                {`${year} ${make} ${model} ${seriesTrim}`}{" "}
                <span className={classes.viewText}>
                  ({view.toUpperCase()} VIEW)
                </span>
              </Typography>
            </div>
            {view === "dealer" ? (
              <div className={classes.upperWrapper}>
                <div className={classes.leftUpperWrapper}>
                  <ReactSVG
                    id="carfaxLink"
                    className={classes.carReportLink}
                    src={carfaxLogo}
                    alt="carfax"
                    onClick={() => this.showCarfaxReport()}
                  />
                  <ReactSVG
                    id="autoCheckLogo"
                    className={classes.carReportLink}
                    src={autoCheckLogo}
                    alt="autocheck"
                    onClick={() => this.showAutoCheckReport()}
                  />
                  {!fromReports && !externalConditionReportView ? (
                    <React.Fragment>
                      {user &&
                      userAccess.canPurchaseVehicles(user.userRoles) ? (
                        <Button
                          id="noteButton"
                          className={classes.smallButton}
                          onClick={() => this.toggleNote()}
                        >
                          <NoteAdd
                            className={
                              notes[0] ? classes.hasNotes : classes.blankNotes
                            }
                          />
                        </Button>
                      ) : null}
                      {!fromMobility && pageFrom !== "GAMD" && !winLossList ? (
                        <Button
                          id="watchListBtn"
                          className={classes.smallButton}
                          onClick={() => this.toggleWatchList()}
                          title={
                            isInWatchList
                              ? "Remove from Watchlist"
                              : "Add to Watchlist"
                          }
                        >
                          <ReactSVG
                            src={
                              isInWatchList
                                ? activeWatchlistIcon
                                : watchlistIcon
                            }
                          />
                        </Button>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </div>
                <div id="basicVehicleInformation" className={classes.basicInfo}>
                  <Table>
                    <TableBody>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Inspection Date:
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.inspectionDate}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Inspection Grade:
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.gradeScale}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Consignor:
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {sellerName}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Region:
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.region}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Branch:
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.branch}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Distance:
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {distance ? `${distance.toFixed(2)} miles` : 0}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.boldCell}
                          style={{ verticalAlign: "top" }}
                        >
                          Inspection Location:
                        </TableCell>
                        <TableCell className={classes.subTable}>
                          <Table className={classes.subTable}>
                            <TableBody>
                              <TableRow className={classes.row}>
                                <TableCell className={classes.cell}>
                                  {vehicleLocationName}
                                </TableCell>
                              </TableRow>
                              <TableRow className={classes.row}>
                                <TableCell className={classes.cell}>
                                  {addressLine1}
                                </TableCell>
                              </TableRow>
                              <TableRow className={classes.row}>
                                <TableCell
                                  className={classes.cell}
                                >{`${vehicleLocationCity}, ${vehicleLocationState} ${vehicleLocationPostalCode}`}</TableCell>
                              </TableRow>
                              <TableRow className={classes.row}>
                                <TableCell className={classes.cell}>
                                  {businessPhone}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Announcements:
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.announcements &&
                          conditionReport.announcements.length > 0
                            ? conditionReport.announcements.map(
                                (announcement) => {
                                  return (
                                    <span className={classes.redText}>
                                      {announcement}
                                    </span>
                                  );
                                }
                              )
                            : null}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Why Passed:
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {whyPassed ? whyPassed : null}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Promotion Code:
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.programDtos &&
                          conditionReport.programDtos.length > 0
                            ? conditionReport.programDtos.map((DTO) => {
                                return (
                                  <Link
                                    id="LCPLink"
                                    onClick={() => this.toggleLCPCar()}
                                    className={classes.linkColor}
                                    title={`${DTO.programName}. You are eligible to participate in the ${DTO.programCode} promotion.`}
                                  >
                                    {conditionReport.programDtos[0].programCode}
                                  </Link>
                                );
                              })
                            : null}
                        </TableCell>
                        <TableCell className={classes.cell} />
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

                <Dialog
                  open={carfaxFileDetailsPopup}
                  onClose={() => this.toggleCarfaxDetailPopup()}
                  aria-labelledby="customized-dialog-title"
                  aria-describedby="alert-dialog-slide-description"
                  classes={{ paper: classes.dialogPaper }}
                >
                  <DialogTitle
                    className={classes.dialogText}
                    id="alert-dialog-slide-title"
                  >
                    Carfax File Details
                  </DialogTitle>
                  <DialogContent className={classes.table}>
                    <Table>
                      <TableBody className={classes.tableLeft}>
                        <TableRow className={classes.boldRow}>
                          <TableCell className={classes.popupBoldCell}>
                            Last Odometer Reading
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation
                              ? carfaxDetails.clientCarfaxInformation
                                  .lastOdometer
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.row}>
                          <TableCell className={classes.popupBoldCell}>
                            Last Odometer Reading Date
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation ? (
                              <Moment interval={0} format={"MM/DD/YYYY"}>
                                {
                                  carfaxDetails.clientCarfaxInformation
                                    .lastOdometerEpochMilli
                                }
                              </Moment>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.boldRow}>
                          <TableCell className={classes.popupBoldCell}>
                            Major Damage Flag
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation
                              ? carfaxDetails.clientCarfaxInformation
                                  .majorDamage
                                ? "Y"
                                : "N"
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.row}>
                          <TableCell className={classes.popupBoldCell}>
                            Non-Branded Damage Flag
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation
                              ? carfaxDetails.clientCarfaxInformation
                                  .nonBrandedDamage
                                ? "Y"
                                : "N"
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.boldRow}>
                          <TableCell className={classes.popupBoldCell}>
                            Very Minor or Minor Damage Only Flag
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation
                              ? carfaxDetails.clientCarfaxInformation
                                  .minorDamage
                                ? "Y"
                                : "N"
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.row}>
                          <TableCell className={classes.popupBoldCell}>
                            Odometer Problem Flag
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation
                              ? carfaxDetails.clientCarfaxInformation
                                  .odometerProblem
                                ? "Y"
                                : "N"
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.boldRow}>
                          <TableCell className={classes.popupBoldCell}>
                            Odometer Problem Reporting Date
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation &&
                            carfaxDetails.clientCarfaxInformation
                              .odometerProblemEpochMilli ? (
                              <Moment interval={0} format={"MM/DD/YYYY"}>
                                {
                                  carfaxDetails.clientCarfaxInformation
                                    .odometerProblemEpochMilli
                                }
                              </Moment>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.row}>
                          <TableCell className={classes.popupBoldCell}>
                            Mileage Inconsistency Flag
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation
                              ? carfaxDetails.clientCarfaxInformation
                                  .mileageInconsistency
                                ? "Y"
                                : "N"
                              : ""}
                          </TableCell>
                        </TableRow>
                      </TableBody>

                      <TableBody className={classes.tableRight}>
                        <TableRow className={classes.boldRow}>
                          <TableCell className={classes.popupBoldCell}>
                            Mileage Inconsistency Date
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation &&
                            carfaxDetails.clientCarfaxInformation
                              .mileageInconsistencyEpochMili ? (
                              <Moment interval={0} format={"MM/DD/YYYY"}>
                                {
                                  carfaxDetails.clientCarfaxInformation
                                    .mileageInconsistencyEpochMili
                                }
                              </Moment>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.row}>
                          <TableCell className={classes.popupBoldCell}>
                            Branded Manufacturer Buyback Flag
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation
                              ? carfaxDetails.clientCarfaxInformation
                                  .brandedBuyback
                                ? "Y"
                                : "N"
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.boldRow}>
                          <TableCell className={classes.popupBoldCell}>
                            Non-Branded Manufacturer Buyback Flag
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation
                              ? carfaxDetails.clientCarfaxInformation
                                  .nonBrandedBuyback
                                ? "Y"
                                : "N"
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.row}>
                          <TableCell className={classes.popupBoldCell}>
                            Open Recall Flags
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation
                              ? carfaxDetails.clientCarfaxInformation.openRecall
                                ? "Y"
                                : "N"
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.boldRow}>
                          <TableCell className={classes.popupBoldCell}>
                            Stolen Record Flag
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation
                              ? carfaxDetails.clientCarfaxInformation.stolen
                                ? "Y"
                                : "N"
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.row}>
                          <TableCell className={classes.popupBoldCell}>
                            Stolen Record Date
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation &&
                            carfaxDetails.clientCarfaxInformation
                              .stolenEpochMilli ? (
                              <Moment interval={0} format={"MM/DD/YYYY"}>
                                {
                                  carfaxDetails.clientCarfaxInformation
                                    .stolenEpochMilli
                                }
                              </Moment>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.boldRow}>
                          <TableCell className={classes.popupBoldCell}>
                            Last Ownership Date
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation &&
                            carfaxDetails.clientCarfaxInformation
                              .lastOwnershipEpochMilli ? (
                              <Moment interval={0} format={"MM/DD/YYYY"}>
                                {
                                  carfaxDetails.clientCarfaxInformation
                                    .lastOwnershipEpochMilli
                                }
                              </Moment>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.row}>
                          <TableCell className={classes.popupBoldCell}>
                            Carfax Data As of Date
                          </TableCell>
                          <TableCell className={classes.popupCell}>
                            {carfaxDetails.clientCarfaxInformation &&
                            carfaxDetails.clientCarfaxInformation
                              .lastRefreshEpochMilli ? (
                              <Moment interval={0} format={"MM/DD/YYYY"}>
                                {
                                  carfaxDetails.clientCarfaxInformation
                                    .lastRefreshEpochMilli
                                }
                              </Moment>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </DialogContent>

                  <DialogActions>
                    <Button
                      id="carfaxFileDetailsPopupButton"
                      onClick={() => this.toggleCarfaxDetailPopup()}
                      className={classes.closeButton}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>

                <div>
                  <Table>
                    <TableBody>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          <Tooltip
                            title={
                              <span>
                                Date that Carfax data was
                                <br /> last update
                              </span>
                            }
                            style={{ backgroundColor: "none" }}
                            placement="top-start"
                          >
                            <div>Carfax Data as of Date :</div>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.popupCell}>
                          {carfaxDetails.clientCarfaxInformation &&
                          carfaxDetails.clientCarfaxInformation
                            .lastRefreshEpochMilli ? (
                            <Moment interval={0} format={"MM/DD/YYYY"}>
                              {
                                carfaxDetails.clientCarfaxInformation
                                  .lastRefreshEpochMilli
                              }
                            </Moment>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          <Tooltip
                            title={
                              <span>
                                One or More Carfax <br /> Flags are Present
                              </span>
                            }
                            placement="top-start"
                          >
                            <div>Carfax Alert Indicator :</div>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {carfaxDetails.clientCarfaxInformation
                            ? carfaxDetails.clientCarfaxInformation
                                .majorDamage ||
                              carfaxDetails.clientCarfaxInformation
                                .nonBrandedDamage ||
                              carfaxDetails.clientCarfaxInformation
                                .minorDamage ||
                              carfaxDetails.clientCarfaxInformation
                                .odometerProblem ||
                              carfaxDetails.clientCarfaxInformation
                                .mileageInconsistency ||
                              carfaxDetails.clientCarfaxInformation
                                .brandedBuyback ||
                              carfaxDetails.clientCarfaxInformation
                                .nonBrandedBuyback ||
                              carfaxDetails.clientCarfaxInformation
                                .openRecall ||
                              carfaxDetails.clientCarfaxInformation.stolen
                              ? "Y"
                              : "N"
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          <Typography
                            className={classes.clickableLink}
                            onClick={this.toggleCarfaxDetailPopup}
                          >
                            Carfax Report Details
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

                {openNote ? (
                  <Notes
                    id="notesPanel"
                    toggleNote={this.toggleNote}
                    notes={notes}
                    user={user}
                    addNote={this.addNote}
                    car={car}
                    conditionReport={conditionReport}
                    fromConditionReport
                  />
                ) : null}
                {guaranteePayment ? (
                  <GuaranteePayments
                    car={car}
                    toggleGuaranteePayment={this.toggleGuaranteePayment}
                    user={user}
                    isBuyFlow
                    fromConditionReport
                    updateGuaranteePayment={updateGuaranteePayment}
                  />
                ) : null}
                {conditionReport.programDtos && LCPActiveCar ? (
                  <LCPEntryForm
                    car={car}
                    programDTOInfo={conditionReport.programDtos[0]}
                    toggleLCPCar={this.toggleLCPCar}
                    from="CR"
                  />
                ) : null}
                {!fromMobility &&
                !from &&
                !openNote &&
                !guaranteePayment &&
                !LCPActiveCar &&
                car &&
                !car.vehiclePurchased &&
                noPurchaseStatuses.indexOf(car.dealerFacingStatus) === -1 &&
                car.availabilityStatus !== "NOT_PURCHASABLE" ? (
                  <Card
                    className={
                      car.vin
                        ? classes.purchaseVehicleWrapper
                        : classes.GAMDPurchaseVehicleWrapper
                    }
                  >
                    {car.isBuyNow ||
                    car.dealerFacingStatus === "DLR_BUY_NOW" ||
                    car.dlrFacingStatus === 27107 ||
                    car.isBidNow ? (
                      (pageFrom &&
                        pageFrom === "GAMD" &&
                        car.invStatusInAuction === "OFRD_FIRST_PRE_AUCT") ||
                      (car.isVehAtLoggedInDlrLot &&
                        car.invStatusInAuction === "OFRD_FIRST_PRE_AUCT") ? (
                        this.renderGAMDPrice(remainingPaymentAmount)
                      ) : (
                        <CardContent className={classes.priceDisplayTextColumn}>
                          <Typography className={classes.cardText}>
                            <span className={classes.boldedText}>
                              {car.saleEventName}
                            </span>
                          </Typography>
                          {!car.buyNowDisabled ? (
                            <Typography className={classes.cardText}>
                              <span className={classes.boldedText}>
                                Buy Now Price:{" "}
                              </span>
                              <span className={classes.DDOColorText}>
                                {car.discountedBuyNowPrice
                                  ? car.discountedBuyNowPrice.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }
                                    )
                                  : car.buyNowPrice.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    })}
                              </span>
                            </Typography>
                          ) : (
                            <Typography className={classes.cardText}>
                              <span className={classes.boldedText}>
                                My AutoBid:{" "}
                              </span>
                              <span className={classes.DDOColorText}>
                                {car.myCurrentAutoBid &&
                                  car.myCurrentAutoBid.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })}
                              </span>
                            </Typography>
                          )}
                          {this.renderPriceDisplay()}
                          <Typography className={classes.cardText}>
                            <span className={classes.boldedText}>
                              Administration Fee:
                            </span>
                            <span>
                              {car.buyFee
                                ? car.buyFee.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })
                                : null}
                            </span>
                          </Typography>
                        </CardContent>
                      )
                    ) : (
                      this.renderGAMDPrice(remainingPaymentAmount)
                    )}
                    <CardActions
                      className={
                        car.vin ? classes.cardActions : classes.GAMDCardActions
                      }
                    >
                      {userAccess.canPurchaseVehicles(user.userRoles) ? (
                        <Button
                          id="buyNowButton"
                          className={
                            car.vin ? classes.buyButton : classes.GAMDBuyButton
                          }
                          size="small"
                          classes={{ label: classes.buyBidButtonLabel }}
                          disabled={car.buyNowDisabled}
                          onClick={(evt) => {
                            history.push(
                              pageFrom === "watchList"
                                ? "/watchlist"
                                : pageFrom === "advanceSearch"
                                ? "/advancesearch"
                                : pageFrom === "GAMD"
                                ? "/GAMD"
                                : "/DDO"
                            );
                            this.setState({
                              buyLoading: true,
                            });
                            if (!car.groundingDealer) {
                              handleBuyNow(car);
                            } else {
                              this.handleBuy(
                                car.vin
                                  ? car.vin
                                  : car.vehicleIdentificationNumber
                              );
                            }
                          }}
                        >
                          {!buyLoading ? (
                            <React.Fragment>
                              <ReactSVG
                                svgStyle={{
                                  position: "relative",
                                  top: ".25rem",
                                  marginRight: ".25rem",
                                }}
                                src={shoppingCart}
                              />
                              {car.vin ||
                              car.dealerFacingStatus === "DLR_BUY_NOW"
                                ? "Buy Now"
                                : "BUY"}
                            </React.Fragment>
                          ) : (
                            <Loading color={"#FFFFF"} />
                          )}
                        </Button>
                      ) : null}
                      {!(car.isBuyNow || car.isBidNow) &&
                      !car.vehiclePassed &&
                      !car.passedVehicle &&
                      car.dealerDecisionCode !== "DLR_EXCL_EXP" ? (
                        <Button
                          id="passButton"
                          className={classes.passButton}
                          classes={{ label: classes.buyBidButtonLabel }}
                          size="small"
                          onClick={() => {
                            history.push(
                              pageFrom === "advanceSearch"
                                ? "/advancesearch"
                                : "/GAMD"
                            );
                            this.setState({
                              bidLoading: true,
                            });
                            this.handlePass();
                          }}
                        >
                          {!bidLoading ? (
                            <React.Fragment>
                              <ReactSVG
                                svgStyle={{
                                  position: "relative",
                                  top: ".25rem",
                                  marginRight: ".25rem",
                                }}
                                src={rotateRight}
                              />
                              PASS
                            </React.Fragment>
                          ) : (
                            <Loading color={"#FFFFFF"} />
                          )}
                        </Button>
                      ) : null}
                      {(car.isBidNow ||
                        car.dealerFacingStatus === "DLR_BID_NOW") &&
                      userAccess.canPurchaseVehicles(user.userRoles) ? (
                        <Button
                          id="bidButton"
                          className={classes.bidButton}
                          size="small"
                          disabled={
                            pageFrom && pageFrom === "advanceSearch"
                              ? !car.bidButtonEnabled
                              : !car.bidNowAutoBidButtonEnabled
                          }
                          onClick={() => {
                            history.push(
                              pageFrom === "watchList"
                                ? "/watchlist"
                                : pageFrom === "advanceSearch"
                                ? "/advancesearch"
                                : pageFrom === "GAMD"
                                ? "/GAMD"
                                : "/DDO"
                            );
                            this.setState({
                              bidLoading: true,
                            });
                            handleBidOpen(car);
                          }}
                        >
                          {!bidLoading ? (
                            <span className={classes.buttonIcon}>
                              <ReactSVG src={bidIcon} />
                              Bid
                            </span>
                          ) : (
                            <Loading color={"#FFFFFF"} />
                          )}
                        </Button>
                      ) : null}
                      {this.props.car.displayGuaranteePaymentsOnly &&
                      this.props.car.displayGuaranteePaymentsOnly &&
                      !hideGuaranteed ? (
                        <Button
                          id="guaranteeButton"
                          className={classes.guaranteedPaymentButton}
                          classes={{ label: classes.smallButtonLabel }}
                          size="small"
                          onClick={() => {
                            this.toggleGuaranteePayment(false);
                          }}
                        >
                          <ReactSVG
                            svgStyle={{
                              position: "relative",
                              top: ".25rem",
                              marginRight: ".25rem",
                            }}
                            src={circledCheck}
                          />
                          GUARANTEE
                        </Button>
                      ) : null}
                    </CardActions>
                  </Card>
                ) : null}
              </div>
            ) : (
              <div style={{ height: "5rem", width: "100%" }} />
            )}
            <div
              id="primaryVehicleInformation"
              className={classes.vehicleInfoWrapper}
            >
              <Typography className={classes.sectionHeader}>
                Vehicle Information
              </Typography>
              <div className={classes.vehicleInfoTableWrapper}>
                <div className={classes.interiorVehicleInfoTableWrapper}>
                  <Table>
                    <TableBody>
                      <TableRow className={classes.boldRow}>
                        <TableCell className={classes.boldCell}>VIN</TableCell>
                        <TableCell className={classes.cell}>{vin}</TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>Year</TableCell>
                        <TableCell className={classes.cell}>{year}</TableCell>
                      </TableRow>
                      <TableRow className={classes.boldRow}>
                        <TableCell className={classes.boldCell}>Make</TableCell>
                        <TableCell className={classes.cell}>{make}</TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Model
                        </TableCell>
                        <TableCell className={classes.cell}>{model}</TableCell>
                      </TableRow>
                      <TableRow className={classes.boldRow}>
                        <TableCell className={classes.boldCell}>
                          Model #
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation["Model#"]}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Engine
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.Engine}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.boldRow}>
                        <TableCell className={classes.boldCell}>
                          Doors
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.Doors}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Body Style
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.bodyStyle}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.boldRow}>
                        <TableCell className={classes.boldCell}>
                          Transmission
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.Transmission}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table>
                    <TableBody>
                      <TableRow className={classes.boldRow}>
                        <TableCell className={classes.boldCell}>
                          Drive Train
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.DriveTrain}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Ext. Color
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.ExtColor}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.boldRow}>
                        <TableCell className={classes.boldCell}>
                          Int. Type
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.intType}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Int. Color
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.IntColor}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.boldRow}>
                        <TableCell className={classes.boldCell}>Keys</TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.Keys}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Odometer
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.Odometer}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.boldRow}>
                        <TableCell className={classes.boldCell}>
                          Grounding Mileage
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {
                            conditionReport.vehicleInformation[
                              "Grounding Mileage"
                            ]
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.boldCell}>
                          Account Type
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation.AccountType}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.boldRow}>
                        <TableCell className={classes.boldCell}>Odor</TableCell>
                        <TableCell className={classes.cell}>
                          {conditionReport.vehicleInformation["Odor"]}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
                <TableRow className={classes.row}>
                  <TableCell className={classes.boldCell}>Statements</TableCell>
                  <TableCell className={classes.cell}></TableCell>
                </TableRow>
              </div>
            </div>
            <div id="accessoriesDisplay" className={classes.twoColumnDiv}>
              <div className={classes.smallColumn}>
                <Typography className={classes.sectionHeader}>
                  Accessories
                </Typography>
                <div className={classes.accessoriesWrapper}>
                  {ConditionReport.accessoriesList == null
                    ? null
                    : conditionReport.accessoriesList.map((accessory) => {
                        return (
                          <Typography key={accessory}>{accessory}</Typography>
                        );
                      })}
                </div>
                <div className={classes.optionsWrapper}>
                  {conditionReport.optionsEntrySet == null
                    ? null
                    : conditionReport.optionsEntrySet.map((option) => {
                        return (
                          <Typography key={option.key}>
                            {`${option.key}: ${option.value}`}
                          </Typography>
                        );
                      })}
                </div>
              </div>
              <div id="wheelsAndTires" className={classes.smallColumn}>
                <Typography className={classes.sectionHeader}>
                  Wheels and Tires
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.centeredCell} />
                      <TableCell className={classes.centeredCell}>
                        Brand
                      </TableCell>
                      <TableCell className={classes.centeredCell}>
                        Size
                      </TableCell>
                      <TableCell className={classes.centeredCell}>
                        Wheel
                      </TableCell>
                      <TableCell className={classes.centeredCell}>
                        Tread Depth
                      </TableCell>
                    </TableRow>
                    {conditionReport.tires.map((tire) => {
                      return (
                        <TableRow key={tire.location}>
                          <TableCell className={classes.centeredCell}>
                            {tire.location}
                          </TableCell>
                          <TableCell className={classes.centeredCell}>
                            {tire.brand}
                          </TableCell>
                          <TableCell className={classes.centeredCell}>
                            {tire.size}
                          </TableCell>
                          <TableCell className={classes.centeredCell}>
                            {tire.wheel}
                          </TableCell>
                          <TableCell className={classes.centeredCell}>
                            {tire.treadDepth}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
            {!print ? (
              <div id="imageWrapper" className={classes.imageWrapper}>
                <Typography className={classes.sectionHeader}>
                  Inspection Images
                </Typography>
                <div className={classes.imageViewer}>
                  {conditionReport.inspectionImageDTO.length ? (
                    <img
                      onClick={() => this.toggleImageViewer()}
                      className={classes.bigImage}
                      src={
                        conditionReport.inspectionImageDTO[previewImage]
                          .imageUrl
                      }
                      alt="inspectionImage"
                    />
                  ) : null}
                  <div className={classes.smallImageWrapper}>
                    {conditionReport.inspectionImageDTO.map((image, index) => {
                      return (
                        <img
                          id={image.inspectionImagesId}
                          key={image.inspectionImagesId}
                          onClick={() => this.selectNewImage(index)}
                          onMouseOver={() => this.previewNewImage(index)}
                          onMouseLeave={() => this.resetPreviewImage()}
                          className={classes.smallImage}
                          src={image.imageUrl}
                          alt="inspectionImage"
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
            <div
              id="vehicleConditionWrapper"
              className={classes.vehicleConditionWrapper}
            >
              <Typography className={classes.sectionHeader}>
                {`Vehicle Condition `}
                {view === "dealer"
                  ? `Grade - ${conditionReport.gradeScale}`
                  : null}
              </Typography>
              {view !== "retail" ? (
                <React.Fragment>
                  <Typography>
                    {`Labor Rate: ${conditionReport.partRate} Paint Rate: ${conditionReport.paintRate}`}
                  </Typography>
                  <Typography className={classes.redText}>
                    *Damage estimates are included for reference and may not be
                    reflective of the true repair cost
                  </Typography>
                </React.Fragment>
              ) : null}
              {conditionReport.vehicleConditions.map((area) => {
                return area.damages.length > 0 ? (
                  view === "customer" &&
                  area.damages.filter((damage) => damage.wearAndTear !== "N")
                    .length === 0 ? null : (
                    <div key={area.type}>
                      <Typography style={{ fontWeight: "bold" }}>
                        {area.type}:
                      </Typography>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell />
                            <TableCell className={classes.centeredCell}>
                              Damage Area
                            </TableCell>
                            {view !== "retail" ? (
                              <React.Fragment>
                                <TableCell className={classes.centeredCell}>
                                  Labor Hours
                                </TableCell>
                                <TableCell className={classes.centeredCell}>
                                  Paint Hours
                                </TableCell>
                                <TableCell className={classes.centeredCell}>
                                  Part Cost
                                </TableCell>
                                <TableCell className={classes.centeredCell}>
                                  Estimate
                                </TableCell>
                                <TableCell className={classes.centeredCell}>
                                  {"W&T"}
                                </TableCell>
                              </React.Fragment>
                            ) : null}
                          </TableRow>
                          {area.damages.map((damage, index) => {
                            return view === "customer" &&
                              damage.wearAndTear === "N" ? null : (
                              <TableRow
                                key={
                                  damage.inspectionImageDTO
                                    ? damage.inspectionImageDTO
                                        .inspectionImagesId
                                    : index
                                }
                              >
                                <TableCell className={classes.damageImageCell}>
                                  {damage.inspectionImageDTO ? (
                                    <img
                                      onClick={() =>
                                        this.expandDamageImage(
                                          damage.inspectionImageDTO
                                            .inspectionImagesId
                                        )
                                      }
                                      className={classes.damageImage}
                                      src={damage.inspectionImageDTO.imageUrl}
                                      alt="damageImage"
                                    />
                                  ) : null}
                                </TableCell>
                                <TableCell
                                  className={classes.damageDescriptionCell}
                                >
                                  {`${damage.area} - ${damage.type}`}
                                </TableCell>
                                {view !== "retail" ? (
                                  <React.Fragment>
                                    <TableCell className={classes.centeredCell}>
                                      {damage.estimatedLabourHours}
                                    </TableCell>
                                    <TableCell className={classes.centeredCell}>
                                      {damage.partPaintHours}
                                    </TableCell>
                                    <TableCell className={classes.centeredCell}>
                                      {`${damage.partCost.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "USD",
                                        }
                                      )}`}
                                    </TableCell>
                                    <TableCell className={classes.centeredCell}>
                                      {`${damage.estimatedRepairCost.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "USD",
                                        }
                                      )}`}
                                    </TableCell>
                                    <TableCell className={classes.centeredCell}>
                                      {damage.wearAndTear}
                                    </TableCell>
                                  </React.Fragment>
                                ) : null}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      {view !== "retail" ? (
                        <Typography
                          style={{
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {view === "customer"
                            ? `Excess Wear and Tear Subtotal: ${area.excessWearAndTearSubTotal.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                }
                              )} USD`
                            : `Estimated Subtotal: ${area.estimateSubTotal.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                }
                              )} USD`}
                        </Typography>
                      ) : null}
                    </div>
                  )
                ) : null;
              })}
              {view !== "retail" ? (
                <Typography
                  id="wearAndTearEstimates"
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <span className={classes.grandTotalWrapper}>
                    {view !== "customer" ? (
                      <span>
                        {`Normal Wear and Tear Total: ${conditionReport.normalWearAndTearGrandTotal.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )} USD`}
                      </span>
                    ) : null}
                    <span>
                      {`Excess Wear and Tear Total: ${conditionReport.excessWearAndTearGrandTotal.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )} USD`}
                    </span>
                    {view !== "customer" ? (
                      <span>
                        {`Estimated Grand Total: ${conditionReport.estimatedGrandTotal.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )} USD`}
                      </span>
                    ) : null}
                  </span>
                </Typography>
              ) : null}
              <div id="buildData" className={classes.buildDataWrapper}>
                <Typography className={classes.sectionHeader}>
                  Build Data
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.centeredCell}>
                        Code
                      </TableCell>
                      <TableCell className={classes.centeredCell}>
                        Description
                      </TableCell>
                      <TableCell className={classes.centeredCell}>
                        Package Details
                      </TableCell>
                    </TableRow>
                    {conditionReport.oemInformation &&
                      conditionReport.oemInformation.map((code) => {
                        return (
                          <TableRow key={code.code}>
                            <TableCell className={classes.centeredCell}>
                              {code.code}
                            </TableCell>
                            <TableCell className={classes.centeredCell}>
                              {code.description}
                            </TableCell>
                            <TableCell className={classes.centeredCell}>
                              {code.packageDetails}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <Typography className={classes.disclaimer}>
                  Disclaimer: The parts, equipment, accessories, and other
                  information listed above are based on equipment/configuration
                  at the time vehicle was sold by Toyota Motor Sales, U.S.A,
                  Inc. (TMS/USA) to a dealer and does not mean that this vehicle
                  is still so equipped.
                </Typography>
              </div>
            </div>
            {showAutoCheckReport ? (
              <AutoCheckReport
                vin={conditionReport.vin}
                reportType="carfax"
                showAutoCheckReport={this.showAutoCheckReport}
                user={user}
              />
            ) : null}
            {showCarfaxReport ? (
              <CarReport
                vin={conditionReport.vin}
                reportType="carfax"
                showCarfaxReport={this.showCarfaxReport}
                showNoDataVin={this.showNoDataVin}
                user={user}
              />
            ) : null}
            {showCarfaxSnackBar ? (
              <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
            ) : null}
          </div>
        ) : (
          <Loading />
        )}
      </React.Fragment>
    );
  }
}

ConditionReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    accountSetting: state.accountSetting,
    watchlistCount: state.cars.watchlistCount,
    mobileCarInformation: state.cars.mobileCarInformation,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(ConditionReport));
