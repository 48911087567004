import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import ReactSVG from "react-svg";
import circleDDOIcon from "../assets/MobileDDONav.svg";
import circleGAMDIcon from "../assets/MobileGAMDNav.svg";
import circleGroundIcon from "../assets/MobileGroundNav.svg";

const styles = (theme) => ({
  container: {
    height: "3rem",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    bottom: 0,
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    zIndex: 9999,
  },
  navIcon: {
    position: "relative",
    bottom: "2.5rem",
  },
});

export class MobileNavMenu extends PureComponent {
  render() {
    const { classes, history, user, hideMobileNavMenu } = this.props;
    return (
      <React.Fragment>
        {hideMobileNavMenu ? null : user.loginId && !user.resetPassword ? (
          <div className={classes.container}>
            <ReactSVG
              src={circleGroundIcon}
              className={classes.navIcon}
              onClick={() => history.push("/ground")}
            />
            <ReactSVG
              src={circleGAMDIcon}
              className={classes.navIcon}
              onClick={() => history.push("/GAMD")}
            />
            <ReactSVG
              src={circleDDOIcon}
              className={classes.navIcon}
              onClick={() => history.push("/DDO")}
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    hideMobileNavMenu: state.ui.hideMobileNavMenu,
    mobileCarInformation: state.cars.mobileCarInformation,
  };
};

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(MobileNavMenu))
);
