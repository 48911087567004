import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import * as R from "ramda";

import {
  Button,
  Typography,
  Card,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { store_filter_options } from "../actions/uiActions";
import SearchCheckboxOptions from "../components/SearchCheckboxOptions";
import ReactSVG from "react-svg";
import sort from "../assets/sort.svg";

const styles = (theme) => ({
  filterWrapper: {
    margin: "0.5rem",
    fontSize: ".8rem",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      marginLeft: ".25rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5rem",
    },
  },
  button: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "10rem",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      "&:hover": {
        backgroundColor: theme.palette.button.main,
        "@media (hover: none)": {
          backgroundColor: theme.palette.button.main,
          "&:active": {
            backgroundColor: theme.palette.button.main,
          },
        },
      },
    },
  },
  searchResetButton: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.primary.gray,
    width: "10rem",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      "&:hover": {
        backgroundColor: theme.palette.primary.gray,
        "@media (hover: none)": {
          backgroundColor: theme.palette.primary.gray,
          "&:active": {
            backgroundColor: theme.palette.primary.gray,
          },
        },
      },
    },
  },
  selectionButtonGAMD: {
    backgroundColor: theme.palette.GAMD.main,
    width: "2.5rem",
    height: "2.00rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  selectionButtonDDO: {
    backgroundColor: theme.palette.DDO.main,
    width: "2.5rem",
    height: "2.00rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  selectionButtonSearch: {
    backgroundColor: theme.palette.primary.gray,
    width: "2.5rem",
    height: "2.00rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
    "&:hover": {
      opacity: ".5",
    },
  },
  sortDiv: {
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
  },
  select: {
    width: "10.00rem",
    margin: "0.2rem",
  },
  sortLabel: {
    margin: "1rem",
  },
  cbIconWrapper: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
  panel: {
    marginRight: "0.5rem",
    width: "72%",
    fontSize: ".8rem",
    [theme.breakpoints.down("md")]: {
      width: "9rem",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  innerPanel: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: ".5rem",
      paddingRight: 0,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  panelDetail: {
    width: "100%",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("md")]: {
      padding: 0,
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  labelText: {
    [theme.breakpoints.down("md")]: {
      fontSize: theme.font.small,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  "@global": {
    ".MuiChip-label": {
      fontSize: ".6rem",
    },
  },
});

export class Filter extends Component {
  state = {
    filterColor: [],
    selectedColor: [],
    filterDistance: [
      "<50 mi",
      "<100 mi",
      "<200 mi",
      "<300 mi",
      "<400 mi",
      "<500 mi",
      "<750 mi",
      "<1000 mi",
      "<1500 mi",
      "<2000 mi",
    ],
    selectedDistance: [],
    filterYear: [],
    selectYear: [],
    filterBodyStyle: [],
    selectBodyStyle: [],
    filterMake: [],
    selectMake: [],
    filterModel: [],
    selectModel: [],
    filterSeries: [],
    selectSeries: [],
    filterState: [],
    selectState: [],
    filterGrade: [],
    selectGrade: [],
    filterStatus: [],
    selectStatus: [],
    filterPrice: [],
    selectPrice: [],
    filterOdometer: [],
    selectOdometer: [],
  };

  componentWillMount = () => {
    const { filterOptions, totalCount, dispatch, from, fromCR } = this.props;
    if (filterOptions && totalCount > 0) {
      this.setState({
        ...this.state,
        ...filterOptions,
      });
    }
    this.parseFilterData();
    if (from !== "search") {
      dispatch(store_filter_options(null));
      if (!fromCR) {
        this.clearSearchGamdAndDdo();
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.matchCount !== prevProps.matchCount ||
      this.props.totalCount !== prevProps.totalCount
    ) {
      this.parseFilterData();
    }
    if (this.props.fromTab !== prevProps.fromTab) {
      this.clearSearchGamdAndDdo();
    }
  };

  clearSearch = () => {
    const { selectionChanged } = this.props;
    const newState = {
      ...this.state,
      selectedColor: [],
      selectedDistance: [],
      selectYear: [],
      selectBodyStyle: [],
      selectMake: [],
      selectModel: [],
      selectSeries: [],
      selectState: [],
      selectGrade: [],
      selectStatus: [],
      selectPrice: [],
      selectOdometer: [],
    };
    this.setState(newState);
    selectionChanged(newState);
  };

  clearSearchGamdAndDdo = () => {
    const { selectionChanged } = this.props;
    const newState = {
      selectedColor: [],
      selectedDistance: [],
      selectYear: [],
      selectBodyStyle: [],
      selectMake: [],
      selectModel: [],
      selectSeries: [],
      selectState: [],
      selectGrade: [],
      selectStatus: [],
      selectPrice: [],
      selectOdometer: [],
    };
    this.setState(newState);
    selectionChanged(newState);
  };

  parseFilterData = () => {
    const { filterCars, from, unfilteredCars } = this.props;
    const { filterState } = this.state;
    if (from === "ddo") {
      this.setState({
        filterColor: [
          ...new Set(this.search("color", filterCars).map((item) => item)),
        ],
        filterYear: [
          ...new Set(this.search("year", filterCars).map((item) => item)),
        ],
        filterBodyStyle: [
          ...new Set(this.search("bodyStyle", filterCars).map((item) => item)),
        ],
        filterMake: [
          ...new Set(this.search("make", filterCars).map((item) => item)),
        ],
        filterModel: [
          ...new Set(this.search("model", filterCars).map((item) => item)),
        ],
        filterSeries: [
          ...new Set(this.search("series", filterCars).map((item) => item)),
        ],
        filterState:
          filterState.length === 0
            ? [
                ...new Set(
                  this.search("state", unfilteredCars).map((item) => item)
                ),
              ]
            : this.state.filterState,
        filterGrade: [
          ...new Set(this.search("grade", filterCars).map((item) => item)),
        ],
        filterStatus: [
          ...new Set(
            this.search("dlrFacingStatusName", filterCars).map((item) => item)
          ),
        ],
        filterPrice: [
          ...new Set(this.search("startPrice", filterCars).map((item) => item)),
        ],
        filterOdometer: [
          ...new Set(this.search("odometer", filterCars).map((item) => item)),
        ],
      });
    } else if (from === "gamd") {
      this.setState({
        filterYear: [
          ...new Set(this.search("year", filterCars).map((item) => item)),
        ],
        filterBodyStyle: [
          ...new Set(this.search("bodyType", filterCars).map((item) => item)),
        ],
        filterMake: [
          ...new Set(this.search("make", filterCars).map((item) => item)),
        ],
        filterModel: [
          ...new Set(
            this.search("modelWithOutTrim", filterCars).map((item) => item)
          ),
        ],
        filterSeries: [
          ...new Set(this.search("trim", filterCars).map((item) => item)),
        ],
      });
    } else if (from === "search") {
      this.setState({
        filterColor: [
          ...new Set(this.search("color", filterCars).map((item) => item)),
        ],
        filterYear: [
          ...new Set(this.search("year", filterCars).map((item) => item)),
        ],
        filterBodyStyle: [
          ...new Set(this.search("bodyStyle", filterCars).map((item) => item)),
        ],
        filterMake: [
          ...new Set(this.search("make", filterCars).map((item) => item)),
        ],
        filterModel: [
          ...new Set(this.search("model", filterCars).map((item) => item)),
        ],
        filterSeries: [
          ...new Set(this.search("series", filterCars).map((item) => item)),
        ],
        filterState:
          filterState.length === 0
            ? [
                ...new Set(
                  this.search("state", unfilteredCars).map((item) => item)
                ),
              ]
            : this.state.filterState,
        filterGrade: [
          ...new Set(this.search("grade", filterCars).map((item) => item)),
        ],
        filterStatus: [
          ...new Set(
            this.search("dlrFacingStatusName", filterCars).map((item) => item)
          ),
        ],
        filterOdometer: [
          ...new Set(this.search("odometer", filterCars).map((item) => item)),
        ],
      });
    }
  };

  search = (nameKey, myArray) => {
    let key = [];
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i][nameKey]) {
        key.push(myArray[i][nameKey].toString());
      }
    }
    return key;
  };

  handleChipDelete = (stateKey, option) => {
    const { selectionChanged } = this.props;
    const modKey = R.lensPath([stateKey]);
    const removeIndex = R.indexOf(option, R.view(modKey, this.state));
    const newState = R.set(
      modKey,
      R.remove(removeIndex, 1, R.view(modKey, this.state)),
      this.state
    );
    this.setState(newState);
    const param = {
      selectedColor: newState.selectedColor,
      selectedDistance: newState.selectedDistance,
      selectYear: newState.selectYear,
      selectBodyStyle: newState.selectBodyStyle,
      selectMake: newState.selectMake,
      selectModel: newState.selectModel,
      selectSeries: newState.selectSeries,
      selectState: newState.selectState,
      selectGrade: newState.selectGrade,
      selectStatus: newState.selectStatus,
      selectPrice: newState.selectPrice,
      selectOdometer: newState.selectOdometer,
    };
    selectionChanged(param, true);
  };

  isChecked = (key, value) => {
    return this.state[key].includes(value);
  };

  handleCheck = (name) => (evt) => {
    const { selectionChanged } = this.props;
    if (this.state[name].includes(evt.target.value)) {
      const newState = {
        ...this.state,
        [name]: this.state[name].filter((value) => value !== evt.target.value),
      };
      selectionChanged(newState, true);
      return this.setState(newState);
    } else {
      const newState = {
        ...this.state,
        [name]: [...this.state[name], evt.target.value],
      };
      selectionChanged(newState);
      return this.setState(newState);
    }
  };

  buildQuery = () => {
    let param = {
      selectedColor: this.state.selectedColor,
      selectedDistance: this.state.selectedDistance,
      selectYear: this.state.selectYear,
      selectBodyStyle: this.state.selectBodyStyle,
      selectMake: this.state.selectMake,
      selectModel: this.state.selectModel,
      selectSeries: this.state.selectSeries,
      selectState: this.state.selectState,
      selectGrade: this.state.selectGrade,
      selectStatus: this.state.selectStatus,
      selectPrice: this.state.selectPrice,
      selectOdometer: this.state.selectOdometer,
    };
    return param;
  };

  renderPriceOptions = () => {
    const { filterPrice } = this.state;
    let priceRangeArray = [];
    for (let i = 0; i < filterPrice.length; i++) {
      if (filterPrice[i] > 0 && filterPrice[i] < 10000) {
        priceRangeArray.push("$0-$10,000");
      } else if (filterPrice[i] >= 10000 && filterPrice[i] < 15000) {
        priceRangeArray.push("$10,000-$15,000");
      } else if (filterPrice[i] >= 15000 && filterPrice[i] < 20000) {
        priceRangeArray.push("$15,000-$20,000");
      } else if (filterPrice[i] >= 20000 && filterPrice[i] < 25000) {
        priceRangeArray.push("$20,000-$25,000");
      } else if (filterPrice[i] >= 25000 && filterPrice[i] < 30000) {
        priceRangeArray.push("$25,000-$30,000");
      } else if (filterPrice[i] >= 30000 && filterPrice[i] < 35000) {
        priceRangeArray.push("$30,000-$35,000");
      } else if (filterPrice[i] >= 35000 && filterPrice[i] < 40000) {
        priceRangeArray.push("$35,000-$40,000");
      } else if (filterPrice[i] >= 40000 && filterPrice[i] < 45000) {
        priceRangeArray.push("$40,000-$45,000");
      } else if (filterPrice[i] >= 45000 && filterPrice[i] < 50000) {
        priceRangeArray.push("$45,000-$50,000");
      } else if (filterPrice[i] >= 50000 && filterPrice[i] < 55000) {
        priceRangeArray.push("$50,000-$55,000");
      } else if (filterPrice[i] >= 55000 && filterPrice[i] < 60000) {
        priceRangeArray.push("$55,000-$60,000");
      } else if (filterPrice[i] >= 60000 && filterPrice[i] < 65000) {
        priceRangeArray.push("$60,000-$65,000");
      } else if (filterPrice[i] >= 65000 && filterPrice[i] < 75000) {
        priceRangeArray.push("$65,000-$75,000");
      } else if (filterPrice[i] >= 75000 && filterPrice[i] < 100000) {
        priceRangeArray.push("$75,000-$100,000");
      }
    }
    let priceRange = [...new Set(priceRangeArray.map((item) => item))];
    return this.renderCheckboxOptions(
      "Buy Now Price",
      priceRange,
      "selectPrice"
    );
  };

  renderOdometerOptions = () => {
    const { filterOdometer, selectOdometer } = this.state;
    let odoRangeArray = [];
    for (let i = 0; i < filterOdometer.length; i++) {
      if (filterOdometer[i] > 0 && filterOdometer[i] < 10000) {
        odoRangeArray.push("0-10,000");
      } else if (filterOdometer[i] >= 10000 && filterOdometer[i] < 20000) {
        odoRangeArray.push("10,000-20,000");
      } else if (filterOdometer[i] >= 20000 && filterOdometer[i] < 30000) {
        odoRangeArray.push("20,000-30,000");
      } else if (filterOdometer[i] >= 30000 && filterOdometer[i] < 40000) {
        odoRangeArray.push("30,000-40,000");
      } else if (filterOdometer[i] >= 40000 && filterOdometer[i] < 50000) {
        odoRangeArray.push("40,000-50,000");
      } else if (filterOdometer[i] >= 50000 && filterOdometer[i] < 60000) {
        odoRangeArray.push("50,000-60,000");
      } else if (filterOdometer[i] >= 60000 && filterOdometer[i] < 70000) {
        odoRangeArray.push("60,000-70,000");
      } else if (filterOdometer[i] >= 70000 && filterOdometer[i] < 80000) {
        odoRangeArray.push("70,000-80,000");
      } else if (filterOdometer[i] >= 80000 && filterOdometer[i] < 90000) {
        odoRangeArray.push("80,000-90,000");
      } else if (filterOdometer[i] >= 90000 && filterOdometer[i] < 100000) {
        odoRangeArray.push("90,000-100,000");
      }
    }
    let odometerRange = [...new Set(odoRangeArray.map((item) => item))];
    return this.renderCheckboxOptions(
      "Odometer",
      odometerRange,
      "selectOdometer",
      selectOdometer.length > 0 ? true : false
    );
  };

  renderGradeOptions = () => {
    const { filterGrade, selectGrade } = this.state;
    let gradeArray = [];
    for (let i = 0; i < filterGrade.length; i++) {
      if (
        parseFloat(filterGrade[i]) >= 1.0 &&
        parseFloat(filterGrade[i]) < 2.0
      ) {
        gradeArray.push("1-2");
      } else if (
        parseFloat(filterGrade[i]) >= 2.0 &&
        parseFloat(filterGrade[i]) < 3.0
      ) {
        gradeArray.push("2-3");
      } else if (
        parseFloat(filterGrade[i]) >= 3.0 &&
        parseFloat(filterGrade[i]) < 4.0
      ) {
        gradeArray.push("3-4");
      } else if (
        parseFloat(filterGrade[i]) >= 4.0 &&
        parseFloat(filterGrade[i]) < 5.0
      ) {
        gradeArray.push("4-5");
      } else if (parseFloat(filterGrade[i]) === 5.0) {
        gradeArray.push("5");
      }
    }
    let gradeRange = [...new Set(gradeArray.map((item) => item))];
    return this.renderCheckboxOptions(
      "Grade",
      gradeRange,
      "selectGrade",
      selectGrade.length > 0 ? true : false
    );
  };

  renderCheckboxOptions = (summary, criteria, stateKey, isDisabled) => {
    const { classes } = this.props;
    return (
      <ExpansionPanel className={classes.panel}>
        <ExpansionPanelSummary
          className={classes.innerPanel}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className={classes.heading}>
            <Typography className={classes.labelText}>{summary}</Typography>
            <div className={classes.chipSection}>
              {this.state[stateKey].map((option) => {
                return (
                  <Chip
                    variant="outlined"
                    key={option}
                    onDelete={() => this.handleChipDelete(stateKey, option)}
                    label={option}
                    value={option}
                  />
                );
              })}
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetail}>
          <SearchCheckboxOptions
            criteria={criteria}
            stateKey={stateKey}
            isChecked={this.isChecked}
            handleCheck={this.handleCheck}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  onSortSelectChange = (event) => {
    this.setState({
      sortSelect: event.target.value,
    });
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    const {
      selectColor,
      selectYear,
      selectBodyStyle,
      selectMake,
      selectModel,
      selectSeries,
      selectState,
      selectGrade,
      selectStatus,
      selectPrice,
      selectOdometer,
      selectedDistance,
      selectedColor,
    } = this.state;
    const options = {
      selectColor,
      selectYear,
      selectBodyStyle,
      selectMake,
      selectModel,
      selectSeries,
      selectState,
      selectGrade,
      selectStatus,
      selectPrice,
      selectOdometer,
      selectedDistance,
      selectedColor,
    };
    dispatch(store_filter_options(options));
  };
  render() {
    const { classes, matchCount, totalCount, sortCars, from } = this.props;
    const {
      filterColor,
      filterDistance,
      filterYear,
      filterBodyStyle,
      filterMake,
      filterModel,
      filterSeries,
      filterState,
      filterGrade,
      filterStatus,
      filterPrice,
      filterOdometer,
      selectedColor,
      selectYear,
      selectBodyStyle,
      selectMake,
      selectModel,
      selectSeries,
      selectState,
      selectStatus,
      selectedDistance,
    } = this.state;
    return (
      <React.Fragment>
        <div className={classes.filterWrapper}>
          <Typography>
            {" "}
            {matchCount} out of {totalCount} matches
          </Typography>
          <div className={classes.cbIconWrapper}>
            {filterColor.length !== 0
              ? this.renderCheckboxOptions(
                  "Color",
                  filterColor,
                  "selectedColor",
                  selectedColor.length > 0 ? true : false
                )
              : null}
            {filterColor.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("Color")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          {from !== "gamd" ? (
            <div className={classes.cbIconWrapper}>
              {filterDistance.length !== 0
                ? this.renderCheckboxOptions(
                    "Distance",
                    filterDistance,
                    "selectedDistance",
                    selectedDistance.length > 0 ? true : false
                  )
                : null}
              {filterDistance.length !== 0 ? (
                <Card
                  id="sortButton"
                  className={
                    from === "ddo"
                      ? classes.selectionButtonDDO
                      : from === "gamd"
                      ? classes.selectionButtonGAMD
                      : classes.selectionButtonSearch
                  }
                  onClick={() => sortCars("Distance")}
                >
                  <ReactSVG
                    svgStyle={{ fill: "white", marginTop: ".4rem" }}
                    src={sort}
                    alt="sortIcon"
                  />
                </Card>
              ) : null}
            </div>
          ) : null}
          <div className={classes.cbIconWrapper}>
            {filterYear.length !== 0
              ? this.renderCheckboxOptions(
                  "Year",
                  filterYear,
                  "selectYear",
                  selectYear.length > 0 ? true : false
                )
              : null}
            {filterYear.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("Year")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          <div className={classes.cbIconWrapper}>
            {filterBodyStyle.length !== 0
              ? this.renderCheckboxOptions(
                  "Body/Style",
                  filterBodyStyle,
                  "selectBodyStyle",
                  selectBodyStyle.length > 0 ? true : false
                )
              : null}
            {filterBodyStyle.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("Body/Style")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          <div className={classes.cbIconWrapper}>
            {filterMake.length !== 0
              ? this.renderCheckboxOptions(
                  "Make",
                  filterMake,
                  "selectMake",
                  selectMake.length > 0 ? true : false
                )
              : null}
            {filterMake.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("Make")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          <div className={classes.cbIconWrapper}>
            {filterModel.length !== 0
              ? this.renderCheckboxOptions(
                  "Model",
                  filterModel,
                  "selectModel",
                  selectModel.length > 0 ? true : false
                )
              : null}
            {filterModel.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("Model")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          <div className={classes.cbIconWrapper}>
            {filterSeries.length !== 0
              ? this.renderCheckboxOptions(
                  "Series",
                  filterSeries,
                  "selectSeries",
                  selectSeries.length > 0 ? true : false
                )
              : null}
            {filterSeries.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("Series")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          <div className={classes.cbIconWrapper}>
            {filterState.length !== 0
              ? this.renderCheckboxOptions(
                  "State",
                  filterState,
                  "selectState",
                  selectState.length > 0 ? true : false
                )
              : null}
            {filterState.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("State")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          <div className={classes.cbIconWrapper}>
            {filterGrade.length !== 0 ? this.renderGradeOptions() : null}
            {filterGrade.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("Grade")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          <div className={classes.cbIconWrapper}>
            {filterStatus.length !== 0
              ? this.renderCheckboxOptions(
                  "Status",
                  filterStatus,
                  "selectStatus",
                  selectStatus.length > 0 ? true : false
                )
              : null}
            {filterStatus.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("Status")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          <div className={classes.cbIconWrapper}>
            {filterPrice.length !== 0 ? this.renderPriceOptions() : null}
            {filterPrice.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("Buy Now Price")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          <div className={classes.cbIconWrapper}>
            {filterOdometer.length !== 0 ? this.renderOdometerOptions() : null}
            {filterOdometer.length !== 0 ? (
              <Card
                id="sortButton"
                className={
                  from === "ddo"
                    ? classes.selectionButtonDDO
                    : from === "gamd"
                    ? classes.selectionButtonGAMD
                    : classes.selectionButtonSearch
                }
                onClick={() => sortCars("Odometer")}
              >
                <ReactSVG
                  svgStyle={{ fill: "white", marginTop: ".4rem" }}
                  src={sort}
                  alt="sortIcon"
                />
              </Card>
            ) : null}
          </div>
          <Button
            id="clearSearch"
            className={
              from === "search" ? classes.searchResetButton : classes.button
            }
            onClick={(evt) => this.clearSearch()}
          >
            Reset Filter
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filterOptions: state.ui.filterOptions,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Filter));
