import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Loading from "../components/Loading";

import ConditionReport from "../components/ConditionReport";
import * as ConditionReportService from "../services/ConditionReportService";

import { Typography } from "@material-ui/core";

import { set_external_condition_report_view } from "../actions/uiActions";

const styles = (theme) => ({});

export class ConditionReportExternalWrapper extends Component {
  state = {
    conditionReport: null,
    error: false,
  };

  componentWillMount = () => {
    const { dispatch } = this.props;
    dispatch(set_external_condition_report_view());
    const { inventoryId, userId, dealerId } = this.props.match.params;
    this.getConditionReport(inventoryId, userId, dealerId);
  };

  getConditionReport = async (inventoryId, userId, dealerId) => {
    this.setState({ vehicleInformationLoading: true });
    const conditionReport =
      await ConditionReportService.getSingleConditionReport(
        inventoryId,
        userId,
        dealerId
      );
    this.setState({
      ...this.state,
      conditionReport,
      vehicleInformationLoading: false,
    });
  };

  render() {
    const { conditionReport, error } = this.state;
    return (
      <React.Fragment>
        {conditionReport ? (
          <ConditionReport
            conditionReport={conditionReport}
            externalConditionReportView={true}
          />
        ) : error === false ? (
          <Loading />
        ) : (
          <Typography>Error loading condition report!</Typography>
        )}
      </React.Fragment>
    );
  }
}

ConditionReportExternalWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    car: state.car,
    conditionReport: state.conditionReport,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(ConditionReportExternalWrapper)
);
