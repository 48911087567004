import * as R from "ramda";

import {
  ADD_GAMD_CARS,
  ADD_SEARCH_CARS,
  ADD_UPSTREAM_CARS_SUCCESS,
  SELECT_CAR_FOR_PURCHASE,
  SELECT_PASS_CAR,
  PASS_CAR,
  UPDATE_UPSTREAM_CAR,
  ADD_GAMD_CONDITION_REPORT,
  GUARANTEE_PAYMENTS,
  ADD_DDO_CONDITION_REPORT,
  ADD_CAR_TO_WATCHLIST,
  REMOVE_CAR_FROM_WATCHLIST,
  QUICK_SEARCH_KEY,
  ADD_WATCHLIST_CONDITION_REPORT,
  ADVANCE_SEARCH_CARS,
  ADVANCE_SEARCH_CRITERIA,
  ADVANCE_SEARCH_INFO,
  WATCHLIST_COUNT,
  SELECT_MOBILE_CAR,
  REMOVE_SELECTED_MOBILE_CAR,
  MOBILE_SEARCH_CRITERIA,
  MOBILE_SEARCH_EDIT_INFO,
} from "../actions/carActions";

export const carState = {
  groundedCars: [],
  quickSearchKey: "",
  pricedCars: [],
  passedCars: [],
  searchCars: [],
  upstreamCars: [],
  lastChanceCars: [],
  carForPurchase: {},
  passCar: {},
  GAMDConditionReports: {},
  DDOConditionReports: {},
  searchResultConditionReports: {},
  watchListConditionReports: {},
  winListConditionReports: {},
  lossListConditionReports: {},
  advanceSearchCars: null,
  advanceSearchCriteria: null,
  advanceSearchInfo: null,
  watchlistCount: 0,
  mobileSearchCriteria: null,
  mobileCarInformation: null,
  mobileSearchEditInfo: null,
};

const passedCar = (car) => {
  switch (car.dealerFacingStatus) {
    case "DLR_INELIGIBLE_PUR":
      return true;
    case "DLR_ARBITRATION":
      return true;
    case "DLR_BUY_NOW":
      return true;
    case "DLR_BID_NOW":
      return true;
    case "DLR_BUY_BACK":
      return true;
    case "DLR_ON_DLRBLK":
      return true;
    case "DLR_BAL_INELIG_PUR":
      return true;
    case "DLR_BAL_CSC_MNGD":
      return true;
    case "CSC_MANAGED":
      return true;
    case "DLR_OFRD_DB_AUCTION":
      return true;
    default:
      return false;
  }
};

const checkProgramDTOs = (DTOs) => {
  let reducedPricing = false;
  DTOs.map((DTO) => {
    if (DTO.reducePricingProgram) {
      return (reducedPricing = true);
    } else {
      return null;
    }
  });
  return reducedPricing;
};

const LCPCar = (car) => {
  if (car.programDTO.length > 0 && checkProgramDTOs(car.programDTO)) {
    return true;
  }
};

const pricedCar = (car) => {
  if (
    (car.pricingStatus === "PRICING_COMP" ||
      car.pricingStatus === "PRICING_QC_COMP") &&
    car.marketBasedPrice
  ) {
    return true;
  }
};

const filterGAMDVehicles = (cars) => {
  let newCarsState = {
    groundedCars: [],
    pricedCars: [],
    passedCars: [],
    lastChanceCars: [],
    carForPurchase: {},
  };
  cars.map((car) => {
    if (
      passedCar(car) ||
      car.dealerDecisionCode === "DLR_EXCL_EXP" ||
      car.passedVehicle
    ) {
      if (
        car.dealerFacingStatus === "DLR_BUY_NOW" ||
        car.dealerFacingStatus === "DLR_BID_NOW" ||
        car.dealerFacingStatus === "DLR_BID_NOW" ||
        car.dealerFacingStatus === "DLR_MBP_PENDING" ||
        car.dealerFacingStatus === "DLR_INSP_PENDING"
      ) {
        return newCarsState.lastChanceCars.push(car);
      } else {
        return newCarsState.passedCars.push(car);
      }
    } else if (pricedCar(car)) {
      return newCarsState.pricedCars.push(car);
    } else if (LCPCar(car)) {
      return newCarsState.lastChanceCars.push(car);
    } else {
      return newCarsState.groundedCars.push(car);
    }
  });
  return newCarsState;
};

export default (state = carState, action) => {
  switch (action.type) {
    case ADD_GAMD_CARS:
      try {
        const cars = action.cars;
        const filteredCars = filterGAMDVehicles(cars);
        const newCarState = {
          ...state,
          groundedCars: filteredCars.groundedCars,
          pricedCars: filteredCars.pricedCars,
          /* added a temporary filter for cars secured at auction to not show up in the passed column*/
          passedCars: filteredCars.passedCars.filter(
            (car) => car.dealerFacingStatus !== "DLR_SEC_AT_AUCT"
          ),
          lastChanceCars: filteredCars.lastChanceCars,
        };
        return newCarState;
      } catch (err) {
        console.log(err);
        return state;
      }
    case QUICK_SEARCH_KEY:
      const _quickSearchKey = action.quickSearchKey;
      return {
        ...state,
        quickSearchKey: _quickSearchKey,
      };
    case ADD_SEARCH_CARS:
      const cars = action.cars;
      return {
        ...state,
        searchCars: cars,
      };
    case ADD_UPSTREAM_CARS_SUCCESS:
      const _upstreamCars = action.cars;
      return {
        ...state,
        upstreamCars: _upstreamCars,
      };
    case SELECT_CAR_FOR_PURCHASE:
      const buyCar = action.car;
      return {
        ...state,
        carForPurchase: buyCar,
      };
    case SELECT_PASS_CAR:
      const passCar = action.car;
      return {
        ...state,
        passCar: passCar,
      };
    case PASS_CAR:
      const passedCar = action.car;
      let newPassedCars = [...state.passedCars];
      let newLastChanceCars = [...state.lastChanceCars];
      if (
        passedCar.dealerFacingStatus === "DLR_BUY_NOW" ||
        passedCar.dealerFacingStatus === "DLR_BID_NOW" ||
        passedCar.dealerFacingStatus === "DLR_BID_NOW" ||
        passedCar.dealerFacingStatus === "DLR_MBP_PENDING" ||
        passedCar.dealerFacingStatus === "DLR_INSP_PENDING" ||
        passedCar.dealerFacingStatus === "DLR_ACTION_NEEDED"
      ) {
        newLastChanceCars.push(passedCar);
      } else {
        newPassedCars.push(passedCar);
      }
      return {
        ...state,
        passCar: {},
        groundedCars: state.groundedCars.filter(
          (car) =>
            car.vehicleIdentificationNumber !==
            action.car.vehicleIdentificationNumber
        ),
        pricedCars: state.pricedCars.filter(
          (car) =>
            car.vehicleIdentificationNumber !==
            action.car.vehicleIdentificationNumber
        ),
        passedCars: newPassedCars,
        lastChanceCars: newLastChanceCars,
      };
    case UPDATE_UPSTREAM_CAR:
      const carIndex = R.findIndex(R.propEq("vin", action.car.vin))(
        state.upstreamCars
      );
      return R.set(R.lensPath(["upstreamCars", carIndex]), action.car, state);
    case ADD_GAMD_CONDITION_REPORT:
      if (action.conditionReport.vin) {
        return {
          ...state,
          GAMDConditionReports: {
            ...state.GAMDConditionReports,
            [action.conditionReport.vin]: action.conditionReport,
          },
        };
      } else {
        return state;
      }
    case GUARANTEE_PAYMENTS:
      const id = action.car.id ? action.car.id : action.car.inventoryId;
      const groundedIndex = R.findIndex(R.propEq("id", id))(state.groundedCars);
      const pricedIndex = R.findIndex(R.propEq("id", id))(state.pricedCars);
      const index = groundedIndex !== -1 ? groundedIndex : pricedIndex;
      return index === groundedIndex
        ? R.set(
            R.lensPath(["groundedCars", index, "displayGuaranteePaymentsOnly"]),
            false,
            state
          )
        : R.set(
            R.lensPath(["pricedCars", index, "displayGuaranteePaymentsOnly"]),
            false,
            state
          );
    case ADD_DDO_CONDITION_REPORT:
      return {
        ...state,
        DDOConditionReports: {
          ...state.DDOConditionReports,
          [action.conditionReport.vin]: action.conditionReport,
        },
      };
    case ADD_CAR_TO_WATCHLIST:
      const { vin } = action.car;
      const upstreamIndex = R.findIndex(R.propEq("vin", vin))(
        state.upstreamCars
      );
      return R.set(
        R.lensPath(["upstreamCars", upstreamIndex, "isInWatchList"]),
        true,
        state
      );
    case REMOVE_CAR_FROM_WATCHLIST:
      return R.set(
        R.lensPath(["upstreamCars", upstreamIndex, "isInWatchList"]),
        false,
        state
      );
    case ADD_WATCHLIST_CONDITION_REPORT:
      return {
        ...state,
        [action.list]: {
          ...state[action.list],
          [action.conditionReport.vin]: action.conditionReport,
        },
      };
    case ADVANCE_SEARCH_CARS:
      const _advanceSearchCars = action.cars;
      return {
        ...state,
        advanceSearchCars: _advanceSearchCars,
      };
    case ADVANCE_SEARCH_CRITERIA:
      const _advanceSearchCriteria = action.cars;
      return {
        ...state,
        advanceSearchCriteria: _advanceSearchCriteria,
      };
    case ADVANCE_SEARCH_INFO:
      const _advanceSearchInfo = action.cars;
      return {
        ...state,
        advanceSearchInfo: _advanceSearchInfo,
      };
    case WATCHLIST_COUNT:
      const _watchlistCount = action.count;
      return {
        ...state,
        watchlistCount: _watchlistCount,
      };
    case SELECT_MOBILE_CAR:
      const mobileCarInformation = action.car;
      return {
        ...state,
        mobileCarInformation,
      };
    case MOBILE_SEARCH_CRITERIA:
      return {
        ...state,
        mobileSearchCriteria: action.mobileSearchCriteria,
      };
    case MOBILE_SEARCH_EDIT_INFO:
      return {
        ...state,
        mobileSearchEditInfo: action.mobileSearchEditInfo,
      };
    case REMOVE_SELECTED_MOBILE_CAR:
      return {
        ...state,
        mobileCarInformation: null,
      };
    default:
      return state;
  }
};
