import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography, Grid } from "@material-ui/core";
import Loading from "../components/Loading";
const styles = (theme) => ({
  preTermGroundBuyButton: {
    margin: "0.5rem",
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "7rem",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
  },
  retryGetMbpPriceRangeButton: {
    margin: "0.5rem",
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "7rem",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
  },
  preTermCloseButton: {
    margin: "0.5rem",
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.DDO.main,
    width: "7rem",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
  },
  sectionHeader: {
    height: "35px",
    backgroundColor: theme.palette.DDO.main,
    color: theme.palette.primary.white,
    width: "100%",
    paddingLeft: "10px",
    margin: "10px",
    display: "flex",
  },
  priceQuoteText: {
    marginLeft: "1rem",
  },
  priceQuoteEstimateText: {
    marginLeft: "1rem",
    margin: 20,
    color: "red",
  },
  priceQuoteOdometerGrid: {
    borderRight: `2px solid ${theme.palette.secondary.gray}`,
    borderRightStyle: "inset",
  },
  priceQuoteOdometerSubGrid: {
    paddingTop: "35px",
    paddingRight: "10px",
  },
  priceQuoteInformation: {
    paddingLeft: "10px",
  },
});
export class PreTermPriceQuote extends Component {
  render() {
    const {
      classes,
      vin,
      vehicleDetails,
      priceQuoteOdometer,
      loadGroundScreen,
      loadHomeScreen,
      date,
      finishedFetchingMbpPriceQuote,
      errorFetchingMbpPriceRange,
      errorMessage,
      retryGetMbpPriceRange,
    } = this.props;
    return finishedFetchingMbpPriceQuote ? (
      <React.Fragment>
        <Typography className={classes.sectionHeader}>
          Pre-Term Price Quote
        </Typography>
        <Typography className={classes.priceQuoteText}>
          {
            <strong>
              {" "}
              {vin} -{vehicleDetails.vehicleYear} {vehicleDetails.make}{" "}
              {vehicleDetails.model} {vehicleDetails.series}
            </strong>
          }
        </Typography>
        <div style={{ paddingLeft: "25px", marginTop: "20px" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <div className={classes.priceQuoteOdometerGrid}>
              <div className={classes.priceQuoteOdometerSubGrid}>
                Odometer: {Number(priceQuoteOdometer).toLocaleString("en-US")}
              </div>
            </div>
            <Grid item xs={6} className={classes.priceQuoteInformation}>
              <Grid item xs={12}>
                Residual: $
                {vehicleDetails.residual
                  ? vehicleDetails.residual.toLocaleString()
                  : 0}
              </Grid>
              <Grid item xs={12}>
                {vehicleDetails.monthsRemaining ||
                vehicleDetails.paymentsRemaining ? (
                  <React.Fragment>
                    Months Remaining:{" "}
                    {vehicleDetails.monthsRemaining.toLocaleString()} &nbsp;
                    &nbsp; &nbsp; &nbsp; Payments Remaining: $
                    {vehicleDetails.paymentsRemaining.toLocaleString()}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    Months Remaining: 0 &nbsp; &nbsp; &nbsp; &nbsp; Payments
                    Remaining: $0
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={12}>
                Payoff: $
                {vehicleDetails.payoff
                  ? vehicleDetails.payoff.toLocaleString()
                  : 0}
              </Grid>
              <Grid item xs={12}>
                Estimated MBP:
                {vehicleDetails.marketValue
                  ? " $" +
                    vehicleDetails.marketValue.toLocaleString() +
                    "- $" +
                    vehicleDetails.marketValueUpperBound.toLocaleString()
                  : " Pending"}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.priceQuoteEstimateText}>
          {
            <strong>
              Estimated MBP is informational only. Option to purchase after
              grounding will be at the actual MBP after inspection is completed
            </strong>
          }
        </Typography>
        <Typography className={classes.priceQuoteText}>
          {
            <strong>
              Note! If returned prior to {date ? date.toLocaleDateString() : ""}{" "}
              this will be considered an early termination, unless this vehicle
              qualifies for an active payment waiver program.
            </strong>
          }
        </Typography>

        <div style={{ alignSelf: "center", marginLeft: "20px" }}>
          {errorFetchingMbpPriceRange && errorMessage && (
            <strong>{errorMessage}</strong>
          )}
          {!vehicleDetails.marketValue && !errorFetchingMbpPriceRange && (
            <strong>This vehicle has no MBP Quote</strong>
          )}
          {errorFetchingMbpPriceRange && (
            <Button
              style={{
                display: vehicleDetails.alreadyGrounded
                  ? "none"
                  : "inline-block",
              }}
              id="retryGetMbpPriceRangeButton"
              className={classes.retryGetMbpPriceRangeButton}
              onClick={retryGetMbpPriceRange}
            >
              Re-Try
            </Button>
          )}
          <Button
            style={{
              display: vehicleDetails.alreadyGrounded ? "none" : "inline-block",
            }}
            id="preTermGroundBtn"
            className={classes.preTermGroundBuyButton}
            onClick={loadGroundScreen}
            //disabled={!userAccess.canGroundVehicles(user.userRoles)}
          >
            Ground/Buy
          </Button>
          <Button
            id="preTermGroundCancelBtn"
            className={classes.preTermCloseButton}
            onClick={loadHomeScreen}
            //disabled={!userAccess.canGroundVehicles(user.userRoles)}
          >
            Close
          </Button>
        </div>
      </React.Fragment>
    ) : (
      <Loading />
    );
  }
}

export default withStyles(styles)(PreTermPriceQuote);
