import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import SearchResult from "../components/SearchResult";
import FullVehicleInformation from "../components/FullVehicleInformation";
import DDOFullVehicleInformation from "../components/DDOFullVehicleInformation";
import ConditionReport from "../components/ConditionReport";
import { Typography } from "@material-ui/core";

import * as SearchServices from "../services/SearchServices";

const styles = (theme) => ({
  quickSearchWrapper: {
    display: "flex",
    height: "100%",
  },
  quickSearchLeft: {
    backgroundColor: theme.palette.secondary.gray,
    margin: ".5rem",
    padding: ".5rem",
    width: "70%",
    display: "block",
  },
  quickSearchLeftLoading: {
    backgroundColor: theme.palette.secondary.gray,
    margin: ".5rem",
    padding: ".5rem",
    width: "70%",
    display: "block",
  },
  quickSearchLeftWhite: {
    margin: ".5rem",
    padding: ".5rem",
    width: "70%",
    display: "block",
  },
  quickSearchCardWrapper: {
    paddingBottom: ".5rem",
  },
  quickSearchRight: {
    backgroundColor: theme.palette.secondary.gray,
    margin: ".5rem",
    padding: ".5rem",
    width: "30%",
  },
});

export class QuickSearch extends Component {
  state = {
    quickSearchCars: [],
    pageLoading: true,
    buyPassFlowVissible: false,
    showAutoSearch: false,
    showSaveSearch: false,
    query: {},
    showConditionReport: false,
    conditionReport: null,
    selectedCar: null,
    carFrom: null,
    fullConditionReport: false,
    showResults: false,
    numberOfMatch: 0,
  };

  componentWillMount = () => {
    this.loadCars();
  };

  componentDidUpdate = (prevProps) => {
    const { quickSearchKey } = this.props;
    if (prevProps.quickSearchKey !== quickSearchKey) {
      this.setState({
        quickSearchCars: [],
        showResults: false,
        pageLoading: true,
        numberOfMatch: 0,
        selectedCar: null,
        showConditionReport: false,
        conditionReport: null,
      });
      this.loadCars();
    }
  };

  loadCars = async () => {
    const { quickSearchKey, user } = this.props;
    let response = null;
    if (quickSearchKey !== "") {
      response = await SearchServices.quickSearch(user, quickSearchKey);
    }
    if (response !== null && response.message !== "No Results Found !!") {
      this.setState({
        quickSearchCars: response,
        showResults: true,
        pageLoading: false,
        numberOfMatch: response ? response.length : 0,
      });
    } else {
      this.setState({
        quickSearchCars: [],
        showResults: false,
        pageLoading: false,
        numberOfMatch: 0,
      });
    }
  };

  clearSearchResult = () => {
    this.setState({
      selectedCar: null,
      showConditionReport: false,
      conditionReport: null,
    });
  };

  toggleBuyPassFlow = () => {
    this.setState({
      buyPassFlowVissible: !this.state.buyPassFlowVissible,
    });
  };

  toggleConditionReport = (selectedCar, conditionReport, from) => {
    this.setState({
      showConditionReport: !this.state.showConditionReport
        ? true
        : this.state.showConditionReport,
      selectedCar: selectedCar,
      conditionReport: conditionReport,
      carFrom: from,
    });
  };

  toggleFullConditionReport = () => {
    this.setState({
      fullConditionReport: !this.state.fullConditionReport,
      buyPassFlowVissible: !this.state.buyPassFlowVissible,
      showResults: !this.state.showResults,
      selectedCar: this.state.fullConditionReport
        ? null
        : this.state.selectedCar,
      showConditionReport: false,
      conditionReport: this.state.fullConditionReport
        ? null
        : this.state.conditionReport,
    });
  };

  updateWatchlist = (vin) => {
    const { quickSearchCars } = this.state;
    const modifiedDisplayed = quickSearchCars.map((car) => {
      if (car.vin === vin) {
        return { ...car, isInWatchList: !car.isInWatchList };
      } else {
        return car;
      }
    });
    this.setState({
      quickSearchCars: modifiedDisplayed,
    });
  };

  render() {
    const { classes, user, searchCriteria } = this.props;
    const {
      quickSearchCars,
      pageLoading,
      buyPassFlowVissible,
      showConditionReport,
      selectedCar,
      conditionReport,
      carFrom,
      numberOfMatch,
      fullConditionReport,
      showResults,
    } = this.state;
    return (
      <React.Fragment>
        <div className={classes.quickSearchWrapper}>
          {showResults ? (
            pageLoading ? (
              <div className={classes.quickSearchLeftLoading}>
                <Loading />
              </div>
            ) : (
              <div
                className={
                  buyPassFlowVissible
                    ? classes.quickSearchLeftWhite
                    : classes.quickSearchLeft
                }
              >
                <div>
                  <Typography>
                    {" "}
                    Vehicles Matching Selected Options: {numberOfMatch}{" "}
                  </Typography>
                </div>
                <Scrollbars
                  autoHide
                  autoHideTimeout={1000}
                  className={classes.scrollBar}
                >
                  {quickSearchCars && quickSearchCars.length > 0 ? (
                    <SearchResult
                      searchCars={quickSearchCars}
                      toggleBuyPassFlow={this.toggleBuyPassFlow}
                      toggleLoading={this.toggleLoading}
                      clearSearchResult={this.clearSearchResult}
                      toggleConditionReport={this.toggleConditionReport}
                      isFromQuickSearch={true}
                    />
                  ) : (
                    <div> No matches found. Please refine search... </div>
                  )}
                </Scrollbars>
              </div>
            )
          ) : fullConditionReport ? null : (
            <div
              className={
                buyPassFlowVissible
                  ? classes.quickSearchLeftWhite
                  : classes.quickSearchLeft
              }
            >
              {" "}
              No matches found. Please refine search...{" "}
            </div>
          )}
          {buyPassFlowVissible ? null : (
            <div className={classes.quickSearchRight}>
              {showConditionReport ? (
                <Scrollbars className={classes.scrollBar}>
                  {carFrom === "DDO" ? (
                    <DDOFullVehicleInformation
                      user={user}
                      car={selectedCar}
                      conditionReport={conditionReport}
                      toggleFullConditionReport={this.toggleFullConditionReport}
                    />
                  ) : (
                    <FullVehicleInformation
                      car={selectedCar}
                      conditionReport={conditionReport}
                      toggleFullConditionReport={this.toggleFullConditionReport}
                      selectedVehicleDetails={selectedCar}
                      user={user}
                    />
                  )}
                </Scrollbars>
              ) : (
                "Select a vehicle..."
              )}
            </div>
          )}
          {fullConditionReport ? (
            <ConditionReport
              searchCriteria={searchCriteria}
              car={selectedCar}
              user={user}
              conditionReport={conditionReport}
              toggleFullConditionReport={this.toggleFullConditionReport}
              updateWatchlist={this.updateWatchlist}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    quickSearchKey: state.cars.quickSearchKey,
    searchCriteria: state.ui.searchCriteria,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(QuickSearch));
