export const LOAD_STATE_INFO_SUCCESS = "LOAD_STATE_INFO_SUCCESS";
export const LOAD_STATE_INFO_FAIL = "LOAD_STATE_INFO_FAIL";

export const loadStateInfoSuccess = (stateInfo) => {
  return {
    type: LOAD_STATE_INFO_SUCCESS,
    stateInfo,
  };
};

export const loadStateInfoFail = () => {
  return {
    type: LOAD_STATE_INFO_FAIL,
  };
};
