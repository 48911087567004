import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import _ from "lodash";

import {
  Checkbox,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
  SnackbarContent,
  Link,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import moment from "moment";

import GAMDLPWEntryForm from "../components/GAMDLPWEntryForm";
import GuaranteePayments from "../components/GuaranteePayments";
import ConfirmPage from "../components/ConfirmPage";
import CancelConfirm from "../components/CancelConfirm";
import CarReport from "../components/CarReport";
import AutoCheckReport from "../components/AutoCheckReport";
import TransportMethod from "../components/TransportMethod";
import Loading from "../components/Loading";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import * as VehiclePurchaseServices from "../services/VehiclePurchaseServices";
import * as FleetFinanicalService from "../services/FleetFinanicalService";
import * as GroundingServices from "../services/GroundingServices";

import {
  add_purchased_vehicle,
  remove_purchased_vehicle,
} from "../actions/uiActions";

import { select_car_for_purchase } from "../actions/carActions";

import ReactSVG from "react-svg";
import carfaxLogo from "../assets/carfaxLogo.svg";
import autoCheckLogo from "../assets/autoCheckLogo.svg";
import circleGAMDIcon from "../assets/circleGAMDIcon.svg";
import circleDDOIcon from "../assets/circleDDOIcon.svg";
import {
  PAYOFF_PRICE_NOT_AVAILABLE,
  CALL_ADESA_SUPPORT,
  PAY_OFF_NOT_RECEIVED,
  EARLY_TERM_LABEL,
  LESSEE_PAYOFF_DISCLAIMER,
} from "../constants/UILabel";

const awsUrl = process.env.REACT_APP_WCM_CONTENT;

const styles = (theme) => ({
  root: {
    width: "70%",
    margin: "auto",
  },
  buyPageWrapper: {
    height: "inherit",
  },
  rootButton: {
    width: "70%",
    margin: "auto",
    textAlign: "right",
  },
  buyHeader: {
    display: "flex",
    marginTop: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buyWrapper: {
    display: "flex",
    marginTop: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      margin: 0,
      paddingBottom: "2rem",
      marginTop: "2.5rem",
    },
  },
  buyPriceWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    justifyContent: "left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  lowerLeftWrapper: {
    marginRight: "2rem",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      margin: 0,
    },
  },
  lowerVehicleInfoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginLeft: ".5rem",
      marginRight: ".5rem",
    },
  },
  carReportWrapper: {
    display: "flex",
    flexDirection: "column",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
  },
  carInfoTitle: {
    fontSize: "1.5rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
      marginRight: ".5rem",
      fontSize: "1.2rem",
    },
  },
  infoWrapper: {
    width: "75%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginLeft: ".5rem",
      marginRight: ".5rem",
      marginTop: ".5rem",
    },
  },
  lineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".75rem",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    paddingBottom: ".2rem",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  paymentLineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".75rem",
    paddingBottom: ".2rem",
  },
  bolded: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: "50%",
    },
  },
  GAMDBoldedColor: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.GAMD.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "60%",
    },
  },
  DDOBoldedColor: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.DDO.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "60%",
    },
  },
  LPPBoldedColor: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.grounding.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "60%",
    },
  },
  GAMDPricingInfoHeader: {
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.GAMD.main,
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  DDOPricingInfoHeader: {
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.DDO.main,
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  LPPPricingInfoHeader: {
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.grounding.main,
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  earlyTermLabel: {
    fontWeight: "bold",
    fontSize: "0.8rem",
    color: theme.palette.primary.black,
    marginBottom: "2rem",
    marginLeft: "0.2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  programLink: {
    textDecoration: "underline",
    color: "blue",
    margin: 0,
    marginTop: ".2rem",
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
    },
  },
  promoWrapper: {
    display: "flex",
    flexWrap: "none",
  },
  carReportLink: {
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    height: "1rem",
  },
  GAMDButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: 0,
      "&:hover": {
        backgroundColor: theme.palette.GAMD.main,
        "@media (hover: none)": {
          backgroundColor: theme.palette.GAMD.main,
          "&:active": {
            backgroundColor: theme.palette.GAMD.main,
          },
        },
      },
    },
  },
  GAMDButtonBalloon: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      "&:hover": {
        backgroundColor: theme.palette.GAMD.main,
        "@media (hover: none)": {
          backgroundColor: theme.palette.GAMD.main,
          "&:active": {
            backgroundColor: theme.palette.GAMD.main,
          },
        },
      },
    },
  },
  GAMDCancelButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: 0,
      backgroundColor: theme.palette.secondary.gray,
      "&:hover": {
        backgroundColor: theme.palette.secondary.gray,
        "@media (hover: none)": {
          backgroundColor: theme.palette.secondary.gray,
          "&:active": {
            backgroundColor: theme.palette.secondary.gray,
          },
        },
      },
    },
  },
  DDOButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.DDO.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: 0,
      "&:hover": {
        backgroundColor: theme.palette.DDO.main,
        "@media (hover: none)": {
          backgroundColor: theme.palette.DDO.main,
          "&:active": {
            backgroundColor: theme.palette.DDO.main,
          },
        },
      },
    },
  },
  DDOCancelButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.DDO.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: 0,
      backgroundColor: theme.palette.secondary.gray,
      "&:hover": {
        backgroundColor: theme.palette.secondary.gray,
        "@media (hover: none)": {
          backgroundColor: theme.palette.secondary.gray,
          "&:active": {
            backgroundColor: theme.palette.secondary.gray,
          },
        },
      },
    },
  },
  systemMessage: {
    fontSize: ".5rem",
    position: "absolute",
    top: "1.4rem",
    right: "2rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  systemMessage2: {
    fontSize: ".5rem",
    position: "absolute",
    top: "7.5rem",
    color: theme.palette.primary.gray,
    right: "2rem",
    [theme.breakpoints.down("md")]: {
      width: "22rem",
      right: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  systemMessageLPP: {
    fontSize: ".5rem",
    position: "absolute",
    top: "5.5rem",
    color: theme.palette.primary.gray,
    right: "2rem",
    [theme.breakpoints.down("md")]: {
      width: "10rem",
      right: "initial",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buyNowSystemMessage: {
    fontSize: ".5rem",
    position: "absolute",
    top: "6.4rem",
    right: "2rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buyNowSystemMessage2: {
    fontSize: ".5rem",
    position: "absolute",
    top: "8.5rem",
    color: theme.palette.primary.gray,
    right: "1rem",
    [theme.breakpoints.down("md")]: {
      width: "22rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  e2eNoMBPMessage: {
    color: theme.palette.primary.redHighlight,
    fontSize: "1.00rem",
  },
  lesseePayOffDisclaimer: {
    color: theme.palette.primary.redHighlight,
    fontSize: "0.8rem",
    fontWeight: "bold",
    lineHeight: "1.5",
  },
  checkboxRoot: {
    padding: 0,
    height: "1rem",
    paddingLeft: ".5rem",
  },
  priceWrapper: {
    display: "flex",
  },
  paymentSelectionWrapper: {
    marginTop: ".5rem",
    marginBottom: ".5rem",
    [theme.breakpoints.down("sm")]: {
      margin: ".5rem",
    },
  },
  paymentSelectionTitle: {
    marginBottom: ".5rem",
  },
  GAMDIcon: {
    width: "3rem",
  },
  DDOIcon: {
    width: "4rem",
    height: "2.4rem",
  },
  titleGAMDTextWrapper: {
    borderBottom: `5px solid ${theme.palette.GAMD.main}`,
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  titleDDOTextWrapper: {
    borderBottom: `5px solid ${theme.palette.DDO.main}`,
    height: "2rem",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  titleGAMDText: {
    color: theme.palette.GAMD.main,
    fontSize: ".8rem",
    [theme.breakpoints.down("md")]: {
      width: "7rem",
    },
  },
  titleDDOText: {
    color: theme.palette.DDO.main,
    fontSize: ".8rem",
    [theme.breakpoints.down("md")]: {
      width: "9rem",
    },
  },
  titleWrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    width: "12rem",
  },
  stepper: {
    width: "60rem",
    padding: 0,
  },
  stepLabel: {
    fontSize: theme.stepper.label.fontSize,
  },
  GAMDStepIcon: {
    fill: theme.palette.GAMD.main,
    color: theme.palette.GAMD.main,
  },
  DDOStepIcon: {
    fill: theme.palette.DDO.main,
    color: theme.palette.DDO.main,
  },
  GAMDCompletedIcon: {
    fill: theme.palette.GAMD.main,
    color: theme.palette.GAMD.main,
  },
  DDOCompletedIcon: {
    fill: theme.palette.DDO.main,
    color: theme.palette.DDO.main,
  },
  active: {},
  checked: {},
  checkboxRootGAMD: {
    padding: 0,
    height: "1rem",
    paddingLeft: ".5rem",
    "&$checked": {
      color: theme.palette.GAMD.main,
    },
  },
  checkboxRootDDO: {
    padding: 0,
    height: "1rem",
    paddingLeft: ".5rem",
    "&$checked": {
      color: theme.palette.DDO.main,
    },
  },
  checkboxRootLPP: {
    padding: 0,
    height: "1rem",
    paddingLeft: ".5rem",
    "&$checked": {
      color: theme.palette.grounding.main,
    },
  },
  acceptanceText: {
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".5rem",
      marginLeft: ".5rem",
      marginRight: ".5rem",
    },
  },
  NOSMessage: {
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
    marginTop: ".25rem",
    marginLeft: "3rem",
    display: "flex",
    justifyContent: "center",
    width: "35rem",
  },
  PDALink: {
    color: theme.palette.primary.gray,
  },
  label: {
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  groupLabelRed: {
    color: theme.palette.primary.redHighlight,
  },
  imageWrapper: {
    display: "flex",
    height: "7rem",
    marginRight: "3rem",
    width: "96%",
    marginBottom: "3%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  vehicleImage: {
    width: "18%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  errorMessage: {},
  errorSnackBar: {
    marginTop: "7rem",
  },
  balloonFormWrapper: {
    marginTop: "3rem",
    marginLeft: "10rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
      marginLeft: 0,
    },
  },
  balloonConfirmationWrapper: {
    margin: "3rem",
    [theme.breakpoints.down("sm")]: {
      margin: "1rem",
    },
  },
  buttonWrapper: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      display: "flex",
      width: "100vw",
    },
  },
  GAMDMobileStepper: {
    backgroundColor: theme.palette.GAMD.main,
    color: theme.palette.primary.white,
    borderRadius: "0 0 1rem 1rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  DDOMobileStepper: {
    backgroundColor: theme.palette.DDO.main,
    color: theme.palette.primary.white,
    borderRadius: "0 0 1rem 1rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileStepperTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  payOffPrice: {
    color: "red",
  },
  promoText: {
    display: "flex",
    flexDirection: "row",
  },
  linkColor: {
    color: theme.palette.button.main,
    marginLeft: ".2rem",
  },
});

let lpwCount = 1;

function getSteps() {
  return ["Select Purchase Options", "Purchase Receipt"];
}

export class BuyVehicle extends Component {
  state = {
    priceOptionSelected: "",
    priceOptionCode: "",
    paymentOptionSelected: null,
    paymentOptionCode: "",
    programCertNum: "",
    programCertComments: "",
    LPWActiveCar: false,
    totalPriceAmount: 0,
    cancelConfirm: false,
    isGuaranteedFromBuy: false,
    transportDTO: { status: "false", message: "Loading", quote: 0 },
    transportMethodSelected: "",
    selectedLocation: null,
    selectedLocationDTO: {},
    specialInstruction: "",
    purchaseErrorMessage: false,
    showCarfaxReport: false,
    showAutoCheckReport: false,
    activeStep: 0,
    guaranteePayment: false,
    paymentGuaranteed: false,
    showEmailNotification: false,
    selectedProgram: {},
    alfaVehicleDataNotRecieved: false,
    isEarlyTermLease: false,
    purchaseOptions: [],
    purchaseInfoLoading: true,
  };

  componentDidMount = async () => {
    const { car, isBuyNow, accountSetting, page } = this.props;
    const { userId, dealerId } = this.props.user;
    const { TDDMAIN_4047_TDD_E2E_DEALER_ENGAGEMENT } =
      this.props.togglzMap || {};
    if (page === "LPP") {
      this.getPurchaseOptions(userId, dealerId);
    }
    if (!car.inventoryDTO) {
      this.setState({
        loading: true,
      });
      if (this.props.carInfo && this.props.carInfo.inventoryDTO !== undefined) {
        car.inventoryDTO = this.props.carInfo.inventoryDTO;
      } else {
        const inventoryDetails = await GroundingServices.getInventory(
          car.inventoryId
        );
        car.inventoryDTO = inventoryDetails;
      }
    }
    let earlyTermBlockedByLPWCoveringAllRemainingPayments = false;

    if (car.invParticipatingPrograms) {
      earlyTermBlockedByLPWCoveringAllRemainingPayments =
        car.invParticipatingPrograms.length > 0 &&
        car.remainingPaymentsAmt <= car.waivedPayments;
    }

    let companyCar = car.accountType === "COMPANY CAR";

    let earlyTerm =
      car.inventoryDTO &&
      car.inventoryDTO.earlyTermNotification &&
      !earlyTermBlockedByLPWCoveringAllRemainingPayments &&
      !companyCar;

    this.setState({
      isEarlyTermLease: earlyTerm && TDDMAIN_4047_TDD_E2E_DEALER_ENGAGEMENT,
      earlyTerm: earlyTerm && TDDMAIN_4047_TDD_E2E_DEALER_ENGAGEMENT,
      loading: false,
    });

    if (
      (isBuyNow || car.dealerFacingStatus === "DLR_BUY_NOW") &&
      car.invStatusInAuction !== "OFRD_FIRST_PRE_AUCT" &&
      car.isVehAtLoggedInDlrLot
    ) {
      this.setState({
        priceOptionSelected: "Buy Now Price",
        priceOptionCode: "BNP",
        selectedPrice: car.discountedBuyNowPrice
          ? car.discountedBuyNowPrice
          : car.buyNowPrice,
        totalPriceAmount: car.discountedBuyNowPrice
          ? car.discountedBuyNowPrice + car.adminFee + this.getTransportPrice()
          : car.buyNowPrice + car.adminFee + this.getTransportPrice(),
        selectedLocation: this.isCarAtBuyerLocation(car, accountSetting)
          ? car.vehicleLocation.id
          : car.purchasingDealerLocations[0].customerId,
        selectedLocationDTO: this.isCarAtBuyerLocation(car, accountSetting)
          ? accountSetting.custLocations[0]
          : car.purchasingDealerLocations[0],
        transportMethodSelected: this.isCarAtBuyerLocation(car, accountSetting)
          ? true
          : null,
      });
    } else if (
      !car.isVehAtLoggedInDlrLot &&
      (car.invStatusInAuction === "OFRD_FIRST_PRE_AUCT" ||
        car.invStatusInAuction === "OFRD_SCND_PRE_AUCT")
    ) {
      this.setState({
        priceOptionSelected: "Buy Now Price",
        priceOptionCode: "BNP",
        selectedPrice: car.discountedBuyNowPrice
          ? car.discountedBuyNowPrice
          : car.buyNowPrice,
        totalPriceAmount: car.discountedBuyNowPrice
          ? car.discountedBuyNowPrice + car.adminFee + this.getTransportPrice()
          : car.buyNowPrice + car.adminFee + this.getTransportPrice(),
        selectedLocation: this.isCarAtBuyerLocation(car, accountSetting)
          ? car.vehicleLocation.id
          : car.purchasingDealerLocations[0].customerId,
        selectedLocationDTO: this.isCarAtBuyerLocation(car, accountSetting)
          ? accountSetting.custLocations[0]
          : car.purchasingDealerLocations[0],
        transportMethodSelected: this.isCarAtBuyerLocation(car, accountSetting)
          ? true
          : null,
      });
    } else if (car.waivedPayments !== null) {
      this.setState({
        priceOptionSelected: "Payoff Price",
        priceOptionCode: "GPO",
        selectedPrice: car.grossPayoff,
        totalPriceAmount: car.grossPayoff + car.adminFee,
        selectedLocation: car.vehicleLocation && car.vehicleLocation.id,
        selectedLocationDTO: car.vehicleLocation,
        transportMethodSelected: !isBuyNow
          ? true
          : car.invStatusInAuction === "OFRD_FIRST_PRE_AUCT" &&
            car.isVehAtLoggedInDlrLot
          ? true
          : null,
      });
    } else if (car.isMBPApproved && earlyTerm) {
      this.setState({
        priceOptionSelected: "Market Price",
        priceOptionCode: "MBP",
        selectedPrice: car.marketBasedPrice,
        selectedLocation: car.vehicleLocation && car.vehicleLocation.id,
        selectedLocationDTO: car.vehicleLocation,
        totalPriceAmount: isBuyNow
          ? car.marketBasedPrice + car.adminFee + this.getTransportPrice()
          : car.marketBasedPrice + car.adminFee,
        transportMethodSelected: !isBuyNow
          ? true
          : car.invStatusInAuction === "OFRD_FIRST_PRE_AUCT" &&
            car.isVehAtLoggedInDlrLot
          ? true
          : null,
      });
    } else {
      this.setState({
        priceOptionSelected: "Residual Price",
        priceOptionCode: "RES",
        selectedPrice: car.residualAmount,
        totalPriceAmount: car.residualAmount + car.adminFee,
        selectedLocation: car.vehicleLocation && car.vehicleLocation.id,
        selectedLocationDTO: car.vehicleLocation,
        transportMethodSelected: !isBuyNow
          ? true
          : car.invStatusInAuction === "OFRD_FIRST_PRE_AUCT" &&
            car.isVehAtLoggedInDlrLot
          ? true
          : null,
      });
    }

    if (
      car.alfaSourceIndicator &&
      car.alfaSourceIndicator === "ALFA" &&
      car.grossPayoff === null
    ) {
      this.getVehicleFleetFinancialData(
        car.vehicleIdentificationNumber,
        car.alfaAccountNumber,
        car.customerReturnDate,
        car.leaseInfoId
      );
    }
  };

  getVehicleFleetFinancialData = async (
    vin,
    alfaAccountNumber,
    customerReturnDate,
    leaseInformationId
  ) => {
    const { dispatch, car } = this.props;
    const response = await FleetFinanicalService.getVehicleFleetFinancialData(
      vin,
      alfaAccountNumber,
      customerReturnDate,
      leaseInformationId
    );

    if (
      response &&
      response.failure &&
      response.failure === PAY_OFF_NOT_RECEIVED
    ) {
      this.setState({
        alfaVehicleDataNotRecieved: true,
      });
    } else if (response) {
      dispatch(
        select_car_for_purchase({
          ...car,
          grossPayoff: response.payOffAmt,
          goodThruDate: response.payOffGoodThruDate,
          residualAmount: response.residualAmt,
        })
      );
    }
  };

  isCarAtBuyerLocation = (car, buyerAccountSettings) => {
    for (const location of buyerAccountSettings.custLocations) {
      if (car.vehicleLocation.id === location.locationId) {
        return true;
      }
    }
    return false;
  };

  getTransportPrice = () => {
    const { transportDTO } = this.state;
    if (
      transportDTO.status !== "false" &&
      Number(transportDTO.quoteWithPromo) !== transportDTO.quote
    ) {
      return Number(transportDTO.quoteWithPromo);
    } else {
      return transportDTO.quote ? transportDTO.quote : 0;
    }
  };

  selectPrice = (evt) => {
    const { car, isBuyNow } = this.props;
    switch (evt.target.value) {
      case "RES":
        return this.setState({
          priceOptionSelected: "Residual Price",
          priceOptionCode: evt.target.value,
          selectedPrice: car.residualAmount,
          totalPriceAmount: isBuyNow
            ? car.residualAmount + car.adminFee + this.getTransportPrice()
            : car.residualAmount + car.adminFee,
        });
      case "GPO":
        return this.setState({
          priceOptionSelected: "Payoff Price",
          priceOptionCode: evt.target.value,
          selectedPrice: car.grossPayoff,
          totalPriceAmount: isBuyNow
            ? car.grossPayoff + car.adminFee + this.getTransportPrice()
            : car.grossPayoff + car.adminFee,
        });
      case "MBP":
        return this.setState({
          priceOptionSelected: "Market Price",
          priceOptionCode: evt.target.value,
          selectedPrice: car.marketBasedPrice,
          totalPriceAmount: isBuyNow
            ? car.marketBasedPrice + car.adminFee + this.getTransportPrice()
            : car.marketBasedPrice + car.adminFee,
        });
      default:
        return "";
    }
  };

  selectPayment = (evt) => {
    const paymentOptionSelected = parseInt(evt.target.value, 10);
    this.setPayment(paymentOptionSelected);
  };

  setPayment = (paymentOptionSelected) => {
    this.setState({
      paymentOptionCode: paymentOptionSelected === 117 ? "ACH" : "FP",
      paymentOptionSelected,
      paymentOptionError: false,
    });
    localStorage.setItem(
      "paymentOptionCode",
      paymentOptionSelected === 117 ? "ACH" : "FP"
    );
    localStorage.setItem("paymentOptionSelected", paymentOptionSelected);
  };

  validateNextStep = () => {
    const {
      paymentOptionCode,
      paymentOptionSelected,
      transportMethodSelected,
    } = this.state;
    const { isBuyNow } = this.props;
    if (paymentOptionCode && paymentOptionSelected && transportMethodSelected) {
      return true;
    } else {
      this.setState({
        paymentOptionError: !paymentOptionCode || !paymentOptionSelected,
        transportError: isBuyNow && !transportMethodSelected,
      });
    }
  };

  handlePurchase = async () => {
    const { car } = this.props;
    if (this.validateNextStep()) {
      if (
        car.outstandingPayments > 0 &&
        this.state.priceOptionSelected === "Residual Price"
      ) {
        this.setState({
          guaranteePayment: !this.state.guaranteePayment,
          loading: true,
        });
      } else {
        this.setState({ loading: true });
        this.finishPurchase();
      }
    }
  };

  finishPurchase = async () => {
    const { car, user, dispatch } = this.props;
    const timeToCancelPurchase = process.env.REACT_APP_TIME_TO_CANCEL_PURCHASE;
    const purchaseOptions = this.state;
    const response = await VehiclePurchaseServices.completePurchase(
      car,
      user,
      purchaseOptions
    );
    if (response.status === "true") {
      dispatch(add_purchased_vehicle(car.vehicleIdentificationNumber));
      this.timeOut = setTimeout(() => {
        dispatch(remove_purchased_vehicle(car.vehicleIdentificationNumber));
      }, timeToCancelPurchase);
      this.setState({ activeStep: 1 });
    } else {
      this.setState({
        purchaseErrorMessage: true,
        loading: false,
      });
    }
  };

  handleBalloonPurchase = async () => {
    const { user, car } = this.props;
    let buyBalloonDetails = await VehiclePurchaseServices.buyBalloonVehicle(
      car.inventoryDTO.id,
      user.userId,
      car.storeName
    );
    if (buyBalloonDetails.status !== "false") {
      this.setState({ activeStep: 1 });
    } else {
      this.setState({
        purchaseErrorMessage: true,
        loading: false,
      });
    }
  };

  renderBalloonConfirmation = () => {
    const { classes, toggleBuyVehicleDone, ground, toggleBuy } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className={classes.balloonConfirmationWrapper}>
          <Typography className={classes.label}>
            {" "}
            The CSC has been notified of your intent to purchase this vehicle
            and will be in contact with you shortly.{" "}
          </Typography>
          <Button
            id="close"
            className={classes.GAMDButtonBalloon}
            onClick={() => (ground ? toggleBuyVehicleDone() : toggleBuy())}
            disabled={loading}
          >
            Close
          </Button>
        </div>
      </React.Fragment>
    );
  };

  toggleGuaranteePayment = () => {
    this.setState({
      guaranteePayment: !this.state.guaranteePayment,
      loading: false,
    });
  };

  toggleGuaranteePaymentDone = () => {
    this.setState({
      paymentGuaranteed: true,
      guaranteePayment: !this.state.guaranteePayment,
    });
    this.finishPurchase();
  };

  onHandleProgramCertNumChange = (event) => {
    this.setState({
      programCertNum: event.target.value,
    });
  };

  onHandleProgramCertCommentsChange = (event) => {
    this.setState({
      programCertComments: event.target.value,
    });
  };

  handleTransportMethod = (evt) => {
    const { car } = this.props;
    if (evt.target.value === "CarsArrive") {
      this.setState({
        transportMethodSelected: evt.target.value,
        totalPriceAmount: car.discountedBuyNowPrice
          ? car.discountedBuyNowPrice + car.adminFee + this.getTransportPrice()
          : car.buyNowPrice + car.adminFee + this.getTransportPrice(),
        transportError: false,
      });
    } else {
      this.setState({
        transportMethodSelected: evt.target.value,
        totalPriceAmount: car.discountedBuyNowPrice
          ? car.discountedBuyNowPrice + car.adminFee
          : car.buyNowPrice + car.adminFee,
        transportError: false,
      });
    }
  };

  handleTransportDTO = (param) => {
    this.setState({
      transportDTO: param,
    });
  };

  handleSpecialInstruction = (param) => {
    this.setState({ specialInstruction: param });
  };

  handleSelectedLocation = (evt) => {
    const location = evt.target.value;
    this.setState({
      selectedLocation: location.locationId,
      selectedLocationDTO: location,
    });
  };

  cancelTimeout = () => {
    clearTimeout(this.timeOut);
  };

  cancelTickDown = () => {
    clearInterval(this.tickDown);
  };

  toggleCancelConfirm = () => {
    this.setState({
      ...this.state,
      confirmPage: false,
      cancelConfirm: true,
    });
  };

  toggleCancelPurchase = () => {
    this.setState({
      ...this.state,
      cancelConfirm: false,
    });
  };

  toggleShowEmailNotification = () => {
    this.setState({
      showEmailNotification: !this.state.showEmailNotification,
    });
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  showAutoCheckReport = () => {
    this.setState({
      showAutoCheckReport: !this.state.showAutoCheckReport,
    });
  };

  toggleLPWCar = (program) => {
    this.setState({
      LPWActiveCar: !this.state.LPWActiveCar,
      selectedProgram: program,
    });
  };

  toggleLPWCarDone = async () => {
    const { dispatch } = this.props;
    const { saleEventItemId, id } = this.props.car;
    const { userId, dealerId, loginId } = this.props.user;
    dispatch(
      select_car_for_purchase(
        await VehiclePurchaseServices.buy(
          id,
          userId,
          dealerId,
          saleEventItemId,
          loginId
        )
      )
    );
    this.setState({
      LPWActiveCar: !this.state.LPWActiveCar,
    });
  };

  renderTitle = () => {
    const { page, classes } = this.props;
    switch (page) {
      case "GAMD":
        return (
          <div className={classes.titleWrapper}>
            <img
              id="GAMDIcon"
              className={classes.GAMDIcon}
              src={circleGAMDIcon}
              alt="GAMD Icon"
            />
            <div className={classes.titleGAMDTextWrapper}>
              <Typography
                className={classes.titleGAMDText}
                style={{ fontWeight: "bold" }}
              >
                Grounded
              </Typography>
              <Typography className={classes.titleGAMDText}>
                At My Dealership
              </Typography>
            </div>
          </div>
        );
      case "DDO":
        return (
          <div className={classes.titleWrapper}>
            <img
              id="DDOIcon"
              className={classes.DDOIcon}
              src={circleDDOIcon}
              alt="DDO Icon"
            />
            <div className={classes.titleDDOTextWrapper}>
              <Typography
                className={classes.titleDDOText}
                style={{ fontWeight: "bold" }}
              >
                Dealer Direct Online
              </Typography>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  renderStepper = (activeStep, steps) => {
    const { classes, page } = this.props;
    return (
      <div className={classes.buyHeader}>
        {this.renderTitle()}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                classes={{ alternativeLabel: classes.stepLabel }}
                StepIconProps={{
                  classes: {
                    root: classes.stepRoot,
                    active:
                      page === "GAMD"
                        ? classes.GAMDStepIcon
                        : classes.DDOStepIcon,
                    completed:
                      page === "GAMD"
                        ? classes.GAMDCompletedIcon
                        : classes.DDOCompletedIcon,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  };

  renderMobileStepper = (activeStep) => {
    const { classes, page } = this.props;
    const label = () => {
      switch (activeStep) {
        case 0:
          return "1. Vehicle Information";
        case 1:
          return "2. Purchase Confirmation";
        default:
          return null;
      }
    };
    return (
      <div
        className={
          page === "GAMD" ? classes.GAMDMobileStepper : classes.DDOMobileStepper
        }
      >
        <Typography className={classes.mobileStepperTitle}>
          {label()}
        </Typography>
      </div>
    );
  };

  renderStepperButton = (activeStep, steps) => {
    const {
      purchaseErrorMessage,
      loading,
      isEarlyTermLease,
      priceOptionSelected,
    } = this.state;
    const { classes, toggleBuyVehicle, ground, toggleBuy, page, car, user } =
      this.props;
    const errorMessage = `Purchase could not be completed at this time, please contact Dealer Direct support 877-522-3372 for assistance.`;

    let enableConfirm =
      car.groundingDealerId == user.dealerId
        ? isEarlyTermLease && priceOptionSelected !== "Market Price"
        : false;

    return (
      <React.Fragment>
        {activeStep === 0 ? (
          <div className={classes.buttonWrapper}>
            <Snackbar
              anchorOrigin={{
                horizontal: "center",
                vertical: "top",
              }}
              open={purchaseErrorMessage}
              autoHideDuration={2000}
              className={classes.errorSnackBar}
              onClose={() => this.setState({ purchaseErrorMessage: false })}
            >
              <SnackbarContent
                className={classes.errorMessage}
                message={errorMessage}
              />
            </Snackbar>
            {page === "LPP" ? (
              <>{this.props.children}</>
            ) : (
              <>
                <Button
                  id="confirm"
                  className={
                    page === "GAMD" ? classes.GAMDButton : classes.DDOButton
                  }
                  onClick={!loading ? () => this.handlePurchase() : null}
                  disabled={enableConfirm}
                >
                  {!loading ? "Confirm" : <Loading color={"#FFFFFF"} />}
                </Button>
                <Button
                  id="cancel"
                  className={
                    page === "GAMD"
                      ? classes.GAMDCancelButton
                      : classes.DDOCancelButton
                  }
                  onClick={() =>
                    ground
                      ? toggleBuyVehicle()
                      : toggleBuy(car ? car : car, false)
                  }
                  disabled={loading}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  renderStep = (stepIndex) => {
    const {
      car,
      carInfo,
      toggleBuyVehicleDone,
      toggleBuy,
      toggleBuyVehicle,
      page,
      ground,
      conditionReport,
      isBuyNow,
    } = this.props;
    const { timeRemainingToCancel, showEmailNotification } = this.state;
    switch (stepIndex) {
      case 0:
        return (
          <React.Fragment>
            {car.accountType === "BALLOON" || this.props.car.balloonVehicle ? (
              this.renderBalloonBuyForm()
            ) : this.state.purchaseInfoLoading ? (
              this.renderPurchaseForm()
            ) : (
              <Loading color={"#FFFFFF"} />
            )}
          </React.Fragment>
        );
      case 1:
        if (car !== undefined && !_.isEmpty(car)) {
          return (
            <React.Fragment>
              {car.accountType === "BALLOON" ||
              this.props.car.balloonVehicle ? (
                this.renderBalloonConfirmation()
              ) : (
                <ConfirmPage
                  car={car}
                  purchaseOptions={this.state}
                  toggleCancelConfirm={this.toggleCancelConfirm}
                  history={this.props.history}
                  toggleShowEmailNotification={this.toggleShowEmailNotification}
                  showEmailNotification={showEmailNotification}
                  toggleBuyVehicleDone={toggleBuyVehicleDone}
                  toggleBuy={toggleBuy}
                  toggleBuyVehicle={toggleBuyVehicle}
                  page={page}
                  ground={ground}
                  conditionReport={conditionReport}
                  cancelTickDown={this.cancelTickDown}
                  cancelTimeout={this.cancelTimeout}
                  isBuyNow={isBuyNow}
                  timeRemainingToCancel={timeRemainingToCancel}
                />
              )}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <ConfirmPage
                car={carInfo}
                purchaseOptions={this.state}
                toggleCancelConfirm={this.toggleCancelConfirm}
                history={this.props.history}
                toggleShowEmailNotification={this.toggleShowEmailNotification}
                showEmailNotification={showEmailNotification}
                toggleBuyVehicleDone={toggleBuyVehicleDone}
                toggleBuy={toggleBuy}
                toggleBuyVehicle={toggleBuyVehicle}
                page={page}
                ground={ground}
                conditionReport={conditionReport}
                cancelTickDown={this.cancelTickDown}
                cancelTimeout={this.cancelTimeout}
                isBuyNow={isBuyNow}
                timeRemainingToCancel={timeRemainingToCancel}
              />
            </React.Fragment>
          );
        }
      default:
        return <div></div>;
    }
  };

  getPurchaseOptions = async (userId, dealerId) => {
    const response = await GroundingServices.purchaseOptions(userId, dealerId);
    let filteredOptions = [];
    let defaultPaymentOption;
    for (let i = 0; i < response.length; i++) {
      response[i]["label"] = "";
      response[i]["value"] = response[i].codeId;
      if (response[i].isSelectedPaymentOption) {
        defaultPaymentOption = response[i].codeId;
      }
      switch (response[i].optionCode) {
        case "FP":
          response[i].label = "TFS Pre-Owned Floor Plan";
          filteredOptions.push(response[i]);
          break;
        case "ACH":
          response[i].label = "ACH";
          filteredOptions.push(response[i]);
          break;
        default:
          break;
      }
    }
    this.setState({ purchaseOptions: filteredOptions });
    defaultPaymentOption = defaultPaymentOption
      ? defaultPaymentOption
      : this.state.purchaseOptions[0].codeId;
    this.setPayment(defaultPaymentOption);
  };

  renderOptions = (carInfo) => {
    const { classes, page } = this.props;
    const { paymentOptionSelected } = this.state;

    carInfo.purchaseOptions.map((option) => {
      return (
        <div key={option.label} className={classes.paymentLineWrapper}>
          <div className={classes.priceWrapper}>
            <Checkbox
              id={`${option.label}Checkbox`}
              classes={{
                root:
                  page === "GAMD"
                    ? classes.checkboxRootGAMD
                    : page === "LPP"
                    ? classes.checkboxRootLPP
                    : classes.checkboxRootDDO,
                checked: classes.checked,
              }}
              onChange={this.selectPayment}
              value={option.value}
              checked={paymentOptionSelected === option.value}
              disabled={option.value === 118 && !option.isActivated}
            />
            <Typography
              style={{ marginLeft: ".5rem" }}
              className={
                page === "GAMD"
                  ? classes.GAMDBoldedColor
                  : page === "LPP"
                  ? classes.LPPBoldedColor
                  : classes.DDOBoldedColor
              }
            >
              {option.label}
            </Typography>
          </div>
        </div>
      );
    });
  };

  renderBalloonBuyForm = () => {
    const { car, carInfo, classes, page, toggleBuyVehicle, ground, toggleBuy } =
      this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className={classes.balloonFormWrapper}>
          <div className={classes.rightWrapper}>
            <div>
              <Typography
                className={
                  page === "GAMD"
                    ? classes.GAMDPricingInfoHeader
                    : classes.DDOPricingInfoHeader
                }
              >
                Purchase Information
              </Typography>
              {page === "LPP" ? (
                <Typography className={classes.carInfoTitle}>
                  {carInfo.make} {carInfo.model} {carInfo.year}
                </Typography>
              ) : (
                <Typography className={classes.carInfoTitle}>
                  {car.make} {car.model} {car.year}
                </Typography>
              )}

              <div className={classes.lowerVehicleInfoWrapper}>
                <div className={classes.innerVehicleInfoWrapper}>
                  <Typography className={classes.label}>
                    {" "}
                    VIN:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {car.vehicleIdentificationNumber
                        ? car.vehicleIdentificationNumber
                        : car.inventoryDTO.vehicleIdentificationNumber}
                    </span>
                  </Typography>

                  <Typography className={classes.label}>
                    {" "}
                    Odometer:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {car.inspectionMileage
                        ? car.inspectionMileage
                        : car.groundingMileage
                        ? car.groundingMileage
                        : car.odometerInformationDTO.mileage}
                    </span>
                  </Typography>
                </div>
                <div className={classes.carReportWrapper}>
                  <div id="carfaxLinkwrapper">
                    <ReactSVG
                      id="carfaxLink"
                      className={classes.carReportLink}
                      src={carfaxLogo}
                      alt="carfax"
                      onClick={() => this.showCarfaxReport()}
                      svgStyle={{ width: "5rem", height: "2rem" }}
                    />
                  </div>
                  <div id="autocheckLinkwrapper">
                    <ReactSVG
                      id="autocheckLink"
                      className={classes.carReportLink}
                      src={autoCheckLogo}
                      alt="autocheck"
                      onClick={() => this.showAutoCheckReport()}
                      svgStyle={{ width: "5rem", height: "2rem" }}
                      style={{ marginTop: ".5rem" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.paymentSelectionWrapper}></div>
            <div className={classes.summaryWrapper}>
              <Button
                id="confirm"
                className={classes.GAMDButton}
                onClick={!loading ? () => this.handleBalloonPurchase() : null}
              >
                {!loading ? "Confirm" : <Loading color={"#FFFFFF"} />}
              </Button>
              <Button
                id="cancel"
                className={classes.GAMDCancelButton}
                onClick={() => (ground ? toggleBuyVehicle() : toggleBuy())}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  checkForPayoffOption = () => {
    const car = this.props.car || this.props.carInfo;
    if (car.invParticipatingPrograms.length > 0) {
      const purchaseOptions =
        car.invParticipatingPrograms[0].purchaseOptionCodes;
      purchaseOptions.forEach((purchaseCode) => {
        if (purchaseCode === "PAYOFF") return true;
      });
    }
    return false;
  };

  renderPurchaseForm = () => {
    const {
      car,
      carInfo,
      classes,
      page,
      conditionReport,
      isBuyNow,
      updateGuaranteePayment,
      ground,
      pageTheme,
      user,
    } = this.props;
    const {
      priceOptionCode,
      programCertNum,
      programCertComments,
      LPWActiveCar,
      selectedLocation,
      totalPriceAmount,
      guaranteePayment,
      activeStep,
      steps,
      paymentOptionSelected,
      transportDTO,
      transportMethodSelected,
      selectedLocationDTO,
      selectedProgram,
      priceOptionSelected,
      paymentOptionError,
      transportError,
      alfaVehicleDataNotRecieved,
      isEarlyTermLease,
    } = this.state;
    return (
      <React.Fragment>
        {!guaranteePayment && !LPWActiveCar ? (
          <div className={classes.buyWrapper}>
            {!isBuyNow && car.dealerFacingStatus !== "DLR_BUY_NOW" ? (
              <React.Fragment>
                <div className={classes.buyPriceWrapper}>
                  {conditionReport ? (
                    <div className={classes.imageWrapper}>
                      {conditionReport.inspectionImageDTO
                        .slice(0, 5)
                        .map((image) => {
                          return (
                            <img
                              key={image.inspectionImagesId}
                              src={image.imageUrl}
                              className={classes.vehicleImage}
                              alt=""
                            />
                          );
                        })}
                    </div>
                  ) : null}
                  {this.state.loading ? (
                    <Loading color={"#FFFFFF"} />
                  ) : (
                    <div className={classes.lowerLeftWrapper}>
                      <Typography
                        className={
                          page === "GAMD"
                            ? classes.GAMDPricingInfoHeader
                            : page === "LPP"
                            ? classes.LPPPricingInfoHeader
                            : classes.DDOPricingInfoHeader
                        }
                        style={
                          isEarlyTermLease ? { marginBottom: "0rem" } : null
                        }
                      >
                        Purchase Information
                      </Typography>
                      {isEarlyTermLease ? (
                        <Typography className={classes.earlyTermLabel}>
                          {EARLY_TERM_LABEL}
                        </Typography>
                      ) : null}

                      {alfaVehicleDataNotRecieved ? (
                        <div className={classes.payOffPrice}>
                          {PAYOFF_PRICE_NOT_AVAILABLE} <br />
                          {CALL_ADESA_SUPPORT}
                        </div>
                      ) : null}

                      <div className={classes.infoWrapper}>
                        {car.invParticipatingPrograms.length > 0 &&
                        this.checkForPayoffOption() ? (
                          <div className={classes.lineWrapper}>
                            <Typography className={classes.bolded}>
                              Payoff Price (Good through{" "}
                              {moment(car.goodThruDate).format("MM/DD/YYYY")})
                            </Typography>
                            <div className={classes.priceWrapper}>
                              <Typography
                                className={
                                  page === "GAMD"
                                    ? classes.GAMDBoldedColor
                                    : page === "LPP"
                                    ? classes.LPPBoldedColor
                                    : classes.DDOBoldedColor
                                }
                              >
                                {page === "LPP" ? (
                                  carInfo.grossPayoff.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                ) : car.grossPayoff ? (
                                  car.grossPayoff.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                ) : (
                                  <div style={{ color: "black" }}>Pending</div>
                                )}
                              </Typography>
                              <Checkbox
                                id="GPOCheckbox"
                                classes={{
                                  root:
                                    page === "GAMD"
                                      ? classes.checkboxRootGAMD
                                      : page === "LPP"
                                      ? classes.checkboxRootLPP
                                      : classes.checkboxRootDDO,
                                  checked: classes.checked,
                                }}
                                onChange={this.selectPrice}
                                value="GPO"
                                checked={priceOptionCode === "GPO"}
                                disabled={!car.grossPayoff}
                              />
                            </div>
                            <Typography className={classes.systemMessage}>
                              Note: excludes tax, license, and other fees that
                              may apply*
                            </Typography>
                          </div>
                        ) : !isEarlyTermLease &&
                          car.invParticipatingPrograms.length === 0 ? (
                          <div className={classes.lineWrapper}>
                            <Typography className={classes.bolded}>
                              Payoff Price (Good through{" "}
                              {moment(car.goodThruDate).format("MM/DD/YYYY")})
                            </Typography>
                            <div className={classes.priceWrapper}>
                              <Typography
                                className={
                                  page === "GAMD"
                                    ? classes.GAMDBoldedColor
                                    : page === "LPP"
                                    ? classes.LPPBoldedColor
                                    : classes.DDOBoldedColor
                                }
                              >
                                {page === "LPP" ? (
                                  carInfo.grossPayoff.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                ) : car.grossPayoff ? (
                                  car.grossPayoff.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                ) : (
                                  <div style={{ color: "black" }}>Pending</div>
                                )}
                              </Typography>
                              <Checkbox
                                id="GPOCheckbox"
                                classes={{
                                  root:
                                    page === "GAMD"
                                      ? classes.checkboxRootGAMD
                                      : page === "LPP"
                                      ? classes.checkboxRootLPP
                                      : classes.checkboxRootDDO,
                                  checked: classes.checked,
                                }}
                                onChange={this.selectPrice}
                                value="GPO"
                                checked={priceOptionCode === "GPO"}
                                disabled={!car.grossPayoff}
                              />
                            </div>
                            <Typography className={classes.systemMessage}>
                              Note: excludes tax, license, and other fees that
                              may apply*
                            </Typography>
                          </div>
                        ) : null}
                        {page !== "LPP" &&
                        (car.waivedPayments === null ||
                          car.waivedPayments === 0) &&
                        car.renderResidualAmount &&
                        !isEarlyTermLease ? (
                          <div className={classes.lineWrapper}>
                            <Typography className={classes.bolded}>
                              Residual Price
                            </Typography>
                            <div className={classes.priceWrapper}>
                              <Typography
                                className={
                                  page === "GAMD"
                                    ? classes.GAMDBoldedColor
                                    : page === "LPP"
                                    ? classes.LPPBoldedColor
                                    : classes.DDOBoldedColor
                                }
                              >
                                {car.residualAmount &&
                                  car.residualAmount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                              </Typography>
                              <Checkbox
                                id="RESCheckbox"
                                classes={{
                                  root:
                                    page === "GAMD"
                                      ? classes.checkboxRootGAMD
                                      : page === "LPP"
                                      ? classes.checkboxRootLPP
                                      : classes.checkboxRootDDO,
                                  checked: classes.checked,
                                }}
                                onChange={this.selectPrice}
                                value="RES"
                                checked={priceOptionCode === "RES"}
                              />
                            </div>
                          </div>
                        ) : null}
                        {page !== "LPP" ? (
                          <div className={classes.lineWrapper}>
                            <Typography className={classes.bolded}>
                              Market Price
                            </Typography>
                            <div className={classes.priceWrapper}>
                              <Typography
                                className={
                                  page === "GAMD"
                                    ? classes.GAMDBoldedColor
                                    : page === "LPP"
                                    ? classes.LPPBoldedColor
                                    : classes.DDOBoldedColor
                                }
                              >
                                {car.isMBPApproved
                                  ? car.marketBasedPrice.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                      }
                                    )
                                  : "Awaiting Inspection"}
                              </Typography>
                              <Checkbox
                                id="MBPCheckbox"
                                classes={{
                                  root:
                                    page === "GAMD"
                                      ? classes.checkboxRootGAMD
                                      : classes.checkboxRoot,
                                  checked: classes.checked,
                                }}
                                onChange={this.selectPrice}
                                value="MBP"
                                checked={priceOptionCode === "MBP"}
                                disabled={!car.isMBPApproved}
                              />
                            </div>
                          </div>
                        ) : null}

                        {page !== "LPP" &&
                        priceOptionSelected === "Residual Price" &&
                        car.remainingPaymentsAmt > 0 &&
                        !isEarlyTermLease ? (
                          <div className={classes.lineWrapper}>
                            <Typography className={classes.bolded}>
                              Remaining Payments
                            </Typography>
                            <Typography
                              style={{ marginRight: "2rem" }}
                              className={
                                page === "GAMD"
                                  ? classes.GAMDBoldedColor
                                  : page === "LPP"
                                  ? classes.LPPBoldedColor
                                  : classes.DDOBoldedColor
                              }
                            >
                              {car.remainingPaymentsAmt.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                }
                              )}
                            </Typography>
                          </div>
                        ) : null}
                        <div className={classes.lineWrapper}>
                          <Typography className={classes.bolded}>
                            Administrative Fee
                          </Typography>
                          <Typography
                            style={{ marginRight: "2rem" }}
                            className={
                              page === "GAMD"
                                ? classes.GAMDBoldedColor
                                : page === "LPP"
                                ? classes.LPPBoldedColor
                                : classes.DDOBoldedColor
                            }
                          >
                            {page === "LPP"
                              ? "$ 0.00"
                              : car.adminFee &&
                                car.adminFee.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                          </Typography>
                        </div>
                        {!isEarlyTermLease ? (
                          <Typography
                            className={
                              page === "LPP"
                                ? classes.systemMessageLPP
                                : classes.systemMessage2
                            }
                          >
                            *Dealer may be responsible for outstanding
                            fees/penalties for CA registered vehicles.
                          </Typography>
                        ) : null}
                        <div className={classes.lineWrapper}>
                          <Typography className={classes.bolded}>
                            Total
                          </Typography>
                          {(isEarlyTermLease && car.isMBPApproved) ||
                          !isEarlyTermLease ? (
                            <Typography
                              style={{ marginRight: "2rem" }}
                              className={
                                page === "GAMD"
                                  ? classes.GAMDBoldedColor
                                  : page === "LPP"
                                  ? classes.LPPBoldedColor
                                  : classes.DDOBoldedColor
                              }
                            >
                              {page !== "LPP"
                                ? totalPriceAmount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                : carInfo.grossPayoff.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                            </Typography>
                          ) : (
                            <div
                              style={{
                                color: "black",
                                fontSize: ".7rem",
                                fontWeight: "bold",
                              }}
                            >
                              Pending
                            </div>
                          )}
                        </div>
                        {isEarlyTermLease && !car.isMBPApproved ? (
                          <div className={classes.e2eNoMBPMessage}>
                            Market Price not available. Please retry shortly or
                            Call Dealer Direct Support at 1-877-522-3372
                          </div>
                        ) : null}
                        {priceOptionCode === "GPO" &&
                        car.groundingDealerId == user.dealerId ? (
                          <div className={classes.lesseePayOffDisclaimer}>
                            {LESSEE_PAYOFF_DISCLAIMER}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}

                  {car.vehicleTitleDelay ? (
                    <Typography className={classes.NOSMessage}>
                      Vehicle has been grounded as an Early Termination pending
                      Notice of Sale to the lessee. As a result, release of
                      title for purchase may be delayed and is conditional on
                      expiration of the Notice of Sale
                    </Typography>
                  ) : null}
                </div>
              </React.Fragment>
            ) : car.invStatusInAuction === "OFRD_FIRST_PRE_AUCT" &&
              car.isVehAtLoggedInDlrLot ? (
              <React.Fragment>
                <div className={classes.buyPriceWrapper}>
                  {conditionReport ? (
                    <div className={classes.imageWrapper}>
                      {conditionReport.inspectionImageDTO
                        .slice(0, 5)
                        .map((image) => {
                          return (
                            <img
                              key={image.inspectionImagesId}
                              src={image.imageUrl}
                              className={classes.vehicleImage}
                              alt=""
                            />
                          );
                        })}
                    </div>
                  ) : null}
                  <div className={classes.lowerLeftWrapper}>
                    <Typography
                      className={
                        page === "GAMD"
                          ? classes.GAMDPricingInfoHeader
                          : classes.DDOPricingInfoHeader
                      }
                    >
                      Purchase Information
                    </Typography>
                    <div className={classes.infoWrapper}>
                      <div className={classes.lineWrapper}>
                        <Typography className={classes.bolded}>
                          Payoff Price (Good through{" "}
                          {moment(car.goodThruDate).format("MM/DD/YYYY")})
                        </Typography>
                        <div className={classes.priceWrapper}>
                          <Typography
                            className={
                              page === "GAMD"
                                ? classes.GAMDBoldedColor
                                : page === "LPP"
                                ? classes.LPPBoldedColor
                                : classes.DDOBoldedColor
                            }
                          >
                            {car.grossPayoff &&
                              car.grossPayoff.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                          </Typography>
                          <Checkbox
                            id="GPOCheckbox"
                            classes={{
                              root:
                                page === "GAMD"
                                  ? classes.checkboxRootGAMD
                                  : page === "LPP"
                                  ? classes.checkboxRootLPP
                                  : classes.checkboxRootDDO,
                              checked: classes.checked,
                            }}
                            onChange={this.selectPrice}
                            value="GPO"
                            checked={priceOptionCode === "GPO"}
                            disabled={!car.grossPayoff}
                          />
                        </div>
                      </div>
                      <Typography className={classes.systemMessage}>
                        Note: excludes tax, license, and other fees that may
                        apply*
                      </Typography>
                      {(car.waivedPayments === null ||
                        car.waivedPayments === 0) &&
                      car.invParticipatingPrograms.length === 0 ? (
                        <div className={classes.lineWrapper}>
                          <Typography className={classes.bolded}>
                            Residual Price
                          </Typography>
                          <div className={classes.priceWrapper}>
                            <Typography
                              className={
                                page === "GAMD"
                                  ? classes.GAMDBoldedColor
                                  : page === "LPP"
                                  ? classes.LPPBoldedColor
                                  : classes.DDOBoldedColor
                              }
                            >
                              {car.residualAmount &&
                                car.residualAmount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                            </Typography>
                            <Checkbox
                              id="RESCheckbox"
                              classes={{
                                root:
                                  page === "GAMD"
                                    ? classes.checkboxRootGAMD
                                    : page === "LPP"
                                    ? classes.checkboxRootLPP
                                    : classes.checkboxRootDDO,
                                checked: classes.checked,
                              }}
                              onChange={this.selectPrice}
                              value="RES"
                              checked={priceOptionCode === "RES"}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className={classes.lineWrapper}>
                        <Typography className={classes.bolded}>
                          Market Price
                        </Typography>
                        <div className={classes.priceWrapper}>
                          <Typography
                            className={
                              page === "GAMD"
                                ? classes.GAMDBoldedColor
                                : page === "LPP"
                                ? classes.LPPBoldedColor
                                : classes.DDOBoldedColor
                            }
                          >
                            {car.marketBasedPrice.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Typography>
                          <Checkbox
                            id="MBPCheckbox"
                            classes={{
                              root:
                                page === "GAMD"
                                  ? classes.checkboxRootGAMD
                                  : classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                            onChange={this.selectPrice}
                            value="MBP"
                            checked={priceOptionCode === "MBP"}
                          />
                        </div>
                      </div>
                      {priceOptionSelected === "Residual Price" &&
                      car.remainingPaymentsAmt > 0 ? (
                        <div className={classes.lineWrapper}>
                          <Typography className={classes.bolded}>
                            Remaining Payments
                          </Typography>
                          <Typography
                            style={{ marginRight: "2rem" }}
                            className={
                              page === "GAMD"
                                ? classes.GAMDBoldedColor
                                : page === "LPP"
                                ? classes.LPPBoldedColor
                                : classes.DDOBoldedColor
                            }
                          >
                            {car.remainingPaymentsAmt.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Typography>
                        </div>
                      ) : null}
                      <div className={classes.lineWrapper}>
                        <Typography className={classes.bolded}>
                          Administrative Fee
                        </Typography>
                        <Typography
                          style={{ marginRight: "2rem" }}
                          className={
                            page === "GAMD"
                              ? classes.GAMDBoldedColor
                              : page === "LPP"
                              ? classes.LPPBoldedColor
                              : classes.DDOBoldedColor
                          }
                        >
                          {car.adminFee &&
                            car.adminFee.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Typography>
                      </div>
                      <Typography
                        className={
                          page === "LPP"
                            ? classes.systemMessageLPP
                            : classes.systemMessage2
                        }
                      >
                        *Dealer may be responsible for outstanding
                        fees/penalties for CA registered vehicles.
                      </Typography>
                      <div className={classes.lineWrapper}>
                        <Typography className={classes.bolded}>
                          Total
                        </Typography>
                        <Typography
                          style={{ marginRight: "2rem" }}
                          className={
                            page === "GAMD"
                              ? classes.GAMDBoldedColor
                              : page === "LPP"
                              ? classes.LPPBoldedColor
                              : classes.DDOBoldedColor
                          }
                        >
                          {totalPriceAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                      </div>
                      {priceOptionCode === "GPO" &&
                      car.groundingDealerId == user.dealerId ? (
                        <div className={classes.lesseePayOffDisclaimer}>
                          {LESSEE_PAYOFF_DISCLAIMER}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {car.vehicleTitleDelay ? (
                    <Typography className={classes.NOSMessage}>
                      Vehicle has been grounded as an Early Termination pending
                      Notice of Sale to the lessee. As a result, release of
                      title for purchase may be delayed and is conditional on
                      expiration of the Notice of Sale
                    </Typography>
                  ) : null}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={classes.buyPriceWrapper}>
                  {conditionReport ? (
                    <div className={classes.imageWrapper}>
                      {conditionReport.inspectionImageDTO &&
                        conditionReport.inspectionImageDTO
                          .slice(0, 5)
                          .map((image) => {
                            return (
                              <img
                                key={image.inspectionImagesId}
                                src={image.imageUrl}
                                className={classes.vehicleImage}
                                alt=""
                              />
                            );
                          })}
                    </div>
                  ) : null}
                  <div className={classes.lowerLeftWrapper}>
                    <div className={classes.infoWrapper}>
                      <Typography
                        className={
                          page === "GAMD"
                            ? classes.GAMDPricingInfoHeader
                            : classes.DDOPricingInfoHeader
                        }
                      >
                        Purchase Information
                      </Typography>
                      <div className={classes.lineWrapper}>
                        <Typography className={classes.bolded}>
                          Buy Now Price
                        </Typography>
                        <div className={classes.priceWrapper}>
                          <Typography
                            className={
                              page === "GAMD"
                                ? classes.GAMDBoldedColor
                                : page === "LPP"
                                ? classes.LPPBoldedColor
                                : classes.DDOBoldedColor
                            }
                          >
                            {car.discountedBuyNowPrice
                              ? car.discountedBuyNowPrice.toLocaleString(
                                  "en-US",
                                  { style: "currency", currency: "USD" }
                                )
                              : car.buyNowPrice.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                          </Typography>
                        </div>
                      </div>
                      <Typography className={classes.buyNowSystemMessage}>
                        Note: excludes tax, license, and other fees that may
                        apply*
                      </Typography>
                      <div className={classes.lineWrapper}>
                        <Typography className={classes.bolded}>
                          Administrative Fee
                        </Typography>
                        <Typography
                          className={
                            page === "GAMD"
                              ? classes.GAMDBoldedColor
                              : page === "LPP"
                              ? classes.LPPBoldedColor
                              : classes.DDOBoldedColor
                          }
                        >
                          {car.adminFee &&
                            car.adminFee.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </Typography>
                      </div>
                      <Typography className={classes.buyNowSystemMessage2}>
                        *Dealer may be responsible for outstanding
                        fees/penalties for CA registered vehicles.
                      </Typography>
                      <div className={classes.lineWrapper}>
                        <Typography className={classes.bolded}>
                          Total
                        </Typography>
                        <Typography
                          className={
                            page === "GAMD"
                              ? classes.GAMDBoldedColor
                              : page === "LPP"
                              ? classes.LPPBoldedColor
                              : classes.DDOBoldedColor
                          }
                        >
                          {totalPriceAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  {car.vehicleTitleDelay ? (
                    <Typography className={classes.NOSMessage}>
                      Vehicle has been grounded as an Early Termination pending
                      Notice of Sale to the lessee. As a result, release of
                      title for purchase may be delayed and is conditional on
                      expiration of the Notice of Sale
                    </Typography>
                  ) : null}
                </div>
              </React.Fragment>
            )}
            <div className={classes.rightWrapper}>
              <div>
                <div>
                  {page === "LPP" ? (
                    <Typography className={classes.carInfoTitle}>
                      {carInfo.make} {carInfo.model} {carInfo.year}
                    </Typography>
                  ) : (
                    <Typography className={classes.carInfoTitle}>
                      {car.make} {car.model} {car.year}
                    </Typography>
                  )}
                  <div className={classes.lowerVehicleInfoWrapper}>
                    <div className={classes.innerVehicleInfoWrapper}>
                      <Typography className={classes.label}>
                        {" "}
                        VIN:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {page === "LPP"
                            ? carInfo.vehicleIdentificationNumber
                            : car.vehicleIdentificationNumber}
                        </span>
                      </Typography>
                      {page !== "LPP" ? (
                        <Typography className={classes.label}>
                          {" "}
                          Odometer:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {car.inspectionMileage
                              ? car.inspectionMileage
                              : car.groundingMileage}
                          </span>
                        </Typography>
                      ) : null}
                    </div>
                    <div className={classes.carReportWrapper}>
                      <div id="carfaxLinkwrapper">
                        <ReactSVG
                          id="carfaxLink"
                          className={classes.carReportLink}
                          src={carfaxLogo}
                          alt="carfax"
                          onClick={() => this.showCarfaxReport()}
                          svgStyle={{ width: "5rem", height: "2rem" }}
                        />
                      </div>
                      <div id="autocheckLinkwrapper">
                        <ReactSVG
                          id="autocheckLink"
                          className={classes.carReportLink}
                          src={autoCheckLogo}
                          alt="autocheck"
                          onClick={() => this.showAutoCheckReport()}
                          svgStyle={{ width: "5rem", height: "2rem" }}
                          style={{ marginTop: ".5rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.paymentSelectionWrapper}>
                  <Typography
                    className={classes.paymentSelectionTitle}
                    style={paymentOptionError ? { color: "red" } : null}
                  >
                    Payment Method
                  </Typography>
                  {!_.isEmpty(car)
                    ? car.purchaseOptions.map((option) => {
                        return (
                          <div
                            key={option.label}
                            className={classes.paymentLineWrapper}
                          >
                            <div className={classes.priceWrapper}>
                              <Checkbox
                                id={`${option.label}Checkbox`}
                                classes={{
                                  root:
                                    page === "GAMD"
                                      ? classes.checkboxRootGAMD
                                      : page === "LPP"
                                      ? classes.checkboxRootLPP
                                      : classes.checkboxRootDDO,
                                  checked: classes.checked,
                                }}
                                onChange={this.selectPayment}
                                value={option.value}
                                checked={paymentOptionSelected === option.value}
                                disabled={
                                  option.value === 118 &&
                                  car.creditLimit - totalPriceAmount <= 0
                                }
                              />
                              <Typography
                                style={{ marginLeft: ".5rem" }}
                                className={
                                  page === "GAMD"
                                    ? classes.GAMDBoldedColor
                                    : page === "LPP"
                                    ? classes.LPPBoldedColor
                                    : classes.DDOBoldedColor
                                }
                              >
                                {option.label}
                              </Typography>
                            </div>
                          </div>
                        );
                      })
                    : this.state.purchaseOptions.map((option) => {
                        return (
                          <div
                            key={option.label}
                            className={classes.paymentLineWrapper}
                          >
                            <div className={classes.priceWrapper}>
                              <Checkbox
                                id={`${option.label}Checkbox`}
                                classes={{
                                  root:
                                    page === "GAMD"
                                      ? classes.checkboxRootGAMD
                                      : page === "LPP"
                                      ? classes.checkboxRootLPP
                                      : classes.checkboxRootDDO,
                                  checked: classes.checked,
                                }}
                                onChange={this.selectPayment}
                                value={option.value}
                                checked={option.isSelectedPaymentOption}
                                disabled={!option.isSelectedPaymentOption}
                              />
                              <Typography
                                style={{ marginLeft: ".5rem" }}
                                className={
                                  page === "GAMD"
                                    ? classes.GAMDBoldedColor
                                    : page === "LPP"
                                    ? classes.LPPBoldedColor
                                    : classes.DDOBoldedColor
                                }
                              >
                                {option.label}
                              </Typography>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
              {isBuyNow && !car.isVehAtLoggedInDlrLot ? (
                <TransportMethod
                  car={car}
                  onHandleTransportDTO={this.handleTransportDTO}
                  onHandleTransportMethod={this.handleTransportMethod}
                  onHandleSpecialInstruction={this.handleSpecialInstruction}
                  onHandleSelectedLocation={this.handleSelectedLocation}
                  custLocations={car.purchasingDealerLocations}
                  location={selectedLocation}
                  locationDTO={selectedLocationDTO}
                  transportDTO={transportDTO}
                  transportMethodSelected={transportMethodSelected}
                  transportError={transportError}
                />
              ) : null}

              <div className={classes.summaryWrapper}>
                <div className={classes.acceptanceWrapper}>
                  {car.allProgramDTOs ? (
                    <Typography id="promowrapper" className={classes.promoText}>
                      {`This vehicle is available for promotions:`}{" "}
                      {car.allProgramDTOs.map((program) => {
                        return (
                          <Link
                            id={`LPWLink` + lpwCount++}
                            key={`Key` + lpwCount++}
                            onClick={(evt) => this.toggleLPWCar(program)}
                            className={classes.linkColor}
                            title={
                              program.programName +
                              " Caption. You are eligible to participate in the " +
                              program.programName
                            }
                          >
                            {program.programCode}{" "}
                          </Link>
                        );
                      })}
                    </Typography>
                  ) : null}
                  <Typography className={classes.acceptanceText}>
                    {`I have read and agree to the Terms and Conditions stated in
                                        the `}
                    <a
                      id="participatingDealerAgreement"
                      className={classes.PDALink}
                      href={
                        awsUrl +
                        (pageTheme === "Lexus"
                          ? "/docs/lexus/resources/ParticipatingDealerAgreement.pdf"
                          : "/docs/toyota/resources/ParticipatingDealerAgreement.pdf")
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Participating Dealer Agreement
                    </a>
                  </Typography>
                </div>
                {this.renderStepperButton(activeStep, steps)}
              </div>
            </div>
          </div>
        ) : null}
        {guaranteePayment ? (
          <GuaranteePayments
            car={this.getCarInfoForGuaranteePayments()}
            toggleGuaranteePayment={this.toggleGuaranteePayment}
            toggleGuaranteePaymentDone={this.toggleGuaranteePaymentDone}
            user={this.props.user}
            isBuyFlow={true}
            updateGuaranteePayment={updateGuaranteePayment}
            ground={ground}
          />
        ) : null}
        {car.programDTO &&
        car.programDTO[0] &&
        car.programDTO[0].programCode === "LPW" &&
        LPWActiveCar ? (
          <GAMDLPWEntryForm
            car={car}
            onHandleChange={this.handleChange}
            programDTOInfo={selectedProgram}
            programCertNum={programCertNum}
            programCertComments={programCertComments}
            certVerifyMessage="This Payment Waiver Program will require you to guarantee remaining payments."
            toggleLPWCar={this.toggleLPWCar}
            toggleLPWCarDone={this.toggleLPWCarDone}
            from="Buy"
          />
        ) : null}
      </React.Fragment>
    );
  };

  getCarInfoForGuaranteePayments = () => {
    const {
      make,
      model,
      year,
      vehicleIdentificationNumber,
      id,
      remainingPaymentsAmt,
    } = this.props.car;
    const car = {
      id: id,
      year: year,
      make: make,
      model: model,
      vehicleIdentificationNumber: vehicleIdentificationNumber,
      remainingPaymentsAmt: remainingPaymentsAmt,
    };
    return car;
  };

  renderPage = () => {
    const { activeStep, timeRemainingToCancel } = this.state;
    const { car, classes, toggleBuyVehicle, toggleBuy, ground, page } =
      this.props;
    const { cancelConfirm } = this.state;

    if (cancelConfirm) {
      return (
        <div className={classes.root}>
          <CancelConfirm
            car={car}
            purchaseOptions={this.state}
            cancelTickDown={this.cancelTickDown}
            cancelTimeout={this.cancelTimeout}
            history={this.props.history}
            toggleCancelPurchase={this.toggleCancelPurchase}
            toggleBuyVehicle={toggleBuyVehicle}
            toggleBuy={toggleBuy}
            ground={ground}
            page={page}
            timeRemainingToCancel={timeRemainingToCancel}
          />
        </div>
      );
    } else {
      return this.renderStep(activeStep);
    }
  };

  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  removeCarForPurchaseFromReduxState() {
    this.props.dispatch(select_car_for_purchase({}));
  }

  componentWillUnmount = () => {
    this.cancelTickDown();
    this.removeCarForPurchaseFromReduxState();
  };

  render() {
    const { car, user, isBuyNow, classes, page } = this.props;
    const {
      showCarfaxReport,
      activeStep,
      showAutoCheckReport,
      transportError,
      paymentOptionError,
      showCarfaxSnackBar,
    } = this.state;
    const steps = getSteps();

    return (
      <React.Fragment>
        <div className={classes.buyPageWrapper}>
          {this.renderMobileStepper(activeStep)}
          {page === "LPP" ? null : this.renderStepper(activeStep, steps)}
          {this.renderPage()}
          {showCarfaxReport ? (
            <CarReport
              vin={
                car.vehicleIdentificationNumber
                  ? car.vehicleIdentificationNumber
                  : car.inventoryDTO.vehicleIdentificationNumber
              }
              reportType="carfax"
              showCarfaxReport={this.showCarfaxReport}
              showNoDataVin={this.showNoDataVin}
              user={user}
            />
          ) : null}
          {showCarfaxSnackBar ? (
            <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
          ) : null}
          {showAutoCheckReport ? (
            <AutoCheckReport
              vin={
                car.vehicleIdentificationNumber
                  ? car.vehicleIdentificationNumber
                  : car.vin
              }
              reportType="carfax"
              showAutoCheckReport={this.showAutoCheckReport}
              user={user}
            />
          ) : null}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={transportError && isBuyNow}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">Please select transport method</span>
            }
            style={{ top: "13rem" }}
          />
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={paymentOptionError}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">Please select payment method</span>}
            style={{ top: "10rem" }}
          />
        </div>
      </React.Fragment>
    );
  }
}

BuyVehicle.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    car: state.cars.carForPurchase,
    user: state.user,
    cancelTimers: state.ui.cancelTimers,
    accountSetting: state.accountSetting,
    pageTheme: state.ui.pageTheme,
    togglzMap: state.ui.togglzMap,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(BuyVehicle));
