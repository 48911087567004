export function canGroundVehicles(userRoles) {
  return (
    userRoles.includes("DEALERSHIP_ADMIN") ||
    userRoles.includes("GROUNDING_CLERK")
  );
}

export function canPurchaseVehicles(userRoles) {
  return userRoles.includes("BUYER");
}

export function canManageArbitrations(userRoles) {
  return (
    userRoles.includes("DEALERSHIP_ADMIN") ||
    userRoles.includes("OPEN_PROCESS_ARBITRATION")
  );
}

export function canManagePayments(userRoles) {
  return (
    userRoles.includes("DEALERSHIP_ADMIN") ||
    userRoles.includes("ACH_BANK_INFO")
  );
}

export function canViewOnly(userRoles) {
  return (
    userRoles.includes("DEALERSHIP_ADMIN") ||
    userRoles.includes("DLR_VIEW_ONLY")
  );
}

export function isDealerAdmin(userRoles) {
  return userRoles.includes("DEALERSHIP_ADMIN");
}

export function isRideshareUser(userRoles) {
  // Has Allow - MOBILITY_RIDESHARE_ALLOW
  // Has View - MOBILITY_RIDESHARE_VIEW
  return (
    userRoles.includes("MOBILITY_RIDESHARE_ALLOW") ||
    userRoles.includes("MOBILITY_RIDESHARE_VIEW")
  );
}
