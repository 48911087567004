import { PureComponent } from "react";

export class AutoCheckReport extends PureComponent {
  renderForm = (vin, user) => {
    let html = "<html><body>";
    html +=
      "<form action='https://www.autocheck.com/DealerWebLink.jsp' method='post' name='autoCheckForm'>";
    html += `<input type='hidden' name='VIN' value='${vin}' escape='false' />`;
    html += "<input type='hidden' name='CID' value='5051375' escape='false' />";
    html += `<input type='hidden' name='SID' value='${user.autoCheckSid}' escape='false' />`;
    html +=
      "<input type='hidden' name='PWD' value='8uGh59O2Tp' escape='false' />";
    html += "</form>";
    html += "<script>document.forms['autoCheckForm'].submit();</script>";
    html += "</body></html>";
    const newWindow = window.open(
      null,
      null,
      "resizable=1,scrollbars=1,location=0,status=0,toolbar=0,menubar=0,width=675,height=650"
    );
    newWindow.document.write(html);
    return null;
  };

  componentWillUnmount = () => {
    const { showAutoCheckReport } = this.props;
    showAutoCheckReport();
  };

  render() {
    const { vin, user } = this.props;
    return this.renderForm(vin, user);
  }
}

export default AutoCheckReport;
