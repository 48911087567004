import { saveAs } from "file-saver";
const API_URL = process.env.REACT_APP_API_URL;

export async function ground(data, user) {
  const token = sessionStorage.getItem("token");
  let programCertNumAndComments = {};
  programCertNumAndComments[data.programCertNum] = data.programCertComments;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      inventoryId: data.vinDetails.inventoryDTO.id,
      vin: data.vinDetails.inventoryDTO.vehicleIdentificationNumber,
      accountNumber:
        data.vinDetails.inventoryDTO.leaseInformationDTO.accountNumber,
      LesseeNoSignReason: "No Pad Present",
      DealerNoSignReason: "No Pad Present",
      dealerId: user.dealerId,
      userId: user.userId,
      adminId: data.vinDetails.adminId,
      loginId: user.loginId,
      mileage: data.odometer,
      signedOdometerOptionValue: data.disclosureStatementValue,
      locationId: data.custLocation.locationDTO.id,
      numberOfKeys: data.masterKeys,
      numberOfValetKeys: data.valetKeys,
      numberOfKeylessRemotes: null,
      isSignaturePadConnected: false,
      hasDealerSignature: false,
      hasLesseeSignature:
        !!data.padValueToSendToAPI || data.customerWillSignPaperStatement,
      dealerSignature: null,
      lesseeSignature: data.padValueToSendToAPI,
      customerReturnDate: data.customerReturnDate,
      isDrivable: data.vehicleDrivable === "true",
      specialInstructions: data.specialInstructions,
      grossPayAmount:
        data.vinDetails.inventoryDTO.leaseInformationDTO.grossPayAmount,
      baseRent: data.vinDetails.inventoryDTO.leaseInformationDTO.baseRent,
      payOutdata: data.vinDetails.inventoryDTO.leaseInformationDTO.payOutDate,
      monthsRemain:
        data.vinDetails.inventoryDTO.leaseInformationDTO.monthsRemain,
      isOldPayoff: data.vinDetails.inventoryDTO.leaseInformationDTO.isOldPayoff,
      gndRealtimeService: true,
      programCertNumAndComments,
      bckupRemainingPaymentAmount:
        data.vinDetails.inventoryDTO.leaseInformationDTO
          .bckupRemainingPaymentAmount,
      paymentWaiverAvailable:
        data.vinDetails.programDTOs && data.vinDetails.programDTOs !== null
          ? true
          : false,
      dlrGuaranteePayment: data.guaranteeAdditionalRemainingPayments,
      isWaivePayments: data.programCertNum !== "" ? true : false,
      outStandingPayments:
        data.programCertNum === ""
          ? data.vinDetails.inventoryDTO.leaseInformationDTO
              .remainingPaymentAmount
          : data.guaranteeAdditionalRemainingPayments
          ? 0.0
          : Number(data.outStandingPayments).toFixed(2),
      guaranteeAdditionalRemainingPayments:
        data.guaranteeAdditionalRemainingPayments,
      programWaivedPayments:
        data.programCertNum !== ""
          ? Number(data.waivedPayments).toFixed(2)
          : null,
      waivedPayments:
        data.programCertNum !== ""
          ? Number(data.waivedPayments).toFixed(2)
          : null,
      remainingPayments: Number(
        data.vinDetails.inventoryDTO.leaseInformationDTO.remainingPaymentAmount
      ).toFixed(2),
      guaranteedPayments:
        data.programCertNum !== "" && data.guaranteeAdditionalRemainingPayments
          ? Number(data.outStandingPayments).toFixed(2)
          : null,
      customerLesseeWill: data.customerWillDDValue,
    }),
  };
  try {
    const response = await fetch(
      `${API_URL}/groundingService/groundweb`,
      options
    ); //For Odometer statement pdf
    return response.json();
  } catch (err) {
    return null;
  }
}

export async function vinDetails(vin, loginId, customerReturnDate) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let customerReturnDateParam = `&returnDate=`;
  if (customerReturnDate) {
    customerReturnDateParam = `&returnDate=${customerReturnDate.getTime()}`;
  }

  try {
    const response = await fetch(
      `${API_URL}/groundingService/vindetails?vin=${vin}&loginId=${loginId}&inventoryId=${customerReturnDateParam}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export function downloadPdf(odometerPdf) {
  fetch("data:application/pdf;base64," + odometerPdf)
    .then(function (resp) {
      return resp.blob();
    })
    .then(function (blob) {
      saveAs(blob, "OdometerStatement.pdf");
    });
}

export const purchaseOptions = async (userId, dealerId) => {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(
      `${API_URL}/utility/getPaymentOption?userId=${userId}&dealerId=${dealerId}`,
      options
    );
    return response.json();
  } catch (err) {
    return { status: false, message: err.message };
  }
};

export const guaranteePayments = async (userId, inventoryId) => {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(
      `${API_URL}/groundingService/guaranteePayments?userId=${userId}&inventoryId=${inventoryId}`,
      options
    );
    return response.json();
  } catch (err) {
    return { status: false, message: err.message };
  }
};

export async function getInventory(inventoryId) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(
      `${API_URL}/groundingService/getInventory?inventoryId=${inventoryId}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    console.error("Failed to get inventory", err);
  }
}
