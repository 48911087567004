import { combineReducers } from "redux";
import ui from "../reducer/ui";
import user from "../reducer/user";
import cars from "../reducer/cars";
import accountSetting from "../reducer/AccountSetting.reducer";
import stateInfo from "../reducer/StateInfo.reducer";
import storageSession from "redux-persist/lib/storage/session";
import mobilityState from "../reducer/mobility.reducer";

const SIGNOUT_REQUEST = "SIGNOUT_REQUEST";

export const appReducer = combineReducers({
  ui,
  user,
  cars,
  stateInfo,
  accountSetting,
  mobilityState,
});

const rootReducer = (state, action) => {
  if (action.type === SIGNOUT_REQUEST) {
    // for all keys defined in your persistConfig(s)
    storageSession.removeItem("persist:root");
    // storageSession.removeItem('persist:otherKey')

    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
