export const validateEmail = (email) => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
  let re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return re.test(String(phone));
};

export const validatePassword = (password) => {
  //Minimum 8 characters
  //Must contain one upper and one lowercase character
  //Must contain at least 1 number
  let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/;
  return re.test(String(password));
};

export const validateStrongerPassword = (password) => {
  //Minimum 8 characters and Max 128 characters
  //Must contain one upper and one lowercase character
  //Must contain atleast 1 number
  //Must contain atleast 1 special character
  let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ ~^|<=>/:;,+'"()@$!%*#?&])[A-Za-z\d ~^|<=>/:;,+'"()@$!%*#?&]{10,127}$/;
  return re.test(String(password));
};
