import React, { Component } from "react";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import {
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  LEASE_INFORMATION,
  LEASE_NAME,
  LEASE_TYPE,
  MATURITY_DATE,
  REM_PAYMENTS,
  VIN,
} from "../constants/UILabel";

const styles = (theme) => ({
  tableBody: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tableRow: {
    height: "1rem",
    [theme.breakpoints.down("sm")]: {
      height: "1.6rem",
      width: "100%",
      justifyContent: "space-evenly",
      display: "flex",
      "@supports (-ms-ime-align:auto)": {
        justifyContent: "space-around",
      },
    },
  },
  tableRowDark: {
    height: "1rem",
    [theme.breakpoints.down("sm")]: {
      height: "1.6rem",
      width: "100%",
      justifyContent: "space-evenly",
      display: "flex",
      backgroundColor: "#eef0f2",
      "@supports (-ms-ime-align:auto)": {
        justifyContent: "space-around",
      },
    },
  },
  tableCell: {
    borderBottom: 0,
    width: "15rem",
    padding: 0,
    color: theme.palette.primary.gray,
    height: "auto !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      height: "1.5rem",
      width: "100%",
      justifyContent: "space-between",
      padding: "0 !important",
      alignItems: "center",
    },
  },
  groundingInformationTable: {
    width: "55rem",
    [theme.breakpoints.down("md")]: {
      width: "48rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  groundingInformationTableMob: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
    },
  },
  h2Padding: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.grounding.main,
      fontSize: "1rem",
      margin: ".5rem",
      display: "block",
    },
  },
  tableLabel: {
    textAlign: "left",
    paddingLeft: ".5rem",
    borderLeft: `.5rem solid ${theme.palette.primary.gray}`,
    lineHeight: "1.6rem",
    fontWeight: "600",
  },
  tableData: {
    textAlign: "right",
    paddingRight: ".5rem",
  },
});
export class GroundingInformationDisplay extends Component {
  render() {
    const { classes, from, finalGroundedVehicleData, groundInfo } = this.props;
    const {
      remainingPaymentAmount,
      lesseeName,
      leaseEndDate,
      accountType,
      leaseType,
      accountNumber,
      alfaSourceIndicator,
      alfaAccountNumber,
    } = this.props.vinDetails.inventoryDTO.leaseInformationDTO;
    const { exteriorColor, vehicleIdentificationNumber } =
      this.props.vinDetails.inventoryDTO;

    return (
      <React.Fragment>
        <Table className={classes.groundingInformationTable}>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell
                className={classes.tableCell}
              >{`Maturity Date: ${moment(new Date(leaseEndDate)).format(
                "MM/DD/YY"
              )}`}</TableCell>
              <TableCell className={classes.tableCell}>{`Account Type: ${
                accountType === "LEASE" ? "Off lease" : accountType
              }`}</TableCell>
              <TableCell
                className={classes.tableCell}
              >{`VIN: ${vehicleIdentificationNumber}`}</TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              {from && from === "groundingConfirmation" ? (
                <TableCell className={classes.tableCell}>{`Lease Type: ${
                  this.props.originalleaseType
                    ? this.props.originalleaseType
                    : ""
                }`}</TableCell>
              ) : (
                <TableCell className={classes.tableCell}>{`Lease Type: ${
                  leaseType ? leaseType : ""
                }`}</TableCell>
              )}
              <TableCell className={classes.tableCell}>
                {alfaSourceIndicator === "ALFA"
                  ? `Account Number: ${alfaAccountNumber}`
                  : `Account Number: ${accountNumber}`}
              </TableCell>
              <TableCell
                className={classes.tableCell}
              >{`Color: ${exteriorColor}`}</TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell
                className={classes.tableCell}
              >{`Lease Name: ${lesseeName}`}</TableCell>
              <TableCell className={classes.tableCell}>
                {from &&
                from === "groundingConfirmation" &&
                finalGroundedVehicleData.waivedPayments &&
                finalGroundedVehicleData.waivedPayments > 0
                  ? null
                  : `Rem. Payments: ${
                      remainingPaymentAmount > 0
                        ? remainingPaymentAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : "$0"
                    }`}
              </TableCell>
              <TableCell className={classes.tableCell}></TableCell>
            </TableRow>
            {from &&
            from === "groundingConfirmation" &&
            finalGroundedVehicleData.waivedPayments &&
            finalGroundedVehicleData.waivedPayments > 0 ? (
              <TableRow className={classes.tableRow}>
                <TableCell
                  className={classes.tableCell}
                >{`Current Rem Payments: ${
                  finalGroundedVehicleData.outStandingPayments
                    ? finalGroundedVehicleData.outStandingPayments.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : "$0"
                }`}</TableCell>
                <TableCell className={classes.tableCell}>{`Waived Payments: ${
                  finalGroundedVehicleData.programWaivedPayments
                    ? finalGroundedVehicleData.programWaivedPayments.toLocaleString(
                        "en-US",
                        { style: "currency", currency: "USD" }
                      )
                    : "$0"
                }`}</TableCell>
                <TableCell className={classes.tableCell}>
                  {groundInfo && groundInfo.guaranteeAdditionalRemainingPayments
                    ? `Guaranteed Payments: ${
                        groundInfo.outStandingPayments
                          ? groundInfo.outStandingPayments.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : "$0"
                      }`
                    : `Guaranteed Payments: ${
                        finalGroundedVehicleData.guaranteedPayments
                          ? finalGroundedVehicleData.guaranteedPayments.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : "$0"
                      }`}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        <Table className={classes.groundingInformationTableMob}>
          <TableBody className={classes.tableBody}>
            <TableRow className={classes.tableRowDark}>
              <TableCell className={classes.tableCell}>
                <div className={classes.tableLabel}>{VIN}</div>
                <div className={classes.tableData}>
                  {vehicleIdentificationNumber}
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <div className={classes.tableLabel}>{REM_PAYMENTS}</div>
                <div className={classes.tableData}>
                  {remainingPaymentAmount > 0
                    ? remainingPaymentAmount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : "$0"}
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowDark}>
              <TableCell className={classes.tableCell}>
                <div className={classes.tableLabel}>Color</div>
                <div className={classes.tableData}>{exteriorColor}</div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2 className={classes.h2Padding}>{LEASE_INFORMATION}</h2>
        <Table className={classes.groundingInformationTableMob}>
          <TableBody className={classes.tableBody}>
            <TableRow className={classes.tableRowDark}>
              <TableCell className={classes.tableCell}>
                <div className={classes.tableLabel}>{MATURITY_DATE}</div>
                <div className={classes.tableData}>
                  {moment(new Date(leaseEndDate)).format("MM/DD/YY")}
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <div className={classes.tableLabel}>{ACCOUNT_TYPE}</div>
                <div className={classes.tableData}>
                  {accountType === "LEASE" ? "Off lease" : accountType}
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowDark}>
              {from && from === "groundingConfirmation" ? (
                <TableCell className={classes.tableCell}>
                  <div className={classes.tableLabel}>{LEASE_TYPE}</div>
                  <div className={classes.tableData}>
                    {this.props.originalleaseType
                      ? this.props.originalleaseType
                      : ""}
                  </div>
                </TableCell>
              ) : (
                <TableCell className={classes.tableCell}>
                  <div className={classes.tableLabel}>{LEASE_TYPE}</div>
                  <div className={classes.tableData}>
                    {leaseType ? leaseType : ""}
                  </div>
                </TableCell>
              )}
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <div className={classes.tableLabel}>{ACCOUNT_NUMBER}</div>
                <div className={classes.tableData}>{accountNumber}</div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowDark}>
              <TableCell className={classes.tableCell}>
                <div className={classes.tableLabel}>{LEASE_NAME}</div>
                <div className={classes.tableData}>{lesseeName}</div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(GroundingInformationDisplay);
