import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import renderHTML from "react-render-html";

import PropTypes from "prop-types";

export const styles = (theme) => ({
  container: {
    marginLeft: "1rem",
    marginRight: "1rem",
    width: "auto",
    height: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3.5rem",
    },
  },
  paper: {
    marginTop: "1rem",
    flexDirection: "column",
    alignItems: "left",
    padding: `.5rem .5rem`,
    display: "flex",
    width: "100%",
    overflowY: "auto",
    height: "90%",
    [theme.breakpoints.down("sm")]: {
      height: "79%",
      paddingBottom: "6rem",
    },
  },
});

export class Privacy extends Component {
  state = {
    content: null,
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.paper}>
          {this.state.content !== null ? renderHTML(this.state.content) : null}
        </div>
      </div>
    );
  }
}

Privacy.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Privacy);
