import React, { Component } from "react";
import fetchIntercept from "fetch-intercept";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { Redirect } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import TagManager from "react-gtm-module";

import reducer from "reducer";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storageSession from "redux-persist/lib/storage/session";

import Footer from "./components/Footer";
import Home from "./components/Home";
import MobilityHome from "./components/MobilityHome";
import Login from "./components/Login";
import Header from "./components/Header";
import GAMD from "./components/GAMD";
import DesktopNavMenu from "./components/DesktopNavMenu";
import ForgotPassword from "./components/ForgotPassword";
import PasswordReset from "./components/PasswordReset";
import Legal from "./components/Legal";
import Privacy from "./components/Privacy";
import Ground from "./components/Ground";
import PriceQuote from "./components/PriceQuote";
import PreTermPriceQuote from "./components/PreTermPriceQuote";
import AccountSettings from "./components/AccountSettings";
import Loading from "./components/Loading";
import DDO from "./components/DDO";
import WatchList from "./components/WatchList";
import Reports from "./components/Reports";
import MobilityReports from "./components/MobilityReports";
import AdvanceSearch from "./components/AdvanceSearch";
import QuickSearch from "./components/QuickSearch";
import AdministratorNavMenu from "./components/AdministratorNavMenu";
import Help from "./components/Help";
import BuyerView from "./components/BuyerView";
import SSOLogin from "./components/SSOLogin";
import MobileNavMenu from "./components/MobileNavMenu";
import ConditionReportExternalWrapper from "./components/ConditionReportExternalWrapper";
import CallingInformation from "./components/CallingInformation";
import MobilityVehicleCard from "./components/MobilityVehicleCard";
import AppDownloadModal from "./components/AppDownloadModal";

import { set_page_theme } from "./actions/uiActions";

const GTM_AUTH = process.env.REACT_APP_GTM_AUTH;
const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW;

const tagManagerArgs = {
  gtmId: "GTM-T26S4TQ",
  auth: GTM_AUTH,
  preview: GTM_PREVIEW,
};

const persistConfig = {
  key: "root",
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
};

const pReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  pReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export const persistor = persistStore(store);

store.dispatch(set_page_theme());

const styles = (theme) => ({
  app: {
    width: "100vw",
    height: "100vh",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      width: "100vw",
    },
  },
});

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed() === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
}
/**
 * TagManager.initialize(tagManagerArgs);
 */
fetchIntercept.register({
  response: function (response) {
    if (response.url.includes("TDDMobileIntegrationService")) {
      if (response.status === 200) {
        return response;
      } else if (response.status === 401 || response.status === 403) {
        sessionStorage.removeItem("token");
        window.location.reload();
      }
    }
    return response;
  },

  responseError: function (error) {
    return Promise.reject(error);
  },
});

class App extends Component {
  componentDidMount = () => {
    this.handleInactivity();
  };

  handleInactivity = () => {
    const timeout = process.env.REACT_APP_INACTIVITY_TIMEOUT;
    let inactiveCountdown = setTimeout(() => forcedLogout(), timeout);

    const forcedLogout = () => {
      sessionStorage.removeItem("token");
      window.localStorage.removeItem("url_carfax_microservices");
      window.localStorage.removeItem("theme");
      window.location.reload();
    };

    const resetTimer = () => {
      clearTimeout(inactiveCountdown);
      inactiveCountdown = setTimeout(() => forcedLogout(), timeout);
    };

    const events = ["mousedown", "mousemove", "keypress", "scroll"];
    events.forEach((name) => {
      document.addEventListener(name, resetTimer, true);
    });
  };

  checkAuth = () => {
    const token = sessionStorage.getItem("token");
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <Router>
            <MuiThemeProvider theme={store.getState().ui.theme}>
              <div className={classes.app}>
                <React.Fragment>
                  <div className={classes.contentContainer}>
                    <Header />
                    <DesktopNavMenu />
                    <Switch>
                      <Route exact path="/" component={Login} />
                      <Route exact path="/buyerview" component={BuyerView} />
                      <Route exact path="/ssoLogin" component={SSOLogin} />
                      <Route
                        exact
                        path="/conditionreport/:inventoryId/:userId/:dealerId"
                        component={ConditionReportExternalWrapper}
                      />
                      <Route
                        path="/forgotpassword"
                        component={ForgotPassword}
                      />
                      <Route
                        path="/callingInformation"
                        component={CallingInformation}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/mobility"
                        component={MobilityHome}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/home"
                        component={Home}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/GAMD"
                        component={GAMD}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/DDO"
                        component={DDO}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/reports"
                        component={Reports}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/mobilityReports"
                        component={MobilityReports}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/mobilityVehicleCard"
                        component={MobilityVehicleCard}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/legal"
                        component={Legal}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/privacy"
                        component={Privacy}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/changepassword"
                        component={PasswordReset}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/ground"
                        component={Ground}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/priceQuote"
                        component={PriceQuote}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/PreTermPriceQuote"
                        component={PreTermPriceQuote}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/accountsetting"
                        component={AccountSettings}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/admin"
                        component={AdministratorNavMenu}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/watchlist"
                        component={WatchList}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/advancesearch"
                        component={AdvanceSearch}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/quicksearch"
                        component={QuickSearch}
                      />
                      <PrivateRoute
                        authed={this.checkAuth}
                        path="/help"
                        component={Help}
                      />
                      <Route
                        render={() => <Redirect to={{ pathname: "/" }} />}
                      />
                    </Switch>
                    <MobileNavMenu />
                    <AppDownloadModal />
                    <Footer />
                  </div>
                </React.Fragment>
              </div>
            </MuiThemeProvider>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default withStyles(styles)(App);
