import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";

import {
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Link,
  Button,
} from "@material-ui/core";

const styles = (theme) => ({
  pageWrapper: {
    padding: "1.5rem",
    maxHeight: "100%",
    overflowY: "auto",
    minHeight: "72%",
  },
  tableWrapper: {
    width: "100%",
  },
  muitablecellRoot: {
    padding: "0.4rem",
    paddingRight: "0.6rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  tableRowHeader: {
    backgroundColor: theme.palette.headerColor.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  searchButtonWrapper: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  button: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.primary.gray,
    width: "8rem",
    fontWeight: "bold",
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.primary.gray,
      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.gray,
        "&:active": {
          backgroundColor: theme.palette.primary.gray,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: ".5rem",
      width: "7rem",
      height: "2.5rem",
      "&:hover": {
        backgroundColor: theme.palette.primary.gray,
        "@media (hover: none)": {
          backgroundColor: theme.palette.primary.gray,
          "&:active": {
            backgroundColor: theme.palette.primary.gray,
          },
        },
      },
    },
  },
  tabButton: {
    height: "2.0rem",
    boxShadow: "none",
    borderRadius: 0,
    backgroundColor: theme.palette.headerColor.gray,
    width: "50%",
    fontWeight: "bold",
    textTransform: "initial",
    marginBottom: ".1rem",
  },
  activeTabButton: {
    height: "2.0rem",
    boxShadow: "none",
    borderRadius: 0,
    backgroundColor: "#C2BFBF",
    width: "50%",
    fontWeight: "bold",
    textTransform: "initial",
    borderBottom: ".1rem solid #EB0A1E",
    marginBottom: ".1rem",
  },
});

export class MobilitySavedSearch extends Component {
  state = {
    activeTab: 1,
    inactiveTab: 0,
  };

  toggleSearchEdit = (info) => {
    const { showCreateSearch } = this.props;
    showCreateSearch(info);
  };

  showSearchResult = (searchName, userRoleHasMobilityRideshareView) => {
    const { history } = this.props;
    history.push({
      pathname: "/mobilityVehicleCard",
      state: {
        searchName: searchName,
        userRoleHasMobilityRideshareView: userRoleHasMobilityRideshareView,
      },
    });
  };

  toggleTab = (tab) => {
    if (tab === "active") {
      this.setState({
        activeTab: 1,
        inactiveTab: 0,
      });
    } else {
      this.setState({
        activeTab: 0,
        inactiveTab: 1,
      });
    }
  };

  renderSearchEntry = (savedSearch) => {
    const { classes, userRoleHasMobilityRideshareView } = this.props;
    return savedSearch && savedSearch.length > 0 ? (
      savedSearch.map((info) => (
        <TableRow
          id={`${info.searchName}-Row`}
          key={`${info.searchName}-RowKey`}
          className={classes.tableRow}
        >
          <TableCell className={classes.muitablecellRoot}>
            {info.programType !== "ALM" ? (
              <Link
                id={`${info.searchName}-Count`}
                className={classes.linkStyle}
                component="button"
                variant="body2"
                onClick={() =>
                  this.showSearchResult(
                    info.searchName,
                    userRoleHasMobilityRideshareView
                  )
                }
              >
                {info.searchName}
              </Link>
            ) : (
              info.searchName
            )}
          </TableCell>
          <TableCell className={classes.muitablecellRoot}>
            {info.programType}
          </TableCell>
          <TableCell className={classes.muitablecellRoot}>
            {info.saleEventName}
          </TableCell>
          <TableCell className={classes.muitablecellRoot}>
            <Link
              id={`${info.searchName}-View`}
              className={classes.linkStyle}
              component="button"
              variant="body2"
              onClick={() => this.toggleSearchEdit(info)}
            >
              View/Edit
            </Link>
          </TableCell>
          <TableCell className={classes.muitablecellRoot}>
            <Moment interval={0} format={"MM/DD/YYYY"}>
              {info.searchStartDate}
            </Moment>
          </TableCell>
          <TableCell className={classes.muitablecellRoot}>
            <Moment interval={0} format={"MM/DD/YYYY"}>
              {info.searchEndDate}
            </Moment>
          </TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow key={"noSearch"} className={classes.tableRowFull}>
        <TableCell colSpan={5} className={classes.tableCell}>
          No Saved Search.
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { activeTab, inactiveTab } = this.state;
    const {
      classes,
      showCreateSearch,
      userRoleHasMobilityRideshareView,
      activeSavedSearch,
      inactiveSavedSearch,
    } = this.props;
    return (
      <React.Fragment>
        <div className={classes.pageWrapper}>
          <Typography variant="h6" className={classes.heading}>
            Saved Mobility Rideshare Search
          </Typography>
          <div className={classes.tableWrapper}>
            <div>
              <div>
                <Button
                  className={
                    activeTab === 1
                      ? classes.activeTabButton
                      : classes.tabButton
                  }
                  onClick={() => this.toggleTab("active")}
                >
                  Active
                </Button>
                <Button
                  className={
                    inactiveTab === 1
                      ? classes.activeTabButton
                      : classes.tabButton
                  }
                  onClick={() => this.toggleTab("inactive")}
                >
                  Inactive
                </Button>
              </div>
            </div>
            <Table>
              <TableBody key={"body"}>
                <TableRow key={"head"} className={classes.tableRowHeader}>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>Program Type</strong>
                  </TableCell>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>Sale Event</strong>
                  </TableCell>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>Criteria</strong>
                  </TableCell>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>Start Date</strong>
                  </TableCell>
                  <TableCell className={classes.muitablecellRoot}>
                    <strong>End Date</strong>
                  </TableCell>
                </TableRow>
                {this.renderSearchEntry(
                  activeTab === 1 ? activeSavedSearch : inactiveSavedSearch
                )}
              </TableBody>
            </Table>
          </div>
          <div className={classes.searchButtonWrapper}>
            <Button
              id="createSearch"
              className={classes.button}
              onClick={() => showCreateSearch()}
              disabled={userRoleHasMobilityRideshareView}
            >
              Create Search
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    activeSavedSearch: state.mobilityState.activeMobilitySavedSearch,
    inactiveSavedSearch: state.mobilityState.inactiveMobilitySavedSearch,
  };
};
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(MobilitySavedSearch))
);
