import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { non_safari_mobile_app_download_notification_modal } from "../actions/uiActions";

import { Button } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  MOBILE_APP_ANNOUNCEMENT,
  MOBILE_APP_ANNOUNCEMENT_TEXT1,
  MOBILE_APP_ANNOUNCEMENT_TEXT2,
  MOBILE_APP_ANNOUNCEMENT_TEXT3,
} from "../constants/UILabel";

const styles = (theme) => ({
  icon: {
    margin: ".5rem",
    fontSize: "2rem",
    fill: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginLeft: "1.5rem",
      fontSize: "1.3rem",
      marginTop: ".5rem",
    },
  },
  button: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "5rem",
    fontWeight: "bold",
    textTransform: "initial",
    fontSize: ".8rem",
    marginTop: "0rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  title: {
    fontWeight: "bold",
    color: theme.palette.button.main,
    borderBottom: ".2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
  textWrapper: {
    fontSize: ".8rem",
    padding: "1rem",
    paddingTop: "0rem",
    paddingBottom: "0rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
    },
  },
  iconButton: {},
  input: {
    paddingLeft: ".5rem",
    fontSize: ".8rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  root: {
    width: "45%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});

export class NonSafariIOSAppDownloadNoticeModal extends Component {
  state = {};

  handleClose = () => {
    const { dispatch } = this.props;
    dispatch(
      non_safari_mobile_app_download_notification_modal({
        isHidden: true,
        isSeen: true,
      })
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div>
          <>
            <Dialog
              maxWidth="lg"
              open={
                !this.props.nonSafariMobileAppDownloadNotificationModal.isHidden
              }
              onClose={() => this.handleClose()}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle>
                <div className={classes.title}>{MOBILE_APP_ANNOUNCEMENT}</div>
              </DialogTitle>
              <DialogContent>
                <div className={classes.textWrapper}>
                  {MOBILE_APP_ANNOUNCEMENT_TEXT1}
                  <br /> {MOBILE_APP_ANNOUNCEMENT_TEXT2}
                  <br />
                  {MOBILE_APP_ANNOUNCEMENT_TEXT3}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.button}
                  onClick={() => this.handleClose()}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </div>
      </>
    );
  }
}

NonSafariIOSAppDownloadNoticeModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    nonSafariMobileAppDownloadNotificationModal:
      state.ui.nonSafariMobileAppDownloadNotificationModal,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(NonSafariIOSAppDownloadNoticeModal)
);
