import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Card, Typography } from "@material-ui/core";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { saveNote } from "../services/NotesServices";
import { getDate } from "../services/HelperServices";

const styles = (theme) => ({
  textField: {
    margin: ".5rem",
    width: "67%",
  },
  textFieldInput: {
    height: "6rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.font.small,
      height: "initial",
    },
  },
  textFieldInputDisabled: {
    color: theme.palette.primary.gray,
    opacity: ".7",
  },
  wrapper: {
    margin: ".5rem",
    display: "flex",
    width: "97%",
    zIndex: 2,
    marginTop: 0,
  },
  selectedCard: {
    margin: ".5rem",
    width: "98%",
    zIndex: 2,
    marginTop: "-.25rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
    },
  },
  reportsSelectedCard: {
    margin: ".5rem",
    width: "98%",
    zIndex: 2,
  },
  conditionReportCard: {
    margin: ".5rem",
    width: "53%",
    zIndex: 2,
    height: "9rem",
    marginTop: "2rem",
  },
  contentWrapper: {
    display: "flex",
  },
  authorCard: {},
  upperCard: {
    display: "flex",
    margin: "1rem",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginLeft: ".25rem",
    },
  },
  button: {
    margin: ".5rem",
    marginLeft: 0,
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "5rem",
    fontWeight: "bold",
    textTransform: "initial",
    fontSize: theme.font.small,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".5rem",
      width: "3rem",
      marginBottom: 0,
      "&:hover": {
        backgroundColor: theme.palette.button.main,
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: theme.palette.button.main,
          "&:active": {
            backgroundColor: theme.palette.button.main,
          },
        },
      },
    },
  },
  buttonWrapper: {
    display: "flex",
  },
  navButton: {
    width: "2rem",
    minWidth: 0,
  },
  navWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "8rem",
  },
  textWrapper: {
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  cardTitle: {
    color: theme.palette.primary.white,
    fontWeight: "bold",
    backgroundColor: theme.palette.grounding.main,
    textAlign: "center",
    borderRadius: "0 0 .75rem .75rem",
  },
  lowerLeftWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
});

export class Notes extends Component {
  state = {
    noteEnabled: false,
    newNote: "",
    noteIndex: 0,
    isSaving: false,
    noteIsValid: true,
  };

  componentDidMount = () => {
    const { notes } = this.props;
    if (notes[0]) {
      this.setState({
        newNote: notes[0].note,
      });
    }
  };

  setNoteSaving = (savingStatus) => {
    this.setState({ isSaving: savingStatus });
  };

  noteEntry = () => {
    this.setState({
      noteEnabled: !this.state.noteEnabled,
      newNote: "",
      noteIsValid: true,
    });
  };

  nextNote = () => {
    const { noteIndex } = this.state;
    const { notes } = this.props;
    if (noteIndex !== notes.length - 1) {
      this.setState({
        noteIndex: noteIndex + 1,
        newNote: notes[noteIndex + 1].note,
      });
    }
  };

  prevNote = () => {
    const { noteIndex } = this.state;
    const { notes } = this.props;
    if (noteIndex !== 0) {
      this.setState({
        noteIndex: noteIndex - 1,
        newNote: notes[noteIndex - 1].note,
      });
    }
  };

  handleSave = async () => {
    const { vehicleIdentificationNumber, id, inventoryId, vin } =
      this.props.car;
    const { dealerId, userId } = this.props.user;
    const { addNote, notes } = this.props;
    const { newNote } = this.state;
    let sentVin = vehicleIdentificationNumber
      ? vehicleIdentificationNumber
      : vin;
    const sentId = inventoryId ? inventoryId : id;
    this.setNoteSaving(true);
    if (this.props.conditionReport) {
      sentVin = this.props.conditionReport.vin;
    }
    const response = await saveNote(dealerId, userId, sentId, sentVin, newNote);
    if (response.status === "true") {
      this.setNoteSaving(false);
      addNote(newNote);
      this.setState({
        noteEnabled: false,
        noteIndex: notes.length,
        noteIsValid: true,
      });
    }
  };

  onChange = (evt) => {
    let note = evt.target.value;

    this.setState({
      newNote: note,
      noteIsValid: this.noteDoesNotContainsInvalidCharacters(note),
    });
  };

  noteDoesNotContainsInvalidCharacters = (note) => {
    if (!note || note === "") {
      return true;
    }

    const regex = new RegExp(/[+\-=@]/);
    return !regex.test(note.charAt(0));
  };

  renderNoteClassName = () => {
    const { reports, fromConditionReport, classes } = this.props;
    if (reports) {
      return classes.reportsSelectedCard;
    } else if (fromConditionReport) {
      return classes.conditionReportCard;
    } else {
      return classes.selectedCard;
    }
  };

  render() {
    const { toggleNote, notes, accountSetting, classes, reports } = this.props;
    const { noteIndex, noteEnabled, newNote, noteIsValid } = this.state;
    const noteAuthor = notes[0]
      ? `${notes[0].firstName} ${notes[0].lastName}`
      : `${accountSetting.firstName} ${accountSetting.lastName}`;
    const updated = notes[0] ? `${notes[0].createdDate}` : getDate();
    return (
      <Card className={this.renderNoteClassName()}>
        <Typography className={classes.cardTitle}>Notes</Typography>
        <div className={classes.contentWrapper}>
          <div className={classes.upperCard}>
            <div className={classes.authorCard}>
              <Typography className={classes.textWrapper}>
                <span style={{ fontWeight: "bold" }}>Author:</span>
                <span>{` ${noteAuthor}`}</span>
              </Typography>
              <Typography className={classes.textWrapper}>
                <span style={{ fontWeight: "bold" }}>Last Updated:</span>
                <span>{` ${updated}`}</span>
              </Typography>
            </div>
            <div className={classes.lowerLeftWrapper}>
              <div className={classes.buttonWrapper}>
                {noteEnabled ? (
                  <React.Fragment>
                    <Button
                      id="saveButton"
                      onClick={() => this.handleSave()}
                      className={classes.button}
                      disabled={
                        newNote.replace(/\s+/g, "") === "" ||
                        this.state.isSaving ||
                        !this.state.noteIsValid
                      }
                    >
                      Save
                    </Button>
                    {!reports ? (
                      <Button
                        id="cancelButton"
                        onClick={() => toggleNote()}
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {!reports ? (
                      <Button
                        id="doneButton"
                        onClick={() => toggleNote()}
                        className={classes.button}
                      >
                        Done
                      </Button>
                    ) : null}
                    <Button
                      id="addNoteButton"
                      onClick={() => this.noteEntry()}
                      className={classes.button}
                    >
                      Add Note
                    </Button>
                  </React.Fragment>
                )}
              </div>
              {notes[0] && !noteEnabled ? (
                <div className={classes.navWrapper} style={{ display: "flex" }}>
                  <Button
                    id="prevNote"
                    size="small"
                    onClick={() => this.prevNote()}
                    className={classes.navButton}
                  >
                    <KeyboardArrowLeft />
                  </Button>
                  <Typography>{`${noteIndex + 1} / ${
                    notes.length
                  }`}</Typography>
                  <Button
                    id="nextNote"
                    size="small"
                    onClick={() => this.nextNote()}
                    className={classes.navButton}
                  >
                    <KeyboardArrowRight />
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
          <TextField
            error={!noteIsValid}
            id="noteInput"
            placeholder="Click 'Add Note', and enter text"
            value={newNote}
            onChange={(evt) => this.onChange(evt)}
            multiline
            rows="4"
            InputProps={{
              classes: {
                disabled: classes.textFieldInputDisabled,
                input: classes.textFieldInput,
              },
              inputProps: {
                styles: {
                  width: "100%",
                },
                maxLength: 400,
              },
            }}
            helperText={
              !noteIsValid
                ? "Note may not start with the following characters: + - = or @"
                : null
            }
            className={classes.textField}
            margin="normal"
            variant="outlined"
            disabled={!noteEnabled}
          />
        </div>
      </Card>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    accountSetting: state.accountSetting,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Notes));
