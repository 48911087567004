import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Snackbar } from "@material-ui/core";
import * as LoginServices from "../services/LoginServices";
import * as userActions from "../actions/userActions";
import * as accountSettingActions from "../actions/AccountSetting.actions";
import * as stateInfoActions from "../actions/StateInfo.actions";
import { set_buyer_view, set_auction_times } from "../actions/uiActions";
import { loadStateInformation } from "../services/UtilityServices";
import Loading from "../components/Loading";
import { setCurrentAuctionTimes } from "../services/HelperServices";

import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
});

export class BuyerView extends Component {
  state = {
    error: null,
    loading: null,
  };

  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search);
    this.setState({ loading: true });
    this.handleLogin(values.ssoId);
  };

  loadStateInformation = async () => {
    try {
      const stateInfo = await loadStateInformation();
      return stateInfo;
    } catch (err) {
      console.log("Error occured :", err);
    }
  };

  throwError = (error) => {
    this.setState({
      error,
      loading: false,
    });
  };

  handleLogin = async (ssoId) => {
    const { history, dispatch } = this.props;
    this.setState({ loading: true });
    try {
      const user = await LoginServices.loginBuyerView(ssoId);
      if (user.message === "Login success.") {
        sessionStorage.setItem("token", user.token);
        const accountSetting = await LoginServices.loadAccountSetting(
          user.userId
        );
        const stateInfo = await this.loadStateInformation();
        dispatch(set_auction_times(setCurrentAuctionTimes()));
        dispatch(stateInfoActions.loadStateInfoSuccess(stateInfo.stateDTOs));
        dispatch(userActions.loginSuccess(user));
        dispatch(
          accountSettingActions.loadAccountSettingSuccess(accountSetting)
        );
        dispatch(set_buyer_view());
        history.replace("/home");
      } else if (user.status === 503) {
        this.throwError(user.message);
      } else {
        this.throwError("Unsuccessful Login: Check SSO_ID");
        setTimeout(() => history.replace("/"), 2000);
      }
    } catch (err) {
      this.throwError(err.message);
    }
  };

  render() {
    const { classes } = this.props;
    const { loading, error } = this.state;
    return (
      <React.Fragment>
        {loading && (
          <div className={classes.loading}>
            <Loading />
          </div>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={error !== null}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{error}</span>}
        />
      </React.Fragment>
    );
  }
}

export default connect()(withStyles(styles)(BuyerView));
