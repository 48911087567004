import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import CarListTile from "../components/CarListTile";
import DDOCarListTile from "../components/DDOCarListTile";

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    width: "100%",
    backgroundColor: theme.palette.background.dark,
  },
});

export class CarDisplay extends Component {
  state = {
    openNote: null,
    openTransport: null,
  };

  componentWillMount = () => {
    this.refs = {};
  };

  componentDidMount = () => {
    const { selectedCar, carList } = this.props;
    if (
      carList &&
      carList.length > 0 &&
      this.refs[
        selectedCar.vin
          ? selectedCar.vin
          : selectedCar.vehicleIdentificationNumber
      ]
    ) {
      this.scrollToCar(selectedCar);
    }
  };

  handleOpenNote = (car) => {
    const { openNote } = this.state;
    if (
      car &&
      ((car.vin && car.vin !== openNote) ||
        (car.vehicleIdentificationNumber &&
          car.vehicleIdentificationNumber !== openNote))
    ) {
      this.setState({
        openNote: car.vin ? car.vin : car.vehicleIdentificationNumber,
        openTransport: null,
      });
    } else {
      this.setState({
        openNote: null,
      });
    }
  };

  handleTransportToggle = (car) => {
    const { openTransport } = this.state;
    if (openTransport !== car.vin) {
      this.setState({
        openTransport: car.vin,
        openNote: null,
      });
    } else {
      this.setState({
        openTransport: null,
      });
    }
  };

  setTileRef = (car, ref) => {
    this.refs[car.vin ? car.vin : car.vehicleIdentificationNumber] = ref;
  };

  scrollToCar = (car) => {
    this.refs[
      car.vin ? car.vin : car.vehicleIdentificationNumber
    ].current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  render() {
    const {
      classes,
      carList,
      selectCar,
      selectedCar,
      user,
      onHandleProgramCertCommentsChange,
      onHandleProgramCertNumChange,
      programCertNum,
      programCertComments,
      sourcePage,
      GAMDConditionReports,
      DDOConditionReports,
      toggleImageViewer,
      history,
      dispatch,
      accountSetting,
      handleBidOpen,
      handleBuyNow,
      currentAuction,
      togglePass,
      toggleBuy,
      updateAutoBid,
      updateWatchlist,
      updateGuaranteePayment,
      selectConditionReport,
      toggleFullConditionReport,
      totalCars,
      from,
    } = this.props;
    const { openNote, openTransport } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.gridList} children>
          {sourcePage === "GAMD"
            ? (carList && carList.length > 0) || totalCars > 0
              ? carList.map((car) => (
                  <CarListTile
                    from={from}
                    key={car.vehicleIdentificationNumber}
                    car={car}
                    selectCar={selectCar}
                    user={user}
                    onHandleProgramCertCommentsChange={
                      onHandleProgramCertCommentsChange
                    }
                    onHandleProgramCertNumChange={onHandleProgramCertNumChange}
                    programCertNum={programCertNum}
                    programCertComments={programCertComments}
                    selectedCar={selectedCar}
                    conditionReport={
                      GAMDConditionReports[car.vehicleIdentificationNumber]
                    }
                    history={history}
                    dispatch={dispatch}
                    accountSetting={accountSetting}
                    togglePass={togglePass}
                    toggleBuy={toggleBuy}
                    handleBidOpen={handleBidOpen}
                    handleOpenNote={this.handleOpenNote}
                    openNote={openNote}
                    updateGuaranteePayment={updateGuaranteePayment}
                    selectConditionReport={selectConditionReport}
                    setTileRef={this.setTileRef}
                  />
                ))
              : null
            : (carList && carList.length > 0) || totalCars > 0
            ? carList.map((car) => (
                <DDOCarListTile
                  key={car.vin}
                  car={car}
                  selectCar={selectCar}
                  user={user}
                  onHandleProgramCertCommentsChange={
                    onHandleProgramCertCommentsChange
                  }
                  onHandleProgramCertNumChange={onHandleProgramCertNumChange}
                  programCertNum={programCertNum}
                  programCertComments={programCertComments}
                  selectedCar={selectedCar}
                  conditionReport={DDOConditionReports[car.vin]}
                  toggleImageViewer={toggleImageViewer}
                  toggleBuy={toggleBuy}
                  history={history}
                  handleBidOpen={handleBidOpen}
                  handleBuyNow={handleBuyNow}
                  currentAuction={currentAuction}
                  updateAutoBid={updateAutoBid}
                  updateWatchlist={updateWatchlist}
                  handleOpenNote={this.handleOpenNote}
                  openNote={openNote}
                  handleTransportToggle={this.handleTransportToggle}
                  openTransport={openTransport}
                  toggleFullConditionReport={toggleFullConditionReport}
                  setTileRef={this.setTileRef}
                  sourcePage={sourcePage}
                />
              ))
            : null}
        </div>
      </div>
    );
  }
}

CarDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CarDisplay);
