import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Radio } from "@material-ui/core";

const styles = (theme) => ({
  buttonWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    margin: "auto",
    width: "100%",
    justifyContent: "center",
    zIndex: 2,
    position: "absolute",
    bottom: 0,
  },
  checked: {
    height: "10px",
    width: "10px",
    backgroundColor: theme.palette.primary.black,
    borderRadius: "50%",
  },
  unChecked: {
    height: "9px",
    width: "9px",
    border: `1px solid ${theme.palette.primary.black}`,
    borderRadius: "50%",
  },
  radio: {
    display: "flex",
    margin: 0,
    padding: "2px",
  },
});

export class RadioButtons extends React.Component {
  render() {
    const { classes, content, count, handleClick } = this.props;
    return (
      <div className={classes.buttonWrapper}>
        {content.map((item, index) => {
          return (
            <Radio
              id={`radio${index}`}
              checked={count === index}
              onChange={(evt) => handleClick(index)}
              value={index}
              color="default"
              name="radio-button-demo"
              className={classes.radio}
              aria-label="D"
              key={index}
              icon={<div className={classes.unChecked} />}
              checkedIcon={<div className={classes.checked} />}
            />
          );
        })}
      </div>
    );
  }
}

RadioButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadioButtons);
