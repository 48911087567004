import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Button, Typography } from "@material-ui/core";

import ManageUsers from "../components/ManageUsers";
import ManageDealerLocation from "../components/ManageDealerLocation";
import ManageVehicleLocation from "../components/ManageVehicleLocation";
import ManageExclusivityTime from "../components/ManageExclusivityTime";
import ManagePayments from "../components/ManagePayments";
import ManageArbitration from "../components/ManageArbitration";
import ConditionReport from "../components/ConditionReport";

import * as UserRoles from "../services/UserRoles";
import { getSingleConditionReport } from "../services/ConditionReportService";
import * as AdminService from "../services/AdminServices";

import ReactSVG from "react-svg";
import adminIcon from "../assets/adminIcon.svg";

const TAB_MENU = [
  "Manage Users",
  "Dealer Locations",
  "Vehicle Locations",
  "Exclusivity Time",
  // hiding Payments options -- "Payments",
  "Arbitrations",
];

const styles = (theme) => ({
  color: theme.palette.secondary.main,
  header: {
    display: "flex",
    width: "97%",
    marginTop: ".5rem",
    height: "2.5rem%",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    justifyContent: "space-between",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  titleWrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  buttonWrapper: {
    marginLeft: "5%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  noButtonUnderline: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "5px solid transparent",
    marginRight: "1rem",
  },
  buttonUnderline: {
    borderBottom: `5px solid ${theme.palette.primary.gray}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: "1rem",
  },
  highlightedButtonText: {
    color: theme.palette.primary.gray,
    fontSize: ".8rem",
    fontWeight: "bold",
  },
  buttonText: {
    fontSize: ".8rem",
  },
  AdminIcon: {
    width: "1rem",
    height: "1rem",
    margin: "1rem",
    border: `.5rem solid ${theme.palette.primary.gray}`,
    borderRadius: "1.5rem",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.gray,
    fill: theme.palette.primary.white,
  },
  titleTextWrapper: {
    borderBottom: `5px solid ${theme.palette.primary.gray}`,
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  titleText: {
    color: theme.palette.primary.gray,
    fontSize: ".8rem",
    fontWeight: "bold",
  },
  navButton: {
    height: "1.5rem",
    lineHeight: 0,
    padding: 0,
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    width: "max-content",
  },
  manageUsers: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
  },
});

export class AdministratorNavMenu extends Component {
  state = {
    adminFilter: "",
    currentUserData: [],
    dealerLocationData: [],
    vehicleLocationData: [],
    arbitrationData: [],
    paymentData: [],
    conditionReport: null,
  };
  componentWillMount = () => {
    const { user, history } = this.props;
    if (
      !UserRoles.canViewOnly(user.userRoles) &&
      !UserRoles.canManageArbitrations(user.userRoles) &&
      !UserRoles.canManagePayments(user.userRoles) &&
      !UserRoles.isDealerAdmin(user.userRoles)
    ) {
      history.replace("/home");
    } else {
      this.setState({
        adminFilter: this.setInitialPage(),
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { accountSetting } = this.props;
    const { adminFilter } = this.state;
    if (accountSetting !== prevProps.accountSetting) {
      this.setDisplayedNav(adminFilter);
    }
  };

  componentDidMount = () => {
    const { adminFilter } = this.state;
    this.setDisplayedNav(adminFilter);
  };

  setInitialPage = () => {
    const { user } = this.props;
    if (UserRoles.isDealerAdmin(user.userRoles)) {
      return "Manage Users";
    } else if (UserRoles.canManagePayments(user.userRoles)) {
      return "Payments";
    } else {
      return "Arbitrations";
    }
  };

  toggleConditionReport = async (inventoryId) => {
    const { userId, dealerId } = this.props.user;
    const { conditionReport } = this.state;
    if (!conditionReport) {
      const conditionReport = await getSingleConditionReport(
        inventoryId,
        userId,
        dealerId
      );
      this.setState({
        conditionReport,
      });
    } else {
      this.setState({
        conditionReport: null,
      });
    }
  };

  getCurrentUserData = async () => {
    const { user, accountSetting } = this.props;
    const response = await AdminService.getCurrentUsers(
      user.dealerId,
      accountSetting.custLocations
    );
    this.setState({ currentUserData: response });
  };

  getVehicleLocationData = async (vin) => {
    const { user } = this.props;
    const response = await AdminService.getVehicleLocation(vin, user.dealerId);
    this.setState({ vehicleLocationData: response });
  };

  getPaymentData = async () => {
    const { user } = this.props;
    const response = await AdminService.getPaymentSetup(
      user.userId,
      user.dealerId
    );
    this.setState({ paymentData: [response] });
  };

  getArbitrationData = async (
    vin,
    arbitrationStatus,
    arbitrationFromDate,
    arbitrationToDate,
    purchaseFromDate,
    purchaseToDate
  ) => {
    const { user } = this.props;
    this.setState({ searchingArbitrations: true });
    const response = await AdminService.getArbitration(
      user.dealerId,
      vin,
      arbitrationStatus,
      arbitrationFromDate,
      arbitrationToDate,
      purchaseFromDate,
      purchaseToDate
    );
    this.setState({ arbitrationData: response, searchingArbitrations: false });
  };

  displayTab = (nav) => {
    const { adminFilter } = this.state;
    const { classes } = this.props;
    return (
      <div
        key={nav}
        className={
          adminFilter === nav
            ? classes.buttonUnderline
            : classes.noButtonUnderline
        }
      >
        <Button
          id={nav.replace(/\s/g, "_")}
          className={classes.navButton}
          variant="outlined"
          onClick={(evt) => this.setDisplayedNav(nav)}
        >
          <Typography
            className={
              adminFilter === nav
                ? classes.highlightedButtonText
                : classes.buttonText
            }
          >
            {nav}
          </Typography>
        </Button>
      </div>
    );
  };

  setDisplayedNav = (nav) => {
    switch (nav) {
      case "Manage Users":
        this.getCurrentUserData();
        break;
      case "Vehicle Locations":
        this.getVehicleLocationData();
        break;
      case "Payments":
        this.getPaymentData();
        break;
      case "Arbitrations":
        this.getArbitrationData(null, "OPEN");
        break;
      default:
        break;
    }
    this.setState({ adminFilter: nav });
  };

  checkRolesToDisplay = (nav) => {
    const { user } = this.props;
    switch (nav) {
      case "Payments":
        if (UserRoles.canManagePayments(user.userRoles)) {
          return this.displayTab(nav);
        }
        break;
      case "Arbitrations":
        if (
          UserRoles.canManageArbitrations(user.userRoles) ||
          UserRoles.canViewOnly(user.userRoles)
        ) {
          return this.displayTab(nav);
        }
        break;
      case "Exclusivity Time":
        if (
          user.allowDlrExculsivityExtension &&
          UserRoles.isDealerAdmin(user.userRoles)
        ) {
          return this.displayTab(nav);
        }
        break;
      default:
        if (UserRoles.isDealerAdmin(user.userRoles)) {
          return this.displayTab(nav);
        }
        break;
    }
  };

  render() {
    const { classes, user, dispatch } = this.props;
    const {
      adminFilter,
      currentUserData,
      dealerLocationData,
      vehicleLocationData,
      paymentData,
      arbitrationData,
      conditionReport,
      searchingArbitrations,
    } = this.state;
    return (
      <React.Fragment>
        {!conditionReport ? (
          <>
            <div className={classes.header}>
              <div className={classes.headerContainer}>
                <div className={classes.titleWrapper}>
                  <ReactSVG
                    className={classes.AdminIcon}
                    src={adminIcon}
                    alt="Admin Icon"
                    svgStyle={{ width: "1rem", height: "1rem" }}
                  />
                  <div className={classes.titleTextWrapper}>
                    <Typography className={classes.titleText}>
                      Administrator
                    </Typography>
                  </div>
                </div>
                <div className={classes.buttonWrapper}>
                  {TAB_MENU.map((item) => this.checkRolesToDisplay(item))}
                </div>
              </div>
            </div>
            <div className={classes.manageUsers}>
              {adminFilter === "Manage Users" && (
                <ManageUsers
                  data={currentUserData}
                  getCurrentUserData={this.getCurrentUserData}
                />
              )}
              {adminFilter === "Dealer Locations" && (
                <ManageDealerLocation data={dealerLocationData} />
              )}
              {adminFilter === "Vehicle Locations" && (
                <ManageVehicleLocation
                  data={vehicleLocationData}
                  getVehicleLocationData={this.getVehicleLocationData}
                />
              )}
              {adminFilter === "Exclusivity Time" && (
                <ManageExclusivityTime dispatch={dispatch} />
              )}
              {adminFilter === "Payments" && (
                <ManagePayments data={paymentData} />
              )}
              {adminFilter === "Arbitrations" && (
                <ManageArbitration
                  data={arbitrationData}
                  dispatch={dispatch}
                  toggleConditionReport={this.toggleConditionReport}
                  getArbitrationData={this.getArbitrationData}
                  searchingArbitrations={searchingArbitrations}
                />
              )}
            </div>
          </>
        ) : (
          conditionReport.inspectionImageDTO.length > 0 && (
            <ConditionReport
              user={user}
              toggleFullConditionReport={this.toggleConditionReport}
              conditionReport={conditionReport}
              fromReports
            />
          )
        )}
      </React.Fragment>
    );
  }
}

AdministratorNavMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles)(AdministratorNavMenu)
);
