import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Viewer from "react-viewer";

import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Snackbar,
} from "@material-ui/core";

import * as VehiclePurchaseServices from "../services/VehiclePurchaseServices";
import { getNotes } from "../services/NotesServices";
import { getDate, returnAccountType } from "../services/HelperServices";
import * as WatchListServices from "../services/WatchListServices";
import * as userAccess from "../services/UserRoles";

import LPWEntryForm from "../components/LPWEntryForm";
import GuaranteePayments from "../components/GuaranteePayments";
import Notes from "../components/Notes";
import CarReport from "../components/CarReport";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import notesIcon from "../assets/notesIcon.svg";

import ReactSVG from "react-svg";
import watchlistIcon from "../assets/watchlistIcon.svg";
import activeWatchlistIcon from "../assets/activeWatchlistIcon.svg";
import clock from "../assets/clock.svg";

import { watchlist_count } from "../actions/carActions";

import {
  select_car_for_purchase,
  select_pass_car,
} from "../actions/carActions";

const styles = (theme) => ({
  card: {
    display: "flex",
    marginTop: ".5rem",
    borderRadius: 0,
    height: "5rem",
    width: "100%",
    position: "relative",
  },
  cardWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "left",
    flexDirection: "column",
    width: "30%",
    padding: 0,
  },
  cardTitle: {
    fontSize: "64%",
    position: "absolute",
    fontWeight: "bold",
    marginLeft: "2%",
  },
  media: {
    minWidth: "8rem",
    width: "100%",
    height: "7rem",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      minWidth: 0,
      height: "5rem",
      width: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
    },
  },
  clickableMedia: {
    minWidth: "8rem",
    width: "100%",
    height: "7rem",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {
      margin: 0,
      minWidth: 0,
      height: "5rem",
      width: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: ".5rem",
  },
  contentWrapper: {
    display: "flex",
    position: "relative",
    cursor: "pointer",
    width: "80%",
  },
  textWrapper: {
    display: "flex",
    height: "85%",
    justifyContent: "space-around",
    position: "relative",
    top: "1rem",
  },
  textColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "1%",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
  },
  rightTextColumn: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "1%",
    paddingBottom: "1.5rem !important",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
  },
  textContainer: {
    width: "100%",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignContent: "space-between",
    height: "100%",
    justifyContent: "center",
  },
  smallButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute",
    bottom: 0,
    right: 0,
    height: "1.25rem",
    zIndex: 2,
  },
  smallButton: {
    height: "2.5rem",
    minWidth: 0,
    zIndex: 2,
  },
  smallButtonLabel: {
    marginLeft: "2%",
    marginRight: "2%",
    justifyContent: "initial",
    fontSize: ".6rem",
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    minWidth: "13%",
    [theme.breakpoints.down("md")]: {
      minWidth: "20%",
    },
  },
  buyButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100%",
    margin: 0,
  },
  passButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.pass,
    width: "100%",
    margin: 0,
  },
  guaranteedPaymentButton: {
    height: "33.333%",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.guaranteedPayment,
    width: "100%",
    margin: 0,
  },
  cardText: {
    fontSize: "76%",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("md")]: {
      fontSize: "48%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  cardTextRed: {
    fontSize: "76%",
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "48%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  boldedText: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
  redBoldedText: {
    fontWeight: "bold",
    color: theme.palette.primary.redHighlight,
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  statusCardText: {
    marginLeft: ".75rem",
    position: "relative",
    bottom: "2%",
    fontSize: "76%",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("md")]: {
      fontSize: "57%",
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {},
  },
  LPWLink: {},
  hasNotes: {
    color: theme.palette.primary.redHighlight,
    fill: "currentColor",
  },
  noteIcon: {
    fill: theme.palette.grounding.main,
  },
  carInfoIconWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "17%",
    minWidth: "4rem",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carInfoIcon: {
    width: "4rem",
    "&:hover": {
      opacity: ".5",
    },
  },
  errorSnackBar: {
    marginTop: "7rem",
  },
  exclusivityTimer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, .6)",
    height: "1.5rem",
    color: theme.palette.primary.white,
    display: "flex",
    alignItems: "center",
    width: "27%",
    margin: 0,
    justifyContent: "space-around",
  },
  exclusiveText: {
    fontSize: theme.font.small,
    display: "flex",
  },
});

export class MobileCarListTile extends Component {
  state = {
    LPWActiveCar: false,
    guaranteePayment: false,
    openNote: false,
    notes: [],
    showCarfaxReport: false,
    viewerVisible: false,
    buyLoading: false,
    buyError: false,
    errorMessage: "An error has occured, please try again in a few minutes.",
    showMobileActionButtons: false,
    timeRemaining: null,
    hours: 0,
    minutes: 0,
    showCarfaxSnackBar: false,
  };

  componentWillMount = () => {
    this.setInitialData();
  };

  setInitialData = async () => {
    const { car, user } = this.props;
    let notes = null;
    let hours = 0;
    let minutes = 0;
    const exclusivity = this.returnExclusivePeriod();
    if (car.noteAvailable) {
      notes = await getNotes(user.dealerId, car.id);
    }
    if (exclusivity) {
      const time = this.timeRemaining(exclusivity);
      hours = time.hours;
      minutes = time.minutes;
      this.timer = setInterval((timeRemaining) => {
        const newTimeRemaining = this.state.timeRemaining - 1;
        const newTime = this.timeRemaining(newTimeRemaining);
        this.setState({
          timeRemaining: newTimeRemaining,
          hours: newTime.hours,
          minutes: newTime.minutes,
        });
      }, 60000);
    }
    if (notes || exclusivity) {
      this.setState({
        notes,
        timeRemaining: exclusivity,
        hours,
        minutes,
      });
    }
  };

  timeRemaining = (time) => {
    const hours = time / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return { hours: rhours, minutes: rminutes };
  };

  toggleLPWCar = () => {
    this.setState({
      LPWActiveCar: !this.state.LPWActiveCar,
    });
  };

  toggleGuaranteePayment = () => {
    this.setState({
      guaranteePayment: !this.state.guaranteePayment,
    });
  };

  handleBuy = async () => {
    const { toggleBuy, dispatch, car } = this.props;
    const {
      inventoryDTO,
      saleEventItemId,
      vehicleIdentificationNumber,
      accountType,
    } = this.props.car;
    const { userId, dealerId, loginId } = this.props.user;
    if (accountType === "BALLOON") {
      dispatch(select_car_for_purchase(car));
      toggleBuy(vehicleIdentificationNumber);
    } else {
      let buyDetails = await VehiclePurchaseServices.buy(
        inventoryDTO.id,
        userId,
        dealerId,
        saleEventItemId,
        loginId
      );
      if (buyDetails.status !== "false") {
        dispatch(select_car_for_purchase(buyDetails));
        toggleBuy(vehicleIdentificationNumber);
      } else {
        this.setState({
          buyLoading: false,
          buyError: true,
        });
        console.log("Error occured while retrieving buy details", buyDetails);
      }
    }
  };

  handleBuyNow = async () => {
    const { toggleBuy, dispatch } = this.props;
    const { saleEventItemId } = this.props.car;
    const { userId, dealerId } = this.props.user;
    const buyDetails = await VehiclePurchaseServices.buyNow(
      userId,
      dealerId,
      saleEventItemId
    );
    dispatch(select_car_for_purchase(buyDetails));
    toggleBuy();
  };

  handlePass = () => {
    const { dispatch, car, togglePass } = this.props;
    dispatch(select_pass_car(car));
    togglePass();
  };

  addNote = (newNote) => {
    const { accountSetting, car, user } = this.props;
    const fullNote = {
      inventoryId: car.id,
      dealerId: user.dealerId,
      note: newNote,
      needFollowUp: false,
      userId: user.userId,
      firstName: accountSetting.firstName,
      lastName: accountSetting.lastName,
      createdDate: getDate(),
    };
    this.setState({
      notes: [...this.state.notes, fullNote],
    });
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  toggleImageViewer = () => {
    this.setState({
      viewerVisible: !this.state.viewerVisible,
    });
  };

  returnExclusivePeriod = () => {
    const { car } = this.props;
    if (
      car.dealerDecisionCode === "DLR_EXCL_EXP" ||
      car.dealerDecisionCode === "NOTINT"
    ) {
      return null;
    } else if (car.exclusivePeriod > 0 && car.isMBPApproved) {
      return car.exclusivePeriod;
    } else {
      return null;
    }
  };

  toggleWatchList = async () => {
    const { user, car, updateWatchlist, from } = this.props;
    let response;
    if (car.isInWatchList || (from && from === "watchlist")) {
      response = await WatchListServices.removeFromWatchList(user, car);
    } else {
      response = await WatchListServices.addToWatchList(user, car);
    }
    this.loadWatchlistData();
    if (response) {
      updateWatchlist(car.vin);
    }
  };

  loadWatchlistData = async () => {
    const { user, dispatch } = this.props;
    const watchListCars = await WatchListServices.getSavedWatchlist(user);
    if (watchListCars && watchListCars.length !== undefined) {
      dispatch(watchlist_count(watchListCars.length));
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  render() {
    const {
      classes,
      car,
      selectCar,
      user,
      onHandleProgramCertCommentsChange,
      onHandleProgramCertNumChange,
      programCertComments,
      programCertNum,
      selectedCar,
      conditionReport,
      from,
      openNote,
      handleOpenNote,
      updateGuaranteePayment,
    } = this.props;
    const {
      LPWActiveCar,
      notes,
      guaranteePayment,
      showCarfaxReport,
      viewerVisible,
      buyError,
      errorMessage,
      hours,
      minutes,
      timeRemaining,
      showCarfaxSnackBar,
    } = this.state;
    let remainingPaymentAmount = car.remainingPaymentAmount
      ? car.remainingPaymentAmount
      : car.remainingPaymentsAmt;
    if (!remainingPaymentAmount && remainingPaymentAmount !== 0) {
      remainingPaymentAmount = car.inventoryDTO
        ? car.inventoryDTO.leaseInformationDTO.remainingPaymentAmount
        : null;
    }
    const { isInWatchList, displayGuaranteePaymentsOnly } = this.props.car;
    return (
      <React.Fragment>
        {conditionReport && conditionReport.inspectionImageDTO ? (
          <Viewer
            visible={viewerVisible}
            zoomSpeed={0.3}
            noClose={true}
            onMaskClick={(evt) => this.setState({ viewerVisible: false })}
            onClose={() => this.setState({ viewerVisible: false })}
            images={conditionReport.inspectionImageDTO.map((image) => {
              return {
                src: image.imageUrl ? image.imageUrl : image.imagePathFc,
                alt: image.damageImageCaption,
              };
            })}
          />
        ) : null}
        <Card
          className={classes.card}
          id={`MobileCarListTile${car.vehicleIdentificationNumber}`}
          onClick={() => selectCar(car, user, "GAMD")}
        >
          <CardActions className={classes.cardWrapper}>
            <div className={classes.imageWrapper}>
              <img
                id="primaryImage"
                src={car.imageUrl ? car.imageUrl : car.imagePathFc}
                className={
                  conditionReport ? classes.clickableMedia : classes.media
                }
                alt={`${car.make} ${car.model}`}
              />
            </div>
            {userAccess.canPurchaseVehicles(user.userRoles) ? (
              <div className={classes.smallButtonWrapper}>
                {(from && from === "watchlist") ||
                (from && from === "advanceSearch") ||
                isInWatchList ? (
                  <Button
                    id="watchListBtn"
                    className={classes.smallButton}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      this.toggleWatchList();
                    }}
                  >
                    <ReactSVG
                      src={
                        isInWatchList || from === "watchlist"
                          ? activeWatchlistIcon
                          : watchlistIcon
                      }
                    />
                  </Button>
                ) : null}
                <Button
                  id="noteButton"
                  className={classes.smallButton}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    car.vehicleIdentificationNumber
                      ? openNote !== car.vehicleIdentificationNumber
                        ? handleOpenNote(car)
                        : handleOpenNote()
                      : openNote !== car.vin
                      ? handleOpenNote(car)
                      : handleOpenNote();
                  }}
                >
                  <ReactSVG
                    src={notesIcon}
                    className={
                      notes && notes.length > 0
                        ? classes.hasNotes
                        : classes.noteIcon
                    }
                  />
                </Button>
              </div>
            ) : null}
            {timeRemaining ? (
              <div className={classes.exclusivityTimer}>
                <ReactSVG
                  src={clock}
                  style={{ fill: "currentColor", height: "1.5rem" }}
                  svgStyle={{ height: "1.5rem" }}
                />
                <Typography
                  className={classes.exclusiveText}
                >{`${hours}h ${minutes}m`}</Typography>
              </div>
            ) : null}
          </CardActions>
          <div className={classes.contentWrapper}>
            <div className={classes.textContainer}>
              <Typography className={classes.cardTitle}>
                {`${car.year} ${car.make} ${car.model} `}
                {car.color ? car.color : null}
              </Typography>
              <div className={classes.textWrapper}>
                <CardContent className={classes.textColumn}>
                  <Typography className={classes.cardText}>
                    VIN:{" "}
                    <span className={classes.boldedText}>
                      {car.vehicleIdentificationNumber
                        ? car.vehicleIdentificationNumber
                        : car.vin}
                    </span>
                  </Typography>
                  {car.inventoryDTO &&
                  car.inventoryDTO.inspectionInfoCollectionDTO[0] ? (
                    <Typography className={classes.cardText}>
                      Inspection Mileage:{" "}
                      <span className={classes.boldedText}>
                        {car.inventoryDTO.inspectionInfoCollectionDTO[0].inspectionMileage.toLocaleString()}
                      </span>
                    </Typography>
                  ) : (
                    <Typography className={classes.cardText}>
                      Odometer:{" "}
                      <span className={classes.boldedText}>
                        {car.groundingMileage
                          ? car.groundingMileage.toLocaleString()
                          : car.odometer
                          ? car.odometer
                          : null}
                      </span>
                    </Typography>
                  )}
                  <Typography className={classes.cardTextRed}>
                    Rem. Pymt.:{" "}
                    {remainingPaymentAmount &&
                    remainingPaymentAmount !== null &&
                    ((!car.remainingPayGuaranteed && car.isInWatchList) ||
                      displayGuaranteePaymentsOnly ||
                      (car.inventoryDTO &&
                        car.inventoryDTO.leaseInformationDTO.waivedPayments))
                      ? remainingPaymentAmount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : null}
                  </Typography>
                  <Typography className={classes.cardText}>
                    Account Type:{" "}
                    <span
                      className={
                        returnAccountType(car.accountType) === "Company Car"
                          ? classes.redBoldedText
                          : classes.boldedText
                      }
                    >
                      {returnAccountType(car.accountType)}
                    </span>
                  </Typography>
                </CardContent>
                {car.accountType === "BALLOON" ? null : (
                  <CardContent className={classes.rightTextColumn}>
                    <Typography className={classes.cardText}>
                      Residual:{" "}
                      <span className={classes.boldedText}>
                        {car.residualAmount && car.residualAmount !== null
                          ? car.residualAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : car.residualPayAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                      </span>
                    </Typography>
                    <Typography className={classes.cardText}>
                      Payoff:{" "}
                      <span className={classes.boldedText}>
                        {car.grossPayoff && car.grossPayoff !== null
                          ? car.grossPayoff.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : car.grossPayAmount
                          ? car.grossPayAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : null}
                      </span>
                    </Typography>
                    <Typography className={classes.cardText}>
                      Market:{" "}
                      <span className={classes.boldedText}>
                        {car.isMBPApproved
                          ? car.marketBasedPrice.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "Pending"}
                      </span>
                    </Typography>

                    {car.programDTO &&
                    car.programDTO[0] &&
                    car.programDTO[0].programCode === "LPW" ? (
                      <Typography
                        id="LPWLink"
                        onClick={(evt) => this.toggleLPWCar()}
                        className={classes.cardText}
                        gutterBottom
                        variant="body1"
                      >
                        Promo:{" "}
                        <span className={classes.boldedText}>
                          {car.programDTO[0].programCode}
                        </span>
                      </Typography>
                    ) : null}
                  </CardContent>
                )}
              </div>
            </div>
          </div>
        </Card>
        {car.programDTO && car.programDTO[0] && LPWActiveCar ? (
          <LPWEntryForm
            onHandleProgramCertCommentsChange={
              onHandleProgramCertCommentsChange
            }
            onHandleProgramCertNumChange={onHandleProgramCertNumChange}
            programDTOInfo={car.programDTO[0]}
            programCertNum={programCertNum}
            programCertComments={programCertComments}
          />
        ) : null}
        {guaranteePayment ? (
          <GuaranteePayments
            car={car}
            toggleGuaranteePayment={this.toggleGuaranteePayment}
            user={user}
            isBuyFlow
            updateGuaranteePayment={updateGuaranteePayment}
          />
        ) : null}
        {openNote === car.vehicleIdentificationNumber ||
        openNote === car.vin ? (
          <Notes
            toggleNote={handleOpenNote}
            notes={notes}
            user={user}
            addNote={this.addNote}
            car={car}
            selected={
              car.vehicleIdentificationNumber ===
                selectedCar.vehicleIdentificationNumber ||
              car.vin === selectedCar.vin
            }
          />
        ) : null}
        {showCarfaxReport ? (
          <CarReport
            vin={car.vehicleIdentificationNumber}
            reportType="carfax"
            showCarfaxReport={this.showCarfaxReport}
            showNoDataVin={this.showNoDataVin}
          />
        ) : null}
        {showCarfaxSnackBar ? (
          <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
        ) : null}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={buyError}
          autoHideDuration={3000}
          onClose={() => this.setState({ buyError: false })}
          className={classes.errorSnackBar}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{errorMessage}</span>}
        />
      </React.Fragment>
    );
  }
}

MobileCarListTile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MobileCarListTile);
