import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  FormLabel,
  Input,
  Paper,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";

import Loading from "../components/Loading";
import Message from "../components/Message";
import * as GroundingServices from "../services/GroundingServices";
import * as userAccess from "../services/UserRoles";

import { set_nav_location } from "../actions/uiActions";

import {
  GROUNDING_MESSAGES,
  GROUND,
  VIN,
  PRE_TERM_PRICE_QUOTE,
  GROUND_BUY,
  GROUND_BTN,
  EARLY_TERM_GROUNDING_MESSAGE_DETAILS,
} from "../constants/UILabel";

const styles = (theme) => ({
  root: {
    display: "flex",
    color: theme.palette.primary.gray,
  },
  button: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100px",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  homeButton: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "9rem",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "4rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
  },
  groundBuyButtonHome: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "12rem",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
  },

  groundBuyButton: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
  },

  preTermPriceButton: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.DDO.main,
    width: "21rem",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
  },
  homePaper: {
    display: "flex",
    alignItems: "center",
    width: "39.5rem",
    borderRadius: 0,
    backgroundColor: "transparent",
    [theme.breakpoints.down("md")]: {
      width: "27rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      boxShadow: "none",
    },
  },
  groundPaper: {
    display: "flex",
    alignItems: "center",
    width: "34rem",
    borderRadius: 0,
    backgroundColor: "transparent",
    [theme.breakpoints.down("md")]: {
      width: "27rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      boxShadow: "none",
    },
  },
  secondStepPaper: {
    display: "flex",
    alignItems: "center",
    width: "34rem",
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      width: "27rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  paperContainer: {
    display: "flex",
    margin: "2rem",
    width: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      margin: ".1rem",
    },
  },
  homePaperContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      margin: ".1rem",
    },
  },
  label: {
    margin: "1rem",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      marginTop: "1.5rem",
      fontSize: theme.font.medium,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  input: {
    margin: "1rem",
    width: "16rem",
    fontSize: "1.3rem",
    [theme.breakpoints.down("sm")]: {
      width: "12rem",
      margin: ".5rem",
      border: `0.06rem solid ${theme.palette.grounding.main}`,
      borderRadius: "8px",
      padding: ".8rem",
      fontSize: theme.font.small,
    },
  },
  homeInput: {
    margin: "1rem",
    width: "15rem",
    [theme.breakpoints.down("sm")]: {
      width: "14rem",
      margin: ".5rem",
    },
  },
  homeE2EInput: {
    margin: "1rem",
    width: "24rem",
    [theme.breakpoints.down("sm")]: {
      width: "14rem",
      margin: ".5rem",
    },
  },
  margin: {
    margin: ".5rem",
  },
  container: {
    width: "auto",
    display: "block",
    margin: "2.5rem",
    textAlign: "center",
  },
  VINEntryContainer: {
    margin: ".5rem",
    display: "inline-block",
    width: "29.7rem",
    marginTop: "4rem",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      margin: ".5rem",
    },
  },
  clearButton: {
    cursor: "pointer",
    fontSize: "1rem",
    "&:hover": {
      color: theme.palette.grounding.main,
      fontWeight: "bold",
    },
  },
  underline: {
    "&:before": {
      borderBottom: `2px solid ${theme.palette.grounding.main}`,
    },
    [theme.breakpoints.down("sm")]: {
      "&:before": {
        borderBottom: `0px solid ${theme.palette.grounding.main}`,
      },
      "&:after": {
        borderBottom: `0px solid ${theme.palette.grounding.main}`,
      },
    },
  },
  homeUnderline: {
    "&:before": {
      borderBottom: `2px solid ${theme.palette.grounding.main}`,
    },
  },
  earlyTermGroundingMessage: {
    maxWidth: "none",
    width: "648px",
    [theme.breakpoints.down("md")]: {
      width: "508px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "92%",
    },
  },
});

export class VINEntry extends Component {
  state = {
    loading: false,
    isEarlyTermLease: false,
  };

  componentDidMount = async () => {
    const { vin, handleChange, vinEntered } = this.props;
    if (vin !== "" && !vinEntered) {
      await this.getVINDetails();
      handleChange("vinEntered")({ target: { value: true } });
    }
  };

  getVINDetails = async () => {
    const { loginId } = this.props.user;
    const {
      vin,
      onVinSearch,
      navLocation,
      dispatch,
      home,
      customerReturnDate,
    } = this.props;
    let errorMessage = "";
    let messageVariant = "";
    let vinDetails = "";
    if (navLocation !== GROUND) {
      dispatch(set_nav_location(GROUND));
    }
    if (loginId && !home) {
      if (vin !== "") {
        this.setState({ loading: true });
        const vehicleDetails = await GroundingServices.vinDetails(
          vin,
          loginId,
          customerReturnDate
        );
        if (vehicleDetails === null) {
          errorMessage = GROUNDING_MESSAGES.ERROR_SOMETHING_WENT_WRONG;
          messageVariant = "error";
          this.setState({ loading: false });
        } else if (vehicleDetails.status === "false") {
          errorMessage = vehicleDetails.message;
          messageVariant = "error";
          this.setState({ loading: false });
        } else {
          errorMessage = GROUNDING_MESSAGES.SUCCESS_START_GROUNDING_PROCESS;
          messageVariant = "success";
          vinDetails = vehicleDetails;
          this.setState({
            loading: false,
            isEarlyTermLease: vinDetails.inventoryDTO.earlyTermNotification,
          });
        }
      } else {
        errorMessage = GROUNDING_MESSAGES.ERROR_VINS_IS_INVALID;
        messageVariant = "error";
      }
    } else {
      errorMessage = GROUNDING_MESSAGES.ERROR_NO_USER_AVAILABLE;
      messageVariant = "error";
    }
    onVinSearch(vinDetails, errorMessage, messageVariant);
  };

  renderCancelButton = () => {
    const { vin, onVinChange, classes } = this.props;
    return vin !== "" ? (
      <InputAdornment
        id="clearVinButton"
        disableTypography
        className={classes.clearButton}
        position="end"
        onClick={() => onVinChange("vin")({ target: { value: "" } })}
      >
        X
      </InputAdornment>
    ) : null;
  };

  render() {
    const {
      classes,
      vin,
      onVinChange,
      messageVariant,
      errorMessage,
      home,
      onVinSearch,
      user,
      secondStep,
      showGroundingSuccessMessages,
      loadPriceQuoteScreen,
      badCustReturnDate,
      custReturnDateOutOfRange,
    } = this.props;
    const { TDDMAIN_4047_TDD_E2E_DEALER_ENGAGEMENT } =
      this.props.togglzMap || {};
    const { loading } = this.state;
    return (
      <div className={classes.root}>
        <div
          style={
            home
              ? { width: "100%", display: "flex", justifyContent: "center" }
              : {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "29.7rem",
                }
          }
        >
          {showGroundingSuccessMessages ? (
            <React.Fragment>
              {errorMessage && messageVariant && (
                <Message
                  onClose={this.handleClose}
                  variant={messageVariant}
                  message={errorMessage}
                />
              )}
            </React.Fragment>
          ) : null}
          <Paper
            className={
              secondStep
                ? classes.secondStepPaper
                : home
                ? classes.homePaper
                : classes.groundPaper
            }
            elevation={home ? 0 : 6}
          >
            <div
              className={
                home ? classes.homePaperContainer : classes.paperContainer
              }
              style={home ? { width: "100%" } : null}
            >
              <FormLabel
                className={classes.label}
                style={home ? { fontSize: "2rem" } : { fontSize: "1.5rem" }}
              >
                {VIN}
              </FormLabel>
              <Input
                id="vin"
                placeholder="Enter VIN here..."
                className={
                  TDDMAIN_4047_TDD_E2E_DEALER_ENGAGEMENT & home
                    ? classes.homeE2EInput
                    : home
                    ? classes.homeInput
                    : classes.input
                }
                classes={
                  home
                    ? { underline: classes.homeUnderline }
                    : { underline: classes.underline }
                }
                value={vin}
                onChange={onVinChange("vin")}
                inputProps={{
                  maxLength: 17,
                }}
                disabled={!userAccess.canGroundVehicles(user.userRoles) || user.dealerSuspendedGrounding}
                endAdornment={this.renderCancelButton()}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    if (home !== undefined) {
                      onVinSearch();
                    } else {
                      this.getVINDetails();
                    }
                  }
                }}
              />
              {TDDMAIN_4047_TDD_E2E_DEALER_ENGAGEMENT ? (
                <Button
                  id="searchVinBtn"
                  className={
                    home ? classes.groundBuyButtonHome : classes.groundBuyButton
                  }
                  onClick={(evt) => this.getVINDetails()}
                  disabled={!userAccess.canGroundVehicles(user.userRoles) || user.dealerSuspendedGrounding}
                >
                  {!loading ? GROUND_BUY : <Loading color={"#FFFFFF"} />}
                </Button>
              ) : (
                <Button
                  id="searchVinBtn"
                  className={home ? classes.homeButton : classes.button}
                  onClick={(evt) => this.getVINDetails()}
                  disabled={!userAccess.canGroundVehicles(user.userRoles) || user.dealerSuspendedGrounding}
                >
                  {!loading ? GROUND_BTN : <Loading color={"#FFFFFF"} />}
                </Button>
              )}
              {TDDMAIN_4047_TDD_E2E_DEALER_ENGAGEMENT && home ? (
                <Button
                  id="preTermPriceBtn"
                  className={classes.preTermPriceButton}
                  onClick={(evt) => loadPriceQuoteScreen()}
                  disabled={!userAccess.canGroundVehicles(user.userRoles) || user.dealerSuspendedGrounding}
                >
                  {!loading ? (
                    PRE_TERM_PRICE_QUOTE
                  ) : (
                    <Loading color={"#FFFFFF"} />
                  )}
                </Button>
              ) : null}
            </div>
          </Paper>
          {TDDMAIN_4047_TDD_E2E_DEALER_ENGAGEMENT &&
            this.state.isEarlyTermLease &&
            showGroundingSuccessMessages && (
              <Message
                message={EARLY_TERM_GROUNDING_MESSAGE_DETAILS}
                onClose={this.handleClose}
                variant="warn"
                hideIcon={true}
                className={classes.earlyTermGroundingMessage}
              />
            )}
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={badCustReturnDate}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              {"Customer Return Date is Invalid or Required"}
            </span>
          }
          className={classes.errorSnack}
          autoHideDuration={3000}
        />
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    navLocation: state.ui.navLocation,
    togglzMap: state.ui.togglzMap,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(VINEntry));
