import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Input,
  Checkbox,
  Typography,
  TextField,
} from "@material-ui/core";

import * as UtilityServices from "../services/UtilityServices";

const styles = (theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    maxWidth: "100%",
    margin: ".5rem",
    fontSize: theme.font.small,
  },
  paymentSelectionWrapper: {
    marginTop: ".5rem",
    display: "flex",
    flexDirection: "column",
    width: "22rem",
  },
  paymentSelectionTitle: {
    marginBottom: ".5rem",
  },
  priceWrapper: {
    display: "flex",
  },
  checked: {},
  checkboxRootDDO: {
    padding: 0,
    height: "1rem",
    paddingLeft: ".5rem",
    "&$checked": {
      color: theme.palette.DDO.main,
    },
  },
  paymentLineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".75rem",
  },
  DDOBoldedColor: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.DDO.main,
  },
  transportWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  selectRoot: {
    fontSize: ".7rem",
    lineHeight: ".7rem",
  },
  specialInstruction: {
    marginTop: ".25rem",
  },
  locationLabel: {
    width: "10rem",
    fontSize: theme.font.small,
  },
});

export class TransportMethod extends Component {
  state = {
    specialInstruction: "",
  };

  componentDidMount = () => {
    const { location } = this.props;
    if (location) {
      this.getTransportQuote();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { location } = this.props;
    if (prevProps.location !== location) {
      this.getTransportQuote();
    }
  };

  getZip = (id) => {
    const { locationDTO } = this.props;
    return locationDTO.locationDTO.addressDTO.zip;
  };

  getTransportQuote = async () => {
    const { car, onHandleTransportDTO, locationDTO } = this.props;

    try {
      const response = await UtilityServices.transportquote(
        car.id,
        car.loggedInUserId,
        car.saleEventItemId,
        this.getZip(locationDTO),
        locationDTO.customerId
      );
      onHandleTransportDTO(
        response.status !== "false" ? response[0] : response
      );
    } catch (err) {
      console.log(err);
    }
  };

  verifyQuotePrice = (quote) => {
    const { car } = this.props;
    if (car.promotions === "TAP") {
      return `$${quote.quoteWithPromo}`;
    } else {
      return quote.quote.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  };

  render() {
    const {
      car,
      classes,
      custLocations,
      transportDTO,
      onHandleSelectedLocation,
      transportMethodSelected,
      onHandleSpecialInstruction,
      onHandleTransportMethod,
      locationDTO,
      transportError,
    } = this.props;
    return (
      <div className={classes.transportWrapper}>
        <div className={classes.paymentSelectionWrapper}>
          <Typography
            className={classes.paymentSelectionTitle}
            style={transportError ? { color: "red" } : null}
          >
            Transportation Method
          </Typography>
          {car.transportationMethods.map((option) => {
            return (
              <div key={option} className={classes.paymentLineWrapper}>
                <div className={classes.priceWrapper}>
                  <Checkbox
                    id={`${option}Checkbox`}
                    classes={{
                      root: classes.checkboxRootDDO,
                      checked: classes.checked,
                    }}
                    onChange={(evt) => onHandleTransportMethod(evt)}
                    value={option}
                    checked={transportMethodSelected === option}
                    disabled={
                      option !== "Buyer Arranged" &&
                      transportDTO &&
                      transportDTO.status === "false"
                    }
                  />
                  {option !== "Buyer Arranged" ? (
                    <Typography
                      style={{ marginLeft: ".5rem" }}
                      className={classes.DDOBoldedColor}
                    >
                      {transportDTO && transportDTO.status !== "false"
                        ? `${option} - ${this.verifyQuotePrice(
                            transportDTO
                          )}; Delivery Estimate - ${
                            transportDTO.daysRequired
                          } days`
                        : `${option} - ${transportDTO && transportDTO.message}`}
                    </Typography>
                  ) : (
                    <Typography
                      style={{ marginLeft: ".5rem" }}
                      className={classes.DDOBoldedColor}
                    >
                      {`${option}`}
                    </Typography>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {transportMethodSelected === "CarsArrive" ? (
          <FormControl component="fieldset" className={classes.formControl}>
            <FormControl>
              <InputLabel
                className={classes.locationLabel}
                htmlFor="location-helper"
              >
                Transport Location
              </InputLabel>
              <Select
                id="locationSelect"
                value={locationDTO}
                classes={{ root: classes.selectRoot }}
                onChange={(evt) => onHandleSelectedLocation(evt)}
                input={<Input name="location" id="location-helper" />}
              >
                {custLocations.map((location) => {
                  return (
                    <MenuItem
                      id={location.locationId}
                      key={location.locationId}
                      value={location}
                    >
                      {location.locationDTO.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              id="transportMethodSpecialInstructions"
              InputLabelProps={{
                classes: { animated: classes.locationLabel },
              }}
              label="Special Instructions"
              className={classes.specialInstruction}
              onChange={() => onHandleSpecialInstruction()}
            />
          </FormControl>
        ) : null}
      </div>
    );
  }
}

TransportMethod.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TransportMethod);
