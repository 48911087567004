import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Typography,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from "@material-ui/core";
import * as AdminService from "../services/AdminServices";
import * as LoginServices from "../services/LoginServices";
import * as Actions from "../actions/AccountSetting.actions";

const styles = (theme) => ({
  container: {
    marginTop: "3rem",
    width: "auto",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  inputLabel: {
    fontSize: ".8rem",
    padding: ".4rem",
    textAlign: "center",
    alignItems: "center",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.gray,
    width: "12rem",
    display: "inline-block",
    marginTop: ".5rem",
  },
  editColumns: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  inputField: {
    fontSize: ".7rem",
    padding: ".65rem",
    display: "inline-block",
    alignItems: "center",
    width: "13rem",
  },
  selectInput: {
    fontSize: ".8rem",
    width: "12.3rem",
    textAlign: "center",
  },
  button: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.gray,
    fontSize: theme.font.small,
    fontWeight: "bold",
    width: "4rem",
    color: theme.palette.primary.white,
  },
  buttonWrapper: {
    display: "flex",
    margin: "1rem",
    width: "10rem",
    justifyContent: "space-around",
    position: "absolute",
    left: "15%",
    bottom: "10%",
  },
  systemMessage: {
    marginTop: "1rem",
    marginLeft: ".5rem",
    fontSize: theme.font.small,
  },
  fieldWrapper: {
    display: "flex",
    width: "30rem",
    height: "2rem",
    margin: ".5rem",
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "11rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  radioWrapper: {
    marginTop: "1rem",
  },
  redBoldedText: {
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
  },
  errorSnackBar: {
    marginTop: "7rem",
  },
  rowContainer: {
    display: "flex",
  },
  "@global": {
    "label + .MuiInput-formControl": {
      marginTop: 0,
      width: "12rem",
    },
  },
});

export class EditManagePayment extends Component {
  state = {
    bankName: "",
    accountNumber: "",
    accountNumber2: "",
    routingNumber: "",
    routingNumber2: "",
    defaultPaymentOption: "",
    defaultPaymentOptionId: "",
    open: false,
    message: null,
  };

  componentDidMount = () => {
    const { data } = this.props;
    data.paymentOptionDTOCollection.map((payment) => {
      if (payment.isSelectedPaymentOption) {
        this.setState({
          defaultPaymentOptionId: payment.codeId,
          defaultPaymentOption: payment.optionCode,
          bankName: data.bankName,
          accountNumber: data.accountNumber,
          accountNumber2: data.accountNumber,
          routingNumber: data.routingNumber,
          routingNumber2: data.routingNumber,
        });
      }
      return null;
    });
  };

  handleChange = (name) => (event) => {
    if (
      name === "accountNumber" ||
      name === "accountNumber2" ||
      name === "routingNumber" ||
      name === "routingNumber2"
    ) {
      const re = /\D/gm;
      if (!re.test(event.target.value)) {
        this.setState({
          [name]: event.target.value,
        });
      } else {
        this.setState({
          open: true,
          message:
            "Account/Routing number will not accept non-number characters.",
        });
      }
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  paymentOptions = (code) => {
    switch (code) {
      case "CHQ":
        return "Check";
      case "ACH":
        return "ACH";
      case "FP":
        return "Floor Plan";
      default:
        break;
    }
  };

  handleClose = () => {
    this.setState({ ...this.state, open: false, message: null });
  };

  updateAccountSettings = async () => {
    const { dispatch } = this.props;
    const { user } = this.props;
    const accountSetting = await LoginServices.loadAccountSetting(user.userId);
    dispatch(Actions.loadAccountSettingSuccess(accountSetting));
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { user } = this.props;
    const {
      bankName,
      accountNumber,
      accountNumber2,
      routingNumber,
      routingNumber2,
    } = this.state;
    if (
      !bankName ||
      !accountNumber ||
      !accountNumber2 ||
      !routingNumber ||
      !routingNumber2
    ) {
      this.setState({
        open: true,
        message: "Please enter and confirm all fields.",
      });
      return;
    }

    if (accountNumber !== accountNumber2) {
      this.setState({ open: true, message: "Account Number is not same." });
      return;
    }

    if (routingNumber !== routingNumber2) {
      this.setState({ open: true, message: "Routing Number is not same." });
      return;
    }

    if (typeof accountNumber !== "number") {
      this.setState({
        open: true,
        message:
          "Account Number is not a number.  Please remove non-number characters.",
      });
    }

    if (typeof routingNumber !== "number") {
      this.setState({
        open: true,
        message:
          "Routing Number is not a number.  Please remove non-number characters.",
      });
    }

    const response = await AdminService.updatePaymentSetup(user, this.state);
    if (response === "success") {
      this.setState({ open: true, message: "Payment has been updated" });
      await this.updateAccountSettings();
      this.props.cancelEditPayment();
    } else {
      this.setState({
        open: true,
        message: "Service Unavailable. Please try again later.",
      });
    }
  };

  render() {
    const { classes, cancelEditPayment } = this.props;
    const {
      defaultPaymentOption,
      bankName,
      message,
      accountNumber,
      accountNumber2,
      routingNumber,
      routingNumber2,
    } = this.state;
    const {
      dealershipLegalName,
      paymentOptionDTOCollection,
      checkPaymentAllowed,
    } = this.props.data;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    return (
      <div className={classes.container}>
        <form id="form" className={classes.form}>
          <div id="location" className={classes.sectionContainer}>
            <div className={classes.rowContainer}>
              <div className={classes.editColumns}>
                <Typography variant="h5" component="h3">
                  Default Payment Type
                </Typography>
                <div className={classes.radioWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Payment Type</strong>
                  </Typography>
                  <RadioGroup
                    id="paymentType"
                    aria-label="Payment Type"
                    name="Payment_Type"
                    className={classes.group}
                    value={defaultPaymentOption}
                    onChange={this.handleChange("defaultPaymentOption")}
                  >
                    {paymentOptionDTOCollection.map((payment) => {
                      if (payment.optionCode === "CHQ") {
                        if (checkPaymentAllowed) {
                          return (
                            <FormControlLabel
                              key={payment.optionCode}
                              value={payment.optionCode}
                              control={<Radio color="default" />}
                              label={this.paymentOptions(payment.optionCode)}
                            />
                          );
                        } else {
                          return null;
                        }
                      } else {
                        return (
                          <FormControlLabel
                            key={payment.optionCode}
                            value={payment.optionCode}
                            control={<Radio color="default" />}
                            label={this.paymentOptions(payment.optionCode)}
                          />
                        );
                      }
                    })}
                  </RadioGroup>
                </div>
                {defaultPaymentOption === "FP" ? (
                  <Typography className={classes.systemMessage}>
                    <span className={classes.redBoldedText}>WARNING</span> If
                    floor plan credit limit is not sufficient to complete a
                    purchase transaction your payment type shall default to ACH.
                    Please ensure that the proper bank information is entered on
                    this page.
                  </Typography>
                ) : null}
                <div className={classes.buttonWrapper}>
                  <Button
                    id="updateButton"
                    variant="contained"
                    onClick={(evt) => this.handleSubmit(evt)}
                    className={classes.button}
                  >
                    Update
                  </Button>
                  <Button
                    id="cancelButton"
                    variant="contained"
                    onClick={cancelEditPayment}
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <div
                className={classes.editColumns}
                style={{ marginLeft: "3rem" }}
              >
                <Typography variant="h5" component="h3">
                  Manage Payments
                </Typography>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Legal Name</strong>
                  </Typography>
                  <TextField
                    id="legalName"
                    className={classes.textField}
                    value={dealershipLegalName}
                    variant="filled"
                    autoComplete="off"
                    name="legalName"
                    InputProps={InputProps}
                    disabled
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Bank Name</strong>
                  </Typography>
                  <TextField
                    id="bankName"
                    className={classes.textField}
                    value={bankName}
                    variant="filled"
                    autoComplete="off"
                    name="bankName"
                    InputProps={InputProps}
                    onChange={this.handleChange("bankName")}
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Account Number</strong>
                  </Typography>
                  <TextField
                    id="accountNumber"
                    className={classes.textField}
                    value={accountNumber}
                    variant="filled"
                    autoComplete="off"
                    name="accountNumber"
                    InputProps={InputProps}
                    onChange={this.handleChange("accountNumber")}
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Re-enter Account Number</strong>
                  </Typography>
                  <TextField
                    id="accountNumber2"
                    className={classes.textField}
                    value={accountNumber2}
                    variant="filled"
                    autoComplete="off"
                    name="accountNumber2"
                    InputProps={InputProps}
                    onChange={this.handleChange("accountNumber2")}
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Routing Number</strong>
                  </Typography>
                  <TextField
                    id="routingNumber"
                    className={classes.textField}
                    value={routingNumber}
                    variant="filled"
                    autoComplete="off"
                    name="routingNumber"
                    InputProps={InputProps}
                    onChange={this.handleChange("routingNumber")}
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <Typography className={classes.fieldLabel}>
                    <strong>Re-enter Routing Number</strong>
                  </Typography>
                  <TextField
                    id="routingNumber2"
                    className={classes.textField}
                    value={routingNumber2}
                    variant="filled"
                    autoComplete="off"
                    name="routingNumber2"
                    InputProps={InputProps}
                    onChange={this.handleChange("routingNumber2")}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={`top,center`}
          open={message}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          className={classes.errorSnackBar}
          message={<span id="message-id">{message}</span>}
        />
      </div>
    );
  }
}

EditManagePayment.propTyaes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(EditManagePayment));
