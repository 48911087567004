import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Moment from "react-moment";
import SignatureCanvas from "react-signature-canvas";

import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  Button,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Snackbar,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import {
  VEHICLE_RETURN_RECEIPT_ODO_STMT,
  VEHICLE_RETURN_RECEIPT_SIGNING,
  ADD_LEASEE_SIGNATURE,
  SIGN_DISCLOSURE_HERE,
  CLEAR_SIGNATURE,
} from "../constants/UILabel";

const signaturePadWidth = 380;
const signaturePadHeight = 80;
const styles = (theme) => ({
  padSelectWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "1rem",
    padding: 0,
  },
  lowerPad: {
    height: "100%",
  },
  upperPad: {
    width: signaturePadWidth + "px",
    height: signaturePadHeight + "px",
    border: "1px solid rgba(0, 0, 0, 0.38)",
    borderRadius: "4px",
  },
  upperPadDisabled: {
    width: signaturePadWidth + "px",
    height: signaturePadHeight + "px",
    border: "1px solid rgba(0, 0, 0, 0.38)",
    borderRadius: "4px",
    opacity: 0.38,
  },
  padTop: {
    paddingTop: "12px",
  },
  padTopDisabled: {
    paddingTop: "12px",
    opacity: 0.38,
  },
  padWrapper: {
    display: "flex",
    height: "5rem",
    alignItems: "center",
    justifyContent: "space-around",
  },
  button: {
    height: "1rem",
    width: "6rem",
    fontSize: ".4rem",
    backgroundColor: theme.palette.grounding.main,
    color: theme.palette.primary.white,
    boxShadow: "none",
    borderRadius: 0,
    textTransform: "initial",
    alignSelf: "center",
    padding: 0,
    marginTop: ".5rem",
  },
  formControl: {
    height: "1rem",
    marginTop: ".5rem",
    fontSize: ".5rem",
  },
  padText: {
    fontSize: ".6rem",
    width: "100%",
  },
  returnReceiptText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  clearSignature: {
    fontSize: "14px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  clearSignatureDisabled: {
    fontSize: "14px",
    textDecoration: "underline",
    cursor: "default",
  },
  errorSnack: {
    marginTop: "7rem",
    width: "max-content",
    maxWidth: "95%",
  },
  signaturePadAndSignPaperStatement: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  customerWillSignPaperStatementContainer: {
    [theme.breakpoints.up("lg")]: {
      marginRight: "-150px",
    },
  },
  customerWillSignPaperStatement: {
    paddingTop: "30px",
    marginLeft: "0px",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "20px",
      paddingLeft: "15px",
    },
  },
  checkboxRoot: {
    "&$checked": {
      color: theme.palette.grounding.main,
    },
    marginLeft: "-9px",
  },
  checked: {},
});
export class OdometerSigningPad extends Component {
  lesseeSignaturePad = new SignatureCanvas();
  showingPlaceholderText = false;

  showPlaceholderTextOrSignature() {
    if (this.props.padValue) {
      this.loadExistingSignature(this.props.padValue);
    } else {
      this.displayPlaceholderText();
    }
  }

  loadExistingSignature = (base64Signature) => {
    if (!this.lesseeSignaturePad.isEmpty()) {
      return;
    }

    this.lesseeSignaturePad.fromDataURL(base64Signature, {
      ratio: window.devicePixelRatio,
      width: signaturePadWidth,
      height: signaturePadHeight,
    });
  };

  displayPlaceholderText = () => {
    let canvasContext = this.lesseeSignaturePad.getCanvas().getContext("2d");
    canvasContext.clearRect(0, 0, signaturePadWidth, signaturePadHeight);
    canvasContext.font = "20px Montserrat";
    canvasContext.fillStyle = "rgba(0, 0, 0, 0.38)";
    canvasContext.textAlign = "center";
    canvasContext.fillText(
      SIGN_DISCLOSURE_HERE,
      signaturePadWidth / 2,
      signaturePadHeight / 2 + 5
    );
    this.showingPlaceholderText = true;
  };

  clearPlaceholderText = () => {
    if (this.showingPlaceholderText) {
      let ctx = this.lesseeSignaturePad.getCanvas().getContext("2d");
      ctx.clearRect(0, 0, signaturePadWidth, signaturePadHeight);
      this.showingPlaceholderText = false;
    }
  };

  saveLesseeSignature = async () => {
    const lesseeSignature = this.lesseeSignaturePad.toDataURL();
    const resizedLesseeSignature = await this.resizeLesseeSignature(
      lesseeSignature,
      signaturePadWidth * 0.7,
      signaturePadHeight * 0.7
    );
    const modifiedLesseeSignature = this.modifySignatureDataToMatchFormatOnMobileApp(
      resizedLesseeSignature
    );

    this.props.handleChange("padValue")({ target: { value: lesseeSignature } });
    this.props.handleChange("padValueToSendToAPI")({
      target: {
        value: modifiedLesseeSignature,
      },
    });
  };

  modifySignatureDataToMatchFormatOnMobileApp(lesseeSignature) {
    lesseeSignature = lesseeSignature.substring(
      "data:image/png;base64,".length
    );
    lesseeSignature = lesseeSignature.replace(/.{76}/g, "$&\n");
    return lesseeSignature;
  }

  resizeLesseeSignature(lesseeSignature, wantedWidth, wantedHeight) {
    return new Promise(async function (resolve) {
      let img = document.createElement("img");
      img.onload = function () {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        let dataURI = canvas.toDataURL();
        resolve(dataURI);
      };
      img.src = lesseeSignature;
    });
  }

  clearSignature = () => {
    this.lesseeSignaturePad.clear();
    this.displayPlaceholderText();
    this.props.handleChange("padValue")({ target: { value: "" } });
    this.props.handleChange("padValueToSendToAPI")({ target: { value: "" } });
  };

  toggleCustomerWillSignPaperStatement = () => {
    this.props.handleChange("customerWillSignPaperStatement")({
      target: {
        value: !this.props.customerWillSignPaperStatement,
      },
    });
  };

  render() {
    const {
      classes,
      handleChange,
      padValue,
      showMissingSignatureMessage,
      disableSignaturePad,
      customerWillSignPaperStatement,
    } = this.props;
    const { TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF } = this.props.togglzMap;
    return (
      <React.Fragment>
        {TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF && (
          <div>
            <div className={classes.padTop}>
              <Typography
                variant="caption"
                className={classes.returnReceiptText}
              >
                <strong>{VEHICLE_RETURN_RECEIPT_ODO_STMT}</strong>
              </Typography>
            </div>
            <div>
              <Typography
                variant="caption"
                className={classes.returnReceiptText}
              >
                {VEHICLE_RETURN_RECEIPT_SIGNING}
              </Typography>
            </div>

            <div
              className={
                disableSignaturePad ? classes.padTopDisabled : classes.padTop
              }
            >
              <Typography variant="caption">
                <strong>{ADD_LEASEE_SIGNATURE}</strong>
              </Typography>
            </div>
            <div className={classes.signaturePadAndSignPaperStatement}>
              <div
                className={
                  disableSignaturePad
                    ? classes.upperPadDisabled
                    : classes.upperPad
                }
              >
                <SignatureCanvas
                  canvasProps={{
                    height: signaturePadHeight + "px",
                    width: signaturePadWidth + "px",
                  }}
                  ref={(ref) => {
                    this.lesseeSignaturePad = ref;
                  }}
                  onBegin={this.clearPlaceholderText}
                  onEnd={this.saveLesseeSignature}
                ></SignatureCanvas>
                <Typography
                  align="left"
                  className={
                    disableSignaturePad
                      ? classes.clearSignatureDisabled
                      : classes.clearSignature
                  }
                  onClick={this.clearSignature}
                  id="clearSignature"
                >
                  {CLEAR_SIGNATURE}
                </Typography>
              </div>
              <div className={classes.customerWillSignPaperStatementContainer}>
                <FormControlLabel
                  className={classes.customerWillSignPaperStatement}
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      onClick={this.toggleCustomerWillSignPaperStatement}
                      checked={customerWillSignPaperStatement}
                      classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={
                    <Typography
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      Customer will sign a paper statement
                    </Typography>
                  }
                />
              </div>
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={showMissingSignatureMessage}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={
                <span id="message-id">
                  Please have customer sign the odometer disclosure below or
                  choose "Customer will sign a paper statement"
                </span>
              }
              className={classes.errorSnack}
              autoHideDuration={3000}
            />
          </div>
        )}

        {!TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF && (
          <div className={classes.padWrapper}>
            <div className={classes.upperPad}>
              <TextField
                id="odoSignBox"
                variant="outlined"
                disabled
                InputProps={{
                  inputProps: {
                    style: {
                      height: "100%",
                      width: "10rem",
                    },
                  },
                }}
              />
            </div>

            <div className={classes.lowerPad}>
              <div className={classes.padSelectWrapper}>
                <Typography align="center" className={classes.padText}>
                  <strong>LESSEE</strong> -{" "}
                  <Moment
                    date={new Date()}
                    interval={0}
                    format={"MM/DD/YYYY"}
                  />
                </Typography>
                <Button
                  id="addLesseeSigButton"
                  className={classes.button}
                  disabled={padValue === ""}
                  variant="contained"
                  color="primary"
                >
                  Add Lessee Signature
                </Button>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    id="padSelect"
                    value={padValue}
                    onChange={(evt) => handleChange("padValue")}
                    displayEmpty
                    input={
                      <OutlinedInput
                        name="padSelect"
                        id="outlined-padSelect"
                        labelWidth={0}
                        style={{ fontSize: ".5rem", textAlign: "center" }}
                      />
                    }
                  >
                    <MenuItem value="">
                      <em>No Pad Present</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        )}
        {!TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF && (
          <div>
            <div>
              <Typography
                variant="caption"
                className={classes.returnReceiptText}
              >
                <strong>{VEHICLE_RETURN_RECEIPT_ODO_STMT}</strong>
              </Typography>
            </div>
            <div>
              <Typography
                variant="caption"
                className={classes.returnReceiptText}
              >
                {VEHICLE_RETURN_RECEIPT_SIGNING}
              </Typography>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  componentDidMount() {
    if (!!this.props.togglzMap.TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF) {
      if (this.props.disableSignaturePad) {
        this.lesseeSignaturePad.off();
      } else {
        this.showPlaceholderTextOrSignature();
      }
    }
  }

  componentDidUpdate() {
    if (!!this.props.togglzMap.TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF) {
      if (this.props.disableSignaturePad) {
        this.clearSignature();
        this.lesseeSignaturePad.off();
      } else {
        this.lesseeSignaturePad.on();
        this.showPlaceholderTextOrSignature();
      }
    }
  }
}

OdometerSigningPad.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    togglzMap: state.ui.togglzMap,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(OdometerSigningPad));
