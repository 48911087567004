import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Snackbar } from "@material-ui/core";
import LocationForm from "../components/LocationForm";

import { submitNewLocation } from "../services/UtilityServices";
import * as LoginServices from "../services/LoginServices";
import * as accountSettingActions from "../actions/AccountSetting.actions";

import { validatePhone } from "../services/ValidationServices";

const styles = (theme) => ({
  button: {
    margin: ".5rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100px",
  },
  formControl: {
    minWidth: "100%",
  },
  textField: {
    marginLeft: ".5rem",
    marginRight: ".5rem",
    marginTop: "0",
    marginBottom: "0",
    width: "100%",
  },
  textfieldWrapper: {
    margin: "auto",
    width: "100%",
  },
  tableCellBorder: {
    borderBottom: 0,
  },
  locationWrapper: {
    margin: "auto",
    width: "30%",
  },
  successMsg: {
    textAlign: "center",
    margin: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginTop: 0,
      marginLeft: ".5rem",
      marginBottom: ".5rem",
    },
  },
  snackbar: {
    top: "10rem",
  },
});

export class Location extends Component {
  state = {
    locationInfo: {
      vin: "",
      locationName: "",
      address1: "",
      address2: "",
      city: "",
      phone: "",
      fax: "",
      timeZoneId: "",
      stateId: "",
      zip: "",
      contactName1: "",
      contactPhone1: "",
      contactEmail1: "",
      contactName2: "",
      contactPhone2: "",
      contactEmail2: "",
    },
    islocationInfoValid: {
      isLocationNameValid: true,
      isAddress1Valid: true,
      isCityValid: true,
      isPhoneValid: true,
      isTimeZoneIdValid: true,
      isStateIdValid: true,
      isZipValid: true,
      isContactName1Valid: true,
      isContactPhone1Valid: true,
      isContactPhone2Valid: true,
    },
    locationAdded: false,
    errorMessage: null,
  };

  handleChange = (name, flag) => (event) => {
    this.setState({
      locationInfo: {
        ...this.state.locationInfo,
        [name]: event.target.value,
      },
      islocationInfoValid: {
        ...this.state.islocationInfoValid,
        [flag]: event.target.value === "" ? false : true,
      },
    });
  };

  addLocation = async () => {
    const { user } = this.props;
    const data = this.state.locationInfo;
    if (this.validateForm()) {
      try {
        const response = await submitNewLocation(data, user);
        this.updateAccountSettings();
        if (
          response.message === "Location added" &&
          response.status === "true"
        ) {
          this.setState(() => {
            return {
              locationAdded: true,
            };
          });
          if (this.props.page === "Arbitration") {
            this.props.handleAddLocation(true);
          }
        }
      } catch (err) {
        console.log("Error occured :", err);
      }
    }
  };

  updateAccountSettings = async () => {
    const { dispatch } = this.props;
    const { user } = this.props;
    const accountSetting = await LoginServices.loadAccountSetting(user.userId);
    dispatch(accountSettingActions.loadAccountSettingSuccess(accountSetting));
  };

  validateForm = () => {
    const {
      locationName,
      address1,
      city,
      phone,
      timeZoneId,
      stateId,
      zip,
      contactName1,
      contactPhone1,
      contactPhone2,
      contactName2,
    } = this.state.locationInfo;
    const validLocationInformation = {
      ...this.state.islocationInfoValid,
      isLocationNameValid: locationName === "" ? false : true,
      isAddress1Valid: address1 === "" ? false : true,
      isCityValid: city === "" ? false : true,
      isPhoneValid: phone === "" || !validatePhone(phone) ? false : true,
      isTimeZoneIdValid: timeZoneId === "" ? false : true,
      isStateIdValid: stateId === "" ? false : true,
      isZipValid: zip === "" ? false : true,
      isContactName1Valid: contactName1 === "" ? false : true,
      isContactPhone1Valid:
        contactPhone1 === "" || !validatePhone(contactPhone1) ? false : true,
      isContactPhone2Valid:
        (contactName2 !== "" && contactPhone2 === "") ||
        (contactName2 !== "" &&
          contactPhone2 !== "" &&
          !validatePhone(contactPhone2))
          ? false
          : true,
    };
    this.setState({
      islocationInfoValid: validLocationInformation,
    });
    for (let i in validLocationInformation) {
      if (validLocationInformation[i] === false) {
        this.setState({
          errorMessage: "Please check the required fields.",
        });
        return false;
      }
    }
    return true;
  };

  render() {
    const { classes, stateInfo } = this.props;
    const { locationInfo, islocationInfoValid, locationAdded, errorMessage } =
      this.state;
    return (
      <React.Fragment>
        {locationAdded === false ? (
          <LocationForm
            locationDetails={locationInfo}
            locationValidityDetails={islocationInfoValid}
            stateDetails={stateInfo}
            onHandleChange={this.handleChange}
            onAddLocation={this.addLocation}
          />
        ) : (
          <div className={classes.successMsg}>Location added successfully!</div>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={3000}
          open={errorMessage}
          onClose={() => this.setState({ errorMessage: null })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{errorMessage}</span>}
          className={classes.snackbar}
        />
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    stateInfo: state.stateInfo,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Location));
