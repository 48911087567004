import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import MobilitySearchForm from "./MobilitySearchForm";
import { Button } from "@material-ui/core";
import moment from "moment";
import MobilityVehicleSearchCriteria from "./MobilityVehicleSearchCriteria";
import * as MobilityServices from "../services/MobilityServices";

const styles = (theme) => ({
  buttonWrapper: {
    marginLeft: "10rem",
  },
  errorMessage: {
    marginLeft: "1rem",
    fontSize: ".75rem",
    color: "#EB0A1E",
    width: "30rem",
  },
  button: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.primary.gray,
    width: "7rem",
    fontWeight: "bold",
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.primary.gray,
      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.gray,
        "&:active": {
          backgroundColor: theme.palette.primary.gray,
        },
      },
    },
  },
  pageWrapper: {
    width: "98%",
  },
  leftWrapper: {
    float: "left",
    height: "85vh",
  },
  rightWrapper: {
    width: "22rem",
    float: "right",
    height: "85vh",
  },
  rightWrapperHide: {
    display: "none",
    width: "22rem",
    float: "right",
    height: "85vh",
  },
});

class MobilityVehicleSearch extends Component {
  state = {
    name: "",
    nameError: false,
    nameErrorMessage: "",
    nameLengthError: false,
    nameLengthErrorMessage: "Name cannot be longer than 15 characters.",
    programType: "SELECT",
    programTypeError: false,
    programTypeErrorMessage: "Please select program type.",
    startDate: null,
    endDate: null,
    frequency: "SELECT",
    frequencyDay: [],
    frequencyError: false,
    frequencyErrorMessage: "Please select frequency.",
    frequencyDayError: false,
    saleEvent: "SELECT",
    saleEventError: false,
    saleEventErrorMessage: "Please select sale event.",
    startDateError: false,
    endDateError: false,
    startDateErrorMessage: "",
    endDateErrorMessage: "",
    error: false,
    errorMessage: "",
    criteriaSelected: false,
    criteriaError: false,
    gradeError: false,
    selectedSearchCriteriaState: {},
  };

  componentDidMount = () => {
    const { savedSearchInfo } = this.props;
    if (savedSearchInfo !== null) {
      this.setState({
        isFromViewEdit: true,
        name: savedSearchInfo.searchName,
        programType: savedSearchInfo.programType,
        startDate: savedSearchInfo.searchStartDate,
        endDate: savedSearchInfo.searchEndDate,
        saleEvent: savedSearchInfo.saleEventName,
        frequency:
          savedSearchInfo.searchFrequency[0] === "EVERY_DAY" ||
          savedSearchInfo.searchFrequency[0] === "WEEK_DAYS"
            ? savedSearchInfo.searchFrequency[0]
            : "DaysOfTheWeek",
        frequencyDay: savedSearchInfo.searchFrequency,
      });
    }
  };

  handleCriteriaUpdate = (data) => {
    this.setState({
      selectedSearchCriteriaState: { data },
    });
  };

  criteriaSelected = (flag) => {
    this.setState({
      criteriaSelected: flag,
      criteriaError: flag ? false : this.state.criteriaError,
      error: false,
      gradeError: false,
    });
  };

  handleStartDateChange = (date) => {
    let eDate = moment(date).unix();
    let eEndDate = moment(this.state.endDate).unix();
    if (isNaN(date) === true) {
      this.setState({
        startDate: date,
        startDateError: true,
        startDateErrorMessage: "Please select valid date",
      });
    } else if (eEndDate !== null && eDate > eEndDate) {
      this.setState({
        startDate: date,
        startDateError: true,
        startDateErrorMessage: "Please select valid date",
      });
    } else if (eDate <= eEndDate) {
      this.setState({
        startDate: date,
        startDateError: false,
        startDateErrorMessage: "",
        endDateError: false,
        endDateErrorMessage: "",
      });
    } else {
      this.setState({
        startDate: date,
        startDateError: false,
        startDateErrorMessage: "",
      });
    }
  };

  handleEndDateChange = (date) => {
    let eDate = moment(date).unix();
    let eStartDate = moment(this.state.startDate).unix();
    if (isNaN(date) === true) {
      this.setState({
        endDate: date,
        endDateError: true,
        endDateErrorMessage: "Please select valid date",
      });
    } else if (eStartDate !== null && eDate < eStartDate) {
      this.setState({
        endDate: date,
        endDateError: true,
        endDateErrorMessage: "Please select valid date",
      });
    } else if (eDate >= eStartDate) {
      this.setState({
        endDate: date,
        endDateError: false,
        endDateErrorMessage: "",
        startDateError: false,
        startDateErrorMessage: "",
      });
    } else {
      this.setState({
        endDate: date,
        endDateError: false,
        endDateErrorMessage: "",
      });
    }
  };

  setTodayDate = (name) => {
    this.now = moment();
    if (name === "start") {
      this.handleStartDateChange(
        new Date(this.now.format("YYYY-MM-DD 04:00:00"))
      );
    } else {
      this.handleEndDateChange(
        new Date(this.now.format("YYYY-MM-DD 04:00:00"))
      );
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      frequencyDay: name === "frequency" ? [] : this.state.frequencyDay,
      frequencyError: name === "frequency" ? false : this.state.frequencyError,
      saleEventError: name === "saleEvent" ? false : this.state.saleEventError,
      programTypeError:
        name === "programType" ? false : this.state.programTypeError,
    });
  };

  handleNameChange = (name) => (event) => {
    if (event.target.value.length <= 15) {
      this.setState({
        [name]: event.target.value,
        nameError: name === "name" ? false : this.state.nameError,
        nameErrorMessage: name === "name" ? null : this.state.nameErrorMessage,
        nameLengthError: false,
      });
    } else {
      this.setState({
        nameLengthError: true,
      });
    }
  };

  handleNameBlur = () => {
    this.setState({
      nameLengthError: false,
    });
  };

  isChecked = (key, value) => {
    return this.state[key].includes(value);
  };

  toggleFreqCheck = (value) => {
    if (this.state.frequencyDay.includes(value)) {
      this.setState({
        frequencyDay: this.state.frequencyDay.filter(
          (dayValue) => dayValue !== value
        ),
      });
    } else {
      this.setState({
        frequencyDay: [...this.state.frequencyDay, value],
        frequencyDayError: false,
      });
    }
  };

  isEmpty = () => {
    const {
      name,
      programType,
      startDate,
      endDate,
      frequency,
      saleEvent,
      frequencyDay,
      criteriaSelected,
      startDateError,
      endDateError,
    } = this.state;
    const { data } = this.state.selectedSearchCriteriaState;
    let specialRegex = /[^A-Z a-z0-9]/;
    if (
      name === null ||
      name.trim() === "" ||
      specialRegex.test(name) ||
      programType === "SELECT" ||
      startDate === null ||
      endDate === null ||
      saleEvent === "SELECT" ||
      frequency === "SELECT" ||
      (frequency === "DaysOfTheWeek" && frequencyDay.length === 0) ||
      criteriaSelected === false ||
      (programType !== "ALM" ? data.selectedAuction.length === 0 : false) ||
      this.validateGradeInput() ||
      startDateError ||
      endDateError
    ) {
      return true;
    } else {
      return false;
    }
  };

  validateSearch = () => {
    const { activeSavedSearch } = this.props;
    const {
      name,
      programType,
      startDate,
      endDate,
      frequency,
      frequencyDay,
      saleEvent,
      isFromViewEdit,
    } = this.state;
    const weekDays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"];
    let nameAlreadyExist = false;
    let frequencyOverlapped = false;
    if (activeSavedSearch && activeSavedSearch.length > 0) {
      activeSavedSearch.map((info) => {
        if (isFromViewEdit && info.searchName === name.trim()) {
        } else {
          if (info.searchName === name.trim()) {
            nameAlreadyExist = true;
          }
          if (programType === info.programType) {
            if (
              Date.parse(moment(startDate).format("YYYY/MM/DD")) <=
                Date.parse(info.searchEndDate) &&
              Date.parse(endDate) >= Date.parse(info.searchStartDate)
            ) {
              if (saleEvent === info.saleEventName) {
                if (
                  frequency === "EVERY_DAY" ||
                  info.searchFrequency[0] === "EVERY_DAY"
                ) {
                  frequencyOverlapped = true;
                } else if (frequency === "WEEK_DAYS") {
                  if (info.searchFrequency[0] === "WEEK_DAYS") {
                    frequencyOverlapped = true;
                  } else {
                    info.searchFrequency.map((feq) => {
                      if (weekDays.includes(feq)) {
                        frequencyOverlapped = true;
                      }
                      return null;
                    });
                  }
                } else {
                  if (info.searchFrequency[0] === "WEEK_DAYS") {
                    frequencyDay.map((feq) => {
                      if (weekDays.includes(feq)) {
                        frequencyOverlapped = true;
                      }
                      return null;
                    });
                  } else {
                    info.searchFrequency.map((feq) => {
                      if (frequencyDay.includes(feq)) {
                        frequencyOverlapped = true;
                      }
                      return null;
                    });
                  }
                }
              }
            }
          }
        }
        return null;
      });
    }
    return {
      nameAlreadyExist: nameAlreadyExist,
      frequencyOverlapped: frequencyOverlapped,
    };
  };

  validateGradeInput = () => {
    const { data } = this.state.selectedSearchCriteriaState;
    let inValidEntry = false;
    if (data.vehicleGradeFrom !== "" || data.vehicleGradeTo !== "") {
      if (data.vehicleGradeFrom === "" || data.vehicleGradeTo === "") {
        inValidEntry = true;
      }
      if (data.vehicleGradeFromError || data.vehicleGradeToError) {
        inValidEntry = true;
      }
    }
    return inValidEntry;
  };

  isInformationUpdated = () => {
    const { savedSearch } = this.props;
    const { name, startDate, endDate, frequency, frequencyDay, saleEvent } =
      this.state;
    let currentFrequency =
      frequency === "DaysOfTheWeek" ? frequencyDay : frequency;
    let originalEntry = savedSearch.find(
      (element) => element.searchName === name.trim()
    );
    if (
      originalEntry.saleEventName !== saleEvent ||
      originalEntry.searchStartDate !== startDate ||
      originalEntry.searchEndDate !== endDate ||
      JSON.stringify(originalEntry.searchFrequency) !==
        JSON.stringify(currentFrequency)
    ) {
      return true;
    } else {
      return false;
    }
  };

  saveSearchCriteria = async () => {
    const {
      name,
      programType,
      startDate,
      endDate,
      frequency,
      frequencyDay,
      saleEvent,
      criteriaSelected,
      startDateError,
      startDateErrorMessage,
      endDateError,
      endDateErrorMessage,
      isFromViewEdit,
    } = this.state;
    const { data } = this.state.selectedSearchCriteriaState;
    const { hideCreateSearch, loadSavedSearch } = this.props;
    let validFields = null;
    let specialRegex = /[^A-Z a-z0-9]/;
    if (isFromViewEdit) {
      if (this.isInformationUpdated()) {
        validFields = this.validateSearch();
      }
    } else {
      validFields = this.validateSearch();
    }
    if (this.isEmpty()) {
      this.setState({
        nameError:
          name === null || name.trim() === ""
            ? true
            : specialRegex.test(name)
            ? true
            : false,
        nameErrorMessage:
          name === null || name.trim() === ""
            ? "Name cannot be empty."
            : specialRegex.test(name)
            ? "Name cannot have special characters."
            : null,
        programTypeError: programType === "SELECT",
        frequencyError: frequency === "SELECT",
        frequencyDayError:
          frequency === "DaysOfTheWeek" && frequencyDay.length === 0
            ? true
            : false,
        saleEventError: saleEvent === "SELECT",
        startDateError: startDateError
          ? startDateError
          : startDate === null
          ? true
          : false,
        startDateErrorMessage: startDateError
          ? startDateErrorMessage
          : startDate === null
          ? "Please select date."
          : "",
        endDateError: endDateError
          ? endDateError
          : endDate === null
          ? true
          : false,
        endDateErrorMessage: endDateError
          ? endDateErrorMessage
          : endDate === null
          ? "Please select date."
          : "",
        error:
          programType !== "ALM" ? data.selectedAuction.length === 0 : false,
        errorMessage:
          programType !== "ALM"
            ? "Please select at-least one auction location."
            : "",
        serverError: false,
        serverErrorMessage: "",
        criteriaError: criteriaSelected ? false : true,
        gradeError: this.validateGradeInput(),
      });
    } else if (
      validFields !== null &&
      (validFields.nameAlreadyExist || validFields.frequencyOverlapped)
    ) {
      this.setState({
        nameError: validFields.nameAlreadyExist
          ? validFields.nameAlreadyExist
          : false,
        nameErrorMessage: validFields.nameAlreadyExist
          ? "Name must be unique."
          : null,
        programTypeError: false,
        frequencyError: false,
        frequencyDayError: false,
        saleEventError: false,
        startDateError: false,
        endDateError: false,
        startDateErrorMessage: "",
        endDateErrorMessage: "",
        error: validFields.frequencyOverlapped
          ? validFields.frequencyOverlapped
          : false,
        errorMessage: validFields.frequencyOverlapped
          ? "Vehicle matching selected frequency and sale event within the selected date range are already reserved by a different rideshare event."
          : null,
        serverError: false,
        serverErrorMessage: "",
        criteriaError: false,
        gradeError: false,
      });
    } else {
      let finalFrq = [];
      if (this.state.frequency === "DaysOfTheWeek") {
        finalFrq = this.state.frequencyDay;
      } else {
        finalFrq[0] = this.state.frequency;
      }
      let apiData = {
        searchName: this.state.name.trim(),
        programType: programType,
        saleEventName: this.state.saleEvent,
        searchFrequency: finalFrq,
        searchStartDate: Date.parse(this.state.startDate).toString(),
        searchEndDate: Date.parse(this.state.endDate).toString(),
        userId: this.props.user.userId,
        searchCriteria: {
          make: data.selectedMake,
          model: data.selectedModel,
          modelNumber: data.selectedModelNumber,
          bodyStyle: data.selectedStyle,
          vehicleGradeRange: {
            fromGrade:
              data.vehicleGradeFrom === null
                ? null
                : Number(data.vehicleGradeFrom),
            toGrade:
              data.vehicleGradeTo === null ? null : Number(data.vehicleGradeTo),
          },
          exteriorColor: data.selectedExteriorColor,
          interiorColor: data.selectedInteriorColor,
          doors: data.selectedDoor,
          driveTrain: data.selectedDriveTrain,
          region: data.selectedRegion,
          series: data.selectedSeries,
          modelYearRange: {
            fromYear: data.selectedYearFrom,
            toYear: data.selectedYearTo,
          },
          state: data.selectState,
          odometerRange: {
            fromodometer: data.selectedOdometerFrom,
            toodometer: data.selectedOdometerTo,
          },
          priceRange: {
            fromPrice: data.selectedPriceFrom,
            toPrice: data.selectedPriceTo,
          },
          transmission: data.selectedTransmission,
          engine: data.selectedEngine,
          auctionLocations: this.checkAuctionSelection(data.selectedAuction),
          carfaxOptions: data.selectedCarfax,
          options: data.selectedOptions,
        },
      };
      const response = await MobilityServices.saveOrUpdateSearch(apiData);
      if (response === "Success") {
        hideCreateSearch();
        loadSavedSearch();
      } else {
        this.setState({
          nameError: false,
          nameLengthError: false,
          programTypeError: false,
          nameErrorMessage: null,
          frequencyError: false,
          frequencyDayError: false,
          saleEventError: false,
          startDateError: false,
          endDateError: false,
          startDateErrorMessage: "",
          endDateErrorMessage: "",
          error: false,
          errorMessage: null,
          serverError: true,
          serverErrorMessage: "Server error occurred while saving the data.",
          criteriaError: false,
          gradeError: false,
        });
      }
    }
  };

  checkAuctionSelection = (selectedAuction) => {
    let index = selectedAuction.indexOf("AllAuction");
    if (index !== -1) {
      selectedAuction.splice(selectedAuction.indexOf("AllAuction"), 1);
    }
    return selectedAuction;
  };

  cancelSearch = () => {
    const { hideCreateSearch } = this.props;
    hideCreateSearch();
  };

  render() {
    const { classes, savedSearchInfo, userRoleHasMobilityRideshareView } =
      this.props;
    const {
      name,
      nameError,
      nameErrorMessage,
      nameLengthError,
      nameLengthErrorMessage,
      programType,
      programTypeError,
      programTypeErrorMessage,
      startDate,
      endDate,
      frequency,
      frequencyDay,
      frequencyError,
      frequencyErrorMessage,
      frequencyDayError,
      saleEvent,
      saleEventError,
      saleEventErrorMessage,
      startDateError,
      endDateError,
      startDateErrorMessage,
      endDateErrorMessage,
      error,
      errorMessage,
      serverError,
      serverErrorMessage,
      criteriaError,
      gradeError,
      isFromViewEdit,
    } = this.state;
    return (
      <React.Fragment>
        <div className={classes.pageWrapper}>
          <div className={classes.leftWrapper}>
            <MobilitySearchForm
              name={name}
              nameError={nameError}
              nameErrorMessage={nameErrorMessage}
              nameLengthError={nameLengthError}
              nameLengthErrorMessage={nameLengthErrorMessage}
              programType={programType}
              programTypeError={programTypeError}
              programTypeErrorMessage={programTypeErrorMessage}
              startDate={startDate}
              endDate={endDate}
              frequency={frequency}
              frequencyDay={frequencyDay}
              frequencyError={frequencyError}
              frequencyErrorMessage={frequencyErrorMessage}
              frequencyDayError={frequencyDayError}
              saleEvent={saleEvent}
              saleEventError={saleEventError}
              saleEventErrorMessage={saleEventErrorMessage}
              startDateError={startDateError}
              endDateError={endDateError}
              startDateErrorMessage={startDateErrorMessage}
              endDateErrorMessage={endDateErrorMessage}
              error={error}
              errorMessage={errorMessage}
              serverError={serverError}
              serverErrorMessage={serverErrorMessage}
              criteriaError={criteriaError}
              gradeError={gradeError}
              handleStartDateChange={this.handleStartDateChange}
              handleEndDateChange={this.handleEndDateChange}
              setTodayDate={this.setTodayDate}
              handleChange={this.handleChange}
              handleNameChange={this.handleNameChange}
              handleNameBlur={this.handleNameBlur}
              isChecked={this.isChecked}
              toggleFreqCheck={this.toggleFreqCheck}
              isFromViewEdit={isFromViewEdit}
              userRoleHasMobilityRideshareView={
                userRoleHasMobilityRideshareView
              }
            />
            <div className={classes.errorMessage}>
              {error ? errorMessage : null}
            </div>
            <div className={classes.buttonWrapper}>
              {isFromViewEdit && userRoleHasMobilityRideshareView ? (
                <Button id="save" className={classes.button} disabled={true}>
                  Save
                </Button>
              ) : (
                <Button
                  id="save"
                  className={classes.button}
                  onClick={() => this.saveSearchCriteria()}
                >
                  Save
                </Button>
              )}
              <Button
                id="cancel"
                className={classes.button}
                onClick={() => this.cancelSearch()}
              >
                {isFromViewEdit && userRoleHasMobilityRideshareView
                  ? "Back"
                  : "Cancel"}
              </Button>
            </div>
          </div>
          <div
            className={
              programType !== "ALM"
                ? classes.rightWrapper
                : classes.rightWrapperHide
            }
          >
            <MobilityVehicleSearchCriteria
              handleCriteriaUpdate={this.handleCriteriaUpdate}
              criteriaSelected={this.criteriaSelected}
              savedSearchInfo={savedSearchInfo}
              isFromViewEdit={isFromViewEdit}
              userRoleHasMobilityRideshareView={
                userRoleHasMobilityRideshareView
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    savedSearch: state.mobilityState.mobilitySavedSearch,
    activeSavedSearch: state.mobilityState.activeMobilitySavedSearch,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(MobilityVehicleSearch)
);
