import { encode } from "base-64";
const API_URL = process.env.REACT_APP_FLEET_FINANCIAL_API;

export async function getVehicleFleetFinancialData(
  vin,
  accountNumber,
  returnDate,
  leaseInformationId
) {
  const username = process.env.REACT_APP_FLEET_FINANCIAL_USERNAME;
  const password = process.env.REACT_APP_FLEET_FINANCIAL_PASSWORD;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + encode(username + ":" + password),
    },
    body: JSON.stringify({
      vin,
      accountNumber,
      returnDate,
    }),
  };
  try {
    const response = await fetch(
      `${API_URL}/updateVehicleFinancialData/${leaseInformationId}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      console.log("Fetching FleetFinancial data failed for", vin);
      return null;
    }
  } catch (err) {
    console.log("Fetching FleetFinancial data failed for", err.message);
    return null;
  }
}
