import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { connect } from "react-redux";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import * as AdvanceSearchServices from "../services/AdvanceSearchServices";
import {
  mobile_search_edit_info,
  advance_search_criteria,
} from "../actions/carActions";

const returnEmailFreq = (status) => {
  const statuses = {
    18108: "WEEK_DAYS",
    18107: "EVERY_DAY",
    18116: "DaysOfTheWeek",
    26689: "NoEmail",
    18109: "Monday",
    18110: "Tuesday",
    18111: "Wednesday",
    18112: "Thursday",
    18113: "Friday",
    18114: "Saturday",
    18115: "Sunday",
  };
  return statuses[status] || null;
};

const styles = (theme) => ({
  saveSearchWrapper: {
    marginLeft: "0.6rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  select: {
    minWidth: "12rem",
  },
  displayFrequency: {
    textAlign: "left",
    marginLeft: "0.6rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      width: "100%",
    },
  },
  viewDaysSelection: {
    display: "flex",
    marginLeft: "10rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      justifyContent: "center",
    },
  },
  viewDaysSelectionLabel: {
    marginLeft: "10rem",
    marginTop: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      fontSize: ".8rem",
    },
  },
  viewDaysSelectionRight: {
    display: "flex",
    margin: "0.4rem",
    flexDirection: "column",
  },
  viewDaysSelectionLeft: {
    display: "flex",
    margin: "0.4rem",
    flexDirection: "column",
  },
  buttonWrapper: {
    marginLeft: "10rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      textAlign: "center",
      paddingBottom: "6rem",
    },
  },
  button: {
    margin: "1rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.primary.gray,
    width: "7rem",
    fontWeight: "bold",
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.primary.gray,
      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.gray,
        "&:active": {
          backgroundColor: theme.palette.primary.gray,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: ".5rem",
      width: "7rem",
    },
  },
  textField: {
    margin: 0,
    marginRight: ".5rem",
    background: theme.palette.primary.white,
    height: "2rem",
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      margin: "0",
    },
  },
  fieldWrapper: {
    display: "flex",
    width: "20rem",
    height: "2rem",
    margin: ".5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0",
    },
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "6rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
      fontSize: ".6rem",
    },
  },
  fieldLabelEmail: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "10rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      fontSize: ".6rem",
    },
  },
  selectInput: {
    fontSize: ".8rem",
    minWidth: "10rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "50% !important",
    },
  },
  formControl: {
    display: "flex",
    height: "2rem",
    marginTop: ".5rem",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "@global": {
    "label + .MuiInput-formControl": {
      marginTop: 0,
      transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
    ".MuiFormControl-root": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center",
        fontSize: ".6rem",
      },
    },
  },
  heading: {
    margin: "1rem",
    fontSize: "1.2rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
  heading2: {
    margin: "0.6rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
  checkboxRoot: {
    "&$checked": {
      color: theme.palette.primary.gray,
      "&:hover": {
        backgroundColor: fade(
          theme.palette.primary.gray,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "&:hover": {
      backgroundColor: fade(
        theme.palette.primary.gray,
        theme.palette.action.hoverOpacity
      ),
    },
    padding: ".25rem",
    marginLeft: ".5rem",
  },
  errorSnackBar: {
    marginTop: "7rem",
  },
  autoSearchSaveWrapper: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "3.5rem",
    },
  },
});

export class AutoSearchSave extends Component {
  state = {
    name: "",
    frequency: "EVERY_DAY",
    frequencyDay: [],
    errorMessage: null,
    error: false,
    savedSearchInfo: [],
  };

  componentWillMount = () => {
    this.populateSavedSearch();
  };

  componentWillUnmount = () => {
    this.setState({
      name: "",
      frequency: "EVERY_DAY",
      frequencyDay: [],
      errorMessage: null,
      error: false,
    });
  };

  populateSavedSearch = async () => {
    const { searchParamInfo, user } = this.props;
    const savedSearchInfo = await AdvanceSearchServices.getSavedSearch(user);
    if (searchParamInfo) {
      this.setState({
        name: searchParamInfo.searchName ? searchParamInfo.searchName : "",
        frequency: searchParamInfo.alertFrequencies
          ? searchParamInfo.alertFrequencies.length > 1
            ? returnEmailFreq("18116")
            : returnEmailFreq(searchParamInfo.alertFrequencies[0])
          : "EVERY_DAY",
        frequencyDay: searchParamInfo.alertFrequencies
          ? searchParamInfo.alertFrequencies.length > 1
            ? this.returnDaysMapping(searchParamInfo.alertFrequencies)
            : []
          : [],
        editMode: searchParamInfo.searchName ? true : false,
        savedSearchInfo,
      });
    } else {
      this.setState({
        savedSearchInfo,
        editMode: false,
      });
    }
  };

  returnDaysMapping = (freq) => {
    let days = [];
    freq.map((item) => {
      return days.push(returnEmailFreq(item));
    });
    return days;
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      showNameError: false,
      frequencyDay: name === "frequency" ? [] : this.state.frequencyDay,
    });
  };

  toggleFreqCheck = (value) => {
    if (this.state.frequencyDay.includes(value)) {
      this.setState({
        frequencyDay: this.state.frequencyDay.filter(
          (dayValue) => dayValue !== value
        ),
        showNameError: false,
      });
    } else {
      this.setState({
        frequencyDay: [...this.state.frequencyDay, value],
        showNameError: false,
      });
    }
  };

  saveSearch = async () => {
    const {
      toggleAutoSearchSave,
      query,
      user,
      dispatch,
      advancedSearchCriteria,
    } = this.props;
    const { name, frequency, frequencyDay, savedSearchInfo, editMode } =
      this.state;
    let nameExist = false;
    for (let i = 0; i < savedSearchInfo.length; i++) {
      if (savedSearchInfo[i]["searchName"] === name) {
        nameExist = true;
      }
    }
    let response;
    if (name === "") {
      this.setState({
        errorMessage: "Please enter name.",
        error: true,
      });
    } else if (this.validateQuery()) {
      this.setState({
        errorMessage: "Please select criteria.",
        error: true,
      });
    } else if (frequency === "Required Field") {
      this.setState({
        errorMessage: "Please select email alert frequency.",
        error: true,
      });
    } else if (frequency === "DaysOfTheWeek" && frequencyDay.length === 0) {
      this.setState({
        errorMessage: "Please select days.",
        error: true,
      });
    } else if (!editMode && nameExist) {
      this.setState({
        errorMessage: "Name already exist. Please select different name.",
        error: true,
      });
    } else {
      const queryParams = advancedSearchCriteria
        ? advancedSearchCriteria
        : query;
      response = await AdvanceSearchServices.saveUpdateSearch(
        queryParams,
        user,
        frequency,
        frequencyDay,
        name
      );
      if (response !== null) {
        toggleAutoSearchSave();
        dispatch(mobile_search_edit_info(null));
        dispatch(advance_search_criteria(null));
      } else {
        this.setState({
          errorMessage: "Please try again.",
          error: true,
        });
      }
    }
  };

  validateQuery = () => {
    const { query } = this.props;
    if (
      query &&
      query.bodyStyle === null &&
      query.distanceFromUser === null &&
      query.doors === null &&
      query.driveTrain === null &&
      query.exteriorColor === null &&
      query.fromYear === null &&
      query.interiorColor === null &&
      query.make === null &&
      query.model === null &&
      query.modelNumber === null &&
      query.odometerRange[0].fromValue === "0" &&
      query.odometerRange[0].toValue === "200000+" &&
      query.priceRange[0].fromValue === "0" &&
      query.priceRange[0].toValue === "+100000" &&
      query.region === null &&
      query.series === null &&
      query.state === null &&
      query.toYear === null &&
      query.vehicleGrade === null &&
      query.transmission === null &&
      query.engine === null &&
      query.option === null
    ) {
      return true;
    }
    return false;
  };

  isChecked = (key, value) => {
    return this.state[key].includes(value);
  };

  render() {
    const { name, frequency, errorMessage, error } = this.state;
    const { classes, toggleAutoSearchSave } = this.props;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    return (
      <React.Fragment>
        <div className={classes.autoSearchSaveWrapper}>
          <Typography variant="h6" className={classes.heading}>
            Save This Search Result
          </Typography>
          <div className={classes.saveSearchWrapper}>
            <div className={classes.fieldWrapper}>
              <Typography className={classes.fieldLabel}>
                <strong>Name:</strong>
              </Typography>
              <TextField
                id="name"
                placeholder="Required Field"
                className={classes.textField}
                value={name}
                onChange={this.handleChange("name")}
                margin="normal"
                variant="filled"
                autoComplete="off"
                name="name"
                InputProps={InputProps}
                autoFocus
                required
                disabled={this.state.editMode}
              />
            </div>
            <Typography className={classes.heading2}>
              Get vehicles that match this search criteria emailed to you.
            </Typography>
            <div className={classes.displayFrequency}>
              <FormControl className={classes.formControl}>
                <label className={classes.fieldLabelEmail}>
                  <strong>E-mail Alert Frequency:</strong>
                </label>
                <Select
                  id="frequency"
                  className={classes.selectInput}
                  value={frequency}
                  onChange={this.handleChange("frequency")}
                  name="frequency"
                  displayEmpty
                >
                  <MenuItem
                    id="18107"
                    value={"EVERY_DAY"}
                    style={{ fontSize: ".8rem" }}
                  >
                    {" "}
                    Every Day{" "}
                  </MenuItem>
                  <MenuItem
                    id="18108"
                    value={"WEEK_DAYS"}
                    style={{ fontSize: ".8rem" }}
                  >
                    {" "}
                    Week Days{" "}
                  </MenuItem>
                  <MenuItem
                    id="18116"
                    value={"DaysOfTheWeek"}
                    style={{ fontSize: ".8rem" }}
                  >
                    {" "}
                    Day(s) of the Week{" "}
                  </MenuItem>
                  <MenuItem
                    id="26689"
                    value={"NoEmail"}
                    style={{ fontSize: ".8rem" }}
                  >
                    {" "}
                    No E-mail Notification{" "}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {frequency === "DaysOfTheWeek" ? (
              <>
                <FormControl component="fieldset">
                  <Typography className={classes.viewDaysSelectionLabel}>
                    Please Select Day(s) To Be Notified
                  </Typography>
                  <div className={classes.viewDaysSelection}>
                    <div className={classes.viewDaysSelectionRight}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`MondayCheck`}
                            checked={this.isChecked(`frequencyDay`, "Monday")}
                            onChange={() => this.toggleFreqCheck("Monday")}
                            value={18109}
                            color="default"
                            classes={{
                              root: classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: ".75rem",
                            }}
                          >
                            Monday
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`TuesdayCheck`}
                            checked={this.isChecked(`frequencyDay`, "Tuesday")}
                            onChange={() => this.toggleFreqCheck("Tuesday")}
                            value={18110}
                            color="default"
                            classes={{
                              root: classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: ".75rem",
                            }}
                          >
                            Tuesday
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`WednesdayCheck`}
                            checked={this.isChecked(
                              `frequencyDay`,
                              "Wednesday"
                            )}
                            onChange={() => this.toggleFreqCheck("Wednesday")}
                            value={18111}
                            color="default"
                            classes={{
                              root: classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: ".75rem",
                            }}
                          >
                            Wednesday
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`ThursdayCheck`}
                            checked={this.isChecked(`frequencyDay`, "Thursday")}
                            onChange={() => this.toggleFreqCheck("Thursday")}
                            value={18112}
                            color="default"
                            classes={{
                              root: classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: ".75rem",
                            }}
                          >
                            Thursday
                          </Typography>
                        }
                      />
                    </div>
                    <div className={classes.viewDaysSelectionLeft}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`FridayCheck`}
                            checked={this.isChecked(`frequencyDay`, "Friday")}
                            onChange={() => this.toggleFreqCheck("Friday")}
                            value={18113}
                            color="default"
                            classes={{
                              root: classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: ".75rem",
                            }}
                          >
                            Friday
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`SaturdayCheck`}
                            checked={this.isChecked("frequencyDay", "Saturday")}
                            onChange={() => this.toggleFreqCheck("Saturday")}
                            value={18114}
                            color="default"
                            classes={{
                              root: classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: ".75rem",
                            }}
                          >
                            Saturday
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`SundayCheck`}
                            checked={this.isChecked(`frequencyDay`, "Sunday")}
                            onChange={() => this.toggleFreqCheck("Sunday")}
                            value={18115}
                            color="default"
                            classes={{
                              root: classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: ".75rem",
                            }}
                          >
                            Sunday
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </FormControl>
              </>
            ) : null}
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              id="save"
              className={classes.button}
              onClick={() => this.saveSearch()}
            >
              Save
            </Button>
            <Button
              id="cancel"
              className={classes.button}
              onClick={() => toggleAutoSearchSave()}
            >
              Cancel
            </Button>
          </div>
          <Snackbar
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
            className={classes.errorSnackBar}
            open={error}
            autoHideDuration={3000}
            onClose={() => this.setState({ errorMessage: null, error: false })}
          >
            <SnackbarContent
              className={classes.errorMessage}
              message={errorMessage}
            />
          </Snackbar>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    advancedSearchCriteria: state.cars.advanceSearchCriteria,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AutoSearchSave));
