import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Card, Typography } from "@material-ui/core";

import PassVehicle from "../components/PassVehicle";
import BuyVehicle from "../components/BuyVehicle";
import Loading from "../components/Loading";
import BidVehicle from "../components/BidVehicle";
import CarListTile from "../components/CarListTile";
import DDOCarListTile from "../components/DDOCarListTile";
import MobileCarListTile from "../components/MobileCarListTile";
import GuaranteePayments from "../components/GuaranteePayments";
import MobileDDOCarListTile from "../components/MobileDDOCarListTile";
import MobileVehicleInformation from "../components/MobileVehicleInformation";
import MobileDDOVehicleInformation from "../components/MobileDDOVehicleInformation";
import MobilePurchaseButtons from "../components/MobilePurchaseButtons";

import ReactSVG from "react-svg";
import Save from "../assets/save.svg";
import Back from "../assets/back.svg";

const styles = (theme) => ({
  pageLoad: {
    alignItem: "center",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  desktopCardList: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileCardList: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileCardListPadding: {
    paddingBottom: "6rem",
    paddingTop: "3.5rem",
  },
  linkWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  seperatorDiv: {
    paddingTop: "0.2em",
  },
  countStyle: {
    padding: "0.3rem",
    marginTop: "0.2rem",
    fontSize: ".8rem",
  },
  buttonSearch: {
    backgroundColor: theme.palette.primary.gray,
    width: "2.5rem",
    height: "2.00rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
});

export class SearchResult extends Component {
  state = {
    srCar: [],
    programCertComments: "",
    programCertNum: "",
    pageLoading: false,
    vehicleBidInformation: null,
    openNote: null,
    openTransport: null,
    mobileGuarantee: false,
  };

  componentWillMount = () => {
    this.refs = {};
    const { openBid } = this.props;

    if (openBid) {
      this.setState({
        pageLoading: false,
      });
    }
  };

  componentDidMount = () => {
    const { selectedCar, searchCars } = this.props;
    if (
      searchCars &&
      searchCars.length > 0 &&
      this.refs[
        selectedCar.vin
          ? selectedCar.vin
          : selectedCar.vehicleIdentificationNumber
      ]
    ) {
      this.scrollToCar(selectedCar);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { searchCars, user, selectCar } = this.props;
    if (
      prevProps.searchCars.length !== searchCars.length &&
      searchCars.length > 0
    ) {
      if (searchCars[0].vin) {
        selectCar(searchCars[0], user, "DDO");
      } else if (searchCars[0].vehicleIdentificationNumber) {
        selectCar(searchCars[0], user, "GAMD");
      }
    }
  };

  onHandleProgramCertCommentsChange = (event) => {
    this.setState({
      programCertComments: event.target.value,
    });
  };

  onHandleProgramCertNumChange = (event) => {
    this.setState({
      programCertNum: event.target.value,
    });
  };

  toggleMobileGuarantee = () => {
    this.setState({
      mobileGuarantee: !this.state.mobileGuarantee,
    });
  };

  handleOpenNote = (car) => {
    const { openNote } = this.state;
    if (
      car &&
      ((car.vin && car.vin !== openNote) ||
        (car.vehicleIdentificationNumber &&
          car.vehicleIdentificationNumber !== openNote))
    ) {
      this.setState({
        openNote: car.vin ? car.vin : car.vehicleIdentificationNumber,
        openTransport: null,
      });
    } else {
      this.setState({
        openNote: null,
      });
    }
  };

  handleTransportToggle = (car) => {
    const { openTransport } = this.state;
    if (openTransport !== car.vin) {
      this.setState({
        openTransport: car.vin,
        openNote: null,
      });
    } else {
      this.setState({
        openTransport: null,
      });
    }
  };

  setTileRef = (car, ref) => {
    this.refs[car.vin ? car.vin : car.vehicleIdentificationNumber] = ref;
  };

  scrollToCar = (car) => {
    this.refs[
      car.vin ? car.vin : car.vehicleIdentificationNumber
    ].current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  render() {
    const {
      classes,
      user,
      history,
      dispatch,
      accountSetting,
      auctionTimes,
      currentAuction,
      pageFrom,
      passVehicle,
      buyVehicle,
      openBid,
      ddoBuyVehicle,
      updateWatchlist,
      searchResultConditionReports,
      from,
      vehicleBidInformation,
      mobileCarInformation,
      hideMobileNavMenu,
      toggleFullConditionReport,
      toggleConditionReport,
      matchCount,
      viewMode,
      toggleBackButton,
      saveSearchTemp,
      searchCars,
      updateGuaranteePayment,
      selectCar,
      selectedCar,
      selectMobileCar,
      conditionReport,
      handleBidOpen,
      handleBuyNow,
      togglePassVehicle,
      toggleDDOBuyVehicle,
      toggleBuy,
      updateAutoBid,
    } = this.props;
    const {
      programCertComments,
      programCertNum,
      pageLoading,
      openNote,
      openTransport,
      mobileGuarantee,
    } = this.state;
    return (
      <React.Fragment>
        <div className={classes.desktopCardList}>
          <div>
            {!passVehicle && !buyVehicle && !openBid && !ddoBuyVehicle
              ? searchCars.length > 0 &&
                searchCars.map((car) => {
                  return (
                    <div
                      ref={
                        this.refs[
                          car.vin ? car.vin : car.vehicleIdentificationNumber
                        ]
                      }
                      key={
                        car.vehicleIdentificationNumber !== undefined
                          ? car.vehicleIdentificationNumber
                          : car.vin
                      }
                      className={classes.advSearchCardWrapper}
                    >
                      {(car.dealerFacingStatus === "DLR_BID_NOW" ||
                        car.dealerFacingStatus === "DLR_BUY_NOW" ||
                        car.statusName === "DLR_BID_NOW" ||
                        car.statusName === "DLR_BUY_NOW" ||
                        car.statusName === "DLR_SOLD") &&
                      car.vin ? (
                        <DDOCarListTile
                          key={car.vin}
                          car={car}
                          selectCar={selectCar}
                          user={user}
                          onHandleProgramCertCommentsChange={
                            this.onHandleProgramCertCommentsChange
                          }
                          onHandleProgramCertNumChange={
                            this.onHandleProgramCertNumChange
                          }
                          programCertNum={programCertNum}
                          programCertComments={programCertComments}
                          selectedCar={selectedCar}
                          conditionReport={
                            searchResultConditionReports[car.vin]
                          }
                          toggleBuy={toggleDDOBuyVehicle}
                          history={history}
                          handleBidOpen={handleBidOpen}
                          handleBuyNow={handleBuyNow}
                          updateAutoBid={updateAutoBid}
                          currentAuction={currentAuction}
                          pageFrom={pageFrom}
                          updateWatchlist={updateWatchlist}
                          openNote={openNote}
                          handleOpenNote={this.handleOpenNote}
                          openTransport={openTransport}
                          handleTransportToggle={this.handleTransportToggle}
                          toggleFullConditionReport={toggleFullConditionReport}
                          setTileRef={this.setTileRef}
                        />
                      ) : (
                        <CarListTile
                          key={car.vehicleIdentificationNumber}
                          car={car}
                          selectCar={selectCar}
                          user={user}
                          onHandleProgramCertCommentsChange={
                            this.onHandleProgramCertCommentsChange
                          }
                          onHandleProgramCertNumChange={
                            this.onHandleProgramCertNumChange
                          }
                          programCertNum={programCertNum}
                          programCertComments={programCertComments}
                          selectedCar={selectedCar}
                          conditionReport={
                            searchResultConditionReports[
                              car.vehicleIdentificationNumber
                            ]
                          }
                          history={history}
                          dispatch={dispatch}
                          accountSetting={accountSetting}
                          togglePass={togglePassVehicle}
                          toggleBuy={toggleBuy}
                          pageFrom={pageFrom}
                          updateWatchlist={updateWatchlist}
                          from={from}
                          openNote={openNote}
                          handleOpenNote={this.handleOpenNote}
                          selectConditionReport={toggleFullConditionReport}
                          updateGuaranteePayment={updateGuaranteePayment}
                          setTileRef={this.setTileRef}
                        />
                      )}
                    </div>
                  );
                })
              : null}
            {passVehicle ? (
              <PassVehicle togglePass={togglePassVehicle} history={history} />
            ) : null}
            {buyVehicle ? (
              <BuyVehicle
                toggleBuy={toggleBuy}
                page="GAMD"
                conditionReport={conditionReport}
              />
            ) : null}
            {ddoBuyVehicle ? (
              <BuyVehicle
                toggleBuy={toggleDDOBuyVehicle}
                page="DDO"
                conditionReport={conditionReport}
                isBuyNow
              />
            ) : null}
            {openBid ? (
              <BidVehicle
                car={selectedCar}
                accountSetting={accountSetting}
                auctionTimes={auctionTimes}
                user={user}
                vehicleBidInformation={vehicleBidInformation}
                handleBidOpen={handleBidOpen}
                updateAutoBid={updateAutoBid}
                conditionReport={conditionReport}
              />
            ) : null}
          </div>
        </div>
        {window.innerWidth < 500 ? (
          <div className={classes.mobileCardList}>
            {pageLoading ? (
              <Loading />
            ) : searchCars && searchCars.length > 0 ? (
              !passVehicle &&
              !buyVehicle &&
              !openBid &&
              !ddoBuyVehicle &&
              !mobileCarInformation ? (
                <div className={classes.mobileCardListPadding}>
                  <div className={classes.linkWrapper}>
                    <Card
                      id="backButton"
                      className={classes.buttonSearch}
                      onClick={() => toggleBackButton()}
                    >
                      <ReactSVG
                        svgStyle={{ fill: "white", marginTop: ".4rem" }}
                        src={Back}
                      />
                    </Card>
                    <Typography className={classes.countStyle}>
                      {" "}
                      <strong>{matchCount}</strong> Matches{" "}
                    </Typography>
                    {viewMode ? null : (
                      <Card
                        id="saveButton"
                        className={classes.buttonSearch}
                        onClick={() => saveSearchTemp()}
                      >
                        <ReactSVG
                          svgStyle={{ fill: "white", marginTop: ".4rem" }}
                          src={Save}
                        />
                      </Card>
                    )}
                  </div>
                  {searchCars.map((car) =>
                    (car.dealerFacingStatus === "DLR_BID_NOW" ||
                      car.dealerFacingStatus === "DLR_BUY_NOW" ||
                      car.statusName === "DLR_BID_NOW" ||
                      car.statusName === "DLR_BUY_NOW" ||
                      car.statusName === "DLR_SOLD") &&
                    car.vin !== undefined ? (
                      <MobileDDOCarListTile
                        key={car.vin}
                        car={car}
                        selectCar={selectMobileCar}
                        user={user}
                        onHandleProgramCertCommentsChange={
                          this.onHandleProgramCertCommentsChange
                        }
                        onHandleProgramCertNumChange={
                          this.onHandleProgramCertNumChange
                        }
                        programCertNum={programCertNum}
                        programCertComments={programCertComments}
                        selectedCar={selectedCar}
                        conditionReport={searchResultConditionReports[car.vin]}
                        toggleBuy={toggleDDOBuyVehicle}
                        history={history}
                        handleBidOpen={handleBidOpen}
                        handleBuyNow={handleBuyNow}
                        updateAutoBid={updateAutoBid}
                        currentAuction={currentAuction}
                        pageFrom={pageFrom}
                        updateWatchlist={updateWatchlist}
                        openNote={openNote}
                        handleOpenNote={this.handleOpenNote}
                        openTransport={openTransport}
                        handleTransportToggle={this.handleTransportToggle}
                        accountSetting={accountSetting}
                        dispatch={this.props.dispatch}
                      />
                    ) : (
                      <MobileCarListTile
                        key={car.vehicleIdentificationNumber}
                        car={car}
                        selectCar={selectMobileCar}
                        user={user}
                        onHandleProgramCertCommentsChange={
                          this.onHandleProgramCertCommentsChange
                        }
                        onHandleProgramCertNumChange={
                          this.onHandleProgramCertNumChange
                        }
                        programCertNum={programCertNum}
                        programCertComments={programCertComments}
                        selectedCar={selectedCar}
                        conditionReport={
                          searchResultConditionReports[
                            car.vehicleIdentificationNumber
                          ]
                        }
                        history={history}
                        dispatch={dispatch}
                        accountSetting={accountSetting}
                        togglePass={togglePassVehicle}
                        toggleBuy={toggleBuy}
                        updateWatchlist={updateWatchlist}
                        openNote={openNote}
                        handleOpenNote={this.handleOpenNote}
                        pageFrom={pageFrom}
                        from={from}
                      />
                    )
                  )}
                </div>
              ) : null
            ) : null}
            {mobileCarInformation &&
            !passVehicle &&
            !buyVehicle &&
            !ddoBuyVehicle &&
            !openBid &&
            !mobileGuarantee /* && !fullConditionReport */ ? (
              <div className={classes.watchListCardListRight}>
                {mobileCarInformation.vin ? (
                  <MobileDDOVehicleInformation
                    user={user}
                    car={mobileCarInformation}
                    conditionReport={
                      searchResultConditionReports[mobileCarInformation.vin]
                    }
                    toggleFullConditionReport={toggleConditionReport}
                  />
                ) : (
                  <MobileVehicleInformation
                    car={mobileCarInformation}
                    conditionReport={
                      searchResultConditionReports[
                        mobileCarInformation.vehicleIdentificationNumber
                      ]
                    }
                    toggleFullConditionReport={toggleConditionReport}
                    selectedVehicleDetails={selectedCar}
                  />
                )}
              </div>
            ) : null}
            {passVehicle ? (
              <PassVehicle togglePass={togglePassVehicle} history={history} />
            ) : null}
            {buyVehicle ? (
              <BuyVehicle
                toggleBuy={toggleBuy}
                page="GAMD"
                conditionReport={
                  searchResultConditionReports[
                    mobileCarInformation
                      ? mobileCarInformation.vehicleIdentificationNumber
                      : selectedCar.vehicleIdentificationNumber
                  ]
                }
              />
            ) : null}
            {ddoBuyVehicle ? (
              <BuyVehicle
                toggleBuy={toggleDDOBuyVehicle}
                page="DDO"
                conditionReport={
                  searchResultConditionReports[
                    mobileCarInformation
                      ? mobileCarInformation.vin
                      : selectedCar.vin
                  ]
                }
                isBuyNow
              />
            ) : null}
            {openBid ? (
              <BidVehicle
                car={selectedCar}
                accountSetting={accountSetting}
                auctionTimes={auctionTimes}
                user={user}
                vehicleBidInformation={vehicleBidInformation}
                handleBidOpen={handleBidOpen}
                updateAutoBid={updateAutoBid}
                conditionReport={
                  searchResultConditionReports[
                    mobileCarInformation
                      ? mobileCarInformation.vin
                      : selectedCar.vin
                  ]
                }
              />
            ) : null}
            {mobileGuarantee && mobileCarInformation ? (
              <GuaranteePayments
                car={mobileCarInformation}
                toggleGuaranteePayment={this.toggleMobileGuarantee}
                user={user}
                isBuyFlow
                updateGuaranteePayment={updateGuaranteePayment}
              />
            ) : null}
            {mobileCarInformation &&
            hideMobileNavMenu &&
            !ddoBuyVehicle &&
            !buyVehicle &&
            !openBid &&
            !passVehicle &&
            !mobileGuarantee ? (
              <MobilePurchaseButtons
                toggleMobileGuarantee={this.toggleMobileGuarantee}
                handleBuyNow={handleBuyNow}
                toggleBuy={toggleBuy}
                handleBidOpen={handleBidOpen}
                togglePass={togglePassVehicle}
                pageFrom={pageFrom}
                user={user}
                car={mobileCarInformation}
              />
            ) : null}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
    auctionTimes: state.ui.auctionTimes,
    currentAuction: state.ui.currentAuction,
    searchResultConditionReports: state.cars.searchResultConditionReports,
    mobileCarInformation: state.cars.mobileCarInformation,
    hideMobileNavMenu: state.ui.hideMobileNavMenu,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SearchResult));
