import moment from "moment-timezone";
const API_URL = process.env.REACT_APP_API_URL;

export const formatPhoneNumber = (string) => {
  if (string !== "") {
    let cleaned = ("" + string).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }
};

export const getDate = () => {
  const d = new Date(Date.now());
  return d.toLocaleString();
};

export const renderTimeRemaining = (time) => {
  const minutesRemaining = Math.floor((time / 60000) % 60);
  const secondsRemaining = Math.floor((time / 1000) % 60);
  return `${minutesRemaining}m ${secondsRemaining}s`;
};

export const returnAccountType = (accountType) => {
  const accountTypes = {
    LEASE: "Off Lease",
    "COMPANY CAR": "Company Car",
    BALLOON: "Balloon",
  };
  return accountTypes[accountType] || null;
};

export const returnAutoGrade = (car) => {
  if (car.inventoryDTO && car.inventoryDTO.inspectionInfoCollectionDTO[0]) {
    return car.inventoryDTO.inspectionInfoCollectionDTO[0].inspectionGrade;
  } else if (car.grade) {
    return car.grade;
  } else {
    return "Not Available";
  }
};

export const setCurrentAuctionTimes = () => {
  const timeZone = "America/New_York";
  const auctionTimes = {
    vehiclePreview: {
      begin: moment({ hour: 0, minutes: 0, seconds: 0 })
        .add(1, "days")
        .tz(timeZone),
      end: moment({ hour: 4, minutes: 45, seconds: 0 }).tz(timeZone),
    },
    preSale1: {
      begin: moment({ hour: 4, minutes: 45, seconds: 0 }).tz(timeZone),
      end: moment({ hour: 5, minutes: 45, seconds: 0 }).tz(timeZone),
    },
    sale1: {
      begin: moment({ hour: 5, minutes: 45, seconds: 0 }).tz(timeZone),
      end: moment({ hour: 6, minutes: 0, seconds: 0 }).tz(timeZone),
    },
    preSale2: {
      begin: moment({ hour: 6, minutes: 0, seconds: 0 }).tz(timeZone),
      end: moment({ hour: 6, minutes: 45, seconds: 0 }).tz(timeZone),
    },
    sale2: {
      begin: moment({ hour: 6, minutes: 45, seconds: 0 }).tz(timeZone),
      end: moment({ hour: 8, minutes: 0, seconds: 0 }).tz(timeZone),
    },
    stringSale1: "2-3pm (EST)",
    stringSale2: "4-5pm (EST)",
  };
  return auctionTimes;
};

export const setCurrentAuctionTimesFromOnDemand = async () => {
  const timeZone = "America/New_York";

  let saleTimes = await getSaleTimes();

  let saleOneStartTime = moment(saleTimes.upstreamStartTime).tz(timeZone);
  let saleOneEndTime = moment(saleTimes.upstreamEndTime).tz(timeZone);
  let saleTwoStartTime = moment(saleTimes.toyotaLexusUpstreamStartTime).tz(
    timeZone
  );
  let saleTwoEndTime = moment(saleTimes.toyotaLexusUpstreamEndTime).tz(
    timeZone
  );

  let vehiclePreviewEndTime = moment(saleTimes.upstreamStartTime)
    .tz(timeZone)
    .subtract(1, "hours");

  let timeFormatString = "h:mm a";

  const auctionTimes = {
    vehiclePreview: {
      begin: moment({ hour: 0, minutes: 0, seconds: 0 })
        .add(1, "days")
        .tz(timeZone),
      end: vehiclePreviewEndTime,
    },
    preSale1: {
      begin: vehiclePreviewEndTime,
      end: saleOneStartTime,
    },
    sale1: {
      begin: saleOneStartTime,
      end: saleOneEndTime,
    },
    preSale2: {
      begin: saleOneEndTime,
      end: saleTwoStartTime,
    },
    sale2: {
      begin: saleTwoStartTime,
      end: saleTwoEndTime,
    },
    stringSale1:
      "" +
      saleOneStartTime.format(timeFormatString) +
      "-" +
      saleOneEndTime.format(timeFormatString) +
      "(EST)",
    stringSale2:
      "" +
      saleTwoStartTime.format(timeFormatString) +
      "-" +
      saleTwoEndTime.format(timeFormatString) +
      "(EST)",
  };

  return auctionTimes;
};

export const getSaleTimes = async () => {
  const token = sessionStorage.getItem("token");

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(
      `${API_URL}/auction/getUpcomingSaleEventTimes`,
      options
    );

    const result = await response.json();

    return result;
  } catch (err) {
    console.log("error calling to get auction times: ", err);
    return false;
  }

  return false;
};

export const stateIdMapping = {
  1: "Alabama",

  2: "Alaska",

  3: "Arizona",

  4: "Arkansas",

  5: "California",

  6: "Colorado",

  7: "Connecticut",

  8: "Delaware",

  9: "Florida",

  10: "Georgia",

  11: "Hawaii",

  12: "Idaho",

  13: "Illinois",

  14: "Indiana",

  15: "Iowa",

  16: "Kansas",

  17: "Kentucky",

  18: "Louisiana",

  19: "Maine",

  20: "Maryland",

  21: "Massachusetts",

  22: "Michigan",

  23: "Minnesota",

  24: "Mississippi",

  25: "Missouri",

  26: "Montana",

  27: "Nebraska",

  28: "Nevada",

  29: "New Hampshire",

  30: "New Jersey",

  31: "New Mexico",

  32: "New York",

  33: "North Carolina",

  34: "North Dakota",

  35: "Ohio",

  36: "Oklahoma",

  37: "Oregon",

  38: "Pennsylvania",

  39: "Rhode Island",

  40: "South Carolina",

  41: "South Dakota",

  42: "Tennessee",

  43: "Texas",

  44: "Utah",

  45: "Vermont",

  46: "Virginia",

  47: "Washington",

  48: "West Virginia",

  49: "Wisconsin",

  50: "Wyoming",

  51: "District of Columbia",
};
