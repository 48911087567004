import {
  LOAD_ACCOUNT_SETTING_SUCCESS,
  LOAD_ACCOUNT_SETTING_FAIL,
  UPDATE_ACCOUNT_SETTING_SUCCESS,
  UPDATE_ACCOUNT_SETTING_FAIL,
} from "../actions/AccountSetting.actions";

export const accountSetting = {};

export default (state = accountSetting, action) => {
  switch (action.type) {
    case LOAD_ACCOUNT_SETTING_SUCCESS:
      return action.accountSetting;
    case LOAD_ACCOUNT_SETTING_FAIL:
      return state;
    case UPDATE_ACCOUNT_SETTING_SUCCESS:
      return {
        ...state,
        firstName: action.accountSetting.firstName,
        lastName: action.accountSetting.lastName,
        selectedLocation: action.accountSetting.selectedLocation,
        userTitle: action.accountSetting.userTitle,
        aaNumber: action.accountSetting.aaNumber,
        selectedTimeZone: action.accountSetting.selectedTimeZone,
        email: action.accountSetting.email,
        phone: action.accountSetting.phone,
        cell: action.accountSetting.cell,
        emailNotificationCheckBox:
          action.accountSetting.emailNotificationCheckBox,
        userId: action.accountSetting.userId,
        selectedEmailOptions: action.accountSetting.selectedEmailOptions,
        dealerExclusivityEmailPreference:
          action.accountSetting.dealerExclusivityEmailPreference,
      };
    case UPDATE_ACCOUNT_SETTING_FAIL:
      return state;
    default:
      return state;
  }
};
