import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Card, Button, Typography } from "@material-ui/core";

import * as ProgramServices from "../services/ProgramServices";
import {
  CANCEL,
  DONE,
  COLON,
  DESCRIPTION,
  DOLLAR_SIGN,
  GUARANTEE,
  GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_1,
  GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_2,
  GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_3,
  GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_4,
  SYSTEM_MESSAGE,
  TOTAL_REMAINING_PAYMENTS,
  VEHICLE_INFO,
  VIN,
} from "../constants/UILabel";

const styles = (theme) => ({
  bold: {
    fontWeight: "bold",
    margin: ".5rem",
  },
  value: {
    color: theme.palette.primary.redHighlight,
  },
  p: {
    margin: ".5rem",
    fontSize: ".8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.font.small,
    },
  },
  card: {
    margin: ".5rem",
  },
  conditionReportCard: {
    margin: ".5rem",
    width: "40.5rem",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    margin: ".5rem",
  },
  button: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "7rem",
    fontWeight: "bold",
  },
  redBoldedText: {
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
  },
});

export class GuaranteePayments extends Component {
  state = {
    errorMessage: "",
    showConfirmation: false,
  };

  componentWillMount = () => {
    this.myRef = createRef();
  };

  componentDidMount = () => {
    if (this.myRef.current) {
      this.myRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  handleGuarantee = async () => {
    const { car, outStandingPayments, waivedPayments } = this.props;
    await ProgramServices.guarnteePaymentDone(
      car.id,
      waivedPayments.toFixed(2),
      outStandingPayments.toFixed(2)
    );
    this.setState({
      showConfirmation: !this.state.showConfirmation,
    });
  };

  render() {
    const {
      car,
      classes,
      toggleGuaranteePayment,
      toggleGuaranteePaymentDone,
      from,
      handleGuarantee,
      showConfirmationForGP,
    } = this.props;
    const { showConfirmation } = this.state;
    return (
      <Card className={classes.card} ref={this.myRef}>
        <Typography className={classes.p}>
          <strong>{VEHICLE_INFO}</strong>
        </Typography>
        <Typography className={classes.p}>
          <strong>
            {" "}
            {VIN}
            {COLON}
          </strong>{" "}
          {car.vehicleIdentificationNumber}
        </Typography>
        <Typography className={classes.p}>
          <strong>{DESCRIPTION}</strong> {car.year} {car.make} {car.model}
        </Typography>
        {!showConfirmation &&
        !(showConfirmationForGP && showConfirmationForGP === true) ? (
          <div>
            <>
              <Typography className={classes.p}>
                <strong>
                  {GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_1}{" "}
                  <span className={classes.redBoldedText}>
                    {DOLLAR_SIGN}
                    {car.remainingPaymentsAmt.toLocaleString()}
                  </span>{" "}
                  {GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_2}
                </strong>
              </Typography>
              <Typography className={classes.p}>
                {GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_3}
              </Typography>
              <div className={classes.buttonWrapper}>
                {from === "GAMDLPWForm" ? null : (
                  <Button
                    className={classes.button}
                    id="cancelButton"
                    onClick={toggleGuaranteePayment}
                  >
                    {CANCEL}
                  </Button>
                )}
                <Button
                  className={classes.button}
                  id="guaranteeButton"
                  onClick={() =>
                    handleGuarantee !== undefined
                      ? handleGuarantee()
                      : this.handleGuarantee()
                  }
                >
                  {GUARANTEE}
                </Button>
              </div>
            </>
          </div>
        ) : (
          <div>
            <Typography className={classes.p}>
              <strong>{SYSTEM_MESSAGE}</strong> {TOTAL_REMAINING_PAYMENTS}{" "}
              <span className={classes.redBoldedText}>
                {DOLLAR_SIGN}
                {car.remainingPaymentsAmt.toLocaleString()}
              </span>{" "}
              {GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_4}
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                id="doneButton"
                onClick={() =>
                  toggleGuaranteePaymentDone === undefined
                    ? toggleGuaranteePayment()
                    : toggleGuaranteePaymentDone()
                }
              >
                {DONE}
              </Button>
            </div>
          </div>
        )}
      </Card>
    );
  }
}

GuaranteePayments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(GuaranteePayments));
