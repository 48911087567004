import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { cancelPurchase } from "../services/VehiclePurchaseServices";

import { remove_purchased_vehicle } from "../actions/uiActions";

const styles = (theme) => ({
  buyWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  vehicleInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "2rem",
  },
  h1Padding: {
    padding: ".5rem",
  },
  card: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardTitle: {
    color: theme.palette.GAMD.main,
    fontWeight: "bold",
    fontSize: ".8rem",
  },
  DDOCardTitle: {
    color: theme.palette.DDO.main,
    fontWeight: "bold",
    fontSize: ".8rem",
  },
  cardContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    height: "10rem",
    padding: 0,
    borderTop: `3px solid ${theme.palette.secondary.gray}`,
  },
  progressBarWrapper: {
    display: "flex",
  },
  circularProgress: {
    margin: ".5rem",
    height: "8rem",
  },
  circularProgressBar: {
    width: "7rem",
  },
  timeDisplay: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    color: theme.palette.primary.gray,
  },
  interiorText: {
    fontSize: ".8rem",
    marginBottom: "1rem",
    color: theme.palette.primary.gray,
  },
  confirmWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  confirmMessage: {
    fontSize: ".8rem",
    width: "21rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "16rem",
    },
  },
  GAMDButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: 0,
    },
  },
  GAMDCancelButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: 0,
      backgroundColor: theme.palette.secondary.gray,
    },
  },
  DDOButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.DDO.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: 0,
    },
  },
  DDOCancelButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.DDO.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: 0,
      backgroundColor: theme.palette.secondary.gray,
    },
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  pendingCancelButtonWrapper: {
    width: "100%",
    display: "inline-block",
    justifyContent: "center",
  },
  pendingCancelButton: {
    paddingLeft: "35%",
  },

  timeExipredButtonWrapper: {
    textAlign: "center",
  },
});

export class CancelConfirm extends Component {
  state = {
    purchaseCanceled: false,
    isPendingCancel: false,
    isConfirmDisabled: false,
    isTimeExipred: false,
  };

  handleCancel = async () => {
    const { dispatch, car, user, cancelTickDown, cancelTimeout } = this.props;
    this.setState({
      isConfirmDisabled: true,
    });

    const response = await cancelPurchase(car, user, true);

    if (null === response) {
      this.setState({
        isConfirmDisabled: false,
      });
    }

    if (response === "Your time to cancel the purchase has expired.") {
      this.setState({
        isTimeExipred: true,
        purchaseCanceled: false,
        isConfirmDisabled: true,
        isPendingCancel: false,
      });
      return;
    }

    if (response === "Success.") {
      dispatch(remove_purchased_vehicle(car.vehicleIdentificationNumber));
      cancelTickDown();
      cancelTimeout();
      this.setState({
        purchaseCanceled: !this.state.purchaseCanceled,
        isConfirmDisabled: true,
        isPendingCancel: false,
      });
      return;
    }

    if (response === "Pending") {
      cancelTickDown();
      cancelTimeout();
      this.setState({
        isPendingCancel: true,
        isConfirmDisabled: false,
      });
      return;
    }
  };

  handleClose = () => {
    const { page, toggleBuy, toggleBuyVehicle, ground, car } = this.props;
    (page === "GAMD" || page === "DDO") && !ground
      ? toggleBuy(car, false)
      : toggleBuyVehicle();
  };

  timeRemaining = () => {
    const { timeRemainingToCancel } = this.props;
    const minutes = timeRemainingToCancel / 60000;
    const rminutes = Math.floor(minutes);
    const seconds = (minutes - rminutes) * 60;
    const rseconds = Math.round(seconds);
    return { minutes: rminutes, seconds: rseconds };
  };

  render() {
    const { classes, toggleCancelConfirm, page } = this.props;
    const {
      purchaseCanceled,
      isPendingCancel,
      isConfirmDisabled,
      isTimeExipred,
    } = this.state;
    const timeRemaining = this.timeRemaining();
    const progressStyles =
      page === "GAMD"
        ? {
            path: {
              stroke: "#E4B042",
            },
            trail: {
              stroke: "#DEE0E2",
            },
          }
        : {
            path: {
              stroke: "#3F853E",
            },
            trail: {
              stroke: "#DEE0E2",
            },
          };

    return (
      <React.Fragment>
        <div className={classes.vehicleInfoWrapper}>
          {!purchaseCanceled && !isPendingCancel && !isTimeExipred ? (
            <div className={classes.card}>
              <div className={classes.progressBarWrapper}>
                <CircularProgressbarWithChildren
                  styles={progressStyles}
                  value={(timeRemaining.minutes / 5) * 100}
                  className={classes.circularProgressBar}
                >
                  <Typography className={classes.timeDisplay}>
                    {timeRemaining.minutes}
                  </Typography>
                  <Typography className={classes.interiorText}>
                    minutes
                  </Typography>
                </CircularProgressbarWithChildren>
                <CircularProgressbarWithChildren
                  styles={progressStyles}
                  value={(timeRemaining.seconds / 60) * 100}
                  className={classes.circularProgressBar}
                >
                  <Typography className={classes.timeDisplay}>
                    {timeRemaining.seconds}
                  </Typography>
                  <Typography className={classes.interiorText}>
                    seconds
                  </Typography>
                </CircularProgressbarWithChildren>
              </div>
              <Typography
                className={
                  page === "GAMD" ? classes.cardTitle : classes.DDOCardTitle
                }
              >
                {"Time Remaining to Cancel"}
              </Typography>
            </div>
          ) : null}
          {isPendingCancel && !isTimeExipred ? (
            <div className={classes.pendingCancelButtonWrapper}>
              <Typography>
                Your purchase cancellation cannot be completed at this time.
                <br />
                Please wait 30 seconds before attempting to cancel your purchase
                of this vehicle.
              </Typography>

              <div className={classes.pendingCancelButton}>
                <Button
                  id="backButton"
                  onClick={() => toggleCancelConfirm()}
                  className={
                    page === "GAMD"
                      ? classes.GAMDCancelButton
                      : classes.DDOCancelButton
                  }
                >
                  Return
                </Button>
              </div>
            </div>
          ) : (
            <div>
              {purchaseCanceled && !isTimeExipred ? (
                <div className={classes.confirmWrapper}>
                  <Typography className={classes.confirmMessage}>
                    Your purchase has been cancelled.
                  </Typography>
                  <br />
                  <Button
                    id="close"
                    onClick={() => this.handleClose()}
                    className={
                      page === "GAMD" ? classes.GAMDButton : classes.DDOButton
                    }
                  >
                    Close
                  </Button>
                </div>
              ) : (
                <div>
                  {" "}
                  {isTimeExipred ? (
                    <div className={classes.pendingCancelButtonWrapper}>
                      <div className={classes.timeExipredButtonWrapper}>
                        <Typography>
                          Your time to cancel the purchase has expired.
                          <br />
                        </Typography>
                      </div>
                      <div className={classes.pendingCancelButton}>
                        <Button
                          id="backButton"
                          onClick={() => toggleCancelConfirm()}
                          className={
                            page === "GAMD"
                              ? classes.GAMDCancelButton
                              : classes.DDOCancelButton
                          }
                        >
                          Return
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.confirmWrapper}>
                      <Typography className={classes.confirmMessage}>
                        Please confirm that you would like to cancel the
                        purchase of this vehicle.
                      </Typography>
                      <div className={classes.buttonWrapper}>
                        <Button
                          id="cancelConfirm"
                          onClick={() => this.handleCancel()}
                          className={
                            page === "GAMD"
                              ? classes.GAMDButton
                              : classes.DDOButton
                          }
                          disabled={isConfirmDisabled}
                        >
                          Confirm
                        </Button>
                        <Button
                          id="backButton"
                          onClick={() => toggleCancelConfirm()}
                          className={
                            page === "GAMD"
                              ? classes.GAMDCancelButton
                              : classes.DDOCancelButton
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

CancelConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    cancelTimers: state.ui.cancelTimers,
    user: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(CancelConfirm))
);
