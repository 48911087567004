import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  TextField,
  Typography,
  FormControl,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import DealerLocation from "../components/DealerLocation";
import * as LoginServices from "../services/LoginServices";
import * as Actions from "../actions/AccountSetting.actions";
import * as AdminService from "../services/AdminServices";

const styles = (theme) => ({
  container: {
    width: "auto",
    height: "90%",
  },
  inputLabel: {
    fontSize: ".8rem",
    padding: ".4rem",
    textAlign: "center",
    alignItems: "center",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.gray,
    width: "7rem",
    display: "inline-block",
    marginTop: ".5rem",
  },
  preferenceContainer: {
    display: "flex",
    flexDirection: "column",
  },
  inputField: {
    fontSize: ".8rem",
    padding: ".6rem",
    display: "inline-block",
    alignItems: "center",
    width: "11rem",
  },
  selectInput: {
    fontSize: ".8rem",
    width: "12.3rem",
    textAlign: "center",
  },
  emailSettings: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
  },
  textField: {
    width: "15rem",
  },
  fieldWrapper: {
    display: "flex",
    width: "20rem",
    height: "2rem",
    margin: ".5rem",
  },
  fieldLabel: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.white,
    height: "2rem",
    width: "7rem",
    fontSize: ".75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.gray,
    fontSize: theme.font.small,
    fontWeight: "bold",
    width: "4rem",
    color: theme.palette.primary.white,
  },
  buttonWrapper: {
    display: "flex",
    margin: "1rem",
    width: "10rem",
    justifyContent: "space-around",
    position: "absolute",
    left: "42%",
  },
  formControl: {
    display: "flex",
    height: "2rem",
    flexDirection: "row",
    width: "20rem",
    margin: ".5rem",
  },
  systemMessage: {
    marginTop: "1rem",
    marginLeft: ".5rem",
    fontSize: theme.font.small,
  },
  columnContainer: {
    display: "flex",
    flexDireciton: "column",
  },
  "@global": {
    "label + .MuiInput-formControl": {
      marginTop: 0,
      width: "12rem",
    },
  },
});

export class EditVehicleLocation extends Component {
  state = {
    locationName: "",
    address: "",
    vin: "",
    instruction: "",
    selectedLocationId: "",
    addNewVehicle: false,
  };

  componentDidMount = () => {
    const { location, accountSetting } = this.props;
    const currentLocation = accountSetting.custLocations.find(
      (item) => item.locationDTO.name === location.locationName
    );

    this.setState({
      vin: location.vin,
      locationName: location.locationName,
      groundingDate: location.groundingDate,
      dealershipId: location.dealershipId,
      editSpecialInstructions: location.editSpecialInstructions,
      address1: currentLocation.locationDTO.addressDTO.addressLine1,
      city: currentLocation.locationDTO.addressDTO.city,
      state: currentLocation.locationDTO.addressDTO.state,
      zip: currentLocation.locationDTO.addressDTO.zip,
      selectedLocationId: location.locationId,
    });
  };

  populateLocations = () => {
    const { accountSetting } = this.props;
    if (accountSetting.custLocations[0]) {
      return accountSetting.custLocations.map((custLocation) => {
        return (
          <MenuItem
            key={custLocation.locationId}
            value={custLocation.locationId}
          >
            <Typography>{custLocation.locationDTO.name}</Typography>
          </MenuItem>
        );
      });
    } else {
      return (
        <MenuItem value="">
          <em>'Address Not Available'</em>
        </MenuItem>
      );
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleLocation = (event) => {
    if (event.target.value) {
      this.setState({
        selectedLocationId: event.target.value,
      });
    } else {
      this.setState({ addNewVehicle: true });
    }
  };

  cancelAddLocation = () => {
    this.setState({ addNewVehicle: false });
  };

  updateAccountSettings = async () => {
    const { dispatch } = this.props;
    const { user } = this.props;
    const accountSetting = await LoginServices.loadAccountSetting(user.userId);
    dispatch(Actions.loadAccountSettingSuccess(accountSetting));
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const response = await AdminService.updateVehicleLocation(this.state);
    if (response === "success") {
      await this.updateAccountSettings();
      this.props.cancelEditLocation();
    } else {
      alert("Service unavailable. Please try again later.");
      this.setState({
        errorMessage: "Service unavailable. Please try again later.",
      });
    }
  };

  render() {
    const { classes, accountSetting, cancelEditLocation } = this.props;
    const {
      addNewVehicle,
      address1,
      city,
      state,
      zip,
      selectedLocationId,
      vin,
      editSpecialInstructions,
    } = this.state;
    const InputProps = {
      disableUnderline: true,
      style: {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },
      inputProps: {
        style: {
          padding: 5,
          height: "100%",
        },
      },
    };
    return (
      <div className={classes.container}>
        {addNewVehicle && (
          <DealerLocation
            id="add_location"
            addLocation={true}
            timeZoneOptions={accountSetting.timeZoneOptions}
            cancelEditLocation={this.cancelAddLocation}
          />
        )}
        {!addNewVehicle && (
          <React.Fragment>
            <Typography variant="h5" component="h3">
              Edit Vehicle Location
            </Typography>
            <form id="form" className={classes.form}>
              <div className={classes.columnContainer}>
                <div className={classes.preferenceContainer}>
                  <div className={classes.fieldWrapper}>
                    <Typography className={classes.fieldLabel}>
                      <strong>VIN</strong>
                    </Typography>
                    <TextField
                      id="vin"
                      className={classes.textField}
                      value={vin}
                      variant="filled"
                      autoComplete="off"
                      name="vin"
                      InputProps={InputProps}
                      disabled
                    />
                  </div>
                  <div>
                    <FormControl className={classes.formControl}>
                      <Typography
                        className={classes.fieldLabel}
                        style={{ width: "6.4rem" }}
                      >
                        <strong>Location</strong>
                      </Typography>
                      <Select
                        id="location-select"
                        value={selectedLocationId}
                        onChange={(evt) => this.handleLocation(evt)}
                        style={{
                          textAlign: "center",
                          width: "12rem",
                        }}
                      >
                        {this.populateLocations()}
                        <MenuItem id="add-vehicle-location" value="">
                          <Typography>Add Vehicle Location</Typography>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    className={classes.fieldWrapper}
                    style={{ width: "40rem" }}
                  >
                    <Typography className={classes.fieldLabel}>
                      <strong>Street Address</strong>
                    </Typography>
                    <TextField
                      id="address1"
                      placeholder="Required Field"
                      className={classes.textField}
                      value={`${address1}, ${city} ${state} ${zip}`}
                      variant="filled"
                      autoComplete="off"
                      name="address1"
                      InputProps={InputProps}
                      autoFocus
                      required
                      style={{ width: "35rem" }}
                      disabled
                    />
                  </div>
                  <div
                    className={classes.fieldWrapper}
                    style={{ width: "40rem" }}
                  >
                    <Typography
                      className={classes.fieldLabel}
                      style={{ width: "13rem" }}
                    >
                      <strong>Special Grounding Instructions</strong>
                    </Typography>
                    <TextField
                      id="editSpecialInstructions"
                      className={classes.textField}
                      onChange={this.handleChange("editSpecialInstructions")}
                      value={editSpecialInstructions}
                      multiline
                      rows="4"
                      rowsMax="4"
                      variant="filled"
                      autoComplete="off"
                      name="editSpecialInstructions"
                      InputProps={InputProps}
                      style={{ width: "27rem" }}
                    />
                  </div>
                </div>
              </div>
              <Typography className={classes.systemMessage}>
                *Please note that any modifications to the instructions above
                will replace all previous instructions.
              </Typography>
              <div className={classes.buttonWrapper}>
                <Button
                  id="submitButton"
                  variant="contained"
                  className={classes.button}
                  onClick={(evt) => this.handleSubmit(evt)}
                >
                  Update
                </Button>
                <Button
                  id="cancelButton"
                  variant="contained"
                  onClick={cancelEditLocation}
                  className={classes.button}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </React.Fragment>
        )}
      </div>
    );
  }
}

EditVehicleLocation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles)(EditVehicleLocation)
);
