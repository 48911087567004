import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Paper, TextField, Button } from "@material-ui/core";

import renderHTML from "react-render-html";
import * as ProgramServices from "../services/ProgramServices";
import {
  CANCEL,
  COMMENTS,
  ENTER_TEXT,
  GUARANTEE_PAYMENTS,
  INVALID_PROGRAM_CERTIFICATE,
  PAYMENT_WAIVER_ACCEPTANCE,
  PROGRAM_CERT,
  PROGRAM_CERTIFICATE,
  YES,
  NO,
  VERIFY,
} from "../constants/UILabel";

const styles = (theme) => ({
  label: {
    margin: "1rem",
    marginRight: "0",
    fontWeight: "bold",
  },
  labelText: {
    fontWeight: "bold",
  },
  vehicleInfoContainer: {
    width: "auto",
    display: "block",
    margin: ".5rem",
    textAlign: "center",
  },
  vehicleInfoVINEntryContainer: {
    margin: ".5rem",
    display: "inline-block",
    width: "auto",
    marginTop: ".5rem",
  },
  infoParentWrapper: {
    margin: "1rem",
  },
  infoWrapper: {
    margin: "1rem",
  },
  h1Padding: {
    margin: "1rem",
  },
  maxCharLimit: {
    display: "inline",
    color: "#CC0000",
  },
  paper: {
    paddingTop: ".5rem",
    alignItems: "center",
    width: "90%",
    borderRadius: 0,
    margin: "auto",
    fontSize: ".8rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  formControl: {
    minWidth: 200,
  },
  tableCellBorder: {
    borderBottom: 0,
  },
  messageContainer: {
    alignItems: "center",
    width: "80%",
    margin: "auto",
  },
  paperWrapper: {
    alignItems: "center",
    margin: "auto",
  },
  programInfoHighlight: {
    display: "inline",
    color: "#CC0000",
  },
  textField: {
    marginLeft: ".5rem",
    marginRight: ".5rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      fontSize: ".5rem",
    },
  },
  textfieldWrapper: {
    margin: "auto",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  buttonWrapper: {
    textAlign: "right",
  },
  button: {
    margin: ".5rem",
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "100px",
  },
  gpWrapper: {
    margin: ".5rem",
  },
});

export class LPWEntryForm extends Component {
  state = {
    enteredCertNumberValid: false,
    showInvalidCertError: false,
    noToGuarantee: false,
    isProgramCertValid: false,
    badCirtValid: false,
    userAgreed: false,
    showYesNoDropdown: false,
  };

  removeDiv = (text) => {
    let newText = text.replace("<div>", "");
    return newText.replace("</div>", "");
  };

  removeSpan = (text) => {
    let newText = text.replace('<span style="font-size: 11.2px;">', "");
    return newText.replace("</span>", "");
  };

  onVerifyCertNumber = async () => {
    const { programCertNum, vinDetails, cancelLPW } = this.props;
    if (
      programCertNum !== "" &&
      programCertNum === vinDetails.programDTOs[0].certificateNumber
    ) {
      if (vinDetails.programDTOs[0].isDealerGuaranteed) {
        this.setState({
          isProgramCertValid: true,
          showYesNoDropdown: true,
          badCirtValid: false,
          noToGuarantee: false,
        });
      } else {
        this.validateCertificate();
      }
    } else {
      this.setState({
        isProgramCertValid: false,
        badCirtValid: true,
        noToGuarantee: false,
      });
      cancelLPW();
    }
  };

  validateCertificate = async () => {
    const { handleCertValidationChange, vinDetails } = this.props;
    const { remainingPaymentAmount } =
      this.props.vinDetails.inventoryDTO.leaseInformationDTO;
    const waivedAndOutstandingAmounts =
      await ProgramServices.calculateWaivedAndOutstandingAmounts(
        vinDetails.inventoryDTO.id,
        vinDetails.programDTOs[0].numberOfPaymentsWaived
      );
    handleCertValidationChange(
      waivedAndOutstandingAmounts.outStandingPayments,
      waivedAndOutstandingAmounts.waivedPayments > remainingPaymentAmount
        ? remainingPaymentAmount
        : waivedAndOutstandingAmounts.waivedPayments,
      waivedAndOutstandingAmounts.outStandingPayments > 0
        ? vinDetails.programDTOs[0].isDealerGuaranteed
        : false
    );
    this.setState({
      isProgramCertValid: true,
      badCirtValid: false,
      noToGuarantee: false,
      userAgreed: true,
      showYesNoDropdown: false,
    });
  };

  onFocusClear = () => {
    this.setState({
      showInvalidCertError: false,
    });
  };

  handleAgreedToGuaranteedPayment = async (agreed) => {
    const { cancelLPW } = this.props;
    if (agreed) {
      this.validateCertificate();
    } else {
      this.setState({
        isProgramCertValid: false,
        noToGuarantee: true,
        userAgreed: false,
      });
      cancelLPW();
    }
  };

  handleAgreedToGuaranteedPaymentCancel = () => {
    const { cancelLPW } = this.props;
    this.setState({
      isProgramCertValid: false,
      badCirtValid: false,
      noToGuarantee: false,
      userAgreed: false,
    });
    cancelLPW();
  };

  render() {
    const {
      classes,
      onHandleChange,
      programDTOInfo,
      programCertNum,
      programCertComments,
      certVerifyMessage,
      isProgramCertValidated,
    } = this.props;
    const {
      showInvalidCertError,
      noToGuarantee,
      badCirtValid,
      isProgramCertValid,
      showYesNoDropdown,
    } = this.state;

    return (
      <Paper className={classes.paper}>
        {isProgramCertValid === true || isProgramCertValidated ? (
          <div>
            {showYesNoDropdown ? (
              <div className={classes.gpWrapper}>
                <h2 className={classes.h1Padding}> {GUARANTEE_PAYMENTS} </h2>
                <div> {PAYMENT_WAIVER_ACCEPTANCE} </div>
                <div> {certVerifyMessage} </div>
                <div> {PROGRAM_CERT.DO_YOU_WANT_TO_CONTINUE} </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    id="yes"
                    className={classes.button}
                    onClick={(evt) =>
                      this.handleAgreedToGuaranteedPayment(true)
                    }
                  >
                    {YES}
                  </Button>
                  <Button
                    id="no"
                    className={classes.button}
                    onClick={(evt) =>
                      this.handleAgreedToGuaranteedPayment(false)
                    }
                  >
                    {NO}
                  </Button>
                  <Button
                    id="cancel"
                    className={classes.button}
                    onClick={(evt) =>
                      this.handleAgreedToGuaranteedPaymentCancel()
                    }
                  >
                    {CANCEL}
                  </Button>
                </div>
              </div>
            ) : (
              <div className={classes.gpWrapper}>
                <div>
                  {" "}
                  {PROGRAM_CERT.PROGRAM_CERTIFICATE_NUMBER} {programCertNum}{" "}
                  {PROGRAM_CERT.ADDED_TO_THE_VEHICLE}{" "}
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    id="cancel"
                    className={classes.button}
                    onClick={(evt) =>
                      this.handleAgreedToGuaranteedPaymentCancel()
                    }
                  >
                    {CANCEL}
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <h2 className={classes.h1Padding}>Attention:</h2>
            <div className={classes.infoWrapper}>
              <h3>
                {PROGRAM_CERT.VEHICLE_AVAILABLE_FOR}{" "}
                <div className={classes.programInfoHighlight}>
                  {programDTOInfo.programName}
                </div>
              </h3>
              <div>
                {renderHTML(programDTOInfo.contentManagementDTO.content)}
              </div>
            </div>
            <div className={classes.textfieldWrapper}>
              <TextField
                inputProps={{ maxlength: 250 }}
                id="programCertComments"
                label={COMMENTS}
                placeholder={ENTER_TEXT}
                multiline
                rows="3"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={onHandleChange("programCertComments")}
                value={programCertComments}
              />
              <div className={classes.maxCharLimit}>
                &nbsp; {PROGRAM_CERT.MAX_CHARACTER_ALLOWED}
              </div>
            </div>
            <div className={classes.textfieldWrapper}>
              <TextField
                id="programCertNum"
                label={
                  showInvalidCertError
                    ? INVALID_PROGRAM_CERTIFICATE
                    : PROGRAM_CERTIFICATE
                }
                placeholder={ENTER_TEXT}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={onHandleChange("programCertNum")}
                value={programCertNum}
                error={showInvalidCertError}
                onFocus={this.onFocusClear}
              />
            </div>
            <div className={classes.textfieldWrapper}>
              <div className={classes.programInfoHighlight}>
                {badCirtValid ? PROGRAM_CERT.VERIFY_THE_CERT_NUMBER : null}
                {noToGuarantee ? PROGRAM_CERT.GUARANTEE_PAYMENT_WAIVER : null}
              </div>
            </div>
            <div className={classes.textfieldWrapper}>
              <div className={classes.buttonWrapper}>
                <Button
                  id="addLocationBtn"
                  className={classes.button}
                  onClick={(evt) => this.onVerifyCertNumber()}
                >
                  {VERIFY}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Paper>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(LPWEntryForm));
