import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;

export async function getVehicleDetails(inventoryId) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/admin/vehicledetail?inventoryId=${inventoryId}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return { message: err, status: false };
  }
}

export async function getCurrentUsers(customerId, custLocations) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      customerId: customerId,
    }),
  };
  try {
    const response = await fetch(`${API_URL}/admin/currentusers`, options);
    if (response.status === 200) {
      let responseData = await response.json();
      updateCurrentUserResponse(responseData, custLocations);
      return responseData;
    } else {
      return null;
    }
  } catch (err) {
    return { message: err, status: false };
  }
}

export async function updateAccountsettings(payload) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_URL}/admin/updateuser`, options);
  try {
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function addNewUser(payload) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_URL}/admin/adduser`, options);
  try {
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export async function getVehicleLocation(vin, dealerId) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      vin: vin,
      dealershipId: dealerId,
    }),
  };

  const response = await fetch(`${API_URL}/admin/getVehicleLocation`, options);
  try {
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function updateDealerLocation(location, user) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId: +user.userId,
      loginId: user.loginId,
      dealerId: location.dealerId,
      locationName: location.locationName,
      address1: location.address1,
      address2: location.address2,
      city: location.city,
      zip: location.zip,
      phone: location.phone,
      fax: location.fax,
      timeZoneId: location.timeZoneId,
      stateId: location.stateId,
      contactName1: location.contactName1,
      contactPhone1: location.contactPhone1,
      contactEmail1: location.contactEmail1,
      contactName2: location.contactName2,
      contactPhone2: location.contactPhone2,
      contactEmail2: location.contactEmail2,
      errorMessage: location.errorMessage,
      isPrimary: location.isPrimary,
    }),
  };

  const response = await fetch(`${API_URL}/admin/updatelocation`, options);
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
}

export async function updateVehicleLocation(vehicle) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      vin: vehicle.vin,
      locationId: vehicle.selectedLocationId,
      locationName: vehicle.locationName,
      dealershipId: vehicle.dealershipId,
      editSpecialInstructions: vehicle.editSpecialInstructions,
    }),
  };

  const response = await fetch(
    `${API_URL}/admin/updateVehicleLocation`,
    options
  );
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
}

export async function updateDealerExclusivity(dealerId, selectedPeriod) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(
      `${API_URL}/admin/updateExclusivityTime?dealershipId=${dealerId}&period=${selectedPeriod}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export async function getPaymentSetup(userId, dealerId) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(
      `${API_URL}/admin/getPaymentSetup?userId=${userId}&dealerId=${dealerId}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export async function updatePaymentSetup(user, data) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId: +user.userId,
      dealerId: +user.dealerId,
      bankName: data.bankName,
      accountNumber: data.accountNumber,
      routingNumber: data.routingNumber,
      defaultPaymentOption: data.defaultPaymentOption,
      defaultPaymentOptionId: data.defaultPaymentOptionId,
    }),
  };

  try {
    const response = await fetch(
      `${API_URL}/admin/updatePaymentSetup`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export async function getArbitration(
  dealerId,
  vin,
  arbitrationStatus,
  arbitrationFromDate,
  arbitrationToDate,
  purchaseFromDate,
  purchaseToDate
) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      vin: vin ? vin : null,
      arbitrationStatus: arbitrationStatus,
      arbitrationFromDate: arbitrationFromDate
        ? moment(arbitrationFromDate).format("MM/DD/YYYY")
        : null,
      arbitrationToDate: arbitrationToDate
        ? moment(arbitrationToDate).format("MM/DD/YYYY")
        : null,
      purchaseFromDate: purchaseFromDate
        ? moment(purchaseFromDate).format("MM/DD/YYYY")
        : null,
      purchaseToDate: purchaseToDate
        ? moment(purchaseToDate).format("MM/DD/YYYY")
        : null,
      dealerId: +dealerId,
    }),
  };

  try {
    const response = await fetch(`${API_URL}/admin/getArbitration`, options);
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export async function getArbitrationDetail(arbitrationId, vin) {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      arbitrationId,
      vin,
    }),
  };

  try {
    const response = await fetch(
      `${API_URL}/admin/getArbitrationDetail`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
export const createArbitration = async (data) => {
  const token = sessionStorage.getItem("token");
  const {
    userId,
    dealerContactName,
    dealerEmailId,
    dealerPhoneNum,
    description,
    selectedFile,
    perSelectedLocOption,
    addressId,
    locationId,
    locationName,
    addressLine1,
    city,
    state,
    stateCode,
    zip,
    arbitrationType,
    arbitrationMultiTypeId,
    arbReqType,
    locDealerContactName,
    addressLine2,
    locDealerPhoneNum,
    selectedVehicleLocation,
    vin,
    invalidArbWarningAccepted,
  } = data;
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("vin", vin);
  formData.append("invalidArbWarningAccepted", invalidArbWarningAccepted);
  formData.append("dealerContactName", dealerContactName);
  formData.append("dealerEmailId", dealerEmailId);
  formData.append("dealerPhoneNum", dealerPhoneNum);
  formData.append("perSelectedLocOption", perSelectedLocOption);
  formData.append("isVehicleAtYourLocation", perSelectedLocOption === 1);
  if (perSelectedLocOption !== 0) {
    if (perSelectedLocOption !== 2) {
      formData.append(
        "addressId",
        addressId
          ? addressId
          : selectedVehicleLocation.locationDTO.addressDTO.id
      );
    }
    formData.append(
      "locationId",
      locationId ? locationId : selectedVehicleLocation.locationDTO.id
    );
  }
  formData.append(
    "locationName",
    locationName ? locationName : selectedVehicleLocation.locationDTO.name
  );
  formData.append(
    "addressLine1",
    addressLine1
      ? addressLine1
      : selectedVehicleLocation.locationDTO.addressDTO.addressLine1
  );
  formData.append("addressLine2", addressLine2);
  if (perSelectedLocOption === 0) {
    formData.append("locDealerContactName", locDealerContactName);
    formData.append("locDealerPhoneNum", locDealerPhoneNum);
  }
  formData.append(
    "city",
    city ? city : selectedVehicleLocation.locationDTO.addressDTO.city
  );
  formData.append(
    "state",
    state ? state : selectedVehicleLocation.locationDTO.addressDTO.state
  );
  formData.append(
    "stateCode",
    stateCode
      ? stateCode
      : selectedVehicleLocation.locationDTO.addressDTO.stateCode
  );
  formData.append(
    "zip",
    zip ? zip : selectedVehicleLocation.locationDTO.addressDTO.zip
  );
  formData.append("arbitrationTypeId", arbitrationMultiTypeId[0]);
  formData.append("arbitrationType", arbitrationType);
  formData.append("arbitrationMultiTypeId", arbitrationMultiTypeId);
  formData.append("arbReqType", arbReqType);
  formData.append("description", description);
  if (selectedFile) {
    formData.append("file", selectedFile);
  }
  const options = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(`${API_URL}/admin/createArbitration`, options);
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
};

export const updateArbitration = async (data) => {
  const token = sessionStorage.getItem("token");
  const {
    userId,
    arbitrationId,
    dealerContactName,
    dealerEmailId,
    dealerPhoneNum,
    description,
    selectedFile,
    cancelArbitration,
  } = data;
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("arbitrationId", arbitrationId);
  formData.append("dealerContactName", dealerContactName);
  formData.append("dealerEmailId", dealerEmailId);
  formData.append("dealerPhoneNum", dealerPhoneNum);
  formData.append("description", description);
  if (selectedFile) {
    formData.append("file", selectedFile);
  }
  if (cancelArbitration) {
    formData.append("cancelArbitration", cancelArbitration);
  }
  const options = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${API_URL}/admin/updateArbitrationDetail`,
    options
  );
  if (response.status === 200) {
    return response.json();
  } else {
    return null;
  }
};

export const getArbitrationTypes = async () => {
  const token = sessionStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/admin/getArbitrationTypes`,
      options
    );
    if (response.status === 200) {
      return response.json();
    }
  } catch (err) {
    return null;
  }
};

function updateCurrentUserResponse(data, custLocations) {
  data.map((rowData) => {
    rowData.firstName = rowData.userDetails.split(",")[0].split(":")[1].trim();
    rowData.lastName = rowData.userDetails.split(",")[1].split(":")[1].trim();
    rowData.status = !rowData.isDisabledUser
      ? rowData.dealerAdmin
        ? "Dealer Admin"
        : "Active"
      : "Inactive";
    rowData.location = custLocations.find(
      (item) => item.locationId === Number(rowData.dealerId)
    ).locationDTO.name;
    rowData.roles = [];
    rowData.userRoles.map((item) => {
      switch (item) {
        case "DLR_VIEW_ONLY":
          return rowData.roles.push("Allow user to view only");
        case "BUYER":
          return rowData.roles.push("Allow user to purchase vehicles");
        case "GROUNDING_CLERK":
          return rowData.roles.push("Allow user to ground vehicles");
        case "OPEN_PROCESS_ARBITRATION":
          return rowData.roles.push("Allow user to open/process Arbitration");
        case "ACH_BANK_INFO":
          return rowData.roles.push("ACH/Bank Information");
        case "DEALERSHIP_ADMIN":
          return rowData.roles.push("Full Admin Access");
        default:
          return null;
      }
    });
    return null;
  });
}
