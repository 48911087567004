import { encode } from "base-64";
const API_URL = process.env.REACT_APP_MOBILITY_API;
const username = process.env.REACT_APP_MOBILITY_USERNAME;
const password = process.env.REACT_APP_MOBILITY_PASSWORD;

export async function loadSavedSearch(userId) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + encode(username + ":" + password),
    },
  };

  try {
    const response = await fetch(
      `${API_URL}/mobilityRideshare/fetchAllSavedSearch`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return {
        status: response.status,
        message: "The server is having issues, please try again later.",
      };
    }
  } catch (err) {
    return {
      status: false,
      message: err.message,
    };
  }
}

export async function saveOrUpdateSearch(data) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
      Authorization: "Basic " + encode(username + ":" + password),
    },
    body: JSON.stringify({
      searchName: data.searchName,
      programType: data.programType,
      saleEventName: data.saleEventName,
      searchFrequency: data.searchFrequency,
      searchStartDate: data.searchStartDate,
      searchEndDate: data.searchEndDate,
      userId: data.userId,
      searchCriteria: data.searchCriteria,
    }),
  };
  try {
    const response = await fetch(
      `${API_URL}/mobilityRideshare/saveOrUpdateSearch`,
      options
    );
    if (response.status === 200) {
      return "Success";
    } else {
      return response.json();
    }
  } catch (err) {
    return "Error occurred.";
  }
}

export async function setIsReserved(saleEventItemId, isReserved) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + encode(username + ":" + password),
    },
  };
  try {
    const response = await fetch(
      `${API_URL}/mobilityRideshare/` +
        (isReserved ? `reserve/` : `unreserve/`) +
        saleEventItemId,
      options
    );
    if (response.status === 200) {
      return {
        status: response.status,
        message: "Success!",
      };
    } else {
      return {
        status: response.status,
        message: "The server is having issues, please try again later.",
      };
    }
  } catch (err) {
    return {
      status: false,
      message: err.message,
    };
  }
}

export async function loadSavedSearchResultBySearchName(searchName) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + encode(username + ":" + password),
    },
  };

  try {
    const response = await fetch(
      `${API_URL}/mobilityRideshare/search/${searchName}`,
      options
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return {
        status: response.status,
        message: "The server is having issues, please try again later.",
      };
    }
  } catch (err) {
    return {
      status: false,
      message: err.message,
    };
  }
}
