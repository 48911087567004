import React, { Component } from "react";
import { connect } from "react-redux";
import ReactSVG from "react-svg";

import { withStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@material-ui/core";

import VINEntry from "../components/VINEntry";
import VehicleInformation from "../components/VehicleInformation";
import OdometerInformation from "../components/OdometerInformation";
import KeysLocation from "../components/KeysLocation";
import GroundingConfirmation from "../components/GroundingConfirmation";
import BuyVehicle from "../components/BuyVehicle";
import PassVehicle from "../components/PassVehicle";
import GuaranteePayments from "../components/GuaranteePayments";
import GuaranteePaymentsLPW from "../components/GuaranteePaymentsLPW";
import Loading from "../components/Loading";
import ConfirmPage from "../components/ConfirmPage";

import * as WatchListServices from "../services/WatchListServices";
import * as GroundingServices from "../services/GroundingServices";
import * as VehiclePurchaseServices from "../services/VehiclePurchaseServices";
import * as ProgramServices from "../services/ProgramServices";
import * as userAccess from "../services/UserRoles";
import * as FleetFinanicalService from "../services/FleetFinanicalService";

import {
  select_car_for_purchase,
  select_pass_car,
} from "../actions/carActions";
import {
  set_nav_location,
  hide_mobile_nav_menu,
  add_purchased_vehicle,
  remove_purchased_vehicle,
} from "../actions/uiActions";

import circleGroundIcon from "../assets/circleGroundIcon.svg";

import { ROUTE_URL } from "../constants/Constant";
import {
  GROUND,
  ODOMETER,
  ODOMETER_CONFIRMATION,
  DISCLOSURE_STATEMENT_VALUE,
  CUSTLOCATION,
  MASTERKEYS,
  VALETKEYS,
  VEHICLE_DRIVALBE,
  CONFIRM_ERROR,
  BALLOON,
  PROGRAM_CRET_NUM,
  PAD_VALUE,
  PAD_VALUE_TO_SEND_TO_API,
  VEHICLE_ADDED_TO_WATCHLIST,
  VEHICLE_GROUNDING_COMPLETED,
  VERIFY_VEHICLE_INFORMATION,
  ODOMETER_STATEMENT,
  KEYS_AND_LOCATION,
  BACK_CAMEL_CASE,
  GROUND_A_VEHICLE,
  PAY_OFF_NOT_RECEIVED,
  PAYOFF_PRICE_GROUNDING_ERROR,
  CALL_ADESA_SUPPORT,
  CLOSE,
  PLEASE_WAIT,
  PURCHASE_AT_PAYOFF,
  CUSTOMER_WILL_SIGN_PAPER_STATEMENT,
} from "../constants/UILabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";

const styles = (theme) => ({
  container: {
    width: "auto",
    display: "block",
    margin: "2.5rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  VINEntryContainer: {
    margin: ".5rem",
    display: "inline-block",
    width: "auto",
    marginTop: "4rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
    },
  },
  nextButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "8rem",
    textTransform: "initial",
    fontSize: ".8rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: 0,
      width: "50%",
    },
  },
  groundingNextButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "8rem",
    textTransform: "initial",
    fontSize: ".8rem",
    fontWeight: "bold",
    marginTop: "8.5rem",
    marginLeft: "-12rem",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: 0,
      width: "50%",
    },
  },

  disclaimerButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "8rem",
    textTransform: "initial",
    fontSize: ".8rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: 0,
      width: "50%",
    },
  },
  disclaimerTitle: {
    backgroundColor: theme.palette.primary.redHighlight,
    color: theme.palette.primary.white,
  },
  backButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "8rem",
    textTransform: "initial",
    fontSize: ".8rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      backgroundColor: "#9e9e9f",
      margin: 0,
      width: "50%",
    },
  },
  vinInfoBackButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "8rem",
    textTransform: "initial",
    fontSize: ".8rem",
    fontWeight: "bold",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      backgroundColor: "#9e9e9f",
      margin: 0,
      width: "50%",
    },
  },
  stepperButton: {
    margin: "1rem",
    display: "flex",
    justifyContent: "center",
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      marginTop: ".5rem",
    },
  },
  closeButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.button.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "8rem",
    textTransform: "initial",
    fontSize: ".8rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      backgroundColor: "#9e9e9f",
      margin: 0,
      width: "50%",
    },
  },
  groundVehicleText: {
    color: theme.palette.grounding.main,
    fontSize: "1rem",
    marginLeft: "1rem",
    borderBottom: `5px solid ${theme.palette.grounding.main}`,
    [theme.breakpoints.down("sm")]: {
      borderBottom: `none`,
      width: "100%",
      marginLeft: ".5rem",
    },
  },
  gorundIcon: {
    width: "2rem",
    [theme.breakpoints.down("sm")]: {
      margin: ".5rem",
      width: "1.5rem",
    },
  },
  payOffPrice: {
    display: "flex",
    width: "500px",
    margin: "auto",
  },
  stepperWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    margin: ".5rem",
    height: theme.stepper.height,
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      borderBottom: `0.01rem solid ${theme.palette.grounding.main}`,
      width: "100%",
      minHeight: "2.5rem",
    },
  },
  stepper: {
    padding: 0,
    display: "flex",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  stepLabel: {
    fontSize: theme.stepper.label.fontSize,
  },
  stepRoot: {
    "& $active": {
      color: "pink",
    },
  },
  stepIcon: {
    fill: theme.palette.grounding.main,
    color: theme.palette.grounding.main,
  },
  completedIcon: {
    fill: theme.palette.grounding.main,
    color: theme.palette.grounding.main,
  },
  cardTitle: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.primary.white,
      fontWeight: "bold",
      backgroundColor: theme.palette.grounding.main,
      textAlign: "center",
      borderRadius: "0 0 .75rem .75rem",
      display: "block",
    },
  },
  active: {},
  groundParentWrapper: {
    overflowY: "auto",
    overflowX: "hidden",
    minHeight: "78%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3.5rem",
    },
  },
});

function getSteps(isPurchase) {
  if (!isPurchase) {
    return [
      "Verify Vehicle Information",
      "Odometer Statement",
      "Keys and location",
    ];
  } else {
    return ["Verify Vehicle Information", "Purchase", "Odometer Statement"];
  }
}

export class Ground extends Component {
  state = {
    vin: "",
    errorMessage: "",
    messageVariant: "",
    vinDetails: "",
    odometer: "",
    odometerConfirmation: "",
    disclosureStatementValue: "false",
    padValue: "",
    padValueToSendToAPI: "",
    step: 0,
    activeStep: 0,
    isPurchase: false,
    customerWillDDValue: 0,
    paymentOptionCode: "",
    priceOptionSelected: "",
    priceOptionCode: "",
    paymentOptionSelected: null,
    transportDTO: "",
    isBuyNow: null,
    custLocation: {
      locationDTO: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        communicationDTO: {
          businessPhone: "",
        },
        contactName: "",
        name: "",
        id: 0,
      },
    },
    masterKeys: "",
    valetKeys: "",
    badMasterKeySubmit: false,
    badValetKeySubmit: false,
    vehicleDrivable: "",
    specialInstructions: null,
    newLocation: false,
    finalGroundedVehicleData: {},
    programCertNum: "",
    programCertComments: "",
    badVehicleSubmit: false,
    badOdoSubmit: false,
    badCustReturnDate: false,
    badValueSubmit: false,
    badCustomerWill: false,
    isProgramCertValidated: false,
    hasGuaranteedPayment: false,
    badCirtValid: false,
    badGuaranteePayment: false,
    badLocation: false,
    renderBuy: false,
    renderPass: false,
    userBoughtVehicle: false,
    userPassedVehicle: false,
    odoPDFDownloaded: false,
    guaranteePayment: false,
    guaranteePaymentLPW: false,
    userHasGuaranteedPayments: false,
    vinEntered: false,
    odoWarningConfirmation: false,
    userGuaranteedPayment: false,
    outStandingPayments: 0.0,
    waivedPayments: 0.0,
    guaranteeAdditionalRemainingPayments: false,
    customerReturnDate: "",
    alfaVehicleDataNotRecieved: false,
    loadingAsyncData: false,
    showGroundingSuccessMessages: true,
    showMissingSignatureMessage: false,
    disableSignaturePad: false,
    displayLesseeDisclaimer: false,
    isLesseePayoffDisclaimerAccepted: false,
    isCompanyCar: false,
    purchaseOptions: [],
    showEmailNotification: false,
    LesseePayoffPurchaseCompleted: false,
    customerWillSignPaperStatement: false,
    badVehicleDisclosure: false,
  };

  handleDateChange = async (date) => {
    const { loginId } = this.props.user;
    const vehicleDetails = await GroundingServices.vinDetails(
      this.state.vin,
      loginId,
      date
    );

    let details = this.state.vinDetails;
    details.programDTOs = vehicleDetails.programDTOs;
    date.setHours("12");
    if (isNaN(date) === true) {
      this.setState({
        customerReturnDate: date,
        badCustReturnDate: true,
      });
    } else {
      this.setState({
        ...this.state,
        customerReturnDate: date,
        badCustReturnDate: false,
        vinDetails: details,
      });
    }
  };

  componentWillMount = () => {
    const { state } = this.props.location;
    const { dispatch, user, history } = this.props;
    if (!userAccess.canGroundVehicles(user.userRoles) || user.dealerSuspendedGrounding) {
      history.replace(ROUTE_URL.HOME);
    } else {
      dispatch(set_nav_location(GROUND));
    }

    if (state !== undefined) {
      this.resetState();
      this.setState({
        vin: state.vin,
        odometer: state.odometer,
        odometerConfirmation: state.odometerConfirmation,
      });
    }
  };

  componentDidMount = () => {
    const { accountSetting, navLocation, dispatch } = this.props;
    if (navLocation !== GROUND) {
      dispatch(set_nav_location(GROUND));
    }
    if (accountSetting.loginID) {
      this.setState({
        ...this.state,
        custLocation: this.findUsersLocation(accountSetting.selectedLocation),
      });
    }
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    dispatch(hide_mobile_nav_menu(false));
  };

  findUsersLocation = () => {
    const { accountSetting } = this.props;
    return accountSetting.custLocations.filter(
      (location) =>
        location.locationId === parseInt(accountSetting.selectedLocation, 10)
    )[0];
  };

  resetState = () => {
    this.setState({
      vin: "",
      errorMessage: null,
      messageVariant: "",
      vinDetails: "",
      odometer: "",
      odometerConfirmation: "",
      disclosureStatementValue: "false",
      padValue: "",
      padValueToSendToAPI: "",
      step: 0,
      activeStep: 0,
      isPurchase: false,
      customerWillDDValue: 0,
      masterKeys: "",
      valetKeys: "",
      badMasterKeySubmit: false,
      badValetKeySubmit: false,
      vehicleDrivable: "",
      specialInstructions: null,
      newLocation: false,
      finalGroundedVehicleData: {},
      programCertNum: "",
      programCertComments: "",
      badVehicleSubmit: false,
      badOdoSubmit: false,
      badCustReturnDate: false,
      badValueSubmit: false,
      badCustomerWill: false,
      isProgramCertValidated: false,
      hasGuaranteedPayment: false,
      badLocation: false,
      renderBuy: false,
      renderPass: false,
      userBoughtVehicle: false,
      userPassedVehicle: false,
      addedToWatchlist: false,
      customerHasSigned: "",
      odoPDFDownloaded: false,
      guaranteePayment: false,
      guaranteePaymentLPW: false,
      userHasGuaranteedPayments: false,
      loading: false,
      vinEntered: false,
      odoWarningConfirmation: false,
      userGuaranteedPayment: false,
      outStandingPayments: 0.0,
      waivedPayments: 0.0,
      guaranteeAdditionalRemainingPayments: false,
      customerReturnDate: null,
      showGroundingSuccessMessages: true,
      showMissingSignatureMessage: false,
      disableSignaturePad: false,
      isCompanyCar: false,
      customerWillSignPaperStatement: false,
      badVehicleDisclosure: false,
    });
  };

  handleVinSearch = (
    vinDetailsValue,
    errorMessageValue,
    messageVariantValue
  ) => {
    const { history } = this.props;
    if (messageVariantValue !== "success") {
      if (errorMessageValue === "NoUserAvailable") {
        history.push("/");
      }
    }
    this.setState({
      vinDetails: vinDetailsValue,
      errorMessage: errorMessageValue,
      messageVariant: messageVariantValue,
      activeStep: vinDetailsValue !== "" ? 1 : 0,
      isCompanyCar:
        vinDetailsValue !== ""
          ? vinDetailsValue.inventoryDTO.leaseInformationDTO.accountType ===
            "COMPANY CAR"
          : false,
    });
  };

  handleCertValidationChangefalse = (isValid) => {
    this.setState({
      isProgramCertValidated: isValid,
      badCirtValid: false,
      badGuaranteePayment: false,
    });
  };

  handleCertValidationChange = (
    outStandingPayments,
    waivedPayments,
    guaranteeAdditionalRemainingPayments
  ) => {
    this.setState({
      outStandingPayments,
      waivedPayments,
      guaranteeAdditionalRemainingPayments,
      isProgramCertValidated: true,
    });
  };

  denyGuaranteedPayment = () => {
    this.setState({
      programCertNum: "",
      isProgramCertValidated: false,
      badGuaranteePayment: false,
    });
  };

  handleGuaranteedPayment = (has) => {
    this.setState({
      hasGuaranteedPayment: has,
      badGuaranteePayment: false,
    });
  };

  cancelLPW = () => {
    this.setState({
      programCertNum: "",
      programCertComments: "",
      isProgramCertValidated: false,
      hasGuaranteedPayment: false,
      outStandingPayments: 0.0,
      waivedPayments: 0.0,
      guaranteeAdditionalRemainingPayments: false,
    });
  };

  handleVinChange = (vin) => (event) => {
    this.resetState();
    this.setState({
      [vin]: event.target.value,
    });
  };

  handleChange = (name) => (event) => {
    if (name === ODOMETER || name === ODOMETER_CONFIRMATION) {
      if (
        typeof Number(event.target.value) === "number" &&
        Number(event.target.value.replace(/,/g, '')) >= 0 &&
        (event.target.value !== "-" || event.target.value !== "e") &&
        !event.target.value.includes(".")
      ) {
        this.setState({
          [name]: event.target.value.replace(/,/g, ''),
          badOdoSubmit: false,
        });
      }
    } else if (name === DISCLOSURE_STATEMENT_VALUE) {
      if (event.target.value === "true") {
        this.setState({
          customerHasSigned: event.target.value,
          disclosureStatementValue: "113",
          badValueSubmit: false,
          odoWarningConfirmation: false,
        });
        if (!this.state.customerWillSignPaperStatement) {
          this.setState({
            disableSignaturePad: false,
          });
        }
      } else if (event.target.value === "false") {
        this.setState({
          disableSignaturePad: true,
          customerHasSigned: event.target.value,
          disclosureStatementValue: "26831",
          badValueSubmit: false,
          odoWarningConfirmation: false,
        });
      } else {
        this.setState({
          [name]: event.target.value,
          badValueSubmit: false,
          odoWarningConfirmation:
            event.target.value === "114" || event.target.value === "115",
        });
      }
    } else if (name === CUSTLOCATION) {
      this.setState({
        [name]: event.target.value,
        badLocation: false,
      });
    } else if (name === MASTERKEYS) {
      this.setState({
        [name]: event.target.value,
        badMasterKeySubmit: false,
      });
    } else if (name === VALETKEYS) {
      this.setState({
        [name]: event.target.value,
        badValetKeySubmit: false,
      });
    } else if (name === VEHICLE_DRIVALBE) {
      this.setState({
        [name]: event.target.value,
        badVehicleSubmit: false,
      });
    } else if (name === PROGRAM_CRET_NUM) {
      this.setState({
        [name]: event.target.value,
        badVehicleSubmit: false,
        isProgramCertValidated: false,
      });
    } else if (name === PAD_VALUE) {
      this.setState({
        [name]: event.target.value,
      });
    } else if (name === PAD_VALUE_TO_SEND_TO_API) {
      this.setState({
        [name]: event.target.value,
        showMissingSignatureMessage: false,
      });
    } else if (name === CUSTOMER_WILL_SIGN_PAPER_STATEMENT) {
      if (event.target.value === true) {
        this.setState({
          [name]: event.target.value,
          padValue: "",
          padValueToSendToAPI: "",
          disableSignaturePad: true,
          showMissingSignatureMessage: false,
        });
        if (this.state.customerHasSigned === "false") {
          this.setState({
            customerHasSigned: "",
            disclosureStatementValue: "false",
          });
        }
      } else if (event.target.value === false) {
        this.setState({
          [name]: event.target.value,
          disableSignaturePad: false,
        });
      }
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleCustomerWillDDChange = (event) => {
    const { user } = this.props;
    let selectedValue = event.target.value;

    if (
      selectedValue === 1 &&
      !userAccess.canPurchaseVehicles(user.userRoles)
    ) {
      this.setState({
        customerWillDDValue: selectedValue,
        isPurchase: false,
        badCustomerWill: true,
        showGroundingSuccessMessages: false,
      });
    } else {
      this.setState({
        customerWillDDValue: selectedValue,
        isPurchase: selectedValue === 1,
        badCustomerWill: false,
        showGroundingSuccessMessages:
          selectedValue === 0 || selectedValue === 3,
      });
    }
  };

  handleGrounding = async () => {
    const { user } = this.props;
    const { TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF } =
      this.props.togglzMap || {};
    const {
      custLocation,
      guaranteeAdditionalRemainingPayments,
      isPurchase,
      odometer,
      odometerConfirmation,
    } = this.state;
    const errorMessages = {
      badMasterKeySubmit: false,
      badValetKeySubmit: false,
      badLocation: false,
      badVehicleSubmit: false,
      badVehicleDisclosure: false,
    };

    if (isPurchase) {
      this.setState({
        masterKeys: "0",
        valetKeys: "0",
        vehicleDrivable: "true",
      });
    }

    const { masterKeys, valetKeys, vehicleDrivable, vinDetails } = this.state;

    if (masterKeys === "") {
      errorMessages.badMasterKeySubmit = true;
    }
    if (valetKeys === "") {
      errorMessages.badValetKeySubmit = true;
    }
    if (
      !custLocation ||
      (custLocation && custLocation.locationDTO.name === "")
    ) {
      errorMessages.badLocation = true;
    }
    if (vehicleDrivable === "") {
      errorMessages.badVehicleSubmit = true;
    }
    if (
      this.state.activeStep === 2 &&
      this.state.customerWillDDValue === 1 &&
      this.state.odoWarningConfirmation &&
      this.state.isPurchase &&
      TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF &&
      (this.state.disclosureStatementValue === "114" ||
        this.state.disclosureStatementValue === "115")
    ) {
      errorMessages.badVehicleDisclosure = true;
    }
    this.setState({
      badMasterKeySubmit: errorMessages.badMasterKeySubmit,
      badValetKeySubmit: errorMessages.badValetKeySubmit,
      badLocation: errorMessages.badLocation,
      badVehicleSubmit: errorMessages.badVehicleSubmit,
      badVehicleDisclosure: errorMessages.badVehicleDisclosure,
      loading: true,
    });

    if (!odometer || odometer !== odometerConfirmation) {
      this.setState({
        badOdoSubmit: true,
        badValueSubmit: false,
        loading: false,
      });
    } else {
      if (Object.keys(errorMessages).every((key) => !errorMessages[key])) {
        const response = await GroundingServices.ground(this.state, user);
        this.setState({
          ...this.state,
          finalGroundedVehicleData: response,
        });
        if (response && guaranteeAdditionalRemainingPayments) {
          this.handleGuarantee();
        }
        if (response) {
          this.handleNext();
        }
        if (response && this.state.isPurchase) {
          this.setState({ loadingAsyncData: true });
          this.downloadOdometerStatementPdf(this.state);
          this.finishPurchase();
        }
      } else {
        if (this.state.badVehicleDisclosure) {
          this.resetState();
          this.setState(() => ({
            activeStep: 0,
            vin: vinDetails.inventoryDTO.vehicleIdentificationNumber,
          }));
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    }
  };

  finishPurchase = async () => {
    const { user, dispatch } = this.props;
    const { finalGroundedVehicleData } = this.state;

    const car = {
      id: finalGroundedVehicleData.inventoryDTO.id,
      saleEventItemId: finalGroundedVehicleData.saleEventItemId,
      isUpStream: null,
      remainingPaymentFeeAmount:
        finalGroundedVehicleData.remainingPaymentFeeAmount,
      isPilotEnabled: false,
      hideGuaranteePopup: false,
      invStatusInAuction: null,
      adminFee: 0,
      promotionalFee: 0,
      programDTO: [],
      groundingDealerId: parseInt(user.dealerId, 10),
      checkFee: null,
      purchaseOptionAvailable: true,
      paymentGuaranteed: false,
      remainingPayments: 0,
      waivedPayments: null,
    };
    this.setState({
      priceOptionSelected: "Payoff Price",
      priceOptionCode: "LESSEE-PAYOFF",
      paymentOptionSelected: localStorage.getItem("paymentOptionSelected"),
      paymentOptionCode: localStorage.getItem("paymentOptionCode"),
      transportMethodSelected: null,
      selectedTransMethod: "Buyer Arranged",
      transportDTO: { quote: 0 },
      totalPriceAmount:
        finalGroundedVehicleData.inventoryDTO.leaseInformationDTO
          .grossPayAmount,
      selectedPrice:
        finalGroundedVehicleData.inventoryDTO.leaseInformationDTO
          .grossPayAmount,
    });

    const purchaseOptions = this.state;
    const response = await VehiclePurchaseServices.completePurchase(
      car,
      user,
      purchaseOptions
    );
    if (response.status === "true") {
      this.setState({ LesseePayoffPurchaseCompleted: true });
      this.renderStep(4);
      dispatch(
        add_purchased_vehicle(
          finalGroundedVehicleData.vehicleIdentificationNumber
        )
      );
      dispatch(
        remove_purchased_vehicle(
          finalGroundedVehicleData.vehicleIdentificationNumber
        )
      );
    } else {
      this.setState({
        purchaseErrorMessage: true,
        loading: false,
      });
    }
    this.setState({ loadingAsyncData: false });
  };

  handleLPWGrounding = async () => {
    const { user } = this.props;
    const { custLocation, masterKeys, valetKeys, vehicleDrivable } = this.state;
    const errorMessages = {
      badMasterKeySubmit: false,
      badValetKeySubmit: false,
      badLocation: false,
      badVehicleSubmit: false,
    };
    if (masterKeys === "") {
      errorMessages.badMasterKeySubmit = true;
    }
    if (valetKeys === "") {
      errorMessages.badValetKeySubmit = true;
    }
    if (
      !custLocation ||
      (custLocation && custLocation.locationDTO.name === "")
    ) {
      errorMessages.badLocation = true;
    }
    if (vehicleDrivable === "") {
      errorMessages.badVehicleSubmit = true;
    }
    this.setState({
      badMasterKeySubmit: errorMessages.badMasterKeySubmit,
      badValetKeySubmit: errorMessages.badValetKeySubmit,
      badLocation: errorMessages.badLocation,
      badVehicleSubmit: errorMessages.badVehicleSubmit,
      loading: true,
    });
    if (Object.keys(errorMessages).every((key) => !errorMessages[key])) {
      const response = await GroundingServices.ground(this.state, user);
      this.setState({
        ...this.state,
        finalGroundedVehicleData: response,
        guaranteePaymentLPW: !this.state.guaranteePaymentLPW,
        userHasGuaranteedPayments: !this.state.userHasGuaranteedPayments,
        userGuaranteedPayment: !this.state.userGuaranteedPayment,
      });
      if (response) {
        this.handleNext();
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleGuarantee = async () => {
    const { vinDetails } = this.state;
    const { outStandingPayments, waivedPayments } = this.state;
    await ProgramServices.guarnteePaymentDone(
      vinDetails.inventoryDTO.id,
      waivedPayments,
      outStandingPayments
    );
  };

  toggleError = (error, message) => {
    if (error === CONFIRM_ERROR) {
      this.setState({
        confirmError: message,
      });
    } else {
      this.setState({
        [error]: !this.state[error],
      });
    }
  };

  getCarInfoForGuaranteePayments = (finalGroundedVehicleData) => {
    const { saleEventItemId, dealerId } = finalGroundedVehicleData;
    const { make, model, vehicleYear, vehicleIdentificationNumber, id } =
      finalGroundedVehicleData.inventoryDTO;
    const { TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF } =
      this.props.togglzMap || {};
    const { mileage } = TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF
      ? 0
      : finalGroundedVehicleData.odometerInformationDTO;
    const {
      remainingPaymentAmount,
      residualPayAmount,
      grossPayAmount,
      marketBasedPrice,
    } = finalGroundedVehicleData.inventoryDTO.leaseInformationDTO;
    const car = {
      id: id,
      year: vehicleYear,
      make: make,
      model: model,
      vehicleIdentificationNumber: vehicleIdentificationNumber,
      remainingPaymentsAmt: remainingPaymentAmount,
      saleEventItemId: saleEventItemId,
      groundingMileage: mileage,
      residualAmount: residualPayAmount,
      grossPayoff: grossPayAmount,
      marketBasedPrice: marketBasedPrice,
      remainingPayments: remainingPaymentAmount,
      groundingDealerId: dealerId,
      inventoryDTO: finalGroundedVehicleData.inventoryDTO,
    };
    return car;
  };

  getGuaranteePaymentsData = (carInfo) => {
    let paymentsWaived = 0;
    if (carInfo.programDTOs != null) {
      paymentsWaived = carInfo.programDTOs[0].numberOfPaymentsWaived;
    }
    const car = {
      id: carInfo.inventoryDTO.id,
      year: carInfo.inventoryDTO.vehicleYear,
      make: carInfo.inventoryDTO.make,
      model: carInfo.inventoryDTO.model,
      vehicleIdentificationNumber:
        carInfo.inventoryDTO.vehicleIdentificationNumber,
      remainingPaymentsAmt: Number(
        carInfo.inventoryDTO.leaseInformationDTO.remainingPaymentAmount -
          (carInfo.inventoryDTO.leaseInformationDTO.baseRent +
            carInfo.inventoryDTO.leaseInformationDTO.tax) *
            paymentsWaived
      ).toFixed(2),
    };
    return car;
  };

  toggleBuyVehicle = async () => {
    const { dispatch } = this.props;
    const { userId, dealerId, loginId } = this.props.user;
    const { saleEventItemId } = this.state.finalGroundedVehicleData;
    const { id } = this.state.finalGroundedVehicleData.inventoryDTO;
    const { accountType, alfaSourceIndicator } =
      this.state.finalGroundedVehicleData.inventoryDTO.leaseInformationDTO;
    const { renderBuy, finalGroundedVehicleData } = this.state;
    if (!renderBuy) {
      if (accountType === BALLOON) {
        dispatch(select_car_for_purchase(finalGroundedVehicleData));
      } else {
        let buyDetails = await VehiclePurchaseServices.buy(
          id,
          userId,
          dealerId,
          saleEventItemId,
          loginId
        );

        let leaseEndDate =
          finalGroundedVehicleData.inventoryDTO.leaseInformationDTO
            .leaseEndDate;
        let adjustedLeaseEndDate =
          finalGroundedVehicleData.inventoryDTO.leaseInformationDTO
            .adjustMaturityDate;

        buyDetails.leaseEndDate = adjustedLeaseEndDate
          ? adjustedLeaseEndDate
          : leaseEndDate;

        dispatch(select_car_for_purchase(buyDetails));
      }
      if (alfaSourceIndicator && alfaSourceIndicator === "ALFA") {
        setTimeout(() => {
          this.setState({ renderBuy: true });
        }, 2000);
      } else {
        this.setState({ renderBuy: true });
      }
    } else {
      this.setState({
        renderBuy: false,
      });
    }
  };

  toggleBuyVehicleDone = () => {
    this.setState({
      renderBuy: !this.state.renderBuy,
      userBoughtVehicle: !this.state.userBoughtVehicle,
    });
  };

  togglePassVehicle = () => {
    const { dispatch } = this.props;
    const { finalGroundedVehicleData } = this.state;
    dispatch(
      select_pass_car(
        this.getCarInfoForGuaranteePayments(finalGroundedVehicleData)
      )
    );
    this.setState({
      renderPass: !this.state.renderPass,
    });
  };

  togglePDFDownloaded = () => {
    this.setState({
      odoPDFDownloaded: !this.state.odoPDFDownloaded,
    });
  };

  toggleGuaranteePayment = () => {
    this.setState({
      guaranteePayment: !this.state.guaranteePayment,
    });
  };

  toggleGuaranteePaymentLPW = () => {
    this.setState({
      guaranteePaymentLPW: !this.state.guaranteePaymentLPW,
    });
  };

  toggleGuaranteePaymentDone = () => {
    this.setState({
      guaranteePayment: !this.state.guaranteePayment,
      userHasGuaranteedPayments: !this.state.userHasGuaranteedPayments,
    });
  };

  handleAddToWatchList = async () => {
    const { user } = this.props;
    const { finalGroundedVehicleData } = this.state;
    const car = this.getCarInfoForGuaranteePayments(finalGroundedVehicleData);
    const response = await WatchListServices.addToWatchList(user, car);
    if (response === "Success") {
      this.loadWatchlistData();
      this.setState({
        addedToWatchlist: true,
      });
    } else {
      this.setState({
        addedToWatchlist: true,
        confirmError: VEHICLE_ADDED_TO_WATCHLIST,
      });
    }
  };

  toggleShowEmailNotification = () => {
    this.setState({
      showEmailNotification: !this.state.showEmailNotification,
    });
  };

  returnToHomePage = () => {
    this.props.history.replace(ROUTE_URL.HOME);
  };

  downloadOdometerStatementPdf = (state) => {
    if (!state.odoPDFDownloaded) {
      this.togglePDFDownloaded();
      if (state.finalGroundedVehicleData.odometerInformationDTO) {
        GroundingServices.downloadPdf(
          state.finalGroundedVehicleData.odometerInformationDTO.odometerPdf
        );
      } else {
        console.log("There was no Odometer Information in the response");
      }
    }
  };

  renderPurchaseConfirmationStep = () => {
    const { vinDetails, showEmailNotification, odometer } = this.state;
    const { ground } = this.props;
    const car = {
      id: vinDetails.inventoryDTO.id,
      vehicleIdentificationNumber:
        vinDetails.inventoryDTO.vehicleIdentificationNumber,
      vin: vinDetails.inventoryDTO.vehicleIdentificationNumber,
      grossPayoff: vinDetails.inventoryDTO.leaseInformationDTO.grossPayAmount,
      inspectionMileage: odometer,
      adminFee: 0.0,
      make: vinDetails.inventoryDTO.make,
      model: vinDetails.inventoryDTO.model,
      year: vinDetails.inventoryDTO.vehicleYear,
      vehicleTitleDelay: vinDetails.inventoryDTO.vehicleTitleDelay,
      checkFee: null,
      residualAmount: 0,
      remainingPaymentsAmt: null,
      remPayNotGuaranDuringPurchase: false,
      marketBasedPrice: null,
      buyNowPrice: null,
      promotionalFee: 0,
    };
    const purchaseOptions = {
      totalPriceAmount:
        vinDetails.inventoryDTO.leaseInformationDTO.grossPayAmount,
      priceOptionSelected: "Payoff Price",
      transportMethodSelected: false,
      priceOptionCode: "LESSEE-PAYOFF",
      paymentOptionCode: localStorage.getItem("paymentOptionCode"),
    };
    return (
      <React.Fragment>
        <ConfirmPage
          car={car}
          purchaseOptions={purchaseOptions}
          toggleShowEmailNotification={this.toggleShowEmailNotification}
          showEmailNotification={showEmailNotification}
          toggleBuyVehicle={this.toggleBuyVehicle}
          returnToHomePage={this.returnToHomePage}
          page="LPP"
          ground={ground}
        />
      </React.Fragment>
    );
  };

  renderGroundingConfirmationStep = () => {
    const {
      renderBuy,
      renderPass,
      finalGroundedVehicleData,
      userBoughtVehicle,
      userPassedVehicle,
      odoPDFDownloaded,
      guaranteePayment,
      userHasGuaranteedPayments,
      vinDetails,
      addedToWatchlist,
      confirmError,
    } = this.state;
    const { user, history, classes } = this.props;
    if (renderBuy) {
      return (
        <BuyVehicle
          carInfo={this.getCarInfoForGuaranteePayments(
            finalGroundedVehicleData
          )}
          toggleBuyVehicle={this.toggleBuyVehicle}
          toggleBuyVehicleDone={this.toggleBuyVehicleDone}
          history={history}
          ground
          page="GAMD"
        />
      );
    } else if (renderPass) {
      return (
        <PassVehicle
          carInfo={this.getCarInfoForGuaranteePayments(
            finalGroundedVehicleData
          )}
          togglePassVehicle={this.togglePassVehicle}
          history={history}
          ground
        />
      );
    } else if (guaranteePayment) {
      return (
        <GuaranteePayments
          car={this.getCarInfoForGuaranteePayments(finalGroundedVehicleData)}
          toggleGuaranteePayment={this.toggleGuaranteePayment}
          toggleGuaranteePaymentDone={this.toggleGuaranteePaymentDone}
          user={user}
        />
      );
    } else {
      return (
        <>
          <Typography className={classes.cardTitle}>
            {VEHICLE_GROUNDING_COMPLETED}
          </Typography>
          <GroundingConfirmation
            finalGroundedVehicleData={finalGroundedVehicleData}
            history={history}
            user={user}
            toggleBuyVehicle={this.toggleBuyVehicle}
            userBoughtVehicle={userBoughtVehicle}
            togglePassVehicle={this.togglePassVehicle}
            userPassedVehicle={userPassedVehicle}
            handleAddToWatchList={this.handleAddToWatchList}
            addedToWatchlist={addedToWatchlist}
            togglePDFDownloaded={this.togglePDFDownloaded}
            odoPDFDownloaded={odoPDFDownloaded}
            userHasGuaranteedPayments={userHasGuaranteedPayments}
            toggleGuaranteePayment={this.toggleGuaranteePayment}
            fullVinDetails={vinDetails}
            resetState={this.resetState}
            confirmError={confirmError}
            toggleError={this.toggleError}
            groundInfo={this.state}
          />
        </>
      );
    }
  };

  renderAlfaPayoffGroundingError = () => {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.payOffPrice}>
          {PAYOFF_PRICE_GROUNDING_ERROR} <br />
          {CALL_ADESA_SUPPORT}
        </div>
        <Button
          onClick={() => {
            this.handleGroundingError();
          }}
          className={classes.closeButton}
        >
          {CLOSE}
        </Button>
      </div>
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.payOffPrice}>
          {PLEASE_WAIT}
          <br />
          <br />
        </div>
        <Loading />
      </div>
    );
  };

  renderStep = (stepIndex) => {
    const {
      vin,
      errorMessage,
      messageVariant,
      vinDetails,
      odometer,
      odometerConfirmation,
      disclosureStatementValue,
      padValue,
      customerWillDDValue,
      custLocation,
      masterKeys,
      valetKeys,
      badMasterKeySubmit,
      badValetKeySubmit,
      vehicleDrivable,
      badOdoSubmit,
      badCustReturnDate,
      badValueSubmit,
      badVehicleSubmit,
      programCertNum,
      programCertComments,
      isProgramCertValidated,
      hasGuaranteedPayment,
      badCustomerWill,
      badLocation,
      customerHasSigned,
      vinEntered,
      odoWarningConfirmation,
      specialInstructions,
      customerReturnDate,
      showGroundingSuccessMessages,
      showMissingSignatureMessage,
      disableSignaturePad,
      activeStep,
      isCompanyCar,
      isBuyNow,
      LesseePayoffPurchaseCompleted,
      customerWillSignPaperStatement,
    } = this.state;
    const { classes, history } = this.props;
    const steps = getSteps(this.state.isPurchase);
    if (this.state.alfaVehicleDataNotRecieved === true) {
      return this.renderAlfaPayoffGroundingError();
    } else if (this.state.loadingAsyncData === true) {
      return this.renderLoading();
    } else if (this.state.loadingAsyncData === false) {
      switch (stepIndex) {
        case 0:
          return (
            <div className={classes.container}>
              <div className={classes.VINEntryContainer}>
                <VINEntry
                  activeStep={activeStep}
                  vin={vin}
                  errorMessage={errorMessage}
                  messageVariant={messageVariant}
                  onVinChange={this.handleVinChange}
                  onVinSearch={this.handleVinSearch}
                  vinEntered={vinEntered}
                  handleChange={this.handleChange}
                  customerWillDDValue={customerWillDDValue}
                  showGroundingSuccessMessages={showGroundingSuccessMessages}
                />
              </div>
            </div>
          );
        case 1:
          return (
            <>
              <Typography className={classes.cardTitle}>
                {VERIFY_VEHICLE_INFORMATION}
              </Typography>
              <VehicleInformation
                activeStep={activeStep}
                vin={vin}
                vinDetails={vinDetails}
                errorMessage={errorMessage}
                messageVariant={messageVariant}
                onVinChange={this.handleVinChange}
                onVinSearch={this.handleVinSearch}
                onCustomerWillDDChange={this.handleCustomerWillDDChange}
                customerWillDDValue={customerWillDDValue}
                onHandleChange={this.handleChange}
                badCustomerWill={badCustomerWill}
                programCertNum={programCertNum}
                programCertComments={programCertComments}
                isProgramCertValidated={isProgramCertValidated}
                denyGuaranteedPayment={this.denyGuaranteedPayment}
                hasGuaranteedPayment={hasGuaranteedPayment}
                handleGuaranteedPayment={this.handleGuaranteedPayment}
                cancelLPW={this.cancelLPW}
                handleCertValidationChange={this.handleCertValidationChange}
                handleCertValidationChangefalse={
                  this.handleCertValidationChangefalse
                }
                vinEntered={vinEntered}
                showGroundingSuccessMessages={showGroundingSuccessMessages}
                isCompanyCar={isCompanyCar}
                handleDateChange={this.handleDateChange}
                customerReturnDate={customerReturnDate}
                badCustReturnDate={badCustReturnDate}
              >
                {this.renderStepperButton(
                  activeStep,
                  steps,
                  customerWillDDValue
                )}
              </VehicleInformation>
            </>
          );
        case 2:
          return (
            <>
              <Typography className={classes.cardTitle}>
                {ODOMETER_STATEMENT}
              </Typography>
              <OdometerInformation
                odometer={odometer}
                odometerConfirmation={odometerConfirmation}
                disclosureStatementValue={disclosureStatementValue}
                padValue={padValue}
                handleChange={this.handleChange}
                changeStep={this.changeStep}
                badOdoSubmit={badOdoSubmit}
                badValueSubmit={badValueSubmit}
                showMissingSignatureMessage={showMissingSignatureMessage}
                disableSignaturePad={disableSignaturePad}
                customerHasSigned={customerHasSigned}
                odoWarningConfirmation={odoWarningConfirmation}
                customerWillSignPaperStatement={customerWillSignPaperStatement}
                customerWillDDValue={customerWillDDValue}
              >
                {this.renderStepperButton(
                  activeStep,
                  steps,
                  customerWillDDValue
                )}
              </OdometerInformation>
            </>
          );
        case 3:
          return (
            <>
              <Typography className={classes.cardTitle}>
                {KEYS_AND_LOCATION}
              </Typography>
              <KeysLocation
                custLocation={custLocation}
                handleChange={this.handleChange}
                masterKeys={masterKeys}
                valetKeys={valetKeys}
                badMasterKeySubmit={badMasterKeySubmit}
                badValetKeySubmit={badValetKeySubmit}
                vin={vin}
                handleGrounding={this.handleGrounding}
                vehicleDrivable={vehicleDrivable}
                badVehicleSubmit={badVehicleSubmit}
                badLocation={badLocation}
                specialInstructions={specialInstructions}
              >
                {this.renderStepperButton(
                  activeStep,
                  steps,
                  customerWillDDValue
                )}
              </KeysLocation>
            </>
          );
        case 4:
          return this.state.isPurchase && LesseePayoffPurchaseCompleted ? (
            this.renderPurchaseConfirmationStep()
          ) : this.state.isPurchase && !LesseePayoffPurchaseCompleted ? (
            <></>
          ) : (
            this.renderGroundingConfirmationStep()
          );
        case 5:
          return (
            <>
              <BuyVehicle
                carInfo={this.getCarInfoForGuaranteePayments(vinDetails)}
                toggleBuyVehicle={this.toggleBuyVehicle}
                toggleBuyVehicleDone={this.toggleBuyVehicleDone}
                history={history}
                ground
                page="LPP"
                handleChange={this.handleChange}
                customerWillDDValue={customerWillDDValue}
                isBuyNow={isBuyNow}
              >
                {this.renderStepperButton(
                  activeStep,
                  steps,
                  customerWillDDValue
                )}
              </BuyVehicle>
            </>
          );
        default:
          return (
            <div className={classes.VINEntryContainer}>
              <VINEntry
                vin={vin}
                errorMessage={errorMessage}
                messageVariant={messageVariant}
                onVinChange={this.handleVinChange}
                onVinSearch={this.handleVinSearch}
                customerWillDDValue={customerWillDDValue}
                showGroundingSuccessMessages={showGroundingSuccessMessages}
              />
            </div>
          );
      }
    }
  };

  renderStepper = (activeStep, steps) => {
    const { classes } = this.props;
    return (
      <Stepper
        className={classes.stepper}
        activeStep={this.getStepperStep(activeStep)}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              classes={{ alternativeLabel: classes.stepLabel }}
              StepIconProps={{
                classes: {
                  root: classes.stepRoot,
                  active: classes.stepIcon,
                  completed: classes.completedIcon,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };

  getStepperStep = (activeStep) => {
    if (this.state.isPurchase) {
      switch (activeStep) {
        case 1:
          return 0;
        case 5:
          return 1;
        default:
          return activeStep;
      }
    } else {
      return activeStep - 1;
    }
  };

  renderStepperButton = (activeStep, steps, customerWillDDValue) => {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        {activeStep !== 4 ? (
          <div className={classes.stepperButton}>
            {activeStep === 5 ? (
              <Button
                id="next-stepper-button"
                className={classes.nextButton}
                variant="contained"
                onClick={
                  activeStep === steps.length ||
                  (activeStep === 3 && steps.length === 4)
                    ? (evt) => this.handleGrounding()
                    : this.handleNext
                }
              >
                {"Confirm"}
              </Button>
            ) : null}
            {activeStep !== 0 && activeStep !== 1 && (
              <Button
                disabled={activeStep === 0 || loading}
                onClick={this.handleBack}
                className={classes.backButton}
              >
                {activeStep === 5 ? "Cancel" : BACK_CAMEL_CASE}
              </Button>
            )}
            {activeStep === 1 && (
              <Button
                disabled={activeStep === 0 || loading}
                onClick={this.resetState}
                className={classes.vinInfoBackButton}
              >
                {BACK_CAMEL_CASE}
              </Button>
            )}
            {activeStep !== 5 ? (
              <Button
                id="next-stepper-button"
                className={
                  activeStep === 1
                    ? classes.groundingNextButton
                    : classes.nextButton
                }
                variant="contained"
                onClick={
                  activeStep === steps.length ||
                  (activeStep === 2 && this.state.isPurchase) ||
                  (activeStep === 3 && steps.length === 4)
                    ? (evt) => this.handleGrounding()
                    : this.handleNext
                }
                disabled={this.state.badCustomerWill}
              >
                {activeStep === steps.length ||
                (activeStep === 2 && this.state.isPurchase) ||
                (activeStep === 3 && steps.length === 4) ? (
                  loading ? (
                    <Loading color={"#FFFFFF"} />
                  ) : (
                    "Submit & Print"
                  )
                ) : (
                  "Next"
                )}
              </Button>
            ) : null}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  handleNext = () => {
    const {
      activeStep,
      odometer,
      odometerConfirmation,
      disclosureStatementValue,
      customerWillDDValue,
      vinDetails,
      programCertNum,
      isProgramCertValidated,
      custLocation,
      odoWarningConfirmation,
      customerHasSigned,
      customerReturnDate,
      isPurchase,
      padValue,
      isLesseePayoffDisclaimerAccepted,
      customerWillSignPaperStatement,
    } = this.state;
    const { TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF } =
      this.props.togglzMap || {};
    if (activeStep === 3 && !custLocation) {
      this.setState({
        badLocation: true,
      });
    } else if (activeStep === 3 && custLocation.locationDTO.name === "") {
      this.setState({
        badLocation: true,
      });
    } else if (
      activeStep === 2 &&
      (!odometer || odometer !== odometerConfirmation)
    ) {
      this.setState({
        badOdoSubmit: true,
        badValueSubmit: false,
      });
    } else if (activeStep === 5 && isPurchase) {
      this.setState({
        activeStep: 2,
      });
    } else if (
      activeStep === 2 &&
      disclosureStatementValue !== "113" &&
      disclosureStatementValue !== "114" &&
      disclosureStatementValue !== "115" &&
      disclosureStatementValue !== "26831"
    ) {
      this.setState({
        badOdoSubmit: false,
        badValueSubmit: true,
      });
    } else if (
      TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF &&
      activeStep === 2 &&
      padValue === "" &&
      !customerWillSignPaperStatement &&
      (disclosureStatementValue === "113" ||
        disclosureStatementValue === "114" ||
        disclosureStatementValue === "115")
    ) {
      this.setState({
        showMissingSignatureMessage: true,
      });
    } else if (
      customerHasSigned === "true" &&
      !odoWarningConfirmation &&
      (disclosureStatementValue === "114" || disclosureStatementValue === "115")
    ) {
      this.setState({
        odoWarningConfirmation: true,
      });
    } else if (
      activeStep === 1 &&
      (customerReturnDate === "" ||
        isNaN(customerReturnDate) === true ||
        customerReturnDate === null ||
        customerReturnDate > new Date().setHours("12"))
    ) {
      this.setState({
        badCustReturnDate: true,
        badValueSubmit: false,
      });
    } else if (
      activeStep === 1 &&
      customerWillDDValue === 1 &&
      !isLesseePayoffDisclaimerAccepted &&
      TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF
    ) {
      this.setState({
        displayLesseeDisclaimer: true,
      });
    } else if (activeStep === 1 && customerWillDDValue !== 3) {
      this.setState({
        badCustomerWill: true,
      });
    } else if (
      activeStep === 1 &&
      vinDetails.programDTOInfo !== null &&
      programCertNum !== "" &&
      !isProgramCertValidated
    ) {
      this.setState({
        badCirtValid: true,
      });
    } else if (activeStep === 2) {
      if (vinDetails.inventoryDTO !== undefined) {
        if (
          vinDetails.inventoryDTO.leaseInformationDTO.alfaSourceIndicator &&
          vinDetails.inventoryDTO.leaseInformationDTO.alfaSourceIndicator.toUpperCase() ===
            "ALFA"
        ) {
          this.setState({ loadingAsyncData: true });
          this.getVehicleFleetFinancialData(
            vinDetails.inventoryDTO.vehicleIdentificationNumber,
            vinDetails.inventoryDTO.leaseInformationDTO.alfaAccountNumber,
            customerReturnDate,
            vinDetails.inventoryDTO.leaseInformationDTO.id
          ).then(() => {
            this.setState({ loadingAsyncData: false });
          });
        }
      }
      this.setState((state) => ({
        activeStep: state.activeStep + 1,
        loading: false,
      }));
      if (isPurchase) {
        this.setState((state) => ({
          activeStep: 4,
          loading: false,
        }));
      }
    } else {
      this.setState((state) => ({
        activeStep: state.activeStep + 1,
        loading: false,
      }));
    }
  };

  getVehicleFleetFinancialData = async (
    vin,
    alfaAccountNumber,
    customerReturnDate,
    leaseInformationId
  ) => {
    const response = await FleetFinanicalService.getVehicleFleetFinancialData(
      vin,
      alfaAccountNumber,
      customerReturnDate,
      leaseInformationId
    );

    if (
      (response &&
        response.failure &&
        response.failure === PAY_OFF_NOT_RECEIVED) ||
      response == null
    ) {
      this.setState({
        alfaVehicleDataNotRecieved: true,
      });
    }
  };

  handleBack = () => {
    let prevStep = 1;
    if (this.state.isPurchase) {
      switch (this.state.activeStep) {
        case 5:
          prevStep = 1;
          break;
        case 2:
          prevStep = 5;
          break;
        default:
          prevStep = this.state.activeStep - 1;
          break;
      }
    } else {
      prevStep = this.state.activeStep - 1;
    }

    this.setState({
      activeStep: prevStep,
    });
  };

  handleGroundingError = () => {
    this.setState(() => ({
      activeStep: 0,
      alfaVehicleDataNotRecieved: false,
    }));
  };

  handleLesseePayoffDisclaimerClose = () => {
    this.setState(() => ({
      displayLesseeDisclaimer: false,
      isLesseePayoffDisclaimerAccepted: false,
      isPurchase: false,
      activeStep: 1,
    }));
  };

  handleLesseePayoffDisclaimerConfirm = () => {
    this.setState((state) => ({
      displayLesseeDisclaimer: false,
      activeStep: 5,
      isPurchase: true,
      loading: false,
    }));
    this.renderStep(this.state.activeStep);
  };

  render() {
    const steps = getSteps(this.state.isPurchase);
    const {
      activeStep,
      renderBuy,
      renderPass,
      guaranteePayment,
      vinDetails,
      outStandingPayments,
      waivedPayments,
      guaranteePaymentLPW,
      displayLesseeDisclaimer,
      isPurchase,
    } = this.state;
    const { classes, user } = this.props;
    const { TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF } =
      this.props.togglzMap || {};
    return (
      <React.Fragment>
        <div className={classes.groundParentWrapper}>
          {!renderBuy &&
          !renderPass &&
          !guaranteePayment &&
          !guaranteePaymentLPW ? (
            <div className={classes.stepperWrapper}>
              <ReactSVG src={circleGroundIcon} className={classes.gorundIcon} />
              <Typography className={classes.groundVehicleText}>
                {TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF && isPurchase
                  ? PURCHASE_AT_PAYOFF
                  : GROUND_A_VEHICLE}
              </Typography>
              {activeStep !== 0 && this.renderStepper(activeStep, steps)}
            </div>
          ) : null}
          {guaranteePaymentLPW ? (
            <GuaranteePaymentsLPW
              car={this.getGuaranteePaymentsData(vinDetails)}
              toggleGuaranteePayment={this.toggleGuaranteePaymentLPW}
              toggleGuaranteePaymentDone={this.handleLPWGrounding}
              user={user}
              outStandingPayments={outStandingPayments}
              waivedPayments={waivedPayments}
            />
          ) : (
            this.renderStep(activeStep)
          )}
          {displayLesseeDisclaimer &&
          TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF ? (
            <div>
              <Dialog
                open={displayLesseeDisclaimer}
                onClose={this.handleLesseePayoffDisclaimerClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  className={classes.disclaimerTitle}
                  id="alert-dialog-title"
                >
                  {"Disclaimer"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    I acknowledge that I am facilitating a purchase on behalf of
                    the lessee. This transaction will result in a direct payoff
                    purchase of the vehicle and not a lessee turn-in.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    className={classes.disclaimerButton}
                    onClick={this.handleLesseePayoffDisclaimerClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.disclaimerButton}
                    onClick={this.handleLesseePayoffDisclaimerConfirm}
                    autoFocus
                  >
                    Accept
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    accountSetting: state.accountSetting,
    navLocation: state.ui.navLocation,
    togglzMap: state.ui.togglzMap,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Ground));
