import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Snackbar,
  Typography,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "react-day-picker/lib/style.css";
import DateFnsUtils from "@date-io/date-fns";
import {
  ODOMETER_PROBLEM_LESSEE_PAYOFF,
  ODOMETER_PROBLEM_RETURN_TO_TFS_LFS,
} from "../constants/UILabel";

const styles = (theme) => ({
  root: {
    display: "flex",
    color: theme.palette.primary.gray,
  },
  formControl: {
    margin: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      margin: ".5rem",
    },
  },
  subvalue: {
    paddingLeft: "1.5rem",
  },
  radioRoot: {
    color: theme.palette.primary.gray,
  },
  radioLabel: {
    fontSize: ".75rem",
  },
  checked: {},
  colorPrimary: {
    "&$checked": {
      color: theme.palette.grounding.main,
    },
  },
  errorSnack: {
    marginTop: "7rem",
  },
  odometerTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      color: theme.palette.grounding.main,
      fontWeight: "600",
    },
  },
  datePickerWidth: {
    width: "10rem",
    marginTop: "0rem",
    marginBottom: "0rem",
    marginRight: "0rem",
    marginLeft: "0rem",
  },
  custReturnDateLabel: {
    fontSize: "medium",
    fontWeight: "0.875rem",
  },

  custReturnDate: {
    marginTop: "12px",
    marginLeft: "8px",
  },
});

export class RadioButtonsGroup extends Component {
  render() {
    const {
      classes,
      handleChange,
      value,
      badValueSubmit,
      customerHasSigned,
      odoWarningConfirmation,
      customerWillSignPaperStatement,
      padValue,
      customerWillDDValue,
    } = this.props;
    const { TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF } = this.props.togglzMap;
    const radioClasses = {
      root: classes.radioRoot,
      colorPrimary: classes.colorPrimary,
      checked: classes.checked,
    };
    return (
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <Typography variant="h5" className={classes.odometerTitle}>
            Federal Odometer Disclosure Statement Options:
          </Typography>
          <FormControlLabel
            id="signed"
            value={"true"}
            checked={customerHasSigned === "true"}
            control={<Radio classes={radioClasses} color="primary" />}
            onClick={handleChange("disclosureStatementValue")}
            label={
              <Typography className={classes.radioLabel}>
                The customer <strong>has signed</strong> the odometer statement
              </Typography>
            }
          />
          <RadioGroup
            aria-label="signed"
            name="disclosureStatementValue"
            className={classes.group}
            value={value}
            onChange={handleChange("disclosureStatementValue")}
          >
            <FormControlLabel
              id="customerCertified"
              disabled={customerHasSigned !== "true"}
              className={classes.subvalue}
              value={"113"}
              control={<Radio classes={radioClasses} color="primary" />}
              label={
                <Typography className={classes.radioLabel}>
                  The customer certified, to the best of my knowledge, the
                  mileage reflected on the odometer statement is the actual
                  mileage of the above-described vehicle.
                </Typography>
              }
            />
            <FormControlLabel
              id="excessMileage"
              disabled={customerHasSigned !== "true"}
              className={classes.subvalue}
              value={"114"}
              control={<Radio classes={radioClasses} color="primary" />}
              label={
                <Typography className={classes.radioLabel}>
                  The mileage stated above reflects mileage in excess of the
                  vehicles mechanical limit.
                </Typography>
              }
            />
            <FormControlLabel
              id="doesNotMatch"
              disabled={customerHasSigned !== "true"}
              className={classes.subvalue}
              value={"115"}
              control={<Radio classes={radioClasses} color="primary" />}
              label={
                <Typography className={classes.radioLabel}>
                  The customer certifies that the odometer reading IS NOT the
                  actual mileage of the vehicle stated above.
                </Typography>
              }
            />
          </RadioGroup>
          <FormControlLabel
            id="notSigned"
            value={"false"}
            checked={customerHasSigned === "false"}
            control={<Radio classes={radioClasses} color="primary" />}
            onClick={
              customerWillSignPaperStatement || !!padValue
                ? null
                : handleChange("disclosureStatementValue")
            }
            disabled={customerWillSignPaperStatement || !!padValue}
            label={
              <Typography className={classes.radioLabel}>
                The customer <strong>has not signed</strong> the odometer
                statement
              </Typography>
            }
          />
        </FormControl>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={badValueSubmit}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              {"Please make an odometer statement selection."}
            </span>
          }
          className={classes.errorSnack}
          autoHideDuration={3000}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={odoWarningConfirmation}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              {TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF &&
              customerWillDDValue === 1
                ? ODOMETER_PROBLEM_LESSEE_PAYOFF
                : ODOMETER_PROBLEM_RETURN_TO_TFS_LFS}
            </span>
          }
          className={classes.errorSnack}
          autoHideDuration={3000}
        />
      </div>
    );
  }
}

RadioButtonsGroup.propTypes = {
  classes: PropTypes.object.isRequired,
};
export const mapStateToProps = (state) => {
  return {
    togglzMap: state.ui.togglzMap,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(RadioButtonsGroup));
