import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { GridList } from "@material-ui/core";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import CarTile from "../components/CarTile";
import EmptyListPlaceholder from "../components/EmptyListPlaceholder";

import { BACK, NO_RESULT_FOUND, AUTO_SEARCH } from "../constants/UILabel";

const styles = (theme) => ({
  rootHPCD: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    maxHeight: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  rootWidth: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    maxHeight: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  rootHPCDMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      width: "100%",
    },
  },
  rootWidthMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      width: "100%",
    },
  },
  gridListHPCD: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    height: "100%",
    margin: "0 !important",
    overflowY: "initial",
    overflowX: "auto",
    width: "100%",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "unset",
    },
  },
  leftNavHPCD: {
    display: "flex",
    zIndex: 2,
    left: 0,
    backgroundColor: "rgb(0,0,0,.05)",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,.1)",
    },
    cursor: "pointer",
    maxWidth: "1.4rem !important",
    maxHeight: "100% !important",
  },
  rightNavHPCD: {
    display: "flex",
    zIndex: 2,
    right: 0,
    backgroundColor: "rgb(0,0,0,.05)",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,.1)",
    },
    cursor: "pointer",
    maxWidth: "1.4rem !important",
    maxHeight: "100% !important",
  },
  navIcon: {
    zIndex: 3,
    color: theme.palette.primary.black,
    cursor: "pointer",
  },
});

export class HomepageCarDisplay extends Component {
  state = {
    displayCar: {},
    currentStart: 0,
    currentEnd: 4,
  };

  componentWillMount = () => {
    const { carList } = this.props;
    if (carList[0]) {
      this.setState({
        displayedCars: carList.slice(0, 4),
      });
    }
  };

  navigateImages = (nav) => {
    const { currentStart, currentEnd } = this.state;
    if (nav === BACK) {
      if (currentStart > 0) {
        this.setState({
          displayedCars: this.props.carList.slice(
            currentStart - 4,
            currentEnd - 4
          ),
          currentStart: currentStart - 4,
          currentEnd: currentEnd - 4,
        });
      }
    } else {
      if (currentEnd < this.props.carList.length) {
        this.setState({
          displayedCars: this.props.carList.slice(
            currentStart + 4,
            currentEnd + 4
          ),
          currentStart: currentStart + 4,
          currentEnd: currentEnd + 4,
        });
      }
    }
  };

  render() {
    const { classes, carList, from, advanceSearch } = this.props;
    const { displayedCars } = this.state;
    return (
      <>
        <div className={carList[0] ? classes.rootHPCD : classes.rootWidth}>
          {displayedCars &&
          displayedCars[0] &&
          displayedCars[0].message !== NO_RESULT_FOUND ? (
            <>
              <div
                className={classes.leftNavHPCD}
                onClick={(evt) => this.navigateImages("back")}
              >
                <ArrowLeft className={classes.navIcon} />
              </div>
              <GridList className={classes.gridListHPCD} cols={2.5}>
                {displayedCars.map((car) => (
                  <CarTile key={car.vin} car={car} />
                ))}
              </GridList>
              <div
                className={classes.rightNavHPCD}
                onClick={(evt) => this.navigateImages()}
              >
                <ArrowRight className={classes.navIcon} />
              </div>
            </>
          ) : from && from === AUTO_SEARCH ? (
            <EmptyListPlaceholder
              advanceSearch={advanceSearch}
              page={"homeAutoSearch"}
            />
          ) : (
            <EmptyListPlaceholder page={"homeVehicleRequiringAction"} />
          )}
        </div>
        <div
          className={
            carList[0] ? classes.rootHPCDMobile : classes.rootWidthMobile
          }
        >
          {carList && carList[0] && carList[0].message !== NO_RESULT_FOUND ? (
            <GridList className={classes.gridListHPCD} cols={2.5}>
              {carList.map((car) => (
                <CarTile key={car.vin} car={car} />
              ))}
            </GridList>
          ) : from && from === AUTO_SEARCH ? (
            <EmptyListPlaceholder
              advanceSearch={advanceSearch}
              page={"homeAutoSearch"}
            />
          ) : (
            <EmptyListPlaceholder page={"homeVehicleRequiringAction"} />
          )}
        </div>
      </>
    );
  }
}

HomepageCarDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomepageCarDisplay);
