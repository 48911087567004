import React from "react";
import { withStyles } from "@material-ui/core";

import ReactSVG from "react-svg";
import GAMDNavIcon from "../assets/GAMDNavIcon.svg";
import DDONavIcon from "../assets/DDONavIcon.svg";
import watchlistIcon from "../assets/watchlistIcon.svg";
import Search from "../assets/search.svg";

import { Typography, Button } from "@material-ui/core";

import {
  GAMD,
  WATCHLIST,
  DDO_UPPER_CASE,
  SEARCH,
  HOME_AUTO_SEARCH,
  HOME_VEHICLE_REQUIRING_ACTION,
  SETUP_AUTOSEARCH,
  NO_CARS_FOUND,
} from "../constants/UILabel";

const styles = (theme) => ({
  container: {
    width: "100%",
    height: "90%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: "2rem",
  },
  homeText: {
    fontSize: "1rem",
  },
  linkStyle: {
    color: theme.palette.button.main,
  },
  advanceSearchButton: {
    height: "2.5rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "9rem",
    fontWeight: "bold",
    textTransform: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "4rem",
      height: "2rem",
      marginLeft: 0,
      fontSize: theme.font.small,
    },
  },
});

const renderIcon = (page) => {
  const svgStyle = {
    height: 100,
    width: 100,
  };
  const homeSvgStyle = {
    height: 30,
    width: 30,
    fill: "#1B55A6",
  };
  switch (page) {
    case GAMD:
      return (
        <ReactSVG src={GAMDNavIcon} svgStyle={{ height: 200, width: 200 }} />
      );
    case WATCHLIST:
      return <ReactSVG src={watchlistIcon} svgStyle={svgStyle} />;
    case DDO_UPPER_CASE:
      return <ReactSVG src={DDONavIcon} svgStyle={svgStyle} />;
    case SEARCH:
      return <ReactSVG src={Search} svgStyle={svgStyle} />;
    case HOME_AUTO_SEARCH:
      return <ReactSVG src={Search} svgStyle={homeSvgStyle} />;
    case HOME_VEHICLE_REQUIRING_ACTION:
      return (
        <ReactSVG src={GAMDNavIcon} svgStyle={{ height: 60, width: 60 }} />
      );
    default:
      return null;
  }
};

const EmptyListPlaceholder = (props) => {
  const { classes, page, advanceSearch } = props;
  return page === HOME_AUTO_SEARCH ? (
    <div className={classes.container}>
      <Typography className={classes.homeText}>
        <Button
          id="autoSearchLink"
          className={classes.advanceSearchButton}
          onClick={() => advanceSearch()}
        >
          {SETUP_AUTOSEARCH}
        </Button>
      </Typography>
    </div>
  ) : (
    <div className={classes.container}>
      {renderIcon(page)}
      <Typography
        className={
          page === HOME_AUTO_SEARCH || HOME_VEHICLE_REQUIRING_ACTION
            ? classes.homeText
            : classes.text
        }
      >
        {NO_CARS_FOUND}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(EmptyListPlaceholder);
