import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Card,
  Button,
} from "@material-ui/core";
import Moment from "react-moment";

import * as WatchListServices from "../services/WatchListServices";
import Loading from "../components/Loading";

const styles = (theme) => ({
  bidHistoryCard: {
    width: "100%",
    position: "relative",
    maxHeight: "12rem",
  },
  bidHistoryRoot: {
    maxWidth: "80%",
  },
  bHTable: {
    margin: "1rem",
    width: "auto",
    height: "fit-content",
    overflowY: "auto",
  },
  tableCellHead: {
    border: `1px solid ${theme.palette.secondary.gray}`,
    padding: 0,
    height: "1rem",
    textAlign: "center",
    width: "13rem",
  },
  tableCell: {
    border: `1px solid ${theme.palette.secondary.gray}`,
    padding: 0,
    height: "auto",
    textAlign: "center",
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
  },
  button: {
    margin: ".5rem",
    marginLeft: 0,
    height: "2rem",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.main,
    width: "5rem",
    fontWeight: "bold",
    textTransform: "initial",
    fontSize: theme.font.small,
    position: "absolute",
    right: "1rem",
    top: "2rem",
  },
  buttonWrapper: {
    textAlign: "right",
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
    maxWidth: "97%",
  },
  cardTitle: {
    color: theme.palette.primary.white,
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.redHighlight,
    textAlign: "center",
    borderRadius: "0 0 .75rem .75rem",
  },
  overflowDiv: {
    height: "9rem",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2.5rem",
    },
  },
});

export class BidHistory extends Component {
  state = {
    bidInfo: {},
    pageLoading: true,
  };

  componentWillMount = () => {
    this.getBidHistory();
  };

  getBidHistory = async () => {
    const { saleEventItemId } = this.props;
    const bidHistoryInfo = await WatchListServices.getBidHistory(
      saleEventItemId
    );
    if (bidHistoryInfo && bidHistoryInfo.length > 0) {
      const sortByAmount = bidHistoryInfo.sort((a, b) => {
        return b.bidAmount - a.bidAmount;
      });
      const sortByDate = sortByAmount.sort((a, b) => {
        return b.bidTime - a.bidTime;
      });
      this.setState({
        bidInfo: sortByDate,
        pageLoading: false,
      });
    }
  };

  render() {
    const { classes, toggleBidCount, from } = this.props;
    const { pageLoading, bidInfo } = this.state;
    return (
      <React.Fragment>
        <Card className={classes.bidHistoryCard}>
          {pageLoading ? (
            <Loading />
          ) : (
            <>
              {from !== "BidVehicle" && from !== "DDOCarListTile" && (
                <Typography className={classes.cardTitle}>
                  Bid History
                </Typography>
              )}
              <div className={classes.overflowDiv}>
                <Table className={classes.bHTable}>
                  <TableBody>
                    <TableRow key={"head"} className={classes.tableRow}>
                      <TableCell className={classes.tableCellHead}>
                        <strong>Date/Time</strong>
                      </TableCell>
                      <TableCell className={classes.tableCellHead}>
                        <strong>Business Name</strong>
                      </TableCell>
                      <TableCell className={classes.tableCellHead}>
                        <strong>Bid Amount</strong>
                      </TableCell>
                      {(from === "BidVehicle" || from === "DDOCarListTile") && (
                        <TableCell className={classes.tableCellHead}>
                          <strong>Bid Type</strong>
                        </TableCell>
                      )}
                    </TableRow>
                    {bidInfo.map((info) =>
                      info.bidAmount ? (
                        <TableRow
                          key={
                            info.bidType === "AutoBid"
                              ? info.autoBidAmount
                              : info.bidAmount
                          }
                          className={classes.tableRow}
                        >
                          <TableCell className={classes.tableCell}>
                            <Moment
                              interval={0}
                              format={"YYYY-MM-DD HH:mm:ss.SSS"}
                            >
                              {info.bidTime}
                            </Moment>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {info.businessName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {info.bidAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </TableCell>
                          {(from === "BidVehicle" ||
                            from === "DDOCarListTile") && (
                            <TableCell className={classes.tableCell}>
                              {info.bidType === "AutoBid" ? "Proxy" : "Hard"}
                            </TableCell>
                          )}
                        </TableRow>
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </div>
              {from === "BidHistoryReport" ||
              from === "BidVehicle" ||
              from === "DDOCarListTile" ? null : (
                <div className={classes.buttonWrapper}>
                  <Button
                    id="closeButton"
                    className={classes.button}
                    size="small"
                    onClick={(evt) => toggleBidCount()}
                  >
                    Close
                  </Button>
                </div>
              )}
            </>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(BidHistory);
