import {
  MOBILITY_SEARCH_CRITERIA,
  MOBILITY_SAVED_SEARCH,
  AUCTION_LOCATION_DATA,
} from "../actions/mobility.actions";
import moment from "moment";

export const mobilityState = {
  mobilitySearchCriteria: null,
  mobilitySavedSearch: null,
  auctionLocationData: null,
  activeMobilitySavedSearch: null,
  inactiveMobilitySavedSearch: null,
};

const sortSearch = (savedSearch) => {
  let search = savedSearch.slice(0);
  search.sort(function (a, b) {
    return moment(b.searchStartDate).unix() - moment(a.searchStartDate).unix();
  });
  return search;
};

const filterSavedSearch = (savedSearch) => {
  var currentDate = moment(new Date().setHours(0, 0, 0, 0)).unix();
  let activeSearch = [];
  let inactiveSearch = [];
  savedSearch.forEach((element) => {
    if (moment(element.searchEndDate).unix() < currentDate) {
      inactiveSearch.push(element);
    } else {
      activeSearch.push(element);
    }
  });
  let filteredSearch = {
    activeSearch,
    inactiveSearch,
  };
  return filteredSearch;
};

export default (state = mobilityState, action) => {
  switch (action.type) {
    case MOBILITY_SEARCH_CRITERIA:
      return {
        ...state,
        mobilitySearchCriteria: action.mobilitySearchCriteria,
      };
    case MOBILITY_SAVED_SEARCH:
      const filteredSearch = filterSavedSearch(action.mobilitySavedSearch);
      return {
        ...state,
        mobilitySavedSearch: action.mobilitySavedSearch,
        activeMobilitySavedSearch: sortSearch(filteredSearch.activeSearch),
        inactiveMobilitySavedSearch: sortSearch(filteredSearch.inactiveSearch),
      };
    case AUCTION_LOCATION_DATA:
      return {
        ...state,
        auctionLocationData: action.auctionLocationData,
      };
    default:
      return state;
  }
};
