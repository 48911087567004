import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {
  FormLabel,
  Paper,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
  Typography,
} from "@material-ui/core";

import VINEntry from "../components/VINEntry";
import Message from "../components/Message";
import LPWEntryForm from "../components/LPWEntryForm";
import GroundingInformationDisplay from "../components/GroundingInformationDisplay";
import { hide_mobile_nav_menu } from "../actions/uiActions";
import {
  GROUNDING_MESSAGES,
  MAKE_YOUR_SELECTION,
  PURCHASE_LEASE_VEHICLE,
  LESSEE_PAYOFF_PURCHASE,
  RETURN_FOR_TRADEIN,
  RETURN_VEHICLE_FOR_TFS_LFS,
  INELEGIBLE_USER,
} from "../constants/UILabel";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = (theme) => ({
  label: {
    fontSize: ".8rem",
    marginBottom: ".5rem",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.grounding.main,
      fontSize: "1rem",
      fontWeight: "600",
      marginTop: ".7rem",
      marginBottom: ".7rem",
      marginLeft: ".5rem",
      textAlign: "left",
    },
  },
  vehicleInfoContainer: {
    width: "auto",
    display: "block",
    margin: ".5rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  vehicleInfoVINEntryContainer: {
    margin: ".5rem",
    display: "inline-block",
    width: "auto",
    marginTop: ".5rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
    },
  },
  infoParentWrapper: {
    margin: "1rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  infoWrapper: {
    margin: "1rem",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      flexDirection: "column",
    },
  },
  h2Padding: {
    margin: ".5rem",
    fontWeight: "initial",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.grounding.main,
      fontSize: ".8rem",
      fontWeight: "600",
      margin: ".5rem",
    },
  },
  paper: {
    paddingTop: ".5rem",
    alignItems: "center",
    width: "90%",
    borderRadius: 0,
    margin: "auto",
    height: "fit-content",
    paddingBottom: ".5rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
      width: "100%",
    },
  },
  formControl: {
    minWidth: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tableRow: {
    height: "1rem",
  },
  tableCell: {
    borderBottom: 0,
    width: "15rem",
    padding: 0,
    color: theme.palette.primary.gray,
    height: "auto !important",
  },
  messageContainer: {
    alignItems: "center",
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
    },
  },
  paperWrapper: {
    alignItems: "center",
    margin: "auto",
  },
  programInfoHighlight: {
    display: "inline",
    color: "#CC0000",
  },
  textField: {
    marginLeft: ".5rem",
    marginRight: ".5rem",
    width: "100%",
  },
  textfieldWrapper: {
    margin: "auto",
    width: "30%",
  },
  selectWrapper: {
    position: "relative",
    bottom: "2rem",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      paddingTop: 0,
      position: "inherit",
      width: "100%",
      alignItems: "flex-start",
    },
  },
  stepperWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    margin: ".5rem",
    height: theme.stepper.height,
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      borderBottom: `0.01rem solid ${theme.palette.grounding.main}`,
      width: "100%",
      minHeight: "2.5rem",
    },
  },
  select: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      alignSelf: "center",
    },
  },
  lpwWrapper: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem",
    },
  },
  datePickerWidth: {
    width: "11rem",
    marginTop: "0rem",
    marginBottom: "0rem",
    marginRight: "0rem",
    marginLeft: "0rem",
  },
});

export class VehicleInformation extends Component {
  state = {
    labelWidth: 0,
  };

  componentWillMount = () => {
    const { dispatch } = this.props;
    this.myRef = createRef();
    dispatch(hide_mobile_nav_menu(true));
  };

  componentDidMount = () => {
    if (this.myRef.current) {
      this.myRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  render() {
    const {
      classes,
      vin,
      onVinChange,
      messageVariant,
      errorMessage,
      onVinSearch,
      onCustomerWillDDChange,
      customerWillDDValue,
      onHandleChange,
      programCertNum,
      programCertComments,
      isProgramCertValidated,
      hasGuaranteedPayment,
      handleGuaranteedPayment,
      badCustomerWill,
      denyGuaranteedPayment,
      cancelLPW,
      handleCertValidationChange,
      handleCertValidationChangefalse,
      activeStep,
      vinEntered,
      vinDetails,
      showGroundingSuccessMessages,
      isCompanyCar,
      handleDateChange,
      customerReturnDate,
      badCustReturnDate,
    } = this.props;
    const { make, model, vehicleYear } = this.props.vinDetails.inventoryDTO;
    const { programDTOs } = this.props.vinDetails;
    const { TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF } =
      this.props.togglzMap || {};
    let programDTOInfo = null;
    if (programDTOs !== undefined && programDTOs !== null) {
      programDTOInfo = programDTOs[0];
    }
    return (
      <React.Fragment>
        <div>
          <div className={classes.vehicleInfoContainer}>
            <div className={classes.vehicleInfoVINEntryContainer}>
              <VINEntry
                activeStep={activeStep}
                vin={vin}
                errorMessage={errorMessage}
                messageVariant={messageVariant}
                onVinChange={onVinChange}
                onVinSearch={onVinSearch}
                vinEntered={vinEntered}
                handleChange={onHandleChange}
                secondStep={true}
                customerWillDDValue={customerWillDDValue}
                showGroundingSuccessMessages={showGroundingSuccessMessages}
                customerReturnDate={customerReturnDate}
                badCustReturnDate={badCustReturnDate}
              />
            </div>
          </div>
          <div className={classes.infoParentWrapper}>
            <div className={classes.paperWrapper}>
              {!TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF ? (
                <div className={classes.messageContainer}>
                  {customerWillDDValue !== 0 && customerWillDDValue !== 3 && (
                    <Message
                      onClose={this.handleClose}
                      variant="error"
                      message={GROUNDING_MESSAGES.CANNOT_GROUND_PURCHASE}
                    />
                  )}
                </div>
              ) : null}

              <div className={classes.messageContainer}>
                {badCustomerWill && (
                  <Message
                    onClose={this.handleClose}
                    variant="error"
                    message={INELEGIBLE_USER}
                  />
                )}
              </div>

              <Paper className={classes.paper}>
                <h2 className={classes.h2Padding}>
                  {make} {model} {vehicleYear}
                </h2>
                <div className={classes.infoWrapper}>
                  <GroundingInformationDisplay
                    vinDetails={this.props.vinDetails}
                  />
                  <div className={classes.selectWrapper}>
                    <FormControl
                      error={badCustomerWill}
                      variant="standard"
                      className={classes.formControl}
                    >
                      <FormLabel className={classes.label}>
                        Customer/Lessee Will
                      </FormLabel>
                      <Select
                        id="groundMethodSelect"
                        className={classes.select}
                        value={customerWillDDValue}
                        onChange={onCustomerWillDDChange}
                        input={
                          <OutlinedInput
                            labelWidth={this.state.labelWidth}
                            name="choice"
                            id="outlined-choice-simple"
                            style={{ fontSize: ".75rem" }}
                          />
                        }
                      >
                        <MenuItem id="0" disabled value={0}>
                          {MAKE_YOUR_SELECTION}
                        </MenuItem>

                        {TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF ? (
                          isCompanyCar ? (
                            ""
                          ) : (
                            <MenuItem id="1" value={1}>
                              {" "}
                              {LESSEE_PAYOFF_PURCHASE}{" "}
                            </MenuItem>
                          )
                        ) : (
                          <MenuItem id="1" value={1}>
                            {PURCHASE_LEASE_VEHICLE}{" "}
                          </MenuItem>
                        )}

                        {!TDDMAIN_4047_PURCHASE_TYPE_LESSEE_PAYOFF && (
                          <MenuItem id="2" value={2}>
                            {RETURN_FOR_TRADEIN}
                          </MenuItem>
                        )}
                        <MenuItem id="3" value={3}>
                          {RETURN_VEHICLE_FOR_TFS_LFS}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <div className={classes.stepperWrapper}>
                      <FormControl>
                        <FormLabel className={classes.label}>
                          Customer Return Date{" "}
                        </FormLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            name="customerReturnDate"
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="customerReturnDatePicker"
                            value={customerReturnDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            className={classes.datePickerWidth}
                            placeholder="mm/dd/yyyy"
                            disableFuture={true}
                            inputProps={{ readOnly: true }}
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                      {this.props.children}
                    </div>
                  </div>
                </div>
              </Paper>
              {programDTOInfo !== null && customerReturnDate ? (
                <div ref={this.myRef} className={classes.lpwWrapper}>
                  <LPWEntryForm
                    vinDetails={vinDetails}
                    onHandleChange={onHandleChange}
                    programDTOInfo={programDTOInfo}
                    programCertNum={programCertNum}
                    programCertComments={programCertComments}
                    isProgramCertValidated={isProgramCertValidated}
                    denyGuaranteedPayment={denyGuaranteedPayment}
                    hasGuaranteedPayment={hasGuaranteedPayment}
                    handleGuaranteedPayment={handleGuaranteedPayment}
                    certVerifyMessage={
                      GROUNDING_MESSAGES.PAYMENT_WAIVER_PROGRAM
                    }
                    cancelLPW={cancelLPW}
                    handleCertValidationChange={handleCertValidationChange}
                    handleCertValidationChangefalse={
                      handleCertValidationChangefalse
                    }
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    togglzMap: state.ui.togglzMap,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(VehicleInformation));
