import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  buyVehicleInformation: {
    display: "flex",
    marginTop: ".5rem",
    marginLeft: ".2rem",
    width: "18rem",
    flexDirection: "column",
  },
  data: {
    marginBottom: ".25rem",
    color: theme.palette.primary.gray,
  },
});

export class BuyVehicleInformation extends Component {
  handleVinClick = () => {
    //TODO link to condition report
    console.log("Link to Condition Report");
  };

  render() {
    const { car, classes } = this.props;
    return (
      <div className={classes.buyVehicleInformation}>
        <Typography className={classes.data}>
          VIN:{" "}
          <strong>{car.vin ? car.vin : car.vehicleIdentificationNumber}</strong>
        </Typography>
        <Typography className={classes.data}>
          Description:{" "}
          <strong>
            {car.year} {car.make} {car.model}
          </strong>
        </Typography>
        <Typography
          id={car.inspectionMileage ? "inspectionMileage" : "groundingMileage"}
          className={classes.data}
        >
          {" "}
          {car.inspectionMileage ? "Inspection Mileage:" : "Odometer:"}{" "}
          <strong>
            {car.inspectionMileage || car.groundingMileage || car.odometer}
          </strong>
        </Typography>
      </div>
    );
  }
}
export default withStyles(styles)(BuyVehicleInformation);
