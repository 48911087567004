import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, Checkbox } from "@material-ui/core";

import CarReport from "../components/CarReport";
import AutoCheckReport from "../components/AutoCheckReport";
import CancelConfirm from "../components/CancelConfirm";
import SendPurchaseDetails from "../components/SendPurchaseDetails";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import ReactSVG from "react-svg";
import carfaxLogo from "../assets/carfaxLogo.svg";
import autoCheckLogo from "../assets/autoCheckLogo.svg";

const awsUrl = process.env.REACT_APP_WCM_CONTENT;

const styles = (theme) => ({
  confirmPage: {
    display: "flex",
  },
  carReportLink: {
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    height: "1rem",
  },
  innerVehicleInfoWrapper: {
    paddingBottom: "1rem",
  },
  label: {
    color: theme.palette.primary.gray,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    margin: ".5rem",
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      display: "flex",
      width: "100vw",
      margin: 0,
    },
  },
  buyWrapper: {
    display: "flex",
    marginTop: ".5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      flexDirection: "column-reverse",
      paddingBottom: "2rem",
      marginTop: "3.5rem",
    },
  },
  buyPriceWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "51%",
    justifyContent: "left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  lowerLeftWrapper: {
    marginRight: "2rem",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      margin: 0,
    },
  },
  lowerVehicleInfoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginLeft: ".5rem",
      marginRight: ".5rem",
    },
  },
  carReportWrapper: {
    display: "flex",
    flexDirection: "column",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
  },
  carInfoTitle: {
    fontSize: "1.5rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
      marginRight: ".5rem",
      fontSize: "1.2rem",
    },
  },
  infoWrapper: {
    width: "87%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginLeft: ".5rem",
      marginRight: ".5rem",
      marginTop: ".5rem",
    },
  },
  lineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    paddingBottom: ".2rem",
  },
  paymentLineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".75rem",
    paddingBottom: ".2rem",
  },
  bolded: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: "50%",
    },
  },
  GAMDBoldedColor: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.GAMD.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  DDOBoldedColor: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.DDO.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  LPPBoldedColor: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.grounding.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  GAMDPricingInfoHeader: {
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.GAMD.main,
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  DDOPricingInfoHeader: {
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.DDO.main,
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  LPPPricinfInfoHeader: {
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.grounding.main,
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  GAMDButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "33.333%",
      margin: 0,
    },
  },
  LPPButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.grounding.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "33.333%",
      margin: 0,
    },
  },
  DDOButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.DDO.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "33.333%",
      margin: 0,
    },
  },
  GAMDCloseButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "33.333%",
      margin: 0,
      backgroundColor: theme.palette.secondary.gray,
    },
  },
  LPPCloseButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.grounding.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "33.333%",
      margin: 0,
      backgroundColor: theme.palette.secondary.gray,
    },
  },
  DDOCloseButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.DDO.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "33.333%",
      margin: 0,
      backgroundColor: theme.palette.secondary.gray,
    },
  },
  GAMDCancelButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "33.333%",
      margin: 0,
      backgroundColor: theme.palette.primary.redHighlight,
    },
  },
  DDOCancelButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.DDO.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "33.333%",
      margin: 0,
      backgroundColor: theme.palette.primary.redHighlight,
    },
  },
  buttonLabel: {
    fontSize: ".6rem",
  },
  systemMessage: {
    fontSize: ".5rem",
    position: "absolute",
    top: "1.4rem",
    right: "2rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  systemMessage2: {
    fontSize: ".5rem",
    position: "absolute",
    top: "6rem",
    color: theme.palette.primary.gray,
    right: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  checked: {},
  checkboxRootGAMD: {
    padding: 0,
    height: "1rem",
    paddingLeft: ".5rem",
    "&$checked": {
      color: theme.palette.GAMD.main,
    },
  },
  checkboxRootDDO: {
    padding: 0,
    height: "1rem",
    paddingLeft: ".5rem",
    "&$checked": {
      color: theme.palette.DDO.main,
    },
  },
  checkboxRootLPP: {
    padding: 0,
    height: "1rem",
    paddingLeft: ".5rem",
    "&$checked": {
      color: theme.palette.grounding.main,
    },
  },

  priceWrapper: {
    display: "flex",
  },
  paymentSelectionWrapper: {
    marginTop: ".5rem",
    marginBottom: ".5rem",
    [theme.breakpoints.down("sm")]: {
      margin: ".5rem",
    },
  },
  paymentSelectionTitle: {
    marginBottom: ".5rem",
  },
  acceptanceText: {
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".5rem",
      marginLeft: ".5rem",
      marginRight: ".5rem",
    },
  },
  GAMDthanksHeader: {
    backgroundColor: theme.palette.GAMD.main,
    borderRadius: "1rem 1rem 0 0",
    fill: theme.palette.GAMD.main,
    color: theme.palette.primary.white,
    fontSize: "1rem",
    fontWeight: "bold",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1% .5rem 0 .5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  LPPthanksHeader: {
    backgroundColor: theme.palette.grounding.main,
    borderRadius: "1rem 1rem 0 0",
    fill: theme.palette.grounding.main,
    color: theme.palette.primary.white,
    fontSize: "1rem",
    fontWeight: "bold",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1% .5rem 0 .5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  DDOthanksHeader: {
    backgroundColor: theme.palette.DDO.main,
    borderRadius: "1rem 1rem 0 0",
    fill: theme.palette.DDO.main,
    color: theme.palette.primary.white,
    fontSize: "1rem",
    fontWeight: "bold",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1% .5rem 0 .5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imageWrapper: {
    display: "flex",
    height: "7rem",
    marginRight: "3rem",
    width: "96%",
    marginBottom: "3%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  vehicleImage: {
    width: "18%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buyNowSystemMessage: {
    fontSize: ".5rem",
    position: "absolute",
    top: "1.4rem",
    right: "2rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buyNowSystemMessage2: {
    fontSize: ".5rem",
    position: "absolute",
    top: "3.7rem",
    color: theme.palette.primary.gray,
    right: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  NOSMessage: {
    fontSize: theme.font.small,
    color: theme.palette.primary.redHighlight,
    marginTop: ".25rem",
    marginLeft: "3rem",
    display: "flex",
    justifyContent: "center",
    width: "35rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

export class ConfirmPage extends Component {
  state = {
    showCarfaxReport: false,
    showAutoCheckReport: false,
    cancelConfirm: false,
    timeRemainingToCancel: 300000,
    showCarfaxSnackBar: false,
  };

  componentWillMount = () => {
    this.tickDown = setInterval(() => {
      this.setState({
        timeRemainingToCancel: this.state.timeRemainingToCancel - 1000,
      });
    }, 1000);
  };

  getPaymentOptionLabel = (paymentOptionCode) => {
    const { user } = this.props;
    switch (paymentOptionCode) {
      case "ACH":
        return "ACH";
      case "FP":
        return `${
          user.franchise === "TBSG" ? "TFS" : "LFS"
        } Pre-Owned Floor Plan`;
      case "CHQ":
        return "Check";
      default:
        return "";
    }
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  showAutoCheckReport = () => {
    this.setState({
      showAutoCheckReport: !this.state.showAutoCheckReport,
    });
  };

  toggleCancelConfirm = () => {
    this.setState({
      cancelConfirm: !this.state.cancelConfirm,
    });
  };

  renderChosenPrice = () => {
    const { priceOptionCode } = this.props.purchaseOptions;
    const { car } = this.props;
    switch (priceOptionCode) {
      case "RES":
        return car.residualAmount;
      case "GPO":
        return car.grossPayoff;
      case "MBP":
        return car.marketBasedPrice;
      case "BNP":
        return car.discountedBuyNowPrice
          ? car.discountedBuyNowPrice
          : car.buyNowPrice;
      case "LESSEE-PAYOFF":
        return car.grossPayoff;
      default:
        return "N/A";
    }
  };

  renderLowerRight = () => {
    const { cancelConfirm, timeRemainingToCancel } = this.state;
    const {
      car,
      classes,
      toggleShowEmailNotification,
      showEmailNotification,
      toggleBuyVehicle,
      toggleBuyVehicleDone,
      returnToHomePage,
      toggleBuy,
      page,
      ground,
      cancelTickDown,
      cancelTimeout,
      purchaseOptions,
      pageTheme,
    } = this.props;
    const { paymentOptionCode, transportMethodSelected } =
      this.props.purchaseOptions;
    if (!cancelConfirm && !showEmailNotification) {
      return (
        <React.Fragment>
          <div className={classes.paymentSelectionWrapper}>
            <Typography className={classes.paymentSelectionTitle}>
              Payment Method
            </Typography>
            <div className={classes.paymentLineWrapper}>
              <div className={classes.priceWrapper}>
                <Checkbox
                  classes={{
                    root:
                      page === "GAMD"
                        ? classes.checkboxRootGAMD
                        : page === "LPP"
                        ? classes.checkboxRootLPP
                        : classes.checkboxRootDDO,
                    checked: classes.checked,
                  }}
                  checked
                />
                <Typography
                  style={{ marginLeft: ".5rem" }}
                  className={
                    page === "GAMD"
                      ? classes.GAMDBoldedColor
                      : page === "LPP"
                      ? classes.LPPBoldedColor
                      : classes.DDOBoldedColor
                  }
                >
                  {paymentOptionCode}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.summaryWrapper}>
            <div className={classes.acceptanceWrapper}>
              <Typography className={classes.acceptanceText}>
                {`I have read and agree to the Terms and Conditions stated in
                            the `}
                <a
                  id="participatingDealerAgreement"
                  className={classes.PDALink}
                  href={
                    awsUrl +
                    (pageTheme === "Lexus"
                      ? "/docs/lexus/resources/ParticipatingDealerAgreement.pdf"
                      : "/docs/toyota/resources/ParticipatingDealerAgreement.pdf")
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Participating Dealer Agreement
                </a>
              </Typography>
              {transportMethodSelected !== "" ? (
                <Typography className={classes.acceptanceText}>
                  {`Selected Transportation: ${transportMethodSelected}`}
                </Typography>
              ) : null}
              <Typography className={classes.acceptanceText}>
                Title will be sent upon receipt of payment. Please allow 7-10
                days for delivery.
              </Typography>
            </div>
            <div className={classes.confirmPage}>
              <div className={classes.buttonWrapper}>
                <Button
                  id="emailButton"
                  className={
                    page === "GAMD"
                      ? classes.GAMDButton
                      : page === "LPP"
                      ? classes.LPPButton
                      : classes.DDOButton
                  }
                  onClick={() => toggleShowEmailNotification()}
                >
                  Email
                </Button>
                <Button
                  id="closeButton"
                  className={
                    page === "GAMD"
                      ? classes.GAMDCloseButton
                      : page === "LPP"
                      ? classes.LPPCloseButton
                      : classes.DDOCloseButton
                  }
                  onClick={() =>
                    page === "LPP"
                      ? returnToHomePage()
                      : ground
                      ? toggleBuyVehicleDone(true)
                      : toggleBuy(car, true)
                  }
                >
                  Close
                </Button>
                {page !== "LPP" ? (
                  <Button
                    id="cancelButton"
                    onClick={() => this.toggleCancelConfirm()}
                    className={
                      page === "GAMD"
                        ? classes.GAMDCancelButton
                        : classes.DDOCancelButton
                    }
                    classes={{ label: classes.buttonLabel }}
                  >
                    Cancel Purchase
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
    if (cancelConfirm && !showEmailNotification) {
      return (
        <CancelConfirm
          toggleCancelConfirm={this.toggleCancelConfirm}
          car={car}
          cancelTickDown={cancelTickDown}
          cancelTimeout={cancelTimeout}
          toggleBuy={toggleBuy}
          toggleBuyVehicle={toggleBuyVehicle}
          page={page}
          ground={ground}
          timeRemainingToCancel={timeRemainingToCancel}
        />
      );
    }
    if (showEmailNotification && !cancelConfirm) {
      return (
        <SendPurchaseDetails
          car={car}
          toggleShowEmailNotification={toggleShowEmailNotification}
          page={page}
          purchaseOptions={purchaseOptions}
        />
      );
    }
  };

  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  render() {
    const { car, classes, page, conditionReport, isBuyNow, user } = this.props;
    const { priceOptionSelected, totalPriceAmount, transportMethodSelected } =
      this.props.purchaseOptions;
    const {
      showCarfaxReport,
      cancelConfirm,
      showAutoCheckReport,
      showCarfaxSnackBar,
    } = this.state;
    return (
      <React.Fragment>
        <Typography
          className={
            page === "GAMD"
              ? classes.GAMDthanksHeader
              : page === "LPP"
              ? classes.LPPthanksHeader
              : classes.DDOthanksHeader
          }
        >
          {!cancelConfirm
            ? "Thank you for your vehicle purchase!"
            : "Confirm Purchase Cancellation"}
        </Typography>
        <div className={classes.buyWrapper}>
          {page !== "DDO" ||
          !isBuyNow ||
          priceOptionSelected === "Market Price" ? (
            <React.Fragment>
              <div className={classes.buyPriceWrapper}>
                {conditionReport ? (
                  <div className={classes.imageWrapper}>
                    {conditionReport.inspectionImageDTO
                      .slice(0, 5)
                      .map((image) => {
                        return (
                          <img
                            key={image.inspectionImagesId}
                            src={image.imageUrl}
                            className={classes.vehicleImage}
                            alt=""
                          />
                        );
                      })}
                  </div>
                ) : null}
                <div className={classes.lowerLeftWrapper}>
                  <Typography
                    className={
                      page === "GAMD"
                        ? classes.GAMDPricingInfoHeader
                        : page === "LPP"
                        ? classes.LPPPricinfInfoHeader
                        : classes.DDOPricingInfoHeader
                    }
                  >
                    Purchase Information
                  </Typography>
                  <div className={classes.infoWrapper}>
                    <div className={classes.lineWrapper}>
                      <Typography className={classes.bolded}>
                        Selected Price:{" "}
                        <span
                          className={
                            page === "GAMD"
                              ? classes.GAMDBoldedColor
                              : page === "LPP"
                              ? classes.LPPBoldedColor
                              : classes.DDOBoldedColor
                          }
                        >
                          {priceOptionSelected}
                        </span>
                      </Typography>
                      <div className={classes.priceWrapper}>
                        <Typography
                          style={{ marginRight: "2rem" }}
                          className={
                            page === "GAMD"
                              ? classes.GAMDBoldedColor
                              : page === "LPP"
                              ? classes.LPPBoldedColor
                              : classes.DDOBoldedColor
                          }
                        >
                          {this.renderChosenPrice().toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                      </div>
                    </div>
                    <Typography className={classes.systemMessage}>
                      Note: excludes tax, license, and other fees that may
                      apply*
                    </Typography>
                    <div className={classes.lineWrapper}>
                      <Typography className={classes.bolded}>
                        Administrative Fee
                      </Typography>
                      <Typography
                        style={{ marginRight: "2rem" }}
                        className={
                          page === "GAMD"
                            ? classes.GAMDBoldedColor
                            : page === "LPP"
                            ? classes.LPPBoldedColor
                            : classes.DDOBoldedColor
                        }
                      >
                        {page === "LPP"
                          ? "$ 0.00"
                          : car.adminFee.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                      </Typography>
                    </div>
                    <Typography className={classes.systemMessage2}>
                      Dealer may be responsible for outstanding fees/penalties
                      tied to any CA vehicle.
                    </Typography>
                    <div className={classes.lineWrapper}>
                      <Typography className={classes.bolded}>Total</Typography>
                      <Typography
                        style={{ marginRight: "2rem" }}
                        className={
                          page === "GAMD"
                            ? classes.GAMDBoldedColor
                            : page === "LPP"
                            ? classes.LPPBoldedColor
                            : classes.DDOBoldedColor
                        }
                      >
                        {totalPriceAmount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Typography>
                    </div>
                  </div>
                </div>
                {car.vehicleTitleDelay ? (
                  <Typography className={classes.NOSMessage}>
                    Vehicle has been grounded as an Early Termination pending
                    Notice of Sale to the lessee. As a result, release of title
                    for purchase may be delayed and is conditional on expiration
                    of the Notice of Sale
                  </Typography>
                ) : null}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={classes.buyPriceWrapper}>
                {conditionReport ? (
                  <div className={classes.imageWrapper}>
                    {conditionReport.inspectionImageDTO
                      .slice(0, 5)
                      .map((image) => {
                        return (
                          <img
                            key={image.inspectionImagesId}
                            src={image.imageUrl}
                            className={classes.vehicleImage}
                            alt=""
                          />
                        );
                      })}
                  </div>
                ) : null}
                <div className={classes.lowerLeftWrapper}>
                  <Typography
                    className={
                      page === "GAMD"
                        ? classes.GAMDPricingInfoHeader
                        : page === "LPP"
                        ? classes.LPPPricinfInfoHeader
                        : classes.DDOPricingInfoHeader
                    }
                  >
                    Purchase Information
                  </Typography>
                  <div className={classes.infoWrapper}>
                    <div className={classes.lineWrapper}>
                      <Typography className={classes.bolded}>
                        Buy Now Price
                      </Typography>
                      <div className={classes.priceWrapper}>
                        <Typography
                          className={
                            page === "GAMD"
                              ? classes.GAMDBoldedColor
                              : classes.DDOBoldedColor
                          }
                        >
                          {car.buyNowPrice.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                      </div>
                    </div>
                    <Typography className={classes.buyNowSystemMessage}>
                      Note: excludes tax, license, and other fees that may
                      apply*
                    </Typography>
                    <div className={classes.lineWrapper}>
                      <Typography className={classes.bolded}>
                        Administrative Fee
                      </Typography>
                      <Typography
                        className={
                          page === "GAMD"
                            ? classes.GAMDBoldedColor
                            : classes.DDOBoldedColor
                        }
                      >
                        {car.adminFee.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Typography>
                    </div>
                    <Typography className={classes.buyNowSystemMessage2}>
                      *Dealer may be responsible for outstanding fees/penalties
                      tied to any CA vehicle.
                    </Typography>
                    <div className={classes.lineWrapper}>
                      <Typography className={classes.bolded}>
                        Transportation
                      </Typography>
                      <Typography
                        className={
                          page === "GAMD"
                            ? classes.GAMDBoldedColor
                            : classes.DDOBoldedColor
                        }
                      >
                        {transportMethodSelected !== ""
                          ? transportMethodSelected
                          : "N/A"}
                      </Typography>
                    </div>
                    <div className={classes.lineWrapper}>
                      <Typography className={classes.bolded}>Total</Typography>
                      <Typography
                        className={
                          page === "GAMD"
                            ? classes.GAMDBoldedColor
                            : classes.DDOBoldedColor
                        }
                      >
                        {totalPriceAmount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Typography>
                    </div>
                  </div>
                </div>
                {car.vehicleTitleDelay ? (
                  <Typography className={classes.NOSMessage}>
                    Vehicle has been grounded as an Early Termination pending
                    Notice of Sale to the lessee. As a result, release of title
                    for purchase may be delayed and is conditional on expiration
                    of the Notice of Sale
                  </Typography>
                ) : null}
              </div>
            </React.Fragment>
          )}
          <div className={classes.rightWrapper}>
            <div>
              <div>
                <Typography className={classes.carInfoTitle}>
                  {car.make} {car.model} {car.year}
                </Typography>
                <div className={classes.lowerVehicleInfoWrapper}>
                  <div className={classes.innerVehicleInfoWrapper}>
                    <Typography className={classes.label}>
                      {" "}
                      VIN:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {car.vehicleIdentificationNumber}
                      </span>
                    </Typography>
                    <Typography className={classes.label}>
                      {" "}
                      Odometer:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {car.inspectionMileage
                          ? car.inspectionMileage
                          : car.groundingMileage}
                      </span>
                    </Typography>
                  </div>
                  <div className={classes.carReportWrapper}>
                    <div id="carfaxLinkwrapper">
                      <ReactSVG
                        id="carfaxLink"
                        className={classes.carReportLink}
                        src={carfaxLogo}
                        alt="carfax"
                        onClick={() => this.showCarfaxReport()}
                        svgStyle={{ width: "5rem", height: "2rem" }}
                      />
                    </div>
                    <div id="autocheckLinkwrapper">
                      <ReactSVG
                        id="autocheckLink"
                        className={classes.carReportLink}
                        src={autoCheckLogo}
                        alt="autocheck"
                        onClick={() => this.showAutoCheckReport()}
                        svgStyle={{ width: "5rem", height: "2rem" }}
                        style={{ marginTop: ".5rem" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {this.renderLowerRight()}
            </div>
          </div>
        </div>
        {showCarfaxReport ? (
          <CarReport
            vin={car.vehicleIdentificationNumber}
            reportType="carfax"
            user={user}
            showCarfaxReport={this.showCarfaxReport}
            showNoDataVin={this.showNoDataVin}
          />
        ) : null}
        {showCarfaxSnackBar ? (
          <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
        ) : null}
        {showAutoCheckReport ? (
          <AutoCheckReport
            vin={
              car.vehicleIdentificationNumber
                ? car.vehicleIdentificationNumber
                : car.vin
            }
            reportType="carfax"
            showAutoCheckReport={this.showAutoCheckReport}
            user={user}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

ConfirmPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    cancelTimers: state.ui.cancelTimers,
    user: state.user,
    pageTheme: state.ui.pageTheme,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ConfirmPage));
