export const NOS_MESSAGE =
  "Vehicle has been grounded as an Early Termination pending Notice of Sale to the lessee.As a result, release of title for purchase may be delayed and is conditional on expiration of the Notice of Sale";
export const MOBILE_APP_DOWNLOAD =
  "Download it now from the App Store for iPhone/iPad and Google Play for Android";
export const MOBILE_APP_INFO = "The All-New Dealer Direct Mobile App";
export const DDO_UPPER_CASE = "DDO";
export const DDO_LOWER_CASE = "ddo";
export const LEXUS = "Lexus";
export const TOYOTA = "Toyota";
export const TDD = "TDD";
export const LDD = "LDD";
export const LOGIN_ATTEMPT_INVALID = "Your login attempt was invalid.";
export const PLEASE_CONTRACT = "Please contact your";
export const DEALERSHIP = "DEALERSHIP";
export const ADMINISTRATOR = "ADMINISTRATOR";
export const TO_ENSURE_THAT = "to ensure that you";
export const HAVE_VALID_LOGIN = "have a valid login to Dealer Direct.";
export const WELCOME = "WELCOME";
export const DEALERS = "DEALERS";
export const TO = "TO";
export const ONLINE_AUCTION = "Online Auction:";
export const CROSS_LINE_AUCTION = "Cross Line Auction:";
export const USERNAME_PASSWORD_INCORRECT = "Username or Password is incorrect";
export const RECAPTCHA_REQUIRED =
  "Too many failed login attempts reCaptcha required to login";
export const FORGOT_PASSWORD = "Forgot Password";
export const HELP = "Help";
export const LOGIN_SUCCESS = "Login success.";
export const BACK = "back";
export const NO_RESULT_FOUND = "No Results Found !!";
export const GAMD = "GAMD";
export const WATCHLIST = "Watchlist";
export const SEARCH = "Search";
export const HOME_AUTO_SEARCH = "homeAutoSearch";
export const HOME_VEHICLE_REQUIRING_ACTION = "homeVehicleRequiringAction";
export const CLOSE = "Close";
export const DOWNLOAD = "download";

//HOME PAGE CONSTANTS
export const HOME = "home";
export const LBSG = "LBSG";
export const TBSG = "TBSG";
export const TOYOTA_DEALER_DIRECT = "Toyota Dealer Direct";
export const LEXUS_DEALER_DIRECT = "Lexus Dealer Direct";
export const DLR_ACTION_NEEDED = "DLR_ACTION_NEEDED";
export const GROUND_A_VEHICLE_HERE = "GROUND A VEHICLE HERE";
export const VEHICLES_REQUIRING_ACTION = "VEHICLES REQUIRING ACTION";
export const AUTOSEARCH_RESULTS = "AUTOSEARCH RESULTS";
export const SUCCESS = "SUCCESS";
export const GROUNDING_MESSAGES = {
  ERROR_SOMETHING_WENT_WRONG: "Something went wrong. Please try again later.",
  ERROR_VINS_IS_INVALID:
    "*** The VIN you have entered is invalid or the vehicle is ineligible for grounding through Dealer Direct",
  ERROR_NO_USER_AVAILABLE: "NoUserAvailable",
  SUCCESS_START_GROUNDING_PROCESS:
    "Success! VIN has been entered, start the Grounding Process",
  CANNOT_GROUND_PURCHASE:
    "Cannot ground Customer Purchases in Dealer Direct. Customer Purchases must be facilitated through the CSC. Customer Line Toyota Payoff Information call 1-800-286-0652. Customer Line Lexus Payoff Information call 1-800-286-0653. Dealer line for Customer Payoffs call 1-800-253-9332.",
  PAYMENT_WAIVER_PROGRAM:
    "This Payment Waiver Program will require you to guarantee remaining payments.",
  ERROR_ODOMETER_VALUE: "Odometer values do not match / are blank",
};
export const GROUND = "ground";
export const VIN = "VIN";
export const AUTO_SEARCH = "autoSearch";
export const SETUP_AUTOSEARCH = "Setup " + AUTO_SEARCH;
export const NO_CARS_FOUND = "No cars found";
export const DEALER_PURCHASE_PERFORMANCE = "DEALER PURCHASE PERFORMANCE";
export const DEALER_PURCHASE = "Dealer Purchase";
export const REGIONAL_PURCHASE = "Regional Purchase";
export const NATIONAL_PURCHASE = "National Purchase";
export const PRE_TERM_PRICE_QUOTE = "Pre-Term Price Quote";
export const GROUND_BUY = "Ground/Buy";
export const GROUND_BTN = "Ground";

//FORGET PASSWORD
export const ENTER_USER_ID_AND_EMAIL =
  "Please enter User ID and email address.";
export const USERNAME = "Username";
export const EMAIL_ADDRESS = "Email Address";
export const ENTER = "Enter";
export const CANCEL = "Cancel";

export const FORGET_PASSWORD_ERROR_MSG = {
  FILL_BOTH_FIELDS: "Please fill out both fields before submitting.",
  PASSWORD_WAS_NOT_CHANGED:
    "Password was not changed.  Please check your username and email address and try again.",
  PASSWORD_EMAIL_SENT: "Password change email has been sent!",
};

//CALLING INFORMATION
export const GET_HELP_HERE = "Get Help Here";
export const CONTRACT_DD_SUPPORT = "Contact Dealer Direct Support by Telephone";
export const CONTRACT_TIME = "Monday through Friday 9:00am - 8:00pm EST";
export const CONTRACT_NUMBER = "877-522-3372";
export const EMAIL_US = "Email Us";
export const QUESTIONS =
  "Questions regarding outstanding transportation - Cars";
export const ARRIVE = "Arrive";
export const CONTRACT_NUMBER2 = "1-866-457-2277";

//NonSafariIOSAppDownloadNoticeModal
export const MOBILE_APP_ANNOUNCEMENT = "Mobile App Announcement";
export const MOBILE_APP_ANNOUNCEMENT_TEXT1 =
  "Updated versions of the Dealer Direct mobile app are no longer available on the App Store. Please login to either:";
export const MOBILE_APP_ANNOUNCEMENT_TEXT2 =
  "toyotadealerdirect.com or lexusdealerdirect.com";
export const MOBILE_APP_ANNOUNCEMENT_TEXT3 =
  "website from the Safari mobile browser and tap the download link on the pop-up to start the download.";

//AppDownloadModal
export const MOBILE_APP_DOWNLOAD_INSTRUCTIONS =
  "Mobile App Download Instructions";
export const START_DOWNLOAD_TEXT =
  "Please click the following link to start the download and installation of the new mobile app";

//GROUND
export const ODOMETER = "odometer";
export const ODOMETER_CONFIRMATION = "odometerConfirmation";
export const DISCLOSURE_STATEMENT_VALUE = "disclosureStatementValue";
export const CUSTLOCATION = "custLocation";
export const MASTERKEYS = "masterKeys";
export const VALETKEYS = "valetKeys";
export const VEHICLE_DRIVALBE = "vehicleDrivable";
export const PROGRAM_CRET_NUM = "programCertNum";
export const PAD_VALUE = "padValue";
export const PAD_VALUE_TO_SEND_TO_API = "padValueToSendToAPI";
export const CUSTOMER_WILL_SIGN_PAPER_STATEMENT =
  "customerWillSignPaperStatement";
export const CONFIRM_ERROR = "confirmError";
export const BALLOON = "BALLOON";
export const VEHICLE_ADDED_TO_WATCHLIST =
  "Vehicle added to Watchlist, please wait a few minutes for it to populate.";
export const VEHICLE_GROUNDING_COMPLETED = "4. Vehicle Grounding Completed";
export const VERIFY_VEHICLE_INFORMATION = "1. Verify Vehicle Information";
export const ODOMETER_STATEMENT = "2. Odometer Statement";
export const KEYS_AND_LOCATION = "3. Keys and location";
export const BACK_CAMEL_CASE = "Back";
export const GROUND_A_VEHICLE = "Ground a Vehicle";
export const PURCHASE_AT_PAYOFF = "Purchase at Payoff";
export const ADDRESS_NOT_AVAILABLE = "'Address Not Available'";
export const VERIFY_PICKUP_RETURN_DETAILS = "Verify Pickup/Return Details";
export const PICKUP_LOCATION = "Pickup Location";
export const ADD_VEHICLE_LOCATION = "Add Vehicle Location";
export const SPECIAL_INSTRUCTIONS = "Special Instructions";
export const MASTER_KEYS_RETURNED = "# Master Keys Returned";
export const VALET_KEYS_RETURNED = "# Valet Keys Returned";
export const IS_THE_VEHICLE_DRIVABLE = "Is the Vehicle Drivable?";

//Full Vehicle Information Report
export const MODEL_NUMBER = "Model Number";
export const ACCOUNT_TYPE = "Account Type";
export const KEYS = "Keys";
export const MASTER_KEYS = "Master Keys";
export const VALET_KEYS = "Valet Keys";
export const REMAINING_PAYMENTS = "Remaining Payments";
export const PAYOFF_AMOUNT = "Payoff Amount";
export const MARKET_BASED_PRICE = "Market Based Price";
export const GROUNDING_MILEAGE = "Grounding Mileage";
export const GROUNDING_DATE = "Grounding Date";
export const PAYMENT_GUARANTEED = "Payment Guaranteed";
export const GROUNDING_DEALER = "Grounding Dealer";
export const GROUNDER_NAME = "Grounder Name";
export const PAYMENT_WAIVER = "Payment Waiver";
export const ELIGIBLE = "Eligible";
export const NOT_ELIGIBLE = "Not Eligible";
export const LPW = "LPW";
export const TRANSPORTATION_ORDERED = "Transportation Ordered";
export const TRANSPORTATION_STATUS = "Transportation Status";
export const GRADE_CONDITION = "Grade Condition";
export const EWT = "EWT";
export const INSPECTION_COMPLETION_DATE = "Inspection Completion Date";
export const INSPECTION_MILEAGE = "Inspection Mileage";
export const NOT_INSPECTED_REASON = "Not Inspected Reason";
export const SOLD_DATE = "Sold Date";
export const PURCHASING_DEALER = "Purchasing Dealer";
export const PURCHASE_TYPE = "Purchase Type";
export const GROSS_PURCHASE_AMOUNT = "Gross Purchase Amount";
export const CARFAX = "carfax";
export const LEASE_TYPE = "Lease Type";
export const RESIDUAL = "Residual";

//GuaranteePayments
export const VEHICLE_INFO = "Vehicle Info";
export const COLON = ":";
export const DESCRIPTION = "Description:";
export const GUARANTEE_REMANING_PAYMENTS_TEXT =
  "You have chosen to guarantee remaining payments. By selecting to purchase this vehicle at the residual or market price listed above, you are guaranteeing that your dealership will pay the remaining payments of";
export const DOLLAR_SIGN = "$";
export const GUARANTEE_REMANING_PAYMENTS_TEXT_2 =
  "on the customer's behalf, for the above vehicle";
export const GUARANTEE_REMANING_PAYMENTS_TEXT_3 =
  "Remaining guaranteed payments should be remitted within 48 hours via the Dealer Daily Toyota Financial Services Dealer ACH Web Portal.";
export const GUARANTEE = "Guarantee";
export const SYSTEM_MESSAGE = "System Message:";
export const GUARANTEE_REMANING_PAYMENTS_TEXT_4 =
  "You have chosen to guarantee remaining payments only in the amount of";
export const GUARANTEE_REMAINING_PAYMENTS_TEXT_5 =
  "You do not wish to purchase the vehicle at this time.";
export const DONE = "Done";
export const GUARANTEE_REMAINING_PAYMENTS_TEXT_6 =
  "An inspection for this vehicle has been ordered. By selecting Guarantee Remaining Payments Only, you are guaranteeing that your dealership will pay the remaining payments of";
export const GUARANTEE_REMAINING_PAYMENTS_TEXT_7 =
  "on the customer's behalf for the above vehicle. Remaining guaranteed payments should be remitted within 48 hours via the Dealer Daily Toyota Financial Services Dealer ACH Web Portal.";
export const GUARANTEE_REMAINING_PAYMENTS_TEXT_8 =
  "should be remitted within 48 hours via the Dealer Daily Toyota Financial Services Dealer ACH Web Portal.";

//GuaranteePaymentsLPW
export const GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_1 =
  " By entering the certificate number, you are guaranteeing that your dealership will pay the remaining payments of";
export const GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_2 =
  "on behalf of the customer for the above vehicle.";
export const GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_3 =
  "Remaining guaranteed payments should be remitted within 48 hours via the Dealer Daily Toyota Financial Services Dealer ACH Web Portal.";
export const TOTAL_REMAINING_PAYMENTS = "The total Remaining Payments of";
export const GUARANTEE_REMAINING_PAYMENTS_LPW_TEXT_4 =
  "should be remitted within 48 hours via the Dealer Daily Toyota Financial Services Dealer ACH Web Portal.";

//GroundingInformationDisplay
export const REM_PAYMENTS = "Rem. Payments";
export const LEASE_INFORMATION = "Lease Information";
export const MATURITY_DATE = "Maturity Date";
export const ACCOUNT_NUMBER = "Account Number";
export const LEASE_NAME = "Lease Name";
export const EARLY_TERM_GROUNDING_MESSAGE_DETAILS =
  "Based on the maturity date, this is an early termination, and you will only be able to purchase this vehicle at Market Value after an inspection is completed.";
//VehicleInformation
export const MAKE_YOUR_SELECTION = "Please Make Your Selection";
export const PURCHASE_LEASE_VEHICLE = "Purchase this Lease Vehicle";
export const LESSEE_PAYOFF_PURCHASE = "Lessee Payoff Purchase";
export const RETURN_FOR_TRADEIN = "Return for Trade-in";
export const RETURN_VEHICLE_FOR_TFS_LFS = "Return Vehicle for TFS/LFS";
export const INELEGIBLE_USER =
  "You are not authorized to purchase vehicles at this time. Please contact a user with buy permissions to complete the purchase of this vehicle";
export const EARLY_TERM_LABEL = "This is an Early-Term Vehicle";
//LPWEntryForm
export const GUARANTEE_PAYMENTS = "Guarantee Payments";
export const PAYMENT_WAIVER_ACCEPTANCE = "PAYMENT WAIVER ACCEPTANCE";
export const YES = "YES";
export const NO = "No";
export const ATTENTION = "Attention:";
export const COMMENTS = "Comments";
export const ENTER_TEXT = "Enter Text...";
export const INVALID_PROGRAM_CERTIFICATE = "Invalid Program Certificate #";
export const PROGRAM_CERTIFICATE = "Program Certificate #";
export const VERIFY = "Verify";
export const PROGRAM_CERT = {
  VERIFY_THE_CERT_NUMBER:
    "You have entered an invalid certificate number. Please check the certificate number and try again. If you have tried again and the number does not work, please contact the CSC for further assistance.",
  GUARANTEE_PAYMENT_WAIVER:
    "Please guarantee the the payments if you wish to use Payment Waiver Program.",
  MAX_CHARACTER_ALLOWED: "*Maximum of 250 characters allowed",
  PROGRAM_CERTIFICATE_NUMBER: "Program Certificate number",
  ADDED_TO_THE_VEHICLE: "has been added to the vehicle.",
  VEHICLE_AVAILABLE_FOR: "THIS VEHICLE IS AVAILABLE FOR THE",
  DO_YOU_WANT_TO_CONTINUE: "Do you want to continue?",
};

//ODOMETER
export const ODOMETER_LEGAL_DISCLOSURE = "Legally Required Disclosure:";
export const ODOMETER_LEGAL_DISCLOSURE_NOTICE =
  " As grounding dealer, you are responsible to accurately disclose the vehicle mileage. Toyota Motor Credit Corporation relies on the mileage you disclose for completion of the legally required Federal Odometer Disclosure Statement. You must continue to provide TMCC the printed and executed Odometer Disclosure Statement by fax or US mail.";
export const ODOMETER_LEGAL_DISCLOSURE_NOTICE_NEW =
  " As grounding dealer, you are responsible to accurately disclose the vehicle mileage. Toyota Motor Credit Corporation relies on the mileage you disclose for completion of the legally required Federal Odometer Disclosure Statement. You must continue to provide TMCC the printed and executed Odometer Disclosure Statement by fax, US mail or Stratus Upload.";
export const ODOMETER_DISCLOSURE = "Odometer Disclosure";
export const REENTER = "Re-enter:";

export const PAYOFF_PRICE_GROUNDING_ERROR =
  "An error has occurred during the grounding process. Please retry grounding this vehicle within 2 hours or";

export const PAYOFF_PRICE_NOT_AVAILABLE =
  "Payoff Price not available. Please retry shortly or";
export const CALL_ADESA_SUPPORT =
  "Call Dealer Direct Customer Support at 1-877-522-3372";
export const PLEASE_WAIT = "Request is being processed. Please wait.";

export const PAY_OFF_NOT_RECEIVED = "Pay off not received";
export const VEHICLE_RETURN_RECEIPT_ODO_STMT =
  "Vehicle Return Receipt and Odometer Statement";
export const VEHICLE_RETURN_RECEIPT_SIGNING =
  "By signing below, the Lessee conﬁrms that the vehicle was returned, the mileage stated reﬂects the actual mileage if the vehicle and the odometer was not altered.";
export const ADD_LEASEE_SIGNATURE = "Add Leasee Signature";
export const SIGN_DISCLOSURE_HERE = "Sign Disclosure Here!";
export const CLEAR_SIGNATURE = "Clear Signature";
export const ODOMETER_PROBLEM_LESSEE_PAYOFF =
  'Odometer Problem: You have indicated there is a problem with the odometer statement of the vehicle. As a result, this vehicle will not be made available for sale. Please ground this vehicle using the "Return Vehicle for TFS/LFS"';
export const ODOMETER_PROBLEM_RETURN_TO_TFS_LFS =
  "Odometer Problem! You have indicated there is a problem with the odometer statement of this vehicle.  As a result, this vehicle will not be made available for sale. Please confirm.";
export const LESSEE_PAYOFF_DISCLAIMER =
  "The Lease Payoff Quote Amount is subject to increase if additional amounts, such as, but not limited to, citations, returned payments, or property taxes, post to the account after we provide the payoff quote. You are required to provide the Customer’s signed odometer disclosure statement when remitting the payoff funds.";
